/*@tailwind preflight;*/

.appearance-none {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.bg-fixed {
	background-attachment: fixed;
}

.bg-local {
	background-attachment: local;
}
 
.bg-scroll {
	background-attachment: scroll;
}

.bg-transparent {
	background-color: transparent;
}

.bg-black {
	background-color: #22292f;
}

.bg-white {
	background-color: #ffffff;
}

.bg-gray-100 {
	background-color: #f7fafc;
}

.bg-gray-200 {
	background-color: #edf2f7;
}

.bg-gray-300 {
	background-color: #e2e8f0;
}

.bg-gray-400 {
	background-color: #cbd5e0;
}

.bg-gray-500 {
	background-color: #a0aec0;
}

.bg-gray-600 {
	background-color: #718096;
}

.bg-gray-700 {
	background-color: #4a5568;
}

.bg-gray-800 {
	background-color: #2d3748;
}

.bg-gray-900 {
	background-color: #1a202c;
}

.bg-red {
	background-color: #e3342f;
}

.bg-orange {
	background-color: #f6993f;
}

.bg-yellow {
	background-color: #ffed4a;
}

.bg-green {
	background-color: #38c172;
}

.bg-teal {
	background-color: #4dc0b5;
}

.bg-blue {
	background-color: #3490dc;
}

.bg-indigo {
	background-color: #6574cd;
}

.bg-purple {
	background-color: #9561e2;
}

.bg-pink {
	background-color: #f66d9b;
}

.bg-grey-darkest {
	background-color: #3d4852;
}

.bg-grey-darker {
	background-color: #606f7b;
}

.bg-grey-dark {
	background-color: #8795a1;
}

.bg-grey {
	background-color: #b8c2cc;
}

.bg-grey-light {
	background-color: #dae1e7;
}

.bg-grey-lighter {
	background-color: #f1f5f8;
}

.bg-grey-lightest {
	background-color: #f8fafc;
}

.bg-red-darkest {
	background-color: #3b0d0c;
}

.bg-red-darker {
	background-color: #621b18;
}

.bg-red-dark {
	background-color: #ff432b;
}

.bg-red-light {
	background-color: #ef5753;
}

.bg-red-lighter {
	background-color: #f9acaa;
}

.bg-red-lightest {
	background-color: #fcebea;
}

.bg-orange-darkest {
	background-color: #462a16;
}

.bg-orange-darker {
	background-color: #613b1f;
}

.bg-orange-dark {
	background-color: #de751f;
}

.bg-orange-light {
	background-color: #faad63;
}

.bg-orange-lighter {
	background-color: #fcd9b6;
}

.bg-orange-lightest {
	background-color: #fff5eb;
}

.bg-yellow-darkest {
	background-color: #453411;
}

.bg-yellow-darker {
	background-color: #684f1d;
}

.bg-yellow-dark {
	background-color: #f2d024;
}

.bg-yellow-light {
	background-color: #fff382;
}

.bg-yellow-lighter {
	background-color: #fff9c2;
}

.bg-yellow-lightest {
	background-color: #fcfbeb;
}

.bg-green-darkest {
	background-color: #0f2f21;
}

.bg-green-darker {
	background-color: #1a4731;
}

.bg-green-dark {
	background-color: #1f9d55;
}

.bg-green-light {
	background-color: #51d88a;
}

.bg-green-lighter {
	background-color: #a2f5bf;
}

.bg-green-lightest {
	background-color: #e3fcec;
}

.bg-teal-darkest {
	background-color: #0d3331;
}

.bg-teal-darker {
	background-color: #20504f;
}

.bg-teal-dark {
	background-color: #38a89d;
}

.bg-teal-light {
	background-color: #64d5ca;
}

.bg-teal-lighter {
	background-color: #a0f0ed;
}

.bg-teal-lightest {
	background-color: #e8fffe;
}

.bg-blue-darkest {
	background-color: #12283a;
}

.bg-blue-darker {
	background-color: #1c3d5a;
}

.bg-blue-dark {
	background-color: #2779bd;
}

.bg-blue-light {
	background-color: #6cb2eb;
}

.bg-blue-lighter {
	background-color: #bcdefa;
}

.bg-blue-lightest {
	background-color: #eff8ff;
}

.bg-indigo-darkest {
	background-color: #191e38;
}

.bg-indigo-darker {
	background-color: #2f365f;
}

.bg-indigo-dark {
	background-color: #5661b3;
}

.bg-indigo-light {
	background-color: #7886d7;
}

.bg-indigo-lighter {
	background-color: #b2b7ff;
}

.bg-indigo-lightest {
	background-color: #e6e8ff;
}

.bg-purple-darkest {
	background-color: #180f3c;
}

.bg-purple-darker {
	background-color: #271b4f;
}

.bg-purple-dark {
	background-color: #43397b;
}

.bg-purple-light {
	background-color: #a779e9;
}

.bg-purple-lighter {
	background-color: #d6bbfc;
}

.bg-purple-lightest {
	background-color: #f3ebff;
}

.bg-pink-darkest {
	background-color: #451225;
}

.bg-pink-darker {
	background-color: #6f213f;
}

.bg-pink-dark {
	background-color: #eb5286;
}

.bg-pink-light {
	background-color: #fa7ea8;
}

.bg-pink-lighter {
	background-color: #ffbbca;
}

.bg-pink-lightest {
	background-color: #ffebef;
}

.hover\:bg-transparent:hover {
	background-color: transparent;
}

.hover\:bg-black:hover {
	background-color: #22292f;
}

.hover\:bg-white:hover {
	background-color: #ffffff;
}

.hover\:bg-gray-100:hover {
	background-color: #f7fafc;
}

.hover\:bg-gray-200:hover {
	background-color: #edf2f7;
}

.hover\:bg-gray-300:hover {
	background-color: #e2e8f0;
}

.hover\:bg-gray-400:hover {
	background-color: #cbd5e0;
}

.hover\:bg-gray-500:hover {
	background-color: #a0aec0;
}

.hover\:bg-gray-600:hover {
	background-color: #718096;
}

.hover\:bg-gray-700:hover {
	background-color: #4a5568;
}

.hover\:bg-gray-800:hover {
	background-color: #2d3748;
}

.hover\:bg-gray-900:hover {
	background-color: #1a202c;
}

.hover\:bg-red:hover {
	background-color: #e3342f;
}

.hover\:bg-orange:hover {
	background-color: #f6993f;
}

.hover\:bg-yellow:hover {
	background-color: #ffed4a;
}

.hover\:bg-green:hover {
	background-color: #38c172;
}

.hover\:bg-teal:hover {
	background-color: #4dc0b5;
}

.hover\:bg-blue:hover {
	background-color: #3490dc;
}

.hover\:bg-indigo:hover {
	background-color: #6574cd;
}

.hover\:bg-purple:hover {
	background-color: #9561e2;
}

.hover\:bg-pink:hover {
	background-color: #f66d9b;
}

.hover\:bg-grey-darkest:hover {
	background-color: #3d4852;
}

.hover\:bg-grey-darker:hover {
	background-color: #606f7b;
}

.hover\:bg-grey-dark:hover {
	background-color: #8795a1;
}

.hover\:bg-grey:hover {
	background-color: #b8c2cc;
}

.hover\:bg-grey-light:hover {
	background-color: #dae1e7;
}

.hover\:bg-grey-lighter:hover {
	background-color: #f1f5f8;
}

.hover\:bg-grey-lightest:hover {
	background-color: #f8fafc;
}

.hover\:bg-red-darkest:hover {
	background-color: #3b0d0c;
}

.hover\:bg-red-darker:hover {
	background-color: #621b18;
}

.hover\:bg-red-dark:hover {
	background-color: #cc1f1a;
}

.hover\:bg-red-light:hover {
	background-color: #ef5753;
}

.hover\:bg-red-lighter:hover {
	background-color: #f9acaa;
}

.hover\:bg-red-lightest:hover {
	background-color: #fcebea;
}

.hover\:bg-orange-darkest:hover {
	background-color: #462a16;
}

.hover\:bg-orange-darker:hover {
	background-color: #613b1f;
}

.hover\:bg-orange-dark:hover {
	background-color: #de751f;
}

.hover\:bg-orange-light:hover {
	background-color: #faad63;
}

.hover\:bg-orange-lighter:hover {
	background-color: #fcd9b6;
}

.hover\:bg-orange-lightest:hover {
	background-color: #fff5eb;
}

.hover\:bg-yellow-darkest:hover {
	background-color: #453411;
}

.hover\:bg-yellow-darker:hover {
	background-color: #684f1d;
}

.hover\:bg-yellow-dark:hover {
	background-color: #f2d024;
}

.hover\:bg-yellow-light:hover {
	background-color: #fff382;
}

.hover\:bg-yellow-lighter:hover {
	background-color: #fff9c2;
}

.hover\:bg-yellow-lightest:hover {
	background-color: #fcfbeb;
}

.hover\:bg-green-darkest:hover {
	background-color: #0f2f21;
}

.hover\:bg-green-darker:hover {
	background-color: #1a4731;
}

.hover\:bg-green-dark:hover {
	background-color: #1f9d55;
}

.hover\:bg-green-light:hover {
	background-color: #51d88a;
}

.hover\:bg-green-lighter:hover {
	background-color: #a2f5bf;
}

.hover\:bg-green-lightest:hover {
	background-color: #e3fcec;
}

.hover\:bg-teal-darkest:hover {
	background-color: #0d3331;
}

.hover\:bg-teal-darker:hover {
	background-color: #20504f;
}

.hover\:bg-teal-dark:hover {
	background-color: #38a89d;
}

.hover\:bg-teal-light:hover {
	background-color: #64d5ca;
}

.hover\:bg-teal-lighter:hover {
	background-color: #a0f0ed;
}

.hover\:bg-teal-lightest:hover {
	background-color: #e8fffe;
}

.hover\:bg-blue-darkest:hover {
	background-color: #12283a;
}

.hover\:bg-blue-darker:hover {
	background-color: #1c3d5a;
}

.hover\:bg-blue-dark:hover {
	background-color: #2779bd;
}

.hover\:bg-blue-light:hover {
	background-color: #6cb2eb;
}

.hover\:bg-blue-lighter:hover {
	background-color: #bcdefa;
}

.hover\:bg-blue-lightest:hover {
	background-color: #eff8ff;
}

.hover\:bg-indigo-darkest:hover {
	background-color: #191e38;
}

.hover\:bg-indigo-darker:hover {
	background-color: #2f365f;
}

.hover\:bg-indigo-dark:hover {
	background-color: #5661b3;
}

.hover\:bg-indigo-light:hover {
	background-color: #7886d7;
}

.hover\:bg-indigo-lighter:hover {
	background-color: #b2b7ff;
}

.hover\:bg-indigo-lightest:hover {
	background-color: #e6e8ff;
}

.hover\:bg-purple-darkest:hover {
	background-color: #21183c;
}

.hover\:bg-purple-darker:hover {
	background-color: #382b5f;
}

.hover\:bg-purple-dark:hover {
	background-color: #794acf;
}

.hover\:bg-purple-light:hover {
	background-color: #a779e9;
}

.hover\:bg-purple-lighter:hover {
	background-color: #d6bbfc;
}

.hover\:bg-purple-lightest:hover {
	background-color: #f3ebff;
}

.hover\:bg-pink-darkest:hover {
	background-color: #451225;
}

.hover\:bg-pink-darker:hover {
	background-color: #6f213f;
}

.hover\:bg-pink-dark:hover {
	background-color: #eb5286;
}

.hover\:bg-pink-light:hover {
	background-color: #fa7ea8;
}

.hover\:bg-pink-lighter:hover {
	background-color: #ffbbca;
}

.hover\:bg-pink-lightest:hover {
	background-color: #ffebef;
}

.focus\:bg-transparent:focus {
	background-color: transparent;
}

.focus\:bg-black:focus {
	background-color: #22292f;
}

.focus\:bg-white:focus {
	background-color: #ffffff;
}

.focus\:bg-gray-100:focus {
	background-color: #f7fafc;
}

.focus\:bg-gray-200:focus {
	background-color: #edf2f7;
}

.focus\:bg-gray-300:focus {
	background-color: #e2e8f0;
}

.focus\:bg-gray-400:focus {
	background-color: #cbd5e0;
}

.focus\:bg-gray-500:focus {
	background-color: #a0aec0;
}

.focus\:bg-gray-600:focus {
	background-color: #718096;
}

.focus\:bg-gray-700:focus {
	background-color: #4a5568;
}

.focus\:bg-gray-800:focus {
	background-color: #2d3748;
}

.focus\:bg-gray-900:focus {
	background-color: #1a202c;
}

.focus\:bg-red:focus {
	background-color: #e3342f;
}

.focus\:bg-orange:focus {
	background-color: #f6993f;
}

.focus\:bg-yellow:focus {
	background-color: #ffed4a;
}

.focus\:bg-green:focus {
	background-color: #38c172;
}

.focus\:bg-teal:focus {
	background-color: #4dc0b5;
}

.focus\:bg-blue:focus {
	background-color: #3490dc;
}

.focus\:bg-indigo:focus {
	background-color: #6574cd;
}

.focus\:bg-purple:focus {
	background-color: #9561e2;
}

.focus\:bg-pink:focus {
	background-color: #f66d9b;
}

.focus\:bg-grey-darkest:focus {
	background-color: #3d4852;
}

.focus\:bg-grey-darker:focus {
	background-color: #606f7b;
}

.focus\:bg-grey-dark:focus {
	background-color: #8795a1;
}

.focus\:bg-grey:focus {
	background-color: #b8c2cc;
}

.focus\:bg-grey-light:focus {
	background-color: #dae1e7;
}

.focus\:bg-grey-lighter:focus {
	background-color: #f1f5f8;
}

.focus\:bg-grey-lightest:focus {
	background-color: #f8fafc;
}

.focus\:bg-red-darkest:focus {
	background-color: #3b0d0c;
}

.focus\:bg-red-darker:focus {
	background-color: #621b18;
}

.focus\:bg-red-dark:focus {
	background-color: #cc1f1a;
}

.focus\:bg-red-light:focus {
	background-color: #ef5753;
}

.focus\:bg-red-lighter:focus {
	background-color: #f9acaa;
}

.focus\:bg-red-lightest:focus {
	background-color: #fcebea;
}

.focus\:bg-orange-darkest:focus {
	background-color: #462a16;
}

.focus\:bg-orange-darker:focus {
	background-color: #613b1f;
}

.focus\:bg-orange-dark:focus {
	background-color: #de751f;
}

.focus\:bg-orange-light:focus {
	background-color: #faad63;
}

.focus\:bg-orange-lighter:focus {
	background-color: #fcd9b6;
}

.focus\:bg-orange-lightest:focus {
	background-color: #fff5eb;
}

.focus\:bg-yellow-darkest:focus {
	background-color: #453411;
}

.focus\:bg-yellow-darker:focus {
	background-color: #684f1d;
}

.focus\:bg-yellow-dark:focus {
	background-color: #f2d024;
}

.focus\:bg-yellow-light:focus {
	background-color: #fff382;
}

.focus\:bg-yellow-lighter:focus {
	background-color: #fff9c2;
}

.focus\:bg-yellow-lightest:focus {
	background-color: #fcfbeb;
}

.focus\:bg-green-darkest:focus {
	background-color: #0f2f21;
}

.focus\:bg-green-darker:focus {
	background-color: #1a4731;
}

.focus\:bg-green-dark:focus {
	background-color: #1f9d55;
}

.focus\:bg-green-light:focus {
	background-color: #51d88a;
}

.focus\:bg-green-lighter:focus {
	background-color: #a2f5bf;
}

.focus\:bg-green-lightest:focus {
	background-color: #e3fcec;
}

.focus\:bg-teal-darkest:focus {
	background-color: #0d3331;
}

.focus\:bg-teal-darker:focus {
	background-color: #20504f;
}

.focus\:bg-teal-dark:focus {
	background-color: #38a89d;
}

.focus\:bg-teal-light:focus {
	background-color: #64d5ca;
}

.focus\:bg-teal-lighter:focus {
	background-color: #a0f0ed;
}

.focus\:bg-teal-lightest:focus {
	background-color: #e8fffe;
}

.focus\:bg-blue-darkest:focus {
	background-color: #12283a;
}

.focus\:bg-blue-darker:focus {
	background-color: #1c3d5a;
}

.focus\:bg-blue-dark:focus {
	background-color: #2779bd;
}

.focus\:bg-blue-light:focus {
	background-color: #6cb2eb;
}

.focus\:bg-blue-lighter:focus {
	background-color: #bcdefa;
}

.focus\:bg-blue-lightest:focus {
	background-color: #eff8ff;
}

.focus\:bg-indigo-darkest:focus {
	background-color: #191e38;
}

.focus\:bg-indigo-darker:focus {
	background-color: #2f365f;
}

.focus\:bg-indigo-dark:focus {
	background-color: #5661b3;
}

.focus\:bg-indigo-light:focus {
	background-color: #7886d7;
}

.focus\:bg-indigo-lighter:focus {
	background-color: #b2b7ff;
}

.focus\:bg-indigo-lightest:focus {
	background-color: #e6e8ff;
}

.focus\:bg-purple-darkest:focus {
	background-color: #21183c;
}

.focus\:bg-purple-darker:focus {
	background-color: #382b5f;
}

.focus\:bg-purple-dark:focus {
	background-color: #794acf;
}

.focus\:bg-purple-light:focus {
	background-color: #a779e9;
}

.focus\:bg-purple-lighter:focus {
	background-color: #d6bbfc;
}

.focus\:bg-purple-lightest:focus {
	background-color: #f3ebff;
}

.focus\:bg-pink-darkest:focus {
	background-color: #451225;
}

.focus\:bg-pink-darker:focus {
	background-color: #6f213f;
}

.focus\:bg-pink-dark:focus {
	background-color: #eb5286;
}

.focus\:bg-pink-light:focus {
	background-color: #fa7ea8;
}

.focus\:bg-pink-lighter:focus {
	background-color: #ffbbca;
}

.focus\:bg-pink-lightest:focus {
	background-color: #ffebef;
}

.bg-bottom {
	background-position: bottom;
}

.bg-center {
	background-position: center;
}

.bg-left {
	background-position: left;
}

.bg-left-bottom {
	background-position: left bottom;
}

.bg-left-top {
	background-position: left top;
}

.bg-right {
	background-position: right;
}

.bg-right-bottom {
	background-position: right bottom;
}

.bg-right-top {
	background-position: right top;
}

.bg-top {
	background-position: top;
}

.bg-repeat {
	background-repeat: repeat;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-repeat-x {
	background-repeat: repeat-x;
}

.bg-repeat-y {
	background-repeat: repeat-y;
}

.bg-repeat-round {
	background-repeat: round;
}

.bg-repeat-space {
	background-repeat: space;
}

.bg-auto {
	background-size: auto;
}

.bg-cover {
	background-size: cover;
}

.bg-contain {
	background-size: contain;
}

.border-collapse {
	border-collapse: collapse;
}

.border-separate {
	border-collapse: separate;
}

.border-transparent {
	border-color: transparent;
}

.border-black {
	border-color: #22292f;
}

.border-white {
	border-color: #ffffff;
}

.border-gray-100 {
	border-color: #f7fafc;
}

.border-gray-200 {
	border-color: #edf2f7;
}

.border-gray-300 {
	border-color: #e2e8f0;
}

.border-gray-400 {
	border-color: #cbd5e0;
}

.border-gray-500 {
	border-color: #c4c4c4;
}

.border-gray-600 {
	border-color: #718096;
}

.border-gray-700 {
	border-color: #4a5568;
}

.border-gray-800 {
	border-color: #2d3748;
}

.border-gray-900 {
	border-color: #1a202c;
}

.border-red {
	border-color: #e3342f;
}

.border-orange {
	border-color: #f6993f;
}

.border-yellow {
	border-color: #ffed4a;
}

.border-green {
	border-color: #38c172;
}

.border-teal {
	border-color: #4dc0b5;
}

.border-blue {
	border-color: #3490dc;
}

.border-indigo {
	border-color: #6574cd;
}

.border-purple {
	border-color: #9561e2;
}

.border-pink {
	border-color: #f66d9b;
}

.border-grey-darkest {
	border-color: #3d4852;
}

.border-grey-darker {
	border-color: #606f7b;
}

.border-grey-dark {
	border-color: #8795a1;
}

.border-grey {
	border-color: #b8c2cc;
}

.border-grey-light {
	border-color: #dae1e7;
}

.border-grey-lighter {
	border-color: #f1f5f8;
}

.border-grey-lightest {
	border-color: #f8fafc;
}

.border-red-darkest {
	border-color: #3b0d0c;
}

.border-red-darker {
	border-color: #621b18;
}

.border-red-dark {
	border-color: #cc1f1a;
}

.border-red-light {
	border-color: #ef5753;
}

.border-red-lighter {
	border-color: #f9acaa;
}

.border-red-lightest {
	border-color: #fcebea;
}

.border-orange-darkest {
	border-color: #462a16;
}

.border-orange-darker {
	border-color: #613b1f;
}

.border-orange-dark {
	border-color: #de751f;
}

.border-orange-light {
	border-color: #faad63;
}

.border-orange-lighter {
	border-color: #fcd9b6;
}

.border-orange-lightest {
	border-color: #fff5eb;
}

.border-yellow-darkest {
	border-color: #453411;
}

.border-yellow-darker {
	border-color: #684f1d;
}

.border-yellow-dark {
	border-color: #f2d024;
}

.border-yellow-light {
	border-color: #fff382;
}

.border-yellow-lighter {
	border-color: #fff9c2;
}

.border-yellow-lightest {
	border-color: #fcfbeb;
}

.border-green-darkest {
	border-color: #0f2f21;
}

.border-green-darker {
	border-color: #1a4731;
}

.border-green-dark {
	border-color: #1f9d55;
}

.border-green-light {
	border-color: #51d88a;
}

.border-green-lighter {
	border-color: #a2f5bf;
}

.border-green-lightest {
	border-color: #e3fcec;
}

.border-teal-darkest {
	border-color: #0d3331;
}

.border-teal-darker {
	border-color: #20504f;
}

.border-teal-dark {
	border-color: #38a89d;
}

.border-teal-light {
	border-color: #64d5ca;
}

.border-teal-lighter {
	border-color: #a0f0ed;
}

.border-teal-lightest {
	border-color: #e8fffe;
}

.border-blue-darkest {
	border-color: #12283a;
}

.border-blue-darker {
	border-color: #1c3d5a;
}

.border-blue-dark {
	border-color: #2779bd;
}

.border-blue-light {
	border-color: #6cb2eb;
}

.border-blue-lighter {
	border-color: #bcdefa;
}

.border-blue-lightest {
	border-color: #eff8ff;
}

.border-indigo-darkest {
	border-color: #191e38;
}

.border-indigo-darker {
	border-color: #2f365f;
}

.border-indigo-dark {
	border-color: #5661b3;
}

.border-indigo-light {
	border-color: #7886d7;
}

.border-indigo-lighter {
	border-color: #b2b7ff;
}

.border-indigo-lightest {
	border-color: #e6e8ff;
}

.border-purple-darkest {
	border-color: #21183c;
}

.border-purple-darker {
	border-color: #382b5f;
}

.border-purple-dark {
	border-color: #794acf;
}

.border-purple-light {
	border-color: #a779e9;
}

.border-purple-lighter {
	border-color: #d6bbfc;
}

.border-purple-lightest {
	border-color: #f3ebff;
}

.border-pink-darkest {
	border-color: #451225;
}

.border-pink-darker {
	border-color: #6f213f;
}

.border-pink-dark {
	border-color: #eb5286;
}

.border-pink-light {
	border-color: #fa7ea8;
}

.border-pink-lighter {
	border-color: #ffbbca;
}

.border-pink-lightest {
	border-color: #ffebef;
}

.hover\:border-transparent:hover {
	border-color: transparent;
}

.hover\:border-black:hover {
	border-color: #22292f;
}

.hover\:border-white:hover {
	border-color: #ffffff;
}

.hover\:border-gray-100:hover {
	border-color: #f7fafc;
}

.hover\:border-gray-200:hover {
	border-color: #edf2f7;
}

.hover\:border-gray-300:hover {
	border-color: #e2e8f0;
}

.hover\:border-gray-400:hover {
	border-color: #cbd5e0;
}

.hover\:border-gray-500:hover {
	border-color: #a0aec0;
}

.hover\:border-gray-600:hover {
	border-color: #718096;
}

.hover\:border-gray-700:hover {
	border-color: #4a5568;
}

.hover\:border-gray-800:hover {
	border-color: #2d3748;
}

.hover\:border-gray-900:hover {
	border-color: #1a202c;
}

.hover\:border-red:hover {
	border-color: #e3342f;
}

.hover\:border-orange:hover {
	border-color: #f6993f;
}

.hover\:border-yellow:hover {
	border-color: #ffed4a;
}

.hover\:border-green:hover {
	border-color: #38c172;
}

.hover\:border-teal:hover {
	border-color: #4dc0b5;
}

.hover\:border-blue:hover {
	border-color: #3490dc;
}

.hover\:border-indigo:hover {
	border-color: #6574cd;
}

.hover\:border-purple:hover {
	border-color: #9561e2;
}

.hover\:border-pink:hover {
	border-color: #f66d9b;
}

.hover\:border-grey-darkest:hover {
	border-color: #3d4852;
}

.hover\:border-grey-darker:hover {
	border-color: #606f7b;
}

.hover\:border-grey-dark:hover {
	border-color: #8795a1;
}

.hover\:border-grey:hover {
	border-color: #b8c2cc;
}

.hover\:border-grey-light:hover {
	border-color: #dae1e7;
}

.hover\:border-grey-lighter:hover {
	border-color: #f1f5f8;
}

.hover\:border-grey-lightest:hover {
	border-color: #f8fafc;
}

.hover\:border-red-darkest:hover {
	border-color: #3b0d0c;
}

.hover\:border-red-darker:hover {
	border-color: #621b18;
}

.hover\:border-red-dark:hover {
	border-color: #cc1f1a;
}

.hover\:border-red-light:hover {
	border-color: #ef5753;
}

.hover\:border-red-lighter:hover {
	border-color: #f9acaa;
}

.hover\:border-red-lightest:hover {
	border-color: #fcebea;
}

.hover\:border-orange-darkest:hover {
	border-color: #462a16;
}

.hover\:border-orange-darker:hover {
	border-color: #613b1f;
}

.hover\:border-orange-dark:hover {
	border-color: #de751f;
}

.hover\:border-orange-light:hover {
	border-color: #faad63;
}

.hover\:border-orange-lighter:hover {
	border-color: #fcd9b6;
}

.hover\:border-orange-lightest:hover {
	border-color: #fff5eb;
}

.hover\:border-yellow-darkest:hover {
	border-color: #453411;
}

.hover\:border-yellow-darker:hover {
	border-color: #684f1d;
}

.hover\:border-yellow-dark:hover {
	border-color: #f2d024;
}

.hover\:border-yellow-light:hover {
	border-color: #fff382;
}

.hover\:border-yellow-lighter:hover {
	border-color: #fff9c2;
}

.hover\:border-yellow-lightest:hover {
	border-color: #fcfbeb;
}

.hover\:border-green-darkest:hover {
	border-color: #0f2f21;
}

.hover\:border-green-darker:hover {
	border-color: #1a4731;
}

.hover\:border-green-dark:hover {
	border-color: #1f9d55;
}

.hover\:border-green-light:hover {
	border-color: #51d88a;
}

.hover\:border-green-lighter:hover {
	border-color: #a2f5bf;
}

.hover\:border-green-lightest:hover {
	border-color: #e3fcec;
}

.hover\:border-teal-darkest:hover {
	border-color: #0d3331;
}

.hover\:border-teal-darker:hover {
	border-color: #20504f;
}

.hover\:border-teal-dark:hover {
	border-color: #38a89d;
}

.hover\:border-teal-light:hover {
	border-color: #64d5ca;
}

.hover\:border-teal-lighter:hover {
	border-color: #a0f0ed;
}

.hover\:border-teal-lightest:hover {
	border-color: #e8fffe;
}

.hover\:border-blue-darkest:hover {
	border-color: #12283a;
}

.hover\:border-blue-darker:hover {
	border-color: #1c3d5a;
}

.hover\:border-blue-dark:hover {
	border-color: #2779bd;
}

.hover\:border-blue-light:hover {
	border-color: #6cb2eb;
}

.hover\:border-blue-lighter:hover {
	border-color: #bcdefa;
}

.hover\:border-blue-lightest:hover {
	border-color: #eff8ff;
}

.hover\:border-indigo-darkest:hover {
	border-color: #191e38;
}

.hover\:border-indigo-darker:hover {
	border-color: #2f365f;
}

.hover\:border-indigo-dark:hover {
	border-color: #5661b3;
}

.hover\:border-indigo-light:hover {
	border-color: #7886d7;
}

.hover\:border-indigo-lighter:hover {
	border-color: #b2b7ff;
}

.hover\:border-indigo-lightest:hover {
	border-color: #e6e8ff;
}

.hover\:border-purple-darkest:hover {
	border-color: #21183c;
}

.hover\:border-purple-darker:hover {
	border-color: #382b5f;
}

.hover\:border-purple-dark:hover {
	border-color: #794acf;
}

.hover\:border-purple-light:hover {
	border-color: #a779e9;
}

.hover\:border-purple-lighter:hover {
	border-color: #d6bbfc;
}

.hover\:border-purple-lightest:hover {
	border-color: #f3ebff;
}

.hover\:border-pink-darkest:hover {
	border-color: #451225;
}

.hover\:border-pink-darker:hover {
	border-color: #6f213f;
}

.hover\:border-pink-dark:hover {
	border-color: #eb5286;
}

.hover\:border-pink-light:hover {
	border-color: #fa7ea8;
}

.hover\:border-pink-lighter:hover {
	border-color: #ffbbca;
}

.hover\:border-pink-lightest:hover {
	border-color: #ffebef;
}

.focus\:border-transparent:focus {
	border-color: transparent;
}

.focus\:border-black:focus {
	border-color: #22292f;
}

.focus\:border-white:focus {
	border-color: #ffffff;
}

.focus\:border-gray-100:focus {
	border-color: #f7fafc;
}

.focus\:border-gray-200:focus {
	border-color: #edf2f7;
}

.focus\:border-gray-300:focus {
	border-color: #e2e8f0;
}

.focus\:border-gray-400:focus {
	border-color: #cbd5e0;
}

.focus\:border-gray-500:focus {
	border-color: #a0aec0;
}

.focus\:border-gray-600:focus {
	border-color: #718096;
}

.focus\:border-gray-700:focus {
	border-color: #4a5568;
}

.focus\:border-gray-800:focus {
	border-color: #2d3748;
}

.focus\:border-gray-900:focus {
	border-color: #1a202c;
}

.focus\:border-red:focus {
	border-color: #e3342f;
}

.focus\:border-orange:focus {
	border-color: #f6993f;
}

.focus\:border-yellow:focus {
	border-color: #ffed4a;
}

.focus\:border-green:focus {
	border-color: #38c172;
}

.focus\:border-teal:focus {
	border-color: #4dc0b5;
}

.focus\:border-blue:focus {
	border-color: #3490dc;
}

.focus\:border-indigo:focus {
	border-color: #6574cd;
}

.focus\:border-purple:focus {
	border-color: #9561e2;
}

.focus\:border-pink:focus {
	border-color: #f66d9b;
}

.focus\:border-grey-darkest:focus {
	border-color: #3d4852;
}

.focus\:border-grey-darker:focus {
	border-color: #606f7b;
}

.focus\:border-grey-dark:focus {
	border-color: #8795a1;
}

.focus\:border-grey:focus {
	border-color: #b8c2cc;
}

.focus\:border-grey-light:focus {
	border-color: #dae1e7;
}

.focus\:border-grey-lighter:focus {
	border-color: #f1f5f8;
}

.focus\:border-grey-lightest:focus {
	border-color: #f8fafc;
}

.focus\:border-red-darkest:focus {
	border-color: #3b0d0c;
}

.focus\:border-red-darker:focus {
	border-color: #621b18;
}

.focus\:border-red-dark:focus {
	border-color: #cc1f1a;
}

.focus\:border-red-light:focus {
	border-color: #ef5753;
}

.focus\:border-red-lighter:focus {
	border-color: #f9acaa;
}

.focus\:border-red-lightest:focus {
	border-color: #fcebea;
}

.focus\:border-orange-darkest:focus {
	border-color: #462a16;
}

.focus\:border-orange-darker:focus {
	border-color: #613b1f;
}

.focus\:border-orange-dark:focus {
	border-color: #de751f;
}

.focus\:border-orange-light:focus {
	border-color: #faad63;
}

.focus\:border-orange-lighter:focus {
	border-color: #fcd9b6;
}

.focus\:border-orange-lightest:focus {
	border-color: #fff5eb;
}

.focus\:border-yellow-darkest:focus {
	border-color: #453411;
}

.focus\:border-yellow-darker:focus {
	border-color: #684f1d;
}

.focus\:border-yellow-dark:focus {
	border-color: #f2d024;
}

.focus\:border-yellow-light:focus {
	border-color: #fff382;
}

.focus\:border-yellow-lighter:focus {
	border-color: #fff9c2;
}

.focus\:border-yellow-lightest:focus {
	border-color: #fcfbeb;
}

.focus\:border-green-darkest:focus {
	border-color: #0f2f21;
}

.focus\:border-green-darker:focus {
	border-color: #1a4731;
}

.focus\:border-green-dark:focus {
	border-color: #1f9d55;
}

.focus\:border-green-light:focus {
	border-color: #51d88a;
}

.focus\:border-green-lighter:focus {
	border-color: #a2f5bf;
}

.focus\:border-green-lightest:focus {
	border-color: #e3fcec;
}

.focus\:border-teal-darkest:focus {
	border-color: #0d3331;
}

.focus\:border-teal-darker:focus {
	border-color: #20504f;
}

.focus\:border-teal-dark:focus {
	border-color: #38a89d;
}

.focus\:border-teal-light:focus {
	border-color: #64d5ca;
}

.focus\:border-teal-lighter:focus {
	border-color: #a0f0ed;
}

.focus\:border-teal-lightest:focus {
	border-color: #e8fffe;
}

.focus\:border-blue-darkest:focus {
	border-color: #12283a;
}

.focus\:border-blue-darker:focus {
	border-color: #1c3d5a;
}

.focus\:border-blue-dark:focus {
	border-color: #2779bd;
}

.focus\:border-blue-light:focus {
	border-color: #6cb2eb;
}

.focus\:border-blue-lighter:focus {
	border-color: #bcdefa;
}

.focus\:border-blue-lightest:focus {
	border-color: #eff8ff;
}

.focus\:border-indigo-darkest:focus {
	border-color: #191e38;
}

.focus\:border-indigo-darker:focus {
	border-color: #2f365f;
}

.focus\:border-indigo-dark:focus {
	border-color: #5661b3;
}

.focus\:border-indigo-light:focus {
	border-color: #7886d7;
}

.focus\:border-indigo-lighter:focus {
	border-color: #b2b7ff;
}

.focus\:border-indigo-lightest:focus {
	border-color: #e6e8ff;
}

.focus\:border-purple-darkest:focus {
	border-color: #21183c;
}

.focus\:border-purple-darker:focus {
	border-color: #382b5f;
}

.focus\:border-purple-dark:focus {
	border-color: #794acf;
}

.focus\:border-purple-light:focus {
	border-color: #a779e9;
}

.focus\:border-purple-lighter:focus {
	border-color: #d6bbfc;
}

.focus\:border-purple-lightest:focus {
	border-color: #f3ebff;
}

.focus\:border-pink-darkest:focus {
	border-color: #451225;
}

.focus\:border-pink-darker:focus {
	border-color: #6f213f;
}

.focus\:border-pink-dark:focus {
	border-color: #eb5286;
}

.focus\:border-pink-light:focus {
	border-color: #fa7ea8;
}

.focus\:border-pink-lighter:focus {
	border-color: #ffbbca;
}

.focus\:border-pink-lightest:focus {
	border-color: #ffebef;
}

.rounded-2 {
	border-radius: 0.2rem;
}

.rounded-4 {
	border-radius: 0.4rem;
}

.rounded-6 {
	border-radius: 0.6rem;
}

.rounded-8 {
	border-radius: 0.8rem;
}

.rounded-12 {
	border-radius: 1.2rem;
}

.rounded-16 {
	border-radius: 1.6rem;
}

.rounded-20 {
	border-radius: 2rem;
}

.rounded-24 {
	border-radius: 2.4rem;
}

.rounded-28 {
	border-radius: 2.8rem;
}

.rounded-32 {
	border-radius: 3.2rem;
}

.rounded-none {
	border-radius: 0;
}

.rounded-sm {
	border-radius: 0.2rem;
}

.rounded {
	border-radius: 0.4rem;
}

.rounded-lg {
	border-radius: 0.8rem;
}

.rounded-full {
	border-radius: 9999px;
}

.rounded-t-2 {
	border-top-left-radius: 0.2rem;
	border-top-right-radius: 0.2rem;
}

.rounded-r-2 {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.rounded-b-2 {
	border-bottom-right-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.rounded-l-2 {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.rounded-t-4 {
	border-top-left-radius: 0.4rem;
	border-top-right-radius: 0.4rem;
}

.rounded-r-4 {
	border-top-right-radius: 0.4rem;
	border-bottom-right-radius: 0.4rem;
}

.rounded-b-4 {
	border-bottom-right-radius: 0.4rem;
	border-bottom-left-radius: 0.4rem;
}

.rounded-l-4 {
	border-top-left-radius: 0.4rem;
	border-bottom-left-radius: 0.4rem;
}

.rounded-t-6 {
	border-top-left-radius: 0.6rem;
	border-top-right-radius: 0.6rem;
}

.rounded-r-6 {
	border-top-right-radius: 0.6rem;
	border-bottom-right-radius: 0.6rem;
}

.rounded-b-6 {
	border-bottom-right-radius: 0.6rem;
	border-bottom-left-radius: 0.6rem;
}

.rounded-l-6 {
	border-top-left-radius: 0.6rem;
	border-bottom-left-radius: 0.6rem;
}

.rounded-t-8 {
	border-top-left-radius: 0.8rem;
	border-top-right-radius: 0.8rem;
}

.rounded-r-8 {
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
}

.rounded-b-8 {
	border-bottom-right-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
}

.rounded-l-8 {
	border-top-left-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
}

.rounded-t-12 {
	border-top-left-radius: 1.2rem;
	border-top-right-radius: 1.2rem;
}

.rounded-r-12 {
	border-top-right-radius: 1.2rem;
	border-bottom-right-radius: 1.2rem;
}

.rounded-b-12 {
	border-bottom-right-radius: 1.2rem;
	border-bottom-left-radius: 1.2rem;
}

.rounded-l-12 {
	border-top-left-radius: 1.2rem;
	border-bottom-left-radius: 1.2rem;
}

.rounded-t-16 {
	border-top-left-radius: 1.6rem;
	border-top-right-radius: 1.6rem;
}

.rounded-r-16 {
	border-top-right-radius: 1.6rem;
	border-bottom-right-radius: 1.6rem;
}

.rounded-b-16 {
	border-bottom-right-radius: 1.6rem;
	border-bottom-left-radius: 1.6rem;
}

.rounded-l-16 {
	border-top-left-radius: 1.6rem;
	border-bottom-left-radius: 1.6rem;
}

.rounded-t-20 {
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}

.rounded-r-20 {
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
}

.rounded-b-20 {
	border-bottom-right-radius: 2rem;
	border-bottom-left-radius: 2rem;
}

.rounded-l-20 {
	border-top-left-radius: 2rem;
	border-bottom-left-radius: 2rem;
}

.rounded-t-24 {
	border-top-left-radius: 2.4rem;
	border-top-right-radius: 2.4rem;
}

.rounded-r-24 {
	border-top-right-radius: 2.4rem;
	border-bottom-right-radius: 2.4rem;
}

.rounded-b-24 {
	border-bottom-right-radius: 2.4rem;
	border-bottom-left-radius: 2.4rem;
}

.rounded-l-24 {
	border-top-left-radius: 2.4rem;
	border-bottom-left-radius: 2.4rem;
}

.rounded-t-28 {
	border-top-left-radius: 2.8rem;
	border-top-right-radius: 2.8rem;
}

.rounded-r-28 {
	border-top-right-radius: 2.8rem;
	border-bottom-right-radius: 2.8rem;
}

.rounded-b-28 {
	border-bottom-right-radius: 2.8rem;
	border-bottom-left-radius: 2.8rem;
}

.rounded-l-28 {
	border-top-left-radius: 2.8rem;
	border-bottom-left-radius: 2.8rem;
}

.rounded-t-32 {
	border-top-left-radius: 3.2rem;
	border-top-right-radius: 3.2rem;
}

.rounded-r-32 {
	border-top-right-radius: 3.2rem;
	border-bottom-right-radius: 3.2rem;
}

.rounded-b-32 {
	border-bottom-right-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
}

.rounded-l-32 {
	border-top-left-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
}

.rounded-t-none {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.rounded-r-none {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.rounded-b-none {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.rounded-l-none {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rounded-t-sm {
	border-top-left-radius: 0.2rem;
	border-top-right-radius: 0.2rem;
}

.rounded-r-sm {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.rounded-b-sm {
	border-bottom-right-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.rounded-l-sm {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.rounded-t {
	border-top-left-radius: 0.4rem;
	border-top-right-radius: 0.4rem;
}

.rounded-r {
	border-top-right-radius: 0.4rem;
	border-bottom-right-radius: 0.4rem;
}

.rounded-b {
	border-bottom-right-radius: 0.4rem;
	border-bottom-left-radius: 0.4rem;
}

.rounded-l {
	border-top-left-radius: 0.4rem;
	border-bottom-left-radius: 0.4rem;
}

.rounded-t-lg {
	border-top-left-radius: 0.8rem;
	border-top-right-radius: 0.8rem;
}

.rounded-r-lg {
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
}

.rounded-b-lg {
	border-bottom-right-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
}

.rounded-l-lg {
	border-top-left-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
}

.rounded-t-full {
	border-top-left-radius: 9999px;
	border-top-right-radius: 9999px;
}

.rounded-r-full {
	border-top-right-radius: 9999px;
	border-bottom-right-radius: 9999px;
}

.rounded-b-full {
	border-bottom-right-radius: 9999px;
	border-bottom-left-radius: 9999px;
}

.rounded-l-full {
	border-top-left-radius: 9999px;
	border-bottom-left-radius: 9999px;
}

.rounded-tl-2 {
	border-top-left-radius: 0.2rem;
}

.rounded-tr-2 {
	border-top-right-radius: 0.2rem;
}

.rounded-br-2 {
	border-bottom-right-radius: 0.2rem;
}

.rounded-bl-2 {
	border-bottom-left-radius: 0.2rem;
}

.rounded-tl-4 {
	border-top-left-radius: 0.4rem;
}

.rounded-tr-4 {
	border-top-right-radius: 0.4rem;
}

.rounded-br-4 {
	border-bottom-right-radius: 0.4rem;
}

.rounded-bl-4 {
	border-bottom-left-radius: 0.4rem;
}

.rounded-tl-6 {
	border-top-left-radius: 0.6rem;
}

.rounded-tr-6 {
	border-top-right-radius: 0.6rem;
}

.rounded-br-6 {
	border-bottom-right-radius: 0.6rem;
}

.rounded-bl-6 {
	border-bottom-left-radius: 0.6rem;
}

.rounded-tl-8 {
	border-top-left-radius: 0.8rem;
}

.rounded-tr-8 {
	border-top-right-radius: 0.8rem;
}

.rounded-br-8 {
	border-bottom-right-radius: 0.8rem;
}

.rounded-bl-8 {
	border-bottom-left-radius: 0.8rem;
}

.rounded-tl-12 {
	border-top-left-radius: 1.2rem;
}

.rounded-tr-12 {
	border-top-right-radius: 1.2rem;
}

.rounded-br-12 {
	border-bottom-right-radius: 1.2rem;
}

.rounded-bl-12 {
	border-bottom-left-radius: 1.2rem;
}

.rounded-tl-16 {
	border-top-left-radius: 1.6rem;
}

.rounded-tr-16 {
	border-top-right-radius: 1.6rem;
}

.rounded-br-16 {
	border-bottom-right-radius: 1.6rem;
}

.rounded-bl-16 {
	border-bottom-left-radius: 1.6rem;
}

.rounded-tl-20 {
	border-top-left-radius: 2rem;
}

.rounded-tr-20 {
	border-top-right-radius: 2rem;
}

.rounded-br-20 {
	border-bottom-right-radius: 2rem;
}

.rounded-bl-20 {
	border-bottom-left-radius: 2rem;
}

.rounded-tl-24 {
	border-top-left-radius: 2.4rem;
}

.rounded-tr-24 {
	border-top-right-radius: 2.4rem;
}

.rounded-br-24 {
	border-bottom-right-radius: 2.4rem;
}

.rounded-bl-24 {
	border-bottom-left-radius: 2.4rem;
}

.rounded-tl-28 {
	border-top-left-radius: 2.8rem;
}

.rounded-tr-28 {
	border-top-right-radius: 2.8rem;
}

.rounded-br-28 {
	border-bottom-right-radius: 2.8rem;
}

.rounded-bl-28 {
	border-bottom-left-radius: 2.8rem;
}

.rounded-tl-32 {
	border-top-left-radius: 3.2rem;
}

.rounded-tr-32 {
	border-top-right-radius: 3.2rem;
}

.rounded-br-32 {
	border-bottom-right-radius: 3.2rem;
}

.rounded-bl-32 {
	border-bottom-left-radius: 3.2rem;
}

.rounded-tl-none {
	border-top-left-radius: 0;
}

.rounded-tr-none {
	border-top-right-radius: 0;
}

.rounded-br-none {
	border-bottom-right-radius: 0;
}

.rounded-bl-none {
	border-bottom-left-radius: 0;
}

.rounded-tl-sm {
	border-top-left-radius: 0.2rem;
}

.rounded-tr-sm {
	border-top-right-radius: 0.2rem;
}

.rounded-br-sm {
	border-bottom-right-radius: 0.2rem;
}

.rounded-bl-sm {
	border-bottom-left-radius: 0.2rem;
}

.rounded-tl {
	border-top-left-radius: 0.4rem;
}

.rounded-tr {
	border-top-right-radius: 0.4rem;
}

.rounded-br {
	border-bottom-right-radius: 0.4rem;
}

.rounded-bl {
	border-bottom-left-radius: 0.4rem;
}

.rounded-tl-lg {
	border-top-left-radius: 0.8rem;
}

.rounded-tr-lg {
	border-top-right-radius: 0.8rem;
}

.rounded-br-lg {
	border-bottom-right-radius: 0.8rem;
}

.rounded-bl-lg {
	border-bottom-left-radius: 0.8rem;
}

.rounded-tl-full {
	border-top-left-radius: 9999px;
}

.rounded-tr-full {
	border-top-right-radius: 9999px;
}

.rounded-br-full {
	border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
	border-bottom-left-radius: 9999px;
}

.border-solid {
	border-style: solid;
}

.border-dashed {
	border-style: dashed;
}

.border-dotted {
	border-style: dotted;
}

.border-none {
	border-style: none;
}

.border-0 {
	border-width: 0;
}

.border-1 {
	border-width: 1px;
}

.border-2 {
	border-width: 2px;
}

.border-3 {
	border-width: 3px;
}

.border-4 {
	border-width: 4px;
}

.border-8 {
	border-width: 8px;
}

.border {
	border-width: 1px;
}

.border-t-0 {
	border-top-width: 0;
}

.border-r-0 {
	border-right-width: 0;
}

.border-b-0 {
	border-bottom-width: 0;
}

.border-l-0 {
	border-left-width: 0;
}

.border-t-1 {
	border-top-width: 1px;
}

.border-r-1 {
	border-right-width: 1px;
}

.border-b-1 {
	border-bottom-width: 1px;
}

.border-l-1 {
	border-left-width: 1px;
}

.border-t-2 {
	border-top-width: 2px;
}

.border-r-2 {
	border-right-width: 2px;
}

.border-b-2 {
	border-bottom-width: 2px;
}

.border-l-2 {
	border-left-width: 2px;
}

.border-t-3 {
	border-top-width: 3px;
}

.border-r-3 {
	border-right-width: 3px;
}

.border-b-3 {
	border-bottom-width: 3px;
}

.border-l-3 {
	border-left-width: 3px;
}

.border-t-4 {
	border-top-width: 4px;
}

.border-r-4 {
	border-right-width: 4px;
}

.border-b-4 {
	border-bottom-width: 4px;
}

.border-l-4 {
	border-left-width: 4px;
}

.border-t-8 {
	border-top-width: 8px;
}

.border-r-8 {
	border-right-width: 8px;
}

.border-b-8 {
	border-bottom-width: 8px;
}

.border-l-8 {
	border-left-width: 8px;
}

.border-t {
	border-top-width: 1px;
}

.border-r {
	border-right-width: 1px;
}

.border-b {
	border-bottom-width: 1px;
}

.border-l {
	border-left-width: 1px;
}

.cursor-auto {
	cursor: auto;
}

.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-wait {
	cursor: wait;
}

.cursor-text {
	cursor: text;
}

.cursor-move {
	cursor: move;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

.flex {
	display: -webkit-box;
	display: flex;
}

.inline-flex {
	display: -webkit-inline-box;
	display: inline-flex;
}

.table {
	display: table;
}

.table-row {
	display: table-row;
}

.table-cell {
	display: table-cell;
}

.hidden {
	display: none;
}

.hover\:block:hover {
	display: block;
}

.hover\:inline-block:hover {
	display: inline-block;
}

.hover\:inline:hover {
	display: inline;
}

.hover\:flex:hover {
	display: -webkit-box;
	display: flex;
}

.hover\:inline-flex:hover {
	display: -webkit-inline-box;
	display: inline-flex;
}

.hover\:table:hover {
	display: table;
}

.hover\:table-row:hover {
	display: table-row;
}

.hover\:table-cell:hover {
	display: table-cell;
}

.hover\:hidden:hover {
	display: none;
}

.focus\:block:focus {
	display: block;
}

.focus\:inline-block:focus {
	display: inline-block;
}

.focus\:inline:focus {
	display: inline;
}

.focus\:flex:focus {
	display: -webkit-box;
	display: flex;
}

.focus\:inline-flex:focus {
	display: -webkit-inline-box;
	display: inline-flex;
}

.focus\:table:focus {
	display: table;
}

.focus\:table-row:focus {
	display: table-row;
}

.focus\:table-cell:focus {
	display: table-cell;
}

.focus\:hidden:focus {
	display: none;
}

.flex-row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
}

.flex-row-reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	flex-direction: row-reverse;
}

.flex-col {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.flex-col-reverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	flex-direction: column-reverse;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.flex-no-wrap {
	flex-wrap: nowrap;
}

.items-start {
	-webkit-box-align: start;
	align-items: flex-start;
}

.items-end {
	-webkit-box-align: end;
	align-items: flex-end;
}

.items-center {
	-webkit-box-align: center;
	align-items: center;
}

.items-baseline {
	-webkit-box-align: baseline;
	align-items: baseline;
}

.items-stretch {
	-webkit-box-align: stretch;
	align-items: stretch;
}

.self-auto {
	align-self: auto;
}

.self-start {
	align-self: flex-start;
}

.self-end {
	align-self: flex-end;
}

.self-center {
	align-self: center;
}

.self-stretch {
	align-self: stretch;
}

.justify-start {
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.justify-end {
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.justify-center {
	-webkit-box-pack: center;
	justify-content: center;
}

.justify-between {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.content-center {
	align-content: center;
}

.content-start {
	align-content: flex-start;
}

.content-end {
	align-content: flex-end;
}

.content-between {
	align-content: space-between;
}

.content-around {
	align-content: space-around;
}

.flex-1 {
	-webkit-box-flex: 1;
	flex: 1 1 0%;
}

.flex-auto {
	-webkit-box-flex: 1;
	flex: 1 1 auto;
}

.flex-initial {
	-webkit-box-flex: 0;
	flex: 0 1 auto;
}

.flex-none {
	-webkit-box-flex: 0;
	flex: none;
}

.flex-grow-0 {
	-webkit-box-flex: 0;
	flex-grow: 0;
}

.flex-grow {
	-webkit-box-flex: 1;
	flex-grow: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.flex-shrink {
	flex-shrink: 1;
}

.order-1 {
	-webkit-box-ordinal-group: 2;
	order: 1;
}

.order-2 {
	-webkit-box-ordinal-group: 3;
	order: 2;
}

.order-3 {
	-webkit-box-ordinal-group: 4;
	order: 3;
}

.order-4 {
	-webkit-box-ordinal-group: 5;
	order: 4;
}

.order-5 {
	-webkit-box-ordinal-group: 6;
	order: 5;
}

.order-6 {
	-webkit-box-ordinal-group: 7;
	order: 6;
}

.order-7 {
	-webkit-box-ordinal-group: 8;
	order: 7;
}

.order-8 {
	-webkit-box-ordinal-group: 9;
	order: 8;
}

.order-9 {
	-webkit-box-ordinal-group: 10;
	order: 9;
}

.order-10 {
	-webkit-box-ordinal-group: 11;
	order: 10;
}

.order-11 {
	-webkit-box-ordinal-group: 12;
	order: 11;
}

.order-12 {
	-webkit-box-ordinal-group: 13;
	order: 12;
}

.order-first {
	-webkit-box-ordinal-group: -9998;
	order: -9999;
}

.order-last {
	-webkit-box-ordinal-group: 10000;
	order: 9999;
}

.order-none {
	-webkit-box-ordinal-group: 1;
	order: 0;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.float-none {
	float: none;
}

.clearfix:after {
	content: '';
	display: table;
	clear: both;
}

.font-sans {
	font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.font-serif {
	font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
		DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
}

.font-mono {
	font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-100 {
	font-weight: 100;
}

.font-200 {
	font-weight: 200;
}

.font-300 {
	font-weight: 300;
}

.font-400 {
	font-weight: 400;
}

.font-500 {
	font-weight: 500;
}

.font-600 {
	font-weight: 600;
}

.font-700 {
	font-weight: 700;
}

.font-800 {
	font-weight: 800;
}

.font-900 {
	font-weight: 900;
}

.font-hairline {
	font-weight: 100;
}

.font-thin {
	font-weight: 200;
}

.font-light {
	font-weight: 300;
}

.font-normal {
	font-weight: 400;
}

.font-medium {
	font-weight: 500;
}

.font-semibold {
	font-weight: 600;
}

.font-bold {
	font-weight: 700;
}

.font-extrabold {
	font-weight: 800;
}

.font-black {
	font-weight: 900;
}

.hover\:font-100:hover {
	font-weight: 100;
}

.hover\:font-200:hover {
	font-weight: 200;
}

.hover\:font-300:hover {
	font-weight: 300;
}

.hover\:font-400:hover {
	font-weight: 400;
}

.hover\:font-500:hover {
	font-weight: 500;
}

.hover\:font-600:hover {
	font-weight: 600;
}

.hover\:font-700:hover {
	font-weight: 700;
}

.hover\:font-800:hover {
	font-weight: 800;
}

.hover\:font-900:hover {
	font-weight: 900;
}

.hover\:font-hairline:hover {
	font-weight: 100;
}

.hover\:font-thin:hover {
	font-weight: 200;
}

.hover\:font-light:hover {
	font-weight: 300;
}

.hover\:font-normal:hover {
	font-weight: 400;
}

.hover\:font-medium:hover {
	font-weight: 500;
}

.hover\:font-semibold:hover {
	font-weight: 600;
}

.hover\:font-bold:hover {
	font-weight: 700;
}

.hover\:font-extrabold:hover {
	font-weight: 800;
}

.hover\:font-black:hover {
	font-weight: 900;
}

.focus\:font-100:focus {
	font-weight: 100;
}

.focus\:font-200:focus {
	font-weight: 200;
}

.focus\:font-300:focus {
	font-weight: 300;
}

.focus\:font-400:focus {
	font-weight: 400;
}

.focus\:font-500:focus {
	font-weight: 500;
}

.focus\:font-600:focus {
	font-weight: 600;
}

.focus\:font-700:focus {
	font-weight: 700;
}

.focus\:font-800:focus {
	font-weight: 800;
}

.focus\:font-900:focus {
	font-weight: 900;
}

.focus\:font-hairline:focus {
	font-weight: 100;
}

.focus\:font-thin:focus {
	font-weight: 200;
}

.focus\:font-light:focus {
	font-weight: 300;
}

.focus\:font-normal:focus {
	font-weight: 400;
}

.focus\:font-medium:focus {
	font-weight: 500;
}

.focus\:font-semibold:focus {
	font-weight: 600;
}

.focus\:font-bold:focus {
	font-weight: 700;
}

.focus\:font-extrabold:focus {
	font-weight: 800;
}

.focus\:font-black:focus {
	font-weight: 900;
}

.h-0 {
	height: 0;
}

.h-1 {
	height: 0.25rem;
}

.h-2 {
	height: 0.5rem;
}

.h-3 {
	height: 0.75rem;
}

.h-4 {
	height: 0.4rem;
}

.h-5 {
	height: 1.25rem;
}

.h-6 {
	height: 0.6rem;
}

.h-8 {
	height: 0.8rem;
}

.h-10 {
	height: 2.5rem;
}

.h-12 {
	height: 1.2rem;
}

.h-16 {
	height: 1.6rem;
}

.h-20 {
	height: 2rem;
}

.h-24 {
	height: 2.4rem;
}

.h-28 {
	height: 2.8rem;
}

.h-32 {
	height: 3.2rem;
}

.h-36 {
	height: 3.6rem;
}

.h-40 {
	height: 4rem;
}

.h-44 {
	height: 4.4rem;
}

.h-48 {
	height: 4.8rem;
}

.h-52 {
	height: 5.2rem;
}

.h-56 {
	height: 5.6rem;
}

.h-60 {
	height: 6rem;
}

.h-64 {
	height: 6.4rem;
}

.h-68 {
	height: 6.8rem;
}

.h-72 {
	height: 7.2rem;
}

.h-76 {
	height: 7.6rem;
}

.h-80 {
	height: 8rem;
}

.h-84 {
	height: 8.4rem;
}

.h-88 {
	height: 8.8rem;
}

.h-92 {
	height: 9.2rem;
}

.h-96 {
	height: 9.6rem;
}

.h-128 {
	height: 12.8rem;
}

.h-136 {
	height: 13.6rem;
}

.h-160 {
	height: 16rem;
}

.h-192 {
	height: 19.2rem;
}

.h-200 {
	height: 20rem;
}

.h-208 {
	height: 20.8rem;
}

.h-216 {
	height: 21.6rem;
}

.h-224 {
	height: 22.4rem;
}

.h-256 {
	height: 25.6rem;
}

.h-288 {
	height: 28.8rem;
}

.h-320 {
	height: 32rem;
}

.h-360 {
	height: 36rem;
}

.h-384 {
	height: 38.4rem;
}

.h-400 {
	height: 40rem;
}

.h-512 {
	height: 51.2rem;
}

.h-640 {
	height: 64rem;
}

.h-auto {
	height: auto;
}

.h-px {
	height: 1px;
}

.h-xs {
	height: 32rem;
}

.h-sm {
	height: 48rem;
}

.h-md {
	height: 64rem;
}

.h-lg {
	height: 80rem;
}

.h-xl {
	height: 96rem;
}

.h-2xl {
	height: 112rem;
}

.h-3xl {
	height: 128rem;
}

.h-4xl {
	height: 144rem;
}

.h-5xl {
	height: 160rem;
}

.h-full {
	height: 100%;
}

.h-screen {
	height: 100vh;
}

.leading-none {
	line-height: 1;
}

.leading-tight {
	line-height: 1.25;
}

.leading-snug {
	line-height: 1.375;
}

.leading-normal {
	line-height: 1.5;
}

.leading-relaxed {
	line-height: 1.625;
}

.leading-loose {
	line-height: 2;
}

.list-inside {
	list-style-position: inside;
}

.list-outside {
	list-style-position: outside;
}

.list-none {
	list-style-type: none;
}

.list-disc {
	list-style-type: disc;
}

.list-decimal {
	list-style-type: decimal;
}

.m-0 {
	margin: 0;
}

.m-1 {
	margin: 0.25rem;
}

.m-2 {
	margin: 0.5rem;
}

.m-3 {
	margin: 0.75rem;
}

.m-4 {
	margin: 0.4rem;
}

.m-5 {
	margin: 1.25rem;
}

.m-6 {
	margin: 0.6rem;
}

.m-8 {
	margin: 0.8rem;
}

.m-10 {
	margin: 2.5rem;
}

.m-12 {
	margin: 1.2rem;
}

.m-16 {
	margin: 1.6rem;
}

.m-20 {
	margin: 2rem;
}

.m-24 {
	margin: 2.4rem;
}

.m-28 {
	margin: 2.8rem;
}

.m-32 {
	margin: 3.2rem;
}

.m-36 {
	margin: 3.6rem;
}

.m-40 {
	margin: 4rem;
}

.m-44 {
	margin: 4.4rem;
}

.m-48 {
	margin: 4.8rem;
}

.m-52 {
	margin: 5.2rem;
}

.m-56 {
	margin: 5.6rem;
}

.m-60 {
	margin: 6rem;
}

.m-64 {
	margin: 6.4rem;
}

.m-68 {
	margin: 6.8rem;
}

.m-72 {
	margin: 7.2rem;
}

.m-76 {
	margin: 7.6rem;
}

.m-80 {
	margin: 8rem;
}

.m-84 {
	margin: 8.4rem;
}

.m-88 {
	margin: 8.8rem;
}

.m-92 {
	margin: 9.2rem;
}

.m-96 {
	margin: 9.6rem;
}

.m-128 {
	margin: 12.8rem;
}

.m-136 {
	margin: 13.6rem;
}

.m-160 {
	margin: 16rem;
}

.m-192 {
	margin: 19.2rem;
}

.m-200 {
	margin: 20rem;
}

.m-208 {
	margin: 20.8rem;
}

.m-216 {
	margin: 21.6rem;
}

.m-224 {
	margin: 22.4rem;
}

.m-256 {
	margin: 25.6rem;
}

.m-288 {
	margin: 28.8rem;
}

.m-320 {
	margin: 32rem;
}

.m-360 {
	margin: 36rem;
}

.m-384 {
	margin: 38.4rem;
}

.m-400 {
	margin: 40rem;
}

.m-512 {
	margin: 51.2rem;
}

.m-640 {
	margin: 64rem;
}

.m-auto {
	margin: auto;
}

.m-px {
	margin: 1px;
}

.m-xs {
	margin: 32rem;
}

.m-sm {
	margin: 48rem;
}

.m-md {
	margin: 64rem;
}

.m-lg {
	margin: 80rem;
}

.m-xl {
	margin: 96rem;
}

.m-2xl {
	margin: 112rem;
}

.m-3xl {
	margin: 128rem;
}

.m-4xl {
	margin: 144rem;
}

.m-5xl {
	margin: 160rem;
}

.-m-1 {
	margin: -0.25rem;
}

.-m-2 {
	margin: -0.5rem;
}

.-m-3 {
	margin: -0.75rem;
}

.-m-4 {
	margin: -0.4rem;
}

.-m-5 {
	margin: -1.25rem;
}

.-m-6 {
	margin: -0.6rem;
}

.-m-8 {
	margin: -0.8rem;
}

.-m-10 {
	margin: -2.5rem;
}

.-m-12 {
	margin: -1.2rem;
}

.-m-16 {
	margin: -1.6rem;
}

.-m-20 {
	margin: -2rem;
}

.-m-24 {
	margin: -2.4rem;
}

.-m-28 {
	margin: -2.8rem;
}

.-m-32 {
	margin: -3.2rem;
}

.-m-36 {
	margin: -3.6rem;
}

.-m-40 {
	margin: -4rem;
}

.-m-44 {
	margin: -4.4rem;
}

.-m-48 {
	margin: -4.8rem;
}

.-m-52 {
	margin: -5.2rem;
}

.-m-56 {
	margin: -5.6rem;
}

.-m-60 {
	margin: -6rem;
}

.-m-64 {
	margin: -6.4rem;
}

.-m-68 {
	margin: -6.8rem;
}

.-m-72 {
	margin: -7.2rem;
}

.-m-76 {
	margin: -7.6rem;
}

.-m-80 {
	margin: -8rem;
}

.-m-84 {
	margin: -8.4rem;
}

.-m-88 {
	margin: -8.8rem;
}

.-m-92 {
	margin: -9.2rem;
}

.-m-96 {
	margin: -9.6rem;
}

.-m-128 {
	margin: -12.8rem;
}

.-m-136 {
	margin: -13.6rem;
}

.-m-160 {
	margin: -16rem;
}

.-m-192 {
	margin: -19.2rem;
}

.-m-200 {
	margin: -20rem;
}

.-m-208 {
	margin: -20.8rem;
}

.-m-216 {
	margin: -21.6rem;
}

.-m-224 {
	margin: -22.4rem;
}

.-m-256 {
	margin: -25.6rem;
}

.-m-288 {
	margin: -28.8rem;
}

.-m-320 {
	margin: -32rem;
}

.-m-360 {
	margin: -36rem;
}

.-m-384 {
	margin: -38.4rem;
}

.-m-400 {
	margin: -40rem;
}

.-m-512 {
	margin: -51.2rem;
}

.-m-640 {
	margin: -64rem;
}

.-m-px {
	margin: -1px;
}

.-m-xs {
	margin: -32rem;
}

.-m-sm {
	margin: -48rem;
}

.-m-md {
	margin: -64rem;
}

.-m-lg {
	margin: -80rem;
}

.-m-xl {
	margin: -96rem;
}

.-m-2xl {
	margin: -112rem;
}

.-m-3xl {
	margin: -128rem;
}

.-m-4xl {
	margin: -144rem;
}

.-m-5xl {
	margin: -160rem;
}

.-m-auto {
	margin: -auto;
}

.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.mx-3 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}

.my-4 {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
}

.mx-4 {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
}

.my-5 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.mx-5 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}

.my-6 {
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;
}

.mx-6 {
	margin-left: 0.6rem;
	margin-right: 0.6rem;
}

.my-8 {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
}

.mx-8 {
	margin-left: 0.8rem;
	margin-right: 0.8rem;
}

.my-10 {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}

.mx-10 {
	margin-left: 2.5rem;
	margin-right: 2.5rem;
}

.my-12 {
	margin-top: 1.2rem;
	margin-bottom: 1.2rem;
}

.mx-12 {
	margin-left: 1.2rem;
	margin-right: 1.2rem;
}

.my-16 {
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
}

.mx-16 {
	margin-left: 1.6rem;
	margin-right: 1.6rem;
}

.my-20 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.mx-20 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.my-24 {
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
}

.mx-24 {
	margin-left: 2.4rem;
	margin-right: 2.4rem;
}

.my-28 {
	margin-top: 2.8rem;
	margin-bottom: 2.8rem;
}

.mx-28 {
	margin-left: 2.8rem;
	margin-right: 2.8rem;
}

.my-32 {
	margin-top: 3.2rem;
	margin-bottom: 3.2rem;
}

.mx-32 {
	margin-left: 3.2rem;
	margin-right: 3.2rem;
}

.my-36 {
	margin-top: 3.6rem;
	margin-bottom: 3.6rem;
}

.mx-36 {
	margin-left: 3.6rem;
	margin-right: 3.6rem;
}

.my-40 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.mx-40 {
	margin-left: 4rem;
	margin-right: 4rem;
}

.my-44 {
	margin-top: 4.4rem;
	margin-bottom: 4.4rem;
}

.mx-44 {
	margin-left: 4.4rem;
	margin-right: 4.4rem;
}

.my-48 {
	margin-top: 4.8rem;
	margin-bottom: 4.8rem;
}

.mx-48 {
	margin-left: 4.8rem;
	margin-right: 4.8rem;
}

.my-52 {
	margin-top: 5.2rem;
	margin-bottom: 5.2rem;
}

.mx-52 {
	margin-left: 5.2rem;
	margin-right: 5.2rem;
}

.my-56 {
	margin-top: 5.6rem;
	margin-bottom: 5.6rem;
}

.mx-56 {
	margin-left: 5.6rem;
	margin-right: 5.6rem;
}

.my-60 {
	margin-top: 6rem;
	margin-bottom: 6rem;
}

.mx-60 {
	margin-left: 6rem;
	margin-right: 6rem;
}

.my-64 {
	margin-top: 6.4rem;
	margin-bottom: 6.4rem;
}

.mx-64 {
	margin-left: 6.4rem;
	margin-right: 6.4rem;
}

.my-68 {
	margin-top: 6.8rem;
	margin-bottom: 6.8rem;
}

.mx-68 {
	margin-left: 6.8rem;
	margin-right: 6.8rem;
}

.my-72 {
	margin-top: 7.2rem;
	margin-bottom: 7.2rem;
}

.mx-72 {
	margin-left: 7.2rem;
	margin-right: 7.2rem;
}

.my-76 {
	margin-top: 7.6rem;
	margin-bottom: 7.6rem;
}

.mx-76 {
	margin-left: 7.6rem;
	margin-right: 7.6rem;
}

.my-80 {
	margin-top: 8rem;
	margin-bottom: 8rem;
}

.mx-80 {
	margin-left: 8rem;
	margin-right: 8rem;
}

.my-84 {
	margin-top: 8.4rem;
	margin-bottom: 8.4rem;
}

.mx-84 {
	margin-left: 8.4rem;
	margin-right: 8.4rem;
}

.my-88 {
	margin-top: 8.8rem;
	margin-bottom: 8.8rem;
}

.mx-88 {
	margin-left: 8.8rem;
	margin-right: 8.8rem;
}

.my-92 {
	margin-top: 9.2rem;
	margin-bottom: 9.2rem;
}

.mx-92 {
	margin-left: 9.2rem;
	margin-right: 9.2rem;
}

.my-96 {
	margin-top: 9.6rem;
	margin-bottom: 9.6rem;
}

.mx-96 {
	margin-left: 9.6rem;
	margin-right: 9.6rem;
}

.my-128 {
	margin-top: 12.8rem;
	margin-bottom: 12.8rem;
}

.mx-128 {
	margin-left: 12.8rem;
	margin-right: 12.8rem;
}

.my-136 {
	margin-top: 13.6rem;
	margin-bottom: 13.6rem;
}

.mx-136 {
	margin-left: 13.6rem;
	margin-right: 13.6rem;
}

.my-160 {
	margin-top: 16rem;
	margin-bottom: 16rem;
}

.mx-160 {
	margin-left: 16rem;
	margin-right: 16rem;
}

.my-192 {
	margin-top: 19.2rem;
	margin-bottom: 19.2rem;
}

.mx-192 {
	margin-left: 19.2rem;
	margin-right: 19.2rem;
}

.my-200 {
	margin-top: 20rem;
	margin-bottom: 20rem;
}

.mx-200 {
	margin-left: 20rem;
	margin-right: 20rem;
}

.my-208 {
	margin-top: 20.8rem;
	margin-bottom: 20.8rem;
}

.mx-208 {
	margin-left: 20.8rem;
	margin-right: 20.8rem;
}

.my-216 {
	margin-top: 21.6rem;
	margin-bottom: 21.6rem;
}

.mx-216 {
	margin-left: 21.6rem;
	margin-right: 21.6rem;
}

.my-224 {
	margin-top: 22.4rem;
	margin-bottom: 22.4rem;
}

.mx-224 {
	margin-left: 22.4rem;
	margin-right: 22.4rem;
}

.my-256 {
	margin-top: 25.6rem;
	margin-bottom: 25.6rem;
}

.mx-256 {
	margin-left: 25.6rem;
	margin-right: 25.6rem;
}

.my-288 {
	margin-top: 28.8rem;
	margin-bottom: 28.8rem;
}

.mx-288 {
	margin-left: 28.8rem;
	margin-right: 28.8rem;
}

.my-320 {
	margin-top: 32rem;
	margin-bottom: 32rem;
}

.mx-320 {
	margin-left: 32rem;
	margin-right: 32rem;
}

.my-360 {
	margin-top: 36rem;
	margin-bottom: 36rem;
}

.mx-360 {
	margin-left: 36rem;
	margin-right: 36rem;
}

.my-384 {
	margin-top: 38.4rem;
	margin-bottom: 38.4rem;
}

.mx-384 {
	margin-left: 38.4rem;
	margin-right: 38.4rem;
}

.my-400 {
	margin-top: 40rem;
	margin-bottom: 40rem;
}

.mx-400 {
	margin-left: 40rem;
	margin-right: 40rem;
}

.my-512 {
	margin-top: 51.2rem;
	margin-bottom: 51.2rem;
}

.mx-512 {
	margin-left: 51.2rem;
	margin-right: 51.2rem;
}

.my-640 {
	margin-top: 64rem;
	margin-bottom: 64rem;
}

.mx-640 {
	margin-left: 64rem;
	margin-right: 64rem;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-px {
	margin-top: 1px;
	margin-bottom: 1px;
}

.mx-px {
	margin-left: 1px;
	margin-right: 1px;
}

.my-xs {
	margin-top: 32rem;
	margin-bottom: 32rem;
}

.mx-xs {
	margin-left: 32rem;
	margin-right: 32rem;
}

.my-sm {
	margin-top: 48rem;
	margin-bottom: 48rem;
}

.mx-sm {
	margin-left: 48rem;
	margin-right: 48rem;
}

.my-md {
	margin-top: 64rem;
	margin-bottom: 64rem;
}

.mx-md {
	margin-left: 64rem;
	margin-right: 64rem;
}

.my-lg {
	margin-top: 80rem;
	margin-bottom: 80rem;
}

.mx-lg {
	margin-left: 80rem;
	margin-right: 80rem;
}

.my-xl {
	margin-top: 96rem;
	margin-bottom: 96rem;
}

.mx-xl {
	margin-left: 96rem;
	margin-right: 96rem;
}

.my-2xl {
	margin-top: 112rem;
	margin-bottom: 112rem;
}

.mx-2xl {
	margin-left: 112rem;
	margin-right: 112rem;
}

.my-3xl {
	margin-top: 128rem;
	margin-bottom: 128rem;
}

.mx-3xl {
	margin-left: 128rem;
	margin-right: 128rem;
}

.my-4xl {
	margin-top: 144rem;
	margin-bottom: 144rem;
}

.mx-4xl {
	margin-left: 144rem;
	margin-right: 144rem;
}

.my-5xl {
	margin-top: 160rem;
	margin-bottom: 160rem;
}

.mx-5xl {
	margin-left: 160rem;
	margin-right: 160rem;
}

.-my-1 {
	margin-top: -0.25rem;
	margin-bottom: -0.25rem;
}

.-mx-1 {
	margin-left: -0.25rem;
	margin-right: -0.25rem;
}

.-my-2 {
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
}

.-mx-2 {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.-my-3 {
	margin-top: -0.75rem;
	margin-bottom: -0.75rem;
}

.-mx-3 {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
}

.-my-4 {
	margin-top: -0.4rem;
	margin-bottom: -0.4rem;
}

.-mx-4 {
	margin-left: -0.4rem;
	margin-right: -0.4rem;
}

.-my-5 {
	margin-top: -1.25rem;
	margin-bottom: -1.25rem;
}

.-mx-5 {
	margin-left: -1.25rem;
	margin-right: -1.25rem;
}

.-my-6 {
	margin-top: -0.6rem;
	margin-bottom: -0.6rem;
}

.-mx-6 {
	margin-left: -0.6rem;
	margin-right: -0.6rem;
}

.-my-8 {
	margin-top: -0.8rem;
	margin-bottom: -0.8rem;
}

.-mx-8 {
	margin-left: -0.8rem;
	margin-right: -0.8rem;
}

.-my-10 {
	margin-top: -2.5rem;
	margin-bottom: -2.5rem;
}

.-mx-10 {
	margin-left: -2.5rem;
	margin-right: -2.5rem;
}

.-my-12 {
	margin-top: -1.2rem;
	margin-bottom: -1.2rem;
}

.-mx-12 {
	margin-left: -1.2rem;
	margin-right: -1.2rem;
}

.-my-16 {
	margin-top: -1.6rem;
	margin-bottom: -1.6rem;
}

.-mx-16 {
	margin-left: -1.6rem;
	margin-right: -1.6rem;
}

.-my-20 {
	margin-top: -2rem;
	margin-bottom: -2rem;
}

.-mx-20 {
	margin-left: -2rem;
	margin-right: -2rem;
}

.-my-24 {
	margin-top: -2.4rem;
	margin-bottom: -2.4rem;
}

.-mx-24 {
	margin-left: -2.4rem;
	margin-right: -2.4rem;
}

.-my-28 {
	margin-top: -2.8rem;
	margin-bottom: -2.8rem;
}

.-mx-28 {
	margin-left: -2.8rem;
	margin-right: -2.8rem;
}

.-my-32 {
	margin-top: -3.2rem;
	margin-bottom: -3.2rem;
}

.-mx-32 {
	margin-left: -3.2rem;
	margin-right: -3.2rem;
}

.-my-36 {
	margin-top: -3.6rem;
	margin-bottom: -3.6rem;
}

.-mx-36 {
	margin-left: -3.6rem;
	margin-right: -3.6rem;
}

.-my-40 {
	margin-top: -4rem;
	margin-bottom: -4rem;
}

.-mx-40 {
	margin-left: -4rem;
	margin-right: -4rem;
}

.-my-44 {
	margin-top: -4.4rem;
	margin-bottom: -4.4rem;
}

.-mx-44 {
	margin-left: -4.4rem;
	margin-right: -4.4rem;
}

.-my-48 {
	margin-top: -4.8rem;
	margin-bottom: -4.8rem;
}

.-mx-48 {
	margin-left: -4.8rem;
	margin-right: -4.8rem;
}

.-my-52 {
	margin-top: -5.2rem;
	margin-bottom: -5.2rem;
}

.-mx-52 {
	margin-left: -5.2rem;
	margin-right: -5.2rem;
}

.-my-56 {
	margin-top: -5.6rem;
	margin-bottom: -5.6rem;
}

.-mx-56 {
	margin-left: -5.6rem;
	margin-right: -5.6rem;
}

.-my-60 {
	margin-top: -6rem;
	margin-bottom: -6rem;
}

.-mx-60 {
	margin-left: -6rem;
	margin-right: -6rem;
}

.-my-64 {
	margin-top: -6.4rem;
	margin-bottom: -6.4rem;
}

.-mx-64 {
	margin-left: -6.4rem;
	margin-right: -6.4rem;
}

.-my-68 {
	margin-top: -6.8rem;
	margin-bottom: -6.8rem;
}

.-mx-68 {
	margin-left: -6.8rem;
	margin-right: -6.8rem;
}

.-my-72 {
	margin-top: -7.2rem;
	margin-bottom: -7.2rem;
}

.-mx-72 {
	margin-left: -7.2rem;
	margin-right: -7.2rem;
}

.-my-76 {
	margin-top: -7.6rem;
	margin-bottom: -7.6rem;
}

.-mx-76 {
	margin-left: -7.6rem;
	margin-right: -7.6rem;
}

.-my-80 {
	margin-top: -8rem;
	margin-bottom: -8rem;
}

.-mx-80 {
	margin-left: -8rem;
	margin-right: -8rem;
}

.-my-84 {
	margin-top: -8.4rem;
	margin-bottom: -8.4rem;
}

.-mx-84 {
	margin-left: -8.4rem;
	margin-right: -8.4rem;
}

.-my-88 {
	margin-top: -8.8rem;
	margin-bottom: -8.8rem;
}

.-mx-88 {
	margin-left: -8.8rem;
	margin-right: -8.8rem;
}

.-my-92 {
	margin-top: -9.2rem;
	margin-bottom: -9.2rem;
}

.-mx-92 {
	margin-left: -9.2rem;
	margin-right: -9.2rem;
}

.-my-96 {
	margin-top: -9.6rem;
	margin-bottom: -9.6rem;
}

.-mx-96 {
	margin-left: -9.6rem;
	margin-right: -9.6rem;
}

.-my-128 {
	margin-top: -12.8rem;
	margin-bottom: -12.8rem;
}

.-mx-128 {
	margin-left: -12.8rem;
	margin-right: -12.8rem;
}

.-my-136 {
	margin-top: -13.6rem;
	margin-bottom: -13.6rem;
}

.-mx-136 {
	margin-left: -13.6rem;
	margin-right: -13.6rem;
}

.-my-160 {
	margin-top: -16rem;
	margin-bottom: -16rem;
}

.-mx-160 {
	margin-left: -16rem;
	margin-right: -16rem;
}

.-my-192 {
	margin-top: -19.2rem;
	margin-bottom: -19.2rem;
}

.-mx-192 {
	margin-left: -19.2rem;
	margin-right: -19.2rem;
}

.-my-200 {
	margin-top: -20rem;
	margin-bottom: -20rem;
}

.-mx-200 {
	margin-left: -20rem;
	margin-right: -20rem;
}

.-my-208 {
	margin-top: -20.8rem;
	margin-bottom: -20.8rem;
}

.-mx-208 {
	margin-left: -20.8rem;
	margin-right: -20.8rem;
}

.-my-216 {
	margin-top: -21.6rem;
	margin-bottom: -21.6rem;
}

.-mx-216 {
	margin-left: -21.6rem;
	margin-right: -21.6rem;
}

.-my-224 {
	margin-top: -22.4rem;
	margin-bottom: -22.4rem;
}

.-mx-224 {
	margin-left: -22.4rem;
	margin-right: -22.4rem;
}

.-my-256 {
	margin-top: -25.6rem;
	margin-bottom: -25.6rem;
}

.-mx-256 {
	margin-left: -25.6rem;
	margin-right: -25.6rem;
}

.-my-288 {
	margin-top: -28.8rem;
	margin-bottom: -28.8rem;
}

.-mx-288 {
	margin-left: -28.8rem;
	margin-right: -28.8rem;
}

.-my-320 {
	margin-top: -32rem;
	margin-bottom: -32rem;
}

.-mx-320 {
	margin-left: -32rem;
	margin-right: -32rem;
}

.-my-360 {
	margin-top: -36rem;
	margin-bottom: -36rem;
}

.-mx-360 {
	margin-left: -36rem;
	margin-right: -36rem;
}

.-my-384 {
	margin-top: -38.4rem;
	margin-bottom: -38.4rem;
}

.-mx-384 {
	margin-left: -38.4rem;
	margin-right: -38.4rem;
}

.-my-400 {
	margin-top: -40rem;
	margin-bottom: -40rem;
}

.-mx-400 {
	margin-left: -40rem;
	margin-right: -40rem;
}

.-my-512 {
	margin-top: -51.2rem;
	margin-bottom: -51.2rem;
}

.-mx-512 {
	margin-left: -51.2rem;
	margin-right: -51.2rem;
}

.-my-640 {
	margin-top: -64rem;
	margin-bottom: -64rem;
}

.-mx-640 {
	margin-left: -64rem;
	margin-right: -64rem;
}

.-my-px {
	margin-top: -1px;
	margin-bottom: -1px;
}

.-mx-px {
	margin-left: -1px;
	margin-right: -1px;
}

.-my-xs {
	margin-top: -32rem;
	margin-bottom: -32rem;
}

.-mx-xs {
	margin-left: -32rem;
	margin-right: -32rem;
}

.-my-sm {
	margin-top: -48rem;
	margin-bottom: -48rem;
}

.-mx-sm {
	margin-left: -48rem;
	margin-right: -48rem;
}

.-my-md {
	margin-top: -64rem;
	margin-bottom: -64rem;
}

.-mx-md {
	margin-left: -64rem;
	margin-right: -64rem;
}

.-my-lg {
	margin-top: -80rem;
	margin-bottom: -80rem;
}

.-mx-lg {
	margin-left: -80rem;
	margin-right: -80rem;
}

.-my-xl {
	margin-top: -96rem;
	margin-bottom: -96rem;
}

.-mx-xl {
	margin-left: -96rem;
	margin-right: -96rem;
}

.-my-2xl {
	margin-top: -112rem;
	margin-bottom: -112rem;
}

.-mx-2xl {
	margin-left: -112rem;
	margin-right: -112rem;
}

.-my-3xl {
	margin-top: -128rem;
	margin-bottom: -128rem;
}

.-mx-3xl {
	margin-left: -128rem;
	margin-right: -128rem;
}

.-my-4xl {
	margin-top: -144rem;
	margin-bottom: -144rem;
}

.-mx-4xl {
	margin-left: -144rem;
	margin-right: -144rem;
}

.-my-5xl {
	margin-top: -160rem;
	margin-bottom: -160rem;
}

.-mx-5xl {
	margin-left: -160rem;
	margin-right: -160rem;
}

.-my-auto {
	margin-top: -auto;
	margin-bottom: -auto;
}

.-mx-auto {
	margin-left: -auto;
	margin-right: -auto;
}

.mt-0 {
	margin-top: 0;
}

.mr-0 {
	margin-right: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.ml-0 {
	margin-left: 0;
}

.mt-1 {
	margin-top: 0.25rem;
}

.mr-1 {
	margin-right: 0.25rem;
}

.mb-1 {
	margin-bottom: 0.25rem;
}

.ml-1 {
	margin-left: 0.25rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mr-2 {
	margin-right: 0.5rem;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.mr-3 {
	margin-right: 0.75rem;
}

.mb-3 {
	margin-bottom: 0.75rem;
}

.ml-3 {
	margin-left: 0.75rem;
}

.mt-4 {
	margin-top: 0.4rem;
}

.mr-4 {
	margin-right: 0.4rem;
}

.mb-4 {
	margin-bottom: 0.4rem;
}

.ml-4 {
	margin-left: 0.4rem;
}

.mt-5 {
	margin-top: 1.25rem;
}

.mr-5 {
	margin-right: 1.25rem;
}

.mb-5 {
	margin-bottom: 1.25rem;
}

.ml-5 {
	margin-left: 1.25rem;
}

.mt-6 {
	margin-top: 0.6rem;
}

.mr-6 {
	margin-right: 0.6rem;
}

.mb-6 {
	margin-bottom: 0.6rem;
}

.ml-6 {
	margin-left: 0.6rem;
}

.mt-8 {
	margin-top: 0.8rem;
}

.mr-8 {
	margin-right: 0.8rem;
}

.mb-8 {
	margin-bottom: 0.8rem;
}

.ml-8 {
	margin-left: 0.8rem;
}

.mt-10 {
	margin-top: 2.5rem;
}

.mr-10 {
	margin-right: 2.5rem;
}

.mb-10 {
	margin-bottom: 2.5rem;
}

.ml-10 {
	margin-left: 2.5rem;
}

.mt-12 {
	margin-top: 1.2rem;
}

.mr-12 {
	margin-right: 1.2rem;
}

.mb-12 {
	margin-bottom: 1.2rem;
}

.ml-12 {
	margin-left: 1.2rem;
}

.mt-16 {
	margin-top: 1.6rem;
}

.mr-16 {
	margin-right: 1.6rem;
}

.mb-16 {
	margin-bottom: 1.6rem;
}

.ml-16 {
	margin-left: 1.6rem;
}

.mt-20 {
	margin-top: 2rem;
}

.mr-20 {
	margin-right: 2rem;
}

.mb-20 {
	margin-bottom: 2rem;
}

.ml-20 {
	margin-left: 2rem;
}

.mt-24 {
	margin-top: 2.4rem;
}

.mr-24 {
	margin-right: 2.4rem;
}

.mb-24 {
	margin-bottom: 2.4rem;
}

.ml-24 {
	margin-left: 2.4rem;
}

.mt-28 {
	margin-top: 2.8rem;
}

.mr-28 {
	margin-right: 2.8rem;
}

.mb-28 {
	margin-bottom: 2.8rem;
}

.ml-28 {
	margin-left: 2.8rem;
}

.mt-32 {
	margin-top: 3.2rem;
}

.mr-32 {
	margin-right: 3.2rem;
}

.mb-32 {
	margin-bottom: 3.2rem;
}

.ml-32 {
	margin-left: 3.2rem;
}

.mt-36 {
	margin-top: 3.6rem;
}

.mr-36 {
	margin-right: 3.6rem;
}

.mb-36 {
	margin-bottom: 3.6rem;
}

.ml-36 {
	margin-left: 3.6rem;
}

.mt-40 {
	margin-top: 4rem;
}

.mr-40 {
	margin-right: 4rem;
}

.mb-40 {
	margin-bottom: 4rem;
}

.ml-40 {
	margin-left: 4rem;
}

.mt-44 {
	margin-top: 4.4rem;
}

.mr-44 {
	margin-right: 4.4rem;
}

.mb-44 {
	margin-bottom: 4.4rem;
}

.ml-44 {
	margin-left: 4.4rem;
}

.mt-48 {
	margin-top: 4.8rem;
}

.mr-48 {
	margin-right: 4.8rem;
}

.mb-48 {
	margin-bottom: 4.8rem;
}

.ml-48 {
	margin-left: 4.8rem;
}

.mt-52 {
	margin-top: 5.2rem;
}

.mr-52 {
	margin-right: 5.2rem;
}

.mb-52 {
	margin-bottom: 5.2rem;
}

.ml-52 {
	margin-left: 5.2rem;
}

.mt-56 {
	margin-top: 5.6rem;
}

.mr-56 {
	margin-right: 5.6rem;
}

.mb-56 {
	margin-bottom: 5.6rem;
}

.ml-56 {
	margin-left: 5.6rem;
}

.mt-60 {
	margin-top: 6rem;
}

.mr-60 {
	margin-right: 6rem;
}

.mb-60 {
	margin-bottom: 6rem;
}

.ml-60 {
	margin-left: 6rem;
}

.mt-64 {
	margin-top: 6.4rem;
}

.mr-64 {
	margin-right: 6.4rem;
}

.mb-64 {
	margin-bottom: 6.4rem;
}

.ml-64 {
	margin-left: 6.4rem;
}

.mt-68 {
	margin-top: 6.8rem;
}

.mr-68 {
	margin-right: 6.8rem;
}

.mb-68 {
	margin-bottom: 6.8rem;
}

.ml-68 {
	margin-left: 6.8rem;
}

.mt-72 {
	margin-top: 7.2rem;
}

.mr-72 {
	margin-right: 7.2rem;
}

.mb-72 {
	margin-bottom: 7.2rem;
}

.ml-72 {
	margin-left: 7.2rem;
}

.mt-76 {
	margin-top: 7.6rem;
}

.mr-76 {
	margin-right: 7.6rem;
}

.mb-76 {
	margin-bottom: 7.6rem;
}

.ml-76 {
	margin-left: 7.6rem;
}

.mt-80 {
	margin-top: 8rem;
}

.mr-80 {
	margin-right: 8rem;
}

.mb-80 {
	margin-bottom: 8rem;
}

.ml-80 {
	margin-left: 8rem;
}

.mt-84 {
	margin-top: 8.4rem;
}

.mr-84 {
	margin-right: 8.4rem;
}

.mb-84 {
	margin-bottom: 8.4rem;
}

.ml-84 {
	margin-left: 8.4rem;
}

.mt-88 {
	margin-top: 8.8rem;
}

.mr-88 {
	margin-right: 8.8rem;
}

.mb-88 {
	margin-bottom: 8.8rem;
}

.ml-88 {
	margin-left: 8.8rem;
}

.mt-92 {
	margin-top: 9.2rem;
}

.mr-92 {
	margin-right: 9.2rem;
}

.mb-92 {
	margin-bottom: 9.2rem;
}

.ml-92 {
	margin-left: 9.2rem;
}

.mt-96 {
	margin-top: 9.6rem;
}

.mr-96 {
	margin-right: 9.6rem;
}

.mb-96 {
	margin-bottom: 9.6rem;
}

.ml-96 {
	margin-left: 9.6rem;
}

.mt-128 {
	margin-top: 12.8rem;
}

.mr-128 {
	margin-right: 12.8rem;
}

.mb-128 {
	margin-bottom: 12.8rem;
}

.ml-128 {
	margin-left: 12.8rem;
}

.mt-136 {
	margin-top: 13.6rem;
}

.mr-136 {
	margin-right: 13.6rem;
}

.mb-136 {
	margin-bottom: 13.6rem;
}

.ml-136 {
	margin-left: 13.6rem;
}

.mt-160 {
	margin-top: 16rem;
}

.mr-160 {
	margin-right: 16rem;
}

.mb-160 {
	margin-bottom: 16rem;
}

.ml-160 {
	margin-left: 16rem;
}

.mt-192 {
	margin-top: 19.2rem;
}

.mr-192 {
	margin-right: 19.2rem;
}

.mb-192 {
	margin-bottom: 19.2rem;
}

.ml-192 {
	margin-left: 19.2rem;
}

.mt-200 {
	margin-top: 20rem;
}

.mr-200 {
	margin-right: 20rem;
}

.mb-200 {
	margin-bottom: 20rem;
}

.ml-200 {
	margin-left: 20rem;
}

.mt-208 {
	margin-top: 20.8rem;
}

.mr-208 {
	margin-right: 20.8rem;
}

.mb-208 {
	margin-bottom: 20.8rem;
}

.ml-208 {
	margin-left: 20.8rem;
}

.mt-216 {
	margin-top: 21.6rem;
}

.mr-216 {
	margin-right: 21.6rem;
}

.mb-216 {
	margin-bottom: 21.6rem;
}

.ml-216 {
	margin-left: 21.6rem;
}

.mt-224 {
	margin-top: 22.4rem;
}

.mr-224 {
	margin-right: 22.4rem;
}

.mb-224 {
	margin-bottom: 22.4rem;
}

.ml-224 {
	margin-left: 22.4rem;
}

.mt-256 {
	margin-top: 25.6rem;
}

.mr-256 {
	margin-right: 25.6rem;
}

.mb-256 {
	margin-bottom: 25.6rem;
}

.ml-256 {
	margin-left: 25.6rem;
}

.mt-288 {
	margin-top: 28.8rem;
}

.mr-288 {
	margin-right: 28.8rem;
}

.mb-288 {
	margin-bottom: 28.8rem;
}

.ml-288 {
	margin-left: 28.8rem;
}

.mt-320 {
	margin-top: 32rem;
}

.mr-320 {
	margin-right: 32rem;
}

.mb-320 {
	margin-bottom: 32rem;
}

.ml-320 {
	margin-left: 32rem;
}

.mt-360 {
	margin-top: 36rem;
}

.mr-360 {
	margin-right: 36rem;
}

.mb-360 {
	margin-bottom: 36rem;
}

.ml-360 {
	margin-left: 36rem;
}

.mt-384 {
	margin-top: 38.4rem;
}

.mr-384 {
	margin-right: 38.4rem;
}

.mb-384 {
	margin-bottom: 38.4rem;
}

.ml-384 {
	margin-left: 38.4rem;
}

.mt-400 {
	margin-top: 40rem;
}

.mr-400 {
	margin-right: 40rem;
}

.mb-400 {
	margin-bottom: 40rem;
}

.ml-400 {
	margin-left: 40rem;
}

.mt-512 {
	margin-top: 51.2rem;
}

.mr-512 {
	margin-right: 51.2rem;
}

.mb-512 {
	margin-bottom: 51.2rem;
}

.ml-512 {
	margin-left: 51.2rem;
}

.mt-640 {
	margin-top: 64rem;
}

.mr-640 {
	margin-right: 64rem;
}

.mb-640 {
	margin-bottom: 64rem;
}

.ml-640 {
	margin-left: 64rem;
}

.mt-auto {
	margin-top: auto;
}

.mr-auto {
	margin-right: auto;
}

.mb-auto {
	margin-bottom: auto;
}

.ml-auto {
	margin-left: auto;
}

.mt-px {
	margin-top: 1px;
}

.mr-px {
	margin-right: 1px;
}

.mb-px {
	margin-bottom: 1px;
}

.ml-px {
	margin-left: 1px;
}

.mt-xs {
	margin-top: 32rem;
}

.mr-xs {
	margin-right: 32rem;
}

.mb-xs {
	margin-bottom: 32rem;
}

.ml-xs {
	margin-left: 32rem;
}

.mt-sm {
	margin-top: 48rem;
}

.mr-sm {
	margin-right: 48rem;
}

.mb-sm {
	margin-bottom: 48rem;
}

.ml-sm {
	margin-left: 48rem;
}

.mt-md {
	margin-top: 64rem;
}

.mr-md {
	margin-right: 64rem;
}

.mb-md {
	margin-bottom: 64rem;
}

.ml-md {
	margin-left: 64rem;
}

.mt-lg {
	margin-top: 80rem;
}

.mr-lg {
	margin-right: 80rem;
}

.mb-lg {
	margin-bottom: 80rem;
}

.ml-lg {
	margin-left: 80rem;
}

.mt-xl {
	margin-top: 96rem;
}

.mr-xl {
	margin-right: 96rem;
}

.mb-xl {
	margin-bottom: 96rem;
}

.ml-xl {
	margin-left: 96rem;
}

.mt-2xl {
	margin-top: 112rem;
}

.mr-2xl {
	margin-right: 112rem;
}

.mb-2xl {
	margin-bottom: 112rem;
}

.ml-2xl {
	margin-left: 112rem;
}

.mt-3xl {
	margin-top: 128rem;
}

.mr-3xl {
	margin-right: 128rem;
}

.mb-3xl {
	margin-bottom: 128rem;
}

.ml-3xl {
	margin-left: 128rem;
}

.mt-4xl {
	margin-top: 144rem;
}

.mr-4xl {
	margin-right: 144rem;
}

.mb-4xl {
	margin-bottom: 144rem;
}

.ml-4xl {
	margin-left: 144rem;
}

.mt-5xl {
	margin-top: 160rem;
}

.mr-5xl {
	margin-right: 160rem;
}

.mb-5xl {
	margin-bottom: 160rem;
}

.ml-5xl {
	margin-left: 160rem;
}

.-mt-1 {
	margin-top: -0.25rem;
}

.-mr-1 {
	margin-right: -0.25rem;
}

.-mb-1 {
	margin-bottom: -0.25rem;
}

.-ml-1 {
	margin-left: -0.25rem;
}

.-mt-2 {
	margin-top: -0.5rem;
}

.-mr-2 {
	margin-right: -0.5rem;
}

.-mb-2 {
	margin-bottom: -0.5rem;
}

.-ml-2 {
	margin-left: -0.5rem;
}

.-mt-3 {
	margin-top: -0.75rem;
}

.-mr-3 {
	margin-right: -0.75rem;
}

.-mb-3 {
	margin-bottom: -0.75rem;
}

.-ml-3 {
	margin-left: -0.75rem;
}

.-mt-4 {
	margin-top: -0.4rem;
}

.-mr-4 {
	margin-right: -0.4rem;
}

.-mb-4 {
	margin-bottom: -0.4rem;
}

.-ml-4 {
	margin-left: -0.4rem;
}

.-mt-5 {
	margin-top: -1.25rem;
}

.-mr-5 {
	margin-right: -1.25rem;
}

.-mb-5 {
	margin-bottom: -1.25rem;
}

.-ml-5 {
	margin-left: -1.25rem;
}

.-mt-6 {
	margin-top: -0.6rem;
}

.-mr-6 {
	margin-right: -0.6rem;
}

.-mb-6 {
	margin-bottom: -0.6rem;
}

.-ml-6 {
	margin-left: -0.6rem;
}

.-mt-8 {
	margin-top: -0.8rem;
}

.-mr-8 {
	margin-right: -0.8rem;
}

.-mb-8 {
	margin-bottom: -0.8rem;
}

.-ml-8 {
	margin-left: -0.8rem;
}

.-mt-10 {
	margin-top: -2.5rem;
}

.-mr-10 {
	margin-right: -2.5rem;
}

.-mb-10 {
	margin-bottom: -2.5rem;
}

.-ml-10 {
	margin-left: -2.5rem;
}

.-mt-12 {
	margin-top: -1.2rem;
}

.-mr-12 {
	margin-right: -1.2rem;
}

.-mb-12 {
	margin-bottom: -1.2rem;
}

.-ml-12 {
	margin-left: -1.2rem;
}

.-mt-16 {
	margin-top: -1.6rem;
}

.-mr-16 {
	margin-right: -1.6rem;
}

.-mb-16 {
	margin-bottom: -1.6rem;
}

.-ml-16 {
	margin-left: -1.6rem;
}

.-mt-20 {
	margin-top: -2rem;
}

.-mr-20 {
	margin-right: -2rem;
}

.-mb-20 {
	margin-bottom: -2rem;
}

.-ml-20 {
	margin-left: -2rem;
}

.-mt-24 {
	margin-top: -2.4rem;
}

.-mr-24 {
	margin-right: -2.4rem;
}

.-mb-24 {
	margin-bottom: -2.4rem;
}

.-ml-24 {
	margin-left: -2.4rem;
}

.-mt-28 {
	margin-top: -2.8rem;
}

.-mr-28 {
	margin-right: -2.8rem;
}

.-mb-28 {
	margin-bottom: -2.8rem;
}

.-ml-28 {
	margin-left: -2.8rem;
}

.-mt-32 {
	margin-top: -3.2rem;
}

.-mr-32 {
	margin-right: -3.2rem;
}

.-mb-32 {
	margin-bottom: -3.2rem;
}

.-ml-32 {
	margin-left: -3.2rem;
}

.-mt-36 {
	margin-top: -3.6rem;
}

.-mr-36 {
	margin-right: -3.6rem;
}

.-mb-36 {
	margin-bottom: -3.6rem;
}

.-ml-36 {
	margin-left: -3.6rem;
}

.-mt-40 {
	margin-top: -4rem;
}

.-mr-40 {
	margin-right: -4rem;
}

.-mb-40 {
	margin-bottom: -4rem;
}

.-ml-40 {
	margin-left: -4rem;
}

.-mt-44 {
	margin-top: -4.4rem;
}

.-mr-44 {
	margin-right: -4.4rem;
}

.-mb-44 {
	margin-bottom: -4.4rem;
}

.-ml-44 {
	margin-left: -4.4rem;
}

.-mt-48 {
	margin-top: -4.8rem;
}

.-mr-48 {
	margin-right: -4.8rem;
}

.-mb-48 {
	margin-bottom: -4.8rem;
}

.-ml-48 {
	margin-left: -4.8rem;
}

.-mt-52 {
	margin-top: -5.2rem;
}

.-mr-52 {
	margin-right: -5.2rem;
}

.-mb-52 {
	margin-bottom: -5.2rem;
}

.-ml-52 {
	margin-left: -5.2rem;
}

.-mt-56 {
	margin-top: -5.6rem;
}

.-mr-56 {
	margin-right: -5.6rem;
}

.-mb-56 {
	margin-bottom: -5.6rem;
}

.-ml-56 {
	margin-left: -5.6rem;
}

.-mt-60 {
	margin-top: -6rem;
}

.-mr-60 {
	margin-right: -6rem;
}

.-mb-60 {
	margin-bottom: -6rem;
}

.-ml-60 {
	margin-left: -6rem;
}

.-mt-64 {
	margin-top: -6.4rem;
}

.-mr-64 {
	margin-right: -6.4rem;
}

.-mb-64 {
	margin-bottom: -6.4rem;
}

.-ml-64 {
	margin-left: -6.4rem;
}

.-mt-68 {
	margin-top: -6.8rem;
}

.-mr-68 {
	margin-right: -6.8rem;
}

.-mb-68 {
	margin-bottom: -6.8rem;
}

.-ml-68 {
	margin-left: -6.8rem;
}

.-mt-72 {
	margin-top: -7.2rem;
}

.-mr-72 {
	margin-right: -7.2rem;
}

.-mb-72 {
	margin-bottom: -7.2rem;
}

.-ml-72 {
	margin-left: -7.2rem;
}

.-mt-76 {
	margin-top: -7.6rem;
}

.-mr-76 {
	margin-right: -7.6rem;
}

.-mb-76 {
	margin-bottom: -7.6rem;
}

.-ml-76 {
	margin-left: -7.6rem;
}

.-mt-80 {
	margin-top: -8rem;
}

.-mr-80 {
	margin-right: -8rem;
}

.-mb-80 {
	margin-bottom: -8rem;
}

.-ml-80 {
	margin-left: -8rem;
}

.-mt-84 {
	margin-top: -8.4rem;
}

.-mr-84 {
	margin-right: -8.4rem;
}

.-mb-84 {
	margin-bottom: -8.4rem;
}

.-ml-84 {
	margin-left: -8.4rem;
}

.-mt-88 {
	margin-top: -8.8rem;
}

.-mr-88 {
	margin-right: -8.8rem;
}

.-mb-88 {
	margin-bottom: -8.8rem;
}

.-ml-88 {
	margin-left: -8.8rem;
}

.-mt-92 {
	margin-top: -9.2rem;
}

.-mr-92 {
	margin-right: -9.2rem;
}

.-mb-92 {
	margin-bottom: -9.2rem;
}

.-ml-92 {
	margin-left: -9.2rem;
}

.-mt-96 {
	margin-top: -9.6rem;
}

.-mr-96 {
	margin-right: -9.6rem;
}

.-mb-96 {
	margin-bottom: -9.6rem;
}

.-ml-96 {
	margin-left: -9.6rem;
}

.-mt-128 {
	margin-top: -12.8rem;
}

.-mr-128 {
	margin-right: -12.8rem;
}

.-mb-128 {
	margin-bottom: -12.8rem;
}

.-ml-128 {
	margin-left: -12.8rem;
}

.-mt-136 {
	margin-top: -13.6rem;
}

.-mr-136 {
	margin-right: -13.6rem;
}

.-mb-136 {
	margin-bottom: -13.6rem;
}

.-ml-136 {
	margin-left: -13.6rem;
}

.-mt-160 {
	margin-top: -16rem;
}

.-mr-160 {
	margin-right: -16rem;
}

.-mb-160 {
	margin-bottom: -16rem;
}

.-ml-160 {
	margin-left: -16rem;
}

.-mt-192 {
	margin-top: -19.2rem;
}

.-mr-192 {
	margin-right: -19.2rem;
}

.-mb-192 {
	margin-bottom: -19.2rem;
}

.-ml-192 {
	margin-left: -19.2rem;
}

.-mt-200 {
	margin-top: -20rem;
}

.-mr-200 {
	margin-right: -20rem;
}

.-mb-200 {
	margin-bottom: -20rem;
}

.-ml-200 {
	margin-left: -20rem;
}

.-mt-208 {
	margin-top: -20.8rem;
}

.-mr-208 {
	margin-right: -20.8rem;
}

.-mb-208 {
	margin-bottom: -20.8rem;
}

.-ml-208 {
	margin-left: -20.8rem;
}

.-mt-216 {
	margin-top: -21.6rem;
}

.-mr-216 {
	margin-right: -21.6rem;
}

.-mb-216 {
	margin-bottom: -21.6rem;
}

.-ml-216 {
	margin-left: -21.6rem;
}

.-mt-224 {
	margin-top: -22.4rem;
}

.-mr-224 {
	margin-right: -22.4rem;
}

.-mb-224 {
	margin-bottom: -22.4rem;
}

.-ml-224 {
	margin-left: -22.4rem;
}

.-mt-256 {
	margin-top: -25.6rem;
}

.-mr-256 {
	margin-right: -25.6rem;
}

.-mb-256 {
	margin-bottom: -25.6rem;
}

.-ml-256 {
	margin-left: -25.6rem;
}

.-mt-288 {
	margin-top: -28.8rem;
}

.-mr-288 {
	margin-right: -28.8rem;
}

.-mb-288 {
	margin-bottom: -28.8rem;
}

.-ml-288 {
	margin-left: -28.8rem;
}

.-mt-320 {
	margin-top: -32rem;
}

.-mr-320 {
	margin-right: -32rem;
}

.-mb-320 {
	margin-bottom: -32rem;
}

.-ml-320 {
	margin-left: -32rem;
}

.-mt-360 {
	margin-top: -36rem;
}

.-mr-360 {
	margin-right: -36rem;
}

.-mb-360 {
	margin-bottom: -36rem;
}

.-ml-360 {
	margin-left: -36rem;
}

.-mt-384 {
	margin-top: -38.4rem;
}

.-mr-384 {
	margin-right: -38.4rem;
}

.-mb-384 {
	margin-bottom: -38.4rem;
}

.-ml-384 {
	margin-left: -38.4rem;
}

.-mt-400 {
	margin-top: -40rem;
}

.-mr-400 {
	margin-right: -40rem;
}

.-mb-400 {
	margin-bottom: -40rem;
}

.-ml-400 {
	margin-left: -40rem;
}

.-mt-512 {
	margin-top: -51.2rem;
}

.-mr-512 {
	margin-right: -51.2rem;
}

.-mb-512 {
	margin-bottom: -51.2rem;
}

.-ml-512 {
	margin-left: -51.2rem;
}

.-mt-640 {
	margin-top: -64rem;
}

.-mr-640 {
	margin-right: -64rem;
}

.-mb-640 {
	margin-bottom: -64rem;
}

.-ml-640 {
	margin-left: -64rem;
}

.-mt-px {
	margin-top: -1px;
}

.-mr-px {
	margin-right: -1px;
}

.-mb-px {
	margin-bottom: -1px;
}

.-ml-px {
	margin-left: -1px;
}

.-mt-xs {
	margin-top: -32rem;
}

.-mr-xs {
	margin-right: -32rem;
}

.-mb-xs {
	margin-bottom: -32rem;
}

.-ml-xs {
	margin-left: -32rem;
}

.-mt-sm {
	margin-top: -48rem;
}

.-mr-sm {
	margin-right: -48rem;
}

.-mb-sm {
	margin-bottom: -48rem;
}

.-ml-sm {
	margin-left: -48rem;
}

.-mt-md {
	margin-top: -64rem;
}

.-mr-md {
	margin-right: -64rem;
}

.-mb-md {
	margin-bottom: -64rem;
}

.-ml-md {
	margin-left: -64rem;
}

.-mt-lg {
	margin-top: -80rem;
}

.-mr-lg {
	margin-right: -80rem;
}

.-mb-lg {
	margin-bottom: -80rem;
}

.-ml-lg {
	margin-left: -80rem;
}

.-mt-xl {
	margin-top: -96rem;
}

.-mr-xl {
	margin-right: -96rem;
}

.-mb-xl {
	margin-bottom: -96rem;
}

.-ml-xl {
	margin-left: -96rem;
}

.-mt-2xl {
	margin-top: -112rem;
}

.-mr-2xl {
	margin-right: -112rem;
}

.-mb-2xl {
	margin-bottom: -112rem;
}

.-ml-2xl {
	margin-left: -112rem;
}

.-mt-3xl {
	margin-top: -128rem;
}

.-mr-3xl {
	margin-right: -128rem;
}

.-mb-3xl {
	margin-bottom: -128rem;
}

.-ml-3xl {
	margin-left: -128rem;
}

.-mt-4xl {
	margin-top: -144rem;
}

.-mr-4xl {
	margin-right: -144rem;
}

.-mb-4xl {
	margin-bottom: -144rem;
}

.-ml-4xl {
	margin-left: -144rem;
}

.-mt-5xl {
	margin-top: -160rem;
}

.-mr-5xl {
	margin-right: -160rem;
}

.-mb-5xl {
	margin-bottom: -160rem;
}

.-ml-5xl {
	margin-left: -160rem;
}

.-mt-auto {
	margin-top: -auto;
}

.-mr-auto {
	margin-right: -auto;
}

.-mb-auto {
	margin-bottom: -auto;
}

.-ml-auto {
	margin-left: -auto;
}

.max-h-0 {
	max-height: 0;
}

.max-h-1 {
	max-height: 0.25rem;
}

.max-h-2 {
	max-height: 0.5rem;
}

.max-h-3 {
	max-height: 0.75rem;
}

.max-h-4 {
	max-height: 0.4rem;
}

.max-h-5 {
	max-height: 1.25rem;
}

.max-h-6 {
	max-height: 0.6rem;
}

.max-h-8 {
	max-height: 0.8rem;
}

.max-h-10 {
	max-height: 2.5rem;
}

.max-h-12 {
	max-height: 1.2rem;
}

.max-h-16 {
	max-height: 1.6rem;
}

.max-h-20 {
	max-height: 2rem;
}

.max-h-24 {
	max-height: 2.4rem;
}

.max-h-28 {
	max-height: 2.8rem;
}

.max-h-32 {
	max-height: 3.2rem;
}

.max-h-36 {
	max-height: 3.6rem;
}

.max-h-40 {
	max-height: 4rem;
}

.max-h-44 {
	max-height: 4.4rem;
}

.max-h-48 {
	max-height: 4.8rem;
}

.max-h-52 {
	max-height: 5.2rem;
}

.max-h-56 {
	max-height: 5.6rem;
}

.max-h-60 {
	max-height: 6rem;
}

.max-h-64 {
	max-height: 6.4rem;
}

.max-h-68 {
	max-height: 6.8rem;
}

.max-h-72 {
	max-height: 7.2rem;
}

.max-h-76 {
	max-height: 7.6rem;
}

.max-h-80 {
	max-height: 8rem;
}

.max-h-84 {
	max-height: 8.4rem;
}

.max-h-88 {
	max-height: 8.8rem;
}

.max-h-92 {
	max-height: 9.2rem;
}

.max-h-96 {
	max-height: 9.6rem;
}

.max-h-128 {
	max-height: 12.8rem;
}

.max-h-136 {
	max-height: 13.6rem;
}

.max-h-160 {
	max-height: 16rem;
}

.max-h-192 {
	max-height: 19.2rem;
}

.max-h-200 {
	max-height: 20rem;
}

.max-h-208 {
	max-height: 20.8rem;
}

.max-h-216 {
	max-height: 21.6rem;
}

.max-h-224 {
	max-height: 22.4rem;
}

.max-h-256 {
	max-height: 25.6rem;
}

.max-h-288 {
	max-height: 28.8rem;
}

.max-h-320 {
	max-height: 32rem;
}

.max-h-360 {
	max-height: 36rem;
}

.max-h-384 {
	max-height: 38.4rem;
}

.max-h-400 {
	max-height: 40rem;
}

.max-h-512 {
	max-height: 51.2rem;
}

.max-h-640 {
	max-height: 64rem;
}

.max-h-full {
	max-height: 100%;
}

.max-h-screen {
	max-height: 100vh;
}

.max-h-auto {
	max-height: auto;
}

.max-h-px {
	max-height: 1px;
}

.max-h-xs {
	max-height: 32rem;
}

.max-h-sm {
	max-height: 48rem;
}

.max-h-md {
	max-height: 64rem;
}

.max-h-lg {
	max-height: 80rem;
}

.max-h-xl {
	max-height: 96rem;
}

.max-h-2xl {
	max-height: 112rem;
}

.max-h-3xl {
	max-height: 128rem;
}

.max-h-4xl {
	max-height: 144rem;
}

.max-h-5xl {
	max-height: 160rem;
}

.max-w-0 {
	max-width: 0;
}

.max-w-1 {
	max-width: 0.25rem;
}

.max-w-2 {
	max-width: 0.5rem;
}

.max-w-3 {
	max-width: 0.75rem;
}

.max-w-4 {
	max-width: 0.4rem;
}

.max-w-5 {
	max-width: 1.25rem;
}

.max-w-6 {
	max-width: 0.6rem;
}

.max-w-8 {
	max-width: 0.8rem;
}

.max-w-10 {
	max-width: 2.5rem;
}

.max-w-12 {
	max-width: 1.2rem;
}

.max-w-16 {
	max-width: 1.6rem;
}

.max-w-20 {
	max-width: 2rem;
}

.max-w-24 {
	max-width: 2.4rem;
}

.max-w-28 {
	max-width: 2.8rem;
}

.max-w-32 {
	max-width: 3.2rem;
}

.max-w-36 {
	max-width: 3.6rem;
}

.max-w-40 {
	max-width: 4rem;
}

.max-w-44 {
	max-width: 4.4rem;
}

.max-w-48 {
	max-width: 4.8rem;
}

.max-w-52 {
	max-width: 5.2rem;
}

.max-w-56 {
	max-width: 5.6rem;
}

.max-w-60 {
	max-width: 6rem;
}

.max-w-64 {
	max-width: 6.4rem;
}

.max-w-68 {
	max-width: 6.8rem;
}

.max-w-72 {
	max-width: 7.2rem;
}

.max-w-76 {
	max-width: 7.6rem;
}

.max-w-80 {
	max-width: 8rem;
}

.max-w-84 {
	max-width: 8.4rem;
}

.max-w-88 {
	max-width: 8.8rem;
}

.max-w-92 {
	max-width: 9.2rem;
}

.max-w-96 {
	max-width: 9.6rem;
}

.max-w-128 {
	max-width: 12.8rem;
}

.max-w-136 {
	max-width: 13.6rem;
}

.max-w-160 {
	max-width: 16rem;
}

.max-w-192 {
	max-width: 19.2rem;
}

.max-w-200 {
	max-width: 20rem;
}

.max-w-208 {
	max-width: 20.8rem;
}

.max-w-216 {
	max-width: 21.6rem;
}

.max-w-224 {
	max-width: 22.4rem;
}

.max-w-256 {
	max-width: 25.6rem;
}

.max-w-288 {
	max-width: 28.8rem;
}

.max-w-320 {
	max-width: 32rem;
}

.max-w-360 {
	max-width: 36rem;
}

.max-w-384 {
	max-width: 38.4rem;
}

.max-w-400 {
	max-width: 40rem;
}

.max-w-512 {
	max-width: 51.2rem;
}

.max-w-640 {
	max-width: 64rem;
}

.max-w-xs {
	max-width: 32rem;
}

.max-w-sm {
	max-width: 48rem;
}

.max-w-md {
	max-width: 64rem;
}

.max-w-lg {
	max-width: 80rem;
}

.max-w-xl {
	max-width: 96rem;
}

.max-w-2xl {
	max-width: 112rem;
}

.max-w-3xl {
	max-width: 128rem;
}

.max-w-4xl {
	max-width: 144rem;
}

.max-w-5xl {
	max-width: 160rem;
}

.max-w-6xl {
	max-width: 72rem;
}

.max-w-full {
	max-width: 100%;
}

.max-w-px {
	max-width: 1px;
}

.max-w-auto {
	max-width: auto;
}

.max-w-screen {
	max-width: 100vw;
}

.min-h-0 {
	min-height: 0;
}

.min-h-1 {
	min-height: 0.25rem;
}

.min-h-2 {
	min-height: 0.5rem;
}

.min-h-3 {
	min-height: 0.75rem;
}

.min-h-4 {
	min-height: 0.4rem;
}

.min-h-5 {
	min-height: 1.25rem;
}

.min-h-6 {
	min-height: 0.6rem;
}

.min-h-8 {
	min-height: 0.8rem;
}

.min-h-10 {
	min-height: 2.5rem;
}

.min-h-12 {
	min-height: 1.2rem;
}

.min-h-16 {
	min-height: 1.6rem;
}

.min-h-20 {
	min-height: 2rem;
}

.min-h-24 {
	min-height: 2.4rem;
}

.min-h-28 {
	min-height: 2.8rem;
}

.min-h-32 {
	min-height: 3.2rem;
}

.min-h-36 {
	min-height: 3.6rem;
}

.min-h-40 {
	min-height: 4rem;
}

.min-h-44 {
	min-height: 4.4rem;
}

.min-h-48 {
	min-height: 4.8rem;
}

.min-h-52 {
	min-height: 5.2rem;
}

.min-h-56 {
	min-height: 5.6rem;
}

.min-h-60 {
	min-height: 6rem;
}

.min-h-64 {
	min-height: 6.4rem;
}

.min-h-68 {
	min-height: 6.8rem;
}

.min-h-72 {
	min-height: 7.2rem;
}

.min-h-76 {
	min-height: 7.6rem;
}

.min-h-80 {
	min-height: 8rem;
}

.min-h-84 {
	min-height: 8.4rem;
}

.min-h-88 {
	min-height: 8.8rem;
}

.min-h-92 {
	min-height: 9.2rem;
}

.min-h-96 {
	min-height: 9.6rem;
}

.min-h-128 {
	min-height: 12.8rem;
}

.min-h-136 {
	min-height: 13.6rem;
}

.min-h-160 {
	min-height: 16rem;
}

.min-h-192 {
	min-height: 19.2rem;
}

.min-h-200 {
	min-height: 20rem;
}

.min-h-208 {
	min-height: 20.8rem;
}

.min-h-216 {
	min-height: 21.6rem;
}

.min-h-224 {
	min-height: 22.4rem;
}

.min-h-256 {
	min-height: 25.6rem;
}

.min-h-288 {
	min-height: 28.8rem;
}

.min-h-320 {
	min-height: 32rem;
}

.min-h-360 {
	min-height: 36rem;
}

.min-h-384 {
	min-height: 38.4rem;
}

.min-h-400 {
	min-height: 40rem;
}

.min-h-512 {
	min-height: 51.2rem;
}

.min-h-640 {
	min-height: 64rem;
}

.min-h-full {
	min-height: 100%;
}

.min-h-screen {
	min-height: 100vh;
}

.min-h-auto {
	min-height: auto;
}

.min-h-px {
	min-height: 1px;
}

.min-h-xs {
	min-height: 32rem;
}

.min-h-sm {
	min-height: 48rem;
}

.min-h-md {
	min-height: 64rem;
}

.min-h-lg {
	min-height: 80rem;
}

.min-h-xl {
	min-height: 96rem;
}

.min-h-2xl {
	min-height: 112rem;
}

.min-h-3xl {
	min-height: 128rem;
}

.min-h-4xl {
	min-height: 144rem;
}

.min-h-5xl {
	min-height: 160rem;
}

.min-w-0 {
	min-width: 0;
}

.min-w-1 {
	min-width: 0.25rem;
}

.min-w-2 {
	min-width: 0.5rem;
}

.min-w-3 {
	min-width: 0.75rem;
}

.min-w-4 {
	min-width: 0.4rem;
}

.min-w-5 {
	min-width: 1.25rem;
}

.min-w-6 {
	min-width: 0.6rem;
}

.min-w-8 {
	min-width: 0.8rem;
}

.min-w-10 {
	min-width: 2.5rem;
}

.min-w-12 {
	min-width: 1.2rem;
}

.min-w-16 {
	min-width: 1.6rem;
}

.min-w-20 {
	min-width: 2rem;
}

.min-w-24 {
	min-width: 2.4rem;
}

.min-w-28 {
	min-width: 2.8rem;
}

.min-w-32 {
	min-width: 3.2rem;
}

.min-w-36 {
	min-width: 3.6rem;
}

.min-w-40 {
	min-width: 4rem;
}

.min-w-44 {
	min-width: 4.4rem;
}

.min-w-48 {
	min-width: 4.8rem;
}

.min-w-52 {
	min-width: 5.2rem;
}

.min-w-56 {
	min-width: 5.6rem;
}

.min-w-60 {
	min-width: 6rem;
}

.min-w-64 {
	min-width: 6.4rem;
}

.min-w-68 {
	min-width: 6.8rem;
}

.min-w-72 {
	min-width: 7.2rem;
}

.min-w-76 {
	min-width: 7.6rem;
}

.min-w-80 {
	min-width: 8rem;
}

.min-w-84 {
	min-width: 8.4rem;
}

.min-w-88 {
	min-width: 8.8rem;
}

.min-w-92 {
	min-width: 9.2rem;
}

.min-w-96 {
	min-width: 9.6rem;
}

.min-w-128 {
	min-width: 12.8rem;
}

.min-w-136 {
	min-width: 13.6rem;
}

.min-w-160 {
	min-width: 16rem;
}

.min-w-192 {
	min-width: 19.2rem;
}

.min-w-200 {
	min-width: 20rem;
}

.min-w-208 {
	min-width: 20.8rem;
}

.min-w-216 {
	min-width: 21.6rem;
}

.min-w-224 {
	min-width: 22.4rem;
}

.min-w-256 {
	min-width: 25.6rem;
}

.min-w-288 {
	min-width: 28.8rem;
}

.min-w-320 {
	min-width: 32rem;
}

.min-w-360 {
	min-width: 36rem;
}

.min-w-384 {
	min-width: 38.4rem;
}

.min-w-400 {
	min-width: 40rem;
}

.min-w-512 {
	min-width: 51.2rem;
}

.min-w-640 {
	min-width: 64rem;
}

.min-w-full {
	min-width: 100%;
}

.min-w-px {
	min-width: 1px;
}

.min-w-xs {
	min-width: 32rem;
}

.min-w-sm {
	min-width: 48rem;
}

.min-w-md {
	min-width: 64rem;
}

.min-w-lg {
	min-width: 80rem;
}

.min-w-xl {
	min-width: 96rem;
}

.min-w-2xl {
	min-width: 112rem;
}

.min-w-3xl {
	min-width: 128rem;
}

.min-w-4xl {
	min-width: 144rem;
}

.min-w-5xl {
	min-width: 160rem;
}

.min-w-auto {
	min-width: auto;
}

.min-w-screen {
	min-width: 100vw;
}

.object-contain {
	object-fit: contain;
}

.object-cover {
	object-fit: cover;
}

.object-fill {
	object-fit: fill;
}

.object-none {
	object-fit: none;
}

.object-scale-down {
	object-fit: scale-down;
}

.object-bottom {
	object-position: bottom;
}

.object-center {
	object-position: center;
}

.object-left {
	object-position: left;
}

.object-left-bottom {
	object-position: left bottom;
}

.object-left-top {
	object-position: left top;
}

.object-right {
	object-position: right;
}

.object-right-bottom {
	object-position: right bottom;
}

.object-right-top {
	object-position: right top;
}

.object-top {
	object-position: top;
}

.opacity-0 {
	opacity: 0;
}

.opacity-25 {
	opacity: 0.25;
}

.opacity-50 {
	opacity: 0.5;
}

.opacity-75 {
	opacity: 0.75;
}

.opacity-100 {
	opacity: 1;
}

.outline-none {
	outline: 0;
}

.focus\:outline-none:focus {
	outline: 0;
}

.overflow-auto {
	overflow: auto;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-visible {
	overflow: visible;
}

.overflow-scroll {
	overflow: scroll;
}

.overflow-x-auto {
	overflow-x: auto;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.overflow-y-hidden {
	overflow-y: hidden;
}

.overflow-x-visible {
	overflow-x: visible;
}

.overflow-y-visible {
	overflow-y: visible;
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.overflow-y-scroll {
	overflow-y: scroll;
}

.scrolling-touch {
	-webkit-overflow-scrolling: touch;
}

.scrolling-auto {
	-webkit-overflow-scrolling: auto;
}

.p-0 {
	padding: 0;
}

.p-1 {
	padding: 0.25rem;
}

.p-2 {
	padding: 0.5rem;
}

.p-3 {
	padding: 0.75rem;
}

.p-4 {
	padding: 0.4rem;
}

.p-5 {
	padding: 1.25rem;
}

.p-6 {
	padding: 0.6rem;
}

.p-8 {
	padding: 0.8rem;
}

.p-10 {
	padding: 2.5rem;
}

.p-12 {
	padding: 1.2rem;
}

.p-16 {
	padding: 1.6rem;
}

.p-20 {
	padding: 2rem;
}

.p-24 {
	padding: 2.4rem;
}

.p-28 {
	padding: 2.8rem;
}

.p-32 {
	padding: 3.2rem;
}

.p-36 {
	padding: 3.6rem;
}

.p-40 {
	padding: 4rem;
}

.p-44 {
	padding: 4.4rem;
}

.p-48 {
	padding: 4.8rem;
}

.p-52 {
	padding: 5.2rem;
}

.p-56 {
	padding: 5.6rem;
}

.p-60 {
	padding: 6rem;
}

.p-64 {
	padding: 6.4rem;
}

.p-68 {
	padding: 6.8rem;
}

.p-72 {
	padding: 7.2rem;
}

.p-76 {
	padding: 7.6rem;
}

.p-80 {
	padding: 8rem;
}

.p-84 {
	padding: 8.4rem;
}

.p-88 {
	padding: 8.8rem;
}

.p-92 {
	padding: 9.2rem;
}

.p-96 {
	padding: 9.6rem;
}

.p-128 {
	padding: 12.8rem;
}

.p-136 {
	padding: 13.6rem;
}

.p-160 {
	padding: 16rem;
}

.p-192 {
	padding: 19.2rem;
}

.p-200 {
	padding: 20rem;
}

.p-208 {
	padding: 20.8rem;
}

.p-216 {
	padding: 21.6rem;
}

.p-224 {
	padding: 22.4rem;
}

.p-256 {
	padding: 25.6rem;
}

.p-288 {
	padding: 28.8rem;
}

.p-320 {
	padding: 32rem;
}

.p-360 {
	padding: 36rem;
}

.p-384 {
	padding: 38.4rem;
}

.p-400 {
	padding: 40rem;
}

.p-512 {
	padding: 51.2rem;
}

.p-640 {
	padding: 64rem;
}

.p-px {
	padding: 1px;
}

.p-xs {
	padding: 32rem;
}

.p-sm {
	padding: 48rem;
}

.p-md {
	padding: 64rem;
}

.p-lg {
	padding: 80rem;
}

.p-xl {
	padding: 96rem;
}

.p-2xl {
	padding: 112rem;
}

.p-3xl {
	padding: 128rem;
}

.p-4xl {
	padding: 144rem;
}

.p-5xl {
	padding: 160rem;
}

.p-auto {
	padding: auto;
}

.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.px-0 {
	padding-left: 0;
	padding-right: 0;
}

.py-1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.px-3 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.py-4 {
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
}

.px-4 {
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}

.py-5 {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.px-5 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.py-6 {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}

.px-6 {
	padding-left: 0.6rem;
	padding-right: 0.6rem;
}

.py-8 {
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
}

.px-8 {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
}

.py-10 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.px-10 {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.py-12 {
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
}

.px-12 {
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}

.py-16 {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;
}

.px-16 {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

.py-20 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.px-20 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.py-24 {
	padding-top: 2.4rem;
	padding-bottom: 2.4rem;
}

.px-24 {
	padding-left: 2.4rem;
	padding-right: 2.4rem;
}

.py-28 {
	padding-top: 2.8rem;
	padding-bottom: 2.8rem;
}

.px-28 {
	padding-left: 2.8rem;
	padding-right: 2.8rem;
}

.py-32 {
	padding-top: 3.2rem;
	padding-bottom: 3.2rem;
}

.px-32 {
	padding-left: 3.2rem;
	padding-right: 3.2rem;
}

.py-36 {
	padding-top: 3.6rem;
	padding-bottom: 3.6rem;
}

.px-36 {
	padding-left: 3.6rem;
	padding-right: 3.6rem;
}

.py-40 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.px-40 {
	padding-left: 4rem;
	padding-right: 4rem;
}

.py-44 {
	padding-top: 4.4rem;
	padding-bottom: 4.4rem;
}

.px-44 {
	padding-left: 4.4rem;
	padding-right: 4.4rem;
}

.py-48 {
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
}

.px-48 {
	padding-left: 4.8rem;
	padding-right: 4.8rem;
}

.py-52 {
	padding-top: 5.2rem;
	padding-bottom: 5.2rem;
}

.px-52 {
	padding-left: 5.2rem;
	padding-right: 5.2rem;
}

.py-56 {
	padding-top: 5.6rem;
	padding-bottom: 5.6rem;
}

.px-56 {
	padding-left: 5.6rem;
	padding-right: 5.6rem;
}

.py-60 {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.px-60 {
	padding-left: 6rem;
	padding-right: 6rem;
}

.py-64 {
	padding-top: 6.4rem;
	padding-bottom: 6.4rem;
}

.px-64 {
	padding-left: 6.4rem;
	padding-right: 6.4rem;
}

.py-68 {
	padding-top: 6.8rem;
	padding-bottom: 6.8rem;
}

.px-68 {
	padding-left: 6.8rem;
	padding-right: 6.8rem;
}

.py-72 {
	padding-top: 7.2rem;
	padding-bottom: 7.2rem;
}

.px-72 {
	padding-left: 7.2rem;
	padding-right: 7.2rem;
}

.py-76 {
	padding-top: 7.6rem;
	padding-bottom: 7.6rem;
}

.px-76 {
	padding-left: 7.6rem;
	padding-right: 7.6rem;
}

.py-80 {
	padding-top: 8rem;
	padding-bottom: 8rem;
}

.px-80 {
	padding-left: 8rem;
	padding-right: 8rem;
}

.py-84 {
	padding-top: 8.4rem;
	padding-bottom: 8.4rem;
}

.px-84 {
	padding-left: 8.4rem;
	padding-right: 8.4rem;
}

.py-88 {
	padding-top: 8.8rem;
	padding-bottom: 8.8rem;
}

.px-88 {
	padding-left: 8.8rem;
	padding-right: 8.8rem;
}

.py-92 {
	padding-top: 9.2rem;
	padding-bottom: 9.2rem;
}

.px-92 {
	padding-left: 9.2rem;
	padding-right: 9.2rem;
}

.py-96 {
	padding-top: 9.6rem;
	padding-bottom: 9.6rem;
}

.px-96 {
	padding-left: 9.6rem;
	padding-right: 9.6rem;
}

.py-128 {
	padding-top: 12.8rem;
	padding-bottom: 12.8rem;
}

.px-128 {
	padding-left: 12.8rem;
	padding-right: 12.8rem;
}

.py-136 {
	padding-top: 13.6rem;
	padding-bottom: 13.6rem;
}

.px-136 {
	padding-left: 13.6rem;
	padding-right: 13.6rem;
}

.py-160 {
	padding-top: 16rem;
	padding-bottom: 16rem;
}

.px-160 {
	padding-left: 16rem;
	padding-right: 16rem;
}

.py-192 {
	padding-top: 19.2rem;
	padding-bottom: 19.2rem;
}

.px-192 {
	padding-left: 19.2rem;
	padding-right: 19.2rem;
}

.py-200 {
	padding-top: 20rem;
	padding-bottom: 20rem;
}

.px-200 {
	padding-left: 20rem;
	padding-right: 20rem;
}

.py-208 {
	padding-top: 20.8rem;
	padding-bottom: 20.8rem;
}

.px-208 {
	padding-left: 20.8rem;
	padding-right: 20.8rem;
}

.py-216 {
	padding-top: 21.6rem;
	padding-bottom: 21.6rem;
}

.px-216 {
	padding-left: 21.6rem;
	padding-right: 21.6rem;
}

.py-224 {
	padding-top: 22.4rem;
	padding-bottom: 22.4rem;
}

.px-224 {
	padding-left: 22.4rem;
	padding-right: 22.4rem;
}

.py-256 {
	padding-top: 25.6rem;
	padding-bottom: 25.6rem;
}

.px-256 {
	padding-left: 25.6rem;
	padding-right: 25.6rem;
}

.py-288 {
	padding-top: 28.8rem;
	padding-bottom: 28.8rem;
}

.px-288 {
	padding-left: 28.8rem;
	padding-right: 28.8rem;
}

.py-320 {
	padding-top: 32rem;
	padding-bottom: 32rem;
}

.px-320 {
	padding-left: 32rem;
	padding-right: 32rem;
}

.py-360 {
	padding-top: 36rem;
	padding-bottom: 36rem;
}

.px-360 {
	padding-left: 36rem;
	padding-right: 36rem;
}

.py-384 {
	padding-top: 38.4rem;
	padding-bottom: 38.4rem;
}

.px-384 {
	padding-left: 38.4rem;
	padding-right: 38.4rem;
}

.py-400 {
	padding-top: 40rem;
	padding-bottom: 40rem;
}

.px-400 {
	padding-left: 40rem;
	padding-right: 40rem;
}

.py-512 {
	padding-top: 51.2rem;
	padding-bottom: 51.2rem;
}

.px-512 {
	padding-left: 51.2rem;
	padding-right: 51.2rem;
}

.py-640 {
	padding-top: 64rem;
	padding-bottom: 64rem;
}

.px-640 {
	padding-left: 64rem;
	padding-right: 64rem;
}

.py-px {
	padding-top: 1px;
	padding-bottom: 1px;
}

.px-px {
	padding-left: 1px;
	padding-right: 1px;
}

.py-xs {
	padding-top: 32rem;
	padding-bottom: 32rem;
}

.px-xs {
	padding-left: 32rem;
	padding-right: 32rem;
}

.py-sm {
	padding-top: 48rem;
	padding-bottom: 48rem;
}

.px-sm {
	padding-left: 48rem;
	padding-right: 48rem;
}

.py-md {
	padding-top: 64rem;
	padding-bottom: 64rem;
}

.px-md {
	padding-left: 64rem;
	padding-right: 64rem;
}

.py-lg {
	padding-top: 80rem;
	padding-bottom: 80rem;
}

.px-lg {
	padding-left: 80rem;
	padding-right: 80rem;
}

.py-xl {
	padding-top: 96rem;
	padding-bottom: 96rem;
}

.px-xl {
	padding-left: 96rem;
	padding-right: 96rem;
}

.py-2xl {
	padding-top: 112rem;
	padding-bottom: 112rem;
}

.px-2xl {
	padding-left: 112rem;
	padding-right: 112rem;
}

.py-3xl {
	padding-top: 128rem;
	padding-bottom: 128rem;
}

.px-3xl {
	padding-left: 128rem;
	padding-right: 128rem;
}

.py-4xl {
	padding-top: 144rem;
	padding-bottom: 144rem;
}

.px-4xl {
	padding-left: 144rem;
	padding-right: 144rem;
}

.py-5xl {
	padding-top: 160rem;
	padding-bottom: 160rem;
}

.px-5xl {
	padding-left: 160rem;
	padding-right: 160rem;
}

.py-auto {
	padding-top: auto;
	padding-bottom: auto;
}

.px-auto {
	padding-left: auto;
	padding-right: auto;
}

.pt-0 {
	padding-top: 0;
}

.pr-0 {
	padding-right: 0;
}

.pb-0 {
	padding-bottom: 0;
}

.pl-0 {
	padding-left: 0;
}

.pt-1 {
	padding-top: 0.25rem;
}

.pr-1 {
	padding-right: 0.25rem;
}

.pb-1 {
	padding-bottom: 0.25rem;
}

.pl-1 {
	padding-left: 0.25rem;
}

.pt-2 {
	padding-top: 0.5rem;
}

.pr-2 {
	padding-right: 0.5rem;
}

.pb-2 {
	padding-bottom: 0.5rem;
}

.pl-2 {
	padding-left: 0.5rem;
}

.pt-3 {
	padding-top: 0.75rem;
}

.pr-3 {
	padding-right: 0.75rem;
}

.pb-3 {
	padding-bottom: 0.75rem;
}

.pl-3 {
	padding-left: 0.75rem;
}

.pt-4 {
	padding-top: 0.4rem;
}

.pr-4 {
	padding-right: 0.4rem;
}

.pb-4 {
	padding-bottom: 0.4rem;
}

.pl-4 {
	padding-left: 0.4rem;
}

.pt-5 {
	padding-top: 1.25rem;
}

.pr-5 {
	padding-right: 1.25rem;
}

.pb-5 {
	padding-bottom: 1.25rem;
}

.pl-5 {
	padding-left: 1.25rem;
}

.pt-6 {
	padding-top: 0.6rem;
}

.pr-6 {
	padding-right: 0.6rem;
}

.pb-6 {
	padding-bottom: 0.6rem;
}

.pl-6 {
	padding-left: 0.6rem;
}

.pt-8 {
	padding-top: 0.8rem;
}

.pr-8 {
	padding-right: 0.8rem;
}

.pb-8 {
	padding-bottom: 0.8rem;
}

.pl-8 {
	padding-left: 0.8rem;
}

.pt-10 {
	padding-top: 2.5rem;
}

.pr-10 {
	padding-right: 2.5rem;
}

.pb-10 {
	padding-bottom: 2.5rem;
}

.pl-10 {
	padding-left: 2.5rem;
}

.pt-12 {
	padding-top: 1.2rem;
}

.pr-12 {
	padding-right: 1.2rem;
}

.pb-12 {
	padding-bottom: 1.2rem;
}

.pl-12 {
	padding-left: 1.2rem;
}

.pt-16 {
	padding-top: 1.6rem;
}

.pr-16 {
	padding-right: 1.6rem;
}

.pb-16 {
	padding-bottom: 1.6rem;
}

.pl-16 {
	padding-left: 1.6rem;
}

.pt-20 {
	padding-top: 2rem;
}

.pr-20 {
	padding-right: 2rem;
}

.pb-20 {
	padding-bottom: 2rem;
}

.pl-20 {
	padding-left: 2rem;
}

.pt-24 {
	padding-top: 2.4rem;
}

.pr-24 {
	padding-right: 2.4rem;
}

.pb-24 {
	padding-bottom: 2.4rem;
}

.pl-24 {
	padding-left: 2.4rem;
}

.pt-28 {
	padding-top: 2.8rem;
}

.pr-28 {
	padding-right: 2.8rem;
}

.pb-28 {
	padding-bottom: 2.8rem;
}

.pl-28 {
	padding-left: 2.8rem;
}

.pt-32 {
	padding-top: 3.2rem;
}

.pr-32 {
	padding-right: 3.2rem;
}

.pb-32 {
	padding-bottom: 3.2rem;
}

.pl-32 {
	padding-left: 3.2rem;
}

.pt-36 {
	padding-top: 3.6rem;
}

.pr-36 {
	padding-right: 3.6rem;
}

.pb-36 {
	padding-bottom: 3.6rem;
}

.pl-36 {
	padding-left: 3.6rem;
}

.pt-40 {
	padding-top: 4rem;
}

.pr-40 {
	padding-right: 4rem;
}

.pb-40 {
	padding-bottom: 4rem;
}

.pl-40 {
	padding-left: 4rem;
}

.pt-44 {
	padding-top: 4.4rem;
}

.pr-44 {
	padding-right: 4.4rem;
}

.pb-44 {
	padding-bottom: 4.4rem;
}

.pl-44 {
	padding-left: 4.4rem;
}

.pt-48 {
	padding-top: 4.8rem;
}

.pr-48 {
	padding-right: 4.8rem;
}

.pb-48 {
	padding-bottom: 4.8rem;
}

.pl-48 {
	padding-left: 4.8rem;
}

.pt-52 {
	padding-top: 5.2rem;
}

.pr-52 {
	padding-right: 5.2rem;
}

.pb-52 {
	padding-bottom: 5.2rem;
}

.pl-52 {
	padding-left: 5.2rem;
}

.pt-56 {
	padding-top: 5.6rem;
}

.pr-56 {
	padding-right: 5.6rem;
}

.pb-56 {
	padding-bottom: 5.6rem;
}

.pl-56 {
	padding-left: 5.6rem;
}

.pt-60 {
	padding-top: 6rem;
}

.pr-60 {
	padding-right: 6rem;
}

.pb-60 {
	padding-bottom: 6rem;
}

.pl-60 {
	padding-left: 6rem;
}

.pt-64 {
	padding-top: 6.4rem;
}

.pr-64 {
	padding-right: 6.4rem;
}

.pb-64 {
	padding-bottom: 6.4rem;
}

.pl-64 {
	padding-left: 6.4rem;
}

.pt-68 {
	padding-top: 6.8rem;
}

.pr-68 {
	padding-right: 6.8rem;
}

.pb-68 {
	padding-bottom: 6.8rem;
}

.pl-68 {
	padding-left: 6.8rem;
}

.pt-72 {
	padding-top: 7.2rem;
}

.pr-72 {
	padding-right: 7.2rem;
}

.pb-72 {
	padding-bottom: 7.2rem;
}

.pl-72 {
	padding-left: 7.2rem;
}

.pt-76 {
	padding-top: 7.6rem;
}

.pr-76 {
	padding-right: 7.6rem;
}

.pb-76 {
	padding-bottom: 7.6rem;
}

.pl-76 {
	padding-left: 7.6rem;
}

.pt-80 {
	padding-top: 8rem;
}

.pr-80 {
	padding-right: 8rem;
}

.pb-80 {
	padding-bottom: 8rem;
}

.pl-80 {
	padding-left: 8rem;
}

.pt-84 {
	padding-top: 8.4rem;
}

.pr-84 {
	padding-right: 8.4rem;
}

.pb-84 {
	padding-bottom: 8.4rem;
}

.pl-84 {
	padding-left: 8.4rem;
}

.pt-88 {
	padding-top: 8.8rem;
}

.pr-88 {
	padding-right: 8.8rem;
}

.pb-88 {
	padding-bottom: 8.8rem;
}

.pl-88 {
	padding-left: 8.8rem;
}

.pt-92 {
	padding-top: 9.2rem;
}

.pr-92 {
	padding-right: 9.2rem;
}

.pb-92 {
	padding-bottom: 9.2rem;
}

.pl-92 {
	padding-left: 9.2rem;
}

.pt-96 {
	padding-top: 9.6rem;
}

.pr-96 {
	padding-right: 9.6rem;
}

.pb-96 {
	padding-bottom: 9.6rem;
}

.pl-96 {
	padding-left: 9.6rem;
}

.pt-128 {
	padding-top: 12.8rem;
}

.pr-128 {
	padding-right: 12.8rem;
}

.pb-128 {
	padding-bottom: 12.8rem;
}

.pl-128 {
	padding-left: 12.8rem;
}

.pt-136 {
	padding-top: 13.6rem;
}

.pr-136 {
	padding-right: 13.6rem;
}

.pb-136 {
	padding-bottom: 13.6rem;
}

.pl-136 {
	padding-left: 13.6rem;
}

.pt-160 {
	padding-top: 16rem;
}

.pr-160 {
	padding-right: 16rem;
}

.pb-160 {
	padding-bottom: 16rem;
}

.pl-160 {
	padding-left: 16rem;
}

.pt-192 {
	padding-top: 19.2rem;
}

.pr-192 {
	padding-right: 19.2rem;
}

.pb-192 {
	padding-bottom: 19.2rem;
}

.pl-192 {
	padding-left: 19.2rem;
}

.pt-200 {
	padding-top: 20rem;
}

.pr-200 {
	padding-right: 20rem;
}

.pb-200 {
	padding-bottom: 20rem;
}

.pl-200 {
	padding-left: 20rem;
}

.pt-208 {
	padding-top: 20.8rem;
}

.pr-208 {
	padding-right: 20.8rem;
}

.pb-208 {
	padding-bottom: 20.8rem;
}

.pl-208 {
	padding-left: 20.8rem;
}

.pt-216 {
	padding-top: 21.6rem;
}

.pr-216 {
	padding-right: 21.6rem;
}

.pb-216 {
	padding-bottom: 21.6rem;
}

.pl-216 {
	padding-left: 21.6rem;
}

.pt-224 {
	padding-top: 22.4rem;
}

.pr-224 {
	padding-right: 22.4rem;
}

.pb-224 {
	padding-bottom: 22.4rem;
}

.pl-224 {
	padding-left: 22.4rem;
}

.pt-256 {
	padding-top: 25.6rem;
}

.pr-256 {
	padding-right: 25.6rem;
}

.pb-256 {
	padding-bottom: 25.6rem;
}

.pl-256 {
	padding-left: 25.6rem;
}

.pt-288 {
	padding-top: 28.8rem;
}

.pr-288 {
	padding-right: 28.8rem;
}

.pb-288 {
	padding-bottom: 28.8rem;
}

.pl-288 {
	padding-left: 28.8rem;
}

.pt-320 {
	padding-top: 32rem;
}

.pr-320 {
	padding-right: 32rem;
}

.pb-320 {
	padding-bottom: 32rem;
}

.pl-320 {
	padding-left: 32rem;
}

.pt-360 {
	padding-top: 36rem;
}

.pr-360 {
	padding-right: 36rem;
}

.pb-360 {
	padding-bottom: 36rem;
}

.pl-360 {
	padding-left: 36rem;
}

.pt-384 {
	padding-top: 38.4rem;
}

.pr-384 {
	padding-right: 38.4rem;
}

.pb-384 {
	padding-bottom: 38.4rem;
}

.pl-384 {
	padding-left: 38.4rem;
}

.pt-400 {
	padding-top: 40rem;
}

.pr-400 {
	padding-right: 40rem;
}

.pb-400 {
	padding-bottom: 40rem;
}

.pl-400 {
	padding-left: 40rem;
}

.pt-512 {
	padding-top: 51.2rem;
}

.pr-512 {
	padding-right: 51.2rem;
}

.pb-512 {
	padding-bottom: 51.2rem;
}

.pl-512 {
	padding-left: 51.2rem;
}

.pt-640 {
	padding-top: 64rem;
}

.pr-640 {
	padding-right: 64rem;
}

.pb-640 {
	padding-bottom: 64rem;
}

.pl-640 {
	padding-left: 64rem;
}

.pt-px {
	padding-top: 1px;
}

.pr-px {
	padding-right: 1px;
}

.pb-px {
	padding-bottom: 1px;
}

.pl-px {
	padding-left: 1px;
}

.pt-xs {
	padding-top: 32rem;
}

.pr-xs {
	padding-right: 32rem;
}

.pb-xs {
	padding-bottom: 32rem;
}

.pl-xs {
	padding-left: 32rem;
}

.pt-sm {
	padding-top: 48rem;
}

.pr-sm {
	padding-right: 48rem;
}

.pb-sm {
	padding-bottom: 48rem;
}

.pl-sm {
	padding-left: 48rem;
}

.pt-md {
	padding-top: 64rem;
}

.pr-md {
	padding-right: 64rem;
}

.pb-md {
	padding-bottom: 64rem;
}

.pl-md {
	padding-left: 64rem;
}

.pt-lg {
	padding-top: 80rem;
}

.pr-lg {
	padding-right: 80rem;
}

.pb-lg {
	padding-bottom: 80rem;
}

.pl-lg {
	padding-left: 80rem;
}

.pt-xl {
	padding-top: 96rem;
}

.pr-xl {
	padding-right: 96rem;
}

.pb-xl {
	padding-bottom: 96rem;
}

.pl-xl {
	padding-left: 96rem;
}

.pt-2xl {
	padding-top: 112rem;
}

.pr-2xl {
	padding-right: 112rem;
}

.pb-2xl {
	padding-bottom: 112rem;
}

.pl-2xl {
	padding-left: 112rem;
}

.pt-3xl {
	padding-top: 128rem;
}

.pr-3xl {
	padding-right: 128rem;
}

.pb-3xl {
	padding-bottom: 128rem;
}

.pl-3xl {
	padding-left: 128rem;
}

.pt-4xl {
	padding-top: 144rem;
}

.pr-4xl {
	padding-right: 144rem;
}

.pb-4xl {
	padding-bottom: 144rem;
}

.pl-4xl {
	padding-left: 144rem;
}

.pt-5xl {
	padding-top: 160rem;
}

.pr-5xl {
	padding-right: 160rem;
}

.pb-5xl {
	padding-bottom: 160rem;
}

.pl-5xl {
	padding-left: 160rem;
}

.pt-auto {
	padding-top: auto;
}

.pr-auto {
	padding-right: auto;
}

.pb-auto {
	padding-bottom: auto;
}

.pl-auto {
	padding-left: auto;
}

.pointer-events-none {
	pointer-events: none;
}

.pointer-events-auto {
	pointer-events: auto;
}

.static {
	position: static;
}

.fixed {
	position: fixed;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
}

.inset-0 {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.inset-auto {
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
}

.inset-y-0 {
	top: 0;
	bottom: 0;
}

.inset-x-0 {
	right: 0;
	left: 0;
}

.inset-y-auto {
	top: auto;
	bottom: auto;
}

.inset-x-auto {
	right: auto;
	left: auto;
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

.bottom-0 {
	bottom: 0;
}

.left-0 {
	left: 0;
}

.top-auto {
	top: auto;
}

.right-auto {
	right: auto;
}

.bottom-auto {
	bottom: auto;
}

.left-auto {
	left: auto;
}

.resize-none {
	resize: none;
}

.resize-y {
	resize: vertical;
}

.resize-x {
	resize: horizontal;
}

.resize {
	resize: both;
}

.shadow-0 {
	box-shadow: none;
}

.shadow-1 {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.shadow-2 {
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.shadow-3 {
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
		0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.shadow-4 {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.shadow-5 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.shadow-6 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.shadow-7 {
	box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
		0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.shadow-8 {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-9 {
	box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
		0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.shadow-10 {
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
		0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.shadow-11 {
	box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
		0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.shadow-12 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.shadow-13 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.shadow-14 {
	box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
		0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.shadow-15 {
	box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
		0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.shadow-16 {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
		0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.shadow-17 {
	box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
		0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.shadow-18 {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
		0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.shadow-19 {
	box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
		0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.shadow-20 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.shadow-21 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.shadow-22 {
	box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
		0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.shadow-23 {
	box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
		0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.shadow-24 {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
		0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.shadow {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.shadow-md {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.shadow-lg {
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
		0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.shadow-xl {
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
	box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-outline {
	box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.shadow-none {
	box-shadow: none;
}

.hover\:shadow-0:hover {
	box-shadow: none;
}

.hover\:shadow-1:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-2:hover {
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-3:hover {
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
		0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-4:hover {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-5:hover {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-6:hover {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-7:hover {
	box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
		0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-8:hover {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-9:hover {
	box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
		0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-10:hover {
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
		0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-11:hover {
	box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
		0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-12:hover {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-13:hover {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-14:hover {
	box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
		0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-15:hover {
	box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
		0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-16:hover {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
		0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-17:hover {
	box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
		0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-18:hover {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
		0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-19:hover {
	box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
		0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-20:hover {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-21:hover {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-22:hover {
	box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
		0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-23:hover {
	box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
		0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.hover\:shadow-24:hover {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
		0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.hover\:shadow:hover {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.hover\:shadow-md:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.hover\:shadow-lg:hover {
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
		0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.hover\:shadow-xl:hover {
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.hover\:shadow-2xl:hover {
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.hover\:shadow-inner:hover {
	box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.hover\:shadow-outline:hover {
	box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.hover\:shadow-none:hover {
	box-shadow: none;
}

.focus\:shadow-0:focus {
	box-shadow: none;
}

.focus\:shadow-1:focus {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-2:focus {
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-3:focus {
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
		0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-4:focus {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-5:focus {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-6:focus {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-7:focus {
	box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
		0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-8:focus {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-9:focus {
	box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
		0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-10:focus {
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
		0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-11:focus {
	box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
		0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-12:focus {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-13:focus {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-14:focus {
	box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
		0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-15:focus {
	box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
		0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-16:focus {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
		0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-17:focus {
	box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
		0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-18:focus {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
		0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-19:focus {
	box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
		0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-20:focus {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-21:focus {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
		0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-22:focus {
	box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
		0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-23:focus {
	box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
		0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.focus\:shadow-24:focus {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
		0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.focus\:shadow:focus {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.focus\:shadow-md:focus {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.focus\:shadow-lg:focus {
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
		0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.focus\:shadow-xl:focus {
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.focus\:shadow-2xl:focus {
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.focus\:shadow-inner:focus {
	box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.focus\:shadow-outline:focus {
	box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\:shadow-none:focus {
	box-shadow: none;
}

.fill-current {
	fill: currentColor;
}

.stroke-current {
	stroke: currentColor;
}

.table-auto {
	table-layout: auto;
}

.table-fixed {
	table-layout: fixed;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

.text-transparent {
	color: transparent;
}

.text-black {
	color: #22292f;
}

.text-white {
	color: #ffffff;
}

.text-gray-100 {
	color: #f7fafc;
}

.text-gray-200 {
	color: #edf2f7;
}

.text-gray-300 {
	color: #e2e8f0;
}

.text-gray-400 {
	color: #cbd5e0;
}

.text-gray-500 {
	color: #a0aec0;
}

.text-gray-600 {
	color: #718096;
}

.text-gray-700 {
	color: #4a5568;
}

.text-gray-800 {
	color: #2d3748;
}

.text-gray-900 {
	color: #1a202c;
}

.text-red {
	color: #e3342f;
}

.text-orange {
	color: #f6993f;
}

.text-yellow {
	color: #ffed4a;
}

.text-green {
	color: #38c172;
}

.text-teal {
	color: #4dc0b5;
}

.text-blue {
	color: #3490dc;
}

.text-indigo {
	color: #6574cd;
}

.text-purple {
	color: #9561e2;
}

.text-pink {
	color: #f66d9b;
}

.text-grey-darkest {
	color: #3d4852;
}

.text-grey-darker {
	color: #606f7b;
}

.text-grey-dark {
	color: #8795a1;
}

.text-grey {
	color: #b8c2cc;
}

.text-grey-light {
	color: #dae1e7;
}

.text-grey-lighter {
	color: #f1f5f8;
}

.text-grey-lightest {
	color: #f8fafc;
}

.text-red-darkest {
	color: #3b0d0c;
}

.text-red-darker {
	color: #621b18;
}

.text-red-dark {
	color: #cc1f1a;
}

.text-red-light {
	color: #ef5753;
}

.text-red-lighter {
	color: #f9acaa;
}

.text-red-lightest {
	color: #fcebea;
}

.text-orange-darkest {
	color: #462a16;
}

.text-orange-darker {
	color: #613b1f;
}

.text-orange-dark {
	color: #de751f;
}

.text-orange-light {
	color: #faad63;
}

.text-orange-lighter {
	color: #fcd9b6;
}

.text-orange-lightest {
	color: #fff5eb;
}

.text-yellow-darkest {
	color: #453411;
}

.text-yellow-darker {
	color: #684f1d;
}

.text-yellow-dark {
	color: #f2d024;
}

.text-yellow-light {
	color: #fff382;
}

.text-yellow-lighter {
	color: #fff9c2;
}

.text-yellow-lightest {
	color: #fcfbeb;
}

.text-green-darkest {
	color: #0f2f21;
}

.text-green-darker {
	color: #1a4731;
}

.text-green-dark {
	color: #1f9d55;
}

.text-green-light {
	color: #51d88a;
}

.text-green-lighter {
	color: #a2f5bf;
}

.text-green-lightest {
	color: #e3fcec;
}

.text-teal-darkest {
	color: #0d3331;
}

.text-teal-darker {
	color: #20504f;
}

.text-teal-dark {
	color: #38a89d;
}

.text-teal-light {
	color: #64d5ca;
}

.text-teal-lighter {
	color: #a0f0ed;
}

.text-teal-lightest {
	color: #e8fffe;
}

.text-blue-darkest {
	color: #12283a;
}

.text-blue-darker {
	color: #1c3d5a;
}

.text-blue-dark {
	color: #2779bd;
}

.text-blue-light {
	color: #6cb2eb;
}

.text-blue-lighter {
	color: #bcdefa;
}

.text-blue-lightest {
	color: #eff8ff;
}

.text-indigo-darkest {
	color: #191e38;
}

.text-indigo-darker {
	color: #2f365f;
}

.text-indigo-dark {
	color: #5661b3;
}

.text-indigo-light {
	color: #7886d7;
}

.text-indigo-lighter {
	color: #b2b7ff;
}

.text-indigo-lightest {
	color: #e6e8ff;
}

.text-purple-darkest {
	color: #21183c;
}

.text-purple-darker {
	color: #382b5f;
}

.text-purple-dark {
	color: #794acf;
}

.text-purple-light {
	color: #a779e9;
}

.text-purple-lighter {
	color: #d6bbfc;
}

.text-purple-lightest {
	color: #f3ebff;
}

.text-pink-darkest {
	color: #451225;
}

.text-pink-darker {
	color: #6f213f;
}

.text-pink-dark {
	color: #eb5286;
}

.text-pink-light {
	color: #fa7ea8;
}

.text-pink-lighter {
	color: #ffbbca;
}

.text-pink-lightest {
	color: #ffebef;
}

.hover\:text-transparent:hover {
	color: transparent;
}

.hover\:text-black:hover {
	color: #22292f;
}

.hover\:text-white:hover {
	color: #ffffff;
}

.hover\:text-gray-100:hover {
	color: #f7fafc;
}

.hover\:text-gray-200:hover {
	color: #edf2f7;
}

.hover\:text-gray-300:hover {
	color: #e2e8f0;
}

.hover\:text-gray-400:hover {
	color: #cbd5e0;
}

.hover\:text-gray-500:hover {
	color: #a0aec0;
}

.hover\:text-gray-600:hover {
	color: #718096;
}

.hover\:text-gray-700:hover {
	color: #4a5568;
}

.hover\:text-gray-800:hover {
	color: #2d3748;
}

.hover\:text-gray-900:hover {
	color: #1a202c;
}

.hover\:text-red:hover {
	color: #e3342f;
}

.hover\:text-orange:hover {
	color: #f6993f;
}

.hover\:text-yellow:hover {
	color: #ffed4a;
}

.hover\:text-green:hover {
	color: #38c172;
}

.hover\:text-teal:hover {
	color: #4dc0b5;
}

.hover\:text-blue:hover {
	color: #3490dc;
}

.hover\:text-indigo:hover {
	color: #6574cd;
}

.hover\:text-purple:hover {
	color: #9561e2;
}

.hover\:text-pink:hover {
	color: #f66d9b;
}

.hover\:text-grey-darkest:hover {
	color: #3d4852;
}

.hover\:text-grey-darker:hover {
	color: #606f7b;
}

.hover\:text-grey-dark:hover {
	color: #8795a1;
}

.hover\:text-grey:hover {
	color: #b8c2cc;
}

.hover\:text-grey-light:hover {
	color: #dae1e7;
}

.hover\:text-grey-lighter:hover {
	color: #f1f5f8;
}

.hover\:text-grey-lightest:hover {
	color: #f8fafc;
}

.hover\:text-red-darkest:hover {
	color: #3b0d0c;
}

.hover\:text-red-darker:hover {
	color: #621b18;
}

.hover\:text-red-dark:hover {
	color: #cc1f1a;
}

.hover\:text-red-light:hover {
	color: #ef5753;
}

.hover\:text-red-lighter:hover {
	color: #f9acaa;
}

.hover\:text-red-lightest:hover {
	color: #fcebea;
}

.hover\:text-orange-darkest:hover {
	color: #462a16;
}

.hover\:text-orange-darker:hover {
	color: #613b1f;
}

.hover\:text-orange-dark:hover {
	color: #de751f;
}

.hover\:text-orange-light:hover {
	color: #faad63;
}

.hover\:text-orange-lighter:hover {
	color: #fcd9b6;
}

.hover\:text-orange-lightest:hover {
	color: #fff5eb;
}

.hover\:text-yellow-darkest:hover {
	color: #453411;
}

.hover\:text-yellow-darker:hover {
	color: #684f1d;
}

.hover\:text-yellow-dark:hover {
	color: #f2d024;
}

.hover\:text-yellow-light:hover {
	color: #fff382;
}

.hover\:text-yellow-lighter:hover {
	color: #fff9c2;
}

.hover\:text-yellow-lightest:hover {
	color: #fcfbeb;
}

.hover\:text-green-darkest:hover {
	color: #0f2f21;
}

.hover\:text-green-darker:hover {
	color: #1a4731;
}

.hover\:text-green-dark:hover {
	color: #1f9d55;
}

.hover\:text-green-light:hover {
	color: #51d88a;
}

.hover\:text-green-lighter:hover {
	color: #a2f5bf;
}

.hover\:text-green-lightest:hover {
	color: #e3fcec;
}

.hover\:text-teal-darkest:hover {
	color: #0d3331;
}

.hover\:text-teal-darker:hover {
	color: #20504f;
}

.hover\:text-teal-dark:hover {
	color: #38a89d;
}

.hover\:text-teal-light:hover {
	color: #64d5ca;
}

.hover\:text-teal-lighter:hover {
	color: #a0f0ed;
}

.hover\:text-teal-lightest:hover {
	color: #e8fffe;
}

.hover\:text-blue-darkest:hover {
	color: #12283a;
}

.hover\:text-blue-darker:hover {
	color: #1c3d5a;
}

.hover\:text-blue-dark:hover {
	color: #2779bd;
}

.hover\:text-blue-light:hover {
	color: #6cb2eb;
}

.hover\:text-blue-lighter:hover {
	color: #bcdefa;
}

.hover\:text-blue-lightest:hover {
	color: #eff8ff;
}

.hover\:text-indigo-darkest:hover {
	color: #191e38;
}

.hover\:text-indigo-darker:hover {
	color: #2f365f;
}

.hover\:text-indigo-dark:hover {
	color: #5661b3;
}

.hover\:text-indigo-light:hover {
	color: #7886d7;
}

.hover\:text-indigo-lighter:hover {
	color: #b2b7ff;
}

.hover\:text-indigo-lightest:hover {
	color: #e6e8ff;
}

.hover\:text-purple-darkest:hover {
	color: #21183c;
}

.hover\:text-purple-darker:hover {
	color: #382b5f;
}

.hover\:text-purple-dark:hover {
	color: #794acf;
}

.hover\:text-purple-light:hover {
	color: #a779e9;
}

.hover\:text-purple-lighter:hover {
	color: #d6bbfc;
}

.hover\:text-purple-lightest:hover {
	color: #f3ebff;
}

.hover\:text-pink-darkest:hover {
	color: #451225;
}

.hover\:text-pink-darker:hover {
	color: #6f213f;
}

.hover\:text-pink-dark:hover {
	color: #eb5286;
}

.hover\:text-pink-light:hover {
	color: #fa7ea8;
}

.hover\:text-pink-lighter:hover {
	color: #ffbbca;
}

.hover\:text-pink-lightest:hover {
	color: #ffebef;
}

.focus\:text-transparent:focus {
	color: transparent;
}

.focus\:text-black:focus {
	color: #22292f;
}

.focus\:text-white:focus {
	color: #ffffff;
}

.focus\:text-gray-100:focus {
	color: #f7fafc;
}

.focus\:text-gray-200:focus {
	color: #edf2f7;
}

.focus\:text-gray-300:focus {
	color: #e2e8f0;
}

.focus\:text-gray-400:focus {
	color: #cbd5e0;
}

.focus\:text-gray-500:focus {
	color: #a0aec0;
}

.focus\:text-gray-600:focus {
	color: #718096;
}

.focus\:text-gray-700:focus {
	color: #4a5568;
}

.focus\:text-gray-800:focus {
	color: #2d3748;
}

.focus\:text-gray-900:focus {
	color: #1a202c;
}

.focus\:text-red:focus {
	color: #e3342f;
}

.focus\:text-orange:focus {
	color: #f6993f;
}

.focus\:text-yellow:focus {
	color: #ffed4a;
}

.focus\:text-green:focus {
	color: #38c172;
}

.focus\:text-teal:focus {
	color: #4dc0b5;
}

.focus\:text-blue:focus {
	color: #3490dc;
}

.focus\:text-indigo:focus {
	color: #6574cd;
}

.focus\:text-purple:focus {
	color: #9561e2;
}

.focus\:text-pink:focus {
	color: #f66d9b;
}

.focus\:text-grey-darkest:focus {
	color: #3d4852;
}

.focus\:text-grey-darker:focus {
	color: #606f7b;
}

.focus\:text-grey-dark:focus {
	color: #8795a1;
}

.focus\:text-grey:focus {
	color: #b8c2cc;
}

.focus\:text-grey-light:focus {
	color: #dae1e7;
}

.focus\:text-grey-lighter:focus {
	color: #f1f5f8;
}

.focus\:text-grey-lightest:focus {
	color: #f8fafc;
}

.focus\:text-red-darkest:focus {
	color: #3b0d0c;
}

.focus\:text-red-darker:focus {
	color: #621b18;
}

.focus\:text-red-dark:focus {
	color: #cc1f1a;
}

.focus\:text-red-light:focus {
	color: #ef5753;
}

.focus\:text-red-lighter:focus {
	color: #f9acaa;
}

.focus\:text-red-lightest:focus {
	color: #fcebea;
}

.focus\:text-orange-darkest:focus {
	color: #462a16;
}

.focus\:text-orange-darker:focus {
	color: #613b1f;
}

.focus\:text-orange-dark:focus {
	color: #de751f;
}

.focus\:text-orange-light:focus {
	color: #faad63;
}

.focus\:text-orange-lighter:focus {
	color: #fcd9b6;
}

.focus\:text-orange-lightest:focus {
	color: #fff5eb;
}

.focus\:text-yellow-darkest:focus {
	color: #453411;
}

.focus\:text-yellow-darker:focus {
	color: #684f1d;
}

.focus\:text-yellow-dark:focus {
	color: #f2d024;
}

.focus\:text-yellow-light:focus {
	color: #fff382;
}

.focus\:text-yellow-lighter:focus {
	color: #fff9c2;
}

.focus\:text-yellow-lightest:focus {
	color: #fcfbeb;
}

.focus\:text-green-darkest:focus {
	color: #0f2f21;
}

.focus\:text-green-darker:focus {
	color: #1a4731;
}

.focus\:text-green-dark:focus {
	color: #1f9d55;
}

.focus\:text-green-light:focus {
	color: #51d88a;
}

.focus\:text-green-lighter:focus {
	color: #a2f5bf;
}

.focus\:text-green-lightest:focus {
	color: #e3fcec;
}

.focus\:text-teal-darkest:focus {
	color: #0d3331;
}

.focus\:text-teal-darker:focus {
	color: #20504f;
}

.focus\:text-teal-dark:focus {
	color: #38a89d;
}

.focus\:text-teal-light:focus {
	color: #64d5ca;
}

.focus\:text-teal-lighter:focus {
	color: #a0f0ed;
}

.focus\:text-teal-lightest:focus {
	color: #e8fffe;
}

.focus\:text-blue-darkest:focus {
	color: #12283a;
}

.focus\:text-blue-darker:focus {
	color: #1c3d5a;
}

.focus\:text-blue-dark:focus {
	color: #2779bd;
}

.focus\:text-blue-light:focus {
	color: #6cb2eb;
}

.focus\:text-blue-lighter:focus {
	color: #bcdefa;
}

.focus\:text-blue-lightest:focus {
	color: #eff8ff;
}

.focus\:text-indigo-darkest:focus {
	color: #191e38;
}

.focus\:text-indigo-darker:focus {
	color: #2f365f;
}

.focus\:text-indigo-dark:focus {
	color: #5661b3;
}

.focus\:text-indigo-light:focus {
	color: #7886d7;
}

.focus\:text-indigo-lighter:focus {
	color: #b2b7ff;
}

.focus\:text-indigo-lightest:focus {
	color: #e6e8ff;
}

.focus\:text-purple-darkest:focus {
	color: #21183c;
}

.focus\:text-purple-darker:focus {
	color: #382b5f;
}

.focus\:text-purple-dark:focus {
	color: #794acf;
}

.focus\:text-purple-light:focus {
	color: #a779e9;
}

.focus\:text-purple-lighter:focus {
	color: #d6bbfc;
}

.focus\:text-purple-lightest:focus {
	color: #f3ebff;
}

.focus\:text-pink-darkest:focus {
	color: #451225;
}

.focus\:text-pink-darker:focus {
	color: #6f213f;
}

.focus\:text-pink-dark:focus {
	color: #eb5286;
}

.focus\:text-pink-light:focus {
	color: #fa7ea8;
}

.focus\:text-pink-lighter:focus {
	color: #ffbbca;
}

.focus\:text-pink-lightest:focus {
	color: #ffebef;
}

.text-10 {
	font-size: 1rem;
}

.text-11 {
	font-size: 1.1rem;
}

.text-12 {
	font-size: 1.2rem;
}

.text-13 {
	font-size: 1.3rem;
}

.text-14 {
	font-size: 1.4rem;
}

.text-15 {
	font-size: 1.5rem;
}

.text-16 {
	font-size: 1.6rem;
}

.text-17 {
	font-size: 1.7rem;
}

.text-18 {
	font-size: 1.8rem;
}

.text-19 {
	font-size: 1.9rem;
}

.text-20 {
	font-size: 2rem;
}

.text-24 {
	font-size: 2.4rem;
}

.text-28 {
	font-size: 2.8rem;
}

.text-32 {
	font-size: 3.2rem;
}

.text-36 {
	font-size: 3.6rem;
}

.text-40 {
	font-size: 4rem;
}

.text-44 {
	font-size: 4.4rem;
}

.text-48 {
	font-size: 4.8rem;
}

.text-52 {
	font-size: 5.2rem;
}

.text-56 {
	font-size: 5.6rem;
}

.text-60 {
	font-size: 6rem;
}

.text-64 {
	font-size: 6.4rem;
}

.text-68 {
	font-size: 6.8rem;
}

.text-72 {
	font-size: 7.2rem;
}

.text-96 {
	font-size: 9.6rem;
}

.text-128 {
	font-size: 12.8rem;
}

.text-xs {
	font-size: 1.2rem;
}

.text-sm {
	font-size: 1.4rem;
}

.text-base {
	font-size: 1.6rem;
}

.text-lg {
	font-size: 1.8rem;
}

.text-xl {
	font-size: 2rem;
}

.text-2xl {
	font-size: 2.4rem;
}

.text-3xl {
	font-size: 3rem;
}

.text-4xl {
	font-size: 3.6rem;
}

.text-5xl {
	font-size: 4rem;
}

.text-6xl {
	font-size: 4.8rem;
}

.italic {
	font-style: italic;
}

.not-italic {
	font-style: normal;
}

.hover\:italic:hover {
	font-style: italic;
}

.hover\:not-italic:hover {
	font-style: normal;
}

.focus\:italic:focus {
	font-style: italic;
}

.focus\:not-italic:focus {
	font-style: normal;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalize {
	text-transform: capitalize;
}

.normal-case {
	text-transform: none;
}

.underline {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
}

.no-underline {
	text-decoration: none;
}

.hover\:underline:hover {
	text-decoration: underline;
}

.hover\:line-through:hover {
	text-decoration: line-through;
}

.hover\:no-underline:hover {
	text-decoration: none;
}

.focus\:underline:focus {
	text-decoration: underline;
}

.focus\:line-through:focus {
	text-decoration: line-through;
}

.focus\:no-underline:focus {
	text-decoration: none;
}

.antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}

.tracking-tighter {
	letter-spacing: -0.05em;
}

.tracking-tight {
	letter-spacing: -0.025em;
}

.tracking-normal {
	letter-spacing: 0;
}

.tracking-wide {
	letter-spacing: 0.025em;
}

.tracking-wider {
	letter-spacing: 0.05em;
}

.tracking-widest {
	letter-spacing: 0.1em;
}

.select-none {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select-text {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.select-all {
	-webkit-user-select: all;
	-moz-user-select: all;
	-ms-user-select: all;
	user-select: all;
}

.select-auto {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}

.align-baseline {
	vertical-align: baseline;
}

.align-top {
	vertical-align: top;
}

.align-middle {
	vertical-align: middle;
}

.align-bottom {
	vertical-align: bottom;
}

.align-text-top {
	vertical-align: text-top;
}

.align-text-bottom {
	vertical-align: text-bottom;
}

.visible {
	visibility: visible;
}

.invisible {
	visibility: hidden;
}

.whitespace-normal {
	white-space: normal;
}

.whitespace-no-wrap {
	white-space: nowrap;
}

.whitespace-pre {
	white-space: pre;
}

.whitespace-pre-line {
	white-space: pre-line;
}

.whitespace-pre-wrap {
	white-space: pre-wrap;
}

.break-normal {
	overflow-wrap: normal;
	word-break: normal;
}

.break-words {
	overflow-wrap: break-word;
}

.break-all {
	word-break: break-all;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.w-0 {
	width: 0;
}

.w-1 {
	width: 0.25rem;
}

.w-2 {
	width: 0.5rem;
}

.w-3 {
	width: 0.75rem;
}

.w-4 {
	width: 0.4rem;
}

.w-5 {
	width: 1.25rem;
}

.w-6 {
	width: 0.6rem;
}

.w-8 {
	width: 0.8rem;
}

.w-10 {
	width: 2.5rem;
}

.w-12 {
	width: 1.2rem;
}

.w-16 {
	width: 1.6rem;
}

.w-20 {
	width: 2rem;
}

.w-24 {
	width: 2.4rem;
}

.w-28 {
	width: 2.8rem;
}

.w-32 {
	width: 3.2rem;
}

.w-36 {
	width: 3.6rem;
}

.w-40 {
	width: 4rem;
}

.w-44 {
	width: 4.4rem;
}

.w-48 {
	width: 4.8rem;
}

.w-52 {
	width: 5.2rem;
}

.w-56 {
	width: 5.6rem;
}

.w-60 {
	width: 6rem;
}

.w-64 {
	width: 6.4rem;
}

.w-68 {
	width: 6.8rem;
}

.w-72 {
	width: 7.2rem;
}

.w-76 {
	width: 7.6rem;
}

.w-80 {
	width: 8rem;
}

.w-84 {
	width: 8.4rem;
}

.w-88 {
	width: 8.8rem;
}

.w-92 {
	width: 9.2rem;
}

.w-96 {
	width: 9.6rem;
}

.w-128 {
	width: 12.8rem;
}

.w-136 {
	width: 13.6rem;
}

.w-160 {
	width: 16rem;
}

.w-192 {
	width: 19.2rem;
}

.w-200 {
	width: 20rem;
}

.w-208 {
	width: 20.8rem;
}

.w-216 {
	width: 21.6rem;
}

.w-224 {
	width: 22.4rem;
}

.w-256 {
	width: 25.6rem;
}

.w-288 {
	width: 28.8rem;
}

.w-320 {
	width: 32rem;
}

.w-360 {
	width: 36rem;
}

.w-384 {
	width: 38.4rem;
}

.w-400 {
	width: 40rem;
}

.w-512 {
	width: 51.2rem;
}

.w-640 {
	width: 64rem;
}

.w-auto {
	width: auto;
}

.w-px {
	width: 1px;
}

.w-xs {
	width: 32rem;
}

.w-sm {
	width: 48rem;
}

.w-md {
	width: 64rem;
}

.w-lg {
	width: 80rem;
}

.w-xl {
	width: 96rem;
}

.w-2xl {
	width: 112rem;
}

.w-3xl {
	width: 128rem;
}

.w-4xl {
	width: 144rem;
}

.w-5xl {
	width: 160rem;
}

.w-1\/2 {
	width: 50%;
}

.w-1\/3 {
	width: 33.33333%;
}

.w-2\/3 {
	width: 66.66667%;
}

.w-1\/4 {
	width: 25%;
}

.w-2\/4 {
	width: 50%;
}

.w-3\/4 {
	width: 75%;
}

.w-1\/5 {
	width: 20%;
}

.w-2\/5 {
	width: 40%;
}

.w-3\/5 {
	width: 60%;
}

.w-4\/5 {
	width: 80%;
}

.w-1\/6 {
	width: 16.66667%;
}

.w-2\/6 {
	width: 33.33333%;
}

.w-3\/6 {
	width: 50%;
}

.w-4\/6 {
	width: 66.66667%;
}

.w-5\/6 {
	width: 83.33333%;
}

.w-1\/12 {
	width: 8.33333%;
}

.w-2\/12 {
	width: 16.66667%;
}

.w-3\/12 {
	width: 25%;
}

.w-4\/12 {
	width: 33.33333%;
}

.w-5\/12 {
	width: 41.66667%;
}

.w-6\/12 {
	width: 50%;
}

.w-7\/12 {
	width: 58.33333%;
}

.w-8\/12 {
	width: 66.66667%;
}

.w-9\/12 {
	width: 75%;
}

.w-10\/12 {
	width: 83.33333%;
}

.w-11\/12 {
	width: 91.66667%;
}

.w-full {
	width: 100%;
}

.w-screen {
	width: 100vw;
}

.z-0 {
	z-index: 0;
}

.z-10 {
	z-index: 10;
}

.z-20 {
	z-index: 20;
}

.z-30 {
	z-index: 30;
}

.z-40 {
	z-index: 40;
}

.z-50 {
	z-index: 50;
}

.z-99 {
	z-index: 99;
}

.z-999 {
	z-index: 999;
}

.z-9999 {
	z-index: 9999;
}

.z-auto {
	z-index: auto;
}

@media (min-width: 600px) {
	.sm\:appearance-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.sm\:bg-fixed {
		background-attachment: fixed;
	}

	.sm\:bg-local {
		background-attachment: local;
	}

	.sm\:bg-scroll {
		background-attachment: scroll;
	}

	.sm\:bg-transparent {
		background-color: transparent;
	}

	.sm\:bg-black {
		background-color: #22292f;
	}

	.sm\:bg-white {
		background-color: #ffffff;
	}

	.sm\:bg-gray-100 {
		background-color: #f7fafc;
	}

	.sm\:bg-gray-200 {
		background-color: #edf2f7;
	}

	.sm\:bg-gray-300 {
		background-color: #e2e8f0;
	}

	.sm\:bg-gray-400 {
		background-color: #cbd5e0;
	}

	.sm\:bg-gray-500 {
		background-color: #a0aec0;
	}

	.sm\:bg-gray-600 {
		background-color: #718096;
	}

	.sm\:bg-gray-700 {
		background-color: #4a5568;
	}

	.sm\:bg-gray-800 {
		background-color: #2d3748;
	}

	.sm\:bg-gray-900 {
		background-color: #1a202c;
	}

	.sm\:bg-red {
		background-color: #e3342f;
	}

	.sm\:bg-orange {
		background-color: #f6993f;
	}

	.sm\:bg-yellow {
		background-color: #ffed4a;
	}

	.sm\:bg-green {
		background-color: #38c172;
	}

	.sm\:bg-teal {
		background-color: #4dc0b5;
	}

	.sm\:bg-blue {
		background-color: #3490dc;
	}

	.sm\:bg-indigo {
		background-color: #6574cd;
	}

	.sm\:bg-purple {
		background-color: #9561e2;
	}

	.sm\:bg-pink {
		background-color: #f66d9b;
	}

	.sm\:bg-grey-darkest {
		background-color: #3d4852;
	}

	.sm\:bg-grey-darker {
		background-color: #606f7b;
	}

	.sm\:bg-grey-dark {
		background-color: #8795a1;
	}

	.sm\:bg-grey {
		background-color: #b8c2cc;
	}

	.sm\:bg-grey-light {
		background-color: #dae1e7;
	}

	.sm\:bg-grey-lighter {
		background-color: #f1f5f8;
	}

	.sm\:bg-grey-lightest {
		background-color: #f8fafc;
	}

	.sm\:bg-red-darkest {
		background-color: #3b0d0c;
	}

	.sm\:bg-red-darker {
		background-color: #621b18;
	}

	.sm\:bg-red-dark {
		background-color: #cc1f1a;
	}

	.sm\:bg-red-light {
		background-color: #ef5753;
	}

	.sm\:bg-red-lighter {
		background-color: #f9acaa;
	}

	.sm\:bg-red-lightest {
		background-color: #fcebea;
	}

	.sm\:bg-orange-darkest {
		background-color: #462a16;
	}

	.sm\:bg-orange-darker {
		background-color: #613b1f;
	}

	.sm\:bg-orange-dark {
		background-color: #de751f;
	}

	.sm\:bg-orange-light {
		background-color: #faad63;
	}

	.sm\:bg-orange-lighter {
		background-color: #fcd9b6;
	}

	.sm\:bg-orange-lightest {
		background-color: #fff5eb;
	}

	.sm\:bg-yellow-darkest {
		background-color: #453411;
	}

	.sm\:bg-yellow-darker {
		background-color: #684f1d;
	}

	.sm\:bg-yellow-dark {
		background-color: #f2d024;
	}

	.sm\:bg-yellow-light {
		background-color: #fff382;
	}

	.sm\:bg-yellow-lighter {
		background-color: #fff9c2;
	}

	.sm\:bg-yellow-lightest {
		background-color: #fcfbeb;
	}

	.sm\:bg-green-darkest {
		background-color: #0f2f21;
	}

	.sm\:bg-green-darker {
		background-color: #1a4731;
	}

	.sm\:bg-green-dark {
		background-color: #1f9d55;
	}

	.sm\:bg-green-light {
		background-color: #51d88a;
	}

	.sm\:bg-green-lighter {
		background-color: #a2f5bf;
	}

	.sm\:bg-green-lightest {
		background-color: #e3fcec;
	}

	.sm\:bg-teal-darkest {
		background-color: #0d3331;
	}

	.sm\:bg-teal-darker {
		background-color: #20504f;
	}

	.sm\:bg-teal-dark {
		background-color: #38a89d;
	}

	.sm\:bg-teal-light {
		background-color: #64d5ca;
	}

	.sm\:bg-teal-lighter {
		background-color: #a0f0ed;
	}

	.sm\:bg-teal-lightest {
		background-color: #e8fffe;
	}

	.sm\:bg-blue-darkest {
		background-color: #12283a;
	}

	.sm\:bg-blue-darker {
		background-color: #1c3d5a;
	}

	.sm\:bg-blue-dark {
		background-color: #2779bd;
	}

	.sm\:bg-blue-light {
		background-color: #6cb2eb;
	}

	.sm\:bg-blue-lighter {
		background-color: #bcdefa;
	}

	.sm\:bg-blue-lightest {
		background-color: #eff8ff;
	}

	.sm\:bg-indigo-darkest {
		background-color: #191e38;
	}

	.sm\:bg-indigo-darker {
		background-color: #2f365f;
	}

	.sm\:bg-indigo-dark {
		background-color: #5661b3;
	}

	.sm\:bg-indigo-light {
		background-color: #7886d7;
	}

	.sm\:bg-indigo-lighter {
		background-color: #b2b7ff;
	}

	.sm\:bg-indigo-lightest {
		background-color: #e6e8ff;
	}

	.sm\:bg-purple-darkest {
		background-color: #180f3d;
	}

	.sm\:bg-purple-darker {
		background-color: #22184b;
	}

	.sm\:bg-purple-dark {
		background-color: #52468a;
	}

	.sm\:bg-purple-light {
		background-color: #a779e9;
	}

	.sm\:bg-purple-lighter {
		background-color: #d6bbfc;
	}

	.sm\:bg-purple-lightest {
		background-color: #f3ebff;
	}

	.sm\:bg-pink-darkest {
		background-color: #451225;
	}

	.sm\:bg-pink-darker {
		background-color: #6f213f;
	}

	.sm\:bg-pink-dark {
		background-color: #eb5286;
	}

	.sm\:bg-pink-light {
		background-color: #fa7ea8;
	}

	.sm\:bg-pink-lighter {
		background-color: #ffbbca;
	}

	.sm\:bg-pink-lightest {
		background-color: #ffebef;
	}

	.sm\:hover\:bg-transparent:hover {
		background-color: transparent;
	}

	.sm\:hover\:bg-black:hover {
		background-color: #22292f;
	}

	.sm\:hover\:bg-white:hover {
		background-color: #ffffff;
	}

	.sm\:hover\:bg-gray-100:hover {
		background-color: #f7fafc;
	}

	.sm\:hover\:bg-gray-200:hover {
		background-color: #edf2f7;
	}

	.sm\:hover\:bg-gray-300:hover {
		background-color: #e2e8f0;
	}

	.sm\:hover\:bg-gray-400:hover {
		background-color: #cbd5e0;
	}

	.sm\:hover\:bg-gray-500:hover {
		background-color: #a0aec0;
	}

	.sm\:hover\:bg-gray-600:hover {
		background-color: #718096;
	}

	.sm\:hover\:bg-gray-700:hover {
		background-color: #4a5568;
	}

	.sm\:hover\:bg-gray-800:hover {
		background-color: #2d3748;
	}

	.sm\:hover\:bg-gray-900:hover {
		background-color: #1a202c;
	}

	.sm\:hover\:bg-red:hover {
		background-color: #e3342f;
	}

	.sm\:hover\:bg-orange:hover {
		background-color: #f6993f;
	}

	.sm\:hover\:bg-yellow:hover {
		background-color: #ffed4a;
	}

	.sm\:hover\:bg-green:hover {
		background-color: #38c172;
	}

	.sm\:hover\:bg-teal:hover {
		background-color: #4dc0b5;
	}

	.sm\:hover\:bg-blue:hover {
		background-color: #3490dc;
	}

	.sm\:hover\:bg-indigo:hover {
		background-color: #6574cd;
	}

	.sm\:hover\:bg-purple:hover {
		background-color: #9561e2;
	}

	.sm\:hover\:bg-pink:hover {
		background-color: #f66d9b;
	}

	.sm\:hover\:bg-grey-darkest:hover {
		background-color: #3d4852;
	}

	.sm\:hover\:bg-grey-darker:hover {
		background-color: #606f7b;
	}

	.sm\:hover\:bg-grey-dark:hover {
		background-color: #8795a1;
	}

	.sm\:hover\:bg-grey:hover {
		background-color: #b8c2cc;
	}

	.sm\:hover\:bg-grey-light:hover {
		background-color: #dae1e7;
	}

	.sm\:hover\:bg-grey-lighter:hover {
		background-color: #f1f5f8;
	}

	.sm\:hover\:bg-grey-lightest:hover {
		background-color: #f8fafc;
	}

	.sm\:hover\:bg-red-darkest:hover {
		background-color: #3b0d0c;
	}

	.sm\:hover\:bg-red-darker:hover {
		background-color: #621b18;
	}

	.sm\:hover\:bg-red-dark:hover {
		background-color: #cc1f1a;
	}

	.sm\:hover\:bg-red-light:hover {
		background-color: #ef5753;
	}

	.sm\:hover\:bg-red-lighter:hover {
		background-color: #f9acaa;
	}

	.sm\:hover\:bg-red-lightest:hover {
		background-color: #fcebea;
	}

	.sm\:hover\:bg-orange-darkest:hover {
		background-color: #462a16;
	}

	.sm\:hover\:bg-orange-darker:hover {
		background-color: #613b1f;
	}

	.sm\:hover\:bg-orange-dark:hover {
		background-color: #de751f;
	}

	.sm\:hover\:bg-orange-light:hover {
		background-color: #faad63;
	}

	.sm\:hover\:bg-orange-lighter:hover {
		background-color: #fcd9b6;
	}

	.sm\:hover\:bg-orange-lightest:hover {
		background-color: #fff5eb;
	}

	.sm\:hover\:bg-yellow-darkest:hover {
		background-color: #453411;
	}

	.sm\:hover\:bg-yellow-darker:hover {
		background-color: #684f1d;
	}

	.sm\:hover\:bg-yellow-dark:hover {
		background-color: #f2d024;
	}

	.sm\:hover\:bg-yellow-light:hover {
		background-color: #fff382;
	}

	.sm\:hover\:bg-yellow-lighter:hover {
		background-color: #fff9c2;
	}

	.sm\:hover\:bg-yellow-lightest:hover {
		background-color: #fcfbeb;
	}

	.sm\:hover\:bg-green-darkest:hover {
		background-color: #0f2f21;
	}

	.sm\:hover\:bg-green-darker:hover {
		background-color: #1a4731;
	}

	.sm\:hover\:bg-green-dark:hover {
		background-color: #1f9d55;
	}

	.sm\:hover\:bg-green-light:hover {
		background-color: #51d88a;
	}

	.sm\:hover\:bg-green-lighter:hover {
		background-color: #a2f5bf;
	}

	.sm\:hover\:bg-green-lightest:hover {
		background-color: #e3fcec;
	}

	.sm\:hover\:bg-teal-darkest:hover {
		background-color: #0d3331;
	}

	.sm\:hover\:bg-teal-darker:hover {
		background-color: #20504f;
	}

	.sm\:hover\:bg-teal-dark:hover {
		background-color: #38a89d;
	}

	.sm\:hover\:bg-teal-light:hover {
		background-color: #64d5ca;
	}

	.sm\:hover\:bg-teal-lighter:hover {
		background-color: #a0f0ed;
	}

	.sm\:hover\:bg-teal-lightest:hover {
		background-color: #e8fffe;
	}

	.sm\:hover\:bg-blue-darkest:hover {
		background-color: #12283a;
	}

	.sm\:hover\:bg-blue-darker:hover {
		background-color: #1c3d5a;
	}

	.sm\:hover\:bg-blue-dark:hover {
		background-color: #2779bd;
	}

	.sm\:hover\:bg-blue-light:hover {
		background-color: #6cb2eb;
	}

	.sm\:hover\:bg-blue-lighter:hover {
		background-color: #bcdefa;
	}

	.sm\:hover\:bg-blue-lightest:hover {
		background-color: #eff8ff;
	}

	.sm\:hover\:bg-indigo-darkest:hover {
		background-color: #191e38;
	}

	.sm\:hover\:bg-indigo-darker:hover {
		background-color: #2f365f;
	}

	.sm\:hover\:bg-indigo-dark:hover {
		background-color: #5661b3;
	}

	.sm\:hover\:bg-indigo-light:hover {
		background-color: #7886d7;
	}

	.sm\:hover\:bg-indigo-lighter:hover {
		background-color: #b2b7ff;
	}

	.sm\:hover\:bg-indigo-lightest:hover {
		background-color: #e6e8ff;
	}

	.sm\:hover\:bg-purple-darkest:hover {
		background-color: #21183c;
	}

	.sm\:hover\:bg-purple-darker:hover {
		background-color: #382b5f;
	}

	.sm\:hover\:bg-purple-dark:hover {
		background-color: #794acf;
	}

	.sm\:hover\:bg-purple-light:hover {
		background-color: #a779e9;
	}

	.sm\:hover\:bg-purple-lighter:hover {
		background-color: #d6bbfc;
	}

	.sm\:hover\:bg-purple-lightest:hover {
		background-color: #f3ebff;
	}

	.sm\:hover\:bg-pink-darkest:hover {
		background-color: #451225;
	}

	.sm\:hover\:bg-pink-darker:hover {
		background-color: #6f213f;
	}

	.sm\:hover\:bg-pink-dark:hover {
		background-color: #eb5286;
	}

	.sm\:hover\:bg-pink-light:hover {
		background-color: #fa7ea8;
	}

	.sm\:hover\:bg-pink-lighter:hover {
		background-color: #ffbbca;
	}

	.sm\:hover\:bg-pink-lightest:hover {
		background-color: #ffebef;
	}

	.sm\:focus\:bg-transparent:focus {
		background-color: transparent;
	}

	.sm\:focus\:bg-black:focus {
		background-color: #22292f;
	}

	.sm\:focus\:bg-white:focus {
		background-color: #ffffff;
	}

	.sm\:focus\:bg-gray-100:focus {
		background-color: #f7fafc;
	}

	.sm\:focus\:bg-gray-200:focus {
		background-color: #edf2f7;
	}

	.sm\:focus\:bg-gray-300:focus {
		background-color: #e2e8f0;
	}

	.sm\:focus\:bg-gray-400:focus {
		background-color: #cbd5e0;
	}

	.sm\:focus\:bg-gray-500:focus {
		background-color: #a0aec0;
	}

	.sm\:focus\:bg-gray-600:focus {
		background-color: #718096;
	}

	.sm\:focus\:bg-gray-700:focus {
		background-color: #4a5568;
	}

	.sm\:focus\:bg-gray-800:focus {
		background-color: #2d3748;
	}

	.sm\:focus\:bg-gray-900:focus {
		background-color: #1a202c;
	}

	.sm\:focus\:bg-red:focus {
		background-color: #e3342f;
	}

	.sm\:focus\:bg-orange:focus {
		background-color: #f6993f;
	}

	.sm\:focus\:bg-yellow:focus {
		background-color: #ffed4a;
	}

	.sm\:focus\:bg-green:focus {
		background-color: #38c172;
	}

	.sm\:focus\:bg-teal:focus {
		background-color: #4dc0b5;
	}

	.sm\:focus\:bg-blue:focus {
		background-color: #3490dc;
	}

	.sm\:focus\:bg-indigo:focus {
		background-color: #6574cd;
	}

	.sm\:focus\:bg-purple:focus {
		background-color: #9561e2;
	}

	.sm\:focus\:bg-pink:focus {
		background-color: #f66d9b;
	}

	.sm\:focus\:bg-grey-darkest:focus {
		background-color: #3d4852;
	}

	.sm\:focus\:bg-grey-darker:focus {
		background-color: #606f7b;
	}

	.sm\:focus\:bg-grey-dark:focus {
		background-color: #8795a1;
	}

	.sm\:focus\:bg-grey:focus {
		background-color: #b8c2cc;
	}

	.sm\:focus\:bg-grey-light:focus {
		background-color: #dae1e7;
	}

	.sm\:focus\:bg-grey-lighter:focus {
		background-color: #f1f5f8;
	}

	.sm\:focus\:bg-grey-lightest:focus {
		background-color: #f8fafc;
	}

	.sm\:focus\:bg-red-darkest:focus {
		background-color: #3b0d0c;
	}

	.sm\:focus\:bg-red-darker:focus {
		background-color: #621b18;
	}

	.sm\:focus\:bg-red-dark:focus {
		background-color: #cc1f1a;
	}

	.sm\:focus\:bg-red-light:focus {
		background-color: #ef5753;
	}

	.sm\:focus\:bg-red-lighter:focus {
		background-color: #f9acaa;
	}

	.sm\:focus\:bg-red-lightest:focus {
		background-color: #fcebea;
	}

	.sm\:focus\:bg-orange-darkest:focus {
		background-color: #462a16;
	}

	.sm\:focus\:bg-orange-darker:focus {
		background-color: #613b1f;
	}

	.sm\:focus\:bg-orange-dark:focus {
		background-color: #de751f;
	}

	.sm\:focus\:bg-orange-light:focus {
		background-color: #faad63;
	}

	.sm\:focus\:bg-orange-lighter:focus {
		background-color: #fcd9b6;
	}

	.sm\:focus\:bg-orange-lightest:focus {
		background-color: #fff5eb;
	}

	.sm\:focus\:bg-yellow-darkest:focus {
		background-color: #453411;
	}

	.sm\:focus\:bg-yellow-darker:focus {
		background-color: #684f1d;
	}

	.sm\:focus\:bg-yellow-dark:focus {
		background-color: #f2d024;
	}

	.sm\:focus\:bg-yellow-light:focus {
		background-color: #fff382;
	}

	.sm\:focus\:bg-yellow-lighter:focus {
		background-color: #fff9c2;
	}

	.sm\:focus\:bg-yellow-lightest:focus {
		background-color: #fcfbeb;
	}

	.sm\:focus\:bg-green-darkest:focus {
		background-color: #0f2f21;
	}

	.sm\:focus\:bg-green-darker:focus {
		background-color: #1a4731;
	}

	.sm\:focus\:bg-green-dark:focus {
		background-color: #1f9d55;
	}

	.sm\:focus\:bg-green-light:focus {
		background-color: #51d88a;
	}

	.sm\:focus\:bg-green-lighter:focus {
		background-color: #a2f5bf;
	}

	.sm\:focus\:bg-green-lightest:focus {
		background-color: #e3fcec;
	}

	.sm\:focus\:bg-teal-darkest:focus {
		background-color: #0d3331;
	}

	.sm\:focus\:bg-teal-darker:focus {
		background-color: #20504f;
	}

	.sm\:focus\:bg-teal-dark:focus {
		background-color: #38a89d;
	}

	.sm\:focus\:bg-teal-light:focus {
		background-color: #64d5ca;
	}

	.sm\:focus\:bg-teal-lighter:focus {
		background-color: #a0f0ed;
	}

	.sm\:focus\:bg-teal-lightest:focus {
		background-color: #e8fffe;
	}

	.sm\:focus\:bg-blue-darkest:focus {
		background-color: #12283a;
	}

	.sm\:focus\:bg-blue-darker:focus {
		background-color: #1c3d5a;
	}

	.sm\:focus\:bg-blue-dark:focus {
		background-color: #2779bd;
	}

	.sm\:focus\:bg-blue-light:focus {
		background-color: #6cb2eb;
	}

	.sm\:focus\:bg-blue-lighter:focus {
		background-color: #bcdefa;
	}

	.sm\:focus\:bg-blue-lightest:focus {
		background-color: #eff8ff;
	}

	.sm\:focus\:bg-indigo-darkest:focus {
		background-color: #191e38;
	}

	.sm\:focus\:bg-indigo-darker:focus {
		background-color: #2f365f;
	}

	.sm\:focus\:bg-indigo-dark:focus {
		background-color: #5661b3;
	}

	.sm\:focus\:bg-indigo-light:focus {
		background-color: #7886d7;
	}

	.sm\:focus\:bg-indigo-lighter:focus {
		background-color: #b2b7ff;
	}

	.sm\:focus\:bg-indigo-lightest:focus {
		background-color: #e6e8ff;
	}

	.sm\:focus\:bg-purple-darkest:focus {
		background-color: #21183c;
	}

	.sm\:focus\:bg-purple-darker:focus {
		background-color: #382b5f;
	}

	.sm\:focus\:bg-purple-dark:focus {
		background-color: #794acf;
	}

	.sm\:focus\:bg-purple-light:focus {
		background-color: #a779e9;
	}

	.sm\:focus\:bg-purple-lighter:focus {
		background-color: #d6bbfc;
	}

	.sm\:focus\:bg-purple-lightest:focus {
		background-color: #f3ebff;
	}

	.sm\:focus\:bg-pink-darkest:focus {
		background-color: #451225;
	}

	.sm\:focus\:bg-pink-darker:focus {
		background-color: #6f213f;
	}

	.sm\:focus\:bg-pink-dark:focus {
		background-color: #eb5286;
	}

	.sm\:focus\:bg-pink-light:focus {
		background-color: #fa7ea8;
	}

	.sm\:focus\:bg-pink-lighter:focus {
		background-color: #ffbbca;
	}

	.sm\:focus\:bg-pink-lightest:focus {
		background-color: #ffebef;
	}

	.sm\:bg-bottom {
		background-position: bottom;
	}

	.sm\:bg-center {
		background-position: center;
	}

	.sm\:bg-left {
		background-position: left;
	}

	.sm\:bg-left-bottom {
		background-position: left bottom;
	}

	.sm\:bg-left-top {
		background-position: left top;
	}

	.sm\:bg-right {
		background-position: right;
	}

	.sm\:bg-right-bottom {
		background-position: right bottom;
	}

	.sm\:bg-right-top {
		background-position: right top;
	}

	.sm\:bg-top {
		background-position: top;
	}

	.sm\:bg-repeat {
		background-repeat: repeat;
	}

	.sm\:bg-no-repeat {
		background-repeat: no-repeat;
	}

	.sm\:bg-repeat-x {
		background-repeat: repeat-x;
	}

	.sm\:bg-repeat-y {
		background-repeat: repeat-y;
	}

	.sm\:bg-repeat-round {
		background-repeat: round;
	}

	.sm\:bg-repeat-space {
		background-repeat: space;
	}

	.sm\:bg-auto {
		background-size: auto;
	}

	.sm\:bg-cover {
		background-size: cover;
	}

	.sm\:bg-contain {
		background-size: contain;
	}

	.sm\:border-collapse {
		border-collapse: collapse;
	}

	.sm\:border-separate {
		border-collapse: separate;
	}

	.sm\:border-transparent {
		border-color: transparent;
	}

	.sm\:border-black {
		border-color: #22292f;
	}

	.sm\:border-white {
		border-color: #ffffff;
	}

	.sm\:border-gray-100 {
		border-color: #f7fafc;
	}

	.sm\:border-gray-200 {
		border-color: #edf2f7;
	}

	.sm\:border-gray-300 {
		border-color: #e2e8f0;
	}

	.sm\:border-gray-400 {
		border-color: #cbd5e0;
	}

	.sm\:border-gray-500 {
		border-color: #a0aec0;
	}

	.sm\:border-gray-600 {
		border-color: #718096;
	}

	.sm\:border-gray-700 {
		border-color: #4a5568;
	}

	.sm\:border-gray-800 {
		border-color: #2d3748;
	}

	.sm\:border-gray-900 {
		border-color: #1a202c;
	}

	.sm\:border-red {
		border-color: #e3342f;
	}

	.sm\:border-orange {
		border-color: #f6993f;
	}

	.sm\:border-yellow {
		border-color: #ffed4a;
	}

	.sm\:border-green {
		border-color: #38c172;
	}

	.sm\:border-teal {
		border-color: #4dc0b5;
	}

	.sm\:border-blue {
		border-color: #3490dc;
	}

	.sm\:border-indigo {
		border-color: #6574cd;
	}

	.sm\:border-purple {
		border-color: #9561e2;
	}

	.sm\:border-pink {
		border-color: #f66d9b;
	}

	.sm\:border-grey-darkest {
		border-color: #3d4852;
	}

	.sm\:border-grey-darker {
		border-color: #606f7b;
	}

	.sm\:border-grey-dark {
		border-color: #8795a1;
	}

	.sm\:border-grey {
		border-color: #b8c2cc;
	}

	.sm\:border-grey-light {
		border-color: #dae1e7;
	}

	.sm\:border-grey-lighter {
		border-color: #f1f5f8;
	}

	.sm\:border-grey-lightest {
		border-color: #f8fafc;
	}

	.sm\:border-red-darkest {
		border-color: #3b0d0c;
	}

	.sm\:border-red-darker {
		border-color: #621b18;
	}

	.sm\:border-red-dark {
		border-color: #cc1f1a;
	}

	.sm\:border-red-light {
		border-color: #ef5753;
	}

	.sm\:border-red-lighter {
		border-color: #f9acaa;
	}

	.sm\:border-red-lightest {
		border-color: #fcebea;
	}

	.sm\:border-orange-darkest {
		border-color: #462a16;
	}

	.sm\:border-orange-darker {
		border-color: #613b1f;
	}

	.sm\:border-orange-dark {
		border-color: #de751f;
	}

	.sm\:border-orange-light {
		border-color: #faad63;
	}

	.sm\:border-orange-lighter {
		border-color: #fcd9b6;
	}

	.sm\:border-orange-lightest {
		border-color: #fff5eb;
	}

	.sm\:border-yellow-darkest {
		border-color: #453411;
	}

	.sm\:border-yellow-darker {
		border-color: #684f1d;
	}

	.sm\:border-yellow-dark {
		border-color: #f2d024;
	}

	.sm\:border-yellow-light {
		border-color: #fff382;
	}

	.sm\:border-yellow-lighter {
		border-color: #fff9c2;
	}

	.sm\:border-yellow-lightest {
		border-color: #fcfbeb;
	}

	.sm\:border-green-darkest {
		border-color: #0f2f21;
	}

	.sm\:border-green-darker {
		border-color: #1a4731;
	}

	.sm\:border-green-dark {
		border-color: #1f9d55;
	}

	.sm\:border-green-light {
		border-color: #51d88a;
	}

	.sm\:border-green-lighter {
		border-color: #a2f5bf;
	}

	.sm\:border-green-lightest {
		border-color: #e3fcec;
	}

	.sm\:border-teal-darkest {
		border-color: #0d3331;
	}

	.sm\:border-teal-darker {
		border-color: #20504f;
	}

	.sm\:border-teal-dark {
		border-color: #38a89d;
	}

	.sm\:border-teal-light {
		border-color: #64d5ca;
	}

	.sm\:border-teal-lighter {
		border-color: #a0f0ed;
	}

	.sm\:border-teal-lightest {
		border-color: #e8fffe;
	}

	.sm\:border-blue-darkest {
		border-color: #12283a;
	}

	.sm\:border-blue-darker {
		border-color: #1c3d5a;
	}

	.sm\:border-blue-dark {
		border-color: #2779bd;
	}

	.sm\:border-blue-light {
		border-color: #6cb2eb;
	}

	.sm\:border-blue-lighter {
		border-color: #bcdefa;
	}

	.sm\:border-blue-lightest {
		border-color: #eff8ff;
	}

	.sm\:border-indigo-darkest {
		border-color: #191e38;
	}

	.sm\:border-indigo-darker {
		border-color: #2f365f;
	}

	.sm\:border-indigo-dark {
		border-color: #5661b3;
	}

	.sm\:border-indigo-light {
		border-color: #7886d7;
	}

	.sm\:border-indigo-lighter {
		border-color: #b2b7ff;
	}

	.sm\:border-indigo-lightest {
		border-color: #e6e8ff;
	}

	.sm\:border-purple-darkest {
		border-color: #21183c;
	}

	.sm\:border-purple-darker {
		border-color: #382b5f;
	}

	.sm\:border-purple-dark {
		border-color: #794acf;
	}

	.sm\:border-purple-light {
		border-color: #a779e9;
	}

	.sm\:border-purple-lighter {
		border-color: #d6bbfc;
	}

	.sm\:border-purple-lightest {
		border-color: #f3ebff;
	}

	.sm\:border-pink-darkest {
		border-color: #451225;
	}

	.sm\:border-pink-darker {
		border-color: #6f213f;
	}

	.sm\:border-pink-dark {
		border-color: #eb5286;
	}

	.sm\:border-pink-light {
		border-color: #fa7ea8;
	}

	.sm\:border-pink-lighter {
		border-color: #ffbbca;
	}

	.sm\:border-pink-lightest {
		border-color: #ffebef;
	}

	.sm\:hover\:border-transparent:hover {
		border-color: transparent;
	}

	.sm\:hover\:border-black:hover {
		border-color: #22292f;
	}

	.sm\:hover\:border-white:hover {
		border-color: #ffffff;
	}

	.sm\:hover\:border-gray-100:hover {
		border-color: #f7fafc;
	}

	.sm\:hover\:border-gray-200:hover {
		border-color: #edf2f7;
	}

	.sm\:hover\:border-gray-300:hover {
		border-color: #e2e8f0;
	}

	.sm\:hover\:border-gray-400:hover {
		border-color: #cbd5e0;
	}

	.sm\:hover\:border-gray-500:hover {
		border-color: #a0aec0;
	}

	.sm\:hover\:border-gray-600:hover {
		border-color: #718096;
	}

	.sm\:hover\:border-gray-700:hover {
		border-color: #4a5568;
	}

	.sm\:hover\:border-gray-800:hover {
		border-color: #2d3748;
	}

	.sm\:hover\:border-gray-900:hover {
		border-color: #1a202c;
	}

	.sm\:hover\:border-red:hover {
		border-color: #e3342f;
	}

	.sm\:hover\:border-orange:hover {
		border-color: #f6993f;
	}

	.sm\:hover\:border-yellow:hover {
		border-color: #ffed4a;
	}

	.sm\:hover\:border-green:hover {
		border-color: #38c172;
	}

	.sm\:hover\:border-teal:hover {
		border-color: #4dc0b5;
	}

	.sm\:hover\:border-blue:hover {
		border-color: #3490dc;
	}

	.sm\:hover\:border-indigo:hover {
		border-color: #6574cd;
	}

	.sm\:hover\:border-purple:hover {
		border-color: #9561e2;
	}

	.sm\:hover\:border-pink:hover {
		border-color: #f66d9b;
	}

	.sm\:hover\:border-grey-darkest:hover {
		border-color: #3d4852;
	}

	.sm\:hover\:border-grey-darker:hover {
		border-color: #606f7b;
	}

	.sm\:hover\:border-grey-dark:hover {
		border-color: #8795a1;
	}

	.sm\:hover\:border-grey:hover {
		border-color: #b8c2cc;
	}

	.sm\:hover\:border-grey-light:hover {
		border-color: #dae1e7;
	}

	.sm\:hover\:border-grey-lighter:hover {
		border-color: #f1f5f8;
	}

	.sm\:hover\:border-grey-lightest:hover {
		border-color: #f8fafc;
	}

	.sm\:hover\:border-red-darkest:hover {
		border-color: #3b0d0c;
	}

	.sm\:hover\:border-red-darker:hover {
		border-color: #621b18;
	}

	.sm\:hover\:border-red-dark:hover {
		border-color: #cc1f1a;
	}

	.sm\:hover\:border-red-light:hover {
		border-color: #ef5753;
	}

	.sm\:hover\:border-red-lighter:hover {
		border-color: #f9acaa;
	}

	.sm\:hover\:border-red-lightest:hover {
		border-color: #fcebea;
	}

	.sm\:hover\:border-orange-darkest:hover {
		border-color: #462a16;
	}

	.sm\:hover\:border-orange-darker:hover {
		border-color: #613b1f;
	}

	.sm\:hover\:border-orange-dark:hover {
		border-color: #de751f;
	}

	.sm\:hover\:border-orange-light:hover {
		border-color: #faad63;
	}

	.sm\:hover\:border-orange-lighter:hover {
		border-color: #fcd9b6;
	}

	.sm\:hover\:border-orange-lightest:hover {
		border-color: #fff5eb;
	}

	.sm\:hover\:border-yellow-darkest:hover {
		border-color: #453411;
	}

	.sm\:hover\:border-yellow-darker:hover {
		border-color: #684f1d;
	}

	.sm\:hover\:border-yellow-dark:hover {
		border-color: #f2d024;
	}

	.sm\:hover\:border-yellow-light:hover {
		border-color: #fff382;
	}

	.sm\:hover\:border-yellow-lighter:hover {
		border-color: #fff9c2;
	}

	.sm\:hover\:border-yellow-lightest:hover {
		border-color: #fcfbeb;
	}

	.sm\:hover\:border-green-darkest:hover {
		border-color: #0f2f21;
	}

	.sm\:hover\:border-green-darker:hover {
		border-color: #1a4731;
	}

	.sm\:hover\:border-green-dark:hover {
		border-color: #1f9d55;
	}

	.sm\:hover\:border-green-light:hover {
		border-color: #51d88a;
	}

	.sm\:hover\:border-green-lighter:hover {
		border-color: #a2f5bf;
	}

	.sm\:hover\:border-green-lightest:hover {
		border-color: #e3fcec;
	}

	.sm\:hover\:border-teal-darkest:hover {
		border-color: #0d3331;
	}

	.sm\:hover\:border-teal-darker:hover {
		border-color: #20504f;
	}

	.sm\:hover\:border-teal-dark:hover {
		border-color: #38a89d;
	}

	.sm\:hover\:border-teal-light:hover {
		border-color: #64d5ca;
	}

	.sm\:hover\:border-teal-lighter:hover {
		border-color: #a0f0ed;
	}

	.sm\:hover\:border-teal-lightest:hover {
		border-color: #e8fffe;
	}

	.sm\:hover\:border-blue-darkest:hover {
		border-color: #12283a;
	}

	.sm\:hover\:border-blue-darker:hover {
		border-color: #1c3d5a;
	}

	.sm\:hover\:border-blue-dark:hover {
		border-color: #2779bd;
	}

	.sm\:hover\:border-blue-light:hover {
		border-color: #6cb2eb;
	}

	.sm\:hover\:border-blue-lighter:hover {
		border-color: #bcdefa;
	}

	.sm\:hover\:border-blue-lightest:hover {
		border-color: #eff8ff;
	}

	.sm\:hover\:border-indigo-darkest:hover {
		border-color: #191e38;
	}

	.sm\:hover\:border-indigo-darker:hover {
		border-color: #2f365f;
	}

	.sm\:hover\:border-indigo-dark:hover {
		border-color: #5661b3;
	}

	.sm\:hover\:border-indigo-light:hover {
		border-color: #7886d7;
	}

	.sm\:hover\:border-indigo-lighter:hover {
		border-color: #b2b7ff;
	}

	.sm\:hover\:border-indigo-lightest:hover {
		border-color: #e6e8ff;
	}

	.sm\:hover\:border-purple-darkest:hover {
		border-color: #21183c;
	}

	.sm\:hover\:border-purple-darker:hover {
		border-color: #382b5f;
	}

	.sm\:hover\:border-purple-dark:hover {
		border-color: #794acf;
	}

	.sm\:hover\:border-purple-light:hover {
		border-color: #a779e9;
	}

	.sm\:hover\:border-purple-lighter:hover {
		border-color: #d6bbfc;
	}

	.sm\:hover\:border-purple-lightest:hover {
		border-color: #f3ebff;
	}

	.sm\:hover\:border-pink-darkest:hover {
		border-color: #451225;
	}

	.sm\:hover\:border-pink-darker:hover {
		border-color: #6f213f;
	}

	.sm\:hover\:border-pink-dark:hover {
		border-color: #eb5286;
	}

	.sm\:hover\:border-pink-light:hover {
		border-color: #fa7ea8;
	}

	.sm\:hover\:border-pink-lighter:hover {
		border-color: #ffbbca;
	}

	.sm\:hover\:border-pink-lightest:hover {
		border-color: #ffebef;
	}

	.sm\:focus\:border-transparent:focus {
		border-color: transparent;
	}

	.sm\:focus\:border-black:focus {
		border-color: #22292f;
	}

	.sm\:focus\:border-white:focus {
		border-color: #ffffff;
	}

	.sm\:focus\:border-gray-100:focus {
		border-color: #f7fafc;
	}

	.sm\:focus\:border-gray-200:focus {
		border-color: #edf2f7;
	}

	.sm\:focus\:border-gray-300:focus {
		border-color: #e2e8f0;
	}

	.sm\:focus\:border-gray-400:focus {
		border-color: #cbd5e0;
	}

	.sm\:focus\:border-gray-500:focus {
		border-color: #a0aec0;
	}

	.sm\:focus\:border-gray-600:focus {
		border-color: #718096;
	}

	.sm\:focus\:border-gray-700:focus {
		border-color: #4a5568;
	}

	.sm\:focus\:border-gray-800:focus {
		border-color: #2d3748;
	}

	.sm\:focus\:border-gray-900:focus {
		border-color: #1a202c;
	}

	.sm\:focus\:border-red:focus {
		border-color: #e3342f;
	}

	.sm\:focus\:border-orange:focus {
		border-color: #f6993f;
	}

	.sm\:focus\:border-yellow:focus {
		border-color: #ffed4a;
	}

	.sm\:focus\:border-green:focus {
		border-color: #38c172;
	}

	.sm\:focus\:border-teal:focus {
		border-color: #4dc0b5;
	}

	.sm\:focus\:border-blue:focus {
		border-color: #3490dc;
	}

	.sm\:focus\:border-indigo:focus {
		border-color: #6574cd;
	}

	.sm\:focus\:border-purple:focus {
		border-color: #9561e2;
	}

	.sm\:focus\:border-pink:focus {
		border-color: #f66d9b;
	}

	.sm\:focus\:border-grey-darkest:focus {
		border-color: #3d4852;
	}

	.sm\:focus\:border-grey-darker:focus {
		border-color: #606f7b;
	}

	.sm\:focus\:border-grey-dark:focus {
		border-color: #8795a1;
	}

	.sm\:focus\:border-grey:focus {
		border-color: #b8c2cc;
	}

	.sm\:focus\:border-grey-light:focus {
		border-color: #dae1e7;
	}

	.sm\:focus\:border-grey-lighter:focus {
		border-color: #f1f5f8;
	}

	.sm\:focus\:border-grey-lightest:focus {
		border-color: #f8fafc;
	}

	.sm\:focus\:border-red-darkest:focus {
		border-color: #3b0d0c;
	}

	.sm\:focus\:border-red-darker:focus {
		border-color: #621b18;
	}

	.sm\:focus\:border-red-dark:focus {
		border-color: #cc1f1a;
	}

	.sm\:focus\:border-red-light:focus {
		border-color: #ef5753;
	}

	.sm\:focus\:border-red-lighter:focus {
		border-color: #f9acaa;
	}

	.sm\:focus\:border-red-lightest:focus {
		border-color: #fcebea;
	}

	.sm\:focus\:border-orange-darkest:focus {
		border-color: #462a16;
	}

	.sm\:focus\:border-orange-darker:focus {
		border-color: #613b1f;
	}

	.sm\:focus\:border-orange-dark:focus {
		border-color: #de751f;
	}

	.sm\:focus\:border-orange-light:focus {
		border-color: #faad63;
	}

	.sm\:focus\:border-orange-lighter:focus {
		border-color: #fcd9b6;
	}

	.sm\:focus\:border-orange-lightest:focus {
		border-color: #fff5eb;
	}

	.sm\:focus\:border-yellow-darkest:focus {
		border-color: #453411;
	}

	.sm\:focus\:border-yellow-darker:focus {
		border-color: #684f1d;
	}

	.sm\:focus\:border-yellow-dark:focus {
		border-color: #f2d024;
	}

	.sm\:focus\:border-yellow-light:focus {
		border-color: #fff382;
	}

	.sm\:focus\:border-yellow-lighter:focus {
		border-color: #fff9c2;
	}

	.sm\:focus\:border-yellow-lightest:focus {
		border-color: #fcfbeb;
	}

	.sm\:focus\:border-green-darkest:focus {
		border-color: #0f2f21;
	}

	.sm\:focus\:border-green-darker:focus {
		border-color: #1a4731;
	}

	.sm\:focus\:border-green-dark:focus {
		border-color: #1f9d55;
	}

	.sm\:focus\:border-green-light:focus {
		border-color: #51d88a;
	}

	.sm\:focus\:border-green-lighter:focus {
		border-color: #a2f5bf;
	}

	.sm\:focus\:border-green-lightest:focus {
		border-color: #e3fcec;
	}

	.sm\:focus\:border-teal-darkest:focus {
		border-color: #0d3331;
	}

	.sm\:focus\:border-teal-darker:focus {
		border-color: #20504f;
	}

	.sm\:focus\:border-teal-dark:focus {
		border-color: #38a89d;
	}

	.sm\:focus\:border-teal-light:focus {
		border-color: #64d5ca;
	}

	.sm\:focus\:border-teal-lighter:focus {
		border-color: #a0f0ed;
	}

	.sm\:focus\:border-teal-lightest:focus {
		border-color: #e8fffe;
	}

	.sm\:focus\:border-blue-darkest:focus {
		border-color: #12283a;
	}

	.sm\:focus\:border-blue-darker:focus {
		border-color: #1c3d5a;
	}

	.sm\:focus\:border-blue-dark:focus {
		border-color: #2779bd;
	}

	.sm\:focus\:border-blue-light:focus {
		border-color: #6cb2eb;
	}

	.sm\:focus\:border-blue-lighter:focus {
		border-color: #bcdefa;
	}

	.sm\:focus\:border-blue-lightest:focus {
		border-color: #eff8ff;
	}

	.sm\:focus\:border-indigo-darkest:focus {
		border-color: #191e38;
	}

	.sm\:focus\:border-indigo-darker:focus {
		border-color: #2f365f;
	}

	.sm\:focus\:border-indigo-dark:focus {
		border-color: #5661b3;
	}

	.sm\:focus\:border-indigo-light:focus {
		border-color: #7886d7;
	}

	.sm\:focus\:border-indigo-lighter:focus {
		border-color: #b2b7ff;
	}

	.sm\:focus\:border-indigo-lightest:focus {
		border-color: #e6e8ff;
	}

	.sm\:focus\:border-purple-darkest:focus {
		border-color: #21183c;
	}

	.sm\:focus\:border-purple-darker:focus {
		border-color: #382b5f;
	}

	.sm\:focus\:border-purple-dark:focus {
		border-color: #794acf;
	}

	.sm\:focus\:border-purple-light:focus {
		border-color: #a779e9;
	}

	.sm\:focus\:border-purple-lighter:focus {
		border-color: #d6bbfc;
	}

	.sm\:focus\:border-purple-lightest:focus {
		border-color: #f3ebff;
	}

	.sm\:focus\:border-pink-darkest:focus {
		border-color: #451225;
	}

	.sm\:focus\:border-pink-darker:focus {
		border-color: #6f213f;
	}

	.sm\:focus\:border-pink-dark:focus {
		border-color: #eb5286;
	}

	.sm\:focus\:border-pink-light:focus {
		border-color: #fa7ea8;
	}

	.sm\:focus\:border-pink-lighter:focus {
		border-color: #ffbbca;
	}

	.sm\:focus\:border-pink-lightest:focus {
		border-color: #ffebef;
	}

	.sm\:rounded-2 {
		border-radius: 0.2rem;
	}

	.sm\:rounded-4 {
		border-radius: 0.4rem;
	}

	.sm\:rounded-6 {
		border-radius: 0.6rem;
	}

	.sm\:rounded-8 {
		border-radius: 0.8rem;
	}

	.sm\:rounded-12 {
		border-radius: 1.2rem;
	}

	.sm\:rounded-16 {
		border-radius: 1.6rem;
	}

	.sm\:rounded-20 {
		border-radius: 2rem;
	}

	.sm\:rounded-24 {
		border-radius: 2.4rem;
	}

	.sm\:rounded-28 {
		border-radius: 2.8rem;
	}

	.sm\:rounded-32 {
		border-radius: 3.2rem;
	}

	.sm\:rounded-none {
		border-radius: 0;
	}

	.sm\:rounded-sm {
		border-radius: 0.2rem;
	}

	.sm\:rounded {
		border-radius: 0.4rem;
	}

	.sm\:rounded-lg {
		border-radius: 0.8rem;
	}

	.sm\:rounded-full {
		border-radius: 9999px;
	}

	.sm\:rounded-t-2 {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.sm\:rounded-r-2 {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.sm\:rounded-b-2 {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-l-2 {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-t-4 {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.sm\:rounded-r-4 {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.sm\:rounded-b-4 {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-l-4 {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-t-6 {
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
	}

	.sm\:rounded-r-6 {
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
	}

	.sm\:rounded-b-6 {
		border-bottom-right-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.sm\:rounded-l-6 {
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.sm\:rounded-t-8 {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.sm\:rounded-r-8 {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.sm\:rounded-b-8 {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-l-8 {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-t-12 {
		border-top-left-radius: 1.2rem;
		border-top-right-radius: 1.2rem;
	}

	.sm\:rounded-r-12 {
		border-top-right-radius: 1.2rem;
		border-bottom-right-radius: 1.2rem;
	}

	.sm\:rounded-b-12 {
		border-bottom-right-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.sm\:rounded-l-12 {
		border-top-left-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.sm\:rounded-t-16 {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
	}

	.sm\:rounded-r-16 {
		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;
	}

	.sm\:rounded-b-16 {
		border-bottom-right-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.sm\:rounded-l-16 {
		border-top-left-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.sm\:rounded-t-20 {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.sm\:rounded-r-20 {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}

	.sm\:rounded-b-20 {
		border-bottom-right-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.sm\:rounded-l-20 {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.sm\:rounded-t-24 {
		border-top-left-radius: 2.4rem;
		border-top-right-radius: 2.4rem;
	}

	.sm\:rounded-r-24 {
		border-top-right-radius: 2.4rem;
		border-bottom-right-radius: 2.4rem;
	}

	.sm\:rounded-b-24 {
		border-bottom-right-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.sm\:rounded-l-24 {
		border-top-left-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.sm\:rounded-t-28 {
		border-top-left-radius: 2.8rem;
		border-top-right-radius: 2.8rem;
	}

	.sm\:rounded-r-28 {
		border-top-right-radius: 2.8rem;
		border-bottom-right-radius: 2.8rem;
	}

	.sm\:rounded-b-28 {
		border-bottom-right-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.sm\:rounded-l-28 {
		border-top-left-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.sm\:rounded-t-32 {
		border-top-left-radius: 3.2rem;
		border-top-right-radius: 3.2rem;
	}

	.sm\:rounded-r-32 {
		border-top-right-radius: 3.2rem;
		border-bottom-right-radius: 3.2rem;
	}

	.sm\:rounded-b-32 {
		border-bottom-right-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.sm\:rounded-l-32 {
		border-top-left-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.sm\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.sm\:rounded-r-none {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.sm\:rounded-b-none {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.sm\:rounded-l-none {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.sm\:rounded-t-sm {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.sm\:rounded-r-sm {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.sm\:rounded-b-sm {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-l-sm {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-t {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.sm\:rounded-r {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.sm\:rounded-b {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-l {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-t-lg {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.sm\:rounded-r-lg {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.sm\:rounded-b-lg {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-l-lg {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-t-full {
		border-top-left-radius: 9999px;
		border-top-right-radius: 9999px;
	}

	.sm\:rounded-r-full {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}

	.sm\:rounded-b-full {
		border-bottom-right-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.sm\:rounded-l-full {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.sm\:rounded-tl-2 {
		border-top-left-radius: 0.2rem;
	}

	.sm\:rounded-tr-2 {
		border-top-right-radius: 0.2rem;
	}

	.sm\:rounded-br-2 {
		border-bottom-right-radius: 0.2rem;
	}

	.sm\:rounded-bl-2 {
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-tl-4 {
		border-top-left-radius: 0.4rem;
	}

	.sm\:rounded-tr-4 {
		border-top-right-radius: 0.4rem;
	}

	.sm\:rounded-br-4 {
		border-bottom-right-radius: 0.4rem;
	}

	.sm\:rounded-bl-4 {
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-tl-6 {
		border-top-left-radius: 0.6rem;
	}

	.sm\:rounded-tr-6 {
		border-top-right-radius: 0.6rem;
	}

	.sm\:rounded-br-6 {
		border-bottom-right-radius: 0.6rem;
	}

	.sm\:rounded-bl-6 {
		border-bottom-left-radius: 0.6rem;
	}

	.sm\:rounded-tl-8 {
		border-top-left-radius: 0.8rem;
	}

	.sm\:rounded-tr-8 {
		border-top-right-radius: 0.8rem;
	}

	.sm\:rounded-br-8 {
		border-bottom-right-radius: 0.8rem;
	}

	.sm\:rounded-bl-8 {
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-tl-12 {
		border-top-left-radius: 1.2rem;
	}

	.sm\:rounded-tr-12 {
		border-top-right-radius: 1.2rem;
	}

	.sm\:rounded-br-12 {
		border-bottom-right-radius: 1.2rem;
	}

	.sm\:rounded-bl-12 {
		border-bottom-left-radius: 1.2rem;
	}

	.sm\:rounded-tl-16 {
		border-top-left-radius: 1.6rem;
	}

	.sm\:rounded-tr-16 {
		border-top-right-radius: 1.6rem;
	}

	.sm\:rounded-br-16 {
		border-bottom-right-radius: 1.6rem;
	}

	.sm\:rounded-bl-16 {
		border-bottom-left-radius: 1.6rem;
	}

	.sm\:rounded-tl-20 {
		border-top-left-radius: 2rem;
	}

	.sm\:rounded-tr-20 {
		border-top-right-radius: 2rem;
	}

	.sm\:rounded-br-20 {
		border-bottom-right-radius: 2rem;
	}

	.sm\:rounded-bl-20 {
		border-bottom-left-radius: 2rem;
	}

	.sm\:rounded-tl-24 {
		border-top-left-radius: 2.4rem;
	}

	.sm\:rounded-tr-24 {
		border-top-right-radius: 2.4rem;
	}

	.sm\:rounded-br-24 {
		border-bottom-right-radius: 2.4rem;
	}

	.sm\:rounded-bl-24 {
		border-bottom-left-radius: 2.4rem;
	}

	.sm\:rounded-tl-28 {
		border-top-left-radius: 2.8rem;
	}

	.sm\:rounded-tr-28 {
		border-top-right-radius: 2.8rem;
	}

	.sm\:rounded-br-28 {
		border-bottom-right-radius: 2.8rem;
	}

	.sm\:rounded-bl-28 {
		border-bottom-left-radius: 2.8rem;
	}

	.sm\:rounded-tl-32 {
		border-top-left-radius: 3.2rem;
	}

	.sm\:rounded-tr-32 {
		border-top-right-radius: 3.2rem;
	}

	.sm\:rounded-br-32 {
		border-bottom-right-radius: 3.2rem;
	}

	.sm\:rounded-bl-32 {
		border-bottom-left-radius: 3.2rem;
	}

	.sm\:rounded-tl-none {
		border-top-left-radius: 0;
	}

	.sm\:rounded-tr-none {
		border-top-right-radius: 0;
	}

	.sm\:rounded-br-none {
		border-bottom-right-radius: 0;
	}

	.sm\:rounded-bl-none {
		border-bottom-left-radius: 0;
	}

	.sm\:rounded-tl-sm {
		border-top-left-radius: 0.2rem;
	}

	.sm\:rounded-tr-sm {
		border-top-right-radius: 0.2rem;
	}

	.sm\:rounded-br-sm {
		border-bottom-right-radius: 0.2rem;
	}

	.sm\:rounded-bl-sm {
		border-bottom-left-radius: 0.2rem;
	}

	.sm\:rounded-tl {
		border-top-left-radius: 0.4rem;
	}

	.sm\:rounded-tr {
		border-top-right-radius: 0.4rem;
	}

	.sm\:rounded-br {
		border-bottom-right-radius: 0.4rem;
	}

	.sm\:rounded-bl {
		border-bottom-left-radius: 0.4rem;
	}

	.sm\:rounded-tl-lg {
		border-top-left-radius: 0.8rem;
	}

	.sm\:rounded-tr-lg {
		border-top-right-radius: 0.8rem;
	}

	.sm\:rounded-br-lg {
		border-bottom-right-radius: 0.8rem;
	}

	.sm\:rounded-bl-lg {
		border-bottom-left-radius: 0.8rem;
	}

	.sm\:rounded-tl-full {
		border-top-left-radius: 9999px;
	}

	.sm\:rounded-tr-full {
		border-top-right-radius: 9999px;
	}

	.sm\:rounded-br-full {
		border-bottom-right-radius: 9999px;
	}

	.sm\:rounded-bl-full {
		border-bottom-left-radius: 9999px;
	}

	.sm\:border-solid {
		border-style: solid;
	}

	.sm\:border-dashed {
		border-style: dashed;
	}

	.sm\:border-dotted {
		border-style: dotted;
	}

	.sm\:border-none {
		border-style: none;
	}

	.sm\:border-0 {
		border-width: 0;
	}

	.sm\:border-1 {
		border-width: 1px;
	}

	.sm\:border-2 {
		border-width: 2px;
	}

	.sm\:border-3 {
		border-width: 3px;
	}

	.sm\:border-4 {
		border-width: 4px;
	}

	.sm\:border-8 {
		border-width: 8px;
	}

	.sm\:border {
		border-width: 1px;
	}

	.sm\:border-t-0 {
		border-top-width: 0;
	}

	.sm\:border-r-0 {
		border-right-width: 0;
	}

	.sm\:border-b-0 {
		border-bottom-width: 0;
	}

	.sm\:border-l-0 {
		border-left-width: 0;
	}

	.sm\:border-t-1 {
		border-top-width: 1px;
	}

	.sm\:border-r-1 {
		border-right-width: 1px;
	}

	.sm\:border-b-1 {
		border-bottom-width: 1px;
	}

	.sm\:border-l-1 {
		border-left-width: 1px;
	}

	.sm\:border-t-2 {
		border-top-width: 2px;
	}

	.sm\:border-r-2 {
		border-right-width: 2px;
	}

	.sm\:border-b-2 {
		border-bottom-width: 2px;
	}

	.sm\:border-l-2 {
		border-left-width: 2px;
	}

	.sm\:border-t-3 {
		border-top-width: 3px;
	}

	.sm\:border-r-3 {
		border-right-width: 3px;
	}

	.sm\:border-b-3 {
		border-bottom-width: 3px;
	}

	.sm\:border-l-3 {
		border-left-width: 3px;
	}

	.sm\:border-t-4 {
		border-top-width: 4px;
	}

	.sm\:border-r-4 {
		border-right-width: 4px;
	}

	.sm\:border-b-4 {
		border-bottom-width: 4px;
	}

	.sm\:border-l-4 {
		border-left-width: 4px;
	}

	.sm\:border-t-8 {
		border-top-width: 8px;
	}

	.sm\:border-r-8 {
		border-right-width: 8px;
	}

	.sm\:border-b-8 {
		border-bottom-width: 8px;
	}

	.sm\:border-l-8 {
		border-left-width: 8px;
	}

	.sm\:border-t {
		border-top-width: 1px;
	}

	.sm\:border-r {
		border-right-width: 1px;
	}

	.sm\:border-b {
		border-bottom-width: 1px;
	}

	.sm\:border-l {
		border-left-width: 1px;
	}

	.sm\:cursor-auto {
		cursor: auto;
	}

	.sm\:cursor-default {
		cursor: default;
	}

	.sm\:cursor-pointer {
		cursor: pointer;
	}

	.sm\:cursor-wait {
		cursor: wait;
	}

	.sm\:cursor-text {
		cursor: text;
	}

	.sm\:cursor-move {
		cursor: move;
	}

	.sm\:cursor-not-allowed {
		cursor: not-allowed;
	}

	.sm\:block {
		display: block;
	}

	.sm\:inline-block {
		display: inline-block;
	}

	.sm\:inline {
		display: inline;
	}

	.sm\:flex {
		display: -webkit-box;
		display: flex;
	}

	.sm\:inline-flex {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.sm\:table {
		display: table;
	}

	.sm\:table-row {
		display: table-row;
	}

	.sm\:table-cell {
		display: table-cell;
	}

	.sm\:hidden {
		display: none;
	}

	.sm\:hover\:block:hover {
		display: block;
	}

	.sm\:hover\:inline-block:hover {
		display: inline-block;
	}

	.sm\:hover\:inline:hover {
		display: inline;
	}

	.sm\:hover\:flex:hover {
		display: -webkit-box;
		display: flex;
	}

	.sm\:hover\:inline-flex:hover {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.sm\:hover\:table:hover {
		display: table;
	}

	.sm\:hover\:table-row:hover {
		display: table-row;
	}

	.sm\:hover\:table-cell:hover {
		display: table-cell;
	}

	.sm\:hover\:hidden:hover {
		display: none;
	}

	.sm\:focus\:block:focus {
		display: block;
	}

	.sm\:focus\:inline-block:focus {
		display: inline-block;
	}

	.sm\:focus\:inline:focus {
		display: inline;
	}

	.sm\:focus\:flex:focus {
		display: -webkit-box;
		display: flex;
	}

	.sm\:focus\:inline-flex:focus {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.sm\:focus\:table:focus {
		display: table;
	}

	.sm\:focus\:table-row:focus {
		display: table-row;
	}

	.sm\:focus\:table-cell:focus {
		display: table-cell;
	}

	.sm\:focus\:hidden:focus {
		display: none;
	}

	.sm\:flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.sm\:flex-row-reverse {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.sm\:flex-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.sm\:flex-col-reverse {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.sm\:flex-wrap {
		flex-wrap: wrap;
	}

	.sm\:flex-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	.sm\:flex-no-wrap {
		flex-wrap: nowrap;
	}

	.sm\:items-start {
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.sm\:items-end {
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.sm\:items-center {
		-webkit-box-align: center;
		align-items: center;
	}

	.sm\:items-baseline {
		-webkit-box-align: baseline;
		align-items: baseline;
	}

	.sm\:items-stretch {
		-webkit-box-align: stretch;
		align-items: stretch;
	}

	.sm\:self-auto {
		align-self: auto;
	}

	.sm\:self-start {
		align-self: flex-start;
	}

	.sm\:self-end {
		align-self: flex-end;
	}

	.sm\:self-center {
		align-self: center;
	}

	.sm\:self-stretch {
		align-self: stretch;
	}

	.sm\:justify-start {
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.sm\:justify-end {
		-webkit-box-pack: end;
		justify-content: flex-end;
	}

	.sm\:justify-center {
		-webkit-box-pack: center;
		justify-content: center;
	}

	.sm\:justify-between {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.sm\:justify-around {
		justify-content: space-around;
	}

	.sm\:content-center {
		align-content: center;
	}

	.sm\:content-start {
		align-content: flex-start;
	}

	.sm\:content-end {
		align-content: flex-end;
	}

	.sm\:content-between {
		align-content: space-between;
	}

	.sm\:content-around {
		align-content: space-around;
	}

	.sm\:flex-1 {
		-webkit-box-flex: 1;
		flex: 1 1 0%;
	}

	.sm\:flex-auto {
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}

	.sm\:flex-initial {
		-webkit-box-flex: 0;
		flex: 0 1 auto;
	}

	.sm\:flex-none {
		-webkit-box-flex: 0;
		flex: none;
	}

	.sm\:flex-grow-0 {
		-webkit-box-flex: 0;
		flex-grow: 0;
	}

	.sm\:flex-grow {
		-webkit-box-flex: 1;
		flex-grow: 1;
	}

	.sm\:flex-shrink-0 {
		flex-shrink: 0;
	}

	.sm\:flex-shrink {
		flex-shrink: 1;
	}

	.sm\:order-1 {
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	.sm\:order-2 {
		-webkit-box-ordinal-group: 3;
		order: 2;
	}

	.sm\:order-3 {
		-webkit-box-ordinal-group: 4;
		order: 3;
	}

	.sm\:order-4 {
		-webkit-box-ordinal-group: 5;
		order: 4;
	}

	.sm\:order-5 {
		-webkit-box-ordinal-group: 6;
		order: 5;
	}

	.sm\:order-6 {
		-webkit-box-ordinal-group: 7;
		order: 6;
	}

	.sm\:order-7 {
		-webkit-box-ordinal-group: 8;
		order: 7;
	}

	.sm\:order-8 {
		-webkit-box-ordinal-group: 9;
		order: 8;
	}

	.sm\:order-9 {
		-webkit-box-ordinal-group: 10;
		order: 9;
	}

	.sm\:order-10 {
		-webkit-box-ordinal-group: 11;
		order: 10;
	}

	.sm\:order-11 {
		-webkit-box-ordinal-group: 12;
		order: 11;
	}

	.sm\:order-12 {
		-webkit-box-ordinal-group: 13;
		order: 12;
	}

	.sm\:order-first {
		-webkit-box-ordinal-group: -9998;
		order: -9999;
	}

	.sm\:order-last {
		-webkit-box-ordinal-group: 10000;
		order: 9999;
	}

	.sm\:order-none {
		-webkit-box-ordinal-group: 1;
		order: 0;
	}

	.sm\:float-right {
		float: right;
	}

	.sm\:float-left {
		float: left;
	}

	.sm\:float-none {
		float: none;
	}

	.sm\:clearfix:after {
		content: '';
		display: table;
		clear: both;
	}

	.sm\:font-sans {
		font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
			Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
			sans-serif;
	}

	.sm\:font-serif {
		font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
			DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
	}

	.sm\:font-mono {
		font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
			monospace;
	}

	.sm\:font-100 {
		font-weight: 100;
	}

	.sm\:font-200 {
		font-weight: 200;
	}

	.sm\:font-300 {
		font-weight: 300;
	}

	.sm\:font-400 {
		font-weight: 400;
	}

	.sm\:font-500 {
		font-weight: 500;
	}

	.sm\:font-600 {
		font-weight: 600;
	}

	.sm\:font-700 {
		font-weight: 700;
	}

	.sm\:font-800 {
		font-weight: 800;
	}

	.sm\:font-900 {
		font-weight: 900;
	}

	.sm\:font-hairline {
		font-weight: 100;
	}

	.sm\:font-thin {
		font-weight: 200;
	}

	.sm\:font-light {
		font-weight: 300;
	}

	.sm\:font-normal {
		font-weight: 400;
	}

	.sm\:font-medium {
		font-weight: 500;
	}

	.sm\:font-semibold {
		font-weight: 600;
	}

	.sm\:font-bold {
		font-weight: 700;
	}

	.sm\:font-extrabold {
		font-weight: 800;
	}

	.sm\:font-black {
		font-weight: 900;
	}

	.sm\:hover\:font-100:hover {
		font-weight: 100;
	}

	.sm\:hover\:font-200:hover {
		font-weight: 200;
	}

	.sm\:hover\:font-300:hover {
		font-weight: 300;
	}

	.sm\:hover\:font-400:hover {
		font-weight: 400;
	}

	.sm\:hover\:font-500:hover {
		font-weight: 500;
	}

	.sm\:hover\:font-600:hover {
		font-weight: 600;
	}

	.sm\:hover\:font-700:hover {
		font-weight: 700;
	}

	.sm\:hover\:font-800:hover {
		font-weight: 800;
	}

	.sm\:hover\:font-900:hover {
		font-weight: 900;
	}

	.sm\:hover\:font-hairline:hover {
		font-weight: 100;
	}

	.sm\:hover\:font-thin:hover {
		font-weight: 200;
	}

	.sm\:hover\:font-light:hover {
		font-weight: 300;
	}

	.sm\:hover\:font-normal:hover {
		font-weight: 400;
	}

	.sm\:hover\:font-medium:hover {
		font-weight: 500;
	}

	.sm\:hover\:font-semibold:hover {
		font-weight: 600;
	}

	.sm\:hover\:font-bold:hover {
		font-weight: 700;
	}

	.sm\:hover\:font-extrabold:hover {
		font-weight: 800;
	}

	.sm\:hover\:font-black:hover {
		font-weight: 900;
	}

	.sm\:focus\:font-100:focus {
		font-weight: 100;
	}

	.sm\:focus\:font-200:focus {
		font-weight: 200;
	}

	.sm\:focus\:font-300:focus {
		font-weight: 300;
	}

	.sm\:focus\:font-400:focus {
		font-weight: 400;
	}

	.sm\:focus\:font-500:focus {
		font-weight: 500;
	}

	.sm\:focus\:font-600:focus {
		font-weight: 600;
	}

	.sm\:focus\:font-700:focus {
		font-weight: 700;
	}

	.sm\:focus\:font-800:focus {
		font-weight: 800;
	}

	.sm\:focus\:font-900:focus {
		font-weight: 900;
	}

	.sm\:focus\:font-hairline:focus {
		font-weight: 100;
	}

	.sm\:focus\:font-thin:focus {
		font-weight: 200;
	}

	.sm\:focus\:font-light:focus {
		font-weight: 300;
	}

	.sm\:focus\:font-normal:focus {
		font-weight: 400;
	}

	.sm\:focus\:font-medium:focus {
		font-weight: 500;
	}

	.sm\:focus\:font-semibold:focus {
		font-weight: 600;
	}

	.sm\:focus\:font-bold:focus {
		font-weight: 700;
	}

	.sm\:focus\:font-extrabold:focus {
		font-weight: 800;
	}

	.sm\:focus\:font-black:focus {
		font-weight: 900;
	}

	.sm\:h-0 {
		height: 0;
	}

	.sm\:h-1 {
		height: 0.25rem;
	}

	.sm\:h-2 {
		height: 0.5rem;
	}

	.sm\:h-3 {
		height: 0.75rem;
	}

	.sm\:h-4 {
		height: 0.4rem;
	}

	.sm\:h-5 {
		height: 1.25rem;
	}

	.sm\:h-6 {
		height: 0.6rem;
	}

	.sm\:h-8 {
		height: 0.8rem;
	}

	.sm\:h-10 {
		height: 2.5rem;
	}

	.sm\:h-12 {
		height: 1.2rem;
	}

	.sm\:h-16 {
		height: 1.6rem;
	}

	.sm\:h-20 {
		height: 2rem;
	}

	.sm\:h-24 {
		height: 2.4rem;
	}

	.sm\:h-28 {
		height: 2.8rem;
	}

	.sm\:h-32 {
		height: 3.2rem;
	}

	.sm\:h-36 {
		height: 3.6rem;
	}

	.sm\:h-40 {
		height: 4rem;
	}

	.sm\:h-44 {
		height: 4.4rem;
	}

	.sm\:h-48 {
		height: 4.8rem;
	}

	.sm\:h-52 {
		height: 5.2rem;
	}

	.sm\:h-56 {
		height: 5.6rem;
	}

	.sm\:h-60 {
		height: 6rem;
	}

	.sm\:h-64 {
		height: 6.4rem;
	}

	.sm\:h-68 {
		height: 6.8rem;
	}

	.sm\:h-72 {
		height: 7.2rem;
	}

	.sm\:h-76 {
		height: 7.6rem;
	}

	.sm\:h-80 {
		height: 8rem;
	}

	.sm\:h-84 {
		height: 8.4rem;
	}

	.sm\:h-88 {
		height: 8.8rem;
	}

	.sm\:h-92 {
		height: 9.2rem;
	}

	.sm\:h-96 {
		height: 9.6rem;
	}

	.sm\:h-128 {
		height: 12.8rem;
	}

	.sm\:h-136 {
		height: 13.6rem;
	}

	.sm\:h-160 {
		height: 16rem;
	}

	.sm\:h-192 {
		height: 19.2rem;
	}

	.sm\:h-200 {
		height: 20rem;
	}

	.sm\:h-208 {
		height: 20.8rem;
	}

	.sm\:h-216 {
		height: 21.6rem;
	}

	.sm\:h-224 {
		height: 22.4rem;
	}

	.sm\:h-256 {
		height: 25.6rem;
	}

	.sm\:h-288 {
		height: 28.8rem;
	}

	.sm\:h-320 {
		height: 32rem;
	}

	.sm\:h-360 {
		height: 36rem;
	}

	.sm\:h-384 {
		height: 38.4rem;
	}

	.sm\:h-400 {
		height: 40rem;
	}

	.sm\:h-512 {
		height: 51.2rem;
	}

	.sm\:h-640 {
		height: 64rem;
	}

	.sm\:h-auto {
		height: auto;
	}

	.sm\:h-px {
		height: 1px;
	}

	.sm\:h-xs {
		height: 32rem;
	}

	.sm\:h-sm {
		height: 48rem;
	}

	.sm\:h-md {
		height: 64rem;
	}

	.sm\:h-lg {
		height: 80rem;
	}

	.sm\:h-xl {
		height: 96rem;
	}

	.sm\:h-2xl {
		height: 112rem;
	}

	.sm\:h-3xl {
		height: 128rem;
	}

	.sm\:h-4xl {
		height: 144rem;
	}

	.sm\:h-5xl {
		height: 160rem;
	}

	.sm\:h-full {
		height: 100%;
	}

	.sm\:h-screen {
		height: 100vh;
	}

	.sm\:leading-none {
		line-height: 1;
	}

	.sm\:leading-tight {
		line-height: 1.25;
	}

	.sm\:leading-snug {
		line-height: 1.375;
	}

	.sm\:leading-normal {
		line-height: 1.5;
	}

	.sm\:leading-relaxed {
		line-height: 1.625;
	}

	.sm\:leading-loose {
		line-height: 2;
	}

	.sm\:list-inside {
		list-style-position: inside;
	}

	.sm\:list-outside {
		list-style-position: outside;
	}

	.sm\:list-none {
		list-style-type: none;
	}

	.sm\:list-disc {
		list-style-type: disc;
	}

	.sm\:list-decimal {
		list-style-type: decimal;
	}

	.sm\:m-0 {
		margin: 0;
	}

	.sm\:m-1 {
		margin: 0.25rem;
	}

	.sm\:m-2 {
		margin: 0.5rem;
	}

	.sm\:m-3 {
		margin: 0.75rem;
	}

	.sm\:m-4 {
		margin: 0.4rem;
	}

	.sm\:m-5 {
		margin: 1.25rem;
	}

	.sm\:m-6 {
		margin: 0.6rem;
	}

	.sm\:m-8 {
		margin: 0.8rem;
	}

	.sm\:m-10 {
		margin: 2.5rem;
	}

	.sm\:m-12 {
		margin: 1.2rem;
	}

	.sm\:m-16 {
		margin: 1.6rem;
	}

	.sm\:m-20 {
		margin: 2rem;
	}

	.sm\:m-24 {
		margin: 2.4rem;
	}

	.sm\:m-28 {
		margin: 2.8rem;
	}

	.sm\:m-32 {
		margin: 3.2rem;
	}

	.sm\:m-36 {
		margin: 3.6rem;
	}

	.sm\:m-40 {
		margin: 4rem;
	}

	.sm\:m-44 {
		margin: 4.4rem;
	}

	.sm\:m-48 {
		margin: 4.8rem;
	}

	.sm\:m-52 {
		margin: 5.2rem;
	}

	.sm\:m-56 {
		margin: 5.6rem;
	}

	.sm\:m-60 {
		margin: 6rem;
	}

	.sm\:m-64 {
		margin: 6.4rem;
	}

	.sm\:m-68 {
		margin: 6.8rem;
	}

	.sm\:m-72 {
		margin: 7.2rem;
	}

	.sm\:m-76 {
		margin: 7.6rem;
	}

	.sm\:m-80 {
		margin: 8rem;
	}

	.sm\:m-84 {
		margin: 8.4rem;
	}

	.sm\:m-88 {
		margin: 8.8rem;
	}

	.sm\:m-92 {
		margin: 9.2rem;
	}

	.sm\:m-96 {
		margin: 9.6rem;
	}

	.sm\:m-128 {
		margin: 12.8rem;
	}

	.sm\:m-136 {
		margin: 13.6rem;
	}

	.sm\:m-160 {
		margin: 16rem;
	}

	.sm\:m-192 {
		margin: 19.2rem;
	}

	.sm\:m-200 {
		margin: 20rem;
	}

	.sm\:m-208 {
		margin: 20.8rem;
	}

	.sm\:m-216 {
		margin: 21.6rem;
	}

	.sm\:m-224 {
		margin: 22.4rem;
	}

	.sm\:m-256 {
		margin: 25.6rem;
	}

	.sm\:m-288 {
		margin: 28.8rem;
	}

	.sm\:m-320 {
		margin: 32rem;
	}

	.sm\:m-360 {
		margin: 36rem;
	}

	.sm\:m-384 {
		margin: 38.4rem;
	}

	.sm\:m-400 {
		margin: 40rem;
	}

	.sm\:m-512 {
		margin: 51.2rem;
	}

	.sm\:m-640 {
		margin: 64rem;
	}

	.sm\:m-auto {
		margin: auto;
	}

	.sm\:m-px {
		margin: 1px;
	}

	.sm\:m-xs {
		margin: 32rem;
	}

	.sm\:m-sm {
		margin: 48rem;
	}

	.sm\:m-md {
		margin: 64rem;
	}

	.sm\:m-lg {
		margin: 80rem;
	}

	.sm\:m-xl {
		margin: 96rem;
	}

	.sm\:m-2xl {
		margin: 112rem;
	}

	.sm\:m-3xl {
		margin: 128rem;
	}

	.sm\:m-4xl {
		margin: 144rem;
	}

	.sm\:m-5xl {
		margin: 160rem;
	}

	.sm\:-m-1 {
		margin: -0.25rem;
	}

	.sm\:-m-2 {
		margin: -0.5rem;
	}

	.sm\:-m-3 {
		margin: -0.75rem;
	}

	.sm\:-m-4 {
		margin: -0.4rem;
	}

	.sm\:-m-5 {
		margin: -1.25rem;
	}

	.sm\:-m-6 {
		margin: -0.6rem;
	}

	.sm\:-m-8 {
		margin: -0.8rem;
	}

	.sm\:-m-10 {
		margin: -2.5rem;
	}

	.sm\:-m-12 {
		margin: -1.2rem;
	}

	.sm\:-m-16 {
		margin: -1.6rem;
	}

	.sm\:-m-20 {
		margin: -2rem;
	}

	.sm\:-m-24 {
		margin: -2.4rem;
	}

	.sm\:-m-28 {
		margin: -2.8rem;
	}

	.sm\:-m-32 {
		margin: -3.2rem;
	}

	.sm\:-m-36 {
		margin: -3.6rem;
	}

	.sm\:-m-40 {
		margin: -4rem;
	}

	.sm\:-m-44 {
		margin: -4.4rem;
	}

	.sm\:-m-48 {
		margin: -4.8rem;
	}

	.sm\:-m-52 {
		margin: -5.2rem;
	}

	.sm\:-m-56 {
		margin: -5.6rem;
	}

	.sm\:-m-60 {
		margin: -6rem;
	}

	.sm\:-m-64 {
		margin: -6.4rem;
	}

	.sm\:-m-68 {
		margin: -6.8rem;
	}

	.sm\:-m-72 {
		margin: -7.2rem;
	}

	.sm\:-m-76 {
		margin: -7.6rem;
	}

	.sm\:-m-80 {
		margin: -8rem;
	}

	.sm\:-m-84 {
		margin: -8.4rem;
	}

	.sm\:-m-88 {
		margin: -8.8rem;
	}

	.sm\:-m-92 {
		margin: -9.2rem;
	}

	.sm\:-m-96 {
		margin: -9.6rem;
	}

	.sm\:-m-128 {
		margin: -12.8rem;
	}

	.sm\:-m-136 {
		margin: -13.6rem;
	}

	.sm\:-m-160 {
		margin: -16rem;
	}

	.sm\:-m-192 {
		margin: -19.2rem;
	}

	.sm\:-m-200 {
		margin: -20rem;
	}

	.sm\:-m-208 {
		margin: -20.8rem;
	}

	.sm\:-m-216 {
		margin: -21.6rem;
	}

	.sm\:-m-224 {
		margin: -22.4rem;
	}

	.sm\:-m-256 {
		margin: -25.6rem;
	}

	.sm\:-m-288 {
		margin: -28.8rem;
	}

	.sm\:-m-320 {
		margin: -32rem;
	}

	.sm\:-m-360 {
		margin: -36rem;
	}

	.sm\:-m-384 {
		margin: -38.4rem;
	}

	.sm\:-m-400 {
		margin: -40rem;
	}

	.sm\:-m-512 {
		margin: -51.2rem;
	}

	.sm\:-m-640 {
		margin: -64rem;
	}

	.sm\:-m-px {
		margin: -1px;
	}

	.sm\:-m-xs {
		margin: -32rem;
	}

	.sm\:-m-sm {
		margin: -48rem;
	}

	.sm\:-m-md {
		margin: -64rem;
	}

	.sm\:-m-lg {
		margin: -80rem;
	}

	.sm\:-m-xl {
		margin: -96rem;
	}

	.sm\:-m-2xl {
		margin: -112rem;
	}

	.sm\:-m-3xl {
		margin: -128rem;
	}

	.sm\:-m-4xl {
		margin: -144rem;
	}

	.sm\:-m-5xl {
		margin: -160rem;
	}

	.sm\:-m-auto {
		margin: -auto;
	}

	.sm\:my-0 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.sm\:mx-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.sm\:my-1 {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.sm\:mx-1 {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.sm\:my-2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.sm\:mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.sm\:my-3 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.sm\:mx-3 {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.sm\:my-4 {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.sm\:mx-4 {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}

	.sm\:my-5 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.sm\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.sm\:my-6 {
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}

	.sm\:mx-6 {
		margin-left: 0.6rem;
		margin-right: 0.6rem;
	}

	.sm\:my-8 {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}

	.sm\:mx-8 {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}

	.sm\:my-10 {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.sm\:mx-10 {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.sm\:my-12 {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.sm\:mx-12 {
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.sm\:my-16 {
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.sm\:mx-16 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.sm\:my-20 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.sm\:mx-20 {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.sm\:my-24 {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	.sm\:mx-24 {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	.sm\:my-28 {
		margin-top: 2.8rem;
		margin-bottom: 2.8rem;
	}

	.sm\:mx-28 {
		margin-left: 2.8rem;
		margin-right: 2.8rem;
	}

	.sm\:my-32 {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}

	.sm\:mx-32 {
		margin-left: 3.2rem;
		margin-right: 3.2rem;
	}

	.sm\:my-36 {
		margin-top: 3.6rem;
		margin-bottom: 3.6rem;
	}

	.sm\:mx-36 {
		margin-left: 3.6rem;
		margin-right: 3.6rem;
	}

	.sm\:my-40 {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.sm\:mx-40 {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.sm\:my-44 {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	.sm\:mx-44 {
		margin-left: 4.4rem;
		margin-right: 4.4rem;
	}

	.sm\:my-48 {
		margin-top: 4.8rem;
		margin-bottom: 4.8rem;
	}

	.sm\:mx-48 {
		margin-left: 4.8rem;
		margin-right: 4.8rem;
	}

	.sm\:my-52 {
		margin-top: 5.2rem;
		margin-bottom: 5.2rem;
	}

	.sm\:mx-52 {
		margin-left: 5.2rem;
		margin-right: 5.2rem;
	}

	.sm\:my-56 {
		margin-top: 5.6rem;
		margin-bottom: 5.6rem;
	}

	.sm\:mx-56 {
		margin-left: 5.6rem;
		margin-right: 5.6rem;
	}

	.sm\:my-60 {
		margin-top: 6rem;
		margin-bottom: 6rem;
	}

	.sm\:mx-60 {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	.sm\:my-64 {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	.sm\:mx-64 {
		margin-left: 6.4rem;
		margin-right: 6.4rem;
	}

	.sm\:my-68 {
		margin-top: 6.8rem;
		margin-bottom: 6.8rem;
	}

	.sm\:mx-68 {
		margin-left: 6.8rem;
		margin-right: 6.8rem;
	}

	.sm\:my-72 {
		margin-top: 7.2rem;
		margin-bottom: 7.2rem;
	}

	.sm\:mx-72 {
		margin-left: 7.2rem;
		margin-right: 7.2rem;
	}

	.sm\:my-76 {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.sm\:mx-76 {
		margin-left: 7.6rem;
		margin-right: 7.6rem;
	}

	.sm\:my-80 {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}

	.sm\:mx-80 {
		margin-left: 8rem;
		margin-right: 8rem;
	}

	.sm\:my-84 {
		margin-top: 8.4rem;
		margin-bottom: 8.4rem;
	}

	.sm\:mx-84 {
		margin-left: 8.4rem;
		margin-right: 8.4rem;
	}

	.sm\:my-88 {
		margin-top: 8.8rem;
		margin-bottom: 8.8rem;
	}

	.sm\:mx-88 {
		margin-left: 8.8rem;
		margin-right: 8.8rem;
	}

	.sm\:my-92 {
		margin-top: 9.2rem;
		margin-bottom: 9.2rem;
	}

	.sm\:mx-92 {
		margin-left: 9.2rem;
		margin-right: 9.2rem;
	}

	.sm\:my-96 {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}

	.sm\:mx-96 {
		margin-left: 9.6rem;
		margin-right: 9.6rem;
	}

	.sm\:my-128 {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}

	.sm\:mx-128 {
		margin-left: 12.8rem;
		margin-right: 12.8rem;
	}

	.sm\:my-136 {
		margin-top: 13.6rem;
		margin-bottom: 13.6rem;
	}

	.sm\:mx-136 {
		margin-left: 13.6rem;
		margin-right: 13.6rem;
	}

	.sm\:my-160 {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}

	.sm\:mx-160 {
		margin-left: 16rem;
		margin-right: 16rem;
	}

	.sm\:my-192 {
		margin-top: 19.2rem;
		margin-bottom: 19.2rem;
	}

	.sm\:mx-192 {
		margin-left: 19.2rem;
		margin-right: 19.2rem;
	}

	.sm\:my-200 {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.sm\:mx-200 {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.sm\:my-208 {
		margin-top: 20.8rem;
		margin-bottom: 20.8rem;
	}

	.sm\:mx-208 {
		margin-left: 20.8rem;
		margin-right: 20.8rem;
	}

	.sm\:my-216 {
		margin-top: 21.6rem;
		margin-bottom: 21.6rem;
	}

	.sm\:mx-216 {
		margin-left: 21.6rem;
		margin-right: 21.6rem;
	}

	.sm\:my-224 {
		margin-top: 22.4rem;
		margin-bottom: 22.4rem;
	}

	.sm\:mx-224 {
		margin-left: 22.4rem;
		margin-right: 22.4rem;
	}

	.sm\:my-256 {
		margin-top: 25.6rem;
		margin-bottom: 25.6rem;
	}

	.sm\:mx-256 {
		margin-left: 25.6rem;
		margin-right: 25.6rem;
	}

	.sm\:my-288 {
		margin-top: 28.8rem;
		margin-bottom: 28.8rem;
	}

	.sm\:mx-288 {
		margin-left: 28.8rem;
		margin-right: 28.8rem;
	}

	.sm\:my-320 {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.sm\:mx-320 {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.sm\:my-360 {
		margin-top: 36rem;
		margin-bottom: 36rem;
	}

	.sm\:mx-360 {
		margin-left: 36rem;
		margin-right: 36rem;
	}

	.sm\:my-384 {
		margin-top: 38.4rem;
		margin-bottom: 38.4rem;
	}

	.sm\:mx-384 {
		margin-left: 38.4rem;
		margin-right: 38.4rem;
	}

	.sm\:my-400 {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.sm\:mx-400 {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.sm\:my-512 {
		margin-top: 51.2rem;
		margin-bottom: 51.2rem;
	}

	.sm\:mx-512 {
		margin-left: 51.2rem;
		margin-right: 51.2rem;
	}

	.sm\:my-640 {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.sm\:mx-640 {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.sm\:my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.sm\:mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.sm\:my-px {
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.sm\:mx-px {
		margin-left: 1px;
		margin-right: 1px;
	}

	.sm\:my-xs {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.sm\:mx-xs {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.sm\:my-sm {
		margin-top: 48rem;
		margin-bottom: 48rem;
	}

	.sm\:mx-sm {
		margin-left: 48rem;
		margin-right: 48rem;
	}

	.sm\:my-md {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.sm\:mx-md {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.sm\:my-lg {
		margin-top: 80rem;
		margin-bottom: 80rem;
	}

	.sm\:mx-lg {
		margin-left: 80rem;
		margin-right: 80rem;
	}

	.sm\:my-xl {
		margin-top: 96rem;
		margin-bottom: 96rem;
	}

	.sm\:mx-xl {
		margin-left: 96rem;
		margin-right: 96rem;
	}

	.sm\:my-2xl {
		margin-top: 112rem;
		margin-bottom: 112rem;
	}

	.sm\:mx-2xl {
		margin-left: 112rem;
		margin-right: 112rem;
	}

	.sm\:my-3xl {
		margin-top: 128rem;
		margin-bottom: 128rem;
	}

	.sm\:mx-3xl {
		margin-left: 128rem;
		margin-right: 128rem;
	}

	.sm\:my-4xl {
		margin-top: 144rem;
		margin-bottom: 144rem;
	}

	.sm\:mx-4xl {
		margin-left: 144rem;
		margin-right: 144rem;
	}

	.sm\:my-5xl {
		margin-top: 160rem;
		margin-bottom: 160rem;
	}

	.sm\:mx-5xl {
		margin-left: 160rem;
		margin-right: 160rem;
	}

	.sm\:-my-1 {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}

	.sm\:-mx-1 {
		margin-left: -0.25rem;
		margin-right: -0.25rem;
	}

	.sm\:-my-2 {
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
	}

	.sm\:-mx-2 {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	.sm\:-my-3 {
		margin-top: -0.75rem;
		margin-bottom: -0.75rem;
	}

	.sm\:-mx-3 {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}

	.sm\:-my-4 {
		margin-top: -0.4rem;
		margin-bottom: -0.4rem;
	}

	.sm\:-mx-4 {
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.sm\:-my-5 {
		margin-top: -1.25rem;
		margin-bottom: -1.25rem;
	}

	.sm\:-mx-5 {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	}

	.sm\:-my-6 {
		margin-top: -0.6rem;
		margin-bottom: -0.6rem;
	}

	.sm\:-mx-6 {
		margin-left: -0.6rem;
		margin-right: -0.6rem;
	}

	.sm\:-my-8 {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}

	.sm\:-mx-8 {
		margin-left: -0.8rem;
		margin-right: -0.8rem;
	}

	.sm\:-my-10 {
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.sm\:-mx-10 {
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}

	.sm\:-my-12 {
		margin-top: -1.2rem;
		margin-bottom: -1.2rem;
	}

	.sm\:-mx-12 {
		margin-left: -1.2rem;
		margin-right: -1.2rem;
	}

	.sm\:-my-16 {
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	.sm\:-mx-16 {
		margin-left: -1.6rem;
		margin-right: -1.6rem;
	}

	.sm\:-my-20 {
		margin-top: -2rem;
		margin-bottom: -2rem;
	}

	.sm\:-mx-20 {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.sm\:-my-24 {
		margin-top: -2.4rem;
		margin-bottom: -2.4rem;
	}

	.sm\:-mx-24 {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}

	.sm\:-my-28 {
		margin-top: -2.8rem;
		margin-bottom: -2.8rem;
	}

	.sm\:-mx-28 {
		margin-left: -2.8rem;
		margin-right: -2.8rem;
	}

	.sm\:-my-32 {
		margin-top: -3.2rem;
		margin-bottom: -3.2rem;
	}

	.sm\:-mx-32 {
		margin-left: -3.2rem;
		margin-right: -3.2rem;
	}

	.sm\:-my-36 {
		margin-top: -3.6rem;
		margin-bottom: -3.6rem;
	}

	.sm\:-mx-36 {
		margin-left: -3.6rem;
		margin-right: -3.6rem;
	}

	.sm\:-my-40 {
		margin-top: -4rem;
		margin-bottom: -4rem;
	}

	.sm\:-mx-40 {
		margin-left: -4rem;
		margin-right: -4rem;
	}

	.sm\:-my-44 {
		margin-top: -4.4rem;
		margin-bottom: -4.4rem;
	}

	.sm\:-mx-44 {
		margin-left: -4.4rem;
		margin-right: -4.4rem;
	}

	.sm\:-my-48 {
		margin-top: -4.8rem;
		margin-bottom: -4.8rem;
	}

	.sm\:-mx-48 {
		margin-left: -4.8rem;
		margin-right: -4.8rem;
	}

	.sm\:-my-52 {
		margin-top: -5.2rem;
		margin-bottom: -5.2rem;
	}

	.sm\:-mx-52 {
		margin-left: -5.2rem;
		margin-right: -5.2rem;
	}

	.sm\:-my-56 {
		margin-top: -5.6rem;
		margin-bottom: -5.6rem;
	}

	.sm\:-mx-56 {
		margin-left: -5.6rem;
		margin-right: -5.6rem;
	}

	.sm\:-my-60 {
		margin-top: -6rem;
		margin-bottom: -6rem;
	}

	.sm\:-mx-60 {
		margin-left: -6rem;
		margin-right: -6rem;
	}

	.sm\:-my-64 {
		margin-top: -6.4rem;
		margin-bottom: -6.4rem;
	}

	.sm\:-mx-64 {
		margin-left: -6.4rem;
		margin-right: -6.4rem;
	}

	.sm\:-my-68 {
		margin-top: -6.8rem;
		margin-bottom: -6.8rem;
	}

	.sm\:-mx-68 {
		margin-left: -6.8rem;
		margin-right: -6.8rem;
	}

	.sm\:-my-72 {
		margin-top: -7.2rem;
		margin-bottom: -7.2rem;
	}

	.sm\:-mx-72 {
		margin-left: -7.2rem;
		margin-right: -7.2rem;
	}

	.sm\:-my-76 {
		margin-top: -7.6rem;
		margin-bottom: -7.6rem;
	}

	.sm\:-mx-76 {
		margin-left: -7.6rem;
		margin-right: -7.6rem;
	}

	.sm\:-my-80 {
		margin-top: -8rem;
		margin-bottom: -8rem;
	}

	.sm\:-mx-80 {
		margin-left: -8rem;
		margin-right: -8rem;
	}

	.sm\:-my-84 {
		margin-top: -8.4rem;
		margin-bottom: -8.4rem;
	}

	.sm\:-mx-84 {
		margin-left: -8.4rem;
		margin-right: -8.4rem;
	}

	.sm\:-my-88 {
		margin-top: -8.8rem;
		margin-bottom: -8.8rem;
	}

	.sm\:-mx-88 {
		margin-left: -8.8rem;
		margin-right: -8.8rem;
	}

	.sm\:-my-92 {
		margin-top: -9.2rem;
		margin-bottom: -9.2rem;
	}

	.sm\:-mx-92 {
		margin-left: -9.2rem;
		margin-right: -9.2rem;
	}

	.sm\:-my-96 {
		margin-top: -9.6rem;
		margin-bottom: -9.6rem;
	}

	.sm\:-mx-96 {
		margin-left: -9.6rem;
		margin-right: -9.6rem;
	}

	.sm\:-my-128 {
		margin-top: -12.8rem;
		margin-bottom: -12.8rem;
	}

	.sm\:-mx-128 {
		margin-left: -12.8rem;
		margin-right: -12.8rem;
	}

	.sm\:-my-136 {
		margin-top: -13.6rem;
		margin-bottom: -13.6rem;
	}

	.sm\:-mx-136 {
		margin-left: -13.6rem;
		margin-right: -13.6rem;
	}

	.sm\:-my-160 {
		margin-top: -16rem;
		margin-bottom: -16rem;
	}

	.sm\:-mx-160 {
		margin-left: -16rem;
		margin-right: -16rem;
	}

	.sm\:-my-192 {
		margin-top: -19.2rem;
		margin-bottom: -19.2rem;
	}

	.sm\:-mx-192 {
		margin-left: -19.2rem;
		margin-right: -19.2rem;
	}

	.sm\:-my-200 {
		margin-top: -20rem;
		margin-bottom: -20rem;
	}

	.sm\:-mx-200 {
		margin-left: -20rem;
		margin-right: -20rem;
	}

	.sm\:-my-208 {
		margin-top: -20.8rem;
		margin-bottom: -20.8rem;
	}

	.sm\:-mx-208 {
		margin-left: -20.8rem;
		margin-right: -20.8rem;
	}

	.sm\:-my-216 {
		margin-top: -21.6rem;
		margin-bottom: -21.6rem;
	}

	.sm\:-mx-216 {
		margin-left: -21.6rem;
		margin-right: -21.6rem;
	}

	.sm\:-my-224 {
		margin-top: -22.4rem;
		margin-bottom: -22.4rem;
	}

	.sm\:-mx-224 {
		margin-left: -22.4rem;
		margin-right: -22.4rem;
	}

	.sm\:-my-256 {
		margin-top: -25.6rem;
		margin-bottom: -25.6rem;
	}

	.sm\:-mx-256 {
		margin-left: -25.6rem;
		margin-right: -25.6rem;
	}

	.sm\:-my-288 {
		margin-top: -28.8rem;
		margin-bottom: -28.8rem;
	}

	.sm\:-mx-288 {
		margin-left: -28.8rem;
		margin-right: -28.8rem;
	}

	.sm\:-my-320 {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.sm\:-mx-320 {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.sm\:-my-360 {
		margin-top: -36rem;
		margin-bottom: -36rem;
	}

	.sm\:-mx-360 {
		margin-left: -36rem;
		margin-right: -36rem;
	}

	.sm\:-my-384 {
		margin-top: -38.4rem;
		margin-bottom: -38.4rem;
	}

	.sm\:-mx-384 {
		margin-left: -38.4rem;
		margin-right: -38.4rem;
	}

	.sm\:-my-400 {
		margin-top: -40rem;
		margin-bottom: -40rem;
	}

	.sm\:-mx-400 {
		margin-left: -40rem;
		margin-right: -40rem;
	}

	.sm\:-my-512 {
		margin-top: -51.2rem;
		margin-bottom: -51.2rem;
	}

	.sm\:-mx-512 {
		margin-left: -51.2rem;
		margin-right: -51.2rem;
	}

	.sm\:-my-640 {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.sm\:-mx-640 {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.sm\:-my-px {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	.sm\:-mx-px {
		margin-left: -1px;
		margin-right: -1px;
	}

	.sm\:-my-xs {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.sm\:-mx-xs {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.sm\:-my-sm {
		margin-top: -48rem;
		margin-bottom: -48rem;
	}

	.sm\:-mx-sm {
		margin-left: -48rem;
		margin-right: -48rem;
	}

	.sm\:-my-md {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.sm\:-mx-md {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.sm\:-my-lg {
		margin-top: -80rem;
		margin-bottom: -80rem;
	}

	.sm\:-mx-lg {
		margin-left: -80rem;
		margin-right: -80rem;
	}

	.sm\:-my-xl {
		margin-top: -96rem;
		margin-bottom: -96rem;
	}

	.sm\:-mx-xl {
		margin-left: -96rem;
		margin-right: -96rem;
	}

	.sm\:-my-2xl {
		margin-top: -112rem;
		margin-bottom: -112rem;
	}

	.sm\:-mx-2xl {
		margin-left: -112rem;
		margin-right: -112rem;
	}

	.sm\:-my-3xl {
		margin-top: -128rem;
		margin-bottom: -128rem;
	}

	.sm\:-mx-3xl {
		margin-left: -128rem;
		margin-right: -128rem;
	}

	.sm\:-my-4xl {
		margin-top: -144rem;
		margin-bottom: -144rem;
	}

	.sm\:-mx-4xl {
		margin-left: -144rem;
		margin-right: -144rem;
	}

	.sm\:-my-5xl {
		margin-top: -160rem;
		margin-bottom: -160rem;
	}

	.sm\:-mx-5xl {
		margin-left: -160rem;
		margin-right: -160rem;
	}

	.sm\:-my-auto {
		margin-top: -auto;
		margin-bottom: -auto;
	}

	.sm\:-mx-auto {
		margin-left: -auto;
		margin-right: -auto;
	}

	.sm\:mt-0 {
		margin-top: 0;
	}

	.sm\:mr-0 {
		margin-right: 0;
	}

	.sm\:mb-0 {
		margin-bottom: 0;
	}

	.sm\:ml-0 {
		margin-left: 0;
	}

	.sm\:mt-1 {
		margin-top: 0.25rem;
	}

	.sm\:mr-1 {
		margin-right: 0.25rem;
	}

	.sm\:mb-1 {
		margin-bottom: 0.25rem;
	}

	.sm\:ml-1 {
		margin-left: 0.25rem;
	}

	.sm\:mt-2 {
		margin-top: 0.5rem;
	}

	.sm\:mr-2 {
		margin-right: 0.5rem;
	}

	.sm\:mb-2 {
		margin-bottom: 0.5rem;
	}

	.sm\:ml-2 {
		margin-left: 0.5rem;
	}

	.sm\:mt-3 {
		margin-top: 0.75rem;
	}

	.sm\:mr-3 {
		margin-right: 0.75rem;
	}

	.sm\:mb-3 {
		margin-bottom: 0.75rem;
	}

	.sm\:ml-3 {
		margin-left: 0.75rem;
	}

	.sm\:mt-4 {
		margin-top: 0.4rem;
	}

	.sm\:mr-4 {
		margin-right: 0.4rem;
	}

	.sm\:mb-4 {
		margin-bottom: 0.4rem;
	}

	.sm\:ml-4 {
		margin-left: 0.4rem;
	}

	.sm\:mt-5 {
		margin-top: 1.25rem;
	}

	.sm\:mr-5 {
		margin-right: 1.25rem;
	}

	.sm\:mb-5 {
		margin-bottom: 1.25rem;
	}

	.sm\:ml-5 {
		margin-left: 1.25rem;
	}

	.sm\:mt-6 {
		margin-top: 0.6rem;
	}

	.sm\:mr-6 {
		margin-right: 0.6rem;
	}

	.sm\:mb-6 {
		margin-bottom: 0.6rem;
	}

	.sm\:ml-6 {
		margin-left: 0.6rem;
	}

	.sm\:mt-8 {
		margin-top: 0.8rem;
	}

	.sm\:mr-8 {
		margin-right: 0.8rem;
	}

	.sm\:mb-8 {
		margin-bottom: 0.8rem;
	}

	.sm\:ml-8 {
		margin-left: 0.8rem;
	}

	.sm\:mt-10 {
		margin-top: 2.5rem;
	}

	.sm\:mr-10 {
		margin-right: 2.5rem;
	}

	.sm\:mb-10 {
		margin-bottom: 2.5rem;
	}

	.sm\:ml-10 {
		margin-left: 2.5rem;
	}

	.sm\:mt-12 {
		margin-top: 1.2rem;
	}

	.sm\:mr-12 {
		margin-right: 1.2rem;
	}

	.sm\:mb-12 {
		margin-bottom: 1.2rem;
	}

	.sm\:ml-12 {
		margin-left: 1.2rem;
	}

	.sm\:mt-16 {
		margin-top: 1.6rem;
	}

	.sm\:mr-16 {
		margin-right: 1.6rem;
	}

	.sm\:mb-16 {
		margin-bottom: 1.6rem;
	}

	.sm\:ml-16 {
		margin-left: 1.6rem;
	}

	.sm\:mt-20 {
		margin-top: 2rem;
	}

	.sm\:mr-20 {
		margin-right: 2rem;
	}

	.sm\:mb-20 {
		margin-bottom: 2rem;
	}

	.sm\:ml-20 {
		margin-left: 2rem;
	}

	.sm\:mt-24 {
		margin-top: 2.4rem;
	}

	.sm\:mr-24 {
		margin-right: 2.4rem;
	}

	.sm\:mb-24 {
		margin-bottom: 2.4rem;
	}

	.sm\:ml-24 {
		margin-left: 2.4rem;
	}

	.sm\:mt-28 {
		margin-top: 2.8rem;
	}

	.sm\:mr-28 {
		margin-right: 2.8rem;
	}

	.sm\:mb-28 {
		margin-bottom: 2.8rem;
	}

	.sm\:ml-28 {
		margin-left: 2.8rem;
	}

	.sm\:mt-32 {
		margin-top: 3.2rem;
	}

	.sm\:mr-32 {
		margin-right: 3.2rem;
	}

	.sm\:mb-32 {
		margin-bottom: 3.2rem;
	}

	.sm\:ml-32 {
		margin-left: 3.2rem;
	}

	.sm\:mt-36 {
		margin-top: 3.6rem;
	}

	.sm\:mr-36 {
		margin-right: 3.6rem;
	}

	.sm\:mb-36 {
		margin-bottom: 3.6rem;
	}

	.sm\:ml-36 {
		margin-left: 3.6rem;
	}

	.sm\:mt-40 {
		margin-top: 4rem;
	}

	.sm\:mr-40 {
		margin-right: 4rem;
	}

	.sm\:mb-40 {
		margin-bottom: 4rem;
	}

	.sm\:ml-40 {
		margin-left: 4rem;
	}

	.sm\:mt-44 {
		margin-top: 4.4rem;
	}

	.sm\:mr-44 {
		margin-right: 4.4rem;
	}

	.sm\:mb-44 {
		margin-bottom: 4.4rem;
	}

	.sm\:ml-44 {
		margin-left: 4.4rem;
	}

	.sm\:mt-48 {
		margin-top: 4.8rem;
	}

	.sm\:mr-48 {
		margin-right: 4.8rem;
	}

	.sm\:mb-48 {
		margin-bottom: 4.8rem;
	}

	.sm\:ml-48 {
		margin-left: 4.8rem;
	}

	.sm\:mt-52 {
		margin-top: 5.2rem;
	}

	.sm\:mr-52 {
		margin-right: 5.2rem;
	}

	.sm\:mb-52 {
		margin-bottom: 5.2rem;
	}

	.sm\:ml-52 {
		margin-left: 5.2rem;
	}

	.sm\:mt-56 {
		margin-top: 5.6rem;
	}

	.sm\:mr-56 {
		margin-right: 5.6rem;
	}

	.sm\:mb-56 {
		margin-bottom: 5.6rem;
	}

	.sm\:ml-56 {
		margin-left: 5.6rem;
	}

	.sm\:mt-60 {
		margin-top: 6rem;
	}

	.sm\:mr-60 {
		margin-right: 6rem;
	}

	.sm\:mb-60 {
		margin-bottom: 6rem;
	}

	.sm\:ml-60 {
		margin-left: 6rem;
	}

	.sm\:mt-64 {
		margin-top: 6.4rem;
	}

	.sm\:mr-64 {
		margin-right: 6.4rem;
	}

	.sm\:mb-64 {
		margin-bottom: 6.4rem;
	}

	.sm\:ml-64 {
		margin-left: 6.4rem;
	}

	.sm\:mt-68 {
		margin-top: 6.8rem;
	}

	.sm\:mr-68 {
		margin-right: 6.8rem;
	}

	.sm\:mb-68 {
		margin-bottom: 6.8rem;
	}

	.sm\:ml-68 {
		margin-left: 6.8rem;
	}

	.sm\:mt-72 {
		margin-top: 7.2rem;
	}

	.sm\:mr-72 {
		margin-right: 7.2rem;
	}

	.sm\:mb-72 {
		margin-bottom: 7.2rem;
	}

	.sm\:ml-72 {
		margin-left: 7.2rem;
	}

	.sm\:mt-76 {
		margin-top: 7.6rem;
	}

	.sm\:mr-76 {
		margin-right: 7.6rem;
	}

	.sm\:mb-76 {
		margin-bottom: 7.6rem;
	}

	.sm\:ml-76 {
		margin-left: 7.6rem;
	}

	.sm\:mt-80 {
		margin-top: 8rem;
	}

	.sm\:mr-80 {
		margin-right: 8rem;
	}

	.sm\:mb-80 {
		margin-bottom: 8rem;
	}

	.sm\:ml-80 {
		margin-left: 8rem;
	}

	.sm\:mt-84 {
		margin-top: 8.4rem;
	}

	.sm\:mr-84 {
		margin-right: 8.4rem;
	}

	.sm\:mb-84 {
		margin-bottom: 8.4rem;
	}

	.sm\:ml-84 {
		margin-left: 8.4rem;
	}

	.sm\:mt-88 {
		margin-top: 8.8rem;
	}

	.sm\:mr-88 {
		margin-right: 8.8rem;
	}

	.sm\:mb-88 {
		margin-bottom: 8.8rem;
	}

	.sm\:ml-88 {
		margin-left: 8.8rem;
	}

	.sm\:mt-92 {
		margin-top: 9.2rem;
	}

	.sm\:mr-92 {
		margin-right: 9.2rem;
	}

	.sm\:mb-92 {
		margin-bottom: 9.2rem;
	}

	.sm\:ml-92 {
		margin-left: 9.2rem;
	}

	.sm\:mt-96 {
		margin-top: 9.6rem;
	}

	.sm\:mr-96 {
		margin-right: 9.6rem;
	}

	.sm\:mb-96 {
		margin-bottom: 9.6rem;
	}

	.sm\:ml-96 {
		margin-left: 9.6rem;
	}

	.sm\:mt-128 {
		margin-top: 12.8rem;
	}

	.sm\:mr-128 {
		margin-right: 12.8rem;
	}

	.sm\:mb-128 {
		margin-bottom: 12.8rem;
	}

	.sm\:ml-128 {
		margin-left: 12.8rem;
	}

	.sm\:mt-136 {
		margin-top: 13.6rem;
	}

	.sm\:mr-136 {
		margin-right: 13.6rem;
	}

	.sm\:mb-136 {
		margin-bottom: 13.6rem;
	}

	.sm\:ml-136 {
		margin-left: 13.6rem;
	}

	.sm\:mt-160 {
		margin-top: 16rem;
	}

	.sm\:mr-160 {
		margin-right: 16rem;
	}

	.sm\:mb-160 {
		margin-bottom: 16rem;
	}

	.sm\:ml-160 {
		margin-left: 16rem;
	}

	.sm\:mt-192 {
		margin-top: 19.2rem;
	}

	.sm\:mr-192 {
		margin-right: 19.2rem;
	}

	.sm\:mb-192 {
		margin-bottom: 19.2rem;
	}

	.sm\:ml-192 {
		margin-left: 19.2rem;
	}

	.sm\:mt-200 {
		margin-top: 20rem;
	}

	.sm\:mr-200 {
		margin-right: 20rem;
	}

	.sm\:mb-200 {
		margin-bottom: 20rem;
	}

	.sm\:ml-200 {
		margin-left: 20rem;
	}

	.sm\:mt-208 {
		margin-top: 20.8rem;
	}

	.sm\:mr-208 {
		margin-right: 20.8rem;
	}

	.sm\:mb-208 {
		margin-bottom: 20.8rem;
	}

	.sm\:ml-208 {
		margin-left: 20.8rem;
	}

	.sm\:mt-216 {
		margin-top: 21.6rem;
	}

	.sm\:mr-216 {
		margin-right: 21.6rem;
	}

	.sm\:mb-216 {
		margin-bottom: 21.6rem;
	}

	.sm\:ml-216 {
		margin-left: 21.6rem;
	}

	.sm\:mt-224 {
		margin-top: 22.4rem;
	}

	.sm\:mr-224 {
		margin-right: 22.4rem;
	}

	.sm\:mb-224 {
		margin-bottom: 22.4rem;
	}

	.sm\:ml-224 {
		margin-left: 22.4rem;
	}

	.sm\:mt-256 {
		margin-top: 25.6rem;
	}

	.sm\:mr-256 {
		margin-right: 25.6rem;
	}

	.sm\:mb-256 {
		margin-bottom: 25.6rem;
	}

	.sm\:ml-256 {
		margin-left: 25.6rem;
	}

	.sm\:mt-288 {
		margin-top: 28.8rem;
	}

	.sm\:mr-288 {
		margin-right: 28.8rem;
	}

	.sm\:mb-288 {
		margin-bottom: 28.8rem;
	}

	.sm\:ml-288 {
		margin-left: 28.8rem;
	}

	.sm\:mt-320 {
		margin-top: 32rem;
	}

	.sm\:mr-320 {
		margin-right: 32rem;
	}

	.sm\:mb-320 {
		margin-bottom: 32rem;
	}

	.sm\:ml-320 {
		margin-left: 32rem;
	}

	.sm\:mt-360 {
		margin-top: 36rem;
	}

	.sm\:mr-360 {
		margin-right: 36rem;
	}

	.sm\:mb-360 {
		margin-bottom: 36rem;
	}

	.sm\:ml-360 {
		margin-left: 36rem;
	}

	.sm\:mt-384 {
		margin-top: 38.4rem;
	}

	.sm\:mr-384 {
		margin-right: 38.4rem;
	}

	.sm\:mb-384 {
		margin-bottom: 38.4rem;
	}

	.sm\:ml-384 {
		margin-left: 38.4rem;
	}

	.sm\:mt-400 {
		margin-top: 40rem;
	}

	.sm\:mr-400 {
		margin-right: 40rem;
	}

	.sm\:mb-400 {
		margin-bottom: 40rem;
	}

	.sm\:ml-400 {
		margin-left: 40rem;
	}

	.sm\:mt-512 {
		margin-top: 51.2rem;
	}

	.sm\:mr-512 {
		margin-right: 51.2rem;
	}

	.sm\:mb-512 {
		margin-bottom: 51.2rem;
	}

	.sm\:ml-512 {
		margin-left: 51.2rem;
	}

	.sm\:mt-640 {
		margin-top: 64rem;
	}

	.sm\:mr-640 {
		margin-right: 64rem;
	}

	.sm\:mb-640 {
		margin-bottom: 64rem;
	}

	.sm\:ml-640 {
		margin-left: 64rem;
	}

	.sm\:mt-auto {
		margin-top: auto;
	}

	.sm\:mr-auto {
		margin-right: auto;
	}

	.sm\:mb-auto {
		margin-bottom: auto;
	}

	.sm\:ml-auto {
		margin-left: auto;
	}

	.sm\:mt-px {
		margin-top: 1px;
	}

	.sm\:mr-px {
		margin-right: 1px;
	}

	.sm\:mb-px {
		margin-bottom: 1px;
	}

	.sm\:ml-px {
		margin-left: 1px;
	}

	.sm\:mt-xs {
		margin-top: 32rem;
	}

	.sm\:mr-xs {
		margin-right: 32rem;
	}

	.sm\:mb-xs {
		margin-bottom: 32rem;
	}

	.sm\:ml-xs {
		margin-left: 32rem;
	}

	.sm\:mt-sm {
		margin-top: 48rem;
	}

	.sm\:mr-sm {
		margin-right: 48rem;
	}

	.sm\:mb-sm {
		margin-bottom: 48rem;
	}

	.sm\:ml-sm {
		margin-left: 48rem;
	}

	.sm\:mt-md {
		margin-top: 64rem;
	}

	.sm\:mr-md {
		margin-right: 64rem;
	}

	.sm\:mb-md {
		margin-bottom: 64rem;
	}

	.sm\:ml-md {
		margin-left: 64rem;
	}

	.sm\:mt-lg {
		margin-top: 80rem;
	}

	.sm\:mr-lg {
		margin-right: 80rem;
	}

	.sm\:mb-lg {
		margin-bottom: 80rem;
	}

	.sm\:ml-lg {
		margin-left: 80rem;
	}

	.sm\:mt-xl {
		margin-top: 96rem;
	}

	.sm\:mr-xl {
		margin-right: 96rem;
	}

	.sm\:mb-xl {
		margin-bottom: 96rem;
	}

	.sm\:ml-xl {
		margin-left: 96rem;
	}

	.sm\:mt-2xl {
		margin-top: 112rem;
	}

	.sm\:mr-2xl {
		margin-right: 112rem;
	}

	.sm\:mb-2xl {
		margin-bottom: 112rem;
	}

	.sm\:ml-2xl {
		margin-left: 112rem;
	}

	.sm\:mt-3xl {
		margin-top: 128rem;
	}

	.sm\:mr-3xl {
		margin-right: 128rem;
	}

	.sm\:mb-3xl {
		margin-bottom: 128rem;
	}

	.sm\:ml-3xl {
		margin-left: 128rem;
	}

	.sm\:mt-4xl {
		margin-top: 144rem;
	}

	.sm\:mr-4xl {
		margin-right: 144rem;
	}

	.sm\:mb-4xl {
		margin-bottom: 144rem;
	}

	.sm\:ml-4xl {
		margin-left: 144rem;
	}

	.sm\:mt-5xl {
		margin-top: 160rem;
	}

	.sm\:mr-5xl {
		margin-right: 160rem;
	}

	.sm\:mb-5xl {
		margin-bottom: 160rem;
	}

	.sm\:ml-5xl {
		margin-left: 160rem;
	}

	.sm\:-mt-1 {
		margin-top: -0.25rem;
	}

	.sm\:-mr-1 {
		margin-right: -0.25rem;
	}

	.sm\:-mb-1 {
		margin-bottom: -0.25rem;
	}

	.sm\:-ml-1 {
		margin-left: -0.25rem;
	}

	.sm\:-mt-2 {
		margin-top: -0.5rem;
	}

	.sm\:-mr-2 {
		margin-right: -0.5rem;
	}

	.sm\:-mb-2 {
		margin-bottom: -0.5rem;
	}

	.sm\:-ml-2 {
		margin-left: -0.5rem;
	}

	.sm\:-mt-3 {
		margin-top: -0.75rem;
	}

	.sm\:-mr-3 {
		margin-right: -0.75rem;
	}

	.sm\:-mb-3 {
		margin-bottom: -0.75rem;
	}

	.sm\:-ml-3 {
		margin-left: -0.75rem;
	}

	.sm\:-mt-4 {
		margin-top: -0.4rem;
	}

	.sm\:-mr-4 {
		margin-right: -0.4rem;
	}

	.sm\:-mb-4 {
		margin-bottom: -0.4rem;
	}

	.sm\:-ml-4 {
		margin-left: -0.4rem;
	}

	.sm\:-mt-5 {
		margin-top: -1.25rem;
	}

	.sm\:-mr-5 {
		margin-right: -1.25rem;
	}

	.sm\:-mb-5 {
		margin-bottom: -1.25rem;
	}

	.sm\:-ml-5 {
		margin-left: -1.25rem;
	}

	.sm\:-mt-6 {
		margin-top: -0.6rem;
	}

	.sm\:-mr-6 {
		margin-right: -0.6rem;
	}

	.sm\:-mb-6 {
		margin-bottom: -0.6rem;
	}

	.sm\:-ml-6 {
		margin-left: -0.6rem;
	}

	.sm\:-mt-8 {
		margin-top: -0.8rem;
	}

	.sm\:-mr-8 {
		margin-right: -0.8rem;
	}

	.sm\:-mb-8 {
		margin-bottom: -0.8rem;
	}

	.sm\:-ml-8 {
		margin-left: -0.8rem;
	}

	.sm\:-mt-10 {
		margin-top: -2.5rem;
	}

	.sm\:-mr-10 {
		margin-right: -2.5rem;
	}

	.sm\:-mb-10 {
		margin-bottom: -2.5rem;
	}

	.sm\:-ml-10 {
		margin-left: -2.5rem;
	}

	.sm\:-mt-12 {
		margin-top: -1.2rem;
	}

	.sm\:-mr-12 {
		margin-right: -1.2rem;
	}

	.sm\:-mb-12 {
		margin-bottom: -1.2rem;
	}

	.sm\:-ml-12 {
		margin-left: -1.2rem;
	}

	.sm\:-mt-16 {
		margin-top: -1.6rem;
	}

	.sm\:-mr-16 {
		margin-right: -1.6rem;
	}

	.sm\:-mb-16 {
		margin-bottom: -1.6rem;
	}

	.sm\:-ml-16 {
		margin-left: -1.6rem;
	}

	.sm\:-mt-20 {
		margin-top: -2rem;
	}

	.sm\:-mr-20 {
		margin-right: -2rem;
	}

	.sm\:-mb-20 {
		margin-bottom: -2rem;
	}

	.sm\:-ml-20 {
		margin-left: -2rem;
	}

	.sm\:-mt-24 {
		margin-top: -2.4rem;
	}

	.sm\:-mr-24 {
		margin-right: -2.4rem;
	}

	.sm\:-mb-24 {
		margin-bottom: -2.4rem;
	}

	.sm\:-ml-24 {
		margin-left: -2.4rem;
	}

	.sm\:-mt-28 {
		margin-top: -2.8rem;
	}

	.sm\:-mr-28 {
		margin-right: -2.8rem;
	}

	.sm\:-mb-28 {
		margin-bottom: -2.8rem;
	}

	.sm\:-ml-28 {
		margin-left: -2.8rem;
	}

	.sm\:-mt-32 {
		margin-top: -3.2rem;
	}

	.sm\:-mr-32 {
		margin-right: -3.2rem;
	}

	.sm\:-mb-32 {
		margin-bottom: -3.2rem;
	}

	.sm\:-ml-32 {
		margin-left: -3.2rem;
	}

	.sm\:-mt-36 {
		margin-top: -3.6rem;
	}

	.sm\:-mr-36 {
		margin-right: -3.6rem;
	}

	.sm\:-mb-36 {
		margin-bottom: -3.6rem;
	}

	.sm\:-ml-36 {
		margin-left: -3.6rem;
	}

	.sm\:-mt-40 {
		margin-top: -4rem;
	}

	.sm\:-mr-40 {
		margin-right: -4rem;
	}

	.sm\:-mb-40 {
		margin-bottom: -4rem;
	}

	.sm\:-ml-40 {
		margin-left: -4rem;
	}

	.sm\:-mt-44 {
		margin-top: -4.4rem;
	}

	.sm\:-mr-44 {
		margin-right: -4.4rem;
	}

	.sm\:-mb-44 {
		margin-bottom: -4.4rem;
	}

	.sm\:-ml-44 {
		margin-left: -4.4rem;
	}

	.sm\:-mt-48 {
		margin-top: -4.8rem;
	}

	.sm\:-mr-48 {
		margin-right: -4.8rem;
	}

	.sm\:-mb-48 {
		margin-bottom: -4.8rem;
	}

	.sm\:-ml-48 {
		margin-left: -4.8rem;
	}

	.sm\:-mt-52 {
		margin-top: -5.2rem;
	}

	.sm\:-mr-52 {
		margin-right: -5.2rem;
	}

	.sm\:-mb-52 {
		margin-bottom: -5.2rem;
	}

	.sm\:-ml-52 {
		margin-left: -5.2rem;
	}

	.sm\:-mt-56 {
		margin-top: -5.6rem;
	}

	.sm\:-mr-56 {
		margin-right: -5.6rem;
	}

	.sm\:-mb-56 {
		margin-bottom: -5.6rem;
	}

	.sm\:-ml-56 {
		margin-left: -5.6rem;
	}

	.sm\:-mt-60 {
		margin-top: -6rem;
	}

	.sm\:-mr-60 {
		margin-right: -6rem;
	}

	.sm\:-mb-60 {
		margin-bottom: -6rem;
	}

	.sm\:-ml-60 {
		margin-left: -6rem;
	}

	.sm\:-mt-64 {
		margin-top: -6.4rem;
	}

	.sm\:-mr-64 {
		margin-right: -6.4rem;
	}

	.sm\:-mb-64 {
		margin-bottom: -6.4rem;
	}

	.sm\:-ml-64 {
		margin-left: -6.4rem;
	}

	.sm\:-mt-68 {
		margin-top: -6.8rem;
	}

	.sm\:-mr-68 {
		margin-right: -6.8rem;
	}

	.sm\:-mb-68 {
		margin-bottom: -6.8rem;
	}

	.sm\:-ml-68 {
		margin-left: -6.8rem;
	}

	.sm\:-mt-72 {
		margin-top: -7.2rem;
	}

	.sm\:-mr-72 {
		margin-right: -7.2rem;
	}

	.sm\:-mb-72 {
		margin-bottom: -7.2rem;
	}

	.sm\:-ml-72 {
		margin-left: -7.2rem;
	}

	.sm\:-mt-76 {
		margin-top: -7.6rem;
	}

	.sm\:-mr-76 {
		margin-right: -7.6rem;
	}

	.sm\:-mb-76 {
		margin-bottom: -7.6rem;
	}

	.sm\:-ml-76 {
		margin-left: -7.6rem;
	}

	.sm\:-mt-80 {
		margin-top: -8rem;
	}

	.sm\:-mr-80 {
		margin-right: -8rem;
	}

	.sm\:-mb-80 {
		margin-bottom: -8rem;
	}

	.sm\:-ml-80 {
		margin-left: -8rem;
	}

	.sm\:-mt-84 {
		margin-top: -8.4rem;
	}

	.sm\:-mr-84 {
		margin-right: -8.4rem;
	}

	.sm\:-mb-84 {
		margin-bottom: -8.4rem;
	}

	.sm\:-ml-84 {
		margin-left: -8.4rem;
	}

	.sm\:-mt-88 {
		margin-top: -8.8rem;
	}

	.sm\:-mr-88 {
		margin-right: -8.8rem;
	}

	.sm\:-mb-88 {
		margin-bottom: -8.8rem;
	}

	.sm\:-ml-88 {
		margin-left: -8.8rem;
	}

	.sm\:-mt-92 {
		margin-top: -9.2rem;
	}

	.sm\:-mr-92 {
		margin-right: -9.2rem;
	}

	.sm\:-mb-92 {
		margin-bottom: -9.2rem;
	}

	.sm\:-ml-92 {
		margin-left: -9.2rem;
	}

	.sm\:-mt-96 {
		margin-top: -9.6rem;
	}

	.sm\:-mr-96 {
		margin-right: -9.6rem;
	}

	.sm\:-mb-96 {
		margin-bottom: -9.6rem;
	}

	.sm\:-ml-96 {
		margin-left: -9.6rem;
	}

	.sm\:-mt-128 {
		margin-top: -12.8rem;
	}

	.sm\:-mr-128 {
		margin-right: -12.8rem;
	}

	.sm\:-mb-128 {
		margin-bottom: -12.8rem;
	}

	.sm\:-ml-128 {
		margin-left: -12.8rem;
	}

	.sm\:-mt-136 {
		margin-top: -13.6rem;
	}

	.sm\:-mr-136 {
		margin-right: -13.6rem;
	}

	.sm\:-mb-136 {
		margin-bottom: -13.6rem;
	}

	.sm\:-ml-136 {
		margin-left: -13.6rem;
	}

	.sm\:-mt-160 {
		margin-top: -16rem;
	}

	.sm\:-mr-160 {
		margin-right: -16rem;
	}

	.sm\:-mb-160 {
		margin-bottom: -16rem;
	}

	.sm\:-ml-160 {
		margin-left: -16rem;
	}

	.sm\:-mt-192 {
		margin-top: -19.2rem;
	}

	.sm\:-mr-192 {
		margin-right: -19.2rem;
	}

	.sm\:-mb-192 {
		margin-bottom: -19.2rem;
	}

	.sm\:-ml-192 {
		margin-left: -19.2rem;
	}

	.sm\:-mt-200 {
		margin-top: -20rem;
	}

	.sm\:-mr-200 {
		margin-right: -20rem;
	}

	.sm\:-mb-200 {
		margin-bottom: -20rem;
	}

	.sm\:-ml-200 {
		margin-left: -20rem;
	}

	.sm\:-mt-208 {
		margin-top: -20.8rem;
	}

	.sm\:-mr-208 {
		margin-right: -20.8rem;
	}

	.sm\:-mb-208 {
		margin-bottom: -20.8rem;
	}

	.sm\:-ml-208 {
		margin-left: -20.8rem;
	}

	.sm\:-mt-216 {
		margin-top: -21.6rem;
	}

	.sm\:-mr-216 {
		margin-right: -21.6rem;
	}

	.sm\:-mb-216 {
		margin-bottom: -21.6rem;
	}

	.sm\:-ml-216 {
		margin-left: -21.6rem;
	}

	.sm\:-mt-224 {
		margin-top: -22.4rem;
	}

	.sm\:-mr-224 {
		margin-right: -22.4rem;
	}

	.sm\:-mb-224 {
		margin-bottom: -22.4rem;
	}

	.sm\:-ml-224 {
		margin-left: -22.4rem;
	}

	.sm\:-mt-256 {
		margin-top: -25.6rem;
	}

	.sm\:-mr-256 {
		margin-right: -25.6rem;
	}

	.sm\:-mb-256 {
		margin-bottom: -25.6rem;
	}

	.sm\:-ml-256 {
		margin-left: -25.6rem;
	}

	.sm\:-mt-288 {
		margin-top: -28.8rem;
	}

	.sm\:-mr-288 {
		margin-right: -28.8rem;
	}

	.sm\:-mb-288 {
		margin-bottom: -28.8rem;
	}

	.sm\:-ml-288 {
		margin-left: -28.8rem;
	}

	.sm\:-mt-320 {
		margin-top: -32rem;
	}

	.sm\:-mr-320 {
		margin-right: -32rem;
	}

	.sm\:-mb-320 {
		margin-bottom: -32rem;
	}

	.sm\:-ml-320 {
		margin-left: -32rem;
	}

	.sm\:-mt-360 {
		margin-top: -36rem;
	}

	.sm\:-mr-360 {
		margin-right: -36rem;
	}

	.sm\:-mb-360 {
		margin-bottom: -36rem;
	}

	.sm\:-ml-360 {
		margin-left: -36rem;
	}

	.sm\:-mt-384 {
		margin-top: -38.4rem;
	}

	.sm\:-mr-384 {
		margin-right: -38.4rem;
	}

	.sm\:-mb-384 {
		margin-bottom: -38.4rem;
	}

	.sm\:-ml-384 {
		margin-left: -38.4rem;
	}

	.sm\:-mt-400 {
		margin-top: -40rem;
	}

	.sm\:-mr-400 {
		margin-right: -40rem;
	}

	.sm\:-mb-400 {
		margin-bottom: -40rem;
	}

	.sm\:-ml-400 {
		margin-left: -40rem;
	}

	.sm\:-mt-512 {
		margin-top: -51.2rem;
	}

	.sm\:-mr-512 {
		margin-right: -51.2rem;
	}

	.sm\:-mb-512 {
		margin-bottom: -51.2rem;
	}

	.sm\:-ml-512 {
		margin-left: -51.2rem;
	}

	.sm\:-mt-640 {
		margin-top: -64rem;
	}

	.sm\:-mr-640 {
		margin-right: -64rem;
	}

	.sm\:-mb-640 {
		margin-bottom: -64rem;
	}

	.sm\:-ml-640 {
		margin-left: -64rem;
	}

	.sm\:-mt-px {
		margin-top: -1px;
	}

	.sm\:-mr-px {
		margin-right: -1px;
	}

	.sm\:-mb-px {
		margin-bottom: -1px;
	}

	.sm\:-ml-px {
		margin-left: -1px;
	}

	.sm\:-mt-xs {
		margin-top: -32rem;
	}

	.sm\:-mr-xs {
		margin-right: -32rem;
	}

	.sm\:-mb-xs {
		margin-bottom: -32rem;
	}

	.sm\:-ml-xs {
		margin-left: -32rem;
	}

	.sm\:-mt-sm {
		margin-top: -48rem;
	}

	.sm\:-mr-sm {
		margin-right: -48rem;
	}

	.sm\:-mb-sm {
		margin-bottom: -48rem;
	}

	.sm\:-ml-sm {
		margin-left: -48rem;
	}

	.sm\:-mt-md {
		margin-top: -64rem;
	}

	.sm\:-mr-md {
		margin-right: -64rem;
	}

	.sm\:-mb-md {
		margin-bottom: -64rem;
	}

	.sm\:-ml-md {
		margin-left: -64rem;
	}

	.sm\:-mt-lg {
		margin-top: -80rem;
	}

	.sm\:-mr-lg {
		margin-right: -80rem;
	}

	.sm\:-mb-lg {
		margin-bottom: -80rem;
	}

	.sm\:-ml-lg {
		margin-left: -80rem;
	}

	.sm\:-mt-xl {
		margin-top: -96rem;
	}

	.sm\:-mr-xl {
		margin-right: -96rem;
	}

	.sm\:-mb-xl {
		margin-bottom: -96rem;
	}

	.sm\:-ml-xl {
		margin-left: -96rem;
	}

	.sm\:-mt-2xl {
		margin-top: -112rem;
	}

	.sm\:-mr-2xl {
		margin-right: -112rem;
	}

	.sm\:-mb-2xl {
		margin-bottom: -112rem;
	}

	.sm\:-ml-2xl {
		margin-left: -112rem;
	}

	.sm\:-mt-3xl {
		margin-top: -128rem;
	}

	.sm\:-mr-3xl {
		margin-right: -128rem;
	}

	.sm\:-mb-3xl {
		margin-bottom: -128rem;
	}

	.sm\:-ml-3xl {
		margin-left: -128rem;
	}

	.sm\:-mt-4xl {
		margin-top: -144rem;
	}

	.sm\:-mr-4xl {
		margin-right: -144rem;
	}

	.sm\:-mb-4xl {
		margin-bottom: -144rem;
	}

	.sm\:-ml-4xl {
		margin-left: -144rem;
	}

	.sm\:-mt-5xl {
		margin-top: -160rem;
	}

	.sm\:-mr-5xl {
		margin-right: -160rem;
	}

	.sm\:-mb-5xl {
		margin-bottom: -160rem;
	}

	.sm\:-ml-5xl {
		margin-left: -160rem;
	}

	.sm\:-mt-auto {
		margin-top: -auto;
	}

	.sm\:-mr-auto {
		margin-right: -auto;
	}

	.sm\:-mb-auto {
		margin-bottom: -auto;
	}

	.sm\:-ml-auto {
		margin-left: -auto;
	}

	.sm\:max-h-0 {
		max-height: 0;
	}

	.sm\:max-h-1 {
		max-height: 0.25rem;
	}

	.sm\:max-h-2 {
		max-height: 0.5rem;
	}

	.sm\:max-h-3 {
		max-height: 0.75rem;
	}

	.sm\:max-h-4 {
		max-height: 0.4rem;
	}

	.sm\:max-h-5 {
		max-height: 1.25rem;
	}

	.sm\:max-h-6 {
		max-height: 0.6rem;
	}

	.sm\:max-h-8 {
		max-height: 0.8rem;
	}

	.sm\:max-h-10 {
		max-height: 2.5rem;
	}

	.sm\:max-h-12 {
		max-height: 1.2rem;
	}

	.sm\:max-h-16 {
		max-height: 1.6rem;
	}

	.sm\:max-h-20 {
		max-height: 2rem;
	}

	.sm\:max-h-24 {
		max-height: 2.4rem;
	}

	.sm\:max-h-28 {
		max-height: 2.8rem;
	}

	.sm\:max-h-32 {
		max-height: 3.2rem;
	}

	.sm\:max-h-36 {
		max-height: 3.6rem;
	}

	.sm\:max-h-40 {
		max-height: 4rem;
	}

	.sm\:max-h-44 {
		max-height: 4.4rem;
	}

	.sm\:max-h-48 {
		max-height: 4.8rem;
	}

	.sm\:max-h-52 {
		max-height: 5.2rem;
	}

	.sm\:max-h-56 {
		max-height: 5.6rem;
	}

	.sm\:max-h-60 {
		max-height: 6rem;
	}

	.sm\:max-h-64 {
		max-height: 6.4rem;
	}

	.sm\:max-h-68 {
		max-height: 6.8rem;
	}

	.sm\:max-h-72 {
		max-height: 7.2rem;
	}

	.sm\:max-h-76 {
		max-height: 7.6rem;
	}

	.sm\:max-h-80 {
		max-height: 8rem;
	}

	.sm\:max-h-84 {
		max-height: 8.4rem;
	}

	.sm\:max-h-88 {
		max-height: 8.8rem;
	}

	.sm\:max-h-92 {
		max-height: 9.2rem;
	}

	.sm\:max-h-96 {
		max-height: 9.6rem;
	}

	.sm\:max-h-128 {
		max-height: 12.8rem;
	}

	.sm\:max-h-136 {
		max-height: 13.6rem;
	}

	.sm\:max-h-160 {
		max-height: 16rem;
	}

	.sm\:max-h-192 {
		max-height: 19.2rem;
	}

	.sm\:max-h-200 {
		max-height: 20rem;
	}

	.sm\:max-h-208 {
		max-height: 20.8rem;
	}

	.sm\:max-h-216 {
		max-height: 21.6rem;
	}

	.sm\:max-h-224 {
		max-height: 22.4rem;
	}

	.sm\:max-h-256 {
		max-height: 25.6rem;
	}

	.sm\:max-h-288 {
		max-height: 28.8rem;
	}

	.sm\:max-h-320 {
		max-height: 32rem;
	}

	.sm\:max-h-360 {
		max-height: 36rem;
	}

	.sm\:max-h-384 {
		max-height: 38.4rem;
	}

	.sm\:max-h-400 {
		max-height: 40rem;
	}

	.sm\:max-h-512 {
		max-height: 51.2rem;
	}

	.sm\:max-h-640 {
		max-height: 64rem;
	}

	.sm\:max-h-full {
		max-height: 100%;
	}

	.sm\:max-h-screen {
		max-height: 100vh;
	}

	.sm\:max-h-auto {
		max-height: auto;
	}

	.sm\:max-h-px {
		max-height: 1px;
	}

	.sm\:max-h-xs {
		max-height: 32rem;
	}

	.sm\:max-h-sm {
		max-height: 48rem;
	}

	.sm\:max-h-md {
		max-height: 64rem;
	}

	.sm\:max-h-lg {
		max-height: 80rem;
	}

	.sm\:max-h-xl {
		max-height: 96rem;
	}

	.sm\:max-h-2xl {
		max-height: 112rem;
	}

	.sm\:max-h-3xl {
		max-height: 128rem;
	}

	.sm\:max-h-4xl {
		max-height: 144rem;
	}

	.sm\:max-h-5xl {
		max-height: 160rem;
	}

	.sm\:max-w-0 {
		max-width: 0;
	}

	.sm\:max-w-1 {
		max-width: 0.25rem;
	}

	.sm\:max-w-2 {
		max-width: 0.5rem;
	}

	.sm\:max-w-3 {
		max-width: 0.75rem;
	}

	.sm\:max-w-4 {
		max-width: 0.4rem;
	}

	.sm\:max-w-5 {
		max-width: 1.25rem;
	}

	.sm\:max-w-6 {
		max-width: 0.6rem;
	}

	.sm\:max-w-8 {
		max-width: 0.8rem;
	}

	.sm\:max-w-10 {
		max-width: 2.5rem;
	}

	.sm\:max-w-12 {
		max-width: 1.2rem;
	}

	.sm\:max-w-16 {
		max-width: 1.6rem;
	}

	.sm\:max-w-20 {
		max-width: 2rem;
	}

	.sm\:max-w-24 {
		max-width: 2.4rem;
	}

	.sm\:max-w-28 {
		max-width: 2.8rem;
	}

	.sm\:max-w-32 {
		max-width: 3.2rem;
	}

	.sm\:max-w-36 {
		max-width: 3.6rem;
	}

	.sm\:max-w-40 {
		max-width: 4rem;
	}

	.sm\:max-w-44 {
		max-width: 4.4rem;
	}

	.sm\:max-w-48 {
		max-width: 4.8rem;
	}

	.sm\:max-w-52 {
		max-width: 5.2rem;
	}

	.sm\:max-w-56 {
		max-width: 5.6rem;
	}

	.sm\:max-w-60 {
		max-width: 6rem;
	}

	.sm\:max-w-64 {
		max-width: 6.4rem;
	}

	.sm\:max-w-68 {
		max-width: 6.8rem;
	}

	.sm\:max-w-72 {
		max-width: 7.2rem;
	}

	.sm\:max-w-76 {
		max-width: 7.6rem;
	}

	.sm\:max-w-80 {
		max-width: 8rem;
	}

	.sm\:max-w-84 {
		max-width: 8.4rem;
	}

	.sm\:max-w-88 {
		max-width: 8.8rem;
	}

	.sm\:max-w-92 {
		max-width: 9.2rem;
	}

	.sm\:max-w-96 {
		max-width: 9.6rem;
	}

	.sm\:max-w-128 {
		max-width: 12.8rem;
	}

	.sm\:max-w-136 {
		max-width: 13.6rem;
	}

	.sm\:max-w-160 {
		max-width: 16rem;
	}

	.sm\:max-w-192 {
		max-width: 19.2rem;
	}

	.sm\:max-w-200 {
		max-width: 20rem;
	}

	.sm\:max-w-208 {
		max-width: 20.8rem;
	}

	.sm\:max-w-216 {
		max-width: 21.6rem;
	}

	.sm\:max-w-224 {
		max-width: 22.4rem;
	}

	.sm\:max-w-256 {
		max-width: 25.6rem;
	}

	.sm\:max-w-288 {
		max-width: 28.8rem;
	}

	.sm\:max-w-320 {
		max-width: 32rem;
	}

	.sm\:max-w-360 {
		max-width: 36rem;
	}

	.sm\:max-w-384 {
		max-width: 38.4rem;
	}

	.sm\:max-w-400 {
		max-width: 40rem;
	}

	.sm\:max-w-512 {
		max-width: 51.2rem;
	}

	.sm\:max-w-640 {
		max-width: 64rem;
	}

	.sm\:max-w-xs {
		max-width: 32rem;
	}

	.sm\:max-w-sm {
		max-width: 48rem;
	}

	.sm\:max-w-md {
		max-width: 64rem;
	}

	.sm\:max-w-lg {
		max-width: 80rem;
	}

	.sm\:max-w-xl {
		max-width: 96rem;
	}

	.sm\:max-w-2xl {
		max-width: 112rem;
	}

	.sm\:max-w-3xl {
		max-width: 128rem;
	}

	.sm\:max-w-4xl {
		max-width: 144rem;
	}

	.sm\:max-w-5xl {
		max-width: 160rem;
	}

	.sm\:max-w-6xl {
		max-width: 72rem;
	}

	.sm\:max-w-full {
		max-width: 100%;
	}

	.sm\:max-w-px {
		max-width: 1px;
	}

	.sm\:max-w-auto {
		max-width: auto;
	}

	.sm\:max-w-screen {
		max-width: 100vw;
	}

	.sm\:min-h-0 {
		min-height: 0;
	}

	.sm\:min-h-1 {
		min-height: 0.25rem;
	}

	.sm\:min-h-2 {
		min-height: 0.5rem;
	}

	.sm\:min-h-3 {
		min-height: 0.75rem;
	}

	.sm\:min-h-4 {
		min-height: 0.4rem;
	}

	.sm\:min-h-5 {
		min-height: 1.25rem;
	}

	.sm\:min-h-6 {
		min-height: 0.6rem;
	}

	.sm\:min-h-8 {
		min-height: 0.8rem;
	}

	.sm\:min-h-10 {
		min-height: 2.5rem;
	}

	.sm\:min-h-12 {
		min-height: 1.2rem;
	}

	.sm\:min-h-16 {
		min-height: 1.6rem;
	}

	.sm\:min-h-20 {
		min-height: 2rem;
	}

	.sm\:min-h-24 {
		min-height: 2.4rem;
	}

	.sm\:min-h-28 {
		min-height: 2.8rem;
	}

	.sm\:min-h-32 {
		min-height: 3.2rem;
	}

	.sm\:min-h-36 {
		min-height: 3.6rem;
	}

	.sm\:min-h-40 {
		min-height: 4rem;
	}

	.sm\:min-h-44 {
		min-height: 4.4rem;
	}

	.sm\:min-h-48 {
		min-height: 4.8rem;
	}

	.sm\:min-h-52 {
		min-height: 5.2rem;
	}

	.sm\:min-h-56 {
		min-height: 5.6rem;
	}

	.sm\:min-h-60 {
		min-height: 6rem;
	}

	.sm\:min-h-64 {
		min-height: 6.4rem;
	}

	.sm\:min-h-68 {
		min-height: 6.8rem;
	}

	.sm\:min-h-72 {
		min-height: 7.2rem;
	}

	.sm\:min-h-76 {
		min-height: 7.6rem;
	}

	.sm\:min-h-80 {
		min-height: 8rem;
	}

	.sm\:min-h-84 {
		min-height: 8.4rem;
	}

	.sm\:min-h-88 {
		min-height: 8.8rem;
	}

	.sm\:min-h-92 {
		min-height: 9.2rem;
	}

	.sm\:min-h-96 {
		min-height: 9.6rem;
	}

	.sm\:min-h-128 {
		min-height: 12.8rem;
	}

	.sm\:min-h-136 {
		min-height: 13.6rem;
	}

	.sm\:min-h-160 {
		min-height: 16rem;
	}

	.sm\:min-h-192 {
		min-height: 19.2rem;
	}

	.sm\:min-h-200 {
		min-height: 20rem;
	}

	.sm\:min-h-208 {
		min-height: 20.8rem;
	}

	.sm\:min-h-216 {
		min-height: 21.6rem;
	}

	.sm\:min-h-224 {
		min-height: 22.4rem;
	}

	.sm\:min-h-256 {
		min-height: 25.6rem;
	}

	.sm\:min-h-288 {
		min-height: 28.8rem;
	}

	.sm\:min-h-320 {
		min-height: 32rem;
	}

	.sm\:min-h-360 {
		min-height: 36rem;
	}

	.sm\:min-h-384 {
		min-height: 38.4rem;
	}

	.sm\:min-h-400 {
		min-height: 40rem;
	}

	.sm\:min-h-512 {
		min-height: 51.2rem;
	}

	.sm\:min-h-640 {
		min-height: 64rem;
	}

	.sm\:min-h-full {
		min-height: 100%;
	}

	.sm\:min-h-screen {
		min-height: 100vh;
	}

	.sm\:min-h-auto {
		min-height: auto;
	}

	.sm\:min-h-px {
		min-height: 1px;
	}

	.sm\:min-h-xs {
		min-height: 32rem;
	}

	.sm\:min-h-sm {
		min-height: 48rem;
	}

	.sm\:min-h-md {
		min-height: 64rem;
	}

	.sm\:min-h-lg {
		min-height: 80rem;
	}

	.sm\:min-h-xl {
		min-height: 96rem;
	}

	.sm\:min-h-2xl {
		min-height: 112rem;
	}

	.sm\:min-h-3xl {
		min-height: 128rem;
	}

	.sm\:min-h-4xl {
		min-height: 144rem;
	}

	.sm\:min-h-5xl {
		min-height: 160rem;
	}

	.sm\:min-w-0 {
		min-width: 0;
	}

	.sm\:min-w-1 {
		min-width: 0.25rem;
	}

	.sm\:min-w-2 {
		min-width: 0.5rem;
	}

	.sm\:min-w-3 {
		min-width: 0.75rem;
	}

	.sm\:min-w-4 {
		min-width: 0.4rem;
	}

	.sm\:min-w-5 {
		min-width: 1.25rem;
	}

	.sm\:min-w-6 {
		min-width: 0.6rem;
	}

	.sm\:min-w-8 {
		min-width: 0.8rem;
	}

	.sm\:min-w-10 {
		min-width: 2.5rem;
	}

	.sm\:min-w-12 {
		min-width: 1.2rem;
	}

	.sm\:min-w-16 {
		min-width: 1.6rem;
	}

	.sm\:min-w-20 {
		min-width: 2rem;
	}

	.sm\:min-w-24 {
		min-width: 2.4rem;
	}

	.sm\:min-w-28 {
		min-width: 2.8rem;
	}

	.sm\:min-w-32 {
		min-width: 3.2rem;
	}

	.sm\:min-w-36 {
		min-width: 3.6rem;
	}

	.sm\:min-w-40 {
		min-width: 4rem;
	}

	.sm\:min-w-44 {
		min-width: 4.4rem;
	}

	.sm\:min-w-48 {
		min-width: 4.8rem;
	}

	.sm\:min-w-52 {
		min-width: 5.2rem;
	}

	.sm\:min-w-56 {
		min-width: 5.6rem;
	}

	.sm\:min-w-60 {
		min-width: 6rem;
	}

	.sm\:min-w-64 {
		min-width: 6.4rem;
	}

	.sm\:min-w-68 {
		min-width: 6.8rem;
	}

	.sm\:min-w-72 {
		min-width: 7.2rem;
	}

	.sm\:min-w-76 {
		min-width: 7.6rem;
	}

	.sm\:min-w-80 {
		min-width: 8rem;
	}

	.sm\:min-w-84 {
		min-width: 8.4rem;
	}

	.sm\:min-w-88 {
		min-width: 8.8rem;
	}

	.sm\:min-w-92 {
		min-width: 9.2rem;
	}

	.sm\:min-w-96 {
		min-width: 9.6rem;
	}

	.sm\:min-w-128 {
		min-width: 12.8rem;
	}

	.sm\:min-w-136 {
		min-width: 13.6rem;
	}

	.sm\:min-w-160 {
		min-width: 16rem;
	}

	.sm\:min-w-192 {
		min-width: 19.2rem;
	}

	.sm\:min-w-200 {
		min-width: 20rem;
	}

	.sm\:min-w-208 {
		min-width: 20.8rem;
	}

	.sm\:min-w-216 {
		min-width: 21.6rem;
	}

	.sm\:min-w-224 {
		min-width: 22.4rem;
	}

	.sm\:min-w-256 {
		min-width: 25.6rem;
	}

	.sm\:min-w-288 {
		min-width: 28.8rem;
	}

	.sm\:min-w-320 {
		min-width: 32rem;
	}

	.sm\:min-w-360 {
		min-width: 36rem;
	}

	.sm\:min-w-384 {
		min-width: 38.4rem;
	}

	.sm\:min-w-400 {
		min-width: 40rem;
	}

	.sm\:min-w-512 {
		min-width: 51.2rem;
	}

	.sm\:min-w-640 {
		min-width: 64rem;
	}

	.sm\:min-w-full {
		min-width: 100%;
	}

	.sm\:min-w-px {
		min-width: 1px;
	}

	.sm\:min-w-xs {
		min-width: 32rem;
	}

	.sm\:min-w-sm {
		min-width: 48rem;
	}

	.sm\:min-w-md {
		min-width: 64rem;
	}

	.sm\:min-w-lg {
		min-width: 80rem;
	}

	.sm\:min-w-xl {
		min-width: 96rem;
	}

	.sm\:min-w-2xl {
		min-width: 112rem;
	}

	.sm\:min-w-3xl {
		min-width: 128rem;
	}

	.sm\:min-w-4xl {
		min-width: 144rem;
	}

	.sm\:min-w-5xl {
		min-width: 160rem;
	}

	.sm\:min-w-auto {
		min-width: auto;
	}

	.sm\:min-w-screen {
		min-width: 100vw;
	}

	.sm\:object-contain {
		object-fit: contain;
	}

	.sm\:object-cover {
		object-fit: cover;
	}

	.sm\:object-fill {
		object-fit: fill;
	}

	.sm\:object-none {
		object-fit: none;
	}

	.sm\:object-scale-down {
		object-fit: scale-down;
	}

	.sm\:object-bottom {
		object-position: bottom;
	}

	.sm\:object-center {
		object-position: center;
	}

	.sm\:object-left {
		object-position: left;
	}

	.sm\:object-left-bottom {
		object-position: left bottom;
	}

	.sm\:object-left-top {
		object-position: left top;
	}

	.sm\:object-right {
		object-position: right;
	}

	.sm\:object-right-bottom {
		object-position: right bottom;
	}

	.sm\:object-right-top {
		object-position: right top;
	}

	.sm\:object-top {
		object-position: top;
	}

	.sm\:opacity-0 {
		opacity: 0;
	}

	.sm\:opacity-25 {
		opacity: 0.25;
	}

	.sm\:opacity-50 {
		opacity: 0.5;
	}

	.sm\:opacity-75 {
		opacity: 0.75;
	}

	.sm\:opacity-100 {
		opacity: 1;
	}

	.sm\:outline-none {
		outline: 0;
	}

	.sm\:focus\:outline-none:focus {
		outline: 0;
	}

	.sm\:overflow-auto {
		overflow: auto;
	}

	.sm\:overflow-hidden {
		overflow: hidden;
	}

	.sm\:overflow-visible {
		overflow: visible;
	}

	.sm\:overflow-scroll {
		overflow: scroll;
	}

	.sm\:overflow-x-auto {
		overflow-x: auto;
	}

	.sm\:overflow-y-auto {
		overflow-y: auto;
	}

	.sm\:overflow-x-hidden {
		overflow-x: hidden;
	}

	.sm\:overflow-y-hidden {
		overflow-y: hidden;
	}

	.sm\:overflow-x-visible {
		overflow-x: visible;
	}

	.sm\:overflow-y-visible {
		overflow-y: visible;
	}

	.sm\:overflow-x-scroll {
		overflow-x: scroll;
	}

	.sm\:overflow-y-scroll {
		overflow-y: scroll;
	}

	.sm\:scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}

	.sm\:scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}

	.sm\:p-0 {
		padding: 0;
	}

	.sm\:p-1 {
		padding: 0.25rem;
	}

	.sm\:p-2 {
		padding: 0.5rem;
	}

	.sm\:p-3 {
		padding: 0.75rem;
	}

	.sm\:p-4 {
		padding: 0.4rem;
	}

	.sm\:p-5 {
		padding: 1.25rem;
	}

	.sm\:p-6 {
		padding: 0.6rem;
	}

	.sm\:p-8 {
		padding: 0.8rem;
	}

	.sm\:p-10 {
		padding: 2.5rem;
	}

	.sm\:p-12 {
		padding: 1.2rem;
	}

	.sm\:p-16 {
		padding: 1.6rem;
	}

	.sm\:p-20 {
		padding: 2rem;
	}

	.sm\:p-24 {
		padding: 2.4rem;
	}

	.sm\:p-28 {
		padding: 2.8rem;
	}

	.sm\:p-32 {
		padding: 3.2rem;
	}

	.sm\:p-36 {
		padding: 3.6rem;
	}

	.sm\:p-40 {
		padding: 4rem;
	}

	.sm\:p-44 {
		padding: 4.4rem;
	}

	.sm\:p-48 {
		padding: 4.8rem;
	}

	.sm\:p-52 {
		padding: 5.2rem;
	}

	.sm\:p-56 {
		padding: 5.6rem;
	}

	.sm\:p-60 {
		padding: 6rem;
	}

	.sm\:p-64 {
		padding: 6.4rem;
	}

	.sm\:p-68 {
		padding: 6.8rem;
	}

	.sm\:p-72 {
		padding: 7.2rem;
	}

	.sm\:p-76 {
		padding: 7.6rem;
	}

	.sm\:p-80 {
		padding: 8rem;
	}

	.sm\:p-84 {
		padding: 8.4rem;
	}

	.sm\:p-88 {
		padding: 8.8rem;
	}

	.sm\:p-92 {
		padding: 9.2rem;
	}

	.sm\:p-96 {
		padding: 9.6rem;
	}

	.sm\:p-128 {
		padding: 12.8rem;
	}

	.sm\:p-136 {
		padding: 13.6rem;
	}

	.sm\:p-160 {
		padding: 16rem;
	}

	.sm\:p-192 {
		padding: 19.2rem;
	}

	.sm\:p-200 {
		padding: 20rem;
	}

	.sm\:p-208 {
		padding: 20.8rem;
	}

	.sm\:p-216 {
		padding: 21.6rem;
	}

	.sm\:p-224 {
		padding: 22.4rem;
	}

	.sm\:p-256 {
		padding: 25.6rem;
	}

	.sm\:p-288 {
		padding: 28.8rem;
	}

	.sm\:p-320 {
		padding: 32rem;
	}

	.sm\:p-360 {
		padding: 36rem;
	}

	.sm\:p-384 {
		padding: 38.4rem;
	}

	.sm\:p-400 {
		padding: 40rem;
	}

	.sm\:p-512 {
		padding: 51.2rem;
	}

	.sm\:p-640 {
		padding: 64rem;
	}

	.sm\:p-px {
		padding: 1px;
	}

	.sm\:p-xs {
		padding: 32rem;
	}

	.sm\:p-sm {
		padding: 48rem;
	}

	.sm\:p-md {
		padding: 64rem;
	}

	.sm\:p-lg {
		padding: 80rem;
	}

	.sm\:p-xl {
		padding: 96rem;
	}

	.sm\:p-2xl {
		padding: 112rem;
	}

	.sm\:p-3xl {
		padding: 128rem;
	}

	.sm\:p-4xl {
		padding: 144rem;
	}

	.sm\:p-5xl {
		padding: 160rem;
	}

	.sm\:p-auto {
		padding: auto;
	}

	.sm\:py-0 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.sm\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.sm\:py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.sm\:px-1 {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.sm\:py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.sm\:px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.sm\:py-3 {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.sm\:px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.sm\:py-4 {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.sm\:px-4 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}

	.sm\:py-5 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.sm\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.sm\:py-6 {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.sm\:px-6 {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.sm\:py-8 {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
	}

	.sm\:px-8 {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.sm\:py-10 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.sm\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.sm\:py-12 {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.sm\:px-12 {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}

	.sm\:py-16 {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	.sm\:px-16 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.sm\:py-20 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.sm\:px-20 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.sm\:py-24 {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	.sm\:px-24 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.sm\:py-28 {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}

	.sm\:px-28 {
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	.sm\:py-32 {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.sm\:px-32 {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.sm\:py-36 {
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
	}

	.sm\:px-36 {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.sm\:py-40 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.sm\:px-40 {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	.sm\:py-44 {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.sm\:px-44 {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.sm\:py-48 {
		padding-top: 4.8rem;
		padding-bottom: 4.8rem;
	}

	.sm\:px-48 {
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.sm\:py-52 {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.sm\:px-52 {
		padding-left: 5.2rem;
		padding-right: 5.2rem;
	}

	.sm\:py-56 {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}

	.sm\:px-56 {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}

	.sm\:py-60 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.sm\:px-60 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.sm\:py-64 {
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;
	}

	.sm\:px-64 {
		padding-left: 6.4rem;
		padding-right: 6.4rem;
	}

	.sm\:py-68 {
		padding-top: 6.8rem;
		padding-bottom: 6.8rem;
	}

	.sm\:px-68 {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.sm\:py-72 {
		padding-top: 7.2rem;
		padding-bottom: 7.2rem;
	}

	.sm\:px-72 {
		padding-left: 7.2rem;
		padding-right: 7.2rem;
	}

	.sm\:py-76 {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
	}

	.sm\:px-76 {
		padding-left: 7.6rem;
		padding-right: 7.6rem;
	}

	.sm\:py-80 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.sm\:px-80 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.sm\:py-84 {
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}

	.sm\:px-84 {
		padding-left: 8.4rem;
		padding-right: 8.4rem;
	}

	.sm\:py-88 {
		padding-top: 8.8rem;
		padding-bottom: 8.8rem;
	}

	.sm\:px-88 {
		padding-left: 8.8rem;
		padding-right: 8.8rem;
	}

	.sm\:py-92 {
		padding-top: 9.2rem;
		padding-bottom: 9.2rem;
	}

	.sm\:px-92 {
		padding-left: 9.2rem;
		padding-right: 9.2rem;
	}

	.sm\:py-96 {
		padding-top: 9.6rem;
		padding-bottom: 9.6rem;
	}

	.sm\:px-96 {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}

	.sm\:py-128 {
		padding-top: 12.8rem;
		padding-bottom: 12.8rem;
	}

	.sm\:px-128 {
		padding-left: 12.8rem;
		padding-right: 12.8rem;
	}

	.sm\:py-136 {
		padding-top: 13.6rem;
		padding-bottom: 13.6rem;
	}

	.sm\:px-136 {
		padding-left: 13.6rem;
		padding-right: 13.6rem;
	}

	.sm\:py-160 {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}

	.sm\:px-160 {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.sm\:py-192 {
		padding-top: 19.2rem;
		padding-bottom: 19.2rem;
	}

	.sm\:px-192 {
		padding-left: 19.2rem;
		padding-right: 19.2rem;
	}

	.sm\:py-200 {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.sm\:px-200 {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.sm\:py-208 {
		padding-top: 20.8rem;
		padding-bottom: 20.8rem;
	}

	.sm\:px-208 {
		padding-left: 20.8rem;
		padding-right: 20.8rem;
	}

	.sm\:py-216 {
		padding-top: 21.6rem;
		padding-bottom: 21.6rem;
	}

	.sm\:px-216 {
		padding-left: 21.6rem;
		padding-right: 21.6rem;
	}

	.sm\:py-224 {
		padding-top: 22.4rem;
		padding-bottom: 22.4rem;
	}

	.sm\:px-224 {
		padding-left: 22.4rem;
		padding-right: 22.4rem;
	}

	.sm\:py-256 {
		padding-top: 25.6rem;
		padding-bottom: 25.6rem;
	}

	.sm\:px-256 {
		padding-left: 25.6rem;
		padding-right: 25.6rem;
	}

	.sm\:py-288 {
		padding-top: 28.8rem;
		padding-bottom: 28.8rem;
	}

	.sm\:px-288 {
		padding-left: 28.8rem;
		padding-right: 28.8rem;
	}

	.sm\:py-320 {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.sm\:px-320 {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.sm\:py-360 {
		padding-top: 36rem;
		padding-bottom: 36rem;
	}

	.sm\:px-360 {
		padding-left: 36rem;
		padding-right: 36rem;
	}

	.sm\:py-384 {
		padding-top: 38.4rem;
		padding-bottom: 38.4rem;
	}

	.sm\:px-384 {
		padding-left: 38.4rem;
		padding-right: 38.4rem;
	}

	.sm\:py-400 {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.sm\:px-400 {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.sm\:py-512 {
		padding-top: 51.2rem;
		padding-bottom: 51.2rem;
	}

	.sm\:px-512 {
		padding-left: 51.2rem;
		padding-right: 51.2rem;
	}

	.sm\:py-640 {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.sm\:px-640 {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.sm\:py-px {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.sm\:px-px {
		padding-left: 1px;
		padding-right: 1px;
	}

	.sm\:py-xs {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.sm\:px-xs {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.sm\:py-sm {
		padding-top: 48rem;
		padding-bottom: 48rem;
	}

	.sm\:px-sm {
		padding-left: 48rem;
		padding-right: 48rem;
	}

	.sm\:py-md {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.sm\:px-md {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.sm\:py-lg {
		padding-top: 80rem;
		padding-bottom: 80rem;
	}

	.sm\:px-lg {
		padding-left: 80rem;
		padding-right: 80rem;
	}

	.sm\:py-xl {
		padding-top: 96rem;
		padding-bottom: 96rem;
	}

	.sm\:px-xl {
		padding-left: 96rem;
		padding-right: 96rem;
	}

	.sm\:py-2xl {
		padding-top: 112rem;
		padding-bottom: 112rem;
	}

	.sm\:px-2xl {
		padding-left: 112rem;
		padding-right: 112rem;
	}

	.sm\:py-3xl {
		padding-top: 128rem;
		padding-bottom: 128rem;
	}

	.sm\:px-3xl {
		padding-left: 128rem;
		padding-right: 128rem;
	}

	.sm\:py-4xl {
		padding-top: 144rem;
		padding-bottom: 144rem;
	}

	.sm\:px-4xl {
		padding-left: 144rem;
		padding-right: 144rem;
	}

	.sm\:py-5xl {
		padding-top: 160rem;
		padding-bottom: 160rem;
	}

	.sm\:px-5xl {
		padding-left: 160rem;
		padding-right: 160rem;
	}

	.sm\:py-auto {
		padding-top: auto;
		padding-bottom: auto;
	}

	.sm\:px-auto {
		padding-left: auto;
		padding-right: auto;
	}

	.sm\:pt-0 {
		padding-top: 0;
	}

	.sm\:pr-0 {
		padding-right: 0;
	}

	.sm\:pb-0 {
		padding-bottom: 0;
	}

	.sm\:pl-0 {
		padding-left: 0;
	}

	.sm\:pt-1 {
		padding-top: 0.25rem;
	}

	.sm\:pr-1 {
		padding-right: 0.25rem;
	}

	.sm\:pb-1 {
		padding-bottom: 0.25rem;
	}

	.sm\:pl-1 {
		padding-left: 0.25rem;
	}

	.sm\:pt-2 {
		padding-top: 0.5rem;
	}

	.sm\:pr-2 {
		padding-right: 0.5rem;
	}

	.sm\:pb-2 {
		padding-bottom: 0.5rem;
	}

	.sm\:pl-2 {
		padding-left: 0.5rem;
	}

	.sm\:pt-3 {
		padding-top: 0.75rem;
	}

	.sm\:pr-3 {
		padding-right: 0.75rem;
	}

	.sm\:pb-3 {
		padding-bottom: 0.75rem;
	}

	.sm\:pl-3 {
		padding-left: 0.75rem;
	}

	.sm\:pt-4 {
		padding-top: 0.4rem;
	}

	.sm\:pr-4 {
		padding-right: 0.4rem;
	}

	.sm\:pb-4 {
		padding-bottom: 0.4rem;
	}

	.sm\:pl-4 {
		padding-left: 0.4rem;
	}

	.sm\:pt-5 {
		padding-top: 1.25rem;
	}

	.sm\:pr-5 {
		padding-right: 1.25rem;
	}

	.sm\:pb-5 {
		padding-bottom: 1.25rem;
	}

	.sm\:pl-5 {
		padding-left: 1.25rem;
	}

	.sm\:pt-6 {
		padding-top: 0.6rem;
	}

	.sm\:pr-6 {
		padding-right: 0.6rem;
	}

	.sm\:pb-6 {
		padding-bottom: 0.6rem;
	}

	.sm\:pl-6 {
		padding-left: 0.6rem;
	}

	.sm\:pt-8 {
		padding-top: 0.8rem;
	}

	.sm\:pr-8 {
		padding-right: 0.8rem;
	}

	.sm\:pb-8 {
		padding-bottom: 0.8rem;
	}

	.sm\:pl-8 {
		padding-left: 0.8rem;
	}

	.sm\:pt-10 {
		padding-top: 2.5rem;
	}

	.sm\:pr-10 {
		padding-right: 2.5rem;
	}

	.sm\:pb-10 {
		padding-bottom: 2.5rem;
	}

	.sm\:pl-10 {
		padding-left: 2.5rem;
	}

	.sm\:pt-12 {
		padding-top: 1.2rem;
	}

	.sm\:pr-12 {
		padding-right: 1.2rem;
	}

	.sm\:pb-12 {
		padding-bottom: 1.2rem;
	}

	.sm\:pl-12 {
		padding-left: 1.2rem;
	}

	.sm\:pt-16 {
		padding-top: 1.6rem;
	}

	.sm\:pr-16 {
		padding-right: 1.6rem;
	}

	.sm\:pb-16 {
		padding-bottom: 1.6rem;
	}

	.sm\:pl-16 {
		padding-left: 1.6rem;
	}

	.sm\:pt-20 {
		padding-top: 2rem;
	}

	.sm\:pr-20 {
		padding-right: 2rem;
	}

	.sm\:pb-20 {
		padding-bottom: 2rem;
	}

	.sm\:pl-20 {
		padding-left: 2rem;
	}

	.sm\:pt-24 {
		padding-top: 2.4rem;
	}

	.sm\:pr-24 {
		padding-right: 2.4rem;
	}

	.sm\:pb-24 {
		padding-bottom: 2.4rem;
	}

	.sm\:pl-24 {
		padding-left: 2.4rem;
	}

	.sm\:pt-28 {
		padding-top: 2.8rem;
	}

	.sm\:pr-28 {
		padding-right: 2.8rem;
	}

	.sm\:pb-28 {
		padding-bottom: 2.8rem;
	}

	.sm\:pl-28 {
		padding-left: 2.8rem;
	}

	.sm\:pt-32 {
		padding-top: 3.2rem;
	}

	.sm\:pr-32 {
		padding-right: 3.2rem;
	}

	.sm\:pb-32 {
		padding-bottom: 3.2rem;
	}

	.sm\:pl-32 {
		padding-left: 3.2rem;
	}

	.sm\:pt-36 {
		padding-top: 3.6rem;
	}

	.sm\:pr-36 {
		padding-right: 3.6rem;
	}

	.sm\:pb-36 {
		padding-bottom: 3.6rem;
	}

	.sm\:pl-36 {
		padding-left: 3.6rem;
	}

	.sm\:pt-40 {
		padding-top: 4rem;
	}

	.sm\:pr-40 {
		padding-right: 4rem;
	}

	.sm\:pb-40 {
		padding-bottom: 4rem;
	}

	.sm\:pl-40 {
		padding-left: 4rem;
	}

	.sm\:pt-44 {
		padding-top: 4.4rem;
	}

	.sm\:pr-44 {
		padding-right: 4.4rem;
	}

	.sm\:pb-44 {
		padding-bottom: 4.4rem;
	}

	.sm\:pl-44 {
		padding-left: 4.4rem;
	}

	.sm\:pt-48 {
		padding-top: 4.8rem;
	}

	.sm\:pr-48 {
		padding-right: 4.8rem;
	}

	.sm\:pb-48 {
		padding-bottom: 4.8rem;
	}

	.sm\:pl-48 {
		padding-left: 4.8rem;
	}

	.sm\:pt-52 {
		padding-top: 5.2rem;
	}

	.sm\:pr-52 {
		padding-right: 5.2rem;
	}

	.sm\:pb-52 {
		padding-bottom: 5.2rem;
	}

	.sm\:pl-52 {
		padding-left: 5.2rem;
	}

	.sm\:pt-56 {
		padding-top: 5.6rem;
	}

	.sm\:pr-56 {
		padding-right: 5.6rem;
	}

	.sm\:pb-56 {
		padding-bottom: 5.6rem;
	}

	.sm\:pl-56 {
		padding-left: 5.6rem;
	}

	.sm\:pt-60 {
		padding-top: 6rem;
	}

	.sm\:pr-60 {
		padding-right: 6rem;
	}

	.sm\:pb-60 {
		padding-bottom: 6rem;
	}

	.sm\:pl-60 {
		padding-left: 6rem;
	}

	.sm\:pt-64 {
		padding-top: 6.4rem;
	}

	.sm\:pr-64 {
		padding-right: 6.4rem;
	}

	.sm\:pb-64 {
		padding-bottom: 6.4rem;
	}

	.sm\:pl-64 {
		padding-left: 6.4rem;
	}

	.sm\:pt-68 {
		padding-top: 6.8rem;
	}

	.sm\:pr-68 {
		padding-right: 6.8rem;
	}

	.sm\:pb-68 {
		padding-bottom: 6.8rem;
	}

	.sm\:pl-68 {
		padding-left: 6.8rem;
	}

	.sm\:pt-72 {
		padding-top: 7.2rem;
	}

	.sm\:pr-72 {
		padding-right: 7.2rem;
	}

	.sm\:pb-72 {
		padding-bottom: 7.2rem;
	}

	.sm\:pl-72 {
		padding-left: 7.2rem;
	}

	.sm\:pt-76 {
		padding-top: 7.6rem;
	}

	.sm\:pr-76 {
		padding-right: 7.6rem;
	}

	.sm\:pb-76 {
		padding-bottom: 7.6rem;
	}

	.sm\:pl-76 {
		padding-left: 7.6rem;
	}

	.sm\:pt-80 {
		padding-top: 8rem;
	}

	.sm\:pr-80 {
		padding-right: 8rem;
	}

	.sm\:pb-80 {
		padding-bottom: 8rem;
	}

	.sm\:pl-80 {
		padding-left: 8rem;
	}

	.sm\:pt-84 {
		padding-top: 8.4rem;
	}

	.sm\:pr-84 {
		padding-right: 8.4rem;
	}

	.sm\:pb-84 {
		padding-bottom: 8.4rem;
	}

	.sm\:pl-84 {
		padding-left: 8.4rem;
	}

	.sm\:pt-88 {
		padding-top: 8.8rem;
	}

	.sm\:pr-88 {
		padding-right: 8.8rem;
	}

	.sm\:pb-88 {
		padding-bottom: 8.8rem;
	}

	.sm\:pl-88 {
		padding-left: 8.8rem;
	}

	.sm\:pt-92 {
		padding-top: 9.2rem;
	}

	.sm\:pr-92 {
		padding-right: 9.2rem;
	}

	.sm\:pb-92 {
		padding-bottom: 9.2rem;
	}

	.sm\:pl-92 {
		padding-left: 9.2rem;
	}

	.sm\:pt-96 {
		padding-top: 9.6rem;
	}

	.sm\:pr-96 {
		padding-right: 9.6rem;
	}

	.sm\:pb-96 {
		padding-bottom: 9.6rem;
	}

	.sm\:pl-96 {
		padding-left: 9.6rem;
	}

	.sm\:pt-128 {
		padding-top: 12.8rem;
	}

	.sm\:pr-128 {
		padding-right: 12.8rem;
	}

	.sm\:pb-128 {
		padding-bottom: 12.8rem;
	}

	.sm\:pl-128 {
		padding-left: 12.8rem;
	}

	.sm\:pt-136 {
		padding-top: 13.6rem;
	}

	.sm\:pr-136 {
		padding-right: 13.6rem;
	}

	.sm\:pb-136 {
		padding-bottom: 13.6rem;
	}

	.sm\:pl-136 {
		padding-left: 13.6rem;
	}

	.sm\:pt-160 {
		padding-top: 16rem;
	}

	.sm\:pr-160 {
		padding-right: 16rem;
	}

	.sm\:pb-160 {
		padding-bottom: 16rem;
	}

	.sm\:pl-160 {
		padding-left: 16rem;
	}

	.sm\:pt-192 {
		padding-top: 19.2rem;
	}

	.sm\:pr-192 {
		padding-right: 19.2rem;
	}

	.sm\:pb-192 {
		padding-bottom: 19.2rem;
	}

	.sm\:pl-192 {
		padding-left: 19.2rem;
	}

	.sm\:pt-200 {
		padding-top: 20rem;
	}

	.sm\:pr-200 {
		padding-right: 20rem;
	}

	.sm\:pb-200 {
		padding-bottom: 20rem;
	}

	.sm\:pl-200 {
		padding-left: 20rem;
	}

	.sm\:pt-208 {
		padding-top: 20.8rem;
	}

	.sm\:pr-208 {
		padding-right: 20.8rem;
	}

	.sm\:pb-208 {
		padding-bottom: 20.8rem;
	}

	.sm\:pl-208 {
		padding-left: 20.8rem;
	}

	.sm\:pt-216 {
		padding-top: 21.6rem;
	}

	.sm\:pr-216 {
		padding-right: 21.6rem;
	}

	.sm\:pb-216 {
		padding-bottom: 21.6rem;
	}

	.sm\:pl-216 {
		padding-left: 21.6rem;
	}

	.sm\:pt-224 {
		padding-top: 22.4rem;
	}

	.sm\:pr-224 {
		padding-right: 22.4rem;
	}

	.sm\:pb-224 {
		padding-bottom: 22.4rem;
	}

	.sm\:pl-224 {
		padding-left: 22.4rem;
	}

	.sm\:pt-256 {
		padding-top: 25.6rem;
	}

	.sm\:pr-256 {
		padding-right: 25.6rem;
	}

	.sm\:pb-256 {
		padding-bottom: 25.6rem;
	}

	.sm\:pl-256 {
		padding-left: 25.6rem;
	}

	.sm\:pt-288 {
		padding-top: 28.8rem;
	}

	.sm\:pr-288 {
		padding-right: 28.8rem;
	}

	.sm\:pb-288 {
		padding-bottom: 28.8rem;
	}

	.sm\:pl-288 {
		padding-left: 28.8rem;
	}

	.sm\:pt-320 {
		padding-top: 32rem;
	}

	.sm\:pr-320 {
		padding-right: 32rem;
	}

	.sm\:pb-320 {
		padding-bottom: 32rem;
	}

	.sm\:pl-320 {
		padding-left: 32rem;
	}

	.sm\:pt-360 {
		padding-top: 36rem;
	}

	.sm\:pr-360 {
		padding-right: 36rem;
	}

	.sm\:pb-360 {
		padding-bottom: 36rem;
	}

	.sm\:pl-360 {
		padding-left: 36rem;
	}

	.sm\:pt-384 {
		padding-top: 38.4rem;
	}

	.sm\:pr-384 {
		padding-right: 38.4rem;
	}

	.sm\:pb-384 {
		padding-bottom: 38.4rem;
	}

	.sm\:pl-384 {
		padding-left: 38.4rem;
	}

	.sm\:pt-400 {
		padding-top: 40rem;
	}

	.sm\:pr-400 {
		padding-right: 40rem;
	}

	.sm\:pb-400 {
		padding-bottom: 40rem;
	}

	.sm\:pl-400 {
		padding-left: 40rem;
	}

	.sm\:pt-512 {
		padding-top: 51.2rem;
	}

	.sm\:pr-512 {
		padding-right: 51.2rem;
	}

	.sm\:pb-512 {
		padding-bottom: 51.2rem;
	}

	.sm\:pl-512 {
		padding-left: 51.2rem;
	}

	.sm\:pt-640 {
		padding-top: 64rem;
	}

	.sm\:pr-640 {
		padding-right: 64rem;
	}

	.sm\:pb-640 {
		padding-bottom: 64rem;
	}

	.sm\:pl-640 {
		padding-left: 64rem;
	}

	.sm\:pt-px {
		padding-top: 1px;
	}

	.sm\:pr-px {
		padding-right: 1px;
	}

	.sm\:pb-px {
		padding-bottom: 1px;
	}

	.sm\:pl-px {
		padding-left: 1px;
	}

	.sm\:pt-xs {
		padding-top: 32rem;
	}

	.sm\:pr-xs {
		padding-right: 32rem;
	}

	.sm\:pb-xs {
		padding-bottom: 32rem;
	}

	.sm\:pl-xs {
		padding-left: 32rem;
	}

	.sm\:pt-sm {
		padding-top: 48rem;
	}

	.sm\:pr-sm {
		padding-right: 48rem;
	}

	.sm\:pb-sm {
		padding-bottom: 48rem;
	}

	.sm\:pl-sm {
		padding-left: 48rem;
	}

	.sm\:pt-md {
		padding-top: 64rem;
	}

	.sm\:pr-md {
		padding-right: 64rem;
	}

	.sm\:pb-md {
		padding-bottom: 64rem;
	}

	.sm\:pl-md {
		padding-left: 64rem;
	}

	.sm\:pt-lg {
		padding-top: 80rem;
	}

	.sm\:pr-lg {
		padding-right: 80rem;
	}

	.sm\:pb-lg {
		padding-bottom: 80rem;
	}

	.sm\:pl-lg {
		padding-left: 80rem;
	}

	.sm\:pt-xl {
		padding-top: 96rem;
	}

	.sm\:pr-xl {
		padding-right: 96rem;
	}

	.sm\:pb-xl {
		padding-bottom: 96rem;
	}

	.sm\:pl-xl {
		padding-left: 96rem;
	}

	.sm\:pt-2xl {
		padding-top: 112rem;
	}

	.sm\:pr-2xl {
		padding-right: 112rem;
	}

	.sm\:pb-2xl {
		padding-bottom: 112rem;
	}

	.sm\:pl-2xl {
		padding-left: 112rem;
	}

	.sm\:pt-3xl {
		padding-top: 128rem;
	}

	.sm\:pr-3xl {
		padding-right: 128rem;
	}

	.sm\:pb-3xl {
		padding-bottom: 128rem;
	}

	.sm\:pl-3xl {
		padding-left: 128rem;
	}

	.sm\:pt-4xl {
		padding-top: 144rem;
	}

	.sm\:pr-4xl {
		padding-right: 144rem;
	}

	.sm\:pb-4xl {
		padding-bottom: 144rem;
	}

	.sm\:pl-4xl {
		padding-left: 144rem;
	}

	.sm\:pt-5xl {
		padding-top: 160rem;
	}

	.sm\:pr-5xl {
		padding-right: 160rem;
	}

	.sm\:pb-5xl {
		padding-bottom: 160rem;
	}

	.sm\:pl-5xl {
		padding-left: 160rem;
	}

	.sm\:pt-auto {
		padding-top: auto;
	}

	.sm\:pr-auto {
		padding-right: auto;
	}

	.sm\:pb-auto {
		padding-bottom: auto;
	}

	.sm\:pl-auto {
		padding-left: auto;
	}

	.sm\:pointer-events-none {
		pointer-events: none;
	}

	.sm\:pointer-events-auto {
		pointer-events: auto;
	}

	.sm\:static {
		position: static;
	}

	.sm\:fixed {
		position: fixed;
	}

	.sm\:absolute {
		position: absolute;
	}

	.sm\:relative {
		position: relative;
	}

	.sm\:sticky {
		position: -webkit-sticky;
		position: sticky;
	}

	.sm\:inset-0 {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.sm\:inset-auto {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}

	.sm\:inset-y-0 {
		top: 0;
		bottom: 0;
	}

	.sm\:inset-x-0 {
		right: 0;
		left: 0;
	}

	.sm\:inset-y-auto {
		top: auto;
		bottom: auto;
	}

	.sm\:inset-x-auto {
		right: auto;
		left: auto;
	}

	.sm\:top-0 {
		top: 0;
	}

	.sm\:right-0 {
		right: 0;
	}

	.sm\:bottom-0 {
		bottom: 0;
	}

	.sm\:left-0 {
		left: 0;
	}

	.sm\:top-auto {
		top: auto;
	}

	.sm\:right-auto {
		right: auto;
	}

	.sm\:bottom-auto {
		bottom: auto;
	}

	.sm\:left-auto {
		left: auto;
	}

	.sm\:resize-none {
		resize: none;
	}

	.sm\:resize-y {
		resize: vertical;
	}

	.sm\:resize-x {
		resize: horizontal;
	}

	.sm\:resize {
		resize: both;
	}

	.sm\:shadow-0 {
		box-shadow: none;
	}

	.sm\:shadow-1 {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-2 {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-3 {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-4 {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-5 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-6 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-7 {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-8 {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-9 {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-10 {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-11 {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-12 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-13 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-14 {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-15 {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-16 {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-17 {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-18 {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-19 {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-20 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-21 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-22 {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-23 {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow-24 {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.sm\:shadow-md {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:shadow-lg {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:shadow-xl {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.sm\:shadow-2xl {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.sm\:shadow-inner {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.sm\:shadow-outline {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.sm\:shadow-none {
		box-shadow: none;
	}

	.sm\:hover\:shadow-0:hover {
		box-shadow: none;
	}

	.sm\:hover\:shadow-1:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-2:hover {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-3:hover {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-4:hover {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-5:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-6:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-7:hover {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-8:hover {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-9:hover {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-10:hover {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-11:hover {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-12:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-13:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-14:hover {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-15:hover {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-16:hover {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-17:hover {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-18:hover {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-19:hover {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-20:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-21:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-22:hover {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-23:hover {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow-24:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:hover\:shadow:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.sm\:hover\:shadow-md:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:hover\:shadow-lg:hover {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:hover\:shadow-xl:hover {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.sm\:hover\:shadow-2xl:hover {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.sm\:hover\:shadow-inner:hover {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.sm\:hover\:shadow-outline:hover {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.sm\:hover\:shadow-none:hover {
		box-shadow: none;
	}

	.sm\:focus\:shadow-0:focus {
		box-shadow: none;
	}

	.sm\:focus\:shadow-1:focus {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-2:focus {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-3:focus {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-4:focus {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-5:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-6:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-7:focus {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-8:focus {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-9:focus {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-10:focus {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-11:focus {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-12:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-13:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-14:focus {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-15:focus {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-16:focus {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-17:focus {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-18:focus {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-19:focus {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-20:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-21:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-22:focus {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-23:focus {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow-24:focus {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.sm\:focus\:shadow:focus {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.sm\:focus\:shadow-md:focus {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:focus\:shadow-lg:focus {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.sm\:focus\:shadow-xl:focus {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.sm\:focus\:shadow-2xl:focus {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.sm\:focus\:shadow-inner:focus {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.sm\:focus\:shadow-outline:focus {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.sm\:focus\:shadow-none:focus {
		box-shadow: none;
	}

	.sm\:fill-current {
		fill: currentColor;
	}

	.sm\:stroke-current {
		stroke: currentColor;
	}

	.sm\:table-auto {
		table-layout: auto;
	}

	.sm\:table-fixed {
		table-layout: fixed;
	}

	.sm\:text-left {
		text-align: left;
	}

	.sm\:text-center {
		text-align: center;
	}

	.sm\:text-right {
		text-align: right;
	}

	.sm\:text-justify {
		text-align: justify;
	}

	.sm\:text-transparent {
		color: transparent;
	}

	.sm\:text-black {
		color: #22292f;
	}

	.sm\:text-white {
		color: #ffffff;
	}

	.sm\:text-gray-100 {
		color: #f7fafc;
	}

	.sm\:text-gray-200 {
		color: #edf2f7;
	}

	.sm\:text-gray-300 {
		color: #e2e8f0;
	}

	.sm\:text-gray-400 {
		color: #cbd5e0;
	}

	.sm\:text-gray-500 {
		color: #a0aec0;
	}

	.sm\:text-gray-600 {
		color: #718096;
	}

	.sm\:text-gray-700 {
		color: #4a5568;
	}

	.sm\:text-gray-800 {
		color: #2d3748;
	}

	.sm\:text-gray-900 {
		color: #1a202c;
	}

	.sm\:text-red {
		color: #e3342f;
	}

	.sm\:text-orange {
		color: #f6993f;
	}

	.sm\:text-yellow {
		color: #ffed4a;
	}

	.sm\:text-green {
		color: #38c172;
	}

	.sm\:text-teal {
		color: #4dc0b5;
	}

	.sm\:text-blue {
		color: #3490dc;
	}

	.sm\:text-indigo {
		color: #6574cd;
	}

	.sm\:text-purple {
		color: #9561e2;
	}

	.sm\:text-pink {
		color: #f66d9b;
	}

	.sm\:text-grey-darkest {
		color: #3d4852;
	}

	.sm\:text-grey-darker {
		color: #606f7b;
	}

	.sm\:text-grey-dark {
		color: #8795a1;
	}

	.sm\:text-grey {
		color: #b8c2cc;
	}

	.sm\:text-grey-light {
		color: #dae1e7;
	}

	.sm\:text-grey-lighter {
		color: #f1f5f8;
	}

	.sm\:text-grey-lightest {
		color: #f8fafc;
	}

	.sm\:text-red-darkest {
		color: #3b0d0c;
	}

	.sm\:text-red-darker {
		color: #621b18;
	}

	.sm\:text-red-dark {
		color: #cc1f1a;
	}

	.sm\:text-red-light {
		color: #ef5753;
	}

	.sm\:text-red-lighter {
		color: #f9acaa;
	}

	.sm\:text-red-lightest {
		color: #fcebea;
	}

	.sm\:text-orange-darkest {
		color: #462a16;
	}

	.sm\:text-orange-darker {
		color: #613b1f;
	}

	.sm\:text-orange-dark {
		color: #de751f;
	}

	.sm\:text-orange-light {
		color: #faad63;
	}

	.sm\:text-orange-lighter {
		color: #fcd9b6;
	}

	.sm\:text-orange-lightest {
		color: #fff5eb;
	}

	.sm\:text-yellow-darkest {
		color: #453411;
	}

	.sm\:text-yellow-darker {
		color: #684f1d;
	}

	.sm\:text-yellow-dark {
		color: #f2d024;
	}

	.sm\:text-yellow-light {
		color: #fff382;
	}

	.sm\:text-yellow-lighter {
		color: #fff9c2;
	}

	.sm\:text-yellow-lightest {
		color: #fcfbeb;
	}

	.sm\:text-green-darkest {
		color: #0f2f21;
	}

	.sm\:text-green-darker {
		color: #1a4731;
	}

	.sm\:text-green-dark {
		color: #1f9d55;
	}

	.sm\:text-green-light {
		color: #51d88a;
	}

	.sm\:text-green-lighter {
		color: #a2f5bf;
	}

	.sm\:text-green-lightest {
		color: #e3fcec;
	}

	.sm\:text-teal-darkest {
		color: #0d3331;
	}

	.sm\:text-teal-darker {
		color: #20504f;
	}

	.sm\:text-teal-dark {
		color: #38a89d;
	}

	.sm\:text-teal-light {
		color: #64d5ca;
	}

	.sm\:text-teal-lighter {
		color: #a0f0ed;
	}

	.sm\:text-teal-lightest {
		color: #e8fffe;
	}

	.sm\:text-blue-darkest {
		color: #12283a;
	}

	.sm\:text-blue-darker {
		color: #1c3d5a;
	}

	.sm\:text-blue-dark {
		color: #2779bd;
	}

	.sm\:text-blue-light {
		color: #6cb2eb;
	}

	.sm\:text-blue-lighter {
		color: #bcdefa;
	}

	.sm\:text-blue-lightest {
		color: #eff8ff;
	}

	.sm\:text-indigo-darkest {
		color: #191e38;
	}

	.sm\:text-indigo-darker {
		color: #2f365f;
	}

	.sm\:text-indigo-dark {
		color: #5661b3;
	}

	.sm\:text-indigo-light {
		color: #7886d7;
	}

	.sm\:text-indigo-lighter {
		color: #b2b7ff;
	}

	.sm\:text-indigo-lightest {
		color: #e6e8ff;
	}

	.sm\:text-purple-darkest {
		color: #21183c;
	}

	.sm\:text-purple-darker {
		color: #382b5f;
	}

	.sm\:text-purple-dark {
		color: #794acf;
	}

	.sm\:text-purple-light {
		color: #a779e9;
	}

	.sm\:text-purple-lighter {
		color: #d6bbfc;
	}

	.sm\:text-purple-lightest {
		color: #f3ebff;
	}

	.sm\:text-pink-darkest {
		color: #451225;
	}

	.sm\:text-pink-darker {
		color: #6f213f;
	}

	.sm\:text-pink-dark {
		color: #eb5286;
	}

	.sm\:text-pink-light {
		color: #fa7ea8;
	}

	.sm\:text-pink-lighter {
		color: #ffbbca;
	}

	.sm\:text-pink-lightest {
		color: #ffebef;
	}

	.sm\:hover\:text-transparent:hover {
		color: transparent;
	}

	.sm\:hover\:text-black:hover {
		color: #22292f;
	}

	.sm\:hover\:text-white:hover {
		color: #ffffff;
	}

	.sm\:hover\:text-gray-100:hover {
		color: #f7fafc;
	}

	.sm\:hover\:text-gray-200:hover {
		color: #edf2f7;
	}

	.sm\:hover\:text-gray-300:hover {
		color: #e2e8f0;
	}

	.sm\:hover\:text-gray-400:hover {
		color: #cbd5e0;
	}

	.sm\:hover\:text-gray-500:hover {
		color: #a0aec0;
	}

	.sm\:hover\:text-gray-600:hover {
		color: #718096;
	}

	.sm\:hover\:text-gray-700:hover {
		color: #4a5568;
	}

	.sm\:hover\:text-gray-800:hover {
		color: #2d3748;
	}

	.sm\:hover\:text-gray-900:hover {
		color: #1a202c;
	}

	.sm\:hover\:text-red:hover {
		color: #e3342f;
	}

	.sm\:hover\:text-orange:hover {
		color: #f6993f;
	}

	.sm\:hover\:text-yellow:hover {
		color: #ffed4a;
	}

	.sm\:hover\:text-green:hover {
		color: #38c172;
	}

	.sm\:hover\:text-teal:hover {
		color: #4dc0b5;
	}

	.sm\:hover\:text-blue:hover {
		color: #3490dc;
	}

	.sm\:hover\:text-indigo:hover {
		color: #6574cd;
	}

	.sm\:hover\:text-purple:hover {
		color: #9561e2;
	}

	.sm\:hover\:text-pink:hover {
		color: #f66d9b;
	}

	.sm\:hover\:text-grey-darkest:hover {
		color: #3d4852;
	}

	.sm\:hover\:text-grey-darker:hover {
		color: #606f7b;
	}

	.sm\:hover\:text-grey-dark:hover {
		color: #8795a1;
	}

	.sm\:hover\:text-grey:hover {
		color: #b8c2cc;
	}

	.sm\:hover\:text-grey-light:hover {
		color: #dae1e7;
	}

	.sm\:hover\:text-grey-lighter:hover {
		color: #f1f5f8;
	}

	.sm\:hover\:text-grey-lightest:hover {
		color: #f8fafc;
	}

	.sm\:hover\:text-red-darkest:hover {
		color: #3b0d0c;
	}

	.sm\:hover\:text-red-darker:hover {
		color: #621b18;
	}

	.sm\:hover\:text-red-dark:hover {
		color: #cc1f1a;
	}

	.sm\:hover\:text-red-light:hover {
		color: #ef5753;
	}

	.sm\:hover\:text-red-lighter:hover {
		color: #f9acaa;
	}

	.sm\:hover\:text-red-lightest:hover {
		color: #fcebea;
	}

	.sm\:hover\:text-orange-darkest:hover {
		color: #462a16;
	}

	.sm\:hover\:text-orange-darker:hover {
		color: #613b1f;
	}

	.sm\:hover\:text-orange-dark:hover {
		color: #de751f;
	}

	.sm\:hover\:text-orange-light:hover {
		color: #faad63;
	}

	.sm\:hover\:text-orange-lighter:hover {
		color: #fcd9b6;
	}

	.sm\:hover\:text-orange-lightest:hover {
		color: #fff5eb;
	}

	.sm\:hover\:text-yellow-darkest:hover {
		color: #453411;
	}

	.sm\:hover\:text-yellow-darker:hover {
		color: #684f1d;
	}

	.sm\:hover\:text-yellow-dark:hover {
		color: #f2d024;
	}

	.sm\:hover\:text-yellow-light:hover {
		color: #fff382;
	}

	.sm\:hover\:text-yellow-lighter:hover {
		color: #fff9c2;
	}

	.sm\:hover\:text-yellow-lightest:hover {
		color: #fcfbeb;
	}

	.sm\:hover\:text-green-darkest:hover {
		color: #0f2f21;
	}

	.sm\:hover\:text-green-darker:hover {
		color: #1a4731;
	}

	.sm\:hover\:text-green-dark:hover {
		color: #1f9d55;
	}

	.sm\:hover\:text-green-light:hover {
		color: #51d88a;
	}

	.sm\:hover\:text-green-lighter:hover {
		color: #a2f5bf;
	}

	.sm\:hover\:text-green-lightest:hover {
		color: #e3fcec;
	}

	.sm\:hover\:text-teal-darkest:hover {
		color: #0d3331;
	}

	.sm\:hover\:text-teal-darker:hover {
		color: #20504f;
	}

	.sm\:hover\:text-teal-dark:hover {
		color: #38a89d;
	}

	.sm\:hover\:text-teal-light:hover {
		color: #64d5ca;
	}

	.sm\:hover\:text-teal-lighter:hover {
		color: #a0f0ed;
	}

	.sm\:hover\:text-teal-lightest:hover {
		color: #e8fffe;
	}

	.sm\:hover\:text-blue-darkest:hover {
		color: #12283a;
	}

	.sm\:hover\:text-blue-darker:hover {
		color: #1c3d5a;
	}

	.sm\:hover\:text-blue-dark:hover {
		color: #2779bd;
	}

	.sm\:hover\:text-blue-light:hover {
		color: #6cb2eb;
	}

	.sm\:hover\:text-blue-lighter:hover {
		color: #bcdefa;
	}

	.sm\:hover\:text-blue-lightest:hover {
		color: #eff8ff;
	}

	.sm\:hover\:text-indigo-darkest:hover {
		color: #191e38;
	}

	.sm\:hover\:text-indigo-darker:hover {
		color: #2f365f;
	}

	.sm\:hover\:text-indigo-dark:hover {
		color: #5661b3;
	}

	.sm\:hover\:text-indigo-light:hover {
		color: #7886d7;
	}

	.sm\:hover\:text-indigo-lighter:hover {
		color: #b2b7ff;
	}

	.sm\:hover\:text-indigo-lightest:hover {
		color: #e6e8ff;
	}

	.sm\:hover\:text-purple-darkest:hover {
		color: #21183c;
	}

	.sm\:hover\:text-purple-darker:hover {
		color: #382b5f;
	}

	.sm\:hover\:text-purple-dark:hover {
		color: #794acf;
	}

	.sm\:hover\:text-purple-light:hover {
		color: #a779e9;
	}

	.sm\:hover\:text-purple-lighter:hover {
		color: #d6bbfc;
	}

	.sm\:hover\:text-purple-lightest:hover {
		color: #f3ebff;
	}

	.sm\:hover\:text-pink-darkest:hover {
		color: #451225;
	}

	.sm\:hover\:text-pink-darker:hover {
		color: #6f213f;
	}

	.sm\:hover\:text-pink-dark:hover {
		color: #eb5286;
	}

	.sm\:hover\:text-pink-light:hover {
		color: #fa7ea8;
	}

	.sm\:hover\:text-pink-lighter:hover {
		color: #ffbbca;
	}

	.sm\:hover\:text-pink-lightest:hover {
		color: #ffebef;
	}

	.sm\:focus\:text-transparent:focus {
		color: transparent;
	}

	.sm\:focus\:text-black:focus {
		color: #22292f;
	}

	.sm\:focus\:text-white:focus {
		color: #ffffff;
	}

	.sm\:focus\:text-gray-100:focus {
		color: #f7fafc;
	}

	.sm\:focus\:text-gray-200:focus {
		color: #edf2f7;
	}

	.sm\:focus\:text-gray-300:focus {
		color: #e2e8f0;
	}

	.sm\:focus\:text-gray-400:focus {
		color: #cbd5e0;
	}

	.sm\:focus\:text-gray-500:focus {
		color: #a0aec0;
	}

	.sm\:focus\:text-gray-600:focus {
		color: #718096;
	}

	.sm\:focus\:text-gray-700:focus {
		color: #4a5568;
	}

	.sm\:focus\:text-gray-800:focus {
		color: #2d3748;
	}

	.sm\:focus\:text-gray-900:focus {
		color: #1a202c;
	}

	.sm\:focus\:text-red:focus {
		color: #e3342f;
	}

	.sm\:focus\:text-orange:focus {
		color: #f6993f;
	}

	.sm\:focus\:text-yellow:focus {
		color: #ffed4a;
	}

	.sm\:focus\:text-green:focus {
		color: #38c172;
	}

	.sm\:focus\:text-teal:focus {
		color: #4dc0b5;
	}

	.sm\:focus\:text-blue:focus {
		color: #3490dc;
	}

	.sm\:focus\:text-indigo:focus {
		color: #6574cd;
	}

	.sm\:focus\:text-purple:focus {
		color: #9561e2;
	}

	.sm\:focus\:text-pink:focus {
		color: #f66d9b;
	}

	.sm\:focus\:text-grey-darkest:focus {
		color: #3d4852;
	}

	.sm\:focus\:text-grey-darker:focus {
		color: #606f7b;
	}

	.sm\:focus\:text-grey-dark:focus {
		color: #8795a1;
	}

	.sm\:focus\:text-grey:focus {
		color: #b8c2cc;
	}

	.sm\:focus\:text-grey-light:focus {
		color: #dae1e7;
	}

	.sm\:focus\:text-grey-lighter:focus {
		color: #f1f5f8;
	}

	.sm\:focus\:text-grey-lightest:focus {
		color: #f8fafc;
	}

	.sm\:focus\:text-red-darkest:focus {
		color: #3b0d0c;
	}

	.sm\:focus\:text-red-darker:focus {
		color: #621b18;
	}

	.sm\:focus\:text-red-dark:focus {
		color: #cc1f1a;
	}

	.sm\:focus\:text-red-light:focus {
		color: #ef5753;
	}

	.sm\:focus\:text-red-lighter:focus {
		color: #f9acaa;
	}

	.sm\:focus\:text-red-lightest:focus {
		color: #fcebea;
	}

	.sm\:focus\:text-orange-darkest:focus {
		color: #462a16;
	}

	.sm\:focus\:text-orange-darker:focus {
		color: #613b1f;
	}

	.sm\:focus\:text-orange-dark:focus {
		color: #de751f;
	}

	.sm\:focus\:text-orange-light:focus {
		color: #faad63;
	}

	.sm\:focus\:text-orange-lighter:focus {
		color: #fcd9b6;
	}

	.sm\:focus\:text-orange-lightest:focus {
		color: #fff5eb;
	}

	.sm\:focus\:text-yellow-darkest:focus {
		color: #453411;
	}

	.sm\:focus\:text-yellow-darker:focus {
		color: #684f1d;
	}

	.sm\:focus\:text-yellow-dark:focus {
		color: #f2d024;
	}

	.sm\:focus\:text-yellow-light:focus {
		color: #fff382;
	}

	.sm\:focus\:text-yellow-lighter:focus {
		color: #fff9c2;
	}

	.sm\:focus\:text-yellow-lightest:focus {
		color: #fcfbeb;
	}

	.sm\:focus\:text-green-darkest:focus {
		color: #0f2f21;
	}

	.sm\:focus\:text-green-darker:focus {
		color: #1a4731;
	}

	.sm\:focus\:text-green-dark:focus {
		color: #1f9d55;
	}

	.sm\:focus\:text-green-light:focus {
		color: #51d88a;
	}

	.sm\:focus\:text-green-lighter:focus {
		color: #a2f5bf;
	}

	.sm\:focus\:text-green-lightest:focus {
		color: #e3fcec;
	}

	.sm\:focus\:text-teal-darkest:focus {
		color: #0d3331;
	}

	.sm\:focus\:text-teal-darker:focus {
		color: #20504f;
	}

	.sm\:focus\:text-teal-dark:focus {
		color: #38a89d;
	}

	.sm\:focus\:text-teal-light:focus {
		color: #64d5ca;
	}

	.sm\:focus\:text-teal-lighter:focus {
		color: #a0f0ed;
	}

	.sm\:focus\:text-teal-lightest:focus {
		color: #e8fffe;
	}

	.sm\:focus\:text-blue-darkest:focus {
		color: #12283a;
	}

	.sm\:focus\:text-blue-darker:focus {
		color: #1c3d5a;
	}

	.sm\:focus\:text-blue-dark:focus {
		color: #2779bd;
	}

	.sm\:focus\:text-blue-light:focus {
		color: #6cb2eb;
	}

	.sm\:focus\:text-blue-lighter:focus {
		color: #bcdefa;
	}

	.sm\:focus\:text-blue-lightest:focus {
		color: #eff8ff;
	}

	.sm\:focus\:text-indigo-darkest:focus {
		color: #191e38;
	}

	.sm\:focus\:text-indigo-darker:focus {
		color: #2f365f;
	}

	.sm\:focus\:text-indigo-dark:focus {
		color: #5661b3;
	}

	.sm\:focus\:text-indigo-light:focus {
		color: #7886d7;
	}

	.sm\:focus\:text-indigo-lighter:focus {
		color: #b2b7ff;
	}

	.sm\:focus\:text-indigo-lightest:focus {
		color: #e6e8ff;
	}

	.sm\:focus\:text-purple-darkest:focus {
		color: #21183c;
	}

	.sm\:focus\:text-purple-darker:focus {
		color: #382b5f;
	}

	.sm\:focus\:text-purple-dark:focus {
		color: #794acf;
	}

	.sm\:focus\:text-purple-light:focus {
		color: #a779e9;
	}

	.sm\:focus\:text-purple-lighter:focus {
		color: #d6bbfc;
	}

	.sm\:focus\:text-purple-lightest:focus {
		color: #f3ebff;
	}

	.sm\:focus\:text-pink-darkest:focus {
		color: #451225;
	}

	.sm\:focus\:text-pink-darker:focus {
		color: #6f213f;
	}

	.sm\:focus\:text-pink-dark:focus {
		color: #eb5286;
	}

	.sm\:focus\:text-pink-light:focus {
		color: #fa7ea8;
	}

	.sm\:focus\:text-pink-lighter:focus {
		color: #ffbbca;
	}

	.sm\:focus\:text-pink-lightest:focus {
		color: #ffebef;
	}

	.sm\:text-10 {
		font-size: 1rem;
	}

	.sm\:text-11 {
		font-size: 1.1rem;
	}

	.sm\:text-12 {
		font-size: 1.2rem;
	}

	.sm\:text-13 {
		font-size: 1.3rem;
	}

	.sm\:text-14 {
		font-size: 1.4rem;
	}

	.sm\:text-15 {
		font-size: 1.5rem;
	}

	.sm\:text-16 {
		font-size: 1.6rem;
	}

	.sm\:text-17 {
		font-size: 1.7rem;
	}

	.sm\:text-18 {
		font-size: 1.8rem;
	}

	.sm\:text-19 {
		font-size: 1.9rem;
	}

	.sm\:text-20 {
		font-size: 2rem;
	}

	.sm\:text-24 {
		font-size: 2.4rem;
	}

	.sm\:text-28 {
		font-size: 2.8rem;
	}

	.sm\:text-32 {
		font-size: 3.2rem;
	}

	.sm\:text-36 {
		font-size: 3.6rem;
	}

	.sm\:text-40 {
		font-size: 4rem;
	}

	.sm\:text-44 {
		font-size: 4.4rem;
	}

	.sm\:text-48 {
		font-size: 4.8rem;
	}

	.sm\:text-52 {
		font-size: 5.2rem;
	}

	.sm\:text-56 {
		font-size: 5.6rem;
	}

	.sm\:text-60 {
		font-size: 6rem;
	}

	.sm\:text-64 {
		font-size: 6.4rem;
	}

	.sm\:text-68 {
		font-size: 6.8rem;
	}

	.sm\:text-72 {
		font-size: 7.2rem;
	}

	.sm\:text-96 {
		font-size: 9.6rem;
	}

	.sm\:text-128 {
		font-size: 12.8rem;
	}

	.sm\:text-xs {
		font-size: 1.2rem;
	}

	.sm\:text-sm {
		font-size: 2.4rem;
	}

	.sm\:text-base {
		font-size: 1.6rem;
	}

	.sm\:text-lg {
		font-size: 1.8rem;
	}

	.sm\:text-xl {
		font-size: 2rem;
	}

	.sm\:text-2xl {
		font-size: 2.4rem;
	}

	.sm\:text-3xl {
		font-size: 3rem;
	}

	.sm\:text-4xl {
		font-size: 3.6rem;
	}

	.sm\:text-5xl {
		font-size: 4.8rem;
	}

	.sm\:text-6xl {
		font-size: 4rem;
	}

	.sm\:italic {
		font-style: italic;
	}

	.sm\:not-italic {
		font-style: normal;
	}

	.sm\:hover\:italic:hover {
		font-style: italic;
	}

	.sm\:hover\:not-italic:hover {
		font-style: normal;
	}

	.sm\:focus\:italic:focus {
		font-style: italic;
	}

	.sm\:focus\:not-italic:focus {
		font-style: normal;
	}

	.sm\:uppercase {
		text-transform: uppercase;
	}

	.sm\:lowercase {
		text-transform: lowercase;
	}

	.sm\:capitalize {
		text-transform: capitalize;
	}

	.sm\:normal-case {
		text-transform: none;
	}

	.sm\:underline {
		text-decoration: underline;
	}

	.sm\:line-through {
		text-decoration: line-through;
	}

	.sm\:no-underline {
		text-decoration: none;
	}

	.sm\:hover\:underline:hover {
		text-decoration: underline;
	}

	.sm\:hover\:line-through:hover {
		text-decoration: line-through;
	}

	.sm\:hover\:no-underline:hover {
		text-decoration: none;
	}

	.sm\:focus\:underline:focus {
		text-decoration: underline;
	}

	.sm\:focus\:line-through:focus {
		text-decoration: line-through;
	}

	.sm\:focus\:no-underline:focus {
		text-decoration: none;
	}

	.sm\:antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.sm\:subpixel-antialiased {
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;
	}

	.sm\:tracking-tighter {
		letter-spacing: -0.05em;
	}

	.sm\:tracking-tight {
		letter-spacing: -0.025em;
	}

	.sm\:tracking-normal {
		letter-spacing: 0;
	}

	.sm\:tracking-wide {
		letter-spacing: 0.025em;
	}

	.sm\:tracking-wider {
		letter-spacing: 0.05em;
	}

	.sm\:tracking-widest {
		letter-spacing: 0.1em;
	}

	.sm\:select-none {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.sm\:select-text {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.sm\:select-all {
		-webkit-user-select: all;
		-moz-user-select: all;
		-ms-user-select: all;
		user-select: all;
	}

	.sm\:select-auto {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.sm\:align-baseline {
		vertical-align: baseline;
	}

	.sm\:align-top {
		vertical-align: top;
	}

	.sm\:align-middle {
		vertical-align: middle;
	}

	.sm\:align-bottom {
		vertical-align: bottom;
	}

	.sm\:align-text-top {
		vertical-align: text-top;
	}

	.sm\:align-text-bottom {
		vertical-align: text-bottom;
	}

	.sm\:visible {
		visibility: visible;
	}

	.sm\:invisible {
		visibility: hidden;
	}

	.sm\:whitespace-normal {
		white-space: normal;
	}

	.sm\:whitespace-no-wrap {
		white-space: nowrap;
	}

	.sm\:whitespace-pre {
		white-space: pre;
	}

	.sm\:whitespace-pre-line {
		white-space: pre-line;
	}

	.sm\:whitespace-pre-wrap {
		white-space: pre-wrap;
	}

	.sm\:break-normal {
		overflow-wrap: normal;
		word-break: normal;
	}

	.sm\:break-words {
		overflow-wrap: break-word;
	}

	.sm\:break-all {
		word-break: break-all;
	}

	.sm\:truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.sm\:w-0 {
		width: 0;
	}

	.sm\:w-1 {
		width: 0.25rem;
	}

	.sm\:w-2 {
		width: 0.5rem;
	}

	.sm\:w-3 {
		width: 0.75rem;
	}

	.sm\:w-4 {
		width: 0.4rem;
	}

	.sm\:w-5 {
		width: 1.25rem;
	}

	.sm\:w-6 {
		width: 0.6rem;
	}

	.sm\:w-8 {
		width: 0.8rem;
	}

	.sm\:w-10 {
		width: 2.5rem;
	}

	.sm\:w-12 {
		width: 1.2rem;
	}

	.sm\:w-16 {
		width: 1.6rem;
	}

	.sm\:w-20 {
		width: 2rem;
	}

	.sm\:w-24 {
		width: 2.4rem;
	}

	.sm\:w-28 {
		width: 2.8rem;
	}

	.sm\:w-32 {
		width: 3.2rem;
	}

	.sm\:w-36 {
		width: 3.6rem;
	}

	.sm\:w-40 {
		width: 4rem;
	}

	.sm\:w-44 {
		width: 4.4rem;
	}

	.sm\:w-48 {
		width: 4.8rem;
	}

	.sm\:w-52 {
		width: 5.2rem;
	}

	.sm\:w-56 {
		width: 5.6rem;
	}

	.sm\:w-60 {
		width: 6rem;
	}

	.sm\:w-64 {
		width: 6.4rem;
	}

	.sm\:w-68 {
		width: 6.8rem;
	}

	.sm\:w-72 {
		width: 7.2rem;
	}

	.sm\:w-76 {
		width: 7.6rem;
	}

	.sm\:w-80 {
		width: 8rem;
	}

	.sm\:w-84 {
		width: 8.4rem;
	}

	.sm\:w-88 {
		width: 8.8rem;
	}

	.sm\:w-92 {
		width: 9.2rem;
	}

	.sm\:w-96 {
		width: 9.6rem;
	}

	.sm\:w-128 {
		width: 12.8rem;
	}

	.sm\:w-136 {
		width: 13.6rem;
	}

	.sm\:w-160 {
		width: 16rem;
	}

	.sm\:w-192 {
		width: 19.2rem;
	}

	.sm\:w-200 {
		width: 20rem;
	}

	.sm\:w-208 {
		width: 20.8rem;
	}

	.sm\:w-216 {
		width: 21.6rem;
	}

	.sm\:w-224 {
		width: 22.4rem;
	}

	.sm\:w-256 {
		width: 25.6rem;
	}

	.sm\:w-288 {
		width: 28.8rem;
	}

	.sm\:w-320 {
		width: 32rem;
	}

	.sm\:w-360 {
		width: 36rem;
	}

	.sm\:w-384 {
		width: 38.4rem;
	}

	.sm\:w-400 {
		width: 40rem;
	}

	.sm\:w-512 {
		width: 51.2rem;
	}

	.sm\:w-640 {
		width: 64rem;
	}

	.sm\:w-auto {
		width: auto;
	}

	.sm\:w-px {
		width: 1px;
	}

	.sm\:w-xs {
		width: 32rem;
	}

	.sm\:w-sm {
		width: 48rem;
	}

	.sm\:w-md {
		width: 64rem;
	}

	.sm\:w-lg {
		width: 80rem;
	}

	.sm\:w-xl {
		width: 96rem;
	}

	.sm\:w-2xl {
		width: 112rem;
	}

	.sm\:w-3xl {
		width: 128rem;
	}

	.sm\:w-4xl {
		width: 144rem;
	}

	.sm\:w-5xl {
		width: 160rem;
	}

	.sm\:w-1\/2 {
		width: 50%;
	}

	.sm\:w-1\/3 {
		width: 33.33333%;
	}

	.sm\:w-2\/3 {
		width: 66.66667%;
	}

	.sm\:w-1\/4 {
		width: 25%;
	}

	.sm\:w-2\/4 {
		width: 50%;
	}

	.sm\:w-3\/4 {
		width: 75%;
	}

	.sm\:w-1\/5 {
		width: 20%;
	}

	.sm\:w-2\/5 {
		width: 40%;
	}

	.sm\:w-3\/5 {
		width: 60%;
	}

	.sm\:w-4\/5 {
		width: 80%;
	}

	.sm\:w-1\/6 {
		width: 16.66667%;
	}

	.sm\:w-2\/6 {
		width: 33.33333%;
	}

	.sm\:w-3\/6 {
		width: 50%;
	}

	.sm\:w-4\/6 {
		width: 66.66667%;
	}

	.sm\:w-5\/6 {
		width: 83.33333%;
	}

	.sm\:w-1\/12 {
		width: 8.33333%;
	}

	.sm\:w-2\/12 {
		width: 16.66667%;
	}

	.sm\:w-3\/12 {
		width: 25%;
	}

	.sm\:w-4\/12 {
		width: 33.33333%;
	}

	.sm\:w-5\/12 {
		width: 41.66667%;
	}

	.sm\:w-6\/12 {
		width: 50%;
	}

	.sm\:w-7\/12 {
		width: 58.33333%;
	}

	.sm\:w-8\/12 {
		width: 66.66667%;
	}

	.sm\:w-9\/12 {
		width: 75%;
	}

	.sm\:w-10\/12 {
		width: 83.33333%;
	}

	.sm\:w-11\/12 {
		width: 91.66667%;
	}

	.sm\:w-full {
		width: 100%;
	}

	.sm\:w-screen {
		width: 100vw;
	}

	.sm\:z-0 {
		z-index: 0;
	}

	.sm\:z-10 {
		z-index: 10;
	}

	.sm\:z-20 {
		z-index: 20;
	}

	.sm\:z-30 {
		z-index: 30;
	}

	.sm\:z-40 {
		z-index: 40;
	}

	.sm\:z-50 {
		z-index: 50;
	}

	.sm\:z-99 {
		z-index: 99;
	}

	.sm\:z-999 {
		z-index: 999;
	}

	.sm\:z-9999 {
		z-index: 9999;
	}

	.sm\:z-auto {
		z-index: auto;
	}
}

@media (min-width: 960px) {
	.md\:appearance-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.md\:bg-fixed {
		background-attachment: fixed;
	}

	.md\:bg-local {
		background-attachment: local;
	}

	.md\:bg-scroll {
		background-attachment: scroll;
	}

	.md\:bg-transparent {
		background-color: transparent;
	}

	.md\:bg-black {
		background-color: #22292f;
	}

	.md\:bg-white {
		background-color: #ffffff;
	}

	.md\:bg-gray-100 {
		background-color: #f7fafc;
	}

	.md\:bg-gray-200 {
		background-color: #edf2f7;
	}

	.md\:bg-gray-300 {
		background-color: #e2e8f0;
	}

	.md\:bg-gray-400 {
		background-color: #cbd5e0;
	}

	.md\:bg-gray-500 {
		background-color: #a0aec0;
	}

	.md\:bg-gray-600 {
		background-color: #718096;
	}

	.md\:bg-gray-700 {
		background-color: #4a5568;
	}

	.md\:bg-gray-800 {
		background-color: #2d3748;
	}

	.md\:bg-gray-900 {
		background-color: #1a202c;
	}

	.md\:bg-red {
		background-color: #e3342f;
	}

	.md\:bg-orange {
		background-color: #f6993f;
	}

	.md\:bg-yellow {
		background-color: #ffed4a;
	}

	.md\:bg-green {
		background-color: #38c172;
	}

	.md\:bg-teal {
		background-color: #4dc0b5;
	}

	.md\:bg-blue {
		background-color: #3490dc;
	}

	.md\:bg-indigo {
		background-color: #6574cd;
	}

	.md\:bg-purple {
		background-color: #9561e2;
	}

	.md\:bg-pink {
		background-color: #f66d9b;
	}

	.md\:bg-grey-darkest {
		background-color: #3d4852;
	}

	.md\:bg-grey-darker {
		background-color: #606f7b;
	}

	.md\:bg-grey-dark {
		background-color: #8795a1;
	}

	.md\:bg-grey {
		background-color: #b8c2cc;
	}

	.md\:bg-grey-light {
		background-color: #dae1e7;
	}

	.md\:bg-grey-lighter {
		background-color: #f1f5f8;
	}

	.md\:bg-grey-lightest {
		background-color: #f8fafc;
	}

	.md\:bg-red-darkest {
		background-color: #3b0d0c;
	}

	.md\:bg-red-darker {
		background-color: #621b18;
	}

	.md\:bg-red-dark {
		background-color: #cc1f1a;
	}

	.md\:bg-red-light {
		background-color: #ef5753;
	}

	.md\:bg-red-lighter {
		background-color: #f9acaa;
	}

	.md\:bg-red-lightest {
		background-color: #fcebea;
	}

	.md\:bg-orange-darkest {
		background-color: #462a16;
	}

	.md\:bg-orange-darker {
		background-color: #613b1f;
	}

	.md\:bg-orange-dark {
		background-color: #de751f;
	}

	.md\:bg-orange-light {
		background-color: #faad63;
	}

	.md\:bg-orange-lighter {
		background-color: #fcd9b6;
	}

	.md\:bg-orange-lightest {
		background-color: #fff5eb;
	}

	.md\:bg-yellow-darkest {
		background-color: #453411;
	}

	.md\:bg-yellow-darker {
		background-color: #684f1d;
	}

	.md\:bg-yellow-dark {
		background-color: #f2d024;
	}

	.md\:bg-yellow-light {
		background-color: #fff382;
	}

	.md\:bg-yellow-lighter {
		background-color: #fff9c2;
	}

	.md\:bg-yellow-lightest {
		background-color: #fcfbeb;
	}

	.md\:bg-green-darkest {
		background-color: #0f2f21;
	}

	.md\:bg-green-darker {
		background-color: #1a4731;
	}

	.md\:bg-green-dark {
		background-color: #1f9d55;
	}

	.md\:bg-green-light {
		background-color: #51d88a;
	}

	.md\:bg-green-lighter {
		background-color: #a2f5bf;
	}

	.md\:bg-green-lightest {
		background-color: #e3fcec;
	}

	.md\:bg-teal-darkest {
		background-color: #0d3331;
	}

	.md\:bg-teal-darker {
		background-color: #20504f;
	}

	.md\:bg-teal-dark {
		background-color: #38a89d;
	}

	.md\:bg-teal-light {
		background-color: #64d5ca;
	}

	.md\:bg-teal-lighter {
		background-color: #a0f0ed;
	}

	.md\:bg-teal-lightest {
		background-color: #e8fffe;
	}

	.md\:bg-blue-darkest {
		background-color: #12283a;
	}

	.md\:bg-blue-darker {
		background-color: #1c3d5a;
	}

	.md\:bg-blue-dark {
		background-color: #2779bd;
	}

	.md\:bg-blue-light {
		background-color: #6cb2eb;
	}

	.md\:bg-blue-lighter {
		background-color: #bcdefa;
	}

	.md\:bg-blue-lightest {
		background-color: #eff8ff;
	}

	.md\:bg-indigo-darkest {
		background-color: #191e38;
	}

	.md\:bg-indigo-darker {
		background-color: #2f365f;
	}

	.md\:bg-indigo-dark {
		background-color: #5661b3;
	}

	.md\:bg-indigo-light {
		background-color: #7886d7;
	}

	.md\:bg-indigo-lighter {
		background-color: #b2b7ff;
	}

	.md\:bg-indigo-lightest {
		background-color: #e6e8ff;
	}

	.md\:bg-purple-darkest {
		background-color: #21183c;
	}

	.md\:bg-purple-darker {
		background-color: #382b5f;
	}

	.md\:bg-purple-dark {
		background-color: #794acf;
	}

	.md\:bg-purple-light {
		background-color: #a779e9;
	}

	.md\:bg-purple-lighter {
		background-color: #d6bbfc;
	}

	.md\:bg-purple-lightest {
		background-color: #f3ebff;
	}

	.md\:bg-pink-darkest {
		background-color: #451225;
	}

	.md\:bg-pink-darker {
		background-color: #6f213f;
	}

	.md\:bg-pink-dark {
		background-color: #eb5286;
	}

	.md\:bg-pink-light {
		background-color: #fa7ea8;
	}

	.md\:bg-pink-lighter {
		background-color: #ffbbca;
	}

	.md\:bg-pink-lightest {
		background-color: #ffebef;
	}

	.md\:hover\:bg-transparent:hover {
		background-color: transparent;
	}

	.md\:hover\:bg-black:hover {
		background-color: #22292f;
	}

	.md\:hover\:bg-white:hover {
		background-color: #ffffff;
	}

	.md\:hover\:bg-gray-100:hover {
		background-color: #f7fafc;
	}

	.md\:hover\:bg-gray-200:hover {
		background-color: #edf2f7;
	}

	.md\:hover\:bg-gray-300:hover {
		background-color: #e2e8f0;
	}

	.md\:hover\:bg-gray-400:hover {
		background-color: #cbd5e0;
	}

	.md\:hover\:bg-gray-500:hover {
		background-color: #a0aec0;
	}

	.md\:hover\:bg-gray-600:hover {
		background-color: #718096;
	}

	.md\:hover\:bg-gray-700:hover {
		background-color: #4a5568;
	}

	.md\:hover\:bg-gray-800:hover {
		background-color: #2d3748;
	}

	.md\:hover\:bg-gray-900:hover {
		background-color: #1a202c;
	}

	.md\:hover\:bg-red:hover {
		background-color: #e3342f;
	}

	.md\:hover\:bg-orange:hover {
		background-color: #f6993f;
	}

	.md\:hover\:bg-yellow:hover {
		background-color: #ffed4a;
	}

	.md\:hover\:bg-green:hover {
		background-color: #38c172;
	}

	.md\:hover\:bg-teal:hover {
		background-color: #4dc0b5;
	}

	.md\:hover\:bg-blue:hover {
		background-color: #3490dc;
	}

	.md\:hover\:bg-indigo:hover {
		background-color: #6574cd;
	}

	.md\:hover\:bg-purple:hover {
		background-color: #9561e2;
	}

	.md\:hover\:bg-pink:hover {
		background-color: #f66d9b;
	}

	.md\:hover\:bg-grey-darkest:hover {
		background-color: #3d4852;
	}

	.md\:hover\:bg-grey-darker:hover {
		background-color: #606f7b;
	}

	.md\:hover\:bg-grey-dark:hover {
		background-color: #8795a1;
	}

	.md\:hover\:bg-grey:hover {
		background-color: #b8c2cc;
	}

	.md\:hover\:bg-grey-light:hover {
		background-color: #dae1e7;
	}

	.md\:hover\:bg-grey-lighter:hover {
		background-color: #f1f5f8;
	}

	.md\:hover\:bg-grey-lightest:hover {
		background-color: #f8fafc;
	}

	.md\:hover\:bg-red-darkest:hover {
		background-color: #3b0d0c;
	}

	.md\:hover\:bg-red-darker:hover {
		background-color: #621b18;
	}

	.md\:hover\:bg-red-dark:hover {
		background-color: #cc1f1a;
	}

	.md\:hover\:bg-red-light:hover {
		background-color: #ef5753;
	}

	.md\:hover\:bg-red-lighter:hover {
		background-color: #f9acaa;
	}

	.md\:hover\:bg-red-lightest:hover {
		background-color: #fcebea;
	}

	.md\:hover\:bg-orange-darkest:hover {
		background-color: #462a16;
	}

	.md\:hover\:bg-orange-darker:hover {
		background-color: #613b1f;
	}

	.md\:hover\:bg-orange-dark:hover {
		background-color: #de751f;
	}

	.md\:hover\:bg-orange-light:hover {
		background-color: #faad63;
	}

	.md\:hover\:bg-orange-lighter:hover {
		background-color: #fcd9b6;
	}

	.md\:hover\:bg-orange-lightest:hover {
		background-color: #fff5eb;
	}

	.md\:hover\:bg-yellow-darkest:hover {
		background-color: #453411;
	}

	.md\:hover\:bg-yellow-darker:hover {
		background-color: #684f1d;
	}

	.md\:hover\:bg-yellow-dark:hover {
		background-color: #f2d024;
	}

	.md\:hover\:bg-yellow-light:hover {
		background-color: #fff382;
	}

	.md\:hover\:bg-yellow-lighter:hover {
		background-color: #fff9c2;
	}

	.md\:hover\:bg-yellow-lightest:hover {
		background-color: #fcfbeb;
	}

	.md\:hover\:bg-green-darkest:hover {
		background-color: #0f2f21;
	}

	.md\:hover\:bg-green-darker:hover {
		background-color: #1a4731;
	}

	.md\:hover\:bg-green-dark:hover {
		background-color: #1f9d55;
	}

	.md\:hover\:bg-green-light:hover {
		background-color: #51d88a;
	}

	.md\:hover\:bg-green-lighter:hover {
		background-color: #a2f5bf;
	}

	.md\:hover\:bg-green-lightest:hover {
		background-color: #e3fcec;
	}

	.md\:hover\:bg-teal-darkest:hover {
		background-color: #0d3331;
	}

	.md\:hover\:bg-teal-darker:hover {
		background-color: #20504f;
	}

	.md\:hover\:bg-teal-dark:hover {
		background-color: #38a89d;
	}

	.md\:hover\:bg-teal-light:hover {
		background-color: #64d5ca;
	}

	.md\:hover\:bg-teal-lighter:hover {
		background-color: #a0f0ed;
	}

	.md\:hover\:bg-teal-lightest:hover {
		background-color: #e8fffe;
	}

	.md\:hover\:bg-blue-darkest:hover {
		background-color: #12283a;
	}

	.md\:hover\:bg-blue-darker:hover {
		background-color: #1c3d5a;
	}

	.md\:hover\:bg-blue-dark:hover {
		background-color: #2779bd;
	}

	.md\:hover\:bg-blue-light:hover {
		background-color: #6cb2eb;
	}

	.md\:hover\:bg-blue-lighter:hover {
		background-color: #bcdefa;
	}

	.md\:hover\:bg-blue-lightest:hover {
		background-color: #eff8ff;
	}

	.md\:hover\:bg-indigo-darkest:hover {
		background-color: #191e38;
	}

	.md\:hover\:bg-indigo-darker:hover {
		background-color: #2f365f;
	}

	.md\:hover\:bg-indigo-dark:hover {
		background-color: #5661b3;
	}

	.md\:hover\:bg-indigo-light:hover {
		background-color: #7886d7;
	}

	.md\:hover\:bg-indigo-lighter:hover {
		background-color: #b2b7ff;
	}

	.md\:hover\:bg-indigo-lightest:hover {
		background-color: #e6e8ff;
	}

	.md\:hover\:bg-purple-darkest:hover {
		background-color: #21183c;
	}

	.md\:hover\:bg-purple-darker:hover {
		background-color: #382b5f;
	}

	.md\:hover\:bg-purple-dark:hover {
		background-color: #794acf;
	}

	.md\:hover\:bg-purple-light:hover {
		background-color: #a779e9;
	}

	.md\:hover\:bg-purple-lighter:hover {
		background-color: #d6bbfc;
	}

	.md\:hover\:bg-purple-lightest:hover {
		background-color: #f3ebff;
	}

	.md\:hover\:bg-pink-darkest:hover {
		background-color: #451225;
	}

	.md\:hover\:bg-pink-darker:hover {
		background-color: #6f213f;
	}

	.md\:hover\:bg-pink-dark:hover {
		background-color: #eb5286;
	}

	.md\:hover\:bg-pink-light:hover {
		background-color: #fa7ea8;
	}

	.md\:hover\:bg-pink-lighter:hover {
		background-color: #ffbbca;
	}

	.md\:hover\:bg-pink-lightest:hover {
		background-color: #ffebef;
	}

	.md\:focus\:bg-transparent:focus {
		background-color: transparent;
	}

	.md\:focus\:bg-black:focus {
		background-color: #22292f;
	}

	.md\:focus\:bg-white:focus {
		background-color: #ffffff;
	}

	.md\:focus\:bg-gray-100:focus {
		background-color: #f7fafc;
	}

	.md\:focus\:bg-gray-200:focus {
		background-color: #edf2f7;
	}

	.md\:focus\:bg-gray-300:focus {
		background-color: #e2e8f0;
	}

	.md\:focus\:bg-gray-400:focus {
		background-color: #cbd5e0;
	}

	.md\:focus\:bg-gray-500:focus {
		background-color: #a0aec0;
	}

	.md\:focus\:bg-gray-600:focus {
		background-color: #718096;
	}

	.md\:focus\:bg-gray-700:focus {
		background-color: #4a5568;
	}

	.md\:focus\:bg-gray-800:focus {
		background-color: #2d3748;
	}

	.md\:focus\:bg-gray-900:focus {
		background-color: #1a202c;
	}

	.md\:focus\:bg-red:focus {
		background-color: #e3342f;
	}

	.md\:focus\:bg-orange:focus {
		background-color: #f6993f;
	}

	.md\:focus\:bg-yellow:focus {
		background-color: #ffed4a;
	}

	.md\:focus\:bg-green:focus {
		background-color: #38c172;
	}

	.md\:focus\:bg-teal:focus {
		background-color: #4dc0b5;
	}

	.md\:focus\:bg-blue:focus {
		background-color: #3490dc;
	}

	.md\:focus\:bg-indigo:focus {
		background-color: #6574cd;
	}

	.md\:focus\:bg-purple:focus {
		background-color: #9561e2;
	}

	.md\:focus\:bg-pink:focus {
		background-color: #f66d9b;
	}

	.md\:focus\:bg-grey-darkest:focus {
		background-color: #3d4852;
	}

	.md\:focus\:bg-grey-darker:focus {
		background-color: #606f7b;
	}

	.md\:focus\:bg-grey-dark:focus {
		background-color: #8795a1;
	}

	.md\:focus\:bg-grey:focus {
		background-color: #b8c2cc;
	}

	.md\:focus\:bg-grey-light:focus {
		background-color: #dae1e7;
	}

	.md\:focus\:bg-grey-lighter:focus {
		background-color: #f1f5f8;
	}

	.md\:focus\:bg-grey-lightest:focus {
		background-color: #f8fafc;
	}

	.md\:focus\:bg-red-darkest:focus {
		background-color: #3b0d0c;
	}

	.md\:focus\:bg-red-darker:focus {
		background-color: #621b18;
	}

	.md\:focus\:bg-red-dark:focus {
		background-color: #cc1f1a;
	}

	.md\:focus\:bg-red-light:focus {
		background-color: #ef5753;
	}

	.md\:focus\:bg-red-lighter:focus {
		background-color: #f9acaa;
	}

	.md\:focus\:bg-red-lightest:focus {
		background-color: #fcebea;
	}

	.md\:focus\:bg-orange-darkest:focus {
		background-color: #462a16;
	}

	.md\:focus\:bg-orange-darker:focus {
		background-color: #613b1f;
	}

	.md\:focus\:bg-orange-dark:focus {
		background-color: #de751f;
	}

	.md\:focus\:bg-orange-light:focus {
		background-color: #faad63;
	}

	.md\:focus\:bg-orange-lighter:focus {
		background-color: #fcd9b6;
	}

	.md\:focus\:bg-orange-lightest:focus {
		background-color: #fff5eb;
	}

	.md\:focus\:bg-yellow-darkest:focus {
		background-color: #453411;
	}

	.md\:focus\:bg-yellow-darker:focus {
		background-color: #684f1d;
	}

	.md\:focus\:bg-yellow-dark:focus {
		background-color: #f2d024;
	}

	.md\:focus\:bg-yellow-light:focus {
		background-color: #fff382;
	}

	.md\:focus\:bg-yellow-lighter:focus {
		background-color: #fff9c2;
	}

	.md\:focus\:bg-yellow-lightest:focus {
		background-color: #fcfbeb;
	}

	.md\:focus\:bg-green-darkest:focus {
		background-color: #0f2f21;
	}

	.md\:focus\:bg-green-darker:focus {
		background-color: #1a4731;
	}

	.md\:focus\:bg-green-dark:focus {
		background-color: #1f9d55;
	}

	.md\:focus\:bg-green-light:focus {
		background-color: #51d88a;
	}

	.md\:focus\:bg-green-lighter:focus {
		background-color: #a2f5bf;
	}

	.md\:focus\:bg-green-lightest:focus {
		background-color: #e3fcec;
	}

	.md\:focus\:bg-teal-darkest:focus {
		background-color: #0d3331;
	}

	.md\:focus\:bg-teal-darker:focus {
		background-color: #20504f;
	}

	.md\:focus\:bg-teal-dark:focus {
		background-color: #38a89d;
	}

	.md\:focus\:bg-teal-light:focus {
		background-color: #64d5ca;
	}

	.md\:focus\:bg-teal-lighter:focus {
		background-color: #a0f0ed;
	}

	.md\:focus\:bg-teal-lightest:focus {
		background-color: #e8fffe;
	}

	.md\:focus\:bg-blue-darkest:focus {
		background-color: #12283a;
	}

	.md\:focus\:bg-blue-darker:focus {
		background-color: #1c3d5a;
	}

	.md\:focus\:bg-blue-dark:focus {
		background-color: #2779bd;
	}

	.md\:focus\:bg-blue-light:focus {
		background-color: #6cb2eb;
	}

	.md\:focus\:bg-blue-lighter:focus {
		background-color: #bcdefa;
	}

	.md\:focus\:bg-blue-lightest:focus {
		background-color: #eff8ff;
	}

	.md\:focus\:bg-indigo-darkest:focus {
		background-color: #191e38;
	}

	.md\:focus\:bg-indigo-darker:focus {
		background-color: #2f365f;
	}

	.md\:focus\:bg-indigo-dark:focus {
		background-color: #5661b3;
	}

	.md\:focus\:bg-indigo-light:focus {
		background-color: #7886d7;
	}

	.md\:focus\:bg-indigo-lighter:focus {
		background-color: #b2b7ff;
	}

	.md\:focus\:bg-indigo-lightest:focus {
		background-color: #e6e8ff;
	}

	.md\:focus\:bg-purple-darkest:focus {
		background-color: #21183c;
	}

	.md\:focus\:bg-purple-darker:focus {
		background-color: #382b5f;
	}

	.md\:focus\:bg-purple-dark:focus {
		background-color: #794acf;
	}

	.md\:focus\:bg-purple-light:focus {
		background-color: #a779e9;
	}

	.md\:focus\:bg-purple-lighter:focus {
		background-color: #d6bbfc;
	}

	.md\:focus\:bg-purple-lightest:focus {
		background-color: #f3ebff;
	}

	.md\:focus\:bg-pink-darkest:focus {
		background-color: #451225;
	}

	.md\:focus\:bg-pink-darker:focus {
		background-color: #6f213f;
	}

	.md\:focus\:bg-pink-dark:focus {
		background-color: #eb5286;
	}

	.md\:focus\:bg-pink-light:focus {
		background-color: #fa7ea8;
	}

	.md\:focus\:bg-pink-lighter:focus {
		background-color: #ffbbca;
	}

	.md\:focus\:bg-pink-lightest:focus {
		background-color: #ffebef;
	}

	.md\:bg-bottom {
		background-position: bottom;
	}

	.md\:bg-center {
		background-position: center;
	}

	.md\:bg-left {
		background-position: left;
	}

	.md\:bg-left-bottom {
		background-position: left bottom;
	}

	.md\:bg-left-top {
		background-position: left top;
	}

	.md\:bg-right {
		background-position: right;
	}

	.md\:bg-right-bottom {
		background-position: right bottom;
	}

	.md\:bg-right-top {
		background-position: right top;
	}

	.md\:bg-top {
		background-position: top;
	}

	.md\:bg-repeat {
		background-repeat: repeat;
	}

	.md\:bg-no-repeat {
		background-repeat: no-repeat;
	}

	.md\:bg-repeat-x {
		background-repeat: repeat-x;
	}

	.md\:bg-repeat-y {
		background-repeat: repeat-y;
	}

	.md\:bg-repeat-round {
		background-repeat: round;
	}

	.md\:bg-repeat-space {
		background-repeat: space;
	}

	.md\:bg-auto {
		background-size: auto;
	}

	.md\:bg-cover {
		background-size: cover;
	}

	.md\:bg-contain {
		background-size: contain;
	}

	.md\:border-collapse {
		border-collapse: collapse;
	}

	.md\:border-separate {
		border-collapse: separate;
	}

	.md\:border-transparent {
		border-color: transparent;
	}

	.md\:border-black {
		border-color: #22292f;
	}

	.md\:border-white {
		border-color: #ffffff;
	}

	.md\:border-gray-100 {
		border-color: #f7fafc;
	}

	.md\:border-gray-200 {
		border-color: #edf2f7;
	}

	.md\:border-gray-300 {
		border-color: #e2e8f0;
	}

	.md\:border-gray-400 {
		border-color: #cbd5e0;
	}

	.md\:border-gray-500 {
		border-color: #a0aec0;
	}

	.md\:border-gray-600 {
		border-color: #718096;
	}

	.md\:border-gray-700 {
		border-color: #4a5568;
	}

	.md\:border-gray-800 {
		border-color: #2d3748;
	}

	.md\:border-gray-900 {
		border-color: #1a202c;
	}

	.md\:border-red {
		border-color: #e3342f;
	}

	.md\:border-orange {
		border-color: #f6993f;
	}

	.md\:border-yellow {
		border-color: #ffed4a;
	}

	.md\:border-green {
		border-color: #38c172;
	}

	.md\:border-teal {
		border-color: #4dc0b5;
	}

	.md\:border-blue {
		border-color: #3490dc;
	}

	.md\:border-indigo {
		border-color: #6574cd;
	}

	.md\:border-purple {
		border-color: #9561e2;
	}

	.md\:border-pink {
		border-color: #f66d9b;
	}

	.md\:border-grey-darkest {
		border-color: #3d4852;
	}

	.md\:border-grey-darker {
		border-color: #606f7b;
	}

	.md\:border-grey-dark {
		border-color: #8795a1;
	}

	.md\:border-grey {
		border-color: #b8c2cc;
	}

	.md\:border-grey-light {
		border-color: #dae1e7;
	}

	.md\:border-grey-lighter {
		border-color: #f1f5f8;
	}

	.md\:border-grey-lightest {
		border-color: #f8fafc;
	}

	.md\:border-red-darkest {
		border-color: #3b0d0c;
	}

	.md\:border-red-darker {
		border-color: #621b18;
	}

	.md\:border-red-dark {
		border-color: #cc1f1a;
	}

	.md\:border-red-light {
		border-color: #ef5753;
	}

	.md\:border-red-lighter {
		border-color: #f9acaa;
	}

	.md\:border-red-lightest {
		border-color: #fcebea;
	}

	.md\:border-orange-darkest {
		border-color: #462a16;
	}

	.md\:border-orange-darker {
		border-color: #613b1f;
	}

	.md\:border-orange-dark {
		border-color: #de751f;
	}

	.md\:border-orange-light {
		border-color: #faad63;
	}

	.md\:border-orange-lighter {
		border-color: #fcd9b6;
	}

	.md\:border-orange-lightest {
		border-color: #fff5eb;
	}

	.md\:border-yellow-darkest {
		border-color: #453411;
	}

	.md\:border-yellow-darker {
		border-color: #684f1d;
	}

	.md\:border-yellow-dark {
		border-color: #f2d024;
	}

	.md\:border-yellow-light {
		border-color: #fff382;
	}

	.md\:border-yellow-lighter {
		border-color: #fff9c2;
	}

	.md\:border-yellow-lightest {
		border-color: #fcfbeb;
	}

	.md\:border-green-darkest {
		border-color: #0f2f21;
	}

	.md\:border-green-darker {
		border-color: #1a4731;
	}

	.md\:border-green-dark {
		border-color: #1f9d55;
	}

	.md\:border-green-light {
		border-color: #51d88a;
	}

	.md\:border-green-lighter {
		border-color: #a2f5bf;
	}

	.md\:border-green-lightest {
		border-color: #e3fcec;
	}

	.md\:border-teal-darkest {
		border-color: #0d3331;
	}

	.md\:border-teal-darker {
		border-color: #20504f;
	}

	.md\:border-teal-dark {
		border-color: #38a89d;
	}

	.md\:border-teal-light {
		border-color: #64d5ca;
	}

	.md\:border-teal-lighter {
		border-color: #a0f0ed;
	}

	.md\:border-teal-lightest {
		border-color: #e8fffe;
	}

	.md\:border-blue-darkest {
		border-color: #12283a;
	}

	.md\:border-blue-darker {
		border-color: #1c3d5a;
	}

	.md\:border-blue-dark {
		border-color: #2779bd;
	}

	.md\:border-blue-light {
		border-color: #6cb2eb;
	}

	.md\:border-blue-lighter {
		border-color: #bcdefa;
	}

	.md\:border-blue-lightest {
		border-color: #eff8ff;
	}

	.md\:border-indigo-darkest {
		border-color: #191e38;
	}

	.md\:border-indigo-darker {
		border-color: #2f365f;
	}

	.md\:border-indigo-dark {
		border-color: #5661b3;
	}

	.md\:border-indigo-light {
		border-color: #7886d7;
	}

	.md\:border-indigo-lighter {
		border-color: #b2b7ff;
	}

	.md\:border-indigo-lightest {
		border-color: #e6e8ff;
	}

	.md\:border-purple-darkest {
		border-color: #21183c;
	}

	.md\:border-purple-darker {
		border-color: #382b5f;
	}

	.md\:border-purple-dark {
		border-color: #794acf;
	}

	.md\:border-purple-light {
		border-color: #a779e9;
	}

	.md\:border-purple-lighter {
		border-color: #d6bbfc;
	}

	.md\:border-purple-lightest {
		border-color: #f3ebff;
	}

	.md\:border-pink-darkest {
		border-color: #451225;
	}

	.md\:border-pink-darker {
		border-color: #6f213f;
	}

	.md\:border-pink-dark {
		border-color: #eb5286;
	}

	.md\:border-pink-light {
		border-color: #fa7ea8;
	}

	.md\:border-pink-lighter {
		border-color: #ffbbca;
	}

	.md\:border-pink-lightest {
		border-color: #ffebef;
	}

	.md\:hover\:border-transparent:hover {
		border-color: transparent;
	}

	.md\:hover\:border-black:hover {
		border-color: #22292f;
	}

	.md\:hover\:border-white:hover {
		border-color: #ffffff;
	}

	.md\:hover\:border-gray-100:hover {
		border-color: #f7fafc;
	}

	.md\:hover\:border-gray-200:hover {
		border-color: #edf2f7;
	}

	.md\:hover\:border-gray-300:hover {
		border-color: #e2e8f0;
	}

	.md\:hover\:border-gray-400:hover {
		border-color: #cbd5e0;
	}

	.md\:hover\:border-gray-500:hover {
		border-color: #a0aec0;
	}

	.md\:hover\:border-gray-600:hover {
		border-color: #718096;
	}

	.md\:hover\:border-gray-700:hover {
		border-color: #4a5568;
	}

	.md\:hover\:border-gray-800:hover {
		border-color: #2d3748;
	}

	.md\:hover\:border-gray-900:hover {
		border-color: #1a202c;
	}

	.md\:hover\:border-red:hover {
		border-color: #e3342f;
	}

	.md\:hover\:border-orange:hover {
		border-color: #f6993f;
	}

	.md\:hover\:border-yellow:hover {
		border-color: #ffed4a;
	}

	.md\:hover\:border-green:hover {
		border-color: #38c172;
	}

	.md\:hover\:border-teal:hover {
		border-color: #4dc0b5;
	}

	.md\:hover\:border-blue:hover {
		border-color: #3490dc;
	}

	.md\:hover\:border-indigo:hover {
		border-color: #6574cd;
	}

	.md\:hover\:border-purple:hover {
		border-color: #9561e2;
	}

	.md\:hover\:border-pink:hover {
		border-color: #f66d9b;
	}

	.md\:hover\:border-grey-darkest:hover {
		border-color: #3d4852;
	}

	.md\:hover\:border-grey-darker:hover {
		border-color: #606f7b;
	}

	.md\:hover\:border-grey-dark:hover {
		border-color: #8795a1;
	}

	.md\:hover\:border-grey:hover {
		border-color: #b8c2cc;
	}

	.md\:hover\:border-grey-light:hover {
		border-color: #dae1e7;
	}

	.md\:hover\:border-grey-lighter:hover {
		border-color: #f1f5f8;
	}

	.md\:hover\:border-grey-lightest:hover {
		border-color: #f8fafc;
	}

	.md\:hover\:border-red-darkest:hover {
		border-color: #3b0d0c;
	}

	.md\:hover\:border-red-darker:hover {
		border-color: #621b18;
	}

	.md\:hover\:border-red-dark:hover {
		border-color: #cc1f1a;
	}

	.md\:hover\:border-red-light:hover {
		border-color: #ef5753;
	}

	.md\:hover\:border-red-lighter:hover {
		border-color: #f9acaa;
	}

	.md\:hover\:border-red-lightest:hover {
		border-color: #fcebea;
	}

	.md\:hover\:border-orange-darkest:hover {
		border-color: #462a16;
	}

	.md\:hover\:border-orange-darker:hover {
		border-color: #613b1f;
	}

	.md\:hover\:border-orange-dark:hover {
		border-color: #de751f;
	}

	.md\:hover\:border-orange-light:hover {
		border-color: #faad63;
	}

	.md\:hover\:border-orange-lighter:hover {
		border-color: #fcd9b6;
	}

	.md\:hover\:border-orange-lightest:hover {
		border-color: #fff5eb;
	}

	.md\:hover\:border-yellow-darkest:hover {
		border-color: #453411;
	}

	.md\:hover\:border-yellow-darker:hover {
		border-color: #684f1d;
	}

	.md\:hover\:border-yellow-dark:hover {
		border-color: #f2d024;
	}

	.md\:hover\:border-yellow-light:hover {
		border-color: #fff382;
	}

	.md\:hover\:border-yellow-lighter:hover {
		border-color: #fff9c2;
	}

	.md\:hover\:border-yellow-lightest:hover {
		border-color: #fcfbeb;
	}

	.md\:hover\:border-green-darkest:hover {
		border-color: #0f2f21;
	}

	.md\:hover\:border-green-darker:hover {
		border-color: #1a4731;
	}

	.md\:hover\:border-green-dark:hover {
		border-color: #1f9d55;
	}

	.md\:hover\:border-green-light:hover {
		border-color: #51d88a;
	}

	.md\:hover\:border-green-lighter:hover {
		border-color: #a2f5bf;
	}

	.md\:hover\:border-green-lightest:hover {
		border-color: #e3fcec;
	}

	.md\:hover\:border-teal-darkest:hover {
		border-color: #0d3331;
	}

	.md\:hover\:border-teal-darker:hover {
		border-color: #20504f;
	}

	.md\:hover\:border-teal-dark:hover {
		border-color: #38a89d;
	}

	.md\:hover\:border-teal-light:hover {
		border-color: #64d5ca;
	}

	.md\:hover\:border-teal-lighter:hover {
		border-color: #a0f0ed;
	}

	.md\:hover\:border-teal-lightest:hover {
		border-color: #e8fffe;
	}

	.md\:hover\:border-blue-darkest:hover {
		border-color: #12283a;
	}

	.md\:hover\:border-blue-darker:hover {
		border-color: #1c3d5a;
	}

	.md\:hover\:border-blue-dark:hover {
		border-color: #2779bd;
	}

	.md\:hover\:border-blue-light:hover {
		border-color: #6cb2eb;
	}

	.md\:hover\:border-blue-lighter:hover {
		border-color: #bcdefa;
	}

	.md\:hover\:border-blue-lightest:hover {
		border-color: #eff8ff;
	}

	.md\:hover\:border-indigo-darkest:hover {
		border-color: #191e38;
	}

	.md\:hover\:border-indigo-darker:hover {
		border-color: #2f365f;
	}

	.md\:hover\:border-indigo-dark:hover {
		border-color: #5661b3;
	}

	.md\:hover\:border-indigo-light:hover {
		border-color: #7886d7;
	}

	.md\:hover\:border-indigo-lighter:hover {
		border-color: #b2b7ff;
	}

	.md\:hover\:border-indigo-lightest:hover {
		border-color: #e6e8ff;
	}

	.md\:hover\:border-purple-darkest:hover {
		border-color: #21183c;
	}

	.md\:hover\:border-purple-darker:hover {
		border-color: #382b5f;
	}

	.md\:hover\:border-purple-dark:hover {
		border-color: #794acf;
	}

	.md\:hover\:border-purple-light:hover {
		border-color: #a779e9;
	}

	.md\:hover\:border-purple-lighter:hover {
		border-color: #d6bbfc;
	}

	.md\:hover\:border-purple-lightest:hover {
		border-color: #f3ebff;
	}

	.md\:hover\:border-pink-darkest:hover {
		border-color: #451225;
	}

	.md\:hover\:border-pink-darker:hover {
		border-color: #6f213f;
	}

	.md\:hover\:border-pink-dark:hover {
		border-color: #eb5286;
	}

	.md\:hover\:border-pink-light:hover {
		border-color: #fa7ea8;
	}

	.md\:hover\:border-pink-lighter:hover {
		border-color: #ffbbca;
	}

	.md\:hover\:border-pink-lightest:hover {
		border-color: #ffebef;
	}

	.md\:focus\:border-transparent:focus {
		border-color: transparent;
	}

	.md\:focus\:border-black:focus {
		border-color: #22292f;
	}

	.md\:focus\:border-white:focus {
		border-color: #ffffff;
	}

	.md\:focus\:border-gray-100:focus {
		border-color: #f7fafc;
	}

	.md\:focus\:border-gray-200:focus {
		border-color: #edf2f7;
	}

	.md\:focus\:border-gray-300:focus {
		border-color: #e2e8f0;
	}

	.md\:focus\:border-gray-400:focus {
		border-color: #cbd5e0;
	}

	.md\:focus\:border-gray-500:focus {
		border-color: #a0aec0;
	}

	.md\:focus\:border-gray-600:focus {
		border-color: #718096;
	}

	.md\:focus\:border-gray-700:focus {
		border-color: #4a5568;
	}

	.md\:focus\:border-gray-800:focus {
		border-color: #2d3748;
	}

	.md\:focus\:border-gray-900:focus {
		border-color: #1a202c;
	}

	.md\:focus\:border-red:focus {
		border-color: #e3342f;
	}

	.md\:focus\:border-orange:focus {
		border-color: #f6993f;
	}

	.md\:focus\:border-yellow:focus {
		border-color: #ffed4a;
	}

	.md\:focus\:border-green:focus {
		border-color: #38c172;
	}

	.md\:focus\:border-teal:focus {
		border-color: #4dc0b5;
	}

	.md\:focus\:border-blue:focus {
		border-color: #3490dc;
	}

	.md\:focus\:border-indigo:focus {
		border-color: #6574cd;
	}

	.md\:focus\:border-purple:focus {
		border-color: #9561e2;
	}

	.md\:focus\:border-pink:focus {
		border-color: #f66d9b;
	}

	.md\:focus\:border-grey-darkest:focus {
		border-color: #3d4852;
	}

	.md\:focus\:border-grey-darker:focus {
		border-color: #606f7b;
	}

	.md\:focus\:border-grey-dark:focus {
		border-color: #8795a1;
	}

	.md\:focus\:border-grey:focus {
		border-color: #b8c2cc;
	}

	.md\:focus\:border-grey-light:focus {
		border-color: #dae1e7;
	}

	.md\:focus\:border-grey-lighter:focus {
		border-color: #f1f5f8;
	}

	.md\:focus\:border-grey-lightest:focus {
		border-color: #f8fafc;
	}

	.md\:focus\:border-red-darkest:focus {
		border-color: #3b0d0c;
	}

	.md\:focus\:border-red-darker:focus {
		border-color: #621b18;
	}

	.md\:focus\:border-red-dark:focus {
		border-color: #cc1f1a;
	}

	.md\:focus\:border-red-light:focus {
		border-color: #ef5753;
	}

	.md\:focus\:border-red-lighter:focus {
		border-color: #f9acaa;
	}

	.md\:focus\:border-red-lightest:focus {
		border-color: #fcebea;
	}

	.md\:focus\:border-orange-darkest:focus {
		border-color: #462a16;
	}

	.md\:focus\:border-orange-darker:focus {
		border-color: #613b1f;
	}

	.md\:focus\:border-orange-dark:focus {
		border-color: #de751f;
	}

	.md\:focus\:border-orange-light:focus {
		border-color: #faad63;
	}

	.md\:focus\:border-orange-lighter:focus {
		border-color: #fcd9b6;
	}

	.md\:focus\:border-orange-lightest:focus {
		border-color: #fff5eb;
	}

	.md\:focus\:border-yellow-darkest:focus {
		border-color: #453411;
	}

	.md\:focus\:border-yellow-darker:focus {
		border-color: #684f1d;
	}

	.md\:focus\:border-yellow-dark:focus {
		border-color: #f2d024;
	}

	.md\:focus\:border-yellow-light:focus {
		border-color: #fff382;
	}

	.md\:focus\:border-yellow-lighter:focus {
		border-color: #fff9c2;
	}

	.md\:focus\:border-yellow-lightest:focus {
		border-color: #fcfbeb;
	}

	.md\:focus\:border-green-darkest:focus {
		border-color: #0f2f21;
	}

	.md\:focus\:border-green-darker:focus {
		border-color: #1a4731;
	}

	.md\:focus\:border-green-dark:focus {
		border-color: #1f9d55;
	}

	.md\:focus\:border-green-light:focus {
		border-color: #51d88a;
	}

	.md\:focus\:border-green-lighter:focus {
		border-color: #a2f5bf;
	}

	.md\:focus\:border-green-lightest:focus {
		border-color: #e3fcec;
	}

	.md\:focus\:border-teal-darkest:focus {
		border-color: #0d3331;
	}

	.md\:focus\:border-teal-darker:focus {
		border-color: #20504f;
	}

	.md\:focus\:border-teal-dark:focus {
		border-color: #38a89d;
	}

	.md\:focus\:border-teal-light:focus {
		border-color: #64d5ca;
	}

	.md\:focus\:border-teal-lighter:focus {
		border-color: #a0f0ed;
	}

	.md\:focus\:border-teal-lightest:focus {
		border-color: #e8fffe;
	}

	.md\:focus\:border-blue-darkest:focus {
		border-color: #12283a;
	}

	.md\:focus\:border-blue-darker:focus {
		border-color: #1c3d5a;
	}

	.md\:focus\:border-blue-dark:focus {
		border-color: #2779bd;
	}

	.md\:focus\:border-blue-light:focus {
		border-color: #6cb2eb;
	}

	.md\:focus\:border-blue-lighter:focus {
		border-color: #bcdefa;
	}

	.md\:focus\:border-blue-lightest:focus {
		border-color: #eff8ff;
	}

	.md\:focus\:border-indigo-darkest:focus {
		border-color: #191e38;
	}

	.md\:focus\:border-indigo-darker:focus {
		border-color: #2f365f;
	}

	.md\:focus\:border-indigo-dark:focus {
		border-color: #5661b3;
	}

	.md\:focus\:border-indigo-light:focus {
		border-color: #7886d7;
	}

	.md\:focus\:border-indigo-lighter:focus {
		border-color: #b2b7ff;
	}

	.md\:focus\:border-indigo-lightest:focus {
		border-color: #e6e8ff;
	}

	.md\:focus\:border-purple-darkest:focus {
		border-color: #21183c;
	}

	.md\:focus\:border-purple-darker:focus {
		border-color: #382b5f;
	}

	.md\:focus\:border-purple-dark:focus {
		border-color: #794acf;
	}

	.md\:focus\:border-purple-light:focus {
		border-color: #a779e9;
	}

	.md\:focus\:border-purple-lighter:focus {
		border-color: #d6bbfc;
	}

	.md\:focus\:border-purple-lightest:focus {
		border-color: #f3ebff;
	}

	.md\:focus\:border-pink-darkest:focus {
		border-color: #451225;
	}

	.md\:focus\:border-pink-darker:focus {
		border-color: #6f213f;
	}

	.md\:focus\:border-pink-dark:focus {
		border-color: #eb5286;
	}

	.md\:focus\:border-pink-light:focus {
		border-color: #fa7ea8;
	}

	.md\:focus\:border-pink-lighter:focus {
		border-color: #ffbbca;
	}

	.md\:focus\:border-pink-lightest:focus {
		border-color: #ffebef;
	}

	.md\:rounded-2 {
		border-radius: 0.2rem;
	}

	.md\:rounded-4 {
		border-radius: 0.4rem;
	}

	.md\:rounded-6 {
		border-radius: 0.6rem;
	}

	.md\:rounded-8 {
		border-radius: 0.8rem;
	}

	.md\:rounded-12 {
		border-radius: 1.2rem;
	}

	.md\:rounded-16 {
		border-radius: 1.6rem;
	}

	.md\:rounded-20 {
		border-radius: 2rem;
	}

	.md\:rounded-24 {
		border-radius: 2.4rem;
	}

	.md\:rounded-28 {
		border-radius: 2.8rem;
	}

	.md\:rounded-32 {
		border-radius: 3.2rem;
	}

	.md\:rounded-none {
		border-radius: 0;
	}

	.md\:rounded-sm {
		border-radius: 0.2rem;
	}

	.md\:rounded {
		border-radius: 0.4rem;
	}

	.md\:rounded-lg {
		border-radius: 0.8rem;
	}

	.md\:rounded-full {
		border-radius: 9999px;
	}

	.md\:rounded-t-2 {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.md\:rounded-r-2 {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.md\:rounded-b-2 {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-l-2 {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-t-4 {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.md\:rounded-r-4 {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.md\:rounded-b-4 {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-l-4 {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-t-6 {
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
	}

	.md\:rounded-r-6 {
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
	}

	.md\:rounded-b-6 {
		border-bottom-right-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.md\:rounded-l-6 {
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.md\:rounded-t-8 {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.md\:rounded-r-8 {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.md\:rounded-b-8 {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-l-8 {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-t-12 {
		border-top-left-radius: 1.2rem;
		border-top-right-radius: 1.2rem;
	}

	.md\:rounded-r-12 {
		border-top-right-radius: 1.2rem;
		border-bottom-right-radius: 1.2rem;
	}

	.md\:rounded-b-12 {
		border-bottom-right-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.md\:rounded-l-12 {
		border-top-left-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.md\:rounded-t-16 {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
	}

	.md\:rounded-r-16 {
		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;
	}

	.md\:rounded-b-16 {
		border-bottom-right-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.md\:rounded-l-16 {
		border-top-left-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.md\:rounded-t-20 {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.md\:rounded-r-20 {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}

	.md\:rounded-b-20 {
		border-bottom-right-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.md\:rounded-l-20 {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.md\:rounded-t-24 {
		border-top-left-radius: 2.4rem;
		border-top-right-radius: 2.4rem;
	}

	.md\:rounded-r-24 {
		border-top-right-radius: 2.4rem;
		border-bottom-right-radius: 2.4rem;
	}

	.md\:rounded-b-24 {
		border-bottom-right-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.md\:rounded-l-24 {
		border-top-left-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.md\:rounded-t-28 {
		border-top-left-radius: 2.8rem;
		border-top-right-radius: 2.8rem;
	}

	.md\:rounded-r-28 {
		border-top-right-radius: 2.8rem;
		border-bottom-right-radius: 2.8rem;
	}

	.md\:rounded-b-28 {
		border-bottom-right-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.md\:rounded-l-28 {
		border-top-left-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.md\:rounded-t-32 {
		border-top-left-radius: 3.2rem;
		border-top-right-radius: 3.2rem;
	}

	.md\:rounded-r-32 {
		border-top-right-radius: 3.2rem;
		border-bottom-right-radius: 3.2rem;
	}

	.md\:rounded-b-32 {
		border-bottom-right-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.md\:rounded-l-32 {
		border-top-left-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.md\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.md\:rounded-r-none {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.md\:rounded-b-none {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.md\:rounded-l-none {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.md\:rounded-t-sm {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.md\:rounded-r-sm {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.md\:rounded-b-sm {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-l-sm {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-t {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.md\:rounded-r {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.md\:rounded-b {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-l {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-t-lg {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.md\:rounded-r-lg {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.md\:rounded-b-lg {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-l-lg {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-t-full {
		border-top-left-radius: 9999px;
		border-top-right-radius: 9999px;
	}

	.md\:rounded-r-full {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}

	.md\:rounded-b-full {
		border-bottom-right-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.md\:rounded-l-full {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.md\:rounded-tl-2 {
		border-top-left-radius: 0.2rem;
	}

	.md\:rounded-tr-2 {
		border-top-right-radius: 0.2rem;
	}

	.md\:rounded-br-2 {
		border-bottom-right-radius: 0.2rem;
	}

	.md\:rounded-bl-2 {
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-tl-4 {
		border-top-left-radius: 0.4rem;
	}

	.md\:rounded-tr-4 {
		border-top-right-radius: 0.4rem;
	}

	.md\:rounded-br-4 {
		border-bottom-right-radius: 0.4rem;
	}

	.md\:rounded-bl-4 {
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-tl-6 {
		border-top-left-radius: 0.6rem;
	}

	.md\:rounded-tr-6 {
		border-top-right-radius: 0.6rem;
	}

	.md\:rounded-br-6 {
		border-bottom-right-radius: 0.6rem;
	}

	.md\:rounded-bl-6 {
		border-bottom-left-radius: 0.6rem;
	}

	.md\:rounded-tl-8 {
		border-top-left-radius: 0.8rem;
	}

	.md\:rounded-tr-8 {
		border-top-right-radius: 0.8rem;
	}

	.md\:rounded-br-8 {
		border-bottom-right-radius: 0.8rem;
	}

	.md\:rounded-bl-8 {
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-tl-12 {
		border-top-left-radius: 1.2rem;
	}

	.md\:rounded-tr-12 {
		border-top-right-radius: 1.2rem;
	}

	.md\:rounded-br-12 {
		border-bottom-right-radius: 1.2rem;
	}

	.md\:rounded-bl-12 {
		border-bottom-left-radius: 1.2rem;
	}

	.md\:rounded-tl-16 {
		border-top-left-radius: 1.6rem;
	}

	.md\:rounded-tr-16 {
		border-top-right-radius: 1.6rem;
	}

	.md\:rounded-br-16 {
		border-bottom-right-radius: 1.6rem;
	}

	.md\:rounded-bl-16 {
		border-bottom-left-radius: 1.6rem;
	}

	.md\:rounded-tl-20 {
		border-top-left-radius: 2rem;
	}

	.md\:rounded-tr-20 {
		border-top-right-radius: 2rem;
	}

	.md\:rounded-br-20 {
		border-bottom-right-radius: 2rem;
	}

	.md\:rounded-bl-20 {
		border-bottom-left-radius: 2rem;
	}

	.md\:rounded-tl-24 {
		border-top-left-radius: 2.4rem;
	}

	.md\:rounded-tr-24 {
		border-top-right-radius: 2.4rem;
	}

	.md\:rounded-br-24 {
		border-bottom-right-radius: 2.4rem;
	}

	.md\:rounded-bl-24 {
		border-bottom-left-radius: 2.4rem;
	}

	.md\:rounded-tl-28 {
		border-top-left-radius: 2.8rem;
	}

	.md\:rounded-tr-28 {
		border-top-right-radius: 2.8rem;
	}

	.md\:rounded-br-28 {
		border-bottom-right-radius: 2.8rem;
	}

	.md\:rounded-bl-28 {
		border-bottom-left-radius: 2.8rem;
	}

	.md\:rounded-tl-32 {
		border-top-left-radius: 3.2rem;
	}

	.md\:rounded-tr-32 {
		border-top-right-radius: 3.2rem;
	}

	.md\:rounded-br-32 {
		border-bottom-right-radius: 3.2rem;
	}

	.md\:rounded-bl-32 {
		border-bottom-left-radius: 3.2rem;
	}

	.md\:rounded-tl-none {
		border-top-left-radius: 0;
	}

	.md\:rounded-tr-none {
		border-top-right-radius: 0;
	}

	.md\:rounded-br-none {
		border-bottom-right-radius: 0;
	}

	.md\:rounded-bl-none {
		border-bottom-left-radius: 0;
	}

	.md\:rounded-tl-sm {
		border-top-left-radius: 0.2rem;
	}

	.md\:rounded-tr-sm {
		border-top-right-radius: 0.2rem;
	}

	.md\:rounded-br-sm {
		border-bottom-right-radius: 0.2rem;
	}

	.md\:rounded-bl-sm {
		border-bottom-left-radius: 0.2rem;
	}

	.md\:rounded-tl {
		border-top-left-radius: 0.4rem;
	}

	.md\:rounded-tr {
		border-top-right-radius: 0.4rem;
	}

	.md\:rounded-br {
		border-bottom-right-radius: 0.4rem;
	}

	.md\:rounded-bl {
		border-bottom-left-radius: 0.4rem;
	}

	.md\:rounded-tl-lg {
		border-top-left-radius: 0.8rem;
	}

	.md\:rounded-tr-lg {
		border-top-right-radius: 0.8rem;
	}

	.md\:rounded-br-lg {
		border-bottom-right-radius: 0.8rem;
	}

	.md\:rounded-bl-lg {
		border-bottom-left-radius: 0.8rem;
	}

	.md\:rounded-tl-full {
		border-top-left-radius: 9999px;
	}

	.md\:rounded-tr-full {
		border-top-right-radius: 9999px;
	}

	.md\:rounded-br-full {
		border-bottom-right-radius: 9999px;
	}

	.md\:rounded-bl-full {
		border-bottom-left-radius: 9999px;
	}

	.md\:border-solid {
		border-style: solid;
	}

	.md\:border-dashed {
		border-style: dashed;
	}

	.md\:border-dotted {
		border-style: dotted;
	}

	.md\:border-none {
		border-style: none;
	}

	.md\:border-0 {
		border-width: 0;
	}

	.md\:border-1 {
		border-width: 1px;
	}

	.md\:border-2 {
		border-width: 2px;
	}

	.md\:border-3 {
		border-width: 3px;
	}

	.md\:border-4 {
		border-width: 4px;
	}

	.md\:border-8 {
		border-width: 8px;
	}

	.md\:border {
		border-width: 1px;
	}

	.md\:border-t-0 {
		border-top-width: 0;
	}

	.md\:border-r-0 {
		border-right-width: 0;
	}

	.md\:border-b-0 {
		border-bottom-width: 0;
	}

	.md\:border-l-0 {
		border-left-width: 0;
	}

	.md\:border-t-1 {
		border-top-width: 1px;
	}

	.md\:border-r-1 {
		border-right-width: 1px;
	}

	.md\:border-b-1 {
		border-bottom-width: 1px;
	}

	.md\:border-l-1 {
		border-left-width: 1px;
	}

	.md\:border-t-2 {
		border-top-width: 2px;
	}

	.md\:border-r-2 {
		border-right-width: 2px;
	}

	.md\:border-b-2 {
		border-bottom-width: 2px;
	}

	.md\:border-l-2 {
		border-left-width: 2px;
	}

	.md\:border-t-3 {
		border-top-width: 3px;
	}

	.md\:border-r-3 {
		border-right-width: 3px;
	}

	.md\:border-b-3 {
		border-bottom-width: 3px;
	}

	.md\:border-l-3 {
		border-left-width: 3px;
	}

	.md\:border-t-4 {
		border-top-width: 4px;
	}

	.md\:border-r-4 {
		border-right-width: 4px;
	}

	.md\:border-b-4 {
		border-bottom-width: 4px;
	}

	.md\:border-l-4 {
		border-left-width: 4px;
	}

	.md\:border-t-8 {
		border-top-width: 8px;
	}

	.md\:border-r-8 {
		border-right-width: 8px;
	}

	.md\:border-b-8 {
		border-bottom-width: 8px;
	}

	.md\:border-l-8 {
		border-left-width: 8px;
	}

	.md\:border-t {
		border-top-width: 1px;
	}

	.md\:border-r {
		border-right-width: 1px;
	}

	.md\:border-b {
		border-bottom-width: 1px;
	}

	.md\:border-l {
		border-left-width: 1px;
	}

	.md\:cursor-auto {
		cursor: auto;
	}

	.md\:cursor-default {
		cursor: default;
	}

	.md\:cursor-pointer {
		cursor: pointer;
	}

	.md\:cursor-wait {
		cursor: wait;
	}

	.md\:cursor-text {
		cursor: text;
	}

	.md\:cursor-move {
		cursor: move;
	}

	.md\:cursor-not-allowed {
		cursor: not-allowed;
	}

	.md\:block {
		display: block;
	}

	.md\:inline-block {
		display: inline-block;
	}

	.md\:inline {
		display: inline;
	}

	.md\:flex {
		display: -webkit-box;
		display: flex;
	}

	.md\:inline-flex {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.md\:table {
		display: table;
	}

	.md\:table-row {
		display: table-row;
	}

	.md\:table-cell {
		display: table-cell;
	}

	.md\:hidden {
		display: none;
	}

	.md\:hover\:block:hover {
		display: block;
	}

	.md\:hover\:inline-block:hover {
		display: inline-block;
	}

	.md\:hover\:inline:hover {
		display: inline;
	}

	.md\:hover\:flex:hover {
		display: -webkit-box;
		display: flex;
	}

	.md\:hover\:inline-flex:hover {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.md\:hover\:table:hover {
		display: table;
	}

	.md\:hover\:table-row:hover {
		display: table-row;
	}

	.md\:hover\:table-cell:hover {
		display: table-cell;
	}

	.md\:hover\:hidden:hover {
		display: none;
	}

	.md\:focus\:block:focus {
		display: block;
	}

	.md\:focus\:inline-block:focus {
		display: inline-block;
	}

	.md\:focus\:inline:focus {
		display: inline;
	}

	.md\:focus\:flex:focus {
		display: -webkit-box;
		display: flex;
	}

	.md\:focus\:inline-flex:focus {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.md\:focus\:table:focus {
		display: table;
	}

	.md\:focus\:table-row:focus {
		display: table-row;
	}

	.md\:focus\:table-cell:focus {
		display: table-cell;
	}

	.md\:focus\:hidden:focus {
		display: none;
	}

	.md\:flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.md\:flex-row-reverse {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.md\:flex-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.md\:flex-col-reverse {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.md\:flex-wrap {
		flex-wrap: wrap;
	}

	.md\:flex-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	.md\:flex-no-wrap {
		flex-wrap: nowrap;
	}

	.md\:items-start {
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.md\:items-end {
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.md\:items-center {
		-webkit-box-align: center;
		align-items: center;
	}

	.md\:items-baseline {
		-webkit-box-align: baseline;
		align-items: baseline;
	}

	.md\:items-stretch {
		-webkit-box-align: stretch;
		align-items: stretch;
	}

	.md\:self-auto {
		align-self: auto;
	}

	.md\:self-start {
		align-self: flex-start;
	}

	.md\:self-end {
		align-self: flex-end;
	}

	.md\:self-center {
		align-self: center;
	}

	.md\:self-stretch {
		align-self: stretch;
	}

	.md\:justify-start {
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.md\:justify-end {
		-webkit-box-pack: end;
		justify-content: flex-end;
	}

	.md\:justify-center {
		-webkit-box-pack: center;
		justify-content: center;
	}

	.md\:justify-between {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.md\:justify-around {
		justify-content: space-around;
	}

	.md\:content-center {
		align-content: center;
	}

	.md\:content-start {
		align-content: flex-start;
	}

	.md\:content-end {
		align-content: flex-end;
	}

	.md\:content-between {
		align-content: space-between;
	}

	.md\:content-around {
		align-content: space-around;
	}

	.md\:flex-1 {
		-webkit-box-flex: 1;
		flex: 1 1 0%;
	}

	.md\:flex-auto {
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}

	.md\:flex-initial {
		-webkit-box-flex: 0;
		flex: 0 1 auto;
	}

	.md\:flex-none {
		-webkit-box-flex: 0;
		flex: none;
	}

	.md\:flex-grow-0 {
		-webkit-box-flex: 0;
		flex-grow: 0;
	}

	.md\:flex-grow {
		-webkit-box-flex: 1;
		flex-grow: 1;
	}

	.md\:flex-shrink-0 {
		flex-shrink: 0;
	}

	.md\:flex-shrink {
		flex-shrink: 1;
	}

	.md\:order-1 {
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	.md\:order-2 {
		-webkit-box-ordinal-group: 3;
		order: 2;
	}

	.md\:order-3 {
		-webkit-box-ordinal-group: 4;
		order: 3;
	}

	.md\:order-4 {
		-webkit-box-ordinal-group: 5;
		order: 4;
	}

	.md\:order-5 {
		-webkit-box-ordinal-group: 6;
		order: 5;
	}

	.md\:order-6 {
		-webkit-box-ordinal-group: 7;
		order: 6;
	}

	.md\:order-7 {
		-webkit-box-ordinal-group: 8;
		order: 7;
	}

	.md\:order-8 {
		-webkit-box-ordinal-group: 9;
		order: 8;
	}

	.md\:order-9 {
		-webkit-box-ordinal-group: 10;
		order: 9;
	}

	.md\:order-10 {
		-webkit-box-ordinal-group: 11;
		order: 10;
	}

	.md\:order-11 {
		-webkit-box-ordinal-group: 12;
		order: 11;
	}

	.md\:order-12 {
		-webkit-box-ordinal-group: 13;
		order: 12;
	}

	.md\:order-first {
		-webkit-box-ordinal-group: -9998;
		order: -9999;
	}

	.md\:order-last {
		-webkit-box-ordinal-group: 10000;
		order: 9999;
	}

	.md\:order-none {
		-webkit-box-ordinal-group: 1;
		order: 0;
	}

	.md\:float-right {
		float: right;
	}

	.md\:float-left {
		float: left;
	}

	.md\:float-none {
		float: none;
	}

	.md\:clearfix:after {
		content: '';
		display: table;
		clear: both;
	}

	.md\:font-sans {
		font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
			Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
			sans-serif;
	}

	.md\:font-serif {
		font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
			DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
	}

	.md\:font-mono {
		font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
			monospace;
	}

	.md\:font-100 {
		font-weight: 100;
	}

	.md\:font-200 {
		font-weight: 200;
	}

	.md\:font-300 {
		font-weight: 300;
	}

	.md\:font-400 {
		font-weight: 400;
	}

	.md\:font-500 {
		font-weight: 500;
	}

	.md\:font-600 {
		font-weight: 600;
	}

	.md\:font-700 {
		font-weight: 700;
	}

	.md\:font-800 {
		font-weight: 800;
	}

	.md\:font-900 {
		font-weight: 900;
	}

	.md\:font-hairline {
		font-weight: 100;
	}

	.md\:font-thin {
		font-weight: 200;
	}

	.md\:font-light {
		font-weight: 300;
	}

	.md\:font-normal {
		font-weight: 400;
	}

	.md\:font-medium {
		font-weight: 500;
	}

	.md\:font-semibold {
		font-weight: 600;
	}

	.md\:font-bold {
		font-weight: 700;
	}

	.md\:font-extrabold {
		font-weight: 800;
	}

	.md\:font-black {
		font-weight: 900;
	}

	.md\:hover\:font-100:hover {
		font-weight: 100;
	}

	.md\:hover\:font-200:hover {
		font-weight: 200;
	}

	.md\:hover\:font-300:hover {
		font-weight: 300;
	}

	.md\:hover\:font-400:hover {
		font-weight: 400;
	}

	.md\:hover\:font-500:hover {
		font-weight: 500;
	}

	.md\:hover\:font-600:hover {
		font-weight: 600;
	}

	.md\:hover\:font-700:hover {
		font-weight: 700;
	}

	.md\:hover\:font-800:hover {
		font-weight: 800;
	}

	.md\:hover\:font-900:hover {
		font-weight: 900;
	}

	.md\:hover\:font-hairline:hover {
		font-weight: 100;
	}

	.md\:hover\:font-thin:hover {
		font-weight: 200;
	}

	.md\:hover\:font-light:hover {
		font-weight: 300;
	}

	.md\:hover\:font-normal:hover {
		font-weight: 400;
	}

	.md\:hover\:font-medium:hover {
		font-weight: 500;
	}

	.md\:hover\:font-semibold:hover {
		font-weight: 600;
	}

	.md\:hover\:font-bold:hover {
		font-weight: 700;
	}

	.md\:hover\:font-extrabold:hover {
		font-weight: 800;
	}

	.md\:hover\:font-black:hover {
		font-weight: 900;
	}

	.md\:focus\:font-100:focus {
		font-weight: 100;
	}

	.md\:focus\:font-200:focus {
		font-weight: 200;
	}

	.md\:focus\:font-300:focus {
		font-weight: 300;
	}

	.md\:focus\:font-400:focus {
		font-weight: 400;
	}

	.md\:focus\:font-500:focus {
		font-weight: 500;
	}

	.md\:focus\:font-600:focus {
		font-weight: 600;
	}

	.md\:focus\:font-700:focus {
		font-weight: 700;
	}

	.md\:focus\:font-800:focus {
		font-weight: 800;
	}

	.md\:focus\:font-900:focus {
		font-weight: 900;
	}

	.md\:focus\:font-hairline:focus {
		font-weight: 100;
	}

	.md\:focus\:font-thin:focus {
		font-weight: 200;
	}

	.md\:focus\:font-light:focus {
		font-weight: 300;
	}

	.md\:focus\:font-normal:focus {
		font-weight: 400;
	}

	.md\:focus\:font-medium:focus {
		font-weight: 500;
	}

	.md\:focus\:font-semibold:focus {
		font-weight: 600;
	}

	.md\:focus\:font-bold:focus {
		font-weight: 700;
	}

	.md\:focus\:font-extrabold:focus {
		font-weight: 800;
	}

	.md\:focus\:font-black:focus {
		font-weight: 900;
	}

	.md\:h-0 {
		height: 0;
	}

	.md\:h-1 {
		height: 0.25rem;
	}

	.md\:h-2 {
		height: 0.5rem;
	}

	.md\:h-3 {
		height: 0.75rem;
	}

	.md\:h-4 {
		height: 0.4rem;
	}

	.md\:h-5 {
		height: 1.25rem;
	}

	.md\:h-6 {
		height: 0.6rem;
	}

	.md\:h-8 {
		height: 0.8rem;
	}

	.md\:h-10 {
		height: 2.5rem;
	}

	.md\:h-12 {
		height: 1.2rem;
	}

	.md\:h-16 {
		height: 1.6rem;
	}

	.md\:h-20 {
		height: 2rem;
	}

	.md\:h-24 {
		height: 2.4rem;
	}

	.md\:h-28 {
		height: 2.8rem;
	}

	.md\:h-32 {
		height: 3.2rem;
	}

	.md\:h-36 {
		height: 3.6rem;
	}

	.md\:h-40 {
		height: 4rem;
	}

	.md\:h-44 {
		height: 4.4rem;
	}

	.md\:h-48 {
		height: 4.8rem;
	}

	.md\:h-52 {
		height: 5.2rem;
	}

	.md\:h-56 {
		height: 5.6rem;
	}

	.md\:h-60 {
		height: 6rem;
	}

	.md\:h-64 {
		height: 6.4rem;
	}

	.md\:h-68 {
		height: 6.8rem;
	}

	.md\:h-72 {
		height: 7.2rem;
	}

	.md\:h-76 {
		height: 7.6rem;
	}

	.md\:h-80 {
		height: 8rem;
	}

	.md\:h-84 {
		height: 8.4rem;
	}

	.md\:h-88 {
		height: 8.8rem;
	}

	.md\:h-92 {
		height: 9.2rem;
	}

	.md\:h-96 {
		height: 9.6rem;
	}

	.md\:h-128 {
		height: 12.8rem;
	}

	.md\:h-136 {
		height: 13.6rem;
	}

	.md\:h-160 {
		height: 16rem;
	}

	.md\:h-192 {
		height: 19.2rem;
	}

	.md\:h-200 {
		height: 20rem;
	}

	.md\:h-208 {
		height: 20.8rem;
	}

	.md\:h-216 {
		height: 21.6rem;
	}

	.md\:h-224 {
		height: 22.4rem;
	}

	.md\:h-256 {
		height: 25.6rem;
	}

	.md\:h-288 {
		height: 28.8rem;
	}

	.md\:h-320 {
		height: 32rem;
	}

	.md\:h-360 {
		height: 36rem;
	}

	.md\:h-384 {
		height: 38.4rem;
	}

	.md\:h-400 {
		height: 40rem;
	}

	.md\:h-512 {
		height: 51.2rem;
	}

	.md\:h-640 {
		height: 64rem;
	}

	.md\:h-auto {
		height: auto;
	}

	.md\:h-px {
		height: 1px;
	}

	.md\:h-xs {
		height: 32rem;
	}

	.md\:h-sm {
		height: 48rem;
	}

	.md\:h-md {
		height: 64rem;
	}

	.md\:h-lg {
		height: 80rem;
	}

	.md\:h-xl {
		height: 96rem;
	}

	.md\:h-2xl {
		height: 112rem;
	}

	.md\:h-3xl {
		height: 128rem;
	}

	.md\:h-4xl {
		height: 144rem;
	}

	.md\:h-5xl {
		height: 160rem;
	}

	.md\:h-full {
		height: 100%;
	}

	.md\:h-screen {
		height: 100vh;
	}

	.md\:leading-none {
		line-height: 1;
	}

	.md\:leading-tight {
		line-height: 1.25;
	}

	.md\:leading-snug {
		line-height: 1.375;
	}

	.md\:leading-normal {
		line-height: 1.5;
	}

	.md\:leading-relaxed {
		line-height: 1.625;
	}

	.md\:leading-loose {
		line-height: 2;
	}

	.md\:list-inside {
		list-style-position: inside;
	}

	.md\:list-outside {
		list-style-position: outside;
	}

	.md\:list-none {
		list-style-type: none;
	}

	.md\:list-disc {
		list-style-type: disc;
	}

	.md\:list-decimal {
		list-style-type: decimal;
	}

	.md\:m-0 {
		margin: 0;
	}

	.md\:m-1 {
		margin: 0.25rem;
	}

	.md\:m-2 {
		margin: 0.5rem;
	}

	.md\:m-3 {
		margin: 0.75rem;
	}

	.md\:m-4 {
		margin: 0.4rem;
	}

	.md\:m-5 {
		margin: 1.25rem;
	}

	.md\:m-6 {
		margin: 0.6rem;
	}

	.md\:m-8 {
		margin: 0.8rem;
	}

	.md\:m-10 {
		margin: 2.5rem;
	}

	.md\:m-12 {
		margin: 1.2rem;
	}

	.md\:m-16 {
		margin: 1.6rem;
	}

	.md\:m-20 {
		margin: 2rem;
	}

	.md\:m-24 {
		margin: 2.4rem;
	}

	.md\:m-28 {
		margin: 2.8rem;
	}

	.md\:m-32 {
		margin: 3.2rem;
	}

	.md\:m-36 {
		margin: 3.6rem;
	}

	.md\:m-40 {
		margin: 4rem;
	}

	.md\:m-44 {
		margin: 4.4rem;
	}

	.md\:m-48 {
		margin: 4.8rem;
	}

	.md\:m-52 {
		margin: 5.2rem;
	}

	.md\:m-56 {
		margin: 5.6rem;
	}

	.md\:m-60 {
		margin: 6rem;
	}

	.md\:m-64 {
		margin: 6.4rem;
	}

	.md\:m-68 {
		margin: 6.8rem;
	}

	.md\:m-72 {
		margin: 7.2rem;
	}

	.md\:m-76 {
		margin: 7.6rem;
	}

	.md\:m-80 {
		margin: 8rem;
	}

	.md\:m-84 {
		margin: 8.4rem;
	}

	.md\:m-88 {
		margin: 8.8rem;
	}

	.md\:m-92 {
		margin: 9.2rem;
	}

	.md\:m-96 {
		margin: 9.6rem;
	}

	.md\:m-128 {
		margin: 12.8rem;
	}

	.md\:m-136 {
		margin: 13.6rem;
	}

	.md\:m-160 {
		margin: 16rem;
	}

	.md\:m-192 {
		margin: 19.2rem;
	}

	.md\:m-200 {
		margin: 20rem;
	}

	.md\:m-208 {
		margin: 20.8rem;
	}

	.md\:m-216 {
		margin: 21.6rem;
	}

	.md\:m-224 {
		margin: 22.4rem;
	}

	.md\:m-256 {
		margin: 25.6rem;
	}

	.md\:m-288 {
		margin: 28.8rem;
	}

	.md\:m-320 {
		margin: 32rem;
	}

	.md\:m-360 {
		margin: 36rem;
	}

	.md\:m-384 {
		margin: 38.4rem;
	}

	.md\:m-400 {
		margin: 40rem;
	}

	.md\:m-512 {
		margin: 51.2rem;
	}

	.md\:m-640 {
		margin: 64rem;
	}

	.md\:m-auto {
		margin: auto;
	}

	.md\:m-px {
		margin: 1px;
	}

	.md\:m-xs {
		margin: 32rem;
	}

	.md\:m-sm {
		margin: 48rem;
	}

	.md\:m-md {
		margin: 64rem;
	}

	.md\:m-lg {
		margin: 80rem;
	}

	.md\:m-xl {
		margin: 96rem;
	}

	.md\:m-2xl {
		margin: 112rem;
	}

	.md\:m-3xl {
		margin: 128rem;
	}

	.md\:m-4xl {
		margin: 144rem;
	}

	.md\:m-5xl {
		margin: 160rem;
	}

	.md\:-m-1 {
		margin: -0.25rem;
	}

	.md\:-m-2 {
		margin: -0.5rem;
	}

	.md\:-m-3 {
		margin: -0.75rem;
	}

	.md\:-m-4 {
		margin: -0.4rem;
	}

	.md\:-m-5 {
		margin: -1.25rem;
	}

	.md\:-m-6 {
		margin: -0.6rem;
	}

	.md\:-m-8 {
		margin: -0.8rem;
	}

	.md\:-m-10 {
		margin: -2.5rem;
	}

	.md\:-m-12 {
		margin: -1.2rem;
	}

	.md\:-m-16 {
		margin: -1.6rem;
	}

	.md\:-m-20 {
		margin: -2rem;
	}

	.md\:-m-24 {
		margin: -2.4rem;
	}

	.md\:-m-28 {
		margin: -2.8rem;
	}

	.md\:-m-32 {
		margin: -3.2rem;
	}

	.md\:-m-36 {
		margin: -3.6rem;
	}

	.md\:-m-40 {
		margin: -4rem;
	}

	.md\:-m-44 {
		margin: -4.4rem;
	}

	.md\:-m-48 {
		margin: -4.8rem;
	}

	.md\:-m-52 {
		margin: -5.2rem;
	}

	.md\:-m-56 {
		margin: -5.6rem;
	}

	.md\:-m-60 {
		margin: -6rem;
	}

	.md\:-m-64 {
		margin: -6.4rem;
	}

	.md\:-m-68 {
		margin: -6.8rem;
	}

	.md\:-m-72 {
		margin: -7.2rem;
	}

	.md\:-m-76 {
		margin: -7.6rem;
	}

	.md\:-m-80 {
		margin: -8rem;
	}

	.md\:-m-84 {
		margin: -8.4rem;
	}

	.md\:-m-88 {
		margin: -8.8rem;
	}

	.md\:-m-92 {
		margin: -9.2rem;
	}

	.md\:-m-96 {
		margin: -9.6rem;
	}

	.md\:-m-128 {
		margin: -12.8rem;
	}

	.md\:-m-136 {
		margin: -13.6rem;
	}

	.md\:-m-160 {
		margin: -16rem;
	}

	.md\:-m-192 {
		margin: -19.2rem;
	}

	.md\:-m-200 {
		margin: -20rem;
	}

	.md\:-m-208 {
		margin: -20.8rem;
	}

	.md\:-m-216 {
		margin: -21.6rem;
	}

	.md\:-m-224 {
		margin: -22.4rem;
	}

	.md\:-m-256 {
		margin: -25.6rem;
	}

	.md\:-m-288 {
		margin: -28.8rem;
	}

	.md\:-m-320 {
		margin: -32rem;
	}

	.md\:-m-360 {
		margin: -36rem;
	}

	.md\:-m-384 {
		margin: -38.4rem;
	}

	.md\:-m-400 {
		margin: -40rem;
	}

	.md\:-m-512 {
		margin: -51.2rem;
	}

	.md\:-m-640 {
		margin: -64rem;
	}

	.md\:-m-px {
		margin: -1px;
	}

	.md\:-m-xs {
		margin: -32rem;
	}

	.md\:-m-sm {
		margin: -48rem;
	}

	.md\:-m-md {
		margin: -64rem;
	}

	.md\:-m-lg {
		margin: -80rem;
	}

	.md\:-m-xl {
		margin: -96rem;
	}

	.md\:-m-2xl {
		margin: -112rem;
	}

	.md\:-m-3xl {
		margin: -128rem;
	}

	.md\:-m-4xl {
		margin: -144rem;
	}

	.md\:-m-5xl {
		margin: -160rem;
	}

	.md\:-m-auto {
		margin: -auto;
	}

	.md\:my-0 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.md\:mx-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.md\:my-1 {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.md\:mx-1 {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.md\:my-2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.md\:mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.md\:my-3 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.md\:mx-3 {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.md\:my-4 {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.md\:mx-4 {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}

	.md\:my-5 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.md\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.md\:my-6 {
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}

	.md\:mx-6 {
		margin-left: 0.6rem;
		margin-right: 0.6rem;
	}

	.md\:my-8 {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}

	.md\:mx-8 {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}

	.md\:my-10 {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.md\:mx-10 {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.md\:my-12 {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.md\:mx-12 {
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.md\:my-16 {
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.md\:mx-16 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.md\:my-20 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.md\:mx-20 {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.md\:my-24 {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	.md\:mx-24 {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	.md\:my-28 {
		margin-top: 2.8rem;
		margin-bottom: 2.8rem;
	}

	.md\:mx-28 {
		margin-left: 2.8rem;
		margin-right: 2.8rem;
	}

	.md\:my-32 {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}

	.md\:mx-32 {
		margin-left: 3.2rem;
		margin-right: 3.2rem;
	}

	.md\:my-36 {
		margin-top: 3.6rem;
		margin-bottom: 3.6rem;
	}

	.md\:mx-36 {
		margin-left: 3.6rem;
		margin-right: 3.6rem;
	}

	.md\:my-40 {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.md\:mx-40 {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.md\:my-44 {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	.md\:mx-44 {
		margin-left: 4.4rem;
		margin-right: 4.4rem;
	}

	.md\:my-48 {
		margin-top: 4.8rem;
		margin-bottom: 4.8rem;
	}

	.md\:mx-48 {
		margin-left: 4.8rem;
		margin-right: 4.8rem;
	}

	.md\:my-52 {
		margin-top: 5.2rem;
		margin-bottom: 5.2rem;
	}

	.md\:mx-52 {
		margin-left: 5.2rem;
		margin-right: 5.2rem;
	}

	.md\:my-56 {
		margin-top: 5.6rem;
		margin-bottom: 5.6rem;
	}

	.md\:mx-56 {
		margin-left: 5.6rem;
		margin-right: 5.6rem;
	}

	.md\:my-60 {
		margin-top: 6rem;
		margin-bottom: 6rem;
	}

	.md\:mx-60 {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	.md\:my-64 {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	.md\:mx-64 {
		margin-left: 6.4rem;
		margin-right: 6.4rem;
	}

	.md\:my-68 {
		margin-top: 6.8rem;
		margin-bottom: 6.8rem;
	}

	.md\:mx-68 {
		margin-left: 6.8rem;
		margin-right: 6.8rem;
	}

	.md\:my-72 {
		margin-top: 7.2rem;
		margin-bottom: 7.2rem;
	}

	.md\:mx-72 {
		margin-left: 7.2rem;
		margin-right: 7.2rem;
	}

	.md\:my-76 {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.md\:mx-76 {
		margin-left: 7.6rem;
		margin-right: 7.6rem;
	}

	.md\:my-80 {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}

	.md\:mx-80 {
		margin-left: 8rem;
		margin-right: 8rem;
	}

	.md\:my-84 {
		margin-top: 8.4rem;
		margin-bottom: 8.4rem;
	}

	.md\:mx-84 {
		margin-left: 8.4rem;
		margin-right: 8.4rem;
	}

	.md\:my-88 {
		margin-top: 8.8rem;
		margin-bottom: 8.8rem;
	}

	.md\:mx-88 {
		margin-left: 8.8rem;
		margin-right: 8.8rem;
	}

	.md\:my-92 {
		margin-top: 9.2rem;
		margin-bottom: 9.2rem;
	}

	.md\:mx-92 {
		margin-left: 9.2rem;
		margin-right: 9.2rem;
	}

	.md\:my-96 {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}

	.md\:mx-96 {
		margin-left: 9.6rem;
		margin-right: 9.6rem;
	}

	.md\:my-128 {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}

	.md\:mx-128 {
		margin-left: 12.8rem;
		margin-right: 12.8rem;
	}

	.md\:my-136 {
		margin-top: 13.6rem;
		margin-bottom: 13.6rem;
	}

	.md\:mx-136 {
		margin-left: 13.6rem;
		margin-right: 13.6rem;
	}

	.md\:my-160 {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}

	.md\:mx-160 {
		margin-left: 16rem;
		margin-right: 16rem;
	}

	.md\:my-192 {
		margin-top: 19.2rem;
		margin-bottom: 19.2rem;
	}

	.md\:mx-192 {
		margin-left: 19.2rem;
		margin-right: 19.2rem;
	}

	.md\:my-200 {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.md\:mx-200 {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.md\:my-208 {
		margin-top: 20.8rem;
		margin-bottom: 20.8rem;
	}

	.md\:mx-208 {
		margin-left: 20.8rem;
		margin-right: 20.8rem;
	}

	.md\:my-216 {
		margin-top: 21.6rem;
		margin-bottom: 21.6rem;
	}

	.md\:mx-216 {
		margin-left: 21.6rem;
		margin-right: 21.6rem;
	}

	.md\:my-224 {
		margin-top: 22.4rem;
		margin-bottom: 22.4rem;
	}

	.md\:mx-224 {
		margin-left: 22.4rem;
		margin-right: 22.4rem;
	}

	.md\:my-256 {
		margin-top: 25.6rem;
		margin-bottom: 25.6rem;
	}

	.md\:mx-256 {
		margin-left: 25.6rem;
		margin-right: 25.6rem;
	}

	.md\:my-288 {
		margin-top: 28.8rem;
		margin-bottom: 28.8rem;
	}

	.md\:mx-288 {
		margin-left: 28.8rem;
		margin-right: 28.8rem;
	}

	.md\:my-320 {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.md\:mx-320 {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.md\:my-360 {
		margin-top: 36rem;
		margin-bottom: 36rem;
	}

	.md\:mx-360 {
		margin-left: 36rem;
		margin-right: 36rem;
	}

	.md\:my-384 {
		margin-top: 38.4rem;
		margin-bottom: 38.4rem;
	}

	.md\:mx-384 {
		margin-left: 38.4rem;
		margin-right: 38.4rem;
	}

	.md\:my-400 {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.md\:mx-400 {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.md\:my-512 {
		margin-top: 51.2rem;
		margin-bottom: 51.2rem;
	}

	.md\:mx-512 {
		margin-left: 51.2rem;
		margin-right: 51.2rem;
	}

	.md\:my-640 {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.md\:mx-640 {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.md\:my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.md\:mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.md\:my-px {
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.md\:mx-px {
		margin-left: 1px;
		margin-right: 1px;
	}

	.md\:my-xs {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.md\:mx-xs {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.md\:my-sm {
		margin-top: 48rem;
		margin-bottom: 48rem;
	}

	.md\:mx-sm {
		margin-left: 48rem;
		margin-right: 48rem;
	}

	.md\:my-md {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.md\:mx-md {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.md\:my-lg {
		margin-top: 80rem;
		margin-bottom: 80rem;
	}

	.md\:mx-lg {
		margin-left: 80rem;
		margin-right: 80rem;
	}

	.md\:my-xl {
		margin-top: 96rem;
		margin-bottom: 96rem;
	}

	.md\:mx-xl {
		margin-left: 96rem;
		margin-right: 96rem;
	}

	.md\:my-2xl {
		margin-top: 112rem;
		margin-bottom: 112rem;
	}

	.md\:mx-2xl {
		margin-left: 112rem;
		margin-right: 112rem;
	}

	.md\:my-3xl {
		margin-top: 128rem;
		margin-bottom: 128rem;
	}

	.md\:mx-3xl {
		margin-left: 128rem;
		margin-right: 128rem;
	}

	.md\:my-4xl {
		margin-top: 144rem;
		margin-bottom: 144rem;
	}

	.md\:mx-4xl {
		margin-left: 144rem;
		margin-right: 144rem;
	}

	.md\:my-5xl {
		margin-top: 160rem;
		margin-bottom: 160rem;
	}

	.md\:mx-5xl {
		margin-left: 160rem;
		margin-right: 160rem;
	}

	.md\:-my-1 {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}

	.md\:-mx-1 {
		margin-left: -0.25rem;
		margin-right: -0.25rem;
	}

	.md\:-my-2 {
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
	}

	.md\:-mx-2 {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	.md\:-my-3 {
		margin-top: -0.75rem;
		margin-bottom: -0.75rem;
	}

	.md\:-mx-3 {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}

	.md\:-my-4 {
		margin-top: -0.4rem;
		margin-bottom: -0.4rem;
	}

	.md\:-mx-4 {
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.md\:-my-5 {
		margin-top: -1.25rem;
		margin-bottom: -1.25rem;
	}

	.md\:-mx-5 {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	}

	.md\:-my-6 {
		margin-top: -0.6rem;
		margin-bottom: -0.6rem;
	}

	.md\:-mx-6 {
		margin-left: -0.6rem;
		margin-right: -0.6rem;
	}

	.md\:-my-8 {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}

	.md\:-mx-8 {
		margin-left: -0.8rem;
		margin-right: -0.8rem;
	}

	.md\:-my-10 {
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.md\:-mx-10 {
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}

	.md\:-my-12 {
		margin-top: -1.2rem;
		margin-bottom: -1.2rem;
	}

	.md\:-mx-12 {
		margin-left: -1.2rem;
		margin-right: -1.2rem;
	}

	.md\:-my-16 {
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	.md\:-mx-16 {
		margin-left: -1.6rem;
		margin-right: -1.6rem;
	}

	.md\:-my-20 {
		margin-top: -2rem;
		margin-bottom: -2rem;
	}

	.md\:-mx-20 {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.md\:-my-24 {
		margin-top: -2.4rem;
		margin-bottom: -2.4rem;
	}

	.md\:-mx-24 {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}

	.md\:-my-28 {
		margin-top: -2.8rem;
		margin-bottom: -2.8rem;
	}

	.md\:-mx-28 {
		margin-left: -2.8rem;
		margin-right: -2.8rem;
	}

	.md\:-my-32 {
		margin-top: -3.2rem;
		margin-bottom: -3.2rem;
	}

	.md\:-mx-32 {
		margin-left: -3.2rem;
		margin-right: -3.2rem;
	}

	.md\:-my-36 {
		margin-top: -3.6rem;
		margin-bottom: -3.6rem;
	}

	.md\:-mx-36 {
		margin-left: -3.6rem;
		margin-right: -3.6rem;
	}

	.md\:-my-40 {
		margin-top: -4rem;
		margin-bottom: -4rem;
	}

	.md\:-mx-40 {
		margin-left: -4rem;
		margin-right: -4rem;
	}

	.md\:-my-44 {
		margin-top: -4.4rem;
		margin-bottom: -4.4rem;
	}

	.md\:-mx-44 {
		margin-left: -4.4rem;
		margin-right: -4.4rem;
	}

	.md\:-my-48 {
		margin-top: -4.8rem;
		margin-bottom: -4.8rem;
	}

	.md\:-mx-48 {
		margin-left: -4.8rem;
		margin-right: -4.8rem;
	}

	.md\:-my-52 {
		margin-top: -5.2rem;
		margin-bottom: -5.2rem;
	}

	.md\:-mx-52 {
		margin-left: -5.2rem;
		margin-right: -5.2rem;
	}

	.md\:-my-56 {
		margin-top: -5.6rem;
		margin-bottom: -5.6rem;
	}

	.md\:-mx-56 {
		margin-left: -5.6rem;
		margin-right: -5.6rem;
	}

	.md\:-my-60 {
		margin-top: -6rem;
		margin-bottom: -6rem;
	}

	.md\:-mx-60 {
		margin-left: -6rem;
		margin-right: -6rem;
	}

	.md\:-my-64 {
		margin-top: -6.4rem;
		margin-bottom: -6.4rem;
	}

	.md\:-mx-64 {
		margin-left: -6.4rem;
		margin-right: -6.4rem;
	}

	.md\:-my-68 {
		margin-top: -6.8rem;
		margin-bottom: -6.8rem;
	}

	.md\:-mx-68 {
		margin-left: -6.8rem;
		margin-right: -6.8rem;
	}

	.md\:-my-72 {
		margin-top: -7.2rem;
		margin-bottom: -7.2rem;
	}

	.md\:-mx-72 {
		margin-left: -7.2rem;
		margin-right: -7.2rem;
	}

	.md\:-my-76 {
		margin-top: -7.6rem;
		margin-bottom: -7.6rem;
	}

	.md\:-mx-76 {
		margin-left: -7.6rem;
		margin-right: -7.6rem;
	}

	.md\:-my-80 {
		margin-top: -8rem;
		margin-bottom: -8rem;
	}

	.md\:-mx-80 {
		margin-left: -8rem;
		margin-right: -8rem;
	}

	.md\:-my-84 {
		margin-top: -8.4rem;
		margin-bottom: -8.4rem;
	}

	.md\:-mx-84 {
		margin-left: -8.4rem;
		margin-right: -8.4rem;
	}

	.md\:-my-88 {
		margin-top: -8.8rem;
		margin-bottom: -8.8rem;
	}

	.md\:-mx-88 {
		margin-left: -8.8rem;
		margin-right: -8.8rem;
	}

	.md\:-my-92 {
		margin-top: -9.2rem;
		margin-bottom: -9.2rem;
	}

	.md\:-mx-92 {
		margin-left: -9.2rem;
		margin-right: -9.2rem;
	}

	.md\:-my-96 {
		margin-top: -9.6rem;
		margin-bottom: -9.6rem;
	}

	.md\:-mx-96 {
		margin-left: -9.6rem;
		margin-right: -9.6rem;
	}

	.md\:-my-128 {
		margin-top: -12.8rem;
		margin-bottom: -12.8rem;
	}

	.md\:-mx-128 {
		margin-left: -12.8rem;
		margin-right: -12.8rem;
	}

	.md\:-my-136 {
		margin-top: -13.6rem;
		margin-bottom: -13.6rem;
	}

	.md\:-mx-136 {
		margin-left: -13.6rem;
		margin-right: -13.6rem;
	}

	.md\:-my-160 {
		margin-top: -16rem;
		margin-bottom: -16rem;
	}

	.md\:-mx-160 {
		margin-left: -16rem;
		margin-right: -16rem;
	}

	.md\:-my-192 {
		margin-top: -19.2rem;
		margin-bottom: -19.2rem;
	}

	.md\:-mx-192 {
		margin-left: -19.2rem;
		margin-right: -19.2rem;
	}

	.md\:-my-200 {
		margin-top: -20rem;
		margin-bottom: -20rem;
	}

	.md\:-mx-200 {
		margin-left: -20rem;
		margin-right: -20rem;
	}

	.md\:-my-208 {
		margin-top: -20.8rem;
		margin-bottom: -20.8rem;
	}

	.md\:-mx-208 {
		margin-left: -20.8rem;
		margin-right: -20.8rem;
	}

	.md\:-my-216 {
		margin-top: -21.6rem;
		margin-bottom: -21.6rem;
	}

	.md\:-mx-216 {
		margin-left: -21.6rem;
		margin-right: -21.6rem;
	}

	.md\:-my-224 {
		margin-top: -22.4rem;
		margin-bottom: -22.4rem;
	}

	.md\:-mx-224 {
		margin-left: -22.4rem;
		margin-right: -22.4rem;
	}

	.md\:-my-256 {
		margin-top: -25.6rem;
		margin-bottom: -25.6rem;
	}

	.md\:-mx-256 {
		margin-left: -25.6rem;
		margin-right: -25.6rem;
	}

	.md\:-my-288 {
		margin-top: -28.8rem;
		margin-bottom: -28.8rem;
	}

	.md\:-mx-288 {
		margin-left: -28.8rem;
		margin-right: -28.8rem;
	}

	.md\:-my-320 {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.md\:-mx-320 {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.md\:-my-360 {
		margin-top: -36rem;
		margin-bottom: -36rem;
	}

	.md\:-mx-360 {
		margin-left: -36rem;
		margin-right: -36rem;
	}

	.md\:-my-384 {
		margin-top: -38.4rem;
		margin-bottom: -38.4rem;
	}

	.md\:-mx-384 {
		margin-left: -38.4rem;
		margin-right: -38.4rem;
	}

	.md\:-my-400 {
		margin-top: -40rem;
		margin-bottom: -40rem;
	}

	.md\:-mx-400 {
		margin-left: -40rem;
		margin-right: -40rem;
	}

	.md\:-my-512 {
		margin-top: -51.2rem;
		margin-bottom: -51.2rem;
	}

	.md\:-mx-512 {
		margin-left: -51.2rem;
		margin-right: -51.2rem;
	}

	.md\:-my-640 {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.md\:-mx-640 {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.md\:-my-px {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	.md\:-mx-px {
		margin-left: -1px;
		margin-right: -1px;
	}

	.md\:-my-xs {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.md\:-mx-xs {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.md\:-my-sm {
		margin-top: -48rem;
		margin-bottom: -48rem;
	}

	.md\:-mx-sm {
		margin-left: -48rem;
		margin-right: -48rem;
	}

	.md\:-my-md {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.md\:-mx-md {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.md\:-my-lg {
		margin-top: -80rem;
		margin-bottom: -80rem;
	}

	.md\:-mx-lg {
		margin-left: -80rem;
		margin-right: -80rem;
	}

	.md\:-my-xl {
		margin-top: -96rem;
		margin-bottom: -96rem;
	}

	.md\:-mx-xl {
		margin-left: -96rem;
		margin-right: -96rem;
	}

	.md\:-my-2xl {
		margin-top: -112rem;
		margin-bottom: -112rem;
	}

	.md\:-mx-2xl {
		margin-left: -112rem;
		margin-right: -112rem;
	}

	.md\:-my-3xl {
		margin-top: -128rem;
		margin-bottom: -128rem;
	}

	.md\:-mx-3xl {
		margin-left: -128rem;
		margin-right: -128rem;
	}

	.md\:-my-4xl {
		margin-top: -144rem;
		margin-bottom: -144rem;
	}

	.md\:-mx-4xl {
		margin-left: -144rem;
		margin-right: -144rem;
	}

	.md\:-my-5xl {
		margin-top: -160rem;
		margin-bottom: -160rem;
	}

	.md\:-mx-5xl {
		margin-left: -160rem;
		margin-right: -160rem;
	}

	.md\:-my-auto {
		margin-top: -auto;
		margin-bottom: -auto;
	}

	.md\:-mx-auto {
		margin-left: -auto;
		margin-right: -auto;
	}

	.md\:mt-0 {
		margin-top: 0;
	}

	.md\:mr-0 {
		margin-right: 0;
	}

	.md\:mb-0 {
		margin-bottom: 0;
	}

	.md\:ml-0 {
		margin-left: 0;
	}

	.md\:mt-1 {
		margin-top: 0.25rem;
	}

	.md\:mr-1 {
		margin-right: 0.25rem;
	}

	.md\:mb-1 {
		margin-bottom: 0.25rem;
	}

	.md\:ml-1 {
		margin-left: 0.25rem;
	}

	.md\:mt-2 {
		margin-top: 0.5rem;
	}

	.md\:mr-2 {
		margin-right: 0.5rem;
	}

	.md\:mb-2 {
		margin-bottom: 0.5rem;
	}

	.md\:ml-2 {
		margin-left: 0.5rem;
	}

	.md\:mt-3 {
		margin-top: 0.75rem;
	}

	.md\:mr-3 {
		margin-right: 0.75rem;
	}

	.md\:mb-3 {
		margin-bottom: 0.75rem;
	}

	.md\:ml-3 {
		margin-left: 0.75rem;
	}

	.md\:mt-4 {
		margin-top: 0.4rem;
	}

	.md\:mr-4 {
		margin-right: 0.4rem;
	}

	.md\:mb-4 {
		margin-bottom: 0.4rem;
	}

	.md\:ml-4 {
		margin-left: 0.4rem;
	}

	.md\:mt-5 {
		margin-top: 1.25rem;
	}

	.md\:mr-5 {
		margin-right: 1.25rem;
	}

	.md\:mb-5 {
		margin-bottom: 1.25rem;
	}

	.md\:ml-5 {
		margin-left: 1.25rem;
	}

	.md\:mt-6 {
		margin-top: 0.6rem;
	}

	.md\:mr-6 {
		margin-right: 0.6rem;
	}

	.md\:mb-6 {
		margin-bottom: 0.6rem;
	}

	.md\:ml-6 {
		margin-left: 0.6rem;
	}

	.md\:mt-8 {
		margin-top: 0.8rem;
	}

	.md\:mr-8 {
		margin-right: 0.8rem;
	}

	.md\:mb-8 {
		margin-bottom: 0.8rem;
	}

	.md\:ml-8 {
		margin-left: 0.8rem;
	}

	.md\:mt-10 {
		margin-top: 2.5rem;
	}

	.md\:mr-10 {
		margin-right: 2.5rem;
	}

	.md\:mb-10 {
		margin-bottom: 2.5rem;
	}

	.md\:ml-10 {
		margin-left: 2.5rem;
	}

	.md\:mt-12 {
		margin-top: 1.2rem;
	}

	.md\:mr-12 {
		margin-right: 1.2rem;
	}

	.md\:mb-12 {
		margin-bottom: 1.2rem;
	}

	.md\:ml-12 {
		margin-left: 1.2rem;
	}

	.md\:mt-16 {
		margin-top: 1.6rem;
	}

	.md\:mr-16 {
		margin-right: 1.6rem;
	}

	.md\:mb-16 {
		margin-bottom: 1.6rem;
	}

	.md\:ml-16 {
		margin-left: 1.6rem;
	}

	.md\:mt-20 {
		margin-top: 2rem;
	}

	.md\:mr-20 {
		margin-right: 2rem;
	}

	.md\:mb-20 {
		margin-bottom: 2rem;
	}

	.md\:ml-20 {
		margin-left: 2rem;
	}

	.md\:mt-24 {
		margin-top: 2.4rem;
	}

	.md\:mr-24 {
		margin-right: 2.4rem;
	}

	.md\:mb-24 {
		margin-bottom: 2.4rem;
	}

	.md\:ml-24 {
		margin-left: 2.4rem;
	}

	.md\:mt-28 {
		margin-top: 2.8rem;
	}

	.md\:mr-28 {
		margin-right: 2.8rem;
	}

	.md\:mb-28 {
		margin-bottom: 2.8rem;
	}

	.md\:ml-28 {
		margin-left: 2.8rem;
	}

	.md\:mt-32 {
		margin-top: 3.2rem;
	}

	.md\:mr-32 {
		margin-right: 3.2rem;
	}

	.md\:mb-32 {
		margin-bottom: 3.2rem;
	}

	.md\:ml-32 {
		margin-left: 3.2rem;
	}

	.md\:mt-36 {
		margin-top: 3.6rem;
	}

	.md\:mr-36 {
		margin-right: 3.6rem;
	}

	.md\:mb-36 {
		margin-bottom: 3.6rem;
	}

	.md\:ml-36 {
		margin-left: 3.6rem;
	}

	.md\:mt-40 {
		margin-top: 4rem;
	}

	.md\:mr-40 {
		margin-right: 4rem;
	}

	.md\:mb-40 {
		margin-bottom: 4rem;
	}

	.md\:ml-40 {
		margin-left: 4rem;
	}

	.md\:mt-44 {
		margin-top: 4.4rem;
	}

	.md\:mr-44 {
		margin-right: 4.4rem;
	}

	.md\:mb-44 {
		margin-bottom: 4.4rem;
	}

	.md\:ml-44 {
		margin-left: 4.4rem;
	}

	.md\:mt-48 {
		margin-top: 4.8rem;
	}

	.md\:mr-48 {
		margin-right: 4.8rem;
	}

	.md\:mb-48 {
		margin-bottom: 4.8rem;
	}

	.md\:ml-48 {
		margin-left: 4.8rem;
	}

	.md\:mt-52 {
		margin-top: 5.2rem;
	}

	.md\:mr-52 {
		margin-right: 5.2rem;
	}

	.md\:mb-52 {
		margin-bottom: 5.2rem;
	}

	.md\:ml-52 {
		margin-left: 5.2rem;
	}

	.md\:mt-56 {
		margin-top: 5.6rem;
	}

	.md\:mr-56 {
		margin-right: 5.6rem;
	}

	.md\:mb-56 {
		margin-bottom: 5.6rem;
	}

	.md\:ml-56 {
		margin-left: 5.6rem;
	}

	.md\:mt-60 {
		margin-top: 6rem;
	}

	.md\:mr-60 {
		margin-right: 6rem;
	}

	.md\:mb-60 {
		margin-bottom: 6rem;
	}

	.md\:ml-60 {
		margin-left: 6rem;
	}

	.md\:mt-64 {
		margin-top: 6.4rem;
	}

	.md\:mr-64 {
		margin-right: 6.4rem;
	}

	.md\:mb-64 {
		margin-bottom: 6.4rem;
	}

	.md\:ml-64 {
		margin-left: 6.4rem;
	}

	.md\:mt-68 {
		margin-top: 6.8rem;
	}

	.md\:mr-68 {
		margin-right: 6.8rem;
	}

	.md\:mb-68 {
		margin-bottom: 6.8rem;
	}

	.md\:ml-68 {
		margin-left: 6.8rem;
	}

	.md\:mt-72 {
		margin-top: 7.2rem;
	}

	.md\:mr-72 {
		margin-right: 7.2rem;
	}

	.md\:mb-72 {
		margin-bottom: 7.2rem;
	}

	.md\:ml-72 {
		margin-left: 7.2rem;
	}

	.md\:mt-76 {
		margin-top: 7.6rem;
	}

	.md\:mr-76 {
		margin-right: 7.6rem;
	}

	.md\:mb-76 {
		margin-bottom: 7.6rem;
	}

	.md\:ml-76 {
		margin-left: 7.6rem;
	}

	.md\:mt-80 {
		margin-top: 8rem;
	}

	.md\:mr-80 {
		margin-right: 8rem;
	}

	.md\:mb-80 {
		margin-bottom: 8rem;
	}

	.md\:ml-80 {
		margin-left: 8rem;
	}

	.md\:mt-84 {
		margin-top: 8.4rem;
	}

	.md\:mr-84 {
		margin-right: 8.4rem;
	}

	.md\:mb-84 {
		margin-bottom: 8.4rem;
	}

	.md\:ml-84 {
		margin-left: 8.4rem;
	}

	.md\:mt-88 {
		margin-top: 8.8rem;
	}

	.md\:mr-88 {
		margin-right: 8.8rem;
	}

	.md\:mb-88 {
		margin-bottom: 8.8rem;
	}

	.md\:ml-88 {
		margin-left: 8.8rem;
	}

	.md\:mt-92 {
		margin-top: 9.2rem;
	}

	.md\:mr-92 {
		margin-right: 9.2rem;
	}

	.md\:mb-92 {
		margin-bottom: 9.2rem;
	}

	.md\:ml-92 {
		margin-left: 9.2rem;
	}

	.md\:mt-96 {
		margin-top: 9.6rem;
	}

	.md\:mr-96 {
		margin-right: 9.6rem;
	}

	.md\:mb-96 {
		margin-bottom: 9.6rem;
	}

	.md\:ml-96 {
		margin-left: 9.6rem;
	}

	.md\:mt-128 {
		margin-top: 12.8rem;
	}

	.md\:mr-128 {
		margin-right: 12.8rem;
	}

	.md\:mb-128 {
		margin-bottom: 12.8rem;
	}

	.md\:ml-128 {
		margin-left: 12.8rem;
	}

	.md\:mt-136 {
		margin-top: 13.6rem;
	}

	.md\:mr-136 {
		margin-right: 13.6rem;
	}

	.md\:mb-136 {
		margin-bottom: 13.6rem;
	}

	.md\:ml-136 {
		margin-left: 13.6rem;
	}

	.md\:mt-160 {
		margin-top: 16rem;
	}

	.md\:mr-160 {
		margin-right: 16rem;
	}

	.md\:mb-160 {
		margin-bottom: 16rem;
	}

	.md\:ml-160 {
		margin-left: 16rem;
	}

	.md\:mt-192 {
		margin-top: 19.2rem;
	}

	.md\:mr-192 {
		margin-right: 19.2rem;
	}

	.md\:mb-192 {
		margin-bottom: 19.2rem;
	}

	.md\:ml-192 {
		margin-left: 19.2rem;
	}

	.md\:mt-200 {
		margin-top: 20rem;
	}

	.md\:mr-200 {
		margin-right: 20rem;
	}

	.md\:mb-200 {
		margin-bottom: 20rem;
	}

	.md\:ml-200 {
		margin-left: 20rem;
	}

	.md\:mt-208 {
		margin-top: 20.8rem;
	}

	.md\:mr-208 {
		margin-right: 20.8rem;
	}

	.md\:mb-208 {
		margin-bottom: 20.8rem;
	}

	.md\:ml-208 {
		margin-left: 20.8rem;
	}

	.md\:mt-216 {
		margin-top: 21.6rem;
	}

	.md\:mr-216 {
		margin-right: 21.6rem;
	}

	.md\:mb-216 {
		margin-bottom: 21.6rem;
	}

	.md\:ml-216 {
		margin-left: 21.6rem;
	}

	.md\:mt-224 {
		margin-top: 22.4rem;
	}

	.md\:mr-224 {
		margin-right: 22.4rem;
	}

	.md\:mb-224 {
		margin-bottom: 22.4rem;
	}

	.md\:ml-224 {
		margin-left: 22.4rem;
	}

	.md\:mt-256 {
		margin-top: 25.6rem;
	}

	.md\:mr-256 {
		margin-right: 25.6rem;
	}

	.md\:mb-256 {
		margin-bottom: 25.6rem;
	}

	.md\:ml-256 {
		margin-left: 25.6rem;
	}

	.md\:mt-288 {
		margin-top: 28.8rem;
	}

	.md\:mr-288 {
		margin-right: 28.8rem;
	}

	.md\:mb-288 {
		margin-bottom: 28.8rem;
	}

	.md\:ml-288 {
		margin-left: 28.8rem;
	}

	.md\:mt-320 {
		margin-top: 32rem;
	}

	.md\:mr-320 {
		margin-right: 32rem;
	}

	.md\:mb-320 {
		margin-bottom: 32rem;
	}

	.md\:ml-320 {
		margin-left: 32rem;
	}

	.md\:mt-360 {
		margin-top: 36rem;
	}

	.md\:mr-360 {
		margin-right: 36rem;
	}

	.md\:mb-360 {
		margin-bottom: 36rem;
	}

	.md\:ml-360 {
		margin-left: 36rem;
	}

	.md\:mt-384 {
		margin-top: 38.4rem;
	}

	.md\:mr-384 {
		margin-right: 38.4rem;
	}

	.md\:mb-384 {
		margin-bottom: 38.4rem;
	}

	.md\:ml-384 {
		margin-left: 38.4rem;
	}

	.md\:mt-400 {
		margin-top: 40rem;
	}

	.md\:mr-400 {
		margin-right: 40rem;
	}

	.md\:mb-400 {
		margin-bottom: 40rem;
	}

	.md\:ml-400 {
		margin-left: 40rem;
	}

	.md\:mt-512 {
		margin-top: 51.2rem;
	}

	.md\:mr-512 {
		margin-right: 51.2rem;
	}

	.md\:mb-512 {
		margin-bottom: 51.2rem;
	}

	.md\:ml-512 {
		margin-left: 51.2rem;
	}

	.md\:mt-640 {
		margin-top: 64rem;
	}

	.md\:mr-640 {
		margin-right: 64rem;
	}

	.md\:mb-640 {
		margin-bottom: 64rem;
	}

	.md\:ml-640 {
		margin-left: 64rem;
	}

	.md\:mt-auto {
		margin-top: auto;
	}

	.md\:mr-auto {
		margin-right: auto;
	}

	.md\:mb-auto {
		margin-bottom: auto;
	}

	.md\:ml-auto {
		margin-left: auto;
	}

	.md\:mt-px {
		margin-top: 1px;
	}

	.md\:mr-px {
		margin-right: 1px;
	}

	.md\:mb-px {
		margin-bottom: 1px;
	}

	.md\:ml-px {
		margin-left: 1px;
	}

	.md\:mt-xs {
		margin-top: 32rem;
	}

	.md\:mr-xs {
		margin-right: 32rem;
	}

	.md\:mb-xs {
		margin-bottom: 32rem;
	}

	.md\:ml-xs {
		margin-left: 32rem;
	}

	.md\:mt-sm {
		margin-top: 48rem;
	}

	.md\:mr-sm {
		margin-right: 48rem;
	}

	.md\:mb-sm {
		margin-bottom: 48rem;
	}

	.md\:ml-sm {
		margin-left: 48rem;
	}

	.md\:mt-md {
		margin-top: 64rem;
	}

	.md\:mr-md {
		margin-right: 64rem;
	}

	.md\:mb-md {
		margin-bottom: 64rem;
	}

	.md\:ml-md {
		margin-left: 64rem;
	}

	.md\:mt-lg {
		margin-top: 80rem;
	}

	.md\:mr-lg {
		margin-right: 80rem;
	}

	.md\:mb-lg {
		margin-bottom: 80rem;
	}

	.md\:ml-lg {
		margin-left: 80rem;
	}

	.md\:mt-xl {
		margin-top: 96rem;
	}

	.md\:mr-xl {
		margin-right: 96rem;
	}

	.md\:mb-xl {
		margin-bottom: 96rem;
	}

	.md\:ml-xl {
		margin-left: 96rem;
	}

	.md\:mt-2xl {
		margin-top: 112rem;
	}

	.md\:mr-2xl {
		margin-right: 112rem;
	}

	.md\:mb-2xl {
		margin-bottom: 112rem;
	}

	.md\:ml-2xl {
		margin-left: 112rem;
	}

	.md\:mt-3xl {
		margin-top: 128rem;
	}

	.md\:mr-3xl {
		margin-right: 128rem;
	}

	.md\:mb-3xl {
		margin-bottom: 128rem;
	}

	.md\:ml-3xl {
		margin-left: 128rem;
	}

	.md\:mt-4xl {
		margin-top: 144rem;
	}

	.md\:mr-4xl {
		margin-right: 144rem;
	}

	.md\:mb-4xl {
		margin-bottom: 144rem;
	}

	.md\:ml-4xl {
		margin-left: 144rem;
	}

	.md\:mt-5xl {
		margin-top: 160rem;
	}

	.md\:mr-5xl {
		margin-right: 160rem;
	}

	.md\:mb-5xl {
		margin-bottom: 160rem;
	}

	.md\:ml-5xl {
		margin-left: 160rem;
	}

	.md\:-mt-1 {
		margin-top: -0.25rem;
	}

	.md\:-mr-1 {
		margin-right: -0.25rem;
	}

	.md\:-mb-1 {
		margin-bottom: -0.25rem;
	}

	.md\:-ml-1 {
		margin-left: -0.25rem;
	}

	.md\:-mt-2 {
		margin-top: -0.5rem;
	}

	.md\:-mr-2 {
		margin-right: -0.5rem;
	}

	.md\:-mb-2 {
		margin-bottom: -0.5rem;
	}

	.md\:-ml-2 {
		margin-left: -0.5rem;
	}

	.md\:-mt-3 {
		margin-top: -0.75rem;
	}

	.md\:-mr-3 {
		margin-right: -0.75rem;
	}

	.md\:-mb-3 {
		margin-bottom: -0.75rem;
	}

	.md\:-ml-3 {
		margin-left: -0.75rem;
	}

	.md\:-mt-4 {
		margin-top: -0.4rem;
	}

	.md\:-mr-4 {
		margin-right: -0.4rem;
	}

	.md\:-mb-4 {
		margin-bottom: -0.4rem;
	}

	.md\:-ml-4 {
		margin-left: -0.4rem;
	}

	.md\:-mt-5 {
		margin-top: -1.25rem;
	}

	.md\:-mr-5 {
		margin-right: -1.25rem;
	}

	.md\:-mb-5 {
		margin-bottom: -1.25rem;
	}

	.md\:-ml-5 {
		margin-left: -1.25rem;
	}

	.md\:-mt-6 {
		margin-top: -0.6rem;
	}

	.md\:-mr-6 {
		margin-right: -0.6rem;
	}

	.md\:-mb-6 {
		margin-bottom: -0.6rem;
	}

	.md\:-ml-6 {
		margin-left: -0.6rem;
	}

	.md\:-mt-8 {
		margin-top: -0.8rem;
	}

	.md\:-mr-8 {
		margin-right: -0.8rem;
	}

	.md\:-mb-8 {
		margin-bottom: -0.8rem;
	}

	.md\:-ml-8 {
		margin-left: -0.8rem;
	}

	.md\:-mt-10 {
		margin-top: -2.5rem;
	}

	.md\:-mr-10 {
		margin-right: -2.5rem;
	}

	.md\:-mb-10 {
		margin-bottom: -2.5rem;
	}

	.md\:-ml-10 {
		margin-left: -2.5rem;
	}

	.md\:-mt-12 {
		margin-top: -1.2rem;
	}

	.md\:-mr-12 {
		margin-right: -1.2rem;
	}

	.md\:-mb-12 {
		margin-bottom: -1.2rem;
	}

	.md\:-ml-12 {
		margin-left: -1.2rem;
	}

	.md\:-mt-16 {
		margin-top: -1.6rem;
	}

	.md\:-mr-16 {
		margin-right: -1.6rem;
	}

	.md\:-mb-16 {
		margin-bottom: -1.6rem;
	}

	.md\:-ml-16 {
		margin-left: -1.6rem;
	}

	.md\:-mt-20 {
		margin-top: -2rem;
	}

	.md\:-mr-20 {
		margin-right: -2rem;
	}

	.md\:-mb-20 {
		margin-bottom: -2rem;
	}

	.md\:-ml-20 {
		margin-left: -2rem;
	}

	.md\:-mt-24 {
		margin-top: -2.4rem;
	}

	.md\:-mr-24 {
		margin-right: -2.4rem;
	}

	.md\:-mb-24 {
		margin-bottom: -2.4rem;
	}

	.md\:-ml-24 {
		margin-left: -2.4rem;
	}

	.md\:-mt-28 {
		margin-top: -2.8rem;
	}

	.md\:-mr-28 {
		margin-right: -2.8rem;
	}

	.md\:-mb-28 {
		margin-bottom: -2.8rem;
	}

	.md\:-ml-28 {
		margin-left: -2.8rem;
	}

	.md\:-mt-32 {
		margin-top: -3.2rem;
	}

	.md\:-mr-32 {
		margin-right: -3.2rem;
	}

	.md\:-mb-32 {
		margin-bottom: -3.2rem;
	}

	.md\:-ml-32 {
		margin-left: -3.2rem;
	}

	.md\:-mt-36 {
		margin-top: -3.6rem;
	}

	.md\:-mr-36 {
		margin-right: -3.6rem;
	}

	.md\:-mb-36 {
		margin-bottom: -3.6rem;
	}

	.md\:-ml-36 {
		margin-left: -3.6rem;
	}

	.md\:-mt-40 {
		margin-top: -4rem;
	}

	.md\:-mr-40 {
		margin-right: -4rem;
	}

	.md\:-mb-40 {
		margin-bottom: -4rem;
	}

	.md\:-ml-40 {
		margin-left: -4rem;
	}

	.md\:-mt-44 {
		margin-top: -4.4rem;
	}

	.md\:-mr-44 {
		margin-right: -4.4rem;
	}

	.md\:-mb-44 {
		margin-bottom: -4.4rem;
	}

	.md\:-ml-44 {
		margin-left: -4.4rem;
	}

	.md\:-mt-48 {
		margin-top: -4.8rem;
	}

	.md\:-mr-48 {
		margin-right: -4.8rem;
	}

	.md\:-mb-48 {
		margin-bottom: -4.8rem;
	}

	.md\:-ml-48 {
		margin-left: -4.8rem;
	}

	.md\:-mt-52 {
		margin-top: -5.2rem;
	}

	.md\:-mr-52 {
		margin-right: -5.2rem;
	}

	.md\:-mb-52 {
		margin-bottom: -5.2rem;
	}

	.md\:-ml-52 {
		margin-left: -5.2rem;
	}

	.md\:-mt-56 {
		margin-top: -5.6rem;
	}

	.md\:-mr-56 {
		margin-right: -5.6rem;
	}

	.md\:-mb-56 {
		margin-bottom: -5.6rem;
	}

	.md\:-ml-56 {
		margin-left: -5.6rem;
	}

	.md\:-mt-60 {
		margin-top: -6rem;
	}

	.md\:-mr-60 {
		margin-right: -6rem;
	}

	.md\:-mb-60 {
		margin-bottom: -6rem;
	}

	.md\:-ml-60 {
		margin-left: -6rem;
	}

	.md\:-mt-64 {
		margin-top: -6.4rem;
	}

	.md\:-mr-64 {
		margin-right: -6.4rem;
	}

	.md\:-mb-64 {
		margin-bottom: -6.4rem;
	}

	.md\:-ml-64 {
		margin-left: -6.4rem;
	}

	.md\:-mt-68 {
		margin-top: -6.8rem;
	}

	.md\:-mr-68 {
		margin-right: -6.8rem;
	}

	.md\:-mb-68 {
		margin-bottom: -6.8rem;
	}

	.md\:-ml-68 {
		margin-left: -6.8rem;
	}

	.md\:-mt-72 {
		margin-top: -7.2rem;
	}

	.md\:-mr-72 {
		margin-right: -7.2rem;
	}

	.md\:-mb-72 {
		margin-bottom: -7.2rem;
	}

	.md\:-ml-72 {
		margin-left: -7.2rem;
	}

	.md\:-mt-76 {
		margin-top: -7.6rem;
	}

	.md\:-mr-76 {
		margin-right: -7.6rem;
	}

	.md\:-mb-76 {
		margin-bottom: -7.6rem;
	}

	.md\:-ml-76 {
		margin-left: -7.6rem;
	}

	.md\:-mt-80 {
		margin-top: -8rem;
	}

	.md\:-mr-80 {
		margin-right: -8rem;
	}

	.md\:-mb-80 {
		margin-bottom: -8rem;
	}

	.md\:-ml-80 {
		margin-left: -8rem;
	}

	.md\:-mt-84 {
		margin-top: -8.4rem;
	}

	.md\:-mr-84 {
		margin-right: -8.4rem;
	}

	.md\:-mb-84 {
		margin-bottom: -8.4rem;
	}

	.md\:-ml-84 {
		margin-left: -8.4rem;
	}

	.md\:-mt-88 {
		margin-top: -8.8rem;
	}

	.md\:-mr-88 {
		margin-right: -8.8rem;
	}

	.md\:-mb-88 {
		margin-bottom: -8.8rem;
	}

	.md\:-ml-88 {
		margin-left: -8.8rem;
	}

	.md\:-mt-92 {
		margin-top: -9.2rem;
	}

	.md\:-mr-92 {
		margin-right: -9.2rem;
	}

	.md\:-mb-92 {
		margin-bottom: -9.2rem;
	}

	.md\:-ml-92 {
		margin-left: -9.2rem;
	}

	.md\:-mt-96 {
		margin-top: -9.6rem;
	}

	.md\:-mr-96 {
		margin-right: -9.6rem;
	}

	.md\:-mb-96 {
		margin-bottom: -9.6rem;
	}

	.md\:-ml-96 {
		margin-left: -9.6rem;
	}

	.md\:-mt-128 {
		margin-top: -12.8rem;
	}

	.md\:-mr-128 {
		margin-right: -12.8rem;
	}

	.md\:-mb-128 {
		margin-bottom: -12.8rem;
	}

	.md\:-ml-128 {
		margin-left: -12.8rem;
	}

	.md\:-mt-136 {
		margin-top: -13.6rem;
	}

	.md\:-mr-136 {
		margin-right: -13.6rem;
	}

	.md\:-mb-136 {
		margin-bottom: -13.6rem;
	}

	.md\:-ml-136 {
		margin-left: -13.6rem;
	}

	.md\:-mt-160 {
		margin-top: -16rem;
	}

	.md\:-mr-160 {
		margin-right: -16rem;
	}

	.md\:-mb-160 {
		margin-bottom: -16rem;
	}

	.md\:-ml-160 {
		margin-left: -16rem;
	}

	.md\:-mt-192 {
		margin-top: -19.2rem;
	}

	.md\:-mr-192 {
		margin-right: -19.2rem;
	}

	.md\:-mb-192 {
		margin-bottom: -19.2rem;
	}

	.md\:-ml-192 {
		margin-left: -19.2rem;
	}

	.md\:-mt-200 {
		margin-top: -20rem;
	}

	.md\:-mr-200 {
		margin-right: -20rem;
	}

	.md\:-mb-200 {
		margin-bottom: -20rem;
	}

	.md\:-ml-200 {
		margin-left: -20rem;
	}

	.md\:-mt-208 {
		margin-top: -20.8rem;
	}

	.md\:-mr-208 {
		margin-right: -20.8rem;
	}

	.md\:-mb-208 {
		margin-bottom: -20.8rem;
	}

	.md\:-ml-208 {
		margin-left: -20.8rem;
	}

	.md\:-mt-216 {
		margin-top: -21.6rem;
	}

	.md\:-mr-216 {
		margin-right: -21.6rem;
	}

	.md\:-mb-216 {
		margin-bottom: -21.6rem;
	}

	.md\:-ml-216 {
		margin-left: -21.6rem;
	}

	.md\:-mt-224 {
		margin-top: -22.4rem;
	}

	.md\:-mr-224 {
		margin-right: -22.4rem;
	}

	.md\:-mb-224 {
		margin-bottom: -22.4rem;
	}

	.md\:-ml-224 {
		margin-left: -22.4rem;
	}

	.md\:-mt-256 {
		margin-top: -25.6rem;
	}

	.md\:-mr-256 {
		margin-right: -25.6rem;
	}

	.md\:-mb-256 {
		margin-bottom: -25.6rem;
	}

	.md\:-ml-256 {
		margin-left: -25.6rem;
	}

	.md\:-mt-288 {
		margin-top: -28.8rem;
	}

	.md\:-mr-288 {
		margin-right: -28.8rem;
	}

	.md\:-mb-288 {
		margin-bottom: -28.8rem;
	}

	.md\:-ml-288 {
		margin-left: -28.8rem;
	}

	.md\:-mt-320 {
		margin-top: -32rem;
	}

	.md\:-mr-320 {
		margin-right: -32rem;
	}

	.md\:-mb-320 {
		margin-bottom: -32rem;
	}

	.md\:-ml-320 {
		margin-left: -32rem;
	}

	.md\:-mt-360 {
		margin-top: -36rem;
	}

	.md\:-mr-360 {
		margin-right: -36rem;
	}

	.md\:-mb-360 {
		margin-bottom: -36rem;
	}

	.md\:-ml-360 {
		margin-left: -36rem;
	}

	.md\:-mt-384 {
		margin-top: -38.4rem;
	}

	.md\:-mr-384 {
		margin-right: -38.4rem;
	}

	.md\:-mb-384 {
		margin-bottom: -38.4rem;
	}

	.md\:-ml-384 {
		margin-left: -38.4rem;
	}

	.md\:-mt-400 {
		margin-top: -40rem;
	}

	.md\:-mr-400 {
		margin-right: -40rem;
	}

	.md\:-mb-400 {
		margin-bottom: -40rem;
	}

	.md\:-ml-400 {
		margin-left: -40rem;
	}

	.md\:-mt-512 {
		margin-top: -51.2rem;
	}

	.md\:-mr-512 {
		margin-right: -51.2rem;
	}

	.md\:-mb-512 {
		margin-bottom: -51.2rem;
	}

	.md\:-ml-512 {
		margin-left: -51.2rem;
	}

	.md\:-mt-640 {
		margin-top: -64rem;
	}

	.md\:-mr-640 {
		margin-right: -64rem;
	}

	.md\:-mb-640 {
		margin-bottom: -64rem;
	}

	.md\:-ml-640 {
		margin-left: -64rem;
	}

	.md\:-mt-px {
		margin-top: -1px;
	}

	.md\:-mr-px {
		margin-right: -1px;
	}

	.md\:-mb-px {
		margin-bottom: -1px;
	}

	.md\:-ml-px {
		margin-left: -1px;
	}

	.md\:-mt-xs {
		margin-top: -32rem;
	}

	.md\:-mr-xs {
		margin-right: -32rem;
	}

	.md\:-mb-xs {
		margin-bottom: -32rem;
	}

	.md\:-ml-xs {
		margin-left: -32rem;
	}

	.md\:-mt-sm {
		margin-top: -48rem;
	}

	.md\:-mr-sm {
		margin-right: -48rem;
	}

	.md\:-mb-sm {
		margin-bottom: -48rem;
	}

	.md\:-ml-sm {
		margin-left: -48rem;
	}

	.md\:-mt-md {
		margin-top: -64rem;
	}

	.md\:-mr-md {
		margin-right: -64rem;
	}

	.md\:-mb-md {
		margin-bottom: -64rem;
	}

	.md\:-ml-md {
		margin-left: -64rem;
	}

	.md\:-mt-lg {
		margin-top: -80rem;
	}

	.md\:-mr-lg {
		margin-right: -80rem;
	}

	.md\:-mb-lg {
		margin-bottom: -80rem;
	}

	.md\:-ml-lg {
		margin-left: -80rem;
	}

	.md\:-mt-xl {
		margin-top: -96rem;
	}

	.md\:-mr-xl {
		margin-right: -96rem;
	}

	.md\:-mb-xl {
		margin-bottom: -96rem;
	}

	.md\:-ml-xl {
		margin-left: -96rem;
	}

	.md\:-mt-2xl {
		margin-top: -112rem;
	}

	.md\:-mr-2xl {
		margin-right: -112rem;
	}

	.md\:-mb-2xl {
		margin-bottom: -112rem;
	}

	.md\:-ml-2xl {
		margin-left: -112rem;
	}

	.md\:-mt-3xl {
		margin-top: -128rem;
	}

	.md\:-mr-3xl {
		margin-right: -128rem;
	}

	.md\:-mb-3xl {
		margin-bottom: -128rem;
	}

	.md\:-ml-3xl {
		margin-left: -128rem;
	}

	.md\:-mt-4xl {
		margin-top: -144rem;
	}

	.md\:-mr-4xl {
		margin-right: -144rem;
	}

	.md\:-mb-4xl {
		margin-bottom: -144rem;
	}

	.md\:-ml-4xl {
		margin-left: -144rem;
	}

	.md\:-mt-5xl {
		margin-top: -160rem;
	}

	.md\:-mr-5xl {
		margin-right: -160rem;
	}

	.md\:-mb-5xl {
		margin-bottom: -160rem;
	}

	.md\:-ml-5xl {
		margin-left: -160rem;
	}

	.md\:-mt-auto {
		margin-top: -auto;
	}

	.md\:-mr-auto {
		margin-right: -auto;
	}

	.md\:-mb-auto {
		margin-bottom: -auto;
	}

	.md\:-ml-auto {
		margin-left: -auto;
	}

	.md\:max-h-0 {
		max-height: 0;
	}

	.md\:max-h-1 {
		max-height: 0.25rem;
	}

	.md\:max-h-2 {
		max-height: 0.5rem;
	}

	.md\:max-h-3 {
		max-height: 0.75rem;
	}

	.md\:max-h-4 {
		max-height: 0.4rem;
	}

	.md\:max-h-5 {
		max-height: 1.25rem;
	}

	.md\:max-h-6 {
		max-height: 0.6rem;
	}

	.md\:max-h-8 {
		max-height: 0.8rem;
	}

	.md\:max-h-10 {
		max-height: 2.5rem;
	}

	.md\:max-h-12 {
		max-height: 1.2rem;
	}

	.md\:max-h-16 {
		max-height: 1.6rem;
	}

	.md\:max-h-20 {
		max-height: 2rem;
	}

	.md\:max-h-24 {
		max-height: 2.4rem;
	}

	.md\:max-h-28 {
		max-height: 2.8rem;
	}

	.md\:max-h-32 {
		max-height: 3.2rem;
	}

	.md\:max-h-36 {
		max-height: 3.6rem;
	}

	.md\:max-h-40 {
		max-height: 4rem;
	}

	.md\:max-h-44 {
		max-height: 4.4rem;
	}

	.md\:max-h-48 {
		max-height: 4.8rem;
	}

	.md\:max-h-52 {
		max-height: 5.2rem;
	}

	.md\:max-h-56 {
		max-height: 5.6rem;
	}

	.md\:max-h-60 {
		max-height: 6rem;
	}

	.md\:max-h-64 {
		max-height: 6.4rem;
	}

	.md\:max-h-68 {
		max-height: 6.8rem;
	}

	.md\:max-h-72 {
		max-height: 7.2rem;
	}

	.md\:max-h-76 {
		max-height: 7.6rem;
	}

	.md\:max-h-80 {
		max-height: 8rem;
	}

	.md\:max-h-84 {
		max-height: 8.4rem;
	}

	.md\:max-h-88 {
		max-height: 8.8rem;
	}

	.md\:max-h-92 {
		max-height: 9.2rem;
	}

	.md\:max-h-96 {
		max-height: 9.6rem;
	}

	.md\:max-h-128 {
		max-height: 12.8rem;
	}

	.md\:max-h-136 {
		max-height: 13.6rem;
	}

	.md\:max-h-160 {
		max-height: 16rem;
	}

	.md\:max-h-192 {
		max-height: 19.2rem;
	}

	.md\:max-h-200 {
		max-height: 20rem;
	}

	.md\:max-h-208 {
		max-height: 20.8rem;
	}

	.md\:max-h-216 {
		max-height: 21.6rem;
	}

	.md\:max-h-224 {
		max-height: 22.4rem;
	}

	.md\:max-h-256 {
		max-height: 25.6rem;
	}

	.md\:max-h-288 {
		max-height: 28.8rem;
	}

	.md\:max-h-320 {
		max-height: 32rem;
	}

	.md\:max-h-360 {
		max-height: 36rem;
	}

	.md\:max-h-384 {
		max-height: 38.4rem;
	}

	.md\:max-h-400 {
		max-height: 40rem;
	}

	.md\:max-h-512 {
		max-height: 51.2rem;
	}

	.md\:max-h-640 {
		max-height: 64rem;
	}

	.md\:max-h-full {
		max-height: 100%;
	}

	.md\:max-h-screen {
		max-height: 100vh;
	}

	.md\:max-h-auto {
		max-height: auto;
	}

	.md\:max-h-px {
		max-height: 1px;
	}

	.md\:max-h-xs {
		max-height: 32rem;
	}

	.md\:max-h-sm {
		max-height: 48rem;
	}

	.md\:max-h-md {
		max-height: 64rem;
	}

	.md\:max-h-lg {
		max-height: 80rem;
	}

	.md\:max-h-xl {
		max-height: 96rem;
	}

	.md\:max-h-2xl {
		max-height: 112rem;
	}

	.md\:max-h-3xl {
		max-height: 128rem;
	}

	.md\:max-h-4xl {
		max-height: 144rem;
	}

	.md\:max-h-5xl {
		max-height: 160rem;
	}

	.md\:max-w-0 {
		max-width: 0;
	}

	.md\:max-w-1 {
		max-width: 0.25rem;
	}

	.md\:max-w-2 {
		max-width: 0.5rem;
	}

	.md\:max-w-3 {
		max-width: 0.75rem;
	}

	.md\:max-w-4 {
		max-width: 0.4rem;
	}

	.md\:max-w-5 {
		max-width: 1.25rem;
	}

	.md\:max-w-6 {
		max-width: 0.6rem;
	}

	.md\:max-w-8 {
		max-width: 0.8rem;
	}

	.md\:max-w-10 {
		max-width: 2.5rem;
	}

	.md\:max-w-12 {
		max-width: 1.2rem;
	}

	.md\:max-w-16 {
		max-width: 1.6rem;
	}

	.md\:max-w-20 {
		max-width: 2rem;
	}

	.md\:max-w-24 {
		max-width: 2.4rem;
	}

	.md\:max-w-28 {
		max-width: 2.8rem;
	}

	.md\:max-w-32 {
		max-width: 3.2rem;
	}

	.md\:max-w-36 {
		max-width: 3.6rem;
	}

	.md\:max-w-40 {
		max-width: 4rem;
	}

	.md\:max-w-44 {
		max-width: 4.4rem;
	}

	.md\:max-w-48 {
		max-width: 4.8rem;
	}

	.md\:max-w-52 {
		max-width: 5.2rem;
	}

	.md\:max-w-56 {
		max-width: 5.6rem;
	}

	.md\:max-w-60 {
		max-width: 6rem;
	}

	.md\:max-w-64 {
		max-width: 6.4rem;
	}

	.md\:max-w-68 {
		max-width: 6.8rem;
	}

	.md\:max-w-72 {
		max-width: 7.2rem;
	}

	.md\:max-w-76 {
		max-width: 7.6rem;
	}

	.md\:max-w-80 {
		max-width: 8rem;
	}

	.md\:max-w-84 {
		max-width: 8.4rem;
	}

	.md\:max-w-88 {
		max-width: 8.8rem;
	}

	.md\:max-w-92 {
		max-width: 9.2rem;
	}

	.md\:max-w-96 {
		max-width: 9.6rem;
	}

	.md\:max-w-128 {
		max-width: 12.8rem;
	}

	.md\:max-w-136 {
		max-width: 13.6rem;
	}

	.md\:max-w-160 {
		max-width: 16rem;
	}

	.md\:max-w-192 {
		max-width: 19.2rem;
	}

	.md\:max-w-200 {
		max-width: 20rem;
	}

	.md\:max-w-208 {
		max-width: 20.8rem;
	}

	.md\:max-w-216 {
		max-width: 21.6rem;
	}

	.md\:max-w-224 {
		max-width: 22.4rem;
	}

	.md\:max-w-256 {
		max-width: 25.6rem;
	}

	.md\:max-w-288 {
		max-width: 28.8rem;
	}

	.md\:max-w-320 {
		max-width: 32rem;
	}

	.md\:max-w-360 {
		max-width: 36rem;
	}

	.md\:max-w-384 {
		max-width: 38.4rem;
	}

	.md\:max-w-400 {
		max-width: 40rem;
	}

	.md\:max-w-512 {
		max-width: 51.2rem;
	}

	.md\:max-w-640 {
		max-width: 64rem;
	}

	.md\:max-w-xs {
		max-width: 32rem;
	}

	.md\:max-w-sm {
		max-width: 48rem;
	}

	.md\:max-w-md {
		max-width: 64rem;
	}

	.md\:max-w-lg {
		max-width: 80rem;
	}

	.md\:max-w-xl {
		max-width: 96rem;
	}

	.md\:max-w-2xl {
		max-width: 112rem;
	}

	.md\:max-w-3xl {
		max-width: 128rem;
	}

	.md\:max-w-4xl {
		max-width: 144rem;
	}

	.md\:max-w-5xl {
		max-width: 160rem;
	}

	.md\:max-w-6xl {
		max-width: 72rem;
	}

	.md\:max-w-full {
		max-width: 100%;
	}

	.md\:max-w-px {
		max-width: 1px;
	}

	.md\:max-w-auto {
		max-width: auto;
	}

	.md\:max-w-screen {
		max-width: 100vw;
	}

	.md\:min-h-0 {
		min-height: 0;
	}

	.md\:min-h-1 {
		min-height: 0.25rem;
	}

	.md\:min-h-2 {
		min-height: 0.5rem;
	}

	.md\:min-h-3 {
		min-height: 0.75rem;
	}

	.md\:min-h-4 {
		min-height: 0.4rem;
	}

	.md\:min-h-5 {
		min-height: 1.25rem;
	}

	.md\:min-h-6 {
		min-height: 0.6rem;
	}

	.md\:min-h-8 {
		min-height: 0.8rem;
	}

	.md\:min-h-10 {
		min-height: 2.5rem;
	}

	.md\:min-h-12 {
		min-height: 1.2rem;
	}

	.md\:min-h-16 {
		min-height: 1.6rem;
	}

	.md\:min-h-20 {
		min-height: 2rem;
	}

	.md\:min-h-24 {
		min-height: 2.4rem;
	}

	.md\:min-h-28 {
		min-height: 2.8rem;
	}

	.md\:min-h-32 {
		min-height: 3.2rem;
	}

	.md\:min-h-36 {
		min-height: 3.6rem;
	}

	.md\:min-h-40 {
		min-height: 4rem;
	}

	.md\:min-h-44 {
		min-height: 4.4rem;
	}

	.md\:min-h-48 {
		min-height: 4.8rem;
	}

	.md\:min-h-52 {
		min-height: 5.2rem;
	}

	.md\:min-h-56 {
		min-height: 5.6rem;
	}

	.md\:min-h-60 {
		min-height: 6rem;
	}

	.md\:min-h-64 {
		min-height: 6.4rem;
	}

	.md\:min-h-68 {
		min-height: 6.8rem;
	}

	.md\:min-h-72 {
		min-height: 7.2rem;
	}

	.md\:min-h-76 {
		min-height: 7.6rem;
	}

	.md\:min-h-80 {
		min-height: 8rem;
	}

	.md\:min-h-84 {
		min-height: 8.4rem;
	}

	.md\:min-h-88 {
		min-height: 8.8rem;
	}

	.md\:min-h-92 {
		min-height: 9.2rem;
	}

	.md\:min-h-96 {
		min-height: 9.6rem;
	}

	.md\:min-h-128 {
		min-height: 12.8rem;
	}

	.md\:min-h-136 {
		min-height: 13.6rem;
	}

	.md\:min-h-160 {
		min-height: 16rem;
	}

	.md\:min-h-192 {
		min-height: 19.2rem;
	}

	.md\:min-h-200 {
		min-height: 20rem;
	}

	.md\:min-h-208 {
		min-height: 20.8rem;
	}

	.md\:min-h-216 {
		min-height: 21.6rem;
	}

	.md\:min-h-224 {
		min-height: 22.4rem;
	}

	.md\:min-h-256 {
		min-height: 25.6rem;
	}

	.md\:min-h-288 {
		min-height: 28.8rem;
	}

	.md\:min-h-320 {
		min-height: 32rem;
	}

	.md\:min-h-360 {
		min-height: 36rem;
	}

	.md\:min-h-384 {
		min-height: 38.4rem;
	}

	.md\:min-h-400 {
		min-height: 40rem;
	}

	.md\:min-h-512 {
		min-height: 51.2rem;
	}

	.md\:min-h-640 {
		min-height: 64rem;
	}

	.md\:min-h-full {
		min-height: 100%;
	}

	.md\:min-h-screen {
		min-height: 100vh;
	}

	.md\:min-h-auto {
		min-height: auto;
	}

	.md\:min-h-px {
		min-height: 1px;
	}

	.md\:min-h-xs {
		min-height: 32rem;
	}

	.md\:min-h-sm {
		min-height: 48rem;
	}

	.md\:min-h-md {
		min-height: 64rem;
	}

	.md\:min-h-lg {
		min-height: 80rem;
	}

	.md\:min-h-xl {
		min-height: 96rem;
	}

	.md\:min-h-2xl {
		min-height: 112rem;
	}

	.md\:min-h-3xl {
		min-height: 128rem;
	}

	.md\:min-h-4xl {
		min-height: 144rem;
	}

	.md\:min-h-5xl {
		min-height: 160rem;
	}

	.md\:min-w-0 {
		min-width: 0;
	}

	.md\:min-w-1 {
		min-width: 0.25rem;
	}

	.md\:min-w-2 {
		min-width: 0.5rem;
	}

	.md\:min-w-3 {
		min-width: 0.75rem;
	}

	.md\:min-w-4 {
		min-width: 0.4rem;
	}

	.md\:min-w-5 {
		min-width: 1.25rem;
	}

	.md\:min-w-6 {
		min-width: 0.6rem;
	}

	.md\:min-w-8 {
		min-width: 0.8rem;
	}

	.md\:min-w-10 {
		min-width: 2.5rem;
	}

	.md\:min-w-12 {
		min-width: 1.2rem;
	}

	.md\:min-w-16 {
		min-width: 1.6rem;
	}

	.md\:min-w-20 {
		min-width: 2rem;
	}

	.md\:min-w-24 {
		min-width: 2.4rem;
	}

	.md\:min-w-28 {
		min-width: 2.8rem;
	}

	.md\:min-w-32 {
		min-width: 3.2rem;
	}

	.md\:min-w-36 {
		min-width: 3.6rem;
	}

	.md\:min-w-40 {
		min-width: 4rem;
	}

	.md\:min-w-44 {
		min-width: 4.4rem;
	}

	.md\:min-w-48 {
		min-width: 4.8rem;
	}

	.md\:min-w-52 {
		min-width: 5.2rem;
	}

	.md\:min-w-56 {
		min-width: 5.6rem;
	}

	.md\:min-w-60 {
		min-width: 6rem;
	}

	.md\:min-w-64 {
		min-width: 6.4rem;
	}

	.md\:min-w-68 {
		min-width: 6.8rem;
	}

	.md\:min-w-72 {
		min-width: 7.2rem;
	}

	.md\:min-w-76 {
		min-width: 7.6rem;
	}

	.md\:min-w-80 {
		min-width: 8rem;
	}

	.md\:min-w-84 {
		min-width: 8.4rem;
	}

	.md\:min-w-88 {
		min-width: 8.8rem;
	}

	.md\:min-w-92 {
		min-width: 9.2rem;
	}

	.md\:min-w-96 {
		min-width: 9.6rem;
	}

	.md\:min-w-128 {
		min-width: 12.8rem;
	}

	.md\:min-w-136 {
		min-width: 13.6rem;
	}

	.md\:min-w-160 {
		min-width: 16rem;
	}

	.md\:min-w-192 {
		min-width: 19.2rem;
	}

	.md\:min-w-200 {
		min-width: 20rem;
	}

	.md\:min-w-208 {
		min-width: 20.8rem;
	}

	.md\:min-w-216 {
		min-width: 21.6rem;
	}

	.md\:min-w-224 {
		min-width: 22.4rem;
	}

	.md\:min-w-256 {
		min-width: 25.6rem;
	}

	.md\:min-w-288 {
		min-width: 28.8rem;
	}

	.md\:min-w-320 {
		min-width: 32rem;
	}

	.md\:min-w-360 {
		min-width: 36rem;
	}

	.md\:min-w-384 {
		min-width: 38.4rem;
	}

	.md\:min-w-400 {
		min-width: 40rem;
	}

	.md\:min-w-512 {
		min-width: 51.2rem;
	}

	.md\:min-w-640 {
		min-width: 64rem;
	}

	.md\:min-w-full {
		min-width: 100%;
	}

	.md\:min-w-px {
		min-width: 1px;
	}

	.md\:min-w-xs {
		min-width: 32rem;
	}

	.md\:min-w-sm {
		min-width: 48rem;
	}

	.md\:min-w-md {
		min-width: 64rem;
	}

	.md\:min-w-lg {
		min-width: 80rem;
	}

	.md\:min-w-xl {
		min-width: 96rem;
	}

	.md\:min-w-2xl {
		min-width: 112rem;
	}

	.md\:min-w-3xl {
		min-width: 128rem;
	}

	.md\:min-w-4xl {
		min-width: 144rem;
	}

	.md\:min-w-5xl {
		min-width: 160rem;
	}

	.md\:min-w-auto {
		min-width: auto;
	}

	.md\:min-w-screen {
		min-width: 100vw;
	}

	.md\:object-contain {
		object-fit: contain;
	}

	.md\:object-cover {
		object-fit: cover;
	}

	.md\:object-fill {
		object-fit: fill;
	}

	.md\:object-none {
		object-fit: none;
	}

	.md\:object-scale-down {
		object-fit: scale-down;
	}

	.md\:object-bottom {
		object-position: bottom;
	}

	.md\:object-center {
		object-position: center;
	}

	.md\:object-left {
		object-position: left;
	}

	.md\:object-left-bottom {
		object-position: left bottom;
	}

	.md\:object-left-top {
		object-position: left top;
	}

	.md\:object-right {
		object-position: right;
	}

	.md\:object-right-bottom {
		object-position: right bottom;
	}

	.md\:object-right-top {
		object-position: right top;
	}

	.md\:object-top {
		object-position: top;
	}

	.md\:opacity-0 {
		opacity: 0;
	}

	.md\:opacity-25 {
		opacity: 0.25;
	}

	.md\:opacity-50 {
		opacity: 0.5;
	}

	.md\:opacity-75 {
		opacity: 0.75;
	}

	.md\:opacity-100 {
		opacity: 1;
	}

	.md\:outline-none {
		outline: 0;
	}

	.md\:focus\:outline-none:focus {
		outline: 0;
	}

	.md\:overflow-auto {
		overflow: auto;
	}

	.md\:overflow-hidden {
		overflow: hidden;
	}

	.md\:overflow-visible {
		overflow: visible;
	}

	.md\:overflow-scroll {
		overflow: scroll;
	}

	.md\:overflow-x-auto {
		overflow-x: auto;
	}

	.md\:overflow-y-auto {
		overflow-y: auto;
	}

	.md\:overflow-x-hidden {
		overflow-x: hidden;
	}

	.md\:overflow-y-hidden {
		overflow-y: hidden;
	}

	.md\:overflow-x-visible {
		overflow-x: visible;
	}

	.md\:overflow-y-visible {
		overflow-y: visible;
	}

	.md\:overflow-x-scroll {
		overflow-x: scroll;
	}

	.md\:overflow-y-scroll {
		overflow-y: scroll;
	}

	.md\:scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}

	.md\:scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}

	.md\:p-0 {
		padding: 0;
	}

	.md\:p-1 {
		padding: 0.25rem;
	}

	.md\:p-2 {
		padding: 0.5rem;
	}

	.md\:p-3 {
		padding: 0.75rem;
	}

	.md\:p-4 {
		padding: 0.4rem;
	}

	.md\:p-5 {
		padding: 1.25rem;
	}

	.md\:p-6 {
		padding: 0.6rem;
	}

	.md\:p-8 {
		padding: 0.8rem;
	}

	.md\:p-10 {
		padding: 2.5rem;
	}

	.md\:p-12 {
		padding: 1.2rem;
	}

	.md\:p-16 {
		padding: 1.6rem;
	}

	.md\:p-20 {
		padding: 2rem;
	}

	.md\:p-24 {
		padding: 2.4rem;
	}

	.md\:p-28 {
		padding: 2.8rem;
	}

	.md\:p-32 {
		padding: 3.2rem;
	}

	.md\:p-36 {
		padding: 3.6rem;
	}

	.md\:p-40 {
		padding: 4rem;
	}

	.md\:p-44 {
		padding: 4.4rem;
	}

	.md\:p-48 {
		padding: 4.8rem;
	}

	.md\:p-52 {
		padding: 5.2rem;
	}

	.md\:p-56 {
		padding: 5.6rem;
	}

	.md\:p-60 {
		padding: 6rem;
	}

	.md\:p-64 {
		padding: 6.4rem;
	}

	.md\:p-68 {
		padding: 6.8rem;
	}

	.md\:p-72 {
		padding: 7.2rem;
	}

	.md\:p-76 {
		padding: 7.6rem;
	}

	.md\:p-80 {
		padding: 8rem;
	}

	.md\:p-84 {
		padding: 8.4rem;
	}

	.md\:p-88 {
		padding: 8.8rem;
	}

	.md\:p-92 {
		padding: 9.2rem;
	}

	.md\:p-96 {
		padding: 9.6rem;
	}

	.md\:p-128 {
		padding: 12.8rem;
	}

	.md\:p-136 {
		padding: 13.6rem;
	}

	.md\:p-160 {
		padding: 16rem;
	}

	.md\:p-192 {
		padding: 19.2rem;
	}

	.md\:p-200 {
		padding: 20rem;
	}

	.md\:p-208 {
		padding: 20.8rem;
	}

	.md\:p-216 {
		padding: 21.6rem;
	}

	.md\:p-224 {
		padding: 22.4rem;
	}

	.md\:p-256 {
		padding: 25.6rem;
	}

	.md\:p-288 {
		padding: 28.8rem;
	}

	.md\:p-320 {
		padding: 32rem;
	}

	.md\:p-360 {
		padding: 36rem;
	}

	.md\:p-384 {
		padding: 38.4rem;
	}

	.md\:p-400 {
		padding: 40rem;
	}

	.md\:p-512 {
		padding: 51.2rem;
	}

	.md\:p-640 {
		padding: 64rem;
	}

	.md\:p-px {
		padding: 1px;
	}

	.md\:p-xs {
		padding: 32rem;
	}

	.md\:p-sm {
		padding: 48rem;
	}

	.md\:p-md {
		padding: 64rem;
	}

	.md\:p-lg {
		padding: 80rem;
	}

	.md\:p-xl {
		padding: 96rem;
	}

	.md\:p-2xl {
		padding: 112rem;
	}

	.md\:p-3xl {
		padding: 128rem;
	}

	.md\:p-4xl {
		padding: 144rem;
	}

	.md\:p-5xl {
		padding: 160rem;
	}

	.md\:p-auto {
		padding: auto;
	}

	.md\:py-0 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.md\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.md\:py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.md\:px-1 {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.md\:py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.md\:px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.md\:py-3 {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.md\:px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.md\:py-4 {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.md\:px-4 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}

	.md\:py-5 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.md\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.md\:py-6 {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.md\:px-6 {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.md\:py-8 {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
	}

	.md\:px-8 {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.md\:py-10 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.md\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.md\:py-12 {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.md\:px-12 {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}

	.md\:py-16 {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	.md\:px-16 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.md\:py-20 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.md\:px-20 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.md\:py-24 {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	.md\:px-24 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.md\:py-28 {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}

	.md\:px-28 {
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	.md\:py-32 {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.md\:px-32 {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.md\:py-36 {
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
	}

	.md\:px-36 {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.md\:py-40 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.md\:px-40 {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	.md\:py-44 {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.md\:px-44 {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.md\:py-48 {
		padding-top: 4.8rem;
		padding-bottom: 4.8rem;
	}

	.md\:px-48 {
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.md\:py-52 {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.md\:px-52 {
		padding-left: 5.2rem;
		padding-right: 5.2rem;
	}

	.md\:py-56 {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}

	.md\:px-56 {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}

	.md\:py-60 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.md\:px-60 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.md\:py-64 {
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;
	}

	.md\:px-64 {
		padding-left: 6.4rem;
		padding-right: 6.4rem;
	}

	.md\:py-68 {
		padding-top: 6.8rem;
		padding-bottom: 6.8rem;
	}

	.md\:px-68 {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.md\:py-72 {
		padding-top: 7.2rem;
		padding-bottom: 7.2rem;
	}

	.md\:px-72 {
		padding-left: 7.2rem;
		padding-right: 7.2rem;
	}

	.md\:py-76 {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
	}

	.md\:px-76 {
		padding-left: 7.6rem;
		padding-right: 7.6rem;
	}

	.md\:py-80 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.md\:px-80 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.md\:py-84 {
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}

	.md\:px-84 {
		padding-left: 8.4rem;
		padding-right: 8.4rem;
	}

	.md\:py-88 {
		padding-top: 8.8rem;
		padding-bottom: 8.8rem;
	}

	.md\:px-88 {
		padding-left: 8.8rem;
		padding-right: 8.8rem;
	}

	.md\:py-92 {
		padding-top: 9.2rem;
		padding-bottom: 9.2rem;
	}

	.md\:px-92 {
		padding-left: 9.2rem;
		padding-right: 9.2rem;
	}

	.md\:py-96 {
		padding-top: 9.6rem;
		padding-bottom: 9.6rem;
	}

	.md\:px-96 {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}

	.md\:py-128 {
		padding-top: 12.8rem;
		padding-bottom: 12.8rem;
	}

	.md\:px-128 {
		padding-left: 12.8rem;
		padding-right: 12.8rem;
	}

	.md\:py-136 {
		padding-top: 13.6rem;
		padding-bottom: 13.6rem;
	}

	.md\:px-136 {
		padding-left: 13.6rem;
		padding-right: 13.6rem;
	}

	.md\:py-160 {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}

	.md\:px-160 {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.md\:py-192 {
		padding-top: 19.2rem;
		padding-bottom: 19.2rem;
	}

	.md\:px-192 {
		padding-left: 19.2rem;
		padding-right: 19.2rem;
	}

	.md\:py-200 {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.md\:px-200 {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.md\:py-208 {
		padding-top: 20.8rem;
		padding-bottom: 20.8rem;
	}

	.md\:px-208 {
		padding-left: 20.8rem;
		padding-right: 20.8rem;
	}

	.md\:py-216 {
		padding-top: 21.6rem;
		padding-bottom: 21.6rem;
	}

	.md\:px-216 {
		padding-left: 21.6rem;
		padding-right: 21.6rem;
	}

	.md\:py-224 {
		padding-top: 22.4rem;
		padding-bottom: 22.4rem;
	}

	.md\:px-224 {
		padding-left: 22.4rem;
		padding-right: 22.4rem;
	}

	.md\:py-256 {
		padding-top: 25.6rem;
		padding-bottom: 25.6rem;
	}

	.md\:px-256 {
		padding-left: 25.6rem;
		padding-right: 25.6rem;
	}

	.md\:py-288 {
		padding-top: 28.8rem;
		padding-bottom: 28.8rem;
	}

	.md\:px-288 {
		padding-left: 28.8rem;
		padding-right: 28.8rem;
	}

	.md\:py-320 {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.md\:px-320 {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.md\:py-360 {
		padding-top: 36rem;
		padding-bottom: 36rem;
	}

	.md\:px-360 {
		padding-left: 36rem;
		padding-right: 36rem;
	}

	.md\:py-384 {
		padding-top: 38.4rem;
		padding-bottom: 38.4rem;
	}

	.md\:px-384 {
		padding-left: 38.4rem;
		padding-right: 38.4rem;
	}

	.md\:py-400 {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.md\:px-400 {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.md\:py-512 {
		padding-top: 51.2rem;
		padding-bottom: 51.2rem;
	}

	.md\:px-512 {
		padding-left: 51.2rem;
		padding-right: 51.2rem;
	}

	.md\:py-640 {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.md\:px-640 {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.md\:py-px {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.md\:px-px {
		padding-left: 1px;
		padding-right: 1px;
	}

	.md\:py-xs {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.md\:px-xs {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.md\:py-sm {
		padding-top: 48rem;
		padding-bottom: 48rem;
	}

	.md\:px-sm {
		padding-left: 48rem;
		padding-right: 48rem;
	}

	.md\:py-md {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.md\:px-md {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.md\:py-lg {
		padding-top: 80rem;
		padding-bottom: 80rem;
	}

	.md\:px-lg {
		padding-left: 80rem;
		padding-right: 80rem;
	}

	.md\:py-xl {
		padding-top: 96rem;
		padding-bottom: 96rem;
	}

	.md\:px-xl {
		padding-left: 96rem;
		padding-right: 96rem;
	}

	.md\:py-2xl {
		padding-top: 112rem;
		padding-bottom: 112rem;
	}

	.md\:px-2xl {
		padding-left: 112rem;
		padding-right: 112rem;
	}

	.md\:py-3xl {
		padding-top: 128rem;
		padding-bottom: 128rem;
	}

	.md\:px-3xl {
		padding-left: 128rem;
		padding-right: 128rem;
	}

	.md\:py-4xl {
		padding-top: 144rem;
		padding-bottom: 144rem;
	}

	.md\:px-4xl {
		padding-left: 144rem;
		padding-right: 144rem;
	}

	.md\:py-5xl {
		padding-top: 160rem;
		padding-bottom: 160rem;
	}

	.md\:px-5xl {
		padding-left: 160rem;
		padding-right: 160rem;
	}

	.md\:py-auto {
		padding-top: auto;
		padding-bottom: auto;
	}

	.md\:px-auto {
		padding-left: auto;
		padding-right: auto;
	}

	.md\:pt-0 {
		padding-top: 0;
	}

	.md\:pr-0 {
		padding-right: 0;
	}

	.md\:pb-0 {
		padding-bottom: 0;
	}

	.md\:pl-0 {
		padding-left: 0;
	}

	.md\:pt-1 {
		padding-top: 0.25rem;
	}

	.md\:pr-1 {
		padding-right: 0.25rem;
	}

	.md\:pb-1 {
		padding-bottom: 0.25rem;
	}

	.md\:pl-1 {
		padding-left: 0.25rem;
	}

	.md\:pt-2 {
		padding-top: 0.5rem;
	}

	.md\:pr-2 {
		padding-right: 0.5rem;
	}

	.md\:pb-2 {
		padding-bottom: 0.5rem;
	}

	.md\:pl-2 {
		padding-left: 0.5rem;
	}

	.md\:pt-3 {
		padding-top: 0.75rem;
	}

	.md\:pr-3 {
		padding-right: 0.75rem;
	}

	.md\:pb-3 {
		padding-bottom: 0.75rem;
	}

	.md\:pl-3 {
		padding-left: 0.75rem;
	}

	.md\:pt-4 {
		padding-top: 0.4rem;
	}

	.md\:pr-4 {
		padding-right: 0.4rem;
	}

	.md\:pb-4 {
		padding-bottom: 0.4rem;
	}

	.md\:pl-4 {
		padding-left: 0.4rem;
	}

	.md\:pt-5 {
		padding-top: 1.25rem;
	}

	.md\:pr-5 {
		padding-right: 1.25rem;
	}

	.md\:pb-5 {
		padding-bottom: 1.25rem;
	}

	.md\:pl-5 {
		padding-left: 1.25rem;
	}

	.md\:pt-6 {
		padding-top: 0.6rem;
	}

	.md\:pr-6 {
		padding-right: 0.6rem;
	}

	.md\:pb-6 {
		padding-bottom: 0.6rem;
	}

	.md\:pl-6 {
		padding-left: 0.6rem;
	}

	.md\:pt-8 {
		padding-top: 0.8rem;
	}

	.md\:pr-8 {
		padding-right: 0.8rem;
	}

	.md\:pb-8 {
		padding-bottom: 0.8rem;
	}

	.md\:pl-8 {
		padding-left: 0.8rem;
	}

	.md\:pt-10 {
		padding-top: 2.5rem;
	}

	.md\:pr-10 {
		padding-right: 2.5rem;
	}

	.md\:pb-10 {
		padding-bottom: 2.5rem;
	}

	.md\:pl-10 {
		padding-left: 2.5rem;
	}

	.md\:pt-12 {
		padding-top: 1.2rem;
	}

	.md\:pr-12 {
		padding-right: 1.2rem;
	}

	.md\:pb-12 {
		padding-bottom: 1.2rem;
	}

	.md\:pl-12 {
		padding-left: 1.2rem;
	}

	.md\:pt-16 {
		padding-top: 1.6rem;
	}

	.md\:pr-16 {
		padding-right: 1.6rem;
	}

	.md\:pb-16 {
		padding-bottom: 1.6rem;
	}

	.md\:pl-16 {
		padding-left: 1.6rem;
	}

	.md\:pt-20 {
		padding-top: 2rem;
	}

	.md\:pr-20 {
		padding-right: 2rem;
	}

	.md\:pb-20 {
		padding-bottom: 2rem;
	}

	.md\:pl-20 {
		padding-left: 2rem;
	}

	.md\:pt-24 {
		padding-top: 2.4rem;
	}

	.md\:pr-24 {
		padding-right: 2.4rem;
	}

	.md\:pb-24 {
		padding-bottom: 2.4rem;
	}

	.md\:pl-24 {
		padding-left: 2.4rem;
	}

	.md\:pt-28 {
		padding-top: 2.8rem;
	}

	.md\:pr-28 {
		padding-right: 2.8rem;
	}

	.md\:pb-28 {
		padding-bottom: 2.8rem;
	}

	.md\:pl-28 {
		padding-left: 2.8rem;
	}

	.md\:pt-32 {
		padding-top: 3.2rem;
	}

	.md\:pr-32 {
		padding-right: 3.2rem;
	}

	.md\:pb-32 {
		padding-bottom: 3.2rem;
	}

	.md\:pl-32 {
		padding-left: 3.2rem;
	}

	.md\:pt-36 {
		padding-top: 3.6rem;
	}

	.md\:pr-36 {
		padding-right: 3.6rem;
	}

	.md\:pb-36 {
		padding-bottom: 3.6rem;
	}

	.md\:pl-36 {
		padding-left: 3.6rem;
	}

	.md\:pt-40 {
		padding-top: 4rem;
	}

	.md\:pr-40 {
		padding-right: 4rem;
	}

	.md\:pb-40 {
		padding-bottom: 4rem;
	}

	.md\:pl-40 {
		padding-left: 4rem;
	}

	.md\:pt-44 {
		padding-top: 4.4rem;
	}

	.md\:pr-44 {
		padding-right: 4.4rem;
	}

	.md\:pb-44 {
		padding-bottom: 4.4rem;
	}

	.md\:pl-44 {
		padding-left: 4.4rem;
	}

	.md\:pt-48 {
		padding-top: 4.8rem;
	}

	.md\:pr-48 {
		padding-right: 4.8rem;
	}

	.md\:pb-48 {
		padding-bottom: 4.8rem;
	}

	.md\:pl-48 {
		padding-left: 4.8rem;
	}

	.md\:pt-52 {
		padding-top: 5.2rem;
	}

	.md\:pr-52 {
		padding-right: 5.2rem;
	}

	.md\:pb-52 {
		padding-bottom: 5.2rem;
	}

	.md\:pl-52 {
		padding-left: 5.2rem;
	}

	.md\:pt-56 {
		padding-top: 5.6rem;
	}

	.md\:pr-56 {
		padding-right: 5.6rem;
	}

	.md\:pb-56 {
		padding-bottom: 5.6rem;
	}

	.md\:pl-56 {
		padding-left: 5.6rem;
	}

	.md\:pt-60 {
		padding-top: 6rem;
	}

	.md\:pr-60 {
		padding-right: 6rem;
	}

	.md\:pb-60 {
		padding-bottom: 6rem;
	}

	.md\:pl-60 {
		padding-left: 6rem;
	}

	.md\:pt-64 {
		padding-top: 6.4rem;
	}

	.md\:pr-64 {
		padding-right: 6.4rem;
	}

	.md\:pb-64 {
		padding-bottom: 6.4rem;
	}

	.md\:pl-64 {
		padding-left: 6.4rem;
	}

	.md\:pt-68 {
		padding-top: 6.8rem;
	}

	.md\:pr-68 {
		padding-right: 6.8rem;
	}

	.md\:pb-68 {
		padding-bottom: 6.8rem;
	}

	.md\:pl-68 {
		padding-left: 6.8rem;
	}

	.md\:pt-72 {
		padding-top: 7.2rem;
	}

	.md\:pr-72 {
		padding-right: 7.2rem;
	}

	.md\:pb-72 {
		padding-bottom: 7.2rem;
	}

	.md\:pl-72 {
		padding-left: 7.2rem;
	}

	.md\:pt-76 {
		padding-top: 7.6rem;
	}

	.md\:pr-76 {
		padding-right: 7.6rem;
	}

	.md\:pb-76 {
		padding-bottom: 7.6rem;
	}

	.md\:pl-76 {
		padding-left: 7.6rem;
	}

	.md\:pt-80 {
		padding-top: 8rem;
	}

	.md\:pr-80 {
		padding-right: 8rem;
	}

	.md\:pb-80 {
		padding-bottom: 8rem;
	}

	.md\:pl-80 {
		padding-left: 8rem;
	}

	.md\:pt-84 {
		padding-top: 8.4rem;
	}

	.md\:pr-84 {
		padding-right: 8.4rem;
	}

	.md\:pb-84 {
		padding-bottom: 8.4rem;
	}

	.md\:pl-84 {
		padding-left: 8.4rem;
	}

	.md\:pt-88 {
		padding-top: 8.8rem;
	}

	.md\:pr-88 {
		padding-right: 8.8rem;
	}

	.md\:pb-88 {
		padding-bottom: 8.8rem;
	}

	.md\:pl-88 {
		padding-left: 8.8rem;
	}

	.md\:pt-92 {
		padding-top: 9.2rem;
	}

	.md\:pr-92 {
		padding-right: 9.2rem;
	}

	.md\:pb-92 {
		padding-bottom: 9.2rem;
	}

	.md\:pl-92 {
		padding-left: 9.2rem;
	}

	.md\:pt-96 {
		padding-top: 9.6rem;
	}

	.md\:pr-96 {
		padding-right: 9.6rem;
	}

	.md\:pb-96 {
		padding-bottom: 9.6rem;
	}

	.md\:pl-96 {
		padding-left: 9.6rem;
	}

	.md\:pt-128 {
		padding-top: 12.8rem;
	}

	.md\:pr-128 {
		padding-right: 12.8rem;
	}

	.md\:pb-128 {
		padding-bottom: 12.8rem;
	}

	.md\:pl-128 {
		padding-left: 12.8rem;
	}

	.md\:pt-136 {
		padding-top: 13.6rem;
	}

	.md\:pr-136 {
		padding-right: 13.6rem;
	}

	.md\:pb-136 {
		padding-bottom: 13.6rem;
	}

	.md\:pl-136 {
		padding-left: 13.6rem;
	}

	.md\:pt-160 {
		padding-top: 16rem;
	}

	.md\:pr-160 {
		padding-right: 16rem;
	}

	.md\:pb-160 {
		padding-bottom: 16rem;
	}

	.md\:pl-160 {
		padding-left: 16rem;
	}

	.md\:pt-192 {
		padding-top: 19.2rem;
	}

	.md\:pr-192 {
		padding-right: 19.2rem;
	}

	.md\:pb-192 {
		padding-bottom: 19.2rem;
	}

	.md\:pl-192 {
		padding-left: 19.2rem;
	}

	.md\:pt-200 {
		padding-top: 20rem;
	}

	.md\:pr-200 {
		padding-right: 20rem;
	}

	.md\:pb-200 {
		padding-bottom: 20rem;
	}

	.md\:pl-200 {
		padding-left: 20rem;
	}

	.md\:pt-208 {
		padding-top: 20.8rem;
	}

	.md\:pr-208 {
		padding-right: 20.8rem;
	}

	.md\:pb-208 {
		padding-bottom: 20.8rem;
	}

	.md\:pl-208 {
		padding-left: 20.8rem;
	}

	.md\:pt-216 {
		padding-top: 21.6rem;
	}

	.md\:pr-216 {
		padding-right: 21.6rem;
	}

	.md\:pb-216 {
		padding-bottom: 21.6rem;
	}

	.md\:pl-216 {
		padding-left: 21.6rem;
	}

	.md\:pt-224 {
		padding-top: 22.4rem;
	}

	.md\:pr-224 {
		padding-right: 22.4rem;
	}

	.md\:pb-224 {
		padding-bottom: 22.4rem;
	}

	.md\:pl-224 {
		padding-left: 22.4rem;
	}

	.md\:pt-256 {
		padding-top: 25.6rem;
	}

	.md\:pr-256 {
		padding-right: 25.6rem;
	}

	.md\:pb-256 {
		padding-bottom: 25.6rem;
	}

	.md\:pl-256 {
		padding-left: 25.6rem;
	}

	.md\:pt-288 {
		padding-top: 28.8rem;
	}

	.md\:pr-288 {
		padding-right: 28.8rem;
	}

	.md\:pb-288 {
		padding-bottom: 28.8rem;
	}

	.md\:pl-288 {
		padding-left: 28.8rem;
	}

	.md\:pt-320 {
		padding-top: 32rem;
	}

	.md\:pr-320 {
		padding-right: 32rem;
	}

	.md\:pb-320 {
		padding-bottom: 32rem;
	}

	.md\:pl-320 {
		padding-left: 32rem;
	}

	.md\:pt-360 {
		padding-top: 36rem;
	}

	.md\:pr-360 {
		padding-right: 36rem;
	}

	.md\:pb-360 {
		padding-bottom: 36rem;
	}

	.md\:pl-360 {
		padding-left: 36rem;
	}

	.md\:pt-384 {
		padding-top: 38.4rem;
	}

	.md\:pr-384 {
		padding-right: 38.4rem;
	}

	.md\:pb-384 {
		padding-bottom: 38.4rem;
	}

	.md\:pl-384 {
		padding-left: 38.4rem;
	}

	.md\:pt-400 {
		padding-top: 40rem;
	}

	.md\:pr-400 {
		padding-right: 40rem;
	}

	.md\:pb-400 {
		padding-bottom: 40rem;
	}

	.md\:pl-400 {
		padding-left: 40rem;
	}

	.md\:pt-512 {
		padding-top: 51.2rem;
	}

	.md\:pr-512 {
		padding-right: 51.2rem;
	}

	.md\:pb-512 {
		padding-bottom: 51.2rem;
	}

	.md\:pl-512 {
		padding-left: 51.2rem;
	}

	.md\:pt-640 {
		padding-top: 64rem;
	}

	.md\:pr-640 {
		padding-right: 64rem;
	}

	.md\:pb-640 {
		padding-bottom: 64rem;
	}

	.md\:pl-640 {
		padding-left: 64rem;
	}

	.md\:pt-px {
		padding-top: 1px;
	}

	.md\:pr-px {
		padding-right: 1px;
	}

	.md\:pb-px {
		padding-bottom: 1px;
	}

	.md\:pl-px {
		padding-left: 1px;
	}

	.md\:pt-xs {
		padding-top: 32rem;
	}

	.md\:pr-xs {
		padding-right: 32rem;
	}

	.md\:pb-xs {
		padding-bottom: 32rem;
	}

	.md\:pl-xs {
		padding-left: 32rem;
	}

	.md\:pt-sm {
		padding-top: 48rem;
	}

	.md\:pr-sm {
		padding-right: 48rem;
	}

	.md\:pb-sm {
		padding-bottom: 48rem;
	}

	.md\:pl-sm {
		padding-left: 48rem;
	}

	.md\:pt-md {
		padding-top: 64rem;
	}

	.md\:pr-md {
		padding-right: 64rem;
	}

	.md\:pb-md {
		padding-bottom: 64rem;
	}

	.md\:pl-md {
		padding-left: 64rem;
	}

	.md\:pt-lg {
		padding-top: 80rem;
	}

	.md\:pr-lg {
		padding-right: 80rem;
	}

	.md\:pb-lg {
		padding-bottom: 80rem;
	}

	.md\:pl-lg {
		padding-left: 80rem;
	}

	.md\:pt-xl {
		padding-top: 96rem;
	}

	.md\:pr-xl {
		padding-right: 96rem;
	}

	.md\:pb-xl {
		padding-bottom: 96rem;
	}

	.md\:pl-xl {
		padding-left: 96rem;
	}

	.md\:pt-2xl {
		padding-top: 112rem;
	}

	.md\:pr-2xl {
		padding-right: 112rem;
	}

	.md\:pb-2xl {
		padding-bottom: 112rem;
	}

	.md\:pl-2xl {
		padding-left: 112rem;
	}

	.md\:pt-3xl {
		padding-top: 128rem;
	}

	.md\:pr-3xl {
		padding-right: 128rem;
	}

	.md\:pb-3xl {
		padding-bottom: 128rem;
	}

	.md\:pl-3xl {
		padding-left: 128rem;
	}

	.md\:pt-4xl {
		padding-top: 144rem;
	}

	.md\:pr-4xl {
		padding-right: 144rem;
	}

	.md\:pb-4xl {
		padding-bottom: 144rem;
	}

	.md\:pl-4xl {
		padding-left: 144rem;
	}

	.md\:pt-5xl {
		padding-top: 160rem;
	}

	.md\:pr-5xl {
		padding-right: 160rem;
	}

	.md\:pb-5xl {
		padding-bottom: 160rem;
	}

	.md\:pl-5xl {
		padding-left: 160rem;
	}

	.md\:pt-auto {
		padding-top: auto;
	}

	.md\:pr-auto {
		padding-right: auto;
	}

	.md\:pb-auto {
		padding-bottom: auto;
	}

	.md\:pl-auto {
		padding-left: auto;
	}

	.md\:pointer-events-none {
		pointer-events: none;
	}

	.md\:pointer-events-auto {
		pointer-events: auto;
	}

	.md\:static {
		position: static;
	}

	.md\:fixed {
		position: fixed;
	}

	.md\:absolute {
		position: absolute;
	}

	.md\:relative {
		position: relative;
	}

	.md\:sticky {
		position: -webkit-sticky;
		position: sticky;
	}

	.md\:inset-0 {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.md\:inset-auto {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}

	.md\:inset-y-0 {
		top: 0;
		bottom: 0;
	}

	.md\:inset-x-0 {
		right: 0;
		left: 0;
	}

	.md\:inset-y-auto {
		top: auto;
		bottom: auto;
	}

	.md\:inset-x-auto {
		right: auto;
		left: auto;
	}

	.md\:top-0 {
		top: 0;
	}

	.md\:right-0 {
		right: 0;
	}

	.md\:bottom-0 {
		bottom: 0;
	}

	.md\:left-0 {
		left: 0;
	}

	.md\:top-auto {
		top: auto;
	}

	.md\:right-auto {
		right: auto;
	}

	.md\:bottom-auto {
		bottom: auto;
	}

	.md\:left-auto {
		left: auto;
	}

	.md\:resize-none {
		resize: none;
	}

	.md\:resize-y {
		resize: vertical;
	}

	.md\:resize-x {
		resize: horizontal;
	}

	.md\:resize {
		resize: both;
	}

	.md\:shadow-0 {
		box-shadow: none;
	}

	.md\:shadow-1 {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-2 {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-3 {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-4 {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-5 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-6 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-7 {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-8 {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-9 {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-10 {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-11 {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-12 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-13 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-14 {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-15 {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-16 {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-17 {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-18 {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-19 {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-20 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-21 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-22 {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-23 {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow-24 {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.md\:shadow-md {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:shadow-lg {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:shadow-xl {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.md\:shadow-2xl {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.md\:shadow-inner {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.md\:shadow-outline {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.md\:shadow-none {
		box-shadow: none;
	}

	.md\:hover\:shadow-0:hover {
		box-shadow: none;
	}

	.md\:hover\:shadow-1:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-2:hover {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-3:hover {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-4:hover {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-5:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-6:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-7:hover {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-8:hover {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-9:hover {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-10:hover {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-11:hover {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-12:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-13:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-14:hover {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-15:hover {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-16:hover {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-17:hover {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-18:hover {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-19:hover {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-20:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-21:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-22:hover {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-23:hover {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow-24:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:hover\:shadow:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.md\:hover\:shadow-md:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:hover\:shadow-lg:hover {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:hover\:shadow-xl:hover {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.md\:hover\:shadow-2xl:hover {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.md\:hover\:shadow-inner:hover {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.md\:hover\:shadow-outline:hover {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.md\:hover\:shadow-none:hover {
		box-shadow: none;
	}

	.md\:focus\:shadow-0:focus {
		box-shadow: none;
	}

	.md\:focus\:shadow-1:focus {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-2:focus {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-3:focus {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-4:focus {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-5:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-6:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-7:focus {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-8:focus {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-9:focus {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-10:focus {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-11:focus {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-12:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-13:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-14:focus {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-15:focus {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-16:focus {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-17:focus {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-18:focus {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-19:focus {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-20:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-21:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-22:focus {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-23:focus {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow-24:focus {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.md\:focus\:shadow:focus {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.md\:focus\:shadow-md:focus {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:focus\:shadow-lg:focus {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.md\:focus\:shadow-xl:focus {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.md\:focus\:shadow-2xl:focus {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.md\:focus\:shadow-inner:focus {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.md\:focus\:shadow-outline:focus {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.md\:focus\:shadow-none:focus {
		box-shadow: none;
	}

	.md\:fill-current {
		fill: currentColor;
	}

	.md\:stroke-current {
		stroke: currentColor;
	}

	.md\:table-auto {
		table-layout: auto;
	}

	.md\:table-fixed {
		table-layout: fixed;
	}

	.md\:text-left {
		text-align: left;
	}

	.md\:text-center {
		text-align: center;
	}

	.md\:text-right {
		text-align: right;
	}

	.md\:text-justify {
		text-align: justify;
	}

	.md\:text-transparent {
		color: transparent;
	}

	.md\:text-black {
		color: #22292f;
	}

	.md\:text-white {
		color: #ffffff;
	}

	.md\:text-gray-100 {
		color: #f7fafc;
	}

	.md\:text-gray-200 {
		color: #edf2f7;
	}

	.md\:text-gray-300 {
		color: #e2e8f0;
	}

	.md\:text-gray-400 {
		color: #cbd5e0;
	}

	.md\:text-gray-500 {
		color: #a0aec0;
	}

	.md\:text-gray-600 {
		color: #718096;
	}

	.md\:text-gray-700 {
		color: #4a5568;
	}

	.md\:text-gray-800 {
		color: #2d3748;
	}

	.md\:text-gray-900 {
		color: #1a202c;
	}

	.md\:text-red {
		color: #e3342f;
	}

	.md\:text-orange {
		color: #f6993f;
	}

	.md\:text-yellow {
		color: #ffed4a;
	}

	.md\:text-green {
		color: #38c172;
	}

	.md\:text-teal {
		color: #4dc0b5;
	}

	.md\:text-blue {
		color: #3490dc;
	}

	.md\:text-indigo {
		color: #6574cd;
	}

	.md\:text-purple {
		color: #9561e2;
	}

	.md\:text-pink {
		color: #f66d9b;
	}

	.md\:text-grey-darkest {
		color: #3d4852;
	}

	.md\:text-grey-darker {
		color: #606f7b;
	}

	.md\:text-grey-dark {
		color: #8795a1;
	}

	.md\:text-grey {
		color: #b8c2cc;
	}

	.md\:text-grey-light {
		color: #dae1e7;
	}

	.md\:text-grey-lighter {
		color: #f1f5f8;
	}

	.md\:text-grey-lightest {
		color: #f8fafc;
	}

	.md\:text-red-darkest {
		color: #3b0d0c;
	}

	.md\:text-red-darker {
		color: #621b18;
	}

	.md\:text-red-dark {
		color: #cc1f1a;
	}

	.md\:text-red-light {
		color: #ef5753;
	}

	.md\:text-red-lighter {
		color: #f9acaa;
	}

	.md\:text-red-lightest {
		color: #fcebea;
	}

	.md\:text-orange-darkest {
		color: #462a16;
	}

	.md\:text-orange-darker {
		color: #613b1f;
	}

	.md\:text-orange-dark {
		color: #de751f;
	}

	.md\:text-orange-light {
		color: #faad63;
	}

	.md\:text-orange-lighter {
		color: #fcd9b6;
	}

	.md\:text-orange-lightest {
		color: #fff5eb;
	}

	.md\:text-yellow-darkest {
		color: #453411;
	}

	.md\:text-yellow-darker {
		color: #684f1d;
	}

	.md\:text-yellow-dark {
		color: #f2d024;
	}

	.md\:text-yellow-light {
		color: #fff382;
	}

	.md\:text-yellow-lighter {
		color: #fff9c2;
	}

	.md\:text-yellow-lightest {
		color: #fcfbeb;
	}

	.md\:text-green-darkest {
		color: #0f2f21;
	}

	.md\:text-green-darker {
		color: #1a4731;
	}

	.md\:text-green-dark {
		color: #1f9d55;
	}

	.md\:text-green-light {
		color: #51d88a;
	}

	.md\:text-green-lighter {
		color: #a2f5bf;
	}

	.md\:text-green-lightest {
		color: #e3fcec;
	}

	.md\:text-teal-darkest {
		color: #0d3331;
	}

	.md\:text-teal-darker {
		color: #20504f;
	}

	.md\:text-teal-dark {
		color: #38a89d;
	}

	.md\:text-teal-light {
		color: #64d5ca;
	}

	.md\:text-teal-lighter {
		color: #a0f0ed;
	}

	.md\:text-teal-lightest {
		color: #e8fffe;
	}

	.md\:text-blue-darkest {
		color: #12283a;
	}

	.md\:text-blue-darker {
		color: #1c3d5a;
	}

	.md\:text-blue-dark {
		color: #2779bd;
	}

	.md\:text-blue-light {
		color: #6cb2eb;
	}

	.md\:text-blue-lighter {
		color: #bcdefa;
	}

	.md\:text-blue-lightest {
		color: #eff8ff;
	}

	.md\:text-indigo-darkest {
		color: #191e38;
	}

	.md\:text-indigo-darker {
		color: #2f365f;
	}

	.md\:text-indigo-dark {
		color: #5661b3;
	}

	.md\:text-indigo-light {
		color: #7886d7;
	}

	.md\:text-indigo-lighter {
		color: #b2b7ff;
	}

	.md\:text-indigo-lightest {
		color: #e6e8ff;
	}

	.md\:text-purple-darkest {
		color: #21183c;
	}

	.md\:text-purple-darker {
		color: #382b5f;
	}

	.md\:text-purple-dark {
		color: #794acf;
	}

	.md\:text-purple-light {
		color: #a779e9;
	}

	.md\:text-purple-lighter {
		color: #d6bbfc;
	}

	.md\:text-purple-lightest {
		color: #f3ebff;
	}

	.md\:text-pink-darkest {
		color: #451225;
	}

	.md\:text-pink-darker {
		color: #6f213f;
	}

	.md\:text-pink-dark {
		color: #eb5286;
	}

	.md\:text-pink-light {
		color: #fa7ea8;
	}

	.md\:text-pink-lighter {
		color: #ffbbca;
	}

	.md\:text-pink-lightest {
		color: #ffebef;
	}

	.md\:hover\:text-transparent:hover {
		color: transparent;
	}

	.md\:hover\:text-black:hover {
		color: #22292f;
	}

	.md\:hover\:text-white:hover {
		color: #ffffff;
	}

	.md\:hover\:text-gray-100:hover {
		color: #f7fafc;
	}

	.md\:hover\:text-gray-200:hover {
		color: #edf2f7;
	}

	.md\:hover\:text-gray-300:hover {
		color: #e2e8f0;
	}

	.md\:hover\:text-gray-400:hover {
		color: #cbd5e0;
	}

	.md\:hover\:text-gray-500:hover {
		color: #a0aec0;
	}

	.md\:hover\:text-gray-600:hover {
		color: #718096;
	}

	.md\:hover\:text-gray-700:hover {
		color: #4a5568;
	}

	.md\:hover\:text-gray-800:hover {
		color: #2d3748;
	}

	.md\:hover\:text-gray-900:hover {
		color: #1a202c;
	}

	.md\:hover\:text-red:hover {
		color: #e3342f;
	}

	.md\:hover\:text-orange:hover {
		color: #f6993f;
	}

	.md\:hover\:text-yellow:hover {
		color: #ffed4a;
	}

	.md\:hover\:text-green:hover {
		color: #38c172;
	}

	.md\:hover\:text-teal:hover {
		color: #4dc0b5;
	}

	.md\:hover\:text-blue:hover {
		color: #3490dc;
	}

	.md\:hover\:text-indigo:hover {
		color: #6574cd;
	}

	.md\:hover\:text-purple:hover {
		color: #9561e2;
	}

	.md\:hover\:text-pink:hover {
		color: #f66d9b;
	}

	.md\:hover\:text-grey-darkest:hover {
		color: #3d4852;
	}

	.md\:hover\:text-grey-darker:hover {
		color: #606f7b;
	}

	.md\:hover\:text-grey-dark:hover {
		color: #8795a1;
	}

	.md\:hover\:text-grey:hover {
		color: #b8c2cc;
	}

	.md\:hover\:text-grey-light:hover {
		color: #dae1e7;
	}

	.md\:hover\:text-grey-lighter:hover {
		color: #f1f5f8;
	}

	.md\:hover\:text-grey-lightest:hover {
		color: #f8fafc;
	}

	.md\:hover\:text-red-darkest:hover {
		color: #3b0d0c;
	}

	.md\:hover\:text-red-darker:hover {
		color: #621b18;
	}

	.md\:hover\:text-red-dark:hover {
		color: #cc1f1a;
	}

	.md\:hover\:text-red-light:hover {
		color: #ef5753;
	}

	.md\:hover\:text-red-lighter:hover {
		color: #f9acaa;
	}

	.md\:hover\:text-red-lightest:hover {
		color: #fcebea;
	}

	.md\:hover\:text-orange-darkest:hover {
		color: #462a16;
	}

	.md\:hover\:text-orange-darker:hover {
		color: #613b1f;
	}

	.md\:hover\:text-orange-dark:hover {
		color: #de751f;
	}

	.md\:hover\:text-orange-light:hover {
		color: #faad63;
	}

	.md\:hover\:text-orange-lighter:hover {
		color: #fcd9b6;
	}

	.md\:hover\:text-orange-lightest:hover {
		color: #fff5eb;
	}

	.md\:hover\:text-yellow-darkest:hover {
		color: #453411;
	}

	.md\:hover\:text-yellow-darker:hover {
		color: #684f1d;
	}

	.md\:hover\:text-yellow-dark:hover {
		color: #f2d024;
	}

	.md\:hover\:text-yellow-light:hover {
		color: #fff382;
	}

	.md\:hover\:text-yellow-lighter:hover {
		color: #fff9c2;
	}

	.md\:hover\:text-yellow-lightest:hover {
		color: #fcfbeb;
	}

	.md\:hover\:text-green-darkest:hover {
		color: #0f2f21;
	}

	.md\:hover\:text-green-darker:hover {
		color: #1a4731;
	}

	.md\:hover\:text-green-dark:hover {
		color: #1f9d55;
	}

	.md\:hover\:text-green-light:hover {
		color: #51d88a;
	}

	.md\:hover\:text-green-lighter:hover {
		color: #a2f5bf;
	}

	.md\:hover\:text-green-lightest:hover {
		color: #e3fcec;
	}

	.md\:hover\:text-teal-darkest:hover {
		color: #0d3331;
	}

	.md\:hover\:text-teal-darker:hover {
		color: #20504f;
	}

	.md\:hover\:text-teal-dark:hover {
		color: #38a89d;
	}

	.md\:hover\:text-teal-light:hover {
		color: #64d5ca;
	}

	.md\:hover\:text-teal-lighter:hover {
		color: #a0f0ed;
	}

	.md\:hover\:text-teal-lightest:hover {
		color: #e8fffe;
	}

	.md\:hover\:text-blue-darkest:hover {
		color: #12283a;
	}

	.md\:hover\:text-blue-darker:hover {
		color: #1c3d5a;
	}

	.md\:hover\:text-blue-dark:hover {
		color: #2779bd;
	}

	.md\:hover\:text-blue-light:hover {
		color: #6cb2eb;
	}

	.md\:hover\:text-blue-lighter:hover {
		color: #bcdefa;
	}

	.md\:hover\:text-blue-lightest:hover {
		color: #eff8ff;
	}

	.md\:hover\:text-indigo-darkest:hover {
		color: #191e38;
	}

	.md\:hover\:text-indigo-darker:hover {
		color: #2f365f;
	}

	.md\:hover\:text-indigo-dark:hover {
		color: #5661b3;
	}

	.md\:hover\:text-indigo-light:hover {
		color: #7886d7;
	}

	.md\:hover\:text-indigo-lighter:hover {
		color: #b2b7ff;
	}

	.md\:hover\:text-indigo-lightest:hover {
		color: #e6e8ff;
	}

	.md\:hover\:text-purple-darkest:hover {
		color: #21183c;
	}

	.md\:hover\:text-purple-darker:hover {
		color: #382b5f;
	}

	.md\:hover\:text-purple-dark:hover {
		color: #794acf;
	}

	.md\:hover\:text-purple-light:hover {
		color: #a779e9;
	}

	.md\:hover\:text-purple-lighter:hover {
		color: #d6bbfc;
	}

	.md\:hover\:text-purple-lightest:hover {
		color: #f3ebff;
	}

	.md\:hover\:text-pink-darkest:hover {
		color: #451225;
	}

	.md\:hover\:text-pink-darker:hover {
		color: #6f213f;
	}

	.md\:hover\:text-pink-dark:hover {
		color: #eb5286;
	}

	.md\:hover\:text-pink-light:hover {
		color: #fa7ea8;
	}

	.md\:hover\:text-pink-lighter:hover {
		color: #ffbbca;
	}

	.md\:hover\:text-pink-lightest:hover {
		color: #ffebef;
	}

	.md\:focus\:text-transparent:focus {
		color: transparent;
	}

	.md\:focus\:text-black:focus {
		color: #22292f;
	}

	.md\:focus\:text-white:focus {
		color: #ffffff;
	}

	.md\:focus\:text-gray-100:focus {
		color: #f7fafc;
	}

	.md\:focus\:text-gray-200:focus {
		color: #edf2f7;
	}

	.md\:focus\:text-gray-300:focus {
		color: #e2e8f0;
	}

	.md\:focus\:text-gray-400:focus {
		color: #cbd5e0;
	}

	.md\:focus\:text-gray-500:focus {
		color: #a0aec0;
	}

	.md\:focus\:text-gray-600:focus {
		color: #718096;
	}

	.md\:focus\:text-gray-700:focus {
		color: #4a5568;
	}

	.md\:focus\:text-gray-800:focus {
		color: #2d3748;
	}

	.md\:focus\:text-gray-900:focus {
		color: #1a202c;
	}

	.md\:focus\:text-red:focus {
		color: #e3342f;
	}

	.md\:focus\:text-orange:focus {
		color: #f6993f;
	}

	.md\:focus\:text-yellow:focus {
		color: #ffed4a;
	}

	.md\:focus\:text-green:focus {
		color: #38c172;
	}

	.md\:focus\:text-teal:focus {
		color: #4dc0b5;
	}

	.md\:focus\:text-blue:focus {
		color: #3490dc;
	}

	.md\:focus\:text-indigo:focus {
		color: #6574cd;
	}

	.md\:focus\:text-purple:focus {
		color: #9561e2;
	}

	.md\:focus\:text-pink:focus {
		color: #f66d9b;
	}

	.md\:focus\:text-grey-darkest:focus {
		color: #3d4852;
	}

	.md\:focus\:text-grey-darker:focus {
		color: #606f7b;
	}

	.md\:focus\:text-grey-dark:focus {
		color: #8795a1;
	}

	.md\:focus\:text-grey:focus {
		color: #b8c2cc;
	}

	.md\:focus\:text-grey-light:focus {
		color: #dae1e7;
	}

	.md\:focus\:text-grey-lighter:focus {
		color: #f1f5f8;
	}

	.md\:focus\:text-grey-lightest:focus {
		color: #f8fafc;
	}

	.md\:focus\:text-red-darkest:focus {
		color: #3b0d0c;
	}

	.md\:focus\:text-red-darker:focus {
		color: #621b18;
	}

	.md\:focus\:text-red-dark:focus {
		color: #cc1f1a;
	}

	.md\:focus\:text-red-light:focus {
		color: #ef5753;
	}

	.md\:focus\:text-red-lighter:focus {
		color: #f9acaa;
	}

	.md\:focus\:text-red-lightest:focus {
		color: #fcebea;
	}

	.md\:focus\:text-orange-darkest:focus {
		color: #462a16;
	}

	.md\:focus\:text-orange-darker:focus {
		color: #613b1f;
	}

	.md\:focus\:text-orange-dark:focus {
		color: #de751f;
	}

	.md\:focus\:text-orange-light:focus {
		color: #faad63;
	}

	.md\:focus\:text-orange-lighter:focus {
		color: #fcd9b6;
	}

	.md\:focus\:text-orange-lightest:focus {
		color: #fff5eb;
	}

	.md\:focus\:text-yellow-darkest:focus {
		color: #453411;
	}

	.md\:focus\:text-yellow-darker:focus {
		color: #684f1d;
	}

	.md\:focus\:text-yellow-dark:focus {
		color: #f2d024;
	}

	.md\:focus\:text-yellow-light:focus {
		color: #fff382;
	}

	.md\:focus\:text-yellow-lighter:focus {
		color: #fff9c2;
	}

	.md\:focus\:text-yellow-lightest:focus {
		color: #fcfbeb;
	}

	.md\:focus\:text-green-darkest:focus {
		color: #0f2f21;
	}

	.md\:focus\:text-green-darker:focus {
		color: #1a4731;
	}

	.md\:focus\:text-green-dark:focus {
		color: #1f9d55;
	}

	.md\:focus\:text-green-light:focus {
		color: #51d88a;
	}

	.md\:focus\:text-green-lighter:focus {
		color: #a2f5bf;
	}

	.md\:focus\:text-green-lightest:focus {
		color: #e3fcec;
	}

	.md\:focus\:text-teal-darkest:focus {
		color: #0d3331;
	}

	.md\:focus\:text-teal-darker:focus {
		color: #20504f;
	}

	.md\:focus\:text-teal-dark:focus {
		color: #38a89d;
	}

	.md\:focus\:text-teal-light:focus {
		color: #64d5ca;
	}

	.md\:focus\:text-teal-lighter:focus {
		color: #a0f0ed;
	}

	.md\:focus\:text-teal-lightest:focus {
		color: #e8fffe;
	}

	.md\:focus\:text-blue-darkest:focus {
		color: #12283a;
	}

	.md\:focus\:text-blue-darker:focus {
		color: #1c3d5a;
	}

	.md\:focus\:text-blue-dark:focus {
		color: #2779bd;
	}

	.md\:focus\:text-blue-light:focus {
		color: #6cb2eb;
	}

	.md\:focus\:text-blue-lighter:focus {
		color: #bcdefa;
	}

	.md\:focus\:text-blue-lightest:focus {
		color: #eff8ff;
	}

	.md\:focus\:text-indigo-darkest:focus {
		color: #191e38;
	}

	.md\:focus\:text-indigo-darker:focus {
		color: #2f365f;
	}

	.md\:focus\:text-indigo-dark:focus {
		color: #5661b3;
	}

	.md\:focus\:text-indigo-light:focus {
		color: #7886d7;
	}

	.md\:focus\:text-indigo-lighter:focus {
		color: #b2b7ff;
	}

	.md\:focus\:text-indigo-lightest:focus {
		color: #e6e8ff;
	}

	.md\:focus\:text-purple-darkest:focus {
		color: #21183c;
	}

	.md\:focus\:text-purple-darker:focus {
		color: #382b5f;
	}

	.md\:focus\:text-purple-dark:focus {
		color: #794acf;
	}

	.md\:focus\:text-purple-light:focus {
		color: #a779e9;
	}

	.md\:focus\:text-purple-lighter:focus {
		color: #d6bbfc;
	}

	.md\:focus\:text-purple-lightest:focus {
		color: #f3ebff;
	}

	.md\:focus\:text-pink-darkest:focus {
		color: #451225;
	}

	.md\:focus\:text-pink-darker:focus {
		color: #6f213f;
	}

	.md\:focus\:text-pink-dark:focus {
		color: #eb5286;
	}

	.md\:focus\:text-pink-light:focus {
		color: #fa7ea8;
	}

	.md\:focus\:text-pink-lighter:focus {
		color: #ffbbca;
	}

	.md\:focus\:text-pink-lightest:focus {
		color: #ffebef;
	}

	.md\:text-10 {
		font-size: 1rem;
	}

	.md\:text-11 {
		font-size: 1.1rem;
	}

	.md\:text-12 {
		font-size: 1.2rem;
	}

	.md\:text-13 {
		font-size: 1.3rem;
	}

	.md\:text-14 {
		font-size: 1.4rem;
	}

	.md\:text-15 {
		font-size: 1.5rem;
	}

	.md\:text-16 {
		font-size: 1.6rem;
	}

	.md\:text-17 {
		font-size: 1.7rem;
	}

	.md\:text-18 {
		font-size: 1.8rem;
	}

	.md\:text-19 {
		font-size: 1.9rem;
	}

	.md\:text-20 {
		font-size: 2rem;
	}

	.md\:text-24 {
		font-size: 2.4rem;
	}

	.md\:text-28 {
		font-size: 2.8rem;
	}

	.md\:text-32 {
		font-size: 3.2rem;
	}

	.md\:text-36 {
		font-size: 3.6rem;
	}

	.md\:text-40 {
		font-size: 4rem;
	}

	.md\:text-44 {
		font-size: 4.4rem;
	}

	.md\:text-48 {
		font-size: 4.8rem;
	}

	.md\:text-52 {
		font-size: 5.2rem;
	}

	.md\:text-56 {
		font-size: 5.6rem;
	}

	.md\:text-60 {
		font-size: 6rem;
	}

	.md\:text-64 {
		font-size: 6.4rem;
	}

	.md\:text-68 {
		font-size: 6.8rem;
	}

	.md\:text-72 {
		font-size: 7.2rem;
	}

	.md\:text-96 {
		font-size: 9.6rem;
	}

	.md\:text-128 {
		font-size: 12.8rem;
	}

	.md\:text-xs {
		font-size: 1.2rem;
	}

	.md\:text-sm {
		font-size: 2.4rem;
	}

	.md\:text-base {
		font-size: 1.6rem;
	}

	.md\:text-lg {
		font-size: 1.8rem;
	}

	.md\:text-xl {
		font-size: 2rem;
	}

	.md\:text-2xl {
		font-size: 2.4rem;
	}

	.md\:text-3xl {
		font-size: 3rem;
	}

	.md\:text-4xl {
		font-size: 3.6rem;
	}

	.md\:text-5xl {
		font-size: 4.8rem;
	}

	.md\:text-6xl {
		font-size: 4rem;
	}

	.md\:italic {
		font-style: italic;
	}

	.md\:not-italic {
		font-style: normal;
	}

	.md\:hover\:italic:hover {
		font-style: italic;
	}

	.md\:hover\:not-italic:hover {
		font-style: normal;
	}

	.md\:focus\:italic:focus {
		font-style: italic;
	}

	.md\:focus\:not-italic:focus {
		font-style: normal;
	}

	.md\:uppercase {
		text-transform: uppercase;
	}

	.md\:lowercase {
		text-transform: lowercase;
	}

	.md\:capitalize {
		text-transform: capitalize;
	}

	.md\:normal-case {
		text-transform: none;
	}

	.md\:underline {
		text-decoration: underline;
	}

	.md\:line-through {
		text-decoration: line-through;
	}

	.md\:no-underline {
		text-decoration: none;
	}

	.md\:hover\:underline:hover {
		text-decoration: underline;
	}

	.md\:hover\:line-through:hover {
		text-decoration: line-through;
	}

	.md\:hover\:no-underline:hover {
		text-decoration: none;
	}

	.md\:focus\:underline:focus {
		text-decoration: underline;
	}

	.md\:focus\:line-through:focus {
		text-decoration: line-through;
	}

	.md\:focus\:no-underline:focus {
		text-decoration: none;
	}

	.md\:antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.md\:subpixel-antialiased {
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;
	}

	.md\:tracking-tighter {
		letter-spacing: -0.05em;
	}

	.md\:tracking-tight {
		letter-spacing: -0.025em;
	}

	.md\:tracking-normal {
		letter-spacing: 0;
	}

	.md\:tracking-wide {
		letter-spacing: 0.025em;
	}

	.md\:tracking-wider {
		letter-spacing: 0.05em;
	}

	.md\:tracking-widest {
		letter-spacing: 0.1em;
	}

	.md\:select-none {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.md\:select-text {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.md\:select-all {
		-webkit-user-select: all;
		-moz-user-select: all;
		-ms-user-select: all;
		user-select: all;
	}

	.md\:select-auto {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.md\:align-baseline {
		vertical-align: baseline;
	}

	.md\:align-top {
		vertical-align: top;
	}

	.md\:align-middle {
		vertical-align: middle;
	}

	.md\:align-bottom {
		vertical-align: bottom;
	}

	.md\:align-text-top {
		vertical-align: text-top;
	}

	.md\:align-text-bottom {
		vertical-align: text-bottom;
	}

	.md\:visible {
		visibility: visible;
	}

	.md\:invisible {
		visibility: hidden;
	}

	.md\:whitespace-normal {
		white-space: normal;
	}

	.md\:whitespace-no-wrap {
		white-space: nowrap;
	}

	.md\:whitespace-pre {
		white-space: pre;
	}

	.md\:whitespace-pre-line {
		white-space: pre-line;
	}

	.md\:whitespace-pre-wrap {
		white-space: pre-wrap;
	}

	.md\:break-normal {
		overflow-wrap: normal;
		word-break: normal;
	}

	.md\:break-words {
		overflow-wrap: break-word;
	}

	.md\:break-all {
		word-break: break-all;
	}

	.md\:truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.md\:w-0 {
		width: 0;
	}

	.md\:w-1 {
		width: 0.25rem;
	}

	.md\:w-2 {
		width: 0.5rem;
	}

	.md\:w-3 {
		width: 0.75rem;
	}

	.md\:w-4 {
		width: 0.4rem;
	}

	.md\:w-5 {
		width: 1.25rem;
	}

	.md\:w-6 {
		width: 0.6rem;
	}

	.md\:w-8 {
		width: 0.8rem;
	}

	.md\:w-10 {
		width: 2.5rem;
	}

	.md\:w-12 {
		width: 1.2rem;
	}

	.md\:w-16 {
		width: 1.6rem;
	}

	.md\:w-20 {
		width: 2rem;
	}

	.md\:w-24 {
		width: 2.4rem;
	}

	.md\:w-28 {
		width: 2.8rem;
	}

	.md\:w-32 {
		width: 3.2rem;
	}

	.md\:w-36 {
		width: 3.6rem;
	}

	.md\:w-40 {
		width: 4rem;
	}

	.md\:w-44 {
		width: 4.4rem;
	}

	.md\:w-48 {
		width: 4.8rem;
	}

	.md\:w-52 {
		width: 5.2rem;
	}

	.md\:w-56 {
		width: 5.6rem;
	}

	.md\:w-60 {
		width: 6rem;
	}

	.md\:w-64 {
		width: 6.4rem;
	}

	.md\:w-68 {
		width: 6.8rem;
	}

	.md\:w-72 {
		width: 7.2rem;
	}

	.md\:w-76 {
		width: 7.6rem;
	}

	.md\:w-80 {
		width: 8rem;
	}

	.md\:w-84 {
		width: 8.4rem;
	}

	.md\:w-88 {
		width: 8.8rem;
	}

	.md\:w-92 {
		width: 9.2rem;
	}

	.md\:w-96 {
		width: 9.6rem;
	}

	.md\:w-128 {
		width: 12.8rem;
	}

	.md\:w-136 {
		width: 13.6rem;
	}

	.md\:w-160 {
		width: 16rem;
	}

	.md\:w-192 {
		width: 19.2rem;
	}

	.md\:w-200 {
		width: 20rem;
	}

	.md\:w-208 {
		width: 20.8rem;
	}

	.md\:w-216 {
		width: 21.6rem;
	}

	.md\:w-224 {
		width: 22.4rem;
	}

	.md\:w-256 {
		width: 25.6rem;
	}

	.md\:w-288 {
		width: 28.8rem;
	}

	.md\:w-320 {
		width: 32rem;
	}

	.md\:w-360 {
		width: 36rem;
	}

	.md\:w-384 {
		width: 38.4rem;
	}

	.md\:w-400 {
		width: 40rem;
	}

	.md\:w-512 {
		width: 51.2rem;
	}

	.md\:w-640 {
		width: 64rem;
	}

	.md\:w-auto {
		width: auto;
	}

	.md\:w-px {
		width: 1px;
	}

	.md\:w-xs {
		width: 32rem;
	}

	.md\:w-sm {
		width: 48rem;
	}

	.md\:w-md {
		width: 64rem;
	}

	.md\:w-lg {
		width: 80rem;
	}

	.md\:w-xl {
		width: 96rem;
	}

	.md\:w-2xl {
		width: 112rem;
	}

	.md\:w-3xl {
		width: 128rem;
	}

	.md\:w-4xl {
		width: 144rem;
	}

	.md\:w-5xl {
		width: 160rem;
	}

	.md\:w-1\/2 {
		width: 50%;
	}

	.md\:w-1\/3 {
		width: 33.33333%;
	}

	.md\:w-2\/3 {
		width: 66.66667%;
	}

	.md\:w-1\/4 {
		width: 25%;
	}

	.md\:w-2\/4 {
		width: 50%;
	}

	.md\:w-3\/4 {
		width: 75%;
	}

	.md\:w-1\/5 {
		width: 20%;
	}

	.md\:w-2\/5 {
		width: 40%;
	}

	.md\:w-3\/5 {
		width: 60%;
	}

	.md\:w-4\/5 {
		width: 80%;
	}

	.md\:w-1\/6 {
		width: 16.66667%;
	}

	.md\:w-2\/6 {
		width: 33.33333%;
	}

	.md\:w-3\/6 {
		width: 50%;
	}

	.md\:w-4\/6 {
		width: 66.66667%;
	}

	.md\:w-5\/6 {
		width: 83.33333%;
	}

	.md\:w-1\/12 {
		width: 8.33333%;
	}

	.md\:w-2\/12 {
		width: 16.66667%;
	}

	.md\:w-3\/12 {
		width: 25%;
	}

	.md\:w-4\/12 {
		width: 33.33333%;
	}

	.md\:w-5\/12 {
		width: 41.66667%;
	}

	.md\:w-6\/12 {
		width: 50%;
	}

	.md\:w-7\/12 {
		width: 58.33333%;
	}

	.md\:w-8\/12 {
		width: 66.66667%;
	}

	.md\:w-9\/12 {
		width: 75%;
	}

	.md\:w-10\/12 {
		width: 83.33333%;
	}

	.md\:w-11\/12 {
		width: 91.66667%;
	}

	.md\:w-full {
		width: 100%;
	}

	.md\:w-screen {
		width: 100vw;
	}

	.md\:z-0 {
		z-index: 0;
	}

	.md\:z-10 {
		z-index: 10;
	}

	.md\:z-20 {
		z-index: 20;
	}

	.md\:z-30 {
		z-index: 30;
	}

	.md\:z-40 {
		z-index: 40;
	}

	.md\:z-50 {
		z-index: 50;
	}

	.md\:z-99 {
		z-index: 99;
	}

	.md\:z-999 {
		z-index: 999;
	}

	.md\:z-9999 {
		z-index: 9999;
	}

	.md\:z-auto {
		z-index: auto;
	}
}

@media (min-width: 1280px) {
	.lg\:appearance-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.lg\:bg-fixed {
		background-attachment: fixed;
	}

	.lg\:bg-local {
		background-attachment: local;
	}

	.lg\:bg-scroll {
		background-attachment: scroll;
	}

	.lg\:bg-transparent {
		background-color: transparent;
	}

	.lg\:bg-black {
		background-color: #22292f;
	}

	.lg\:bg-white {
		background-color: #ffffff;
	}

	.lg\:bg-gray-100 {
		background-color: #f7fafc;
	}

	.lg\:bg-gray-200 {
		background-color: #edf2f7;
	}

	.lg\:bg-gray-300 {
		background-color: #e2e8f0;
	}

	.lg\:bg-gray-400 {
		background-color: #cbd5e0;
	}

	.lg\:bg-gray-500 {
		background-color: #a0aec0;
	}

	.lg\:bg-gray-600 {
		background-color: #718096;
	}

	.lg\:bg-gray-700 {
		background-color: #4a5568;
	}

	.lg\:bg-gray-800 {
		background-color: #2d3748;
	}

	.lg\:bg-gray-900 {
		background-color: #1a202c;
	}

	.lg\:bg-red {
		background-color: #e3342f;
	}

	.lg\:bg-orange {
		background-color: #f6993f;
	}

	.lg\:bg-yellow {
		background-color: #ffed4a;
	}

	.lg\:bg-green {
		background-color: #38c172;
	}

	.lg\:bg-teal {
		background-color: #4dc0b5;
	}

	.lg\:bg-blue {
		background-color: #3490dc;
	}

	.lg\:bg-indigo {
		background-color: #6574cd;
	}

	.lg\:bg-purple {
		background-color: #9561e2;
	}

	.lg\:bg-pink {
		background-color: #f66d9b;
	}

	.lg\:bg-grey-darkest {
		background-color: #3d4852;
	}

	.lg\:bg-grey-darker {
		background-color: #606f7b;
	}

	.lg\:bg-grey-dark {
		background-color: #8795a1;
	}

	.lg\:bg-grey {
		background-color: #b8c2cc;
	}

	.lg\:bg-grey-light {
		background-color: #dae1e7;
	}

	.lg\:bg-grey-lighter {
		background-color: #f1f5f8;
	}

	.lg\:bg-grey-lightest {
		background-color: #f8fafc;
	}

	.lg\:bg-red-darkest {
		background-color: #3b0d0c;
	}

	.lg\:bg-red-darker {
		background-color: #621b18;
	}

	.lg\:bg-red-dark {
		background-color: #cc1f1a;
	}

	.lg\:bg-red-light {
		background-color: #ef5753;
	}

	.lg\:bg-red-lighter {
		background-color: #f9acaa;
	}

	.lg\:bg-red-lightest {
		background-color: #fcebea;
	}

	.lg\:bg-orange-darkest {
		background-color: #462a16;
	}

	.lg\:bg-orange-darker {
		background-color: #613b1f;
	}

	.lg\:bg-orange-dark {
		background-color: #de751f;
	}

	.lg\:bg-orange-light {
		background-color: #faad63;
	}

	.lg\:bg-orange-lighter {
		background-color: #fcd9b6;
	}

	.lg\:bg-orange-lightest {
		background-color: #fff5eb;
	}

	.lg\:bg-yellow-darkest {
		background-color: #453411;
	}

	.lg\:bg-yellow-darker {
		background-color: #684f1d;
	}

	.lg\:bg-yellow-dark {
		background-color: #f2d024;
	}

	.lg\:bg-yellow-light {
		background-color: #fff382;
	}

	.lg\:bg-yellow-lighter {
		background-color: #fff9c2;
	}

	.lg\:bg-yellow-lightest {
		background-color: #fcfbeb;
	}

	.lg\:bg-green-darkest {
		background-color: #0f2f21;
	}

	.lg\:bg-green-darker {
		background-color: #1a4731;
	}

	.lg\:bg-green-dark {
		background-color: #1f9d55;
	}

	.lg\:bg-green-light {
		background-color: #51d88a;
	}

	.lg\:bg-green-lighter {
		background-color: #a2f5bf;
	}

	.lg\:bg-green-lightest {
		background-color: #e3fcec;
	}

	.lg\:bg-teal-darkest {
		background-color: #0d3331;
	}

	.lg\:bg-teal-darker {
		background-color: #20504f;
	}

	.lg\:bg-teal-dark {
		background-color: #38a89d;
	}

	.lg\:bg-teal-light {
		background-color: #64d5ca;
	}

	.lg\:bg-teal-lighter {
		background-color: #a0f0ed;
	}

	.lg\:bg-teal-lightest {
		background-color: #e8fffe;
	}

	.lg\:bg-blue-darkest {
		background-color: #12283a;
	}

	.lg\:bg-blue-darker {
		background-color: #1c3d5a;
	}

	.lg\:bg-blue-dark {
		background-color: #2779bd;
	}

	.lg\:bg-blue-light {
		background-color: #6cb2eb;
	}

	.lg\:bg-blue-lighter {
		background-color: #bcdefa;
	}

	.lg\:bg-blue-lightest {
		background-color: #eff8ff;
	}

	.lg\:bg-indigo-darkest {
		background-color: #191e38;
	}

	.lg\:bg-indigo-darker {
		background-color: #2f365f;
	}

	.lg\:bg-indigo-dark {
		background-color: #5661b3;
	}

	.lg\:bg-indigo-light {
		background-color: #7886d7;
	}

	.lg\:bg-indigo-lighter {
		background-color: #b2b7ff;
	}

	.lg\:bg-indigo-lightest {
		background-color: #e6e8ff;
	}

	.lg\:bg-purple-darkest {
		background-color: #21183c;
	}

	.lg\:bg-purple-darker {
		background-color: #22184b;
	}

	.lg\:bg-purple-dark {
		background-color: #794acf;
	}

	.lg\:bg-purple-light {
		background-color: #a779e9;
	}

	.lg\:bg-purple-lighter {
		background-color: #d6bbfc;
	}

	.lg\:bg-purple-lightest {
		background-color: #f3ebff;
	}

	.lg\:bg-pink-darkest {
		background-color: #451225;
	}

	.lg\:bg-pink-darker {
		background-color: #6f213f;
	}

	.lg\:bg-pink-dark {
		background-color: #eb5286;
	}

	.lg\:bg-pink-light {
		background-color: #fa7ea8;
	}

	.lg\:bg-pink-lighter {
		background-color: #ffbbca;
	}

	.lg\:bg-pink-lightest {
		background-color: #ffebef;
	}

	.lg\:hover\:bg-transparent:hover {
		background-color: transparent;
	}

	.lg\:hover\:bg-black:hover {
		background-color: #22292f;
	}

	.lg\:hover\:bg-white:hover {
		background-color: #ffffff;
	}

	.lg\:hover\:bg-gray-100:hover {
		background-color: #f7fafc;
	}

	.lg\:hover\:bg-gray-200:hover {
		background-color: #edf2f7;
	}

	.lg\:hover\:bg-gray-300:hover {
		background-color: #e2e8f0;
	}

	.lg\:hover\:bg-gray-400:hover {
		background-color: #cbd5e0;
	}

	.lg\:hover\:bg-gray-500:hover {
		background-color: #a0aec0;
	}

	.lg\:hover\:bg-gray-600:hover {
		background-color: #718096;
	}

	.lg\:hover\:bg-gray-700:hover {
		background-color: #4a5568;
	}

	.lg\:hover\:bg-gray-800:hover {
		background-color: #2d3748;
	}

	.lg\:hover\:bg-gray-900:hover {
		background-color: #1a202c;
	}

	.lg\:hover\:bg-red:hover {
		background-color: #e3342f;
	}

	.lg\:hover\:bg-orange:hover {
		background-color: #f6993f;
	}

	.lg\:hover\:bg-yellow:hover {
		background-color: #ffed4a;
	}

	.lg\:hover\:bg-green:hover {
		background-color: #38c172;
	}

	.lg\:hover\:bg-teal:hover {
		background-color: #4dc0b5;
	}

	.lg\:hover\:bg-blue:hover {
		background-color: #3490dc;
	}

	.lg\:hover\:bg-indigo:hover {
		background-color: #6574cd;
	}

	.lg\:hover\:bg-purple:hover {
		background-color: #9561e2;
	}

	.lg\:hover\:bg-pink:hover {
		background-color: #f66d9b;
	}

	.lg\:hover\:bg-grey-darkest:hover {
		background-color: #3d4852;
	}

	.lg\:hover\:bg-grey-darker:hover {
		background-color: #606f7b;
	}

	.lg\:hover\:bg-grey-dark:hover {
		background-color: #8795a1;
	}

	.lg\:hover\:bg-grey:hover {
		background-color: #b8c2cc;
	}

	.lg\:hover\:bg-grey-light:hover {
		background-color: #dae1e7;
	}

	.lg\:hover\:bg-grey-lighter:hover {
		background-color: #f1f5f8;
	}

	.lg\:hover\:bg-grey-lightest:hover {
		background-color: #f8fafc;
	}

	.lg\:hover\:bg-red-darkest:hover {
		background-color: #3b0d0c;
	}

	.lg\:hover\:bg-red-darker:hover {
		background-color: #621b18;
	}

	.lg\:hover\:bg-red-dark:hover {
		background-color: #cc1f1a;
	}

	.lg\:hover\:bg-red-light:hover {
		background-color: #ef5753;
	}

	.lg\:hover\:bg-red-lighter:hover {
		background-color: #f9acaa;
	}

	.lg\:hover\:bg-red-lightest:hover {
		background-color: #fcebea;
	}

	.lg\:hover\:bg-orange-darkest:hover {
		background-color: #462a16;
	}

	.lg\:hover\:bg-orange-darker:hover {
		background-color: #613b1f;
	}

	.lg\:hover\:bg-orange-dark:hover {
		background-color: #de751f;
	}

	.lg\:hover\:bg-orange-light:hover {
		background-color: #faad63;
	}

	.lg\:hover\:bg-orange-lighter:hover {
		background-color: #fcd9b6;
	}

	.lg\:hover\:bg-orange-lightest:hover {
		background-color: #fff5eb;
	}

	.lg\:hover\:bg-yellow-darkest:hover {
		background-color: #453411;
	}

	.lg\:hover\:bg-yellow-darker:hover {
		background-color: #684f1d;
	}

	.lg\:hover\:bg-yellow-dark:hover {
		background-color: #f2d024;
	}

	.lg\:hover\:bg-yellow-light:hover {
		background-color: #fff382;
	}

	.lg\:hover\:bg-yellow-lighter:hover {
		background-color: #fff9c2;
	}

	.lg\:hover\:bg-yellow-lightest:hover {
		background-color: #fcfbeb;
	}

	.lg\:hover\:bg-green-darkest:hover {
		background-color: #0f2f21;
	}

	.lg\:hover\:bg-green-darker:hover {
		background-color: #1a4731;
	}

	.lg\:hover\:bg-green-dark:hover {
		background-color: #1f9d55;
	}

	.lg\:hover\:bg-green-light:hover {
		background-color: #51d88a;
	}

	.lg\:hover\:bg-green-lighter:hover {
		background-color: #a2f5bf;
	}

	.lg\:hover\:bg-green-lightest:hover {
		background-color: #e3fcec;
	}

	.lg\:hover\:bg-teal-darkest:hover {
		background-color: #0d3331;
	}

	.lg\:hover\:bg-teal-darker:hover {
		background-color: #20504f;
	}

	.lg\:hover\:bg-teal-dark:hover {
		background-color: #38a89d;
	}

	.lg\:hover\:bg-teal-light:hover {
		background-color: #64d5ca;
	}

	.lg\:hover\:bg-teal-lighter:hover {
		background-color: #a0f0ed;
	}

	.lg\:hover\:bg-teal-lightest:hover {
		background-color: #e8fffe;
	}

	.lg\:hover\:bg-blue-darkest:hover {
		background-color: #12283a;
	}

	.lg\:hover\:bg-blue-darker:hover {
		background-color: #1c3d5a;
	}

	.lg\:hover\:bg-blue-dark:hover {
		background-color: #2779bd;
	}

	.lg\:hover\:bg-blue-light:hover {
		background-color: #6cb2eb;
	}

	.lg\:hover\:bg-blue-lighter:hover {
		background-color: #bcdefa;
	}

	.lg\:hover\:bg-blue-lightest:hover {
		background-color: #eff8ff;
	}

	.lg\:hover\:bg-indigo-darkest:hover {
		background-color: #191e38;
	}

	.lg\:hover\:bg-indigo-darker:hover {
		background-color: #2f365f;
	}

	.lg\:hover\:bg-indigo-dark:hover {
		background-color: #5661b3;
	}

	.lg\:hover\:bg-indigo-light:hover {
		background-color: #7886d7;
	}

	.lg\:hover\:bg-indigo-lighter:hover {
		background-color: #b2b7ff;
	}

	.lg\:hover\:bg-indigo-lightest:hover {
		background-color: #e6e8ff;
	}

	.lg\:hover\:bg-purple-darkest:hover {
		background-color: #21183c;
	}

	.lg\:hover\:bg-purple-darker:hover {
		background-color: #382b5f;
	}

	.lg\:hover\:bg-purple-dark:hover {
		background-color: #794acf;
	}

	.lg\:hover\:bg-purple-light:hover {
		background-color: #a779e9;
	}

	.lg\:hover\:bg-purple-lighter:hover {
		background-color: #d6bbfc;
	}

	.lg\:hover\:bg-purple-lightest:hover {
		background-color: #f3ebff;
	}

	.lg\:hover\:bg-pink-darkest:hover {
		background-color: #451225;
	}

	.lg\:hover\:bg-pink-darker:hover {
		background-color: #6f213f;
	}

	.lg\:hover\:bg-pink-dark:hover {
		background-color: #eb5286;
	}

	.lg\:hover\:bg-pink-light:hover {
		background-color: #fa7ea8;
	}

	.lg\:hover\:bg-pink-lighter:hover {
		background-color: #ffbbca;
	}

	.lg\:hover\:bg-pink-lightest:hover {
		background-color: #ffebef;
	}

	.lg\:focus\:bg-transparent:focus {
		background-color: transparent;
	}

	.lg\:focus\:bg-black:focus {
		background-color: #22292f;
	}

	.lg\:focus\:bg-white:focus {
		background-color: #ffffff;
	}

	.lg\:focus\:bg-gray-100:focus {
		background-color: #f7fafc;
	}

	.lg\:focus\:bg-gray-200:focus {
		background-color: #edf2f7;
	}

	.lg\:focus\:bg-gray-300:focus {
		background-color: #e2e8f0;
	}

	.lg\:focus\:bg-gray-400:focus {
		background-color: #cbd5e0;
	}

	.lg\:focus\:bg-gray-500:focus {
		background-color: #a0aec0;
	}

	.lg\:focus\:bg-gray-600:focus {
		background-color: #718096;
	}

	.lg\:focus\:bg-gray-700:focus {
		background-color: #4a5568;
	}

	.lg\:focus\:bg-gray-800:focus {
		background-color: #2d3748;
	}

	.lg\:focus\:bg-gray-900:focus {
		background-color: #1a202c;
	}

	.lg\:focus\:bg-red:focus {
		background-color: #e3342f;
	}

	.lg\:focus\:bg-orange:focus {
		background-color: #f6993f;
	}

	.lg\:focus\:bg-yellow:focus {
		background-color: #ffed4a;
	}

	.lg\:focus\:bg-green:focus {
		background-color: #38c172;
	}

	.lg\:focus\:bg-teal:focus {
		background-color: #4dc0b5;
	}

	.lg\:focus\:bg-blue:focus {
		background-color: #3490dc;
	}

	.lg\:focus\:bg-indigo:focus {
		background-color: #6574cd;
	}

	.lg\:focus\:bg-purple:focus {
		background-color: #9561e2;
	}

	.lg\:focus\:bg-pink:focus {
		background-color: #f66d9b;
	}

	.lg\:focus\:bg-grey-darkest:focus {
		background-color: #3d4852;
	}

	.lg\:focus\:bg-grey-darker:focus {
		background-color: #606f7b;
	}

	.lg\:focus\:bg-grey-dark:focus {
		background-color: #8795a1;
	}

	.lg\:focus\:bg-grey:focus {
		background-color: #b8c2cc;
	}

	.lg\:focus\:bg-grey-light:focus {
		background-color: #dae1e7;
	}

	.lg\:focus\:bg-grey-lighter:focus {
		background-color: #f1f5f8;
	}

	.lg\:focus\:bg-grey-lightest:focus {
		background-color: #f8fafc;
	}

	.lg\:focus\:bg-red-darkest:focus {
		background-color: #3b0d0c;
	}

	.lg\:focus\:bg-red-darker:focus {
		background-color: #621b18;
	}

	.lg\:focus\:bg-red-dark:focus {
		background-color: #cc1f1a;
	}

	.lg\:focus\:bg-red-light:focus {
		background-color: #ef5753;
	}

	.lg\:focus\:bg-red-lighter:focus {
		background-color: #f9acaa;
	}

	.lg\:focus\:bg-red-lightest:focus {
		background-color: #fcebea;
	}

	.lg\:focus\:bg-orange-darkest:focus {
		background-color: #462a16;
	}

	.lg\:focus\:bg-orange-darker:focus {
		background-color: #613b1f;
	}

	.lg\:focus\:bg-orange-dark:focus {
		background-color: #de751f;
	}

	.lg\:focus\:bg-orange-light:focus {
		background-color: #faad63;
	}

	.lg\:focus\:bg-orange-lighter:focus {
		background-color: #fcd9b6;
	}

	.lg\:focus\:bg-orange-lightest:focus {
		background-color: #fff5eb;
	}

	.lg\:focus\:bg-yellow-darkest:focus {
		background-color: #453411;
	}

	.lg\:focus\:bg-yellow-darker:focus {
		background-color: #684f1d;
	}

	.lg\:focus\:bg-yellow-dark:focus {
		background-color: #f2d024;
	}

	.lg\:focus\:bg-yellow-light:focus {
		background-color: #fff382;
	}

	.lg\:focus\:bg-yellow-lighter:focus {
		background-color: #fff9c2;
	}

	.lg\:focus\:bg-yellow-lightest:focus {
		background-color: #fcfbeb;
	}

	.lg\:focus\:bg-green-darkest:focus {
		background-color: #0f2f21;
	}

	.lg\:focus\:bg-green-darker:focus {
		background-color: #1a4731;
	}

	.lg\:focus\:bg-green-dark:focus {
		background-color: #1f9d55;
	}

	.lg\:focus\:bg-green-light:focus {
		background-color: #51d88a;
	}

	.lg\:focus\:bg-green-lighter:focus {
		background-color: #a2f5bf;
	}

	.lg\:focus\:bg-green-lightest:focus {
		background-color: #e3fcec;
	}

	.lg\:focus\:bg-teal-darkest:focus {
		background-color: #0d3331;
	}

	.lg\:focus\:bg-teal-darker:focus {
		background-color: #20504f;
	}

	.lg\:focus\:bg-teal-dark:focus {
		background-color: #38a89d;
	}

	.lg\:focus\:bg-teal-light:focus {
		background-color: #64d5ca;
	}

	.lg\:focus\:bg-teal-lighter:focus {
		background-color: #a0f0ed;
	}

	.lg\:focus\:bg-teal-lightest:focus {
		background-color: #e8fffe;
	}

	.lg\:focus\:bg-blue-darkest:focus {
		background-color: #12283a;
	}

	.lg\:focus\:bg-blue-darker:focus {
		background-color: #1c3d5a;
	}

	.lg\:focus\:bg-blue-dark:focus {
		background-color: #2779bd;
	}

	.lg\:focus\:bg-blue-light:focus {
		background-color: #6cb2eb;
	}

	.lg\:focus\:bg-blue-lighter:focus {
		background-color: #bcdefa;
	}

	.lg\:focus\:bg-blue-lightest:focus {
		background-color: #eff8ff;
	}

	.lg\:focus\:bg-indigo-darkest:focus {
		background-color: #191e38;
	}

	.lg\:focus\:bg-indigo-darker:focus {
		background-color: #2f365f;
	}

	.lg\:focus\:bg-indigo-dark:focus {
		background-color: #5661b3;
	}

	.lg\:focus\:bg-indigo-light:focus {
		background-color: #7886d7;
	}

	.lg\:focus\:bg-indigo-lighter:focus {
		background-color: #b2b7ff;
	}

	.lg\:focus\:bg-indigo-lightest:focus {
		background-color: #e6e8ff;
	}

	.lg\:focus\:bg-purple-darkest:focus {
		background-color: #21183c;
	}

	.lg\:focus\:bg-purple-darker:focus {
		background-color: #382b5f;
	}

	.lg\:focus\:bg-purple-dark:focus {
		background-color: #794acf;
	}

	.lg\:focus\:bg-purple-light:focus {
		background-color: #a779e9;
	}

	.lg\:focus\:bg-purple-lighter:focus {
		background-color: #d6bbfc;
	}

	.lg\:focus\:bg-purple-lightest:focus {
		background-color: #f3ebff;
	}

	.lg\:focus\:bg-pink-darkest:focus {
		background-color: #451225;
	}

	.lg\:focus\:bg-pink-darker:focus {
		background-color: #6f213f;
	}

	.lg\:focus\:bg-pink-dark:focus {
		background-color: #eb5286;
	}

	.lg\:focus\:bg-pink-light:focus {
		background-color: #fa7ea8;
	}

	.lg\:focus\:bg-pink-lighter:focus {
		background-color: #ffbbca;
	}

	.lg\:focus\:bg-pink-lightest:focus {
		background-color: #ffebef;
	}

	.lg\:bg-bottom {
		background-position: bottom;
	}

	.lg\:bg-center {
		background-position: center;
	}

	.lg\:bg-left {
		background-position: left;
	}

	.lg\:bg-left-bottom {
		background-position: left bottom;
	}

	.lg\:bg-left-top {
		background-position: left top;
	}

	.lg\:bg-right {
		background-position: right;
	}

	.lg\:bg-right-bottom {
		background-position: right bottom;
	}

	.lg\:bg-right-top {
		background-position: right top;
	}

	.lg\:bg-top {
		background-position: top;
	}

	.lg\:bg-repeat {
		background-repeat: repeat;
	}

	.lg\:bg-no-repeat {
		background-repeat: no-repeat;
	}

	.lg\:bg-repeat-x {
		background-repeat: repeat-x;
	}

	.lg\:bg-repeat-y {
		background-repeat: repeat-y;
	}

	.lg\:bg-repeat-round {
		background-repeat: round;
	}

	.lg\:bg-repeat-space {
		background-repeat: space;
	}

	.lg\:bg-auto {
		background-size: auto;
	}

	.lg\:bg-cover {
		background-size: cover;
	}

	.lg\:bg-contain {
		background-size: contain;
	}

	.lg\:border-collapse {
		border-collapse: collapse;
	}

	.lg\:border-separate {
		border-collapse: separate;
	}

	.lg\:border-transparent {
		border-color: transparent;
	}

	.lg\:border-black {
		border-color: #22292f;
	}

	.lg\:border-white {
		border-color: #ffffff;
	}

	.lg\:border-gray-100 {
		border-color: #f7fafc;
	}

	.lg\:border-gray-200 {
		border-color: #edf2f7;
	}

	.lg\:border-gray-300 {
		border-color: #e2e8f0;
	}

	.lg\:border-gray-400 {
		border-color: #cbd5e0;
	}

	.lg\:border-gray-500 {
		border-color: #a0aec0;
	}

	.lg\:border-gray-600 {
		border-color: #718096;
	}

	.lg\:border-gray-700 {
		border-color: #4a5568;
	}

	.lg\:border-gray-800 {
		border-color: #2d3748;
	}

	.lg\:border-gray-900 {
		border-color: #1a202c;
	}

	.lg\:border-red {
		border-color: #e3342f;
	}

	.lg\:border-orange {
		border-color: #f6993f;
	}

	.lg\:border-yellow {
		border-color: #ffed4a;
	}

	.lg\:border-green {
		border-color: #38c172;
	}

	.lg\:border-teal {
		border-color: #4dc0b5;
	}

	.lg\:border-blue {
		border-color: #3490dc;
	}

	.lg\:border-indigo {
		border-color: #6574cd;
	}

	.lg\:border-purple {
		border-color: #9561e2;
	}

	.lg\:border-pink {
		border-color: #f66d9b;
	}

	.lg\:border-grey-darkest {
		border-color: #3d4852;
	}

	.lg\:border-grey-darker {
		border-color: #606f7b;
	}

	.lg\:border-grey-dark {
		border-color: #8795a1;
	}

	.lg\:border-grey {
		border-color: #b8c2cc;
	}

	.lg\:border-grey-light {
		border-color: #dae1e7;
	}

	.lg\:border-grey-lighter {
		border-color: #f1f5f8;
	}

	.lg\:border-grey-lightest {
		border-color: #f8fafc;
	}

	.lg\:border-red-darkest {
		border-color: #3b0d0c;
	}

	.lg\:border-red-darker {
		border-color: #621b18;
	}

	.lg\:border-red-dark {
		border-color: #cc1f1a;
	}

	.lg\:border-red-light {
		border-color: #ef5753;
	}

	.lg\:border-red-lighter {
		border-color: #f9acaa;
	}

	.lg\:border-red-lightest {
		border-color: #fcebea;
	}

	.lg\:border-orange-darkest {
		border-color: #462a16;
	}

	.lg\:border-orange-darker {
		border-color: #613b1f;
	}

	.lg\:border-orange-dark {
		border-color: #de751f;
	}

	.lg\:border-orange-light {
		border-color: #faad63;
	}

	.lg\:border-orange-lighter {
		border-color: #fcd9b6;
	}

	.lg\:border-orange-lightest {
		border-color: #fff5eb;
	}

	.lg\:border-yellow-darkest {
		border-color: #453411;
	}

	.lg\:border-yellow-darker {
		border-color: #684f1d;
	}

	.lg\:border-yellow-dark {
		border-color: #f2d024;
	}

	.lg\:border-yellow-light {
		border-color: #fff382;
	}

	.lg\:border-yellow-lighter {
		border-color: #fff9c2;
	}

	.lg\:border-yellow-lightest {
		border-color: #fcfbeb;
	}

	.lg\:border-green-darkest {
		border-color: #0f2f21;
	}

	.lg\:border-green-darker {
		border-color: #1a4731;
	}

	.lg\:border-green-dark {
		border-color: #1f9d55;
	}

	.lg\:border-green-light {
		border-color: #51d88a;
	}

	.lg\:border-green-lighter {
		border-color: #a2f5bf;
	}

	.lg\:border-green-lightest {
		border-color: #e3fcec;
	}

	.lg\:border-teal-darkest {
		border-color: #0d3331;
	}

	.lg\:border-teal-darker {
		border-color: #20504f;
	}

	.lg\:border-teal-dark {
		border-color: #38a89d;
	}

	.lg\:border-teal-light {
		border-color: #64d5ca;
	}

	.lg\:border-teal-lighter {
		border-color: #a0f0ed;
	}

	.lg\:border-teal-lightest {
		border-color: #e8fffe;
	}

	.lg\:border-blue-darkest {
		border-color: #12283a;
	}

	.lg\:border-blue-darker {
		border-color: #1c3d5a;
	}

	.lg\:border-blue-dark {
		border-color: #2779bd;
	}

	.lg\:border-blue-light {
		border-color: #6cb2eb;
	}

	.lg\:border-blue-lighter {
		border-color: #bcdefa;
	}

	.lg\:border-blue-lightest {
		border-color: #eff8ff;
	}

	.lg\:border-indigo-darkest {
		border-color: #191e38;
	}

	.lg\:border-indigo-darker {
		border-color: #2f365f;
	}

	.lg\:border-indigo-dark {
		border-color: #5661b3;
	}

	.lg\:border-indigo-light {
		border-color: #7886d7;
	}

	.lg\:border-indigo-lighter {
		border-color: #b2b7ff;
	}

	.lg\:border-indigo-lightest {
		border-color: #e6e8ff;
	}

	.lg\:border-purple-darkest {
		border-color: #21183c;
	}

	.lg\:border-purple-darker {
		border-color: #382b5f;
	}

	.lg\:border-purple-dark {
		border-color: #794acf;
	}

	.lg\:border-purple-light {
		border-color: #a779e9;
	}

	.lg\:border-purple-lighter {
		border-color: #d6bbfc;
	}

	.lg\:border-purple-lightest {
		border-color: #f3ebff;
	}

	.lg\:border-pink-darkest {
		border-color: #451225;
	}

	.lg\:border-pink-darker {
		border-color: #6f213f;
	}

	.lg\:border-pink-dark {
		border-color: #eb5286;
	}

	.lg\:border-pink-light {
		border-color: #fa7ea8;
	}

	.lg\:border-pink-lighter {
		border-color: #ffbbca;
	}

	.lg\:border-pink-lightest {
		border-color: #ffebef;
	}

	.lg\:hover\:border-transparent:hover {
		border-color: transparent;
	}

	.lg\:hover\:border-black:hover {
		border-color: #22292f;
	}

	.lg\:hover\:border-white:hover {
		border-color: #ffffff;
	}

	.lg\:hover\:border-gray-100:hover {
		border-color: #f7fafc;
	}

	.lg\:hover\:border-gray-200:hover {
		border-color: #edf2f7;
	}

	.lg\:hover\:border-gray-300:hover {
		border-color: #e2e8f0;
	}

	.lg\:hover\:border-gray-400:hover {
		border-color: #cbd5e0;
	}

	.lg\:hover\:border-gray-500:hover {
		border-color: #a0aec0;
	}

	.lg\:hover\:border-gray-600:hover {
		border-color: #718096;
	}

	.lg\:hover\:border-gray-700:hover {
		border-color: #4a5568;
	}

	.lg\:hover\:border-gray-800:hover {
		border-color: #2d3748;
	}

	.lg\:hover\:border-gray-900:hover {
		border-color: #1a202c;
	}

	.lg\:hover\:border-red:hover {
		border-color: #e3342f;
	}

	.lg\:hover\:border-orange:hover {
		border-color: #f6993f;
	}

	.lg\:hover\:border-yellow:hover {
		border-color: #ffed4a;
	}

	.lg\:hover\:border-green:hover {
		border-color: #38c172;
	}

	.lg\:hover\:border-teal:hover {
		border-color: #4dc0b5;
	}

	.lg\:hover\:border-blue:hover {
		border-color: #3490dc;
	}

	.lg\:hover\:border-indigo:hover {
		border-color: #6574cd;
	}

	.lg\:hover\:border-purple:hover {
		border-color: #9561e2;
	}

	.lg\:hover\:border-pink:hover {
		border-color: #f66d9b;
	}

	.lg\:hover\:border-grey-darkest:hover {
		border-color: #3d4852;
	}

	.lg\:hover\:border-grey-darker:hover {
		border-color: #606f7b;
	}

	.lg\:hover\:border-grey-dark:hover {
		border-color: #8795a1;
	}

	.lg\:hover\:border-grey:hover {
		border-color: #b8c2cc;
	}

	.lg\:hover\:border-grey-light:hover {
		border-color: #dae1e7;
	}

	.lg\:hover\:border-grey-lighter:hover {
		border-color: #f1f5f8;
	}

	.lg\:hover\:border-grey-lightest:hover {
		border-color: #f8fafc;
	}

	.lg\:hover\:border-red-darkest:hover {
		border-color: #3b0d0c;
	}

	.lg\:hover\:border-red-darker:hover {
		border-color: #621b18;
	}

	.lg\:hover\:border-red-dark:hover {
		border-color: #cc1f1a;
	}

	.lg\:hover\:border-red-light:hover {
		border-color: #ef5753;
	}

	.lg\:hover\:border-red-lighter:hover {
		border-color: #f9acaa;
	}

	.lg\:hover\:border-red-lightest:hover {
		border-color: #fcebea;
	}

	.lg\:hover\:border-orange-darkest:hover {
		border-color: #462a16;
	}

	.lg\:hover\:border-orange-darker:hover {
		border-color: #613b1f;
	}

	.lg\:hover\:border-orange-dark:hover {
		border-color: #de751f;
	}

	.lg\:hover\:border-orange-light:hover {
		border-color: #faad63;
	}

	.lg\:hover\:border-orange-lighter:hover {
		border-color: #fcd9b6;
	}

	.lg\:hover\:border-orange-lightest:hover {
		border-color: #fff5eb;
	}

	.lg\:hover\:border-yellow-darkest:hover {
		border-color: #453411;
	}

	.lg\:hover\:border-yellow-darker:hover {
		border-color: #684f1d;
	}

	.lg\:hover\:border-yellow-dark:hover {
		border-color: #f2d024;
	}

	.lg\:hover\:border-yellow-light:hover {
		border-color: #fff382;
	}

	.lg\:hover\:border-yellow-lighter:hover {
		border-color: #fff9c2;
	}

	.lg\:hover\:border-yellow-lightest:hover {
		border-color: #fcfbeb;
	}

	.lg\:hover\:border-green-darkest:hover {
		border-color: #0f2f21;
	}

	.lg\:hover\:border-green-darker:hover {
		border-color: #1a4731;
	}

	.lg\:hover\:border-green-dark:hover {
		border-color: #1f9d55;
	}

	.lg\:hover\:border-green-light:hover {
		border-color: #51d88a;
	}

	.lg\:hover\:border-green-lighter:hover {
		border-color: #a2f5bf;
	}

	.lg\:hover\:border-green-lightest:hover {
		border-color: #e3fcec;
	}

	.lg\:hover\:border-teal-darkest:hover {
		border-color: #0d3331;
	}

	.lg\:hover\:border-teal-darker:hover {
		border-color: #20504f;
	}

	.lg\:hover\:border-teal-dark:hover {
		border-color: #38a89d;
	}

	.lg\:hover\:border-teal-light:hover {
		border-color: #64d5ca;
	}

	.lg\:hover\:border-teal-lighter:hover {
		border-color: #a0f0ed;
	}

	.lg\:hover\:border-teal-lightest:hover {
		border-color: #e8fffe;
	}

	.lg\:hover\:border-blue-darkest:hover {
		border-color: #12283a;
	}

	.lg\:hover\:border-blue-darker:hover {
		border-color: #1c3d5a;
	}

	.lg\:hover\:border-blue-dark:hover {
		border-color: #2779bd;
	}

	.lg\:hover\:border-blue-light:hover {
		border-color: #6cb2eb;
	}

	.lg\:hover\:border-blue-lighter:hover {
		border-color: #bcdefa;
	}

	.lg\:hover\:border-blue-lightest:hover {
		border-color: #eff8ff;
	}

	.lg\:hover\:border-indigo-darkest:hover {
		border-color: #191e38;
	}

	.lg\:hover\:border-indigo-darker:hover {
		border-color: #2f365f;
	}

	.lg\:hover\:border-indigo-dark:hover {
		border-color: #5661b3;
	}

	.lg\:hover\:border-indigo-light:hover {
		border-color: #7886d7;
	}

	.lg\:hover\:border-indigo-lighter:hover {
		border-color: #b2b7ff;
	}

	.lg\:hover\:border-indigo-lightest:hover {
		border-color: #e6e8ff;
	}

	.lg\:hover\:border-purple-darkest:hover {
		border-color: #21183c;
	}

	.lg\:hover\:border-purple-darker:hover {
		border-color: #382b5f;
	}

	.lg\:hover\:border-purple-dark:hover {
		border-color: #794acf;
	}

	.lg\:hover\:border-purple-light:hover {
		border-color: #a779e9;
	}

	.lg\:hover\:border-purple-lighter:hover {
		border-color: #d6bbfc;
	}

	.lg\:hover\:border-purple-lightest:hover {
		border-color: #f3ebff;
	}

	.lg\:hover\:border-pink-darkest:hover {
		border-color: #451225;
	}

	.lg\:hover\:border-pink-darker:hover {
		border-color: #6f213f;
	}

	.lg\:hover\:border-pink-dark:hover {
		border-color: #eb5286;
	}

	.lg\:hover\:border-pink-light:hover {
		border-color: #fa7ea8;
	}

	.lg\:hover\:border-pink-lighter:hover {
		border-color: #ffbbca;
	}

	.lg\:hover\:border-pink-lightest:hover {
		border-color: #ffebef;
	}

	.lg\:focus\:border-transparent:focus {
		border-color: transparent;
	}

	.lg\:focus\:border-black:focus {
		border-color: #22292f;
	}

	.lg\:focus\:border-white:focus {
		border-color: #ffffff;
	}

	.lg\:focus\:border-gray-100:focus {
		border-color: #f7fafc;
	}

	.lg\:focus\:border-gray-200:focus {
		border-color: #edf2f7;
	}

	.lg\:focus\:border-gray-300:focus {
		border-color: #e2e8f0;
	}

	.lg\:focus\:border-gray-400:focus {
		border-color: #cbd5e0;
	}

	.lg\:focus\:border-gray-500:focus {
		border-color: #a0aec0;
	}

	.lg\:focus\:border-gray-600:focus {
		border-color: #718096;
	}

	.lg\:focus\:border-gray-700:focus {
		border-color: #4a5568;
	}

	.lg\:focus\:border-gray-800:focus {
		border-color: #2d3748;
	}

	.lg\:focus\:border-gray-900:focus {
		border-color: #1a202c;
	}

	.lg\:focus\:border-red:focus {
		border-color: #e3342f;
	}

	.lg\:focus\:border-orange:focus {
		border-color: #f6993f;
	}

	.lg\:focus\:border-yellow:focus {
		border-color: #ffed4a;
	}

	.lg\:focus\:border-green:focus {
		border-color: #38c172;
	}

	.lg\:focus\:border-teal:focus {
		border-color: #4dc0b5;
	}

	.lg\:focus\:border-blue:focus {
		border-color: #3490dc;
	}

	.lg\:focus\:border-indigo:focus {
		border-color: #6574cd;
	}

	.lg\:focus\:border-purple:focus {
		border-color: #9561e2;
	}

	.lg\:focus\:border-pink:focus {
		border-color: #f66d9b;
	}

	.lg\:focus\:border-grey-darkest:focus {
		border-color: #3d4852;
	}

	.lg\:focus\:border-grey-darker:focus {
		border-color: #606f7b;
	}

	.lg\:focus\:border-grey-dark:focus {
		border-color: #8795a1;
	}

	.lg\:focus\:border-grey:focus {
		border-color: #b8c2cc;
	}

	.lg\:focus\:border-grey-light:focus {
		border-color: #dae1e7;
	}

	.lg\:focus\:border-grey-lighter:focus {
		border-color: #f1f5f8;
	}

	.lg\:focus\:border-grey-lightest:focus {
		border-color: #f8fafc;
	}

	.lg\:focus\:border-red-darkest:focus {
		border-color: #3b0d0c;
	}

	.lg\:focus\:border-red-darker:focus {
		border-color: #621b18;
	}

	.lg\:focus\:border-red-dark:focus {
		border-color: #cc1f1a;
	}

	.lg\:focus\:border-red-light:focus {
		border-color: #ef5753;
	}

	.lg\:focus\:border-red-lighter:focus {
		border-color: #f9acaa;
	}

	.lg\:focus\:border-red-lightest:focus {
		border-color: #fcebea;
	}

	.lg\:focus\:border-orange-darkest:focus {
		border-color: #462a16;
	}

	.lg\:focus\:border-orange-darker:focus {
		border-color: #613b1f;
	}

	.lg\:focus\:border-orange-dark:focus {
		border-color: #de751f;
	}

	.lg\:focus\:border-orange-light:focus {
		border-color: #faad63;
	}

	.lg\:focus\:border-orange-lighter:focus {
		border-color: #fcd9b6;
	}

	.lg\:focus\:border-orange-lightest:focus {
		border-color: #fff5eb;
	}

	.lg\:focus\:border-yellow-darkest:focus {
		border-color: #453411;
	}

	.lg\:focus\:border-yellow-darker:focus {
		border-color: #684f1d;
	}

	.lg\:focus\:border-yellow-dark:focus {
		border-color: #f2d024;
	}

	.lg\:focus\:border-yellow-light:focus {
		border-color: #fff382;
	}

	.lg\:focus\:border-yellow-lighter:focus {
		border-color: #fff9c2;
	}

	.lg\:focus\:border-yellow-lightest:focus {
		border-color: #fcfbeb;
	}

	.lg\:focus\:border-green-darkest:focus {
		border-color: #0f2f21;
	}

	.lg\:focus\:border-green-darker:focus {
		border-color: #1a4731;
	}

	.lg\:focus\:border-green-dark:focus {
		border-color: #1f9d55;
	}

	.lg\:focus\:border-green-light:focus {
		border-color: #51d88a;
	}

	.lg\:focus\:border-green-lighter:focus {
		border-color: #a2f5bf;
	}

	.lg\:focus\:border-green-lightest:focus {
		border-color: #e3fcec;
	}

	.lg\:focus\:border-teal-darkest:focus {
		border-color: #0d3331;
	}

	.lg\:focus\:border-teal-darker:focus {
		border-color: #20504f;
	}

	.lg\:focus\:border-teal-dark:focus {
		border-color: #38a89d;
	}

	.lg\:focus\:border-teal-light:focus {
		border-color: #64d5ca;
	}

	.lg\:focus\:border-teal-lighter:focus {
		border-color: #a0f0ed;
	}

	.lg\:focus\:border-teal-lightest:focus {
		border-color: #e8fffe;
	}

	.lg\:focus\:border-blue-darkest:focus {
		border-color: #12283a;
	}

	.lg\:focus\:border-blue-darker:focus {
		border-color: #1c3d5a;
	}

	.lg\:focus\:border-blue-dark:focus {
		border-color: #2779bd;
	}

	.lg\:focus\:border-blue-light:focus {
		border-color: #6cb2eb;
	}

	.lg\:focus\:border-blue-lighter:focus {
		border-color: #bcdefa;
	}

	.lg\:focus\:border-blue-lightest:focus {
		border-color: #eff8ff;
	}

	.lg\:focus\:border-indigo-darkest:focus {
		border-color: #191e38;
	}

	.lg\:focus\:border-indigo-darker:focus {
		border-color: #2f365f;
	}

	.lg\:focus\:border-indigo-dark:focus {
		border-color: #5661b3;
	}

	.lg\:focus\:border-indigo-light:focus {
		border-color: #7886d7;
	}

	.lg\:focus\:border-indigo-lighter:focus {
		border-color: #b2b7ff;
	}

	.lg\:focus\:border-indigo-lightest:focus {
		border-color: #e6e8ff;
	}

	.lg\:focus\:border-purple-darkest:focus {
		border-color: #21183c;
	}

	.lg\:focus\:border-purple-darker:focus {
		border-color: #382b5f;
	}

	.lg\:focus\:border-purple-dark:focus {
		border-color: #794acf;
	}

	.lg\:focus\:border-purple-light:focus {
		border-color: #a779e9;
	}

	.lg\:focus\:border-purple-lighter:focus {
		border-color: #d6bbfc;
	}

	.lg\:focus\:border-purple-lightest:focus {
		border-color: #f3ebff;
	}

	.lg\:focus\:border-pink-darkest:focus {
		border-color: #451225;
	}

	.lg\:focus\:border-pink-darker:focus {
		border-color: #6f213f;
	}

	.lg\:focus\:border-pink-dark:focus {
		border-color: #eb5286;
	}

	.lg\:focus\:border-pink-light:focus {
		border-color: #fa7ea8;
	}

	.lg\:focus\:border-pink-lighter:focus {
		border-color: #ffbbca;
	}

	.lg\:focus\:border-pink-lightest:focus {
		border-color: #ffebef;
	}

	.lg\:rounded-2 {
		border-radius: 0.2rem;
	}

	.lg\:rounded-4 {
		border-radius: 0.4rem;
	}

	.lg\:rounded-6 {
		border-radius: 0.6rem;
	}

	.lg\:rounded-8 {
		border-radius: 0.8rem;
	}

	.lg\:rounded-12 {
		border-radius: 1.2rem;
	}

	.lg\:rounded-16 {
		border-radius: 1.6rem;
	}

	.lg\:rounded-20 {
		border-radius: 2rem;
	}

	.lg\:rounded-24 {
		border-radius: 2.4rem;
	}

	.lg\:rounded-28 {
		border-radius: 2.8rem;
	}

	.lg\:rounded-32 {
		border-radius: 3.2rem;
	}

	.lg\:rounded-none {
		border-radius: 0;
	}

	.lg\:rounded-sm {
		border-radius: 0.2rem;
	}

	.lg\:rounded {
		border-radius: 0.4rem;
	}

	.lg\:rounded-lg {
		border-radius: 0.8rem;
	}

	.lg\:rounded-full {
		border-radius: 9999px;
	}

	.lg\:rounded-t-2 {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.lg\:rounded-r-2 {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.lg\:rounded-b-2 {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-l-2 {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-t-4 {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.lg\:rounded-r-4 {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.lg\:rounded-b-4 {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-l-4 {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-t-6 {
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
	}

	.lg\:rounded-r-6 {
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
	}

	.lg\:rounded-b-6 {
		border-bottom-right-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.lg\:rounded-l-6 {
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.lg\:rounded-t-8 {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.lg\:rounded-r-8 {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.lg\:rounded-b-8 {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-l-8 {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-t-12 {
		border-top-left-radius: 1.2rem;
		border-top-right-radius: 1.2rem;
	}

	.lg\:rounded-r-12 {
		border-top-right-radius: 1.2rem;
		border-bottom-right-radius: 1.2rem;
	}

	.lg\:rounded-b-12 {
		border-bottom-right-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.lg\:rounded-l-12 {
		border-top-left-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.lg\:rounded-t-16 {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
	}

	.lg\:rounded-r-16 {
		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;
	}

	.lg\:rounded-b-16 {
		border-bottom-right-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.lg\:rounded-l-16 {
		border-top-left-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.lg\:rounded-t-20 {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.lg\:rounded-r-20 {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}

	.lg\:rounded-b-20 {
		border-bottom-right-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.lg\:rounded-l-20 {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.lg\:rounded-t-24 {
		border-top-left-radius: 2.4rem;
		border-top-right-radius: 2.4rem;
	}

	.lg\:rounded-r-24 {
		border-top-right-radius: 2.4rem;
		border-bottom-right-radius: 2.4rem;
	}

	.lg\:rounded-b-24 {
		border-bottom-right-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.lg\:rounded-l-24 {
		border-top-left-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.lg\:rounded-t-28 {
		border-top-left-radius: 2.8rem;
		border-top-right-radius: 2.8rem;
	}

	.lg\:rounded-r-28 {
		border-top-right-radius: 2.8rem;
		border-bottom-right-radius: 2.8rem;
	}

	.lg\:rounded-b-28 {
		border-bottom-right-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.lg\:rounded-l-28 {
		border-top-left-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.lg\:rounded-t-32 {
		border-top-left-radius: 3.2rem;
		border-top-right-radius: 3.2rem;
	}

	.lg\:rounded-r-32 {
		border-top-right-radius: 3.2rem;
		border-bottom-right-radius: 3.2rem;
	}

	.lg\:rounded-b-32 {
		border-bottom-right-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.lg\:rounded-l-32 {
		border-top-left-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.lg\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.lg\:rounded-r-none {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.lg\:rounded-b-none {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.lg\:rounded-l-none {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.lg\:rounded-t-sm {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.lg\:rounded-r-sm {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.lg\:rounded-b-sm {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-l-sm {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-t {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.lg\:rounded-r {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.lg\:rounded-b {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-l {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-t-lg {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.lg\:rounded-r-lg {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.lg\:rounded-b-lg {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-l-lg {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-t-full {
		border-top-left-radius: 9999px;
		border-top-right-radius: 9999px;
	}

	.lg\:rounded-r-full {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}

	.lg\:rounded-b-full {
		border-bottom-right-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.lg\:rounded-l-full {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.lg\:rounded-tl-2 {
		border-top-left-radius: 0.2rem;
	}

	.lg\:rounded-tr-2 {
		border-top-right-radius: 0.2rem;
	}

	.lg\:rounded-br-2 {
		border-bottom-right-radius: 0.2rem;
	}

	.lg\:rounded-bl-2 {
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-tl-4 {
		border-top-left-radius: 0.4rem;
	}

	.lg\:rounded-tr-4 {
		border-top-right-radius: 0.4rem;
	}

	.lg\:rounded-br-4 {
		border-bottom-right-radius: 0.4rem;
	}

	.lg\:rounded-bl-4 {
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-tl-6 {
		border-top-left-radius: 0.6rem;
	}

	.lg\:rounded-tr-6 {
		border-top-right-radius: 0.6rem;
	}

	.lg\:rounded-br-6 {
		border-bottom-right-radius: 0.6rem;
	}

	.lg\:rounded-bl-6 {
		border-bottom-left-radius: 0.6rem;
	}

	.lg\:rounded-tl-8 {
		border-top-left-radius: 0.8rem;
	}

	.lg\:rounded-tr-8 {
		border-top-right-radius: 0.8rem;
	}

	.lg\:rounded-br-8 {
		border-bottom-right-radius: 0.8rem;
	}

	.lg\:rounded-bl-8 {
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-tl-12 {
		border-top-left-radius: 1.2rem;
	}

	.lg\:rounded-tr-12 {
		border-top-right-radius: 1.2rem;
	}

	.lg\:rounded-br-12 {
		border-bottom-right-radius: 1.2rem;
	}

	.lg\:rounded-bl-12 {
		border-bottom-left-radius: 1.2rem;
	}

	.lg\:rounded-tl-16 {
		border-top-left-radius: 1.6rem;
	}

	.lg\:rounded-tr-16 {
		border-top-right-radius: 1.6rem;
	}

	.lg\:rounded-br-16 {
		border-bottom-right-radius: 1.6rem;
	}

	.lg\:rounded-bl-16 {
		border-bottom-left-radius: 1.6rem;
	}

	.lg\:rounded-tl-20 {
		border-top-left-radius: 2rem;
	}

	.lg\:rounded-tr-20 {
		border-top-right-radius: 2rem;
	}

	.lg\:rounded-br-20 {
		border-bottom-right-radius: 2rem;
	}

	.lg\:rounded-bl-20 {
		border-bottom-left-radius: 2rem;
	}

	.lg\:rounded-tl-24 {
		border-top-left-radius: 2.4rem;
	}

	.lg\:rounded-tr-24 {
		border-top-right-radius: 2.4rem;
	}

	.lg\:rounded-br-24 {
		border-bottom-right-radius: 2.4rem;
	}

	.lg\:rounded-bl-24 {
		border-bottom-left-radius: 2.4rem;
	}

	.lg\:rounded-tl-28 {
		border-top-left-radius: 2.8rem;
	}

	.lg\:rounded-tr-28 {
		border-top-right-radius: 2.8rem;
	}

	.lg\:rounded-br-28 {
		border-bottom-right-radius: 2.8rem;
	}

	.lg\:rounded-bl-28 {
		border-bottom-left-radius: 2.8rem;
	}

	.lg\:rounded-tl-32 {
		border-top-left-radius: 3.2rem;
	}

	.lg\:rounded-tr-32 {
		border-top-right-radius: 3.2rem;
	}

	.lg\:rounded-br-32 {
		border-bottom-right-radius: 3.2rem;
	}

	.lg\:rounded-bl-32 {
		border-bottom-left-radius: 3.2rem;
	}

	.lg\:rounded-tl-none {
		border-top-left-radius: 0;
	}

	.lg\:rounded-tr-none {
		border-top-right-radius: 0;
	}

	.lg\:rounded-br-none {
		border-bottom-right-radius: 0;
	}

	.lg\:rounded-bl-none {
		border-bottom-left-radius: 0;
	}

	.lg\:rounded-tl-sm {
		border-top-left-radius: 0.2rem;
	}

	.lg\:rounded-tr-sm {
		border-top-right-radius: 0.2rem;
	}

	.lg\:rounded-br-sm {
		border-bottom-right-radius: 0.2rem;
	}

	.lg\:rounded-bl-sm {
		border-bottom-left-radius: 0.2rem;
	}

	.lg\:rounded-tl {
		border-top-left-radius: 0.4rem;
	}

	.lg\:rounded-tr {
		border-top-right-radius: 0.4rem;
	}

	.lg\:rounded-br {
		border-bottom-right-radius: 0.4rem;
	}

	.lg\:rounded-bl {
		border-bottom-left-radius: 0.4rem;
	}

	.lg\:rounded-tl-lg {
		border-top-left-radius: 0.8rem;
	}

	.lg\:rounded-tr-lg {
		border-top-right-radius: 0.8rem;
	}

	.lg\:rounded-br-lg {
		border-bottom-right-radius: 0.8rem;
	}

	.lg\:rounded-bl-lg {
		border-bottom-left-radius: 0.8rem;
	}

	.lg\:rounded-tl-full {
		border-top-left-radius: 9999px;
	}

	.lg\:rounded-tr-full {
		border-top-right-radius: 9999px;
	}

	.lg\:rounded-br-full {
		border-bottom-right-radius: 9999px;
	}

	.lg\:rounded-bl-full {
		border-bottom-left-radius: 9999px;
	}

	.lg\:border-solid {
		border-style: solid;
	}

	.lg\:border-dashed {
		border-style: dashed;
	}

	.lg\:border-dotted {
		border-style: dotted;
	}

	.lg\:border-none {
		border-style: none;
	}

	.lg\:border-0 {
		border-width: 0;
	}

	.lg\:border-1 {
		border-width: 1px;
	}

	.lg\:border-2 {
		border-width: 2px;
	}

	.lg\:border-3 {
		border-width: 3px;
	}

	.lg\:border-4 {
		border-width: 4px;
	}

	.lg\:border-8 {
		border-width: 8px;
	}

	.lg\:border {
		border-width: 1px;
	}

	.lg\:border-t-0 {
		border-top-width: 0;
	}

	.lg\:border-r-0 {
		border-right-width: 0;
	}

	.lg\:border-b-0 {
		border-bottom-width: 0;
	}

	.lg\:border-l-0 {
		border-left-width: 0;
	}

	.lg\:border-t-1 {
		border-top-width: 1px;
	}

	.lg\:border-r-1 {
		border-right-width: 1px;
	}

	.lg\:border-b-1 {
		border-bottom-width: 1px;
	}

	.lg\:border-l-1 {
		border-left-width: 1px;
	}

	.lg\:border-t-2 {
		border-top-width: 2px;
	}

	.lg\:border-r-2 {
		border-right-width: 2px;
	}

	.lg\:border-b-2 {
		border-bottom-width: 2px;
	}

	.lg\:border-l-2 {
		border-left-width: 2px;
	}

	.lg\:border-t-3 {
		border-top-width: 3px;
	}

	.lg\:border-r-3 {
		border-right-width: 3px;
	}

	.lg\:border-b-3 {
		border-bottom-width: 3px;
	}

	.lg\:border-l-3 {
		border-left-width: 3px;
	}

	.lg\:border-t-4 {
		border-top-width: 4px;
	}

	.lg\:border-r-4 {
		border-right-width: 4px;
	}

	.lg\:border-b-4 {
		border-bottom-width: 4px;
	}

	.lg\:border-l-4 {
		border-left-width: 4px;
	}

	.lg\:border-t-8 {
		border-top-width: 8px;
	}

	.lg\:border-r-8 {
		border-right-width: 8px;
	}

	.lg\:border-b-8 {
		border-bottom-width: 8px;
	}

	.lg\:border-l-8 {
		border-left-width: 8px;
	}

	.lg\:border-t {
		border-top-width: 1px;
	}

	.lg\:border-r {
		border-right-width: 1px;
	}

	.lg\:border-b {
		border-bottom-width: 1px;
	}

	.lg\:border-l {
		border-left-width: 1px;
	}

	.lg\:cursor-auto {
		cursor: auto;
	}

	.lg\:cursor-default {
		cursor: default;
	}

	.lg\:cursor-pointer {
		cursor: pointer;
	}

	.lg\:cursor-wait {
		cursor: wait;
	}

	.lg\:cursor-text {
		cursor: text;
	}

	.lg\:cursor-move {
		cursor: move;
	}

	.lg\:cursor-not-allowed {
		cursor: not-allowed;
	}

	.lg\:block {
		display: block;
	}

	.lg\:inline-block {
		display: inline-block;
	}

	.lg\:inline {
		display: inline;
	}

	.lg\:flex {
		display: -webkit-box;
		display: flex;
	}

	.lg\:inline-flex {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.lg\:table {
		display: table;
	}

	.lg\:table-row {
		display: table-row;
	}

	.lg\:table-cell {
		display: table-cell;
	}

	.lg\:hidden {
		display: none;
	}

	.lg\:hover\:block:hover {
		display: block;
	}

	.lg\:hover\:inline-block:hover {
		display: inline-block;
	}

	.lg\:hover\:inline:hover {
		display: inline;
	}

	.lg\:hover\:flex:hover {
		display: -webkit-box;
		display: flex;
	}

	.lg\:hover\:inline-flex:hover {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.lg\:hover\:table:hover {
		display: table;
	}

	.lg\:hover\:table-row:hover {
		display: table-row;
	}

	.lg\:hover\:table-cell:hover {
		display: table-cell;
	}

	.lg\:hover\:hidden:hover {
		display: none;
	}

	.lg\:focus\:block:focus {
		display: block;
	}

	.lg\:focus\:inline-block:focus {
		display: inline-block;
	}

	.lg\:focus\:inline:focus {
		display: inline;
	}

	.lg\:focus\:flex:focus {
		display: -webkit-box;
		display: flex;
	}

	.lg\:focus\:inline-flex:focus {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.lg\:focus\:table:focus {
		display: table;
	}

	.lg\:focus\:table-row:focus {
		display: table-row;
	}

	.lg\:focus\:table-cell:focus {
		display: table-cell;
	}

	.lg\:focus\:hidden:focus {
		display: none;
	}

	.lg\:flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.lg\:flex-row-reverse {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.lg\:flex-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.lg\:flex-col-reverse {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.lg\:flex-wrap {
		flex-wrap: wrap;
	}

	.lg\:flex-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	.lg\:flex-no-wrap {
		flex-wrap: nowrap;
	}

	.lg\:items-start {
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.lg\:items-end {
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.lg\:items-center {
		-webkit-box-align: center;
		align-items: center;
	}

	.lg\:items-baseline {
		-webkit-box-align: baseline;
		align-items: baseline;
	}

	.lg\:items-stretch {
		-webkit-box-align: stretch;
		align-items: stretch;
	}

	.lg\:self-auto {
		align-self: auto;
	}

	.lg\:self-start {
		align-self: flex-start;
	}

	.lg\:self-end {
		align-self: flex-end;
	}

	.lg\:self-center {
		align-self: center;
	}

	.lg\:self-stretch {
		align-self: stretch;
	}

	.lg\:justify-start {
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.lg\:justify-end {
		-webkit-box-pack: end;
		justify-content: flex-end;
	}

	.lg\:justify-center {
		-webkit-box-pack: center;
		justify-content: center;
	}

	.lg\:justify-between {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.lg\:justify-around {
		justify-content: space-around;
	}

	.lg\:content-center {
		align-content: center;
	}

	.lg\:content-start {
		align-content: flex-start;
	}

	.lg\:content-end {
		align-content: flex-end;
	}

	.lg\:content-between {
		align-content: space-between;
	}

	.lg\:content-around {
		align-content: space-around;
	}

	.lg\:flex-1 {
		-webkit-box-flex: 1;
		flex: 1 1 0%;
	}

	.lg\:flex-auto {
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}

	.lg\:flex-initial {
		-webkit-box-flex: 0;
		flex: 0 1 auto;
	}

	.lg\:flex-none {
		-webkit-box-flex: 0;
		flex: none;
	}

	.lg\:flex-grow-0 {
		-webkit-box-flex: 0;
		flex-grow: 0;
	}

	.lg\:flex-grow {
		-webkit-box-flex: 1;
		flex-grow: 1;
	}

	.lg\:flex-shrink-0 {
		flex-shrink: 0;
	}

	.lg\:flex-shrink {
		flex-shrink: 1;
	}

	.lg\:order-1 {
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	.lg\:order-2 {
		-webkit-box-ordinal-group: 3;
		order: 2;
	}

	.lg\:order-3 {
		-webkit-box-ordinal-group: 4;
		order: 3;
	}

	.lg\:order-4 {
		-webkit-box-ordinal-group: 5;
		order: 4;
	}

	.lg\:order-5 {
		-webkit-box-ordinal-group: 6;
		order: 5;
	}

	.lg\:order-6 {
		-webkit-box-ordinal-group: 7;
		order: 6;
	}

	.lg\:order-7 {
		-webkit-box-ordinal-group: 8;
		order: 7;
	}

	.lg\:order-8 {
		-webkit-box-ordinal-group: 9;
		order: 8;
	}

	.lg\:order-9 {
		-webkit-box-ordinal-group: 10;
		order: 9;
	}

	.lg\:order-10 {
		-webkit-box-ordinal-group: 11;
		order: 10;
	}

	.lg\:order-11 {
		-webkit-box-ordinal-group: 12;
		order: 11;
	}

	.lg\:order-12 {
		-webkit-box-ordinal-group: 13;
		order: 12;
	}

	.lg\:order-first {
		-webkit-box-ordinal-group: -9998;
		order: -9999;
	}

	.lg\:order-last {
		-webkit-box-ordinal-group: 10000;
		order: 9999;
	}

	.lg\:order-none {
		-webkit-box-ordinal-group: 1;
		order: 0;
	}

	.lg\:float-right {
		float: right;
	}

	.lg\:float-left {
		float: left;
	}

	.lg\:float-none {
		float: none;
	}

	.lg\:clearfix:after {
		content: '';
		display: table;
		clear: both;
	}

	.lg\:font-sans {
		font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
			Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
			sans-serif;
	}

	.lg\:font-serif {
		font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
			DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
	}

	.lg\:font-mono {
		font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
			monospace;
	}

	.lg\:font-100 {
		font-weight: 100;
	}

	.lg\:font-200 {
		font-weight: 200;
	}

	.lg\:font-300 {
		font-weight: 300;
	}

	.lg\:font-400 {
		font-weight: 400;
	}

	.lg\:font-500 {
		font-weight: 500;
	}

	.lg\:font-600 {
		font-weight: 600;
	}

	.lg\:font-700 {
		font-weight: 700;
	}

	.lg\:font-800 {
		font-weight: 800;
	}

	.lg\:font-900 {
		font-weight: 900;
	}

	.lg\:font-hairline {
		font-weight: 100;
	}

	.lg\:font-thin {
		font-weight: 200;
	}

	.lg\:font-light {
		font-weight: 300;
	}

	.lg\:font-normal {
		font-weight: 400;
	}

	.lg\:font-medium {
		font-weight: 500;
	}

	.lg\:font-semibold {
		font-weight: 600;
	}

	.lg\:font-bold {
		font-weight: 700;
	}

	.lg\:font-extrabold {
		font-weight: 800;
	}

	.lg\:font-black {
		font-weight: 900;
	}

	.lg\:hover\:font-100:hover {
		font-weight: 100;
	}

	.lg\:hover\:font-200:hover {
		font-weight: 200;
	}

	.lg\:hover\:font-300:hover {
		font-weight: 300;
	}

	.lg\:hover\:font-400:hover {
		font-weight: 400;
	}

	.lg\:hover\:font-500:hover {
		font-weight: 500;
	}

	.lg\:hover\:font-600:hover {
		font-weight: 600;
	}

	.lg\:hover\:font-700:hover {
		font-weight: 700;
	}

	.lg\:hover\:font-800:hover {
		font-weight: 800;
	}

	.lg\:hover\:font-900:hover {
		font-weight: 900;
	}

	.lg\:hover\:font-hairline:hover {
		font-weight: 100;
	}

	.lg\:hover\:font-thin:hover {
		font-weight: 200;
	}

	.lg\:hover\:font-light:hover {
		font-weight: 300;
	}

	.lg\:hover\:font-normal:hover {
		font-weight: 400;
	}

	.lg\:hover\:font-medium:hover {
		font-weight: 500;
	}

	.lg\:hover\:font-semibold:hover {
		font-weight: 600;
	}

	.lg\:hover\:font-bold:hover {
		font-weight: 700;
	}

	.lg\:hover\:font-extrabold:hover {
		font-weight: 800;
	}

	.lg\:hover\:font-black:hover {
		font-weight: 900;
	}

	.lg\:focus\:font-100:focus {
		font-weight: 100;
	}

	.lg\:focus\:font-200:focus {
		font-weight: 200;
	}

	.lg\:focus\:font-300:focus {
		font-weight: 300;
	}

	.lg\:focus\:font-400:focus {
		font-weight: 400;
	}

	.lg\:focus\:font-500:focus {
		font-weight: 500;
	}

	.lg\:focus\:font-600:focus {
		font-weight: 600;
	}

	.lg\:focus\:font-700:focus {
		font-weight: 700;
	}

	.lg\:focus\:font-800:focus {
		font-weight: 800;
	}

	.lg\:focus\:font-900:focus {
		font-weight: 900;
	}

	.lg\:focus\:font-hairline:focus {
		font-weight: 100;
	}

	.lg\:focus\:font-thin:focus {
		font-weight: 200;
	}

	.lg\:focus\:font-light:focus {
		font-weight: 300;
	}

	.lg\:focus\:font-normal:focus {
		font-weight: 400;
	}

	.lg\:focus\:font-medium:focus {
		font-weight: 500;
	}

	.lg\:focus\:font-semibold:focus {
		font-weight: 600;
	}

	.lg\:focus\:font-bold:focus {
		font-weight: 700;
	}

	.lg\:focus\:font-extrabold:focus {
		font-weight: 800;
	}

	.lg\:focus\:font-black:focus {
		font-weight: 900;
	}

	.lg\:h-0 {
		height: 0;
	}

	.lg\:h-1 {
		height: 0.25rem;
	}

	.lg\:h-2 {
		height: 0.5rem;
	}

	.lg\:h-3 {
		height: 0.75rem;
	}

	.lg\:h-4 {
		height: 0.4rem;
	}

	.lg\:h-5 {
		height: 1.25rem;
	}

	.lg\:h-6 {
		height: 0.6rem;
	}

	.lg\:h-8 {
		height: 0.8rem;
	}

	.lg\:h-10 {
		height: 2.5rem;
	}

	.lg\:h-12 {
		height: 1.2rem;
	}

	.lg\:h-16 {
		height: 1.6rem;
	}

	.lg\:h-20 {
		height: 2rem;
	}

	.lg\:h-24 {
		height: 2.4rem;
	}

	.lg\:h-28 {
		height: 2.8rem;
	}

	.lg\:h-32 {
		height: 3.2rem;
	}

	.lg\:h-36 {
		height: 3.6rem;
	}

	.lg\:h-40 {
		height: 4rem;
	}

	.lg\:h-44 {
		height: 4.4rem;
	}

	.lg\:h-48 {
		height: 4.8rem;
	}

	.lg\:h-52 {
		height: 5.2rem;
	}

	.lg\:h-56 {
		height: 5.6rem;
	}

	.lg\:h-60 {
		height: 6rem;
	}

	.lg\:h-64 {
		height: 6.4rem;
	}

	.lg\:h-68 {
		height: 6.8rem;
	}

	.lg\:h-72 {
		height: 7.2rem;
	}

	.lg\:h-76 {
		height: 7.6rem;
	}

	.lg\:h-80 {
		height: 8rem;
	}

	.lg\:h-84 {
		height: 8.4rem;
	}

	.lg\:h-88 {
		height: 8.8rem;
	}

	.lg\:h-92 {
		height: 9.2rem;
	}

	.lg\:h-96 {
		height: 9.6rem;
	}

	.lg\:h-128 {
		height: 12.8rem;
	}

	.lg\:h-136 {
		height: 13.6rem;
	}

	.lg\:h-160 {
		height: 16rem;
	}

	.lg\:h-192 {
		height: 19.2rem;
	}

	.lg\:h-200 {
		height: 20rem;
	}

	.lg\:h-208 {
		height: 20.8rem;
	}

	.lg\:h-216 {
		height: 21.6rem;
	}

	.lg\:h-224 {
		height: 22.4rem;
	}

	.lg\:h-256 {
		height: 25.6rem;
	}

	.lg\:h-288 {
		height: 28.8rem;
	}

	.lg\:h-320 {
		height: 32rem;
	}

	.lg\:h-360 {
		height: 36rem;
	}

	.lg\:h-384 {
		height: 38.4rem;
	}

	.lg\:h-400 {
		height: 40rem;
	}

	.lg\:h-512 {
		height: 51.2rem;
	}

	.lg\:h-640 {
		height: 64rem;
	}

	.lg\:h-auto {
		height: auto;
	}

	.lg\:h-px {
		height: 1px;
	}

	.lg\:h-xs {
		height: 32rem;
	}

	.lg\:h-sm {
		height: 48rem;
	}

	.lg\:h-md {
		height: 64rem;
	}

	.lg\:h-lg {
		height: 80rem;
	}

	.lg\:h-xl {
		height: 96rem;
	}

	.lg\:h-2xl {
		height: 112rem;
	}

	.lg\:h-3xl {
		height: 128rem;
	}

	.lg\:h-4xl {
		height: 144rem;
	}

	.lg\:h-5xl {
		height: 160rem;
	}

	.lg\:h-full {
		height: 100%;
	}

	.lg\:h-screen {
		height: 100vh;
	}

	.lg\:leading-none {
		line-height: 1;
	}

	.lg\:leading-tight {
		line-height: 1.25;
	}

	.lg\:leading-snug {
		line-height: 1.375;
	}

	.lg\:leading-normal {
		line-height: 1.5;
	}

	.lg\:leading-relaxed {
		line-height: 1.625;
	}

	.lg\:leading-loose {
		line-height: 2;
	}

	.lg\:list-inside {
		list-style-position: inside;
	}

	.lg\:list-outside {
		list-style-position: outside;
	}

	.lg\:list-none {
		list-style-type: none;
	}

	.lg\:list-disc {
		list-style-type: disc;
	}

	.lg\:list-decimal {
		list-style-type: decimal;
	}

	.lg\:m-0 {
		margin: 0;
	}

	.lg\:m-1 {
		margin: 0.25rem;
	}

	.lg\:m-2 {
		margin: 0.5rem;
	}

	.lg\:m-3 {
		margin: 0.75rem;
	}

	.lg\:m-4 {
		margin: 0.4rem;
	}

	.lg\:m-5 {
		margin: 1.25rem;
	}

	.lg\:m-6 {
		margin: 0.6rem;
	}

	.lg\:m-8 {
		margin: 0.8rem;
	}

	.lg\:m-10 {
		margin: 2.5rem;
	}

	.lg\:m-12 {
		margin: 1.2rem;
	}

	.lg\:m-16 {
		margin: 1.6rem;
	}

	.lg\:m-20 {
		margin: 2rem;
	}

	.lg\:m-24 {
		margin: 2.4rem;
	}

	.lg\:m-28 {
		margin: 2.8rem;
	}

	.lg\:m-32 {
		margin: 3.2rem;
	}

	.lg\:m-36 {
		margin: 3.6rem;
	}

	.lg\:m-40 {
		margin: 4rem;
	}

	.lg\:m-44 {
		margin: 4.4rem;
	}

	.lg\:m-48 {
		margin: 4.8rem;
	}

	.lg\:m-52 {
		margin: 5.2rem;
	}

	.lg\:m-56 {
		margin: 5.6rem;
	}

	.lg\:m-60 {
		margin: 6rem;
	}

	.lg\:m-64 {
		margin: 6.4rem;
	}

	.lg\:m-68 {
		margin: 6.8rem;
	}

	.lg\:m-72 {
		margin: 7.2rem;
	}

	.lg\:m-76 {
		margin: 7.6rem;
	}

	.lg\:m-80 {
		margin: 8rem;
	}

	.lg\:m-84 {
		margin: 8.4rem;
	}

	.lg\:m-88 {
		margin: 8.8rem;
	}

	.lg\:m-92 {
		margin: 9.2rem;
	}

	.lg\:m-96 {
		margin: 9.6rem;
	}

	.lg\:m-128 {
		margin: 12.8rem;
	}

	.lg\:m-136 {
		margin: 13.6rem;
	}

	.lg\:m-160 {
		margin: 16rem;
	}

	.lg\:m-192 {
		margin: 19.2rem;
	}

	.lg\:m-200 {
		margin: 20rem;
	}

	.lg\:m-208 {
		margin: 20.8rem;
	}

	.lg\:m-216 {
		margin: 21.6rem;
	}

	.lg\:m-224 {
		margin: 22.4rem;
	}

	.lg\:m-256 {
		margin: 25.6rem;
	}

	.lg\:m-288 {
		margin: 28.8rem;
	}

	.lg\:m-320 {
		margin: 32rem;
	}

	.lg\:m-360 {
		margin: 36rem;
	}

	.lg\:m-384 {
		margin: 38.4rem;
	}

	.lg\:m-400 {
		margin: 40rem;
	}

	.lg\:m-512 {
		margin: 51.2rem;
	}

	.lg\:m-640 {
		margin: 64rem;
	}

	.lg\:m-auto {
		margin: auto;
	}

	.lg\:m-px {
		margin: 1px;
	}

	.lg\:m-xs {
		margin: 32rem;
	}

	.lg\:m-sm {
		margin: 48rem;
	}

	.lg\:m-md {
		margin: 64rem;
	}

	.lg\:m-lg {
		margin: 80rem;
	}

	.lg\:m-xl {
		margin: 96rem;
	}

	.lg\:m-2xl {
		margin: 112rem;
	}

	.lg\:m-3xl {
		margin: 128rem;
	}

	.lg\:m-4xl {
		margin: 144rem;
	}

	.lg\:m-5xl {
		margin: 160rem;
	}

	.lg\:-m-1 {
		margin: -0.25rem;
	}

	.lg\:-m-2 {
		margin: -0.5rem;
	}

	.lg\:-m-3 {
		margin: -0.75rem;
	}

	.lg\:-m-4 {
		margin: -0.4rem;
	}

	.lg\:-m-5 {
		margin: -1.25rem;
	}

	.lg\:-m-6 {
		margin: -0.6rem;
	}

	.lg\:-m-8 {
		margin: -0.8rem;
	}

	.lg\:-m-10 {
		margin: -2.5rem;
	}

	.lg\:-m-12 {
		margin: -1.2rem;
	}

	.lg\:-m-16 {
		margin: -1.6rem;
	}

	.lg\:-m-20 {
		margin: -2rem;
	}

	.lg\:-m-24 {
		margin: -2.4rem;
	}

	.lg\:-m-28 {
		margin: -2.8rem;
	}

	.lg\:-m-32 {
		margin: -3.2rem;
	}

	.lg\:-m-36 {
		margin: -3.6rem;
	}

	.lg\:-m-40 {
		margin: -4rem;
	}

	.lg\:-m-44 {
		margin: -4.4rem;
	}

	.lg\:-m-48 {
		margin: -4.8rem;
	}

	.lg\:-m-52 {
		margin: -5.2rem;
	}

	.lg\:-m-56 {
		margin: -5.6rem;
	}

	.lg\:-m-60 {
		margin: -6rem;
	}

	.lg\:-m-64 {
		margin: -6.4rem;
	}

	.lg\:-m-68 {
		margin: -6.8rem;
	}

	.lg\:-m-72 {
		margin: -7.2rem;
	}

	.lg\:-m-76 {
		margin: -7.6rem;
	}

	.lg\:-m-80 {
		margin: -8rem;
	}

	.lg\:-m-84 {
		margin: -8.4rem;
	}

	.lg\:-m-88 {
		margin: -8.8rem;
	}

	.lg\:-m-92 {
		margin: -9.2rem;
	}

	.lg\:-m-96 {
		margin: -9.6rem;
	}

	.lg\:-m-128 {
		margin: -12.8rem;
	}

	.lg\:-m-136 {
		margin: -13.6rem;
	}

	.lg\:-m-160 {
		margin: -16rem;
	}

	.lg\:-m-192 {
		margin: -19.2rem;
	}

	.lg\:-m-200 {
		margin: -20rem;
	}

	.lg\:-m-208 {
		margin: -20.8rem;
	}

	.lg\:-m-216 {
		margin: -21.6rem;
	}

	.lg\:-m-224 {
		margin: -22.4rem;
	}

	.lg\:-m-256 {
		margin: -25.6rem;
	}

	.lg\:-m-288 {
		margin: -28.8rem;
	}

	.lg\:-m-320 {
		margin: -32rem;
	}

	.lg\:-m-360 {
		margin: -36rem;
	}

	.lg\:-m-384 {
		margin: -38.4rem;
	}

	.lg\:-m-400 {
		margin: -40rem;
	}

	.lg\:-m-512 {
		margin: -51.2rem;
	}

	.lg\:-m-640 {
		margin: -64rem;
	}

	.lg\:-m-px {
		margin: -1px;
	}

	.lg\:-m-xs {
		margin: -32rem;
	}

	.lg\:-m-sm {
		margin: -48rem;
	}

	.lg\:-m-md {
		margin: -64rem;
	}

	.lg\:-m-lg {
		margin: -80rem;
	}

	.lg\:-m-xl {
		margin: -96rem;
	}

	.lg\:-m-2xl {
		margin: -112rem;
	}

	.lg\:-m-3xl {
		margin: -128rem;
	}

	.lg\:-m-4xl {
		margin: -144rem;
	}

	.lg\:-m-5xl {
		margin: -160rem;
	}

	.lg\:-m-auto {
		margin: -auto;
	}

	.lg\:my-0 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.lg\:mx-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.lg\:my-1 {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.lg\:mx-1 {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.lg\:my-2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.lg\:mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.lg\:my-3 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.lg\:mx-3 {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.lg\:my-4 {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.lg\:mx-4 {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}

	.lg\:my-5 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.lg\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.lg\:my-6 {
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}

	.lg\:mx-6 {
		margin-left: 0.6rem;
		margin-right: 0.6rem;
	}

	.lg\:my-8 {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}

	.lg\:mx-8 {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}

	.lg\:my-10 {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.lg\:mx-10 {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.lg\:my-12 {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.lg\:mx-12 {
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.lg\:my-16 {
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.lg\:mx-16 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.lg\:my-20 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.lg\:mx-20 {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.lg\:my-24 {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	.lg\:mx-24 {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	.lg\:my-28 {
		margin-top: 2.8rem;
		margin-bottom: 2.8rem;
	}

	.lg\:mx-28 {
		margin-left: 2.8rem;
		margin-right: 2.8rem;
	}

	.lg\:my-32 {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}

	.lg\:mx-32 {
		margin-left: 3.2rem;
		margin-right: 3.2rem;
	}

	.lg\:my-36 {
		margin-top: 3.6rem;
		margin-bottom: 3.6rem;
	}

	.lg\:mx-36 {
		margin-left: 3.6rem;
		margin-right: 3.6rem;
	}

	.lg\:my-40 {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.lg\:mx-40 {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.lg\:my-44 {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	.lg\:mx-44 {
		margin-left: 4.4rem;
		margin-right: 4.4rem;
	}

	.lg\:my-48 {
		margin-top: 4.8rem;
		margin-bottom: 4.8rem;
	}

	.lg\:mx-48 {
		margin-left: 4.8rem;
		margin-right: 4.8rem;
	}

	.lg\:my-52 {
		margin-top: 5.2rem;
		margin-bottom: 5.2rem;
	}

	.lg\:mx-52 {
		margin-left: 5.2rem;
		margin-right: 5.2rem;
	}

	.lg\:my-56 {
		margin-top: 5.6rem;
		margin-bottom: 5.6rem;
	}

	.lg\:mx-56 {
		margin-left: 5.6rem;
		margin-right: 5.6rem;
	}

	.lg\:my-60 {
		margin-top: 6rem;
		margin-bottom: 6rem;
	}

	.lg\:mx-60 {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	.lg\:my-64 {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	.lg\:mx-64 {
		margin-left: 6.4rem;
		margin-right: 6.4rem;
	}

	.lg\:my-68 {
		margin-top: 6.8rem;
		margin-bottom: 6.8rem;
	}

	.lg\:mx-68 {
		margin-left: 6.8rem;
		margin-right: 6.8rem;
	}

	.lg\:my-72 {
		margin-top: 7.2rem;
		margin-bottom: 7.2rem;
	}

	.lg\:mx-72 {
		margin-left: 7.2rem;
		margin-right: 7.2rem;
	}

	.lg\:my-76 {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.lg\:mx-76 {
		margin-left: 7.6rem;
		margin-right: 7.6rem;
	}

	.lg\:my-80 {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}

	.lg\:mx-80 {
		margin-left: 8rem;
		margin-right: 8rem;
	}

	.lg\:my-84 {
		margin-top: 8.4rem;
		margin-bottom: 8.4rem;
	}

	.lg\:mx-84 {
		margin-left: 8.4rem;
		margin-right: 8.4rem;
	}

	.lg\:my-88 {
		margin-top: 8.8rem;
		margin-bottom: 8.8rem;
	}

	.lg\:mx-88 {
		margin-left: 8.8rem;
		margin-right: 8.8rem;
	}

	.lg\:my-92 {
		margin-top: 9.2rem;
		margin-bottom: 9.2rem;
	}

	.lg\:mx-92 {
		margin-left: 9.2rem;
		margin-right: 9.2rem;
	}

	.lg\:my-96 {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}

	.lg\:mx-96 {
		margin-left: 9.6rem;
		margin-right: 9.6rem;
	}

	.lg\:my-128 {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}

	.lg\:mx-128 {
		margin-left: 12.8rem;
		margin-right: 12.8rem;
	}

	.lg\:my-136 {
		margin-top: 13.6rem;
		margin-bottom: 13.6rem;
	}

	.lg\:mx-136 {
		margin-left: 13.6rem;
		margin-right: 13.6rem;
	}

	.lg\:my-160 {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}

	.lg\:mx-160 {
		margin-left: 16rem;
		margin-right: 16rem;
	}

	.lg\:my-192 {
		margin-top: 19.2rem;
		margin-bottom: 19.2rem;
	}

	.lg\:mx-192 {
		margin-left: 19.2rem;
		margin-right: 19.2rem;
	}

	.lg\:my-200 {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.lg\:mx-200 {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.lg\:my-208 {
		margin-top: 20.8rem;
		margin-bottom: 20.8rem;
	}

	.lg\:mx-208 {
		margin-left: 20.8rem;
		margin-right: 20.8rem;
	}

	.lg\:my-216 {
		margin-top: 21.6rem;
		margin-bottom: 21.6rem;
	}

	.lg\:mx-216 {
		margin-left: 21.6rem;
		margin-right: 21.6rem;
	}

	.lg\:my-224 {
		margin-top: 22.4rem;
		margin-bottom: 22.4rem;
	}

	.lg\:mx-224 {
		margin-left: 22.4rem;
		margin-right: 22.4rem;
	}

	.lg\:my-256 {
		margin-top: 25.6rem;
		margin-bottom: 25.6rem;
	}

	.lg\:mx-256 {
		margin-left: 25.6rem;
		margin-right: 25.6rem;
	}

	.lg\:my-288 {
		margin-top: 28.8rem;
		margin-bottom: 28.8rem;
	}

	.lg\:mx-288 {
		margin-left: 28.8rem;
		margin-right: 28.8rem;
	}

	.lg\:my-320 {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.lg\:mx-320 {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.lg\:my-360 {
		margin-top: 36rem;
		margin-bottom: 36rem;
	}

	.lg\:mx-360 {
		margin-left: 36rem;
		margin-right: 36rem;
	}

	.lg\:my-384 {
		margin-top: 38.4rem;
		margin-bottom: 38.4rem;
	}

	.lg\:mx-384 {
		margin-left: 38.4rem;
		margin-right: 38.4rem;
	}

	.lg\:my-400 {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.lg\:mx-400 {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.lg\:my-512 {
		margin-top: 51.2rem;
		margin-bottom: 51.2rem;
	}

	.lg\:mx-512 {
		margin-left: 51.2rem;
		margin-right: 51.2rem;
	}

	.lg\:my-640 {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.lg\:mx-640 {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.lg\:my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.lg\:mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.lg\:my-px {
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.lg\:mx-px {
		margin-left: 1px;
		margin-right: 1px;
	}

	.lg\:my-xs {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.lg\:mx-xs {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.lg\:my-sm {
		margin-top: 48rem;
		margin-bottom: 48rem;
	}

	.lg\:mx-sm {
		margin-left: 48rem;
		margin-right: 48rem;
	}

	.lg\:my-md {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.lg\:mx-md {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.lg\:my-lg {
		margin-top: 80rem;
		margin-bottom: 80rem;
	}

	.lg\:mx-lg {
		margin-left: 80rem;
		margin-right: 80rem;
	}

	.lg\:my-xl {
		margin-top: 96rem;
		margin-bottom: 96rem;
	}

	.lg\:mx-xl {
		margin-left: 96rem;
		margin-right: 96rem;
	}

	.lg\:my-2xl {
		margin-top: 112rem;
		margin-bottom: 112rem;
	}

	.lg\:mx-2xl {
		margin-left: 112rem;
		margin-right: 112rem;
	}

	.lg\:my-3xl {
		margin-top: 128rem;
		margin-bottom: 128rem;
	}

	.lg\:mx-3xl {
		margin-left: 128rem;
		margin-right: 128rem;
	}

	.lg\:my-4xl {
		margin-top: 144rem;
		margin-bottom: 144rem;
	}

	.lg\:mx-4xl {
		margin-left: 144rem;
		margin-right: 144rem;
	}

	.lg\:my-5xl {
		margin-top: 160rem;
		margin-bottom: 160rem;
	}

	.lg\:mx-5xl {
		margin-left: 160rem;
		margin-right: 160rem;
	}

	.lg\:-my-1 {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}

	.lg\:-mx-1 {
		margin-left: -0.25rem;
		margin-right: -0.25rem;
	}

	.lg\:-my-2 {
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
	}

	.lg\:-mx-2 {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	.lg\:-my-3 {
		margin-top: -0.75rem;
		margin-bottom: -0.75rem;
	}

	.lg\:-mx-3 {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}

	.lg\:-my-4 {
		margin-top: -0.4rem;
		margin-bottom: -0.4rem;
	}

	.lg\:-mx-4 {
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.lg\:-my-5 {
		margin-top: -1.25rem;
		margin-bottom: -1.25rem;
	}

	.lg\:-mx-5 {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	}

	.lg\:-my-6 {
		margin-top: -0.6rem;
		margin-bottom: -0.6rem;
	}

	.lg\:-mx-6 {
		margin-left: -0.6rem;
		margin-right: -0.6rem;
	}

	.lg\:-my-8 {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}

	.lg\:-mx-8 {
		margin-left: -0.8rem;
		margin-right: -0.8rem;
	}

	.lg\:-my-10 {
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.lg\:-mx-10 {
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}

	.lg\:-my-12 {
		margin-top: -1.2rem;
		margin-bottom: -1.2rem;
	}

	.lg\:-mx-12 {
		margin-left: -1.2rem;
		margin-right: -1.2rem;
	}

	.lg\:-my-16 {
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	.lg\:-mx-16 {
		margin-left: -1.6rem;
		margin-right: -1.6rem;
	}

	.lg\:-my-20 {
		margin-top: -2rem;
		margin-bottom: -2rem;
	}

	.lg\:-mx-20 {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.lg\:-my-24 {
		margin-top: -2.4rem;
		margin-bottom: -2.4rem;
	}

	.lg\:-mx-24 {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}

	.lg\:-my-28 {
		margin-top: -2.8rem;
		margin-bottom: -2.8rem;
	}

	.lg\:-mx-28 {
		margin-left: -2.8rem;
		margin-right: -2.8rem;
	}

	.lg\:-my-32 {
		margin-top: -3.2rem;
		margin-bottom: -3.2rem;
	}

	.lg\:-mx-32 {
		margin-left: -3.2rem;
		margin-right: -3.2rem;
	}

	.lg\:-my-36 {
		margin-top: -3.6rem;
		margin-bottom: -3.6rem;
	}

	.lg\:-mx-36 {
		margin-left: -3.6rem;
		margin-right: -3.6rem;
	}

	.lg\:-my-40 {
		margin-top: -4rem;
		margin-bottom: -4rem;
	}

	.lg\:-mx-40 {
		margin-left: -4rem;
		margin-right: -4rem;
	}

	.lg\:-my-44 {
		margin-top: -4.4rem;
		margin-bottom: -4.4rem;
	}

	.lg\:-mx-44 {
		margin-left: -4.4rem;
		margin-right: -4.4rem;
	}

	.lg\:-my-48 {
		margin-top: -4.8rem;
		margin-bottom: -4.8rem;
	}

	.lg\:-mx-48 {
		margin-left: -4.8rem;
		margin-right: -4.8rem;
	}

	.lg\:-my-52 {
		margin-top: -5.2rem;
		margin-bottom: -5.2rem;
	}

	.lg\:-mx-52 {
		margin-left: -5.2rem;
		margin-right: -5.2rem;
	}

	.lg\:-my-56 {
		margin-top: -5.6rem;
		margin-bottom: -5.6rem;
	}

	.lg\:-mx-56 {
		margin-left: -5.6rem;
		margin-right: -5.6rem;
	}

	.lg\:-my-60 {
		margin-top: -6rem;
		margin-bottom: -6rem;
	}

	.lg\:-mx-60 {
		margin-left: -6rem;
		margin-right: -6rem;
	}

	.lg\:-my-64 {
		margin-top: -6.4rem;
		margin-bottom: -6.4rem;
	}

	.lg\:-mx-64 {
		margin-left: -6.4rem;
		margin-right: -6.4rem;
	}

	.lg\:-my-68 {
		margin-top: -6.8rem;
		margin-bottom: -6.8rem;
	}

	.lg\:-mx-68 {
		margin-left: -6.8rem;
		margin-right: -6.8rem;
	}

	.lg\:-my-72 {
		margin-top: -7.2rem;
		margin-bottom: -7.2rem;
	}

	.lg\:-mx-72 {
		margin-left: -7.2rem;
		margin-right: -7.2rem;
	}

	.lg\:-my-76 {
		margin-top: -7.6rem;
		margin-bottom: -7.6rem;
	}

	.lg\:-mx-76 {
		margin-left: -7.6rem;
		margin-right: -7.6rem;
	}

	.lg\:-my-80 {
		margin-top: -8rem;
		margin-bottom: -8rem;
	}

	.lg\:-mx-80 {
		margin-left: -8rem;
		margin-right: -8rem;
	}

	.lg\:-my-84 {
		margin-top: -8.4rem;
		margin-bottom: -8.4rem;
	}

	.lg\:-mx-84 {
		margin-left: -8.4rem;
		margin-right: -8.4rem;
	}

	.lg\:-my-88 {
		margin-top: -8.8rem;
		margin-bottom: -8.8rem;
	}

	.lg\:-mx-88 {
		margin-left: -8.8rem;
		margin-right: -8.8rem;
	}

	.lg\:-my-92 {
		margin-top: -9.2rem;
		margin-bottom: -9.2rem;
	}

	.lg\:-mx-92 {
		margin-left: -9.2rem;
		margin-right: -9.2rem;
	}

	.lg\:-my-96 {
		margin-top: -9.6rem;
		margin-bottom: -9.6rem;
	}

	.lg\:-mx-96 {
		margin-left: -9.6rem;
		margin-right: -9.6rem;
	}

	.lg\:-my-128 {
		margin-top: -12.8rem;
		margin-bottom: -12.8rem;
	}

	.lg\:-mx-128 {
		margin-left: -12.8rem;
		margin-right: -12.8rem;
	}

	.lg\:-my-136 {
		margin-top: -13.6rem;
		margin-bottom: -13.6rem;
	}

	.lg\:-mx-136 {
		margin-left: -13.6rem;
		margin-right: -13.6rem;
	}

	.lg\:-my-160 {
		margin-top: -16rem;
		margin-bottom: -16rem;
	}

	.lg\:-mx-160 {
		margin-left: -16rem;
		margin-right: -16rem;
	}

	.lg\:-my-192 {
		margin-top: -19.2rem;
		margin-bottom: -19.2rem;
	}

	.lg\:-mx-192 {
		margin-left: -19.2rem;
		margin-right: -19.2rem;
	}

	.lg\:-my-200 {
		margin-top: -20rem;
		margin-bottom: -20rem;
	}

	.lg\:-mx-200 {
		margin-left: -20rem;
		margin-right: -20rem;
	}

	.lg\:-my-208 {
		margin-top: -20.8rem;
		margin-bottom: -20.8rem;
	}

	.lg\:-mx-208 {
		margin-left: -20.8rem;
		margin-right: -20.8rem;
	}

	.lg\:-my-216 {
		margin-top: -21.6rem;
		margin-bottom: -21.6rem;
	}

	.lg\:-mx-216 {
		margin-left: -21.6rem;
		margin-right: -21.6rem;
	}

	.lg\:-my-224 {
		margin-top: -22.4rem;
		margin-bottom: -22.4rem;
	}

	.lg\:-mx-224 {
		margin-left: -22.4rem;
		margin-right: -22.4rem;
	}

	.lg\:-my-256 {
		margin-top: -25.6rem;
		margin-bottom: -25.6rem;
	}

	.lg\:-mx-256 {
		margin-left: -25.6rem;
		margin-right: -25.6rem;
	}

	.lg\:-my-288 {
		margin-top: -28.8rem;
		margin-bottom: -28.8rem;
	}

	.lg\:-mx-288 {
		margin-left: -28.8rem;
		margin-right: -28.8rem;
	}

	.lg\:-my-320 {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.lg\:-mx-320 {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.lg\:-my-360 {
		margin-top: -36rem;
		margin-bottom: -36rem;
	}

	.lg\:-mx-360 {
		margin-left: -36rem;
		margin-right: -36rem;
	}

	.lg\:-my-384 {
		margin-top: -38.4rem;
		margin-bottom: -38.4rem;
	}

	.lg\:-mx-384 {
		margin-left: -38.4rem;
		margin-right: -38.4rem;
	}

	.lg\:-my-400 {
		margin-top: -40rem;
		margin-bottom: -40rem;
	}

	.lg\:-mx-400 {
		margin-left: -40rem;
		margin-right: -40rem;
	}

	.lg\:-my-512 {
		margin-top: -51.2rem;
		margin-bottom: -51.2rem;
	}

	.lg\:-mx-512 {
		margin-left: -51.2rem;
		margin-right: -51.2rem;
	}

	.lg\:-my-640 {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.lg\:-mx-640 {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.lg\:-my-px {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	.lg\:-mx-px {
		margin-left: -1px;
		margin-right: -1px;
	}

	.lg\:-my-xs {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.lg\:-mx-xs {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.lg\:-my-sm {
		margin-top: -48rem;
		margin-bottom: -48rem;
	}

	.lg\:-mx-sm {
		margin-left: -48rem;
		margin-right: -48rem;
	}

	.lg\:-my-md {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.lg\:-mx-md {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.lg\:-my-lg {
		margin-top: -80rem;
		margin-bottom: -80rem;
	}

	.lg\:-mx-lg {
		margin-left: -80rem;
		margin-right: -80rem;
	}

	.lg\:-my-xl {
		margin-top: -96rem;
		margin-bottom: -96rem;
	}

	.lg\:-mx-xl {
		margin-left: -96rem;
		margin-right: -96rem;
	}

	.lg\:-my-2xl {
		margin-top: -112rem;
		margin-bottom: -112rem;
	}

	.lg\:-mx-2xl {
		margin-left: -112rem;
		margin-right: -112rem;
	}

	.lg\:-my-3xl {
		margin-top: -128rem;
		margin-bottom: -128rem;
	}

	.lg\:-mx-3xl {
		margin-left: -128rem;
		margin-right: -128rem;
	}

	.lg\:-my-4xl {
		margin-top: -144rem;
		margin-bottom: -144rem;
	}

	.lg\:-mx-4xl {
		margin-left: -144rem;
		margin-right: -144rem;
	}

	.lg\:-my-5xl {
		margin-top: -160rem;
		margin-bottom: -160rem;
	}

	.lg\:-mx-5xl {
		margin-left: -160rem;
		margin-right: -160rem;
	}

	.lg\:-my-auto {
		margin-top: -auto;
		margin-bottom: -auto;
	}

	.lg\:-mx-auto {
		margin-left: -auto;
		margin-right: -auto;
	}

	.lg\:mt-0 {
		margin-top: 0;
	}

	.lg\:mr-0 {
		margin-right: 0;
	}

	.lg\:mb-0 {
		margin-bottom: 0;
	}

	.lg\:ml-0 {
		margin-left: 0;
	}

	.lg\:mt-1 {
		margin-top: 0.25rem;
	}

	.lg\:mr-1 {
		margin-right: 0.25rem;
	}

	.lg\:mb-1 {
		margin-bottom: 0.25rem;
	}

	.lg\:ml-1 {
		margin-left: 0.25rem;
	}

	.lg\:mt-2 {
		margin-top: 0.5rem;
	}

	.lg\:mr-2 {
		margin-right: 0.5rem;
	}

	.lg\:mb-2 {
		margin-bottom: 0.5rem;
	}

	.lg\:ml-2 {
		margin-left: 0.5rem;
	}

	.lg\:mt-3 {
		margin-top: 0.75rem;
	}

	.lg\:mr-3 {
		margin-right: 0.75rem;
	}

	.lg\:mb-3 {
		margin-bottom: 0.75rem;
	}

	.lg\:ml-3 {
		margin-left: 0.75rem;
	}

	.lg\:mt-4 {
		margin-top: 0.4rem;
	}

	.lg\:mr-4 {
		margin-right: 0.4rem;
	}

	.lg\:mb-4 {
		margin-bottom: 0.4rem;
	}

	.lg\:ml-4 {
		margin-left: 0.4rem;
	}

	.lg\:mt-5 {
		margin-top: 1.25rem;
	}

	.lg\:mr-5 {
		margin-right: 1.25rem;
	}

	.lg\:mb-5 {
		margin-bottom: 1.25rem;
	}

	.lg\:ml-5 {
		margin-left: 1.25rem;
	}

	.lg\:mt-6 {
		margin-top: 0.6rem;
	}

	.lg\:mr-6 {
		margin-right: 0.6rem;
	}

	.lg\:mb-6 {
		margin-bottom: 0.6rem;
	}

	.lg\:ml-6 {
		margin-left: 0.6rem;
	}

	.lg\:mt-8 {
		margin-top: 0.8rem;
	}

	.lg\:mr-8 {
		margin-right: 0.8rem;
	}

	.lg\:mb-8 {
		margin-bottom: 0.8rem;
	}

	.lg\:ml-8 {
		margin-left: 0.8rem;
	}

	.lg\:mt-10 {
		margin-top: 2.5rem;
	}

	.lg\:mr-10 {
		margin-right: 2.5rem;
	}

	.lg\:mb-10 {
		margin-bottom: 2.5rem;
	}

	.lg\:ml-10 {
		margin-left: 2.5rem;
	}

	.lg\:mt-12 {
		margin-top: 1.2rem;
	}

	.lg\:mr-12 {
		margin-right: 1.2rem;
	}

	.lg\:mb-12 {
		margin-bottom: 1.2rem;
	}

	.lg\:ml-12 {
		margin-left: 1.2rem;
	}

	.lg\:mt-16 {
		margin-top: 1.6rem;
	}

	.lg\:mr-16 {
		margin-right: 1.6rem;
	}

	.lg\:mb-16 {
		margin-bottom: 1.6rem;
	}

	.lg\:ml-16 {
		margin-left: 1.6rem;
	}

	.lg\:mt-20 {
		margin-top: 2rem;
	}

	.lg\:mr-20 {
		margin-right: 2rem;
	}

	.lg\:mb-20 {
		margin-bottom: 2rem;
	}

	.lg\:ml-20 {
		margin-left: 2rem;
	}

	.lg\:mt-24 {
		margin-top: 2.4rem;
	}

	.lg\:mr-24 {
		margin-right: 2.4rem;
	}

	.lg\:mb-24 {
		margin-bottom: 2.4rem;
	}

	.lg\:ml-24 {
		margin-left: 2.4rem;
	}

	.lg\:mt-28 {
		margin-top: 2.8rem;
	}

	.lg\:mr-28 {
		margin-right: 2.8rem;
	}

	.lg\:mb-28 {
		margin-bottom: 2.8rem;
	}

	.lg\:ml-28 {
		margin-left: 2.8rem;
	}

	.lg\:mt-32 {
		margin-top: 3.2rem;
	}

	.lg\:mr-32 {
		margin-right: 3.2rem;
	}

	.lg\:mb-32 {
		margin-bottom: 3.2rem;
	}

	.lg\:ml-32 {
		margin-left: 3.2rem;
	}

	.lg\:mt-36 {
		margin-top: 3.6rem;
	}

	.lg\:mr-36 {
		margin-right: 3.6rem;
	}

	.lg\:mb-36 {
		margin-bottom: 3.6rem;
	}

	.lg\:ml-36 {
		margin-left: 3.6rem;
	}

	.lg\:mt-40 {
		margin-top: 4rem;
	}

	.lg\:mr-40 {
		margin-right: 4rem;
	}

	.lg\:mb-40 {
		margin-bottom: 4rem;
	}

	.lg\:ml-40 {
		margin-left: 4rem;
	}

	.lg\:mt-44 {
		margin-top: 4.4rem;
	}

	.lg\:mr-44 {
		margin-right: 4.4rem;
	}

	.lg\:mb-44 {
		margin-bottom: 4.4rem;
	}

	.lg\:ml-44 {
		margin-left: 4.4rem;
	}

	.lg\:mt-48 {
		margin-top: 4.8rem;
	}

	.lg\:mr-48 {
		margin-right: 4.8rem;
	}

	.lg\:mb-48 {
		margin-bottom: 4.8rem;
	}

	.lg\:ml-48 {
		margin-left: 4.8rem;
	}

	.lg\:mt-52 {
		margin-top: 5.2rem;
	}

	.lg\:mr-52 {
		margin-right: 5.2rem;
	}

	.lg\:mb-52 {
		margin-bottom: 5.2rem;
	}

	.lg\:ml-52 {
		margin-left: 5.2rem;
	}

	.lg\:mt-56 {
		margin-top: 5.6rem;
	}

	.lg\:mr-56 {
		margin-right: 5.6rem;
	}

	.lg\:mb-56 {
		margin-bottom: 5.6rem;
	}

	.lg\:ml-56 {
		margin-left: 5.6rem;
	}

	.lg\:mt-60 {
		margin-top: 6rem;
	}

	.lg\:mr-60 {
		margin-right: 6rem;
	}

	.lg\:mb-60 {
		margin-bottom: 6rem;
	}

	.lg\:ml-60 {
		margin-left: 6rem;
	}

	.lg\:mt-64 {
		margin-top: 6.4rem;
	}

	.lg\:mr-64 {
		margin-right: 6.4rem;
	}

	.lg\:mb-64 {
		margin-bottom: 6.4rem;
	}

	.lg\:ml-64 {
		margin-left: 6.4rem;
	}

	.lg\:mt-68 {
		margin-top: 6.8rem;
	}

	.lg\:mr-68 {
		margin-right: 6.8rem;
	}

	.lg\:mb-68 {
		margin-bottom: 6.8rem;
	}

	.lg\:ml-68 {
		margin-left: 6.8rem;
	}

	.lg\:mt-72 {
		margin-top: 7.2rem;
	}

	.lg\:mr-72 {
		margin-right: 7.2rem;
	}

	.lg\:mb-72 {
		margin-bottom: 7.2rem;
	}

	.lg\:ml-72 {
		margin-left: 7.2rem;
	}

	.lg\:mt-76 {
		margin-top: 7.6rem;
	}

	.lg\:mr-76 {
		margin-right: 7.6rem;
	}

	.lg\:mb-76 {
		margin-bottom: 7.6rem;
	}

	.lg\:ml-76 {
		margin-left: 7.6rem;
	}

	.lg\:mt-80 {
		margin-top: 8rem;
	}

	.lg\:mr-80 {
		margin-right: 8rem;
	}

	.lg\:mb-80 {
		margin-bottom: 8rem;
	}

	.lg\:ml-80 {
		margin-left: 8rem;
	}

	.lg\:mt-84 {
		margin-top: 8.4rem;
	}

	.lg\:mr-84 {
		margin-right: 8.4rem;
	}

	.lg\:mb-84 {
		margin-bottom: 8.4rem;
	}

	.lg\:ml-84 {
		margin-left: 8.4rem;
	}

	.lg\:mt-88 {
		margin-top: 8.8rem;
	}

	.lg\:mr-88 {
		margin-right: 8.8rem;
	}

	.lg\:mb-88 {
		margin-bottom: 8.8rem;
	}

	.lg\:ml-88 {
		margin-left: 8.8rem;
	}

	.lg\:mt-92 {
		margin-top: 9.2rem;
	}

	.lg\:mr-92 {
		margin-right: 9.2rem;
	}

	.lg\:mb-92 {
		margin-bottom: 9.2rem;
	}

	.lg\:ml-92 {
		margin-left: 9.2rem;
	}

	.lg\:mt-96 {
		margin-top: 9.6rem;
	}

	.lg\:mr-96 {
		margin-right: 9.6rem;
	}

	.lg\:mb-96 {
		margin-bottom: 9.6rem;
	}

	.lg\:ml-96 {
		margin-left: 9.6rem;
	}

	.lg\:mt-128 {
		margin-top: 12.8rem;
	}

	.lg\:mr-128 {
		margin-right: 12.8rem;
	}

	.lg\:mb-128 {
		margin-bottom: 12.8rem;
	}

	.lg\:ml-128 {
		margin-left: 12.8rem;
	}

	.lg\:mt-136 {
		margin-top: 13.6rem;
	}

	.lg\:mr-136 {
		margin-right: 13.6rem;
	}

	.lg\:mb-136 {
		margin-bottom: 13.6rem;
	}

	.lg\:ml-136 {
		margin-left: 13.6rem;
	}

	.lg\:mt-160 {
		margin-top: 16rem;
	}

	.lg\:mr-160 {
		margin-right: 16rem;
	}

	.lg\:mb-160 {
		margin-bottom: 16rem;
	}

	.lg\:ml-160 {
		margin-left: 16rem;
	}

	.lg\:mt-192 {
		margin-top: 19.2rem;
	}

	.lg\:mr-192 {
		margin-right: 19.2rem;
	}

	.lg\:mb-192 {
		margin-bottom: 19.2rem;
	}

	.lg\:ml-192 {
		margin-left: 19.2rem;
	}

	.lg\:mt-200 {
		margin-top: 20rem;
	}

	.lg\:mr-200 {
		margin-right: 20rem;
	}

	.lg\:mb-200 {
		margin-bottom: 20rem;
	}

	.lg\:ml-200 {
		margin-left: 20rem;
	}

	.lg\:mt-208 {
		margin-top: 20.8rem;
	}

	.lg\:mr-208 {
		margin-right: 20.8rem;
	}

	.lg\:mb-208 {
		margin-bottom: 20.8rem;
	}

	.lg\:ml-208 {
		margin-left: 20.8rem;
	}

	.lg\:mt-216 {
		margin-top: 21.6rem;
	}

	.lg\:mr-216 {
		margin-right: 21.6rem;
	}

	.lg\:mb-216 {
		margin-bottom: 21.6rem;
	}

	.lg\:ml-216 {
		margin-left: 21.6rem;
	}

	.lg\:mt-224 {
		margin-top: 22.4rem;
	}

	.lg\:mr-224 {
		margin-right: 22.4rem;
	}

	.lg\:mb-224 {
		margin-bottom: 22.4rem;
	}

	.lg\:ml-224 {
		margin-left: 22.4rem;
	}

	.lg\:mt-256 {
		margin-top: 25.6rem;
	}

	.lg\:mr-256 {
		margin-right: 25.6rem;
	}

	.lg\:mb-256 {
		margin-bottom: 25.6rem;
	}

	.lg\:ml-256 {
		margin-left: 25.6rem;
	}

	.lg\:mt-288 {
		margin-top: 28.8rem;
	}

	.lg\:mr-288 {
		margin-right: 28.8rem;
	}

	.lg\:mb-288 {
		margin-bottom: 28.8rem;
	}

	.lg\:ml-288 {
		margin-left: 28.8rem;
	}

	.lg\:mt-320 {
		margin-top: 32rem;
	}

	.lg\:mr-320 {
		margin-right: 32rem;
	}

	.lg\:mb-320 {
		margin-bottom: 32rem;
	}

	.lg\:ml-320 {
		margin-left: 32rem;
	}

	.lg\:mt-360 {
		margin-top: 36rem;
	}

	.lg\:mr-360 {
		margin-right: 36rem;
	}

	.lg\:mb-360 {
		margin-bottom: 36rem;
	}

	.lg\:ml-360 {
		margin-left: 36rem;
	}

	.lg\:mt-384 {
		margin-top: 38.4rem;
	}

	.lg\:mr-384 {
		margin-right: 38.4rem;
	}

	.lg\:mb-384 {
		margin-bottom: 38.4rem;
	}

	.lg\:ml-384 {
		margin-left: 38.4rem;
	}

	.lg\:mt-400 {
		margin-top: 40rem;
	}

	.lg\:mr-400 {
		margin-right: 40rem;
	}

	.lg\:mb-400 {
		margin-bottom: 40rem;
	}

	.lg\:ml-400 {
		margin-left: 40rem;
	}

	.lg\:mt-512 {
		margin-top: 51.2rem;
	}

	.lg\:mr-512 {
		margin-right: 51.2rem;
	}

	.lg\:mb-512 {
		margin-bottom: 51.2rem;
	}

	.lg\:ml-512 {
		margin-left: 51.2rem;
	}

	.lg\:mt-640 {
		margin-top: 64rem;
	}

	.lg\:mr-640 {
		margin-right: 64rem;
	}

	.lg\:mb-640 {
		margin-bottom: 64rem;
	}

	.lg\:ml-640 {
		margin-left: 64rem;
	}

	.lg\:mt-auto {
		margin-top: auto;
	}

	.lg\:mr-auto {
		margin-right: auto;
	}

	.lg\:mb-auto {
		margin-bottom: auto;
	}

	.lg\:ml-auto {
		margin-left: auto;
	}

	.lg\:mt-px {
		margin-top: 1px;
	}

	.lg\:mr-px {
		margin-right: 1px;
	}

	.lg\:mb-px {
		margin-bottom: 1px;
	}

	.lg\:ml-px {
		margin-left: 1px;
	}

	.lg\:mt-xs {
		margin-top: 32rem;
	}

	.lg\:mr-xs {
		margin-right: 32rem;
	}

	.lg\:mb-xs {
		margin-bottom: 32rem;
	}

	.lg\:ml-xs {
		margin-left: 32rem;
	}

	.lg\:mt-sm {
		margin-top: 48rem;
	}

	.lg\:mr-sm {
		margin-right: 48rem;
	}

	.lg\:mb-sm {
		margin-bottom: 48rem;
	}

	.lg\:ml-sm {
		margin-left: 48rem;
	}

	.lg\:mt-md {
		margin-top: 64rem;
	}

	.lg\:mr-md {
		margin-right: 64rem;
	}

	.lg\:mb-md {
		margin-bottom: 64rem;
	}

	.lg\:ml-md {
		margin-left: 64rem;
	}

	.lg\:mt-lg {
		margin-top: 80rem;
	}

	.lg\:mr-lg {
		margin-right: 80rem;
	}

	.lg\:mb-lg {
		margin-bottom: 80rem;
	}

	.lg\:ml-lg {
		margin-left: 80rem;
	}

	.lg\:mt-xl {
		margin-top: 96rem;
	}

	.lg\:mr-xl {
		margin-right: 96rem;
	}

	.lg\:mb-xl {
		margin-bottom: 96rem;
	}

	.lg\:ml-xl {
		margin-left: 96rem;
	}

	.lg\:mt-2xl {
		margin-top: 112rem;
	}

	.lg\:mr-2xl {
		margin-right: 112rem;
	}

	.lg\:mb-2xl {
		margin-bottom: 112rem;
	}

	.lg\:ml-2xl {
		margin-left: 112rem;
	}

	.lg\:mt-3xl {
		margin-top: 128rem;
	}

	.lg\:mr-3xl {
		margin-right: 128rem;
	}

	.lg\:mb-3xl {
		margin-bottom: 128rem;
	}

	.lg\:ml-3xl {
		margin-left: 128rem;
	}

	.lg\:mt-4xl {
		margin-top: 144rem;
	}

	.lg\:mr-4xl {
		margin-right: 144rem;
	}

	.lg\:mb-4xl {
		margin-bottom: 144rem;
	}

	.lg\:ml-4xl {
		margin-left: 144rem;
	}

	.lg\:mt-5xl {
		margin-top: 160rem;
	}

	.lg\:mr-5xl {
		margin-right: 160rem;
	}

	.lg\:mb-5xl {
		margin-bottom: 160rem;
	}

	.lg\:ml-5xl {
		margin-left: 160rem;
	}

	.lg\:-mt-1 {
		margin-top: -0.25rem;
	}

	.lg\:-mr-1 {
		margin-right: -0.25rem;
	}

	.lg\:-mb-1 {
		margin-bottom: -0.25rem;
	}

	.lg\:-ml-1 {
		margin-left: -0.25rem;
	}

	.lg\:-mt-2 {
		margin-top: -0.5rem;
	}

	.lg\:-mr-2 {
		margin-right: -0.5rem;
	}

	.lg\:-mb-2 {
		margin-bottom: -0.5rem;
	}

	.lg\:-ml-2 {
		margin-left: -0.5rem;
	}

	.lg\:-mt-3 {
		margin-top: -0.75rem;
	}

	.lg\:-mr-3 {
		margin-right: -0.75rem;
	}

	.lg\:-mb-3 {
		margin-bottom: -0.75rem;
	}

	.lg\:-ml-3 {
		margin-left: -0.75rem;
	}

	.lg\:-mt-4 {
		margin-top: -0.4rem;
	}

	.lg\:-mr-4 {
		margin-right: -0.4rem;
	}

	.lg\:-mb-4 {
		margin-bottom: -0.4rem;
	}

	.lg\:-ml-4 {
		margin-left: -0.4rem;
	}

	.lg\:-mt-5 {
		margin-top: -1.25rem;
	}

	.lg\:-mr-5 {
		margin-right: -1.25rem;
	}

	.lg\:-mb-5 {
		margin-bottom: -1.25rem;
	}

	.lg\:-ml-5 {
		margin-left: -1.25rem;
	}

	.lg\:-mt-6 {
		margin-top: -0.6rem;
	}

	.lg\:-mr-6 {
		margin-right: -0.6rem;
	}

	.lg\:-mb-6 {
		margin-bottom: -0.6rem;
	}

	.lg\:-ml-6 {
		margin-left: -0.6rem;
	}

	.lg\:-mt-8 {
		margin-top: -0.8rem;
	}

	.lg\:-mr-8 {
		margin-right: -0.8rem;
	}

	.lg\:-mb-8 {
		margin-bottom: -0.8rem;
	}

	.lg\:-ml-8 {
		margin-left: -0.8rem;
	}

	.lg\:-mt-10 {
		margin-top: -2.5rem;
	}

	.lg\:-mr-10 {
		margin-right: -2.5rem;
	}

	.lg\:-mb-10 {
		margin-bottom: -2.5rem;
	}

	.lg\:-ml-10 {
		margin-left: -2.5rem;
	}

	.lg\:-mt-12 {
		margin-top: -1.2rem;
	}

	.lg\:-mr-12 {
		margin-right: -1.2rem;
	}

	.lg\:-mb-12 {
		margin-bottom: -1.2rem;
	}

	.lg\:-ml-12 {
		margin-left: -1.2rem;
	}

	.lg\:-mt-16 {
		margin-top: -1.6rem;
	}

	.lg\:-mr-16 {
		margin-right: -1.6rem;
	}

	.lg\:-mb-16 {
		margin-bottom: -1.6rem;
	}

	.lg\:-ml-16 {
		margin-left: -1.6rem;
	}

	.lg\:-mt-20 {
		margin-top: -2rem;
	}

	.lg\:-mr-20 {
		margin-right: -2rem;
	}

	.lg\:-mb-20 {
		margin-bottom: -2rem;
	}

	.lg\:-ml-20 {
		margin-left: -2rem;
	}

	.lg\:-mt-24 {
		margin-top: -2.4rem;
	}

	.lg\:-mr-24 {
		margin-right: -2.4rem;
	}

	.lg\:-mb-24 {
		margin-bottom: -2.4rem;
	}

	.lg\:-ml-24 {
		margin-left: -2.4rem;
	}

	.lg\:-mt-28 {
		margin-top: -2.8rem;
	}

	.lg\:-mr-28 {
		margin-right: -2.8rem;
	}

	.lg\:-mb-28 {
		margin-bottom: -2.8rem;
	}

	.lg\:-ml-28 {
		margin-left: -2.8rem;
	}

	.lg\:-mt-32 {
		margin-top: -3.2rem;
	}

	.lg\:-mr-32 {
		margin-right: -3.2rem;
	}

	.lg\:-mb-32 {
		margin-bottom: -3.2rem;
	}

	.lg\:-ml-32 {
		margin-left: -3.2rem;
	}

	.lg\:-mt-36 {
		margin-top: -3.6rem;
	}

	.lg\:-mr-36 {
		margin-right: -3.6rem;
	}

	.lg\:-mb-36 {
		margin-bottom: -3.6rem;
	}

	.lg\:-ml-36 {
		margin-left: -3.6rem;
	}

	.lg\:-mt-40 {
		margin-top: -4rem;
	}

	.lg\:-mr-40 {
		margin-right: -4rem;
	}

	.lg\:-mb-40 {
		margin-bottom: -4rem;
	}

	.lg\:-ml-40 {
		margin-left: -4rem;
	}

	.lg\:-mt-44 {
		margin-top: -4.4rem;
	}

	.lg\:-mr-44 {
		margin-right: -4.4rem;
	}

	.lg\:-mb-44 {
		margin-bottom: -4.4rem;
	}

	.lg\:-ml-44 {
		margin-left: -4.4rem;
	}

	.lg\:-mt-48 {
		margin-top: -4.8rem;
	}

	.lg\:-mr-48 {
		margin-right: -4.8rem;
	}

	.lg\:-mb-48 {
		margin-bottom: -4.8rem;
	}

	.lg\:-ml-48 {
		margin-left: -4.8rem;
	}

	.lg\:-mt-52 {
		margin-top: -5.2rem;
	}

	.lg\:-mr-52 {
		margin-right: -5.2rem;
	}

	.lg\:-mb-52 {
		margin-bottom: -5.2rem;
	}

	.lg\:-ml-52 {
		margin-left: -5.2rem;
	}

	.lg\:-mt-56 {
		margin-top: -5.6rem;
	}

	.lg\:-mr-56 {
		margin-right: -5.6rem;
	}

	.lg\:-mb-56 {
		margin-bottom: -5.6rem;
	}

	.lg\:-ml-56 {
		margin-left: -5.6rem;
	}

	.lg\:-mt-60 {
		margin-top: -6rem;
	}

	.lg\:-mr-60 {
		margin-right: -6rem;
	}

	.lg\:-mb-60 {
		margin-bottom: -6rem;
	}

	.lg\:-ml-60 {
		margin-left: -6rem;
	}

	.lg\:-mt-64 {
		margin-top: -6.4rem;
	}

	.lg\:-mr-64 {
		margin-right: -6.4rem;
	}

	.lg\:-mb-64 {
		margin-bottom: -6.4rem;
	}

	.lg\:-ml-64 {
		margin-left: -6.4rem;
	}

	.lg\:-mt-68 {
		margin-top: -6.8rem;
	}

	.lg\:-mr-68 {
		margin-right: -6.8rem;
	}

	.lg\:-mb-68 {
		margin-bottom: -6.8rem;
	}

	.lg\:-ml-68 {
		margin-left: -6.8rem;
	}

	.lg\:-mt-72 {
		margin-top: -7.2rem;
	}

	.lg\:-mr-72 {
		margin-right: -7.2rem;
	}

	.lg\:-mb-72 {
		margin-bottom: -7.2rem;
	}

	.lg\:-ml-72 {
		margin-left: -7.2rem;
	}

	.lg\:-mt-76 {
		margin-top: -7.6rem;
	}

	.lg\:-mr-76 {
		margin-right: -7.6rem;
	}

	.lg\:-mb-76 {
		margin-bottom: -7.6rem;
	}

	.lg\:-ml-76 {
		margin-left: -7.6rem;
	}

	.lg\:-mt-80 {
		margin-top: -8rem;
	}

	.lg\:-mr-80 {
		margin-right: -8rem;
	}

	.lg\:-mb-80 {
		margin-bottom: -8rem;
	}

	.lg\:-ml-80 {
		margin-left: -8rem;
	}

	.lg\:-mt-84 {
		margin-top: -8.4rem;
	}

	.lg\:-mr-84 {
		margin-right: -8.4rem;
	}

	.lg\:-mb-84 {
		margin-bottom: -8.4rem;
	}

	.lg\:-ml-84 {
		margin-left: -8.4rem;
	}

	.lg\:-mt-88 {
		margin-top: -8.8rem;
	}

	.lg\:-mr-88 {
		margin-right: -8.8rem;
	}

	.lg\:-mb-88 {
		margin-bottom: -8.8rem;
	}

	.lg\:-ml-88 {
		margin-left: -8.8rem;
	}

	.lg\:-mt-92 {
		margin-top: -9.2rem;
	}

	.lg\:-mr-92 {
		margin-right: -9.2rem;
	}

	.lg\:-mb-92 {
		margin-bottom: -9.2rem;
	}

	.lg\:-ml-92 {
		margin-left: -9.2rem;
	}

	.lg\:-mt-96 {
		margin-top: -9.6rem;
	}

	.lg\:-mr-96 {
		margin-right: -9.6rem;
	}

	.lg\:-mb-96 {
		margin-bottom: -9.6rem;
	}

	.lg\:-ml-96 {
		margin-left: -9.6rem;
	}

	.lg\:-mt-128 {
		margin-top: -12.8rem;
	}

	.lg\:-mr-128 {
		margin-right: -12.8rem;
	}

	.lg\:-mb-128 {
		margin-bottom: -12.8rem;
	}

	.lg\:-ml-128 {
		margin-left: -12.8rem;
	}

	.lg\:-mt-136 {
		margin-top: -13.6rem;
	}

	.lg\:-mr-136 {
		margin-right: -13.6rem;
	}

	.lg\:-mb-136 {
		margin-bottom: -13.6rem;
	}

	.lg\:-ml-136 {
		margin-left: -13.6rem;
	}

	.lg\:-mt-160 {
		margin-top: -16rem;
	}

	.lg\:-mr-160 {
		margin-right: -16rem;
	}

	.lg\:-mb-160 {
		margin-bottom: -16rem;
	}

	.lg\:-ml-160 {
		margin-left: -16rem;
	}

	.lg\:-mt-192 {
		margin-top: -19.2rem;
	}

	.lg\:-mr-192 {
		margin-right: -19.2rem;
	}

	.lg\:-mb-192 {
		margin-bottom: -19.2rem;
	}

	.lg\:-ml-192 {
		margin-left: -19.2rem;
	}

	.lg\:-mt-200 {
		margin-top: -20rem;
	}

	.lg\:-mr-200 {
		margin-right: -20rem;
	}

	.lg\:-mb-200 {
		margin-bottom: -20rem;
	}

	.lg\:-ml-200 {
		margin-left: -20rem;
	}

	.lg\:-mt-208 {
		margin-top: -20.8rem;
	}

	.lg\:-mr-208 {
		margin-right: -20.8rem;
	}

	.lg\:-mb-208 {
		margin-bottom: -20.8rem;
	}

	.lg\:-ml-208 {
		margin-left: -20.8rem;
	}

	.lg\:-mt-216 {
		margin-top: -21.6rem;
	}

	.lg\:-mr-216 {
		margin-right: -21.6rem;
	}

	.lg\:-mb-216 {
		margin-bottom: -21.6rem;
	}

	.lg\:-ml-216 {
		margin-left: -21.6rem;
	}

	.lg\:-mt-224 {
		margin-top: -22.4rem;
	}

	.lg\:-mr-224 {
		margin-right: -22.4rem;
	}

	.lg\:-mb-224 {
		margin-bottom: -22.4rem;
	}

	.lg\:-ml-224 {
		margin-left: -22.4rem;
	}

	.lg\:-mt-256 {
		margin-top: -25.6rem;
	}

	.lg\:-mr-256 {
		margin-right: -25.6rem;
	}

	.lg\:-mb-256 {
		margin-bottom: -25.6rem;
	}

	.lg\:-ml-256 {
		margin-left: -25.6rem;
	}

	.lg\:-mt-288 {
		margin-top: -28.8rem;
	}

	.lg\:-mr-288 {
		margin-right: -28.8rem;
	}

	.lg\:-mb-288 {
		margin-bottom: -28.8rem;
	}

	.lg\:-ml-288 {
		margin-left: -28.8rem;
	}

	.lg\:-mt-320 {
		margin-top: -32rem;
	}

	.lg\:-mr-320 {
		margin-right: -32rem;
	}

	.lg\:-mb-320 {
		margin-bottom: -32rem;
	}

	.lg\:-ml-320 {
		margin-left: -32rem;
	}

	.lg\:-mt-360 {
		margin-top: -36rem;
	}

	.lg\:-mr-360 {
		margin-right: -36rem;
	}

	.lg\:-mb-360 {
		margin-bottom: -36rem;
	}

	.lg\:-ml-360 {
		margin-left: -36rem;
	}

	.lg\:-mt-384 {
		margin-top: -38.4rem;
	}

	.lg\:-mr-384 {
		margin-right: -38.4rem;
	}

	.lg\:-mb-384 {
		margin-bottom: -38.4rem;
	}

	.lg\:-ml-384 {
		margin-left: -38.4rem;
	}

	.lg\:-mt-400 {
		margin-top: -40rem;
	}

	.lg\:-mr-400 {
		margin-right: -40rem;
	}

	.lg\:-mb-400 {
		margin-bottom: -40rem;
	}

	.lg\:-ml-400 {
		margin-left: -40rem;
	}

	.lg\:-mt-512 {
		margin-top: -51.2rem;
	}

	.lg\:-mr-512 {
		margin-right: -51.2rem;
	}

	.lg\:-mb-512 {
		margin-bottom: -51.2rem;
	}

	.lg\:-ml-512 {
		margin-left: -51.2rem;
	}

	.lg\:-mt-640 {
		margin-top: -64rem;
	}

	.lg\:-mr-640 {
		margin-right: -64rem;
	}

	.lg\:-mb-640 {
		margin-bottom: -64rem;
	}

	.lg\:-ml-640 {
		margin-left: -64rem;
	}

	.lg\:-mt-px {
		margin-top: -1px;
	}

	.lg\:-mr-px {
		margin-right: -1px;
	}

	.lg\:-mb-px {
		margin-bottom: -1px;
	}

	.lg\:-ml-px {
		margin-left: -1px;
	}

	.lg\:-mt-xs {
		margin-top: -32rem;
	}

	.lg\:-mr-xs {
		margin-right: -32rem;
	}

	.lg\:-mb-xs {
		margin-bottom: -32rem;
	}

	.lg\:-ml-xs {
		margin-left: -32rem;
	}

	.lg\:-mt-sm {
		margin-top: -48rem;
	}

	.lg\:-mr-sm {
		margin-right: -48rem;
	}

	.lg\:-mb-sm {
		margin-bottom: -48rem;
	}

	.lg\:-ml-sm {
		margin-left: -48rem;
	}

	.lg\:-mt-md {
		margin-top: -64rem;
	}

	.lg\:-mr-md {
		margin-right: -64rem;
	}

	.lg\:-mb-md {
		margin-bottom: -64rem;
	}

	.lg\:-ml-md {
		margin-left: -64rem;
	}

	.lg\:-mt-lg {
		margin-top: -80rem;
	}

	.lg\:-mr-lg {
		margin-right: -80rem;
	}

	.lg\:-mb-lg {
		margin-bottom: -80rem;
	}

	.lg\:-ml-lg {
		margin-left: -80rem;
	}

	.lg\:-mt-xl {
		margin-top: -96rem;
	}

	.lg\:-mr-xl {
		margin-right: -96rem;
	}

	.lg\:-mb-xl {
		margin-bottom: -96rem;
	}

	.lg\:-ml-xl {
		margin-left: -96rem;
	}

	.lg\:-mt-2xl {
		margin-top: -112rem;
	}

	.lg\:-mr-2xl {
		margin-right: -112rem;
	}

	.lg\:-mb-2xl {
		margin-bottom: -112rem;
	}

	.lg\:-ml-2xl {
		margin-left: -112rem;
	}

	.lg\:-mt-3xl {
		margin-top: -128rem;
	}

	.lg\:-mr-3xl {
		margin-right: -128rem;
	}

	.lg\:-mb-3xl {
		margin-bottom: -128rem;
	}

	.lg\:-ml-3xl {
		margin-left: -128rem;
	}

	.lg\:-mt-4xl {
		margin-top: -144rem;
	}

	.lg\:-mr-4xl {
		margin-right: -144rem;
	}

	.lg\:-mb-4xl {
		margin-bottom: -144rem;
	}

	.lg\:-ml-4xl {
		margin-left: -144rem;
	}

	.lg\:-mt-5xl {
		margin-top: -160rem;
	}

	.lg\:-mr-5xl {
		margin-right: -160rem;
	}

	.lg\:-mb-5xl {
		margin-bottom: -160rem;
	}

	.lg\:-ml-5xl {
		margin-left: -160rem;
	}

	.lg\:-mt-auto {
		margin-top: -auto;
	}

	.lg\:-mr-auto {
		margin-right: -auto;
	}

	.lg\:-mb-auto {
		margin-bottom: -auto;
	}

	.lg\:-ml-auto {
		margin-left: -auto;
	}

	.lg\:max-h-0 {
		max-height: 0;
	}

	.lg\:max-h-1 {
		max-height: 0.25rem;
	}

	.lg\:max-h-2 {
		max-height: 0.5rem;
	}

	.lg\:max-h-3 {
		max-height: 0.75rem;
	}

	.lg\:max-h-4 {
		max-height: 0.4rem;
	}

	.lg\:max-h-5 {
		max-height: 1.25rem;
	}

	.lg\:max-h-6 {
		max-height: 0.6rem;
	}

	.lg\:max-h-8 {
		max-height: 0.8rem;
	}

	.lg\:max-h-10 {
		max-height: 2.5rem;
	}

	.lg\:max-h-12 {
		max-height: 1.2rem;
	}

	.lg\:max-h-16 {
		max-height: 1.6rem;
	}

	.lg\:max-h-20 {
		max-height: 2rem;
	}

	.lg\:max-h-24 {
		max-height: 2.4rem;
	}

	.lg\:max-h-28 {
		max-height: 2.8rem;
	}

	.lg\:max-h-32 {
		max-height: 3.2rem;
	}

	.lg\:max-h-36 {
		max-height: 3.6rem;
	}

	.lg\:max-h-40 {
		max-height: 4rem;
	}

	.lg\:max-h-44 {
		max-height: 4.4rem;
	}

	.lg\:max-h-48 {
		max-height: 4.8rem;
	}

	.lg\:max-h-52 {
		max-height: 5.2rem;
	}

	.lg\:max-h-56 {
		max-height: 5.6rem;
	}

	.lg\:max-h-60 {
		max-height: 6rem;
	}

	.lg\:max-h-64 {
		max-height: 6.4rem;
	}

	.lg\:max-h-68 {
		max-height: 6.8rem;
	}

	.lg\:max-h-72 {
		max-height: 7.2rem;
	}

	.lg\:max-h-76 {
		max-height: 7.6rem;
	}

	.lg\:max-h-80 {
		max-height: 8rem;
	}

	.lg\:max-h-84 {
		max-height: 8.4rem;
	}

	.lg\:max-h-88 {
		max-height: 8.8rem;
	}

	.lg\:max-h-92 {
		max-height: 9.2rem;
	}

	.lg\:max-h-96 {
		max-height: 9.6rem;
	}

	.lg\:max-h-128 {
		max-height: 12.8rem;
	}

	.lg\:max-h-136 {
		max-height: 13.6rem;
	}

	.lg\:max-h-160 {
		max-height: 16rem;
	}

	.lg\:max-h-192 {
		max-height: 19.2rem;
	}

	.lg\:max-h-200 {
		max-height: 20rem;
	}

	.lg\:max-h-208 {
		max-height: 20.8rem;
	}

	.lg\:max-h-216 {
		max-height: 21.6rem;
	}

	.lg\:max-h-224 {
		max-height: 22.4rem;
	}

	.lg\:max-h-256 {
		max-height: 25.6rem;
	}

	.lg\:max-h-288 {
		max-height: 28.8rem;
	}

	.lg\:max-h-320 {
		max-height: 32rem;
	}

	.lg\:max-h-360 {
		max-height: 36rem;
	}

	.lg\:max-h-384 {
		max-height: 38.4rem;
	}

	.lg\:max-h-400 {
		max-height: 40rem;
	}

	.lg\:max-h-512 {
		max-height: 51.2rem;
	}

	.lg\:max-h-640 {
		max-height: 64rem;
	}

	.lg\:max-h-full {
		max-height: 100%;
	}

	.lg\:max-h-screen {
		max-height: 100vh;
	}

	.lg\:max-h-auto {
		max-height: auto;
	}

	.lg\:max-h-px {
		max-height: 1px;
	}

	.lg\:max-h-xs {
		max-height: 32rem;
	}

	.lg\:max-h-sm {
		max-height: 48rem;
	}

	.lg\:max-h-md {
		max-height: 64rem;
	}

	.lg\:max-h-lg {
		max-height: 80rem;
	}

	.lg\:max-h-xl {
		max-height: 96rem;
	}

	.lg\:max-h-2xl {
		max-height: 112rem;
	}

	.lg\:max-h-3xl {
		max-height: 128rem;
	}

	.lg\:max-h-4xl {
		max-height: 144rem;
	}

	.lg\:max-h-5xl {
		max-height: 160rem;
	}

	.lg\:max-w-0 {
		max-width: 0;
	}

	.lg\:max-w-1 {
		max-width: 0.25rem;
	}

	.lg\:max-w-2 {
		max-width: 0.5rem;
	}

	.lg\:max-w-3 {
		max-width: 0.75rem;
	}

	.lg\:max-w-4 {
		max-width: 0.4rem;
	}

	.lg\:max-w-5 {
		max-width: 1.25rem;
	}

	.lg\:max-w-6 {
		max-width: 0.6rem;
	}

	.lg\:max-w-8 {
		max-width: 0.8rem;
	}

	.lg\:max-w-10 {
		max-width: 2.5rem;
	}

	.lg\:max-w-12 {
		max-width: 1.2rem;
	}

	.lg\:max-w-16 {
		max-width: 1.6rem;
	}

	.lg\:max-w-20 {
		max-width: 2rem;
	}

	.lg\:max-w-24 {
		max-width: 2.4rem;
	}

	.lg\:max-w-28 {
		max-width: 2.8rem;
	}

	.lg\:max-w-32 {
		max-width: 3.2rem;
	}

	.lg\:max-w-36 {
		max-width: 3.6rem;
	}

	.lg\:max-w-40 {
		max-width: 4rem;
	}

	.lg\:max-w-44 {
		max-width: 4.4rem;
	}

	.lg\:max-w-48 {
		max-width: 4.8rem;
	}

	.lg\:max-w-52 {
		max-width: 5.2rem;
	}

	.lg\:max-w-56 {
		max-width: 5.6rem;
	}

	.lg\:max-w-60 {
		max-width: 6rem;
	}

	.lg\:max-w-64 {
		max-width: 6.4rem;
	}

	.lg\:max-w-68 {
		max-width: 6.8rem;
	}

	.lg\:max-w-72 {
		max-width: 7.2rem;
	}

	.lg\:max-w-76 {
		max-width: 7.6rem;
	}

	.lg\:max-w-80 {
		max-width: 8rem;
	}

	.lg\:max-w-84 {
		max-width: 8.4rem;
	}

	.lg\:max-w-88 {
		max-width: 8.8rem;
	}

	.lg\:max-w-92 {
		max-width: 9.2rem;
	}

	.lg\:max-w-96 {
		max-width: 9.6rem;
	}

	.lg\:max-w-128 {
		max-width: 12.8rem;
	}

	.lg\:max-w-136 {
		max-width: 13.6rem;
	}

	.lg\:max-w-160 {
		max-width: 16rem;
	}

	.lg\:max-w-192 {
		max-width: 19.2rem;
	}

	.lg\:max-w-200 {
		max-width: 20rem;
	}

	.lg\:max-w-208 {
		max-width: 20.8rem;
	}

	.lg\:max-w-216 {
		max-width: 21.6rem;
	}

	.lg\:max-w-224 {
		max-width: 22.4rem;
	}

	.lg\:max-w-256 {
		max-width: 25.6rem;
	}

	.lg\:max-w-288 {
		max-width: 28.8rem;
	}

	.lg\:max-w-320 {
		max-width: 32rem;
	}

	.lg\:max-w-360 {
		max-width: 36rem;
	}

	.lg\:max-w-384 {
		max-width: 38.4rem;
	}

	.lg\:max-w-400 {
		max-width: 40rem;
	}

	.lg\:max-w-512 {
		max-width: 51.2rem;
	}

	.lg\:max-w-640 {
		max-width: 64rem;
	}

	.lg\:max-w-xs {
		max-width: 32rem;
	}

	.lg\:max-w-sm {
		max-width: 48rem;
	}

	.lg\:max-w-md {
		max-width: 64rem;
	}

	.lg\:max-w-lg {
		max-width: 80rem;
	}

	.lg\:max-w-xl {
		max-width: 96rem;
	}

	.lg\:max-w-2xl {
		max-width: 112rem;
	}

	.lg\:max-w-3xl {
		max-width: 128rem;
	}

	.lg\:max-w-4xl {
		max-width: 144rem;
	}

	.lg\:max-w-5xl {
		max-width: 160rem;
	}

	.lg\:max-w-6xl {
		max-width: 72rem;
	}

	.lg\:max-w-full {
		max-width: 100%;
	}

	.lg\:max-w-px {
		max-width: 1px;
	}

	.lg\:max-w-auto {
		max-width: auto;
	}

	.lg\:max-w-screen {
		max-width: 100vw;
	}

	.lg\:min-h-0 {
		min-height: 0;
	}

	.lg\:min-h-1 {
		min-height: 0.25rem;
	}

	.lg\:min-h-2 {
		min-height: 0.5rem;
	}

	.lg\:min-h-3 {
		min-height: 0.75rem;
	}

	.lg\:min-h-4 {
		min-height: 0.4rem;
	}

	.lg\:min-h-5 {
		min-height: 1.25rem;
	}

	.lg\:min-h-6 {
		min-height: 0.6rem;
	}

	.lg\:min-h-8 {
		min-height: 0.8rem;
	}

	.lg\:min-h-10 {
		min-height: 2.5rem;
	}

	.lg\:min-h-12 {
		min-height: 1.2rem;
	}

	.lg\:min-h-16 {
		min-height: 1.6rem;
	}

	.lg\:min-h-20 {
		min-height: 2rem;
	}

	.lg\:min-h-24 {
		min-height: 2.4rem;
	}

	.lg\:min-h-28 {
		min-height: 2.8rem;
	}

	.lg\:min-h-32 {
		min-height: 3.2rem;
	}

	.lg\:min-h-36 {
		min-height: 3.6rem;
	}

	.lg\:min-h-40 {
		min-height: 4rem;
	}

	.lg\:min-h-44 {
		min-height: 4.4rem;
	}

	.lg\:min-h-48 {
		min-height: 4.8rem;
	}

	.lg\:min-h-52 {
		min-height: 5.2rem;
	}

	.lg\:min-h-56 {
		min-height: 5.6rem;
	}

	.lg\:min-h-60 {
		min-height: 6rem;
	}

	.lg\:min-h-64 {
		min-height: 6.4rem;
	}

	.lg\:min-h-68 {
		min-height: 6.8rem;
	}

	.lg\:min-h-72 {
		min-height: 7.2rem;
	}

	.lg\:min-h-76 {
		min-height: 7.6rem;
	}

	.lg\:min-h-80 {
		min-height: 8rem;
	}

	.lg\:min-h-84 {
		min-height: 8.4rem;
	}

	.lg\:min-h-88 {
		min-height: 8.8rem;
	}

	.lg\:min-h-92 {
		min-height: 9.2rem;
	}

	.lg\:min-h-96 {
		min-height: 9.6rem;
	}

	.lg\:min-h-128 {
		min-height: 12.8rem;
	}

	.lg\:min-h-136 {
		min-height: 13.6rem;
	}

	.lg\:min-h-160 {
		min-height: 16rem;
	}

	.lg\:min-h-192 {
		min-height: 19.2rem;
	}

	.lg\:min-h-200 {
		min-height: 20rem;
	}

	.lg\:min-h-208 {
		min-height: 20.8rem;
	}

	.lg\:min-h-216 {
		min-height: 21.6rem;
	}

	.lg\:min-h-224 {
		min-height: 22.4rem;
	}

	.lg\:min-h-256 {
		min-height: 25.6rem;
	}

	.lg\:min-h-288 {
		min-height: 28.8rem;
	}

	.lg\:min-h-320 {
		min-height: 32rem;
	}

	.lg\:min-h-360 {
		min-height: 36rem;
	}

	.lg\:min-h-384 {
		min-height: 38.4rem;
	}

	.lg\:min-h-400 {
		min-height: 40rem;
	}

	.lg\:min-h-512 {
		min-height: 51.2rem;
	}

	.lg\:min-h-640 {
		min-height: 64rem;
	}

	.lg\:min-h-full {
		min-height: 100%;
	}

	.lg\:min-h-screen {
		min-height: 100vh;
	}

	.lg\:min-h-auto {
		min-height: auto;
	}

	.lg\:min-h-px {
		min-height: 1px;
	}

	.lg\:min-h-xs {
		min-height: 32rem;
	}

	.lg\:min-h-sm {
		min-height: 48rem;
	}

	.lg\:min-h-md {
		min-height: 64rem;
	}

	.lg\:min-h-lg {
		min-height: 80rem;
	}

	.lg\:min-h-xl {
		min-height: 96rem;
	}

	.lg\:min-h-2xl {
		min-height: 112rem;
	}

	.lg\:min-h-3xl {
		min-height: 128rem;
	}

	.lg\:min-h-4xl {
		min-height: 144rem;
	}

	.lg\:min-h-5xl {
		min-height: 160rem;
	}

	.lg\:min-w-0 {
		min-width: 0;
	}

	.lg\:min-w-1 {
		min-width: 0.25rem;
	}

	.lg\:min-w-2 {
		min-width: 0.5rem;
	}

	.lg\:min-w-3 {
		min-width: 0.75rem;
	}

	.lg\:min-w-4 {
		min-width: 0.4rem;
	}

	.lg\:min-w-5 {
		min-width: 1.25rem;
	}

	.lg\:min-w-6 {
		min-width: 0.6rem;
	}

	.lg\:min-w-8 {
		min-width: 0.8rem;
	}

	.lg\:min-w-10 {
		min-width: 2.5rem;
	}

	.lg\:min-w-12 {
		min-width: 1.2rem;
	}

	.lg\:min-w-16 {
		min-width: 1.6rem;
	}

	.lg\:min-w-20 {
		min-width: 2rem;
	}

	.lg\:min-w-24 {
		min-width: 2.4rem;
	}

	.lg\:min-w-28 {
		min-width: 2.8rem;
	}

	.lg\:min-w-32 {
		min-width: 3.2rem;
	}

	.lg\:min-w-36 {
		min-width: 3.6rem;
	}

	.lg\:min-w-40 {
		min-width: 4rem;
	}

	.lg\:min-w-44 {
		min-width: 4.4rem;
	}

	.lg\:min-w-48 {
		min-width: 4.8rem;
	}

	.lg\:min-w-52 {
		min-width: 5.2rem;
	}

	.lg\:min-w-56 {
		min-width: 5.6rem;
	}

	.lg\:min-w-60 {
		min-width: 6rem;
	}

	.lg\:min-w-64 {
		min-width: 6.4rem;
	}

	.lg\:min-w-68 {
		min-width: 6.8rem;
	}

	.lg\:min-w-72 {
		min-width: 7.2rem;
	}

	.lg\:min-w-76 {
		min-width: 7.6rem;
	}

	.lg\:min-w-80 {
		min-width: 8rem;
	}

	.lg\:min-w-84 {
		min-width: 8.4rem;
	}

	.lg\:min-w-88 {
		min-width: 8.8rem;
	}

	.lg\:min-w-92 {
		min-width: 9.2rem;
	}

	.lg\:min-w-96 {
		min-width: 9.6rem;
	}

	.lg\:min-w-128 {
		min-width: 12.8rem;
	}

	.lg\:min-w-136 {
		min-width: 13.6rem;
	}

	.lg\:min-w-160 {
		min-width: 16rem;
	}

	.lg\:min-w-192 {
		min-width: 19.2rem;
	}

	.lg\:min-w-200 {
		min-width: 20rem;
	}

	.lg\:min-w-208 {
		min-width: 20.8rem;
	}

	.lg\:min-w-216 {
		min-width: 21.6rem;
	}

	.lg\:min-w-224 {
		min-width: 22.4rem;
	}

	.lg\:min-w-256 {
		min-width: 25.6rem;
	}

	.lg\:min-w-288 {
		min-width: 28.8rem;
	}

	.lg\:min-w-320 {
		min-width: 32rem;
	}

	.lg\:min-w-360 {
		min-width: 36rem;
	}

	.lg\:min-w-384 {
		min-width: 38.4rem;
	}

	.lg\:min-w-400 {
		min-width: 40rem;
	}

	.lg\:min-w-512 {
		min-width: 51.2rem;
	}

	.lg\:min-w-640 {
		min-width: 64rem;
	}

	.lg\:min-w-full {
		min-width: 100%;
	}

	.lg\:min-w-px {
		min-width: 1px;
	}

	.lg\:min-w-xs {
		min-width: 32rem;
	}

	.lg\:min-w-sm {
		min-width: 48rem;
	}

	.lg\:min-w-md {
		min-width: 64rem;
	}

	.lg\:min-w-lg {
		min-width: 80rem;
	}

	.lg\:min-w-xl {
		min-width: 96rem;
	}

	.lg\:min-w-2xl {
		min-width: 112rem;
	}

	.lg\:min-w-3xl {
		min-width: 128rem;
	}

	.lg\:min-w-4xl {
		min-width: 144rem;
	}

	.lg\:min-w-5xl {
		min-width: 160rem;
	}

	.lg\:min-w-auto {
		min-width: auto;
	}

	.lg\:min-w-screen {
		min-width: 100vw;
	}

	.lg\:object-contain {
		object-fit: contain;
	}

	.lg\:object-cover {
		object-fit: cover;
	}

	.lg\:object-fill {
		object-fit: fill;
	}

	.lg\:object-none {
		object-fit: none;
	}

	.lg\:object-scale-down {
		object-fit: scale-down;
	}

	.lg\:object-bottom {
		object-position: bottom;
	}

	.lg\:object-center {
		object-position: center;
	}

	.lg\:object-left {
		object-position: left;
	}

	.lg\:object-left-bottom {
		object-position: left bottom;
	}

	.lg\:object-left-top {
		object-position: left top;
	}

	.lg\:object-right {
		object-position: right;
	}

	.lg\:object-right-bottom {
		object-position: right bottom;
	}

	.lg\:object-right-top {
		object-position: right top;
	}

	.lg\:object-top {
		object-position: top;
	}

	.lg\:opacity-0 {
		opacity: 0;
	}

	.lg\:opacity-25 {
		opacity: 0.25;
	}

	.lg\:opacity-50 {
		opacity: 0.5;
	}

	.lg\:opacity-75 {
		opacity: 0.75;
	}

	.lg\:opacity-100 {
		opacity: 1;
	}

	.lg\:outline-none {
		outline: 0;
	}

	.lg\:focus\:outline-none:focus {
		outline: 0;
	}

	.lg\:overflow-auto {
		overflow: auto;
	}

	.lg\:overflow-hidden {
		overflow: hidden;
	}

	.lg\:overflow-visible {
		overflow: visible;
	}

	.lg\:overflow-scroll {
		overflow: scroll;
	}

	.lg\:overflow-x-auto {
		overflow-x: auto;
	}

	.lg\:overflow-y-auto {
		overflow-y: auto;
	}

	.lg\:overflow-x-hidden {
		overflow-x: hidden;
	}

	.lg\:overflow-y-hidden {
		overflow-y: hidden;
	}

	.lg\:overflow-x-visible {
		overflow-x: visible;
	}

	.lg\:overflow-y-visible {
		overflow-y: visible;
	}

	.lg\:overflow-x-scroll {
		overflow-x: scroll;
	}

	.lg\:overflow-y-scroll {
		overflow-y: scroll;
	}

	.lg\:scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}

	.lg\:scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}

	.lg\:p-0 {
		padding: 0;
	}

	.lg\:p-1 {
		padding: 0.25rem;
	}

	.lg\:p-2 {
		padding: 0.5rem;
	}

	.lg\:p-3 {
		padding: 0.75rem;
	}

	.lg\:p-4 {
		padding: 0.4rem;
	}

	.lg\:p-5 {
		padding: 1.25rem;
	}

	.lg\:p-6 {
		padding: 0.6rem;
	}

	.lg\:p-8 {
		padding: 0.8rem;
	}

	.lg\:p-10 {
		padding: 2.5rem;
	}

	.lg\:p-12 {
		padding: 1.2rem;
	}

	.lg\:p-16 {
		padding: 1.6rem;
	}

	.lg\:p-20 {
		padding: 2rem;
	}

	.lg\:p-24 {
		padding: 2.4rem;
	}

	.lg\:p-28 {
		padding: 2.8rem;
	}

	.lg\:p-32 {
		padding: 3.2rem;
	}

	.lg\:p-36 {
		padding: 3.6rem;
	}

	.lg\:p-40 {
		padding: 4rem;
	}

	.lg\:p-44 {
		padding: 4.4rem;
	}

	.lg\:p-48 {
		padding: 4.8rem;
	}

	.lg\:p-52 {
		padding: 5.2rem;
	}

	.lg\:p-56 {
		padding: 5.6rem;
	}

	.lg\:p-60 {
		padding: 6rem;
	}

	.lg\:p-64 {
		padding: 6.4rem;
	}

	.lg\:p-68 {
		padding: 6.8rem;
	}

	.lg\:p-72 {
		padding: 7.2rem;
	}

	.lg\:p-76 {
		padding: 7.6rem;
	}

	.lg\:p-80 {
		padding: 8rem;
	}

	.lg\:p-84 {
		padding: 8.4rem;
	}

	.lg\:p-88 {
		padding: 8.8rem;
	}

	.lg\:p-92 {
		padding: 9.2rem;
	}

	.lg\:p-96 {
		padding: 9.6rem;
	}

	.lg\:p-128 {
		padding: 12.8rem;
	}

	.lg\:p-136 {
		padding: 13.6rem;
	}

	.lg\:p-160 {
		padding: 16rem;
	}

	.lg\:p-192 {
		padding: 19.2rem;
	}

	.lg\:p-200 {
		padding: 20rem;
	}

	.lg\:p-208 {
		padding: 20.8rem;
	}

	.lg\:p-216 {
		padding: 21.6rem;
	}

	.lg\:p-224 {
		padding: 22.4rem;
	}

	.lg\:p-256 {
		padding: 25.6rem;
	}

	.lg\:p-288 {
		padding: 28.8rem;
	}

	.lg\:p-320 {
		padding: 32rem;
	}

	.lg\:p-360 {
		padding: 36rem;
	}

	.lg\:p-384 {
		padding: 38.4rem;
	}

	.lg\:p-400 {
		padding: 40rem;
	}

	.lg\:p-512 {
		padding: 51.2rem;
	}

	.lg\:p-640 {
		padding: 64rem;
	}

	.lg\:p-px {
		padding: 1px;
	}

	.lg\:p-xs {
		padding: 32rem;
	}

	.lg\:p-sm {
		padding: 48rem;
	}

	.lg\:p-md {
		padding: 64rem;
	}

	.lg\:p-lg {
		padding: 80rem;
	}

	.lg\:p-xl {
		padding: 96rem;
	}

	.lg\:p-2xl {
		padding: 112rem;
	}

	.lg\:p-3xl {
		padding: 128rem;
	}

	.lg\:p-4xl {
		padding: 144rem;
	}

	.lg\:p-5xl {
		padding: 160rem;
	}

	.lg\:p-auto {
		padding: auto;
	}

	.lg\:py-0 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.lg\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.lg\:py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.lg\:px-1 {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.lg\:py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.lg\:px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.lg\:py-3 {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.lg\:px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.lg\:py-4 {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.lg\:px-4 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}

	.lg\:py-5 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.lg\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.lg\:py-6 {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.lg\:px-6 {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.lg\:py-8 {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
	}

	.lg\:px-8 {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.lg\:py-10 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.lg\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.lg\:py-12 {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.lg\:px-12 {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}

	.lg\:py-16 {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	.lg\:px-16 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.lg\:py-20 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.lg\:px-20 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.lg\:py-24 {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	.lg\:px-24 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.lg\:py-28 {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}

	.lg\:px-28 {
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	.lg\:py-32 {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.lg\:px-32 {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.lg\:py-36 {
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
	}

	.lg\:px-36 {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.lg\:py-40 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.lg\:px-40 {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	.lg\:py-44 {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.lg\:px-44 {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.lg\:py-48 {
		padding-top: 4.8rem;
		padding-bottom: 4.8rem;
	}

	.lg\:px-48 {
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.lg\:py-52 {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.lg\:px-52 {
		padding-left: 5.2rem;
		padding-right: 5.2rem;
	}

	.lg\:py-56 {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}

	.lg\:px-56 {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}

	.lg\:py-60 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.lg\:px-60 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.lg\:py-64 {
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;
	}

	.lg\:px-64 {
		padding-left: 6.4rem;
		padding-right: 6.4rem;
	}

	.lg\:py-68 {
		padding-top: 6.8rem;
		padding-bottom: 6.8rem;
	}

	.lg\:px-68 {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.lg\:py-72 {
		padding-top: 7.2rem;
		padding-bottom: 7.2rem;
	}

	.lg\:px-72 {
		padding-left: 7.2rem;
		padding-right: 7.2rem;
	}

	.lg\:py-76 {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
	}

	.lg\:px-76 {
		padding-left: 7.6rem;
		padding-right: 7.6rem;
	}

	.lg\:py-80 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.lg\:px-80 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.lg\:py-84 {
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}

	.lg\:px-84 {
		padding-left: 8.4rem;
		padding-right: 8.4rem;
	}

	.lg\:py-88 {
		padding-top: 8.8rem;
		padding-bottom: 8.8rem;
	}

	.lg\:px-88 {
		padding-left: 8.8rem;
		padding-right: 8.8rem;
	}

	.lg\:py-92 {
		padding-top: 9.2rem;
		padding-bottom: 9.2rem;
	}

	.lg\:px-92 {
		padding-left: 9.2rem;
		padding-right: 9.2rem;
	}

	.lg\:py-96 {
		padding-top: 9.6rem;
		padding-bottom: 9.6rem;
	}

	.lg\:px-96 {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}

	.lg\:py-128 {
		padding-top: 12.8rem;
		padding-bottom: 12.8rem;
	}

	.lg\:px-128 {
		padding-left: 12.8rem;
		padding-right: 12.8rem;
	}

	.lg\:py-136 {
		padding-top: 13.6rem;
		padding-bottom: 13.6rem;
	}

	.lg\:px-136 {
		padding-left: 13.6rem;
		padding-right: 13.6rem;
	}

	.lg\:py-160 {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}

	.lg\:px-160 {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.lg\:py-192 {
		padding-top: 19.2rem;
		padding-bottom: 19.2rem;
	}

	.lg\:px-192 {
		padding-left: 19.2rem;
		padding-right: 19.2rem;
	}

	.lg\:py-200 {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.lg\:px-200 {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.lg\:py-208 {
		padding-top: 20.8rem;
		padding-bottom: 20.8rem;
	}

	.lg\:px-208 {
		padding-left: 20.8rem;
		padding-right: 20.8rem;
	}

	.lg\:py-216 {
		padding-top: 21.6rem;
		padding-bottom: 21.6rem;
	}

	.lg\:px-216 {
		padding-left: 21.6rem;
		padding-right: 21.6rem;
	}

	.lg\:py-224 {
		padding-top: 22.4rem;
		padding-bottom: 22.4rem;
	}

	.lg\:px-224 {
		padding-left: 22.4rem;
		padding-right: 22.4rem;
	}

	.lg\:py-256 {
		padding-top: 25.6rem;
		padding-bottom: 25.6rem;
	}

	.lg\:px-256 {
		padding-left: 25.6rem;
		padding-right: 25.6rem;
	}

	.lg\:py-288 {
		padding-top: 28.8rem;
		padding-bottom: 28.8rem;
	}

	.lg\:px-288 {
		padding-left: 28.8rem;
		padding-right: 28.8rem;
	}

	.lg\:py-320 {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.lg\:px-320 {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.lg\:py-360 {
		padding-top: 36rem;
		padding-bottom: 36rem;
	}

	.lg\:px-360 {
		padding-left: 36rem;
		padding-right: 36rem;
	}

	.lg\:py-384 {
		padding-top: 38.4rem;
		padding-bottom: 38.4rem;
	}

	.lg\:px-384 {
		padding-left: 38.4rem;
		padding-right: 38.4rem;
	}

	.lg\:py-400 {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.lg\:px-400 {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.lg\:py-512 {
		padding-top: 51.2rem;
		padding-bottom: 51.2rem;
	}

	.lg\:px-512 {
		padding-left: 51.2rem;
		padding-right: 51.2rem;
	}

	.lg\:py-640 {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.lg\:px-640 {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.lg\:py-px {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.lg\:px-px {
		padding-left: 1px;
		padding-right: 1px;
	}

	.lg\:py-xs {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.lg\:px-xs {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.lg\:py-sm {
		padding-top: 48rem;
		padding-bottom: 48rem;
	}

	.lg\:px-sm {
		padding-left: 48rem;
		padding-right: 48rem;
	}

	.lg\:py-md {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.lg\:px-md {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.lg\:py-lg {
		padding-top: 80rem;
		padding-bottom: 80rem;
	}

	.lg\:px-lg {
		padding-left: 80rem;
		padding-right: 80rem;
	}

	.lg\:py-xl {
		padding-top: 96rem;
		padding-bottom: 96rem;
	}

	.lg\:px-xl {
		padding-left: 96rem;
		padding-right: 96rem;
	}

	.lg\:py-2xl {
		padding-top: 112rem;
		padding-bottom: 112rem;
	}

	.lg\:px-2xl {
		padding-left: 112rem;
		padding-right: 112rem;
	}

	.lg\:py-3xl {
		padding-top: 128rem;
		padding-bottom: 128rem;
	}

	.lg\:px-3xl {
		padding-left: 128rem;
		padding-right: 128rem;
	}

	.lg\:py-4xl {
		padding-top: 144rem;
		padding-bottom: 144rem;
	}

	.lg\:px-4xl {
		padding-left: 144rem;
		padding-right: 144rem;
	}

	.lg\:py-5xl {
		padding-top: 160rem;
		padding-bottom: 160rem;
	}

	.lg\:px-5xl {
		padding-left: 160rem;
		padding-right: 160rem;
	}

	.lg\:py-auto {
		padding-top: auto;
		padding-bottom: auto;
	}

	.lg\:px-auto {
		padding-left: auto;
		padding-right: auto;
	}

	.lg\:pt-0 {
		padding-top: 0;
	}

	.lg\:pr-0 {
		padding-right: 0;
	}

	.lg\:pb-0 {
		padding-bottom: 0;
	}

	.lg\:pl-0 {
		padding-left: 0;
	}

	.lg\:pt-1 {
		padding-top: 0.25rem;
	}

	.lg\:pr-1 {
		padding-right: 0.25rem;
	}

	.lg\:pb-1 {
		padding-bottom: 0.25rem;
	}

	.lg\:pl-1 {
		padding-left: 0.25rem;
	}

	.lg\:pt-2 {
		padding-top: 0.5rem;
	}

	.lg\:pr-2 {
		padding-right: 0.5rem;
	}

	.lg\:pb-2 {
		padding-bottom: 0.5rem;
	}

	.lg\:pl-2 {
		padding-left: 0.5rem;
	}

	.lg\:pt-3 {
		padding-top: 0.75rem;
	}

	.lg\:pr-3 {
		padding-right: 0.75rem;
	}

	.lg\:pb-3 {
		padding-bottom: 0.75rem;
	}

	.lg\:pl-3 {
		padding-left: 0.75rem;
	}

	.lg\:pt-4 {
		padding-top: 0.4rem;
	}

	.lg\:pr-4 {
		padding-right: 0.4rem;
	}

	.lg\:pb-4 {
		padding-bottom: 0.4rem;
	}

	.lg\:pl-4 {
		padding-left: 0.4rem;
	}

	.lg\:pt-5 {
		padding-top: 1.25rem;
	}

	.lg\:pr-5 {
		padding-right: 1.25rem;
	}

	.lg\:pb-5 {
		padding-bottom: 1.25rem;
	}

	.lg\:pl-5 {
		padding-left: 1.25rem;
	}

	.lg\:pt-6 {
		padding-top: 0.6rem;
	}

	.lg\:pr-6 {
		padding-right: 0.6rem;
	}

	.lg\:pb-6 {
		padding-bottom: 0.6rem;
	}

	.lg\:pl-6 {
		padding-left: 0.6rem;
	}

	.lg\:pt-8 {
		padding-top: 0.8rem;
	}

	.lg\:pr-8 {
		padding-right: 0.8rem;
	}

	.lg\:pb-8 {
		padding-bottom: 0.8rem;
	}

	.lg\:pl-8 {
		padding-left: 0.8rem;
	}

	.lg\:pt-10 {
		padding-top: 2.5rem;
	}

	.lg\:pr-10 {
		padding-right: 2.5rem;
	}

	.lg\:pb-10 {
		padding-bottom: 2.5rem;
	}

	.lg\:pl-10 {
		padding-left: 2.5rem;
	}

	.lg\:pt-12 {
		padding-top: 1.2rem;
	}

	.lg\:pr-12 {
		padding-right: 1.2rem;
	}

	.lg\:pb-12 {
		padding-bottom: 1.2rem;
	}

	.lg\:pl-12 {
		padding-left: 1.2rem;
	}

	.lg\:pt-16 {
		padding-top: 1.6rem;
	}

	.lg\:pr-16 {
		padding-right: 1.6rem;
	}

	.lg\:pb-16 {
		padding-bottom: 1.6rem;
	}

	.lg\:pl-16 {
		padding-left: 1.6rem;
	}

	.lg\:pt-20 {
		padding-top: 2rem;
	}

	.lg\:pr-20 {
		padding-right: 2rem;
	}

	.lg\:pb-20 {
		padding-bottom: 2rem;
	}

	.lg\:pl-20 {
		padding-left: 2rem;
	}

	.lg\:pt-24 {
		padding-top: 2.4rem;
	}

	.lg\:pr-24 {
		padding-right: 2.4rem;
	}

	.lg\:pb-24 {
		padding-bottom: 2.4rem;
	}

	.lg\:pl-24 {
		padding-left: 2.4rem;
	}

	.lg\:pt-28 {
		padding-top: 2.8rem;
	}

	.lg\:pr-28 {
		padding-right: 2.8rem;
	}

	.lg\:pb-28 {
		padding-bottom: 2.8rem;
	}

	.lg\:pl-28 {
		padding-left: 2.8rem;
	}

	.lg\:pt-32 {
		padding-top: 3.2rem;
	}

	.lg\:pr-32 {
		padding-right: 3.2rem;
	}

	.lg\:pb-32 {
		padding-bottom: 3.2rem;
	}

	.lg\:pl-32 {
		padding-left: 3.2rem;
	}

	.lg\:pt-36 {
		padding-top: 3.6rem;
	}

	.lg\:pr-36 {
		padding-right: 3.6rem;
	}

	.lg\:pb-36 {
		padding-bottom: 3.6rem;
	}

	.lg\:pl-36 {
		padding-left: 3.6rem;
	}

	.lg\:pt-40 {
		padding-top: 4rem;
	}

	.lg\:pr-40 {
		padding-right: 4rem;
	}

	.lg\:pb-40 {
		padding-bottom: 4rem;
	}

	.lg\:pl-40 {
		padding-left: 4rem;
	}

	.lg\:pt-44 {
		padding-top: 4.4rem;
	}

	.lg\:pr-44 {
		padding-right: 4.4rem;
	}

	.lg\:pb-44 {
		padding-bottom: 4.4rem;
	}

	.lg\:pl-44 {
		padding-left: 4.4rem;
	}

	.lg\:pt-48 {
		padding-top: 4.8rem;
	}

	.lg\:pr-48 {
		padding-right: 4.8rem;
	}

	.lg\:pb-48 {
		padding-bottom: 4.8rem;
	}

	.lg\:pl-48 {
		padding-left: 4.8rem;
	}

	.lg\:pt-52 {
		padding-top: 5.2rem;
	}

	.lg\:pr-52 {
		padding-right: 5.2rem;
	}

	.lg\:pb-52 {
		padding-bottom: 5.2rem;
	}

	.lg\:pl-52 {
		padding-left: 5.2rem;
	}

	.lg\:pt-56 {
		padding-top: 5.6rem;
	}

	.lg\:pr-56 {
		padding-right: 5.6rem;
	}

	.lg\:pb-56 {
		padding-bottom: 5.6rem;
	}

	.lg\:pl-56 {
		padding-left: 5.6rem;
	}

	.lg\:pt-60 {
		padding-top: 6rem;
	}

	.lg\:pr-60 {
		padding-right: 6rem;
	}

	.lg\:pb-60 {
		padding-bottom: 6rem;
	}

	.lg\:pl-60 {
		padding-left: 6rem;
	}

	.lg\:pt-64 {
		padding-top: 6.4rem;
	}

	.lg\:pr-64 {
		padding-right: 6.4rem;
	}

	.lg\:pb-64 {
		padding-bottom: 6.4rem;
	}

	.lg\:pl-64 {
		padding-left: 6.4rem;
	}

	.lg\:pt-68 {
		padding-top: 6.8rem;
	}

	.lg\:pr-68 {
		padding-right: 6.8rem;
	}

	.lg\:pb-68 {
		padding-bottom: 6.8rem;
	}

	.lg\:pl-68 {
		padding-left: 6.8rem;
	}

	.lg\:pt-72 {
		padding-top: 7.2rem;
	}

	.lg\:pr-72 {
		padding-right: 7.2rem;
	}

	.lg\:pb-72 {
		padding-bottom: 7.2rem;
	}

	.lg\:pl-72 {
		padding-left: 7.2rem;
	}

	.lg\:pt-76 {
		padding-top: 7.6rem;
	}

	.lg\:pr-76 {
		padding-right: 7.6rem;
	}

	.lg\:pb-76 {
		padding-bottom: 7.6rem;
	}

	.lg\:pl-76 {
		padding-left: 7.6rem;
	}

	.lg\:pt-80 {
		padding-top: 8rem;
	}

	.lg\:pr-80 {
		padding-right: 8rem;
	}

	.lg\:pb-80 {
		padding-bottom: 8rem;
	}

	.lg\:pl-80 {
		padding-left: 8rem;
	}

	.lg\:pt-84 {
		padding-top: 8.4rem;
	}

	.lg\:pr-84 {
		padding-right: 8.4rem;
	}

	.lg\:pb-84 {
		padding-bottom: 8.4rem;
	}

	.lg\:pl-84 {
		padding-left: 8.4rem;
	}

	.lg\:pt-88 {
		padding-top: 8.8rem;
	}

	.lg\:pr-88 {
		padding-right: 8.8rem;
	}

	.lg\:pb-88 {
		padding-bottom: 8.8rem;
	}

	.lg\:pl-88 {
		padding-left: 8.8rem;
	}

	.lg\:pt-92 {
		padding-top: 9.2rem;
	}

	.lg\:pr-92 {
		padding-right: 9.2rem;
	}

	.lg\:pb-92 {
		padding-bottom: 9.2rem;
	}

	.lg\:pl-92 {
		padding-left: 9.2rem;
	}

	.lg\:pt-96 {
		padding-top: 9.6rem;
	}

	.lg\:pr-96 {
		padding-right: 9.6rem;
	}

	.lg\:pb-96 {
		padding-bottom: 9.6rem;
	}

	.lg\:pl-96 {
		padding-left: 9.6rem;
	}

	.lg\:pt-128 {
		padding-top: 12.8rem;
	}

	.lg\:pr-128 {
		padding-right: 12.8rem;
	}

	.lg\:pb-128 {
		padding-bottom: 12.8rem;
	}

	.lg\:pl-128 {
		padding-left: 12.8rem;
	}

	.lg\:pt-136 {
		padding-top: 13.6rem;
	}

	.lg\:pr-136 {
		padding-right: 13.6rem;
	}

	.lg\:pb-136 {
		padding-bottom: 13.6rem;
	}

	.lg\:pl-136 {
		padding-left: 13.6rem;
	}

	.lg\:pt-160 {
		padding-top: 16rem;
	}

	.lg\:pr-160 {
		padding-right: 16rem;
	}

	.lg\:pb-160 {
		padding-bottom: 16rem;
	}

	.lg\:pl-160 {
		padding-left: 16rem;
	}

	.lg\:pt-192 {
		padding-top: 19.2rem;
	}

	.lg\:pr-192 {
		padding-right: 19.2rem;
	}

	.lg\:pb-192 {
		padding-bottom: 19.2rem;
	}

	.lg\:pl-192 {
		padding-left: 19.2rem;
	}

	.lg\:pt-200 {
		padding-top: 20rem;
	}

	.lg\:pr-200 {
		padding-right: 20rem;
	}

	.lg\:pb-200 {
		padding-bottom: 20rem;
	}

	.lg\:pl-200 {
		padding-left: 20rem;
	}

	.lg\:pt-208 {
		padding-top: 20.8rem;
	}

	.lg\:pr-208 {
		padding-right: 20.8rem;
	}

	.lg\:pb-208 {
		padding-bottom: 20.8rem;
	}

	.lg\:pl-208 {
		padding-left: 20.8rem;
	}

	.lg\:pt-216 {
		padding-top: 21.6rem;
	}

	.lg\:pr-216 {
		padding-right: 21.6rem;
	}

	.lg\:pb-216 {
		padding-bottom: 21.6rem;
	}

	.lg\:pl-216 {
		padding-left: 21.6rem;
	}

	.lg\:pt-224 {
		padding-top: 22.4rem;
	}

	.lg\:pr-224 {
		padding-right: 22.4rem;
	}

	.lg\:pb-224 {
		padding-bottom: 22.4rem;
	}

	.lg\:pl-224 {
		padding-left: 22.4rem;
	}

	.lg\:pt-256 {
		padding-top: 25.6rem;
	}

	.lg\:pr-256 {
		padding-right: 25.6rem;
	}

	.lg\:pb-256 {
		padding-bottom: 25.6rem;
	}

	.lg\:pl-256 {
		padding-left: 25.6rem;
	}

	.lg\:pt-288 {
		padding-top: 28.8rem;
	}

	.lg\:pr-288 {
		padding-right: 28.8rem;
	}

	.lg\:pb-288 {
		padding-bottom: 28.8rem;
	}

	.lg\:pl-288 {
		padding-left: 28.8rem;
	}

	.lg\:pt-320 {
		padding-top: 32rem;
	}

	.lg\:pr-320 {
		padding-right: 32rem;
	}

	.lg\:pb-320 {
		padding-bottom: 32rem;
	}

	.lg\:pl-320 {
		padding-left: 32rem;
	}

	.lg\:pt-360 {
		padding-top: 36rem;
	}

	.lg\:pr-360 {
		padding-right: 36rem;
	}

	.lg\:pb-360 {
		padding-bottom: 36rem;
	}

	.lg\:pl-360 {
		padding-left: 36rem;
	}

	.lg\:pt-384 {
		padding-top: 38.4rem;
	}

	.lg\:pr-384 {
		padding-right: 38.4rem;
	}

	.lg\:pb-384 {
		padding-bottom: 38.4rem;
	}

	.lg\:pl-384 {
		padding-left: 38.4rem;
	}

	.lg\:pt-400 {
		padding-top: 40rem;
	}

	.lg\:pr-400 {
		padding-right: 40rem;
	}

	.lg\:pb-400 {
		padding-bottom: 40rem;
	}

	.lg\:pl-400 {
		padding-left: 40rem;
	}

	.lg\:pt-512 {
		padding-top: 51.2rem;
	}

	.lg\:pr-512 {
		padding-right: 51.2rem;
	}

	.lg\:pb-512 {
		padding-bottom: 51.2rem;
	}

	.lg\:pl-512 {
		padding-left: 51.2rem;
	}

	.lg\:pt-640 {
		padding-top: 64rem;
	}

	.lg\:pr-640 {
		padding-right: 64rem;
	}

	.lg\:pb-640 {
		padding-bottom: 64rem;
	}

	.lg\:pl-640 {
		padding-left: 64rem;
	}

	.lg\:pt-px {
		padding-top: 1px;
	}

	.lg\:pr-px {
		padding-right: 1px;
	}

	.lg\:pb-px {
		padding-bottom: 1px;
	}

	.lg\:pl-px {
		padding-left: 1px;
	}

	.lg\:pt-xs {
		padding-top: 32rem;
	}

	.lg\:pr-xs {
		padding-right: 32rem;
	}

	.lg\:pb-xs {
		padding-bottom: 32rem;
	}

	.lg\:pl-xs {
		padding-left: 32rem;
	}

	.lg\:pt-sm {
		padding-top: 48rem;
	}

	.lg\:pr-sm {
		padding-right: 48rem;
	}

	.lg\:pb-sm {
		padding-bottom: 48rem;
	}

	.lg\:pl-sm {
		padding-left: 48rem;
	}

	.lg\:pt-md {
		padding-top: 64rem;
	}

	.lg\:pr-md {
		padding-right: 64rem;
	}

	.lg\:pb-md {
		padding-bottom: 64rem;
	}

	.lg\:pl-md {
		padding-left: 64rem;
	}

	.lg\:pt-lg {
		padding-top: 80rem;
	}

	.lg\:pr-lg {
		padding-right: 80rem;
	}

	.lg\:pb-lg {
		padding-bottom: 80rem;
	}

	.lg\:pl-lg {
		padding-left: 80rem;
	}

	.lg\:pt-xl {
		padding-top: 96rem;
	}

	.lg\:pr-xl {
		padding-right: 96rem;
	}

	.lg\:pb-xl {
		padding-bottom: 96rem;
	}

	.lg\:pl-xl {
		padding-left: 96rem;
	}

	.lg\:pt-2xl {
		padding-top: 112rem;
	}

	.lg\:pr-2xl {
		padding-right: 112rem;
	}

	.lg\:pb-2xl {
		padding-bottom: 112rem;
	}

	.lg\:pl-2xl {
		padding-left: 112rem;
	}

	.lg\:pt-3xl {
		padding-top: 128rem;
	}

	.lg\:pr-3xl {
		padding-right: 128rem;
	}

	.lg\:pb-3xl {
		padding-bottom: 128rem;
	}

	.lg\:pl-3xl {
		padding-left: 128rem;
	}

	.lg\:pt-4xl {
		padding-top: 144rem;
	}

	.lg\:pr-4xl {
		padding-right: 144rem;
	}

	.lg\:pb-4xl {
		padding-bottom: 144rem;
	}

	.lg\:pl-4xl {
		padding-left: 144rem;
	}

	.lg\:pt-5xl {
		padding-top: 160rem;
	}

	.lg\:pr-5xl {
		padding-right: 160rem;
	}

	.lg\:pb-5xl {
		padding-bottom: 160rem;
	}

	.lg\:pl-5xl {
		padding-left: 160rem;
	}

	.lg\:pt-auto {
		padding-top: auto;
	}

	.lg\:pr-auto {
		padding-right: auto;
	}

	.lg\:pb-auto {
		padding-bottom: auto;
	}

	.lg\:pl-auto {
		padding-left: auto;
	}

	.lg\:pointer-events-none {
		pointer-events: none;
	}

	.lg\:pointer-events-auto {
		pointer-events: auto;
	}

	.lg\:static {
		position: static;
	}

	.lg\:fixed {
		position: fixed;
	}

	.lg\:absolute {
		position: absolute;
	}

	.lg\:relative {
		position: relative;
	}

	.lg\:sticky {
		position: -webkit-sticky;
		position: sticky;
	}

	.lg\:inset-0 {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.lg\:inset-auto {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}

	.lg\:inset-y-0 {
		top: 0;
		bottom: 0;
	}

	.lg\:inset-x-0 {
		right: 0;
		left: 0;
	}

	.lg\:inset-y-auto {
		top: auto;
		bottom: auto;
	}

	.lg\:inset-x-auto {
		right: auto;
		left: auto;
	}

	.lg\:top-0 {
		top: 0;
	}

	.lg\:right-0 {
		right: 0;
	}

	.lg\:bottom-0 {
		bottom: 0;
	}

	.lg\:left-0 {
		left: 0;
	}

	.lg\:top-auto {
		top: auto;
	}

	.lg\:right-auto {
		right: auto;
	}

	.lg\:bottom-auto {
		bottom: auto;
	}

	.lg\:left-auto {
		left: auto;
	}

	.lg\:resize-none {
		resize: none;
	}

	.lg\:resize-y {
		resize: vertical;
	}

	.lg\:resize-x {
		resize: horizontal;
	}

	.lg\:resize {
		resize: both;
	}

	.lg\:shadow-0 {
		box-shadow: none;
	}

	.lg\:shadow-1 {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-2 {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-3 {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-4 {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-5 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-6 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-7 {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-8 {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-9 {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-10 {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-11 {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-12 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-13 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-14 {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-15 {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-16 {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-17 {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-18 {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-19 {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-20 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-21 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-22 {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-23 {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow-24 {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.lg\:shadow-md {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:shadow-lg {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:shadow-xl {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.lg\:shadow-2xl {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.lg\:shadow-inner {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.lg\:shadow-outline {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.lg\:shadow-none {
		box-shadow: none;
	}

	.lg\:hover\:shadow-0:hover {
		box-shadow: none;
	}

	.lg\:hover\:shadow-1:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-2:hover {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-3:hover {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-4:hover {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-5:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-6:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-7:hover {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-8:hover {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-9:hover {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-10:hover {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-11:hover {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-12:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-13:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-14:hover {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-15:hover {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-16:hover {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-17:hover {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-18:hover {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-19:hover {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-20:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-21:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-22:hover {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-23:hover {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow-24:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:hover\:shadow:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.lg\:hover\:shadow-md:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:hover\:shadow-lg:hover {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:hover\:shadow-xl:hover {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.lg\:hover\:shadow-2xl:hover {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.lg\:hover\:shadow-inner:hover {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.lg\:hover\:shadow-outline:hover {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.lg\:hover\:shadow-none:hover {
		box-shadow: none;
	}

	.lg\:focus\:shadow-0:focus {
		box-shadow: none;
	}

	.lg\:focus\:shadow-1:focus {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-2:focus {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-3:focus {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-4:focus {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-5:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-6:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-7:focus {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-8:focus {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-9:focus {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-10:focus {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-11:focus {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-12:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-13:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-14:focus {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-15:focus {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-16:focus {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-17:focus {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-18:focus {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-19:focus {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-20:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-21:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-22:focus {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-23:focus {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow-24:focus {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.lg\:focus\:shadow:focus {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.lg\:focus\:shadow-md:focus {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:focus\:shadow-lg:focus {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.lg\:focus\:shadow-xl:focus {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.lg\:focus\:shadow-2xl:focus {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.lg\:focus\:shadow-inner:focus {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.lg\:focus\:shadow-outline:focus {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.lg\:focus\:shadow-none:focus {
		box-shadow: none;
	}

	.lg\:fill-current {
		fill: currentColor;
	}

	.lg\:stroke-current {
		stroke: currentColor;
	}

	.lg\:table-auto {
		table-layout: auto;
	}

	.lg\:table-fixed {
		table-layout: fixed;
	}

	.lg\:text-left {
		text-align: left;
	}

	.lg\:text-center {
		text-align: center;
	}

	.lg\:text-right {
		text-align: right;
	}

	.lg\:text-justify {
		text-align: justify;
	}

	.lg\:text-transparent {
		color: transparent;
	}

	.lg\:text-black {
		color: #22292f;
	}

	.lg\:text-white {
		color: #ffffff;
	}

	.lg\:text-gray-100 {
		color: #f7fafc;
	}

	.lg\:text-gray-200 {
		color: #edf2f7;
	}

	.lg\:text-gray-300 {
		color: #e2e8f0;
	}

	.lg\:text-gray-400 {
		color: #cbd5e0;
	}

	.lg\:text-gray-500 {
		color: #a0aec0;
	}

	.lg\:text-gray-600 {
		color: #718096;
	}

	.lg\:text-gray-700 {
		color: #4a5568;
	}

	.lg\:text-gray-800 {
		color: #2d3748;
	}

	.lg\:text-gray-900 {
		color: #1a202c;
	}

	.lg\:text-red {
		color: #e3342f;
	}

	.lg\:text-orange {
		color: #f6993f;
	}

	.lg\:text-yellow {
		color: #ffed4a;
	}

	.lg\:text-green {
		color: #38c172;
	}

	.lg\:text-teal {
		color: #4dc0b5;
	}

	.lg\:text-blue {
		color: #3490dc;
	}

	.lg\:text-indigo {
		color: #6574cd;
	}

	.lg\:text-purple {
		color: #9561e2;
	}

	.lg\:text-pink {
		color: #f66d9b;
	}

	.lg\:text-grey-darkest {
		color: #3d4852;
	}

	.lg\:text-grey-darker {
		color: #606f7b;
	}

	.lg\:text-grey-dark {
		color: #8795a1;
	}

	.lg\:text-grey {
		color: #b8c2cc;
	}

	.lg\:text-grey-light {
		color: #dae1e7;
	}

	.lg\:text-grey-lighter {
		color: #f1f5f8;
	}

	.lg\:text-grey-lightest {
		color: #f8fafc;
	}

	.lg\:text-red-darkest {
		color: #3b0d0c;
	}

	.lg\:text-red-darker {
		color: #621b18;
	}

	.lg\:text-red-dark {
		color: #cc1f1a;
	}

	.lg\:text-red-light {
		color: #ef5753;
	}

	.lg\:text-red-lighter {
		color: #f9acaa;
	}

	.lg\:text-red-lightest {
		color: #fcebea;
	}

	.lg\:text-orange-darkest {
		color: #462a16;
	}

	.lg\:text-orange-darker {
		color: #613b1f;
	}

	.lg\:text-orange-dark {
		color: #de751f;
	}

	.lg\:text-orange-light {
		color: #faad63;
	}

	.lg\:text-orange-lighter {
		color: #fcd9b6;
	}

	.lg\:text-orange-lightest {
		color: #fff5eb;
	}

	.lg\:text-yellow-darkest {
		color: #453411;
	}

	.lg\:text-yellow-darker {
		color: #684f1d;
	}

	.lg\:text-yellow-dark {
		color: #f2d024;
	}

	.lg\:text-yellow-light {
		color: #fff382;
	}

	.lg\:text-yellow-lighter {
		color: #fff9c2;
	}

	.lg\:text-yellow-lightest {
		color: #fcfbeb;
	}

	.lg\:text-green-darkest {
		color: #0f2f21;
	}

	.lg\:text-green-darker {
		color: #1a4731;
	}

	.lg\:text-green-dark {
		color: #1f9d55;
	}

	.lg\:text-green-light {
		color: #51d88a;
	}

	.lg\:text-green-lighter {
		color: #a2f5bf;
	}

	.lg\:text-green-lightest {
		color: #e3fcec;
	}

	.lg\:text-teal-darkest {
		color: #0d3331;
	}

	.lg\:text-teal-darker {
		color: #20504f;
	}

	.lg\:text-teal-dark {
		color: #38a89d;
	}

	.lg\:text-teal-light {
		color: #64d5ca;
	}

	.lg\:text-teal-lighter {
		color: #a0f0ed;
	}

	.lg\:text-teal-lightest {
		color: #e8fffe;
	}

	.lg\:text-blue-darkest {
		color: #12283a;
	}

	.lg\:text-blue-darker {
		color: #1c3d5a;
	}

	.lg\:text-blue-dark {
		color: #2779bd;
	}

	.lg\:text-blue-light {
		color: #6cb2eb;
	}

	.lg\:text-blue-lighter {
		color: #bcdefa;
	}

	.lg\:text-blue-lightest {
		color: #eff8ff;
	}

	.lg\:text-indigo-darkest {
		color: #191e38;
	}

	.lg\:text-indigo-darker {
		color: #2f365f;
	}

	.lg\:text-indigo-dark {
		color: #5661b3;
	}

	.lg\:text-indigo-light {
		color: #7886d7;
	}

	.lg\:text-indigo-lighter {
		color: #b2b7ff;
	}

	.lg\:text-indigo-lightest {
		color: #e6e8ff;
	}

	.lg\:text-purple-darkest {
		color: #21183c;
	}

	.lg\:text-purple-darker {
		color: #382b5f;
	}

	.lg\:text-purple-dark {
		color: #794acf;
	}

	.lg\:text-purple-light {
		color: #a779e9;
	}

	.lg\:text-purple-lighter {
		color: #d6bbfc;
	}

	.lg\:text-purple-lightest {
		color: #f3ebff;
	}

	.lg\:text-pink-darkest {
		color: #451225;
	}

	.lg\:text-pink-darker {
		color: #6f213f;
	}

	.lg\:text-pink-dark {
		color: #eb5286;
	}

	.lg\:text-pink-light {
		color: #fa7ea8;
	}

	.lg\:text-pink-lighter {
		color: #ffbbca;
	}

	.lg\:text-pink-lightest {
		color: #ffebef;
	}

	.lg\:hover\:text-transparent:hover {
		color: transparent;
	}

	.lg\:hover\:text-black:hover {
		color: #22292f;
	}

	.lg\:hover\:text-white:hover {
		color: #ffffff;
	}

	.lg\:hover\:text-gray-100:hover {
		color: #f7fafc;
	}

	.lg\:hover\:text-gray-200:hover {
		color: #edf2f7;
	}

	.lg\:hover\:text-gray-300:hover {
		color: #e2e8f0;
	}

	.lg\:hover\:text-gray-400:hover {
		color: #cbd5e0;
	}

	.lg\:hover\:text-gray-500:hover {
		color: #a0aec0;
	}

	.lg\:hover\:text-gray-600:hover {
		color: #718096;
	}

	.lg\:hover\:text-gray-700:hover {
		color: #4a5568;
	}

	.lg\:hover\:text-gray-800:hover {
		color: #2d3748;
	}

	.lg\:hover\:text-gray-900:hover {
		color: #1a202c;
	}

	.lg\:hover\:text-red:hover {
		color: #e3342f;
	}

	.lg\:hover\:text-orange:hover {
		color: #f6993f;
	}

	.lg\:hover\:text-yellow:hover {
		color: #ffed4a;
	}

	.lg\:hover\:text-green:hover {
		color: #38c172;
	}

	.lg\:hover\:text-teal:hover {
		color: #4dc0b5;
	}

	.lg\:hover\:text-blue:hover {
		color: #3490dc;
	}

	.lg\:hover\:text-indigo:hover {
		color: #6574cd;
	}

	.lg\:hover\:text-purple:hover {
		color: #9561e2;
	}

	.lg\:hover\:text-pink:hover {
		color: #f66d9b;
	}

	.lg\:hover\:text-grey-darkest:hover {
		color: #3d4852;
	}

	.lg\:hover\:text-grey-darker:hover {
		color: #606f7b;
	}

	.lg\:hover\:text-grey-dark:hover {
		color: #8795a1;
	}

	.lg\:hover\:text-grey:hover {
		color: #b8c2cc;
	}

	.lg\:hover\:text-grey-light:hover {
		color: #dae1e7;
	}

	.lg\:hover\:text-grey-lighter:hover {
		color: #f1f5f8;
	}

	.lg\:hover\:text-grey-lightest:hover {
		color: #f8fafc;
	}

	.lg\:hover\:text-red-darkest:hover {
		color: #3b0d0c;
	}

	.lg\:hover\:text-red-darker:hover {
		color: #621b18;
	}

	.lg\:hover\:text-red-dark:hover {
		color: #cc1f1a;
	}

	.lg\:hover\:text-red-light:hover {
		color: #ef5753;
	}

	.lg\:hover\:text-red-lighter:hover {
		color: #f9acaa;
	}

	.lg\:hover\:text-red-lightest:hover {
		color: #fcebea;
	}

	.lg\:hover\:text-orange-darkest:hover {
		color: #462a16;
	}

	.lg\:hover\:text-orange-darker:hover {
		color: #613b1f;
	}

	.lg\:hover\:text-orange-dark:hover {
		color: #de751f;
	}

	.lg\:hover\:text-orange-light:hover {
		color: #faad63;
	}

	.lg\:hover\:text-orange-lighter:hover {
		color: #fcd9b6;
	}

	.lg\:hover\:text-orange-lightest:hover {
		color: #fff5eb;
	}

	.lg\:hover\:text-yellow-darkest:hover {
		color: #453411;
	}

	.lg\:hover\:text-yellow-darker:hover {
		color: #684f1d;
	}

	.lg\:hover\:text-yellow-dark:hover {
		color: #f2d024;
	}

	.lg\:hover\:text-yellow-light:hover {
		color: #fff382;
	}

	.lg\:hover\:text-yellow-lighter:hover {
		color: #fff9c2;
	}

	.lg\:hover\:text-yellow-lightest:hover {
		color: #fcfbeb;
	}

	.lg\:hover\:text-green-darkest:hover {
		color: #0f2f21;
	}

	.lg\:hover\:text-green-darker:hover {
		color: #1a4731;
	}

	.lg\:hover\:text-green-dark:hover {
		color: #1f9d55;
	}

	.lg\:hover\:text-green-light:hover {
		color: #51d88a;
	}

	.lg\:hover\:text-green-lighter:hover {
		color: #a2f5bf;
	}

	.lg\:hover\:text-green-lightest:hover {
		color: #e3fcec;
	}

	.lg\:hover\:text-teal-darkest:hover {
		color: #0d3331;
	}

	.lg\:hover\:text-teal-darker:hover {
		color: #20504f;
	}

	.lg\:hover\:text-teal-dark:hover {
		color: #38a89d;
	}

	.lg\:hover\:text-teal-light:hover {
		color: #64d5ca;
	}

	.lg\:hover\:text-teal-lighter:hover {
		color: #a0f0ed;
	}

	.lg\:hover\:text-teal-lightest:hover {
		color: #e8fffe;
	}

	.lg\:hover\:text-blue-darkest:hover {
		color: #12283a;
	}

	.lg\:hover\:text-blue-darker:hover {
		color: #1c3d5a;
	}

	.lg\:hover\:text-blue-dark:hover {
		color: #2779bd;
	}

	.lg\:hover\:text-blue-light:hover {
		color: #6cb2eb;
	}

	.lg\:hover\:text-blue-lighter:hover {
		color: #bcdefa;
	}

	.lg\:hover\:text-blue-lightest:hover {
		color: #eff8ff;
	}

	.lg\:hover\:text-indigo-darkest:hover {
		color: #191e38;
	}

	.lg\:hover\:text-indigo-darker:hover {
		color: #2f365f;
	}

	.lg\:hover\:text-indigo-dark:hover {
		color: #5661b3;
	}

	.lg\:hover\:text-indigo-light:hover {
		color: #7886d7;
	}

	.lg\:hover\:text-indigo-lighter:hover {
		color: #b2b7ff;
	}

	.lg\:hover\:text-indigo-lightest:hover {
		color: #e6e8ff;
	}

	.lg\:hover\:text-purple-darkest:hover {
		color: #21183c;
	}

	.lg\:hover\:text-purple-darker:hover {
		color: #382b5f;
	}

	.lg\:hover\:text-purple-dark:hover {
		color: #794acf;
	}

	.lg\:hover\:text-purple-light:hover {
		color: #a779e9;
	}

	.lg\:hover\:text-purple-lighter:hover {
		color: #d6bbfc;
	}

	.lg\:hover\:text-purple-lightest:hover {
		color: #f3ebff;
	}

	.lg\:hover\:text-pink-darkest:hover {
		color: #451225;
	}

	.lg\:hover\:text-pink-darker:hover {
		color: #6f213f;
	}

	.lg\:hover\:text-pink-dark:hover {
		color: #eb5286;
	}

	.lg\:hover\:text-pink-light:hover {
		color: #fa7ea8;
	}

	.lg\:hover\:text-pink-lighter:hover {
		color: #ffbbca;
	}

	.lg\:hover\:text-pink-lightest:hover {
		color: #ffebef;
	}

	.lg\:focus\:text-transparent:focus {
		color: transparent;
	}

	.lg\:focus\:text-black:focus {
		color: #22292f;
	}

	.lg\:focus\:text-white:focus {
		color: #ffffff;
	}

	.lg\:focus\:text-gray-100:focus {
		color: #f7fafc;
	}

	.lg\:focus\:text-gray-200:focus {
		color: #edf2f7;
	}

	.lg\:focus\:text-gray-300:focus {
		color: #e2e8f0;
	}

	.lg\:focus\:text-gray-400:focus {
		color: #cbd5e0;
	}

	.lg\:focus\:text-gray-500:focus {
		color: #a0aec0;
	}

	.lg\:focus\:text-gray-600:focus {
		color: #718096;
	}

	.lg\:focus\:text-gray-700:focus {
		color: #4a5568;
	}

	.lg\:focus\:text-gray-800:focus {
		color: #2d3748;
	}

	.lg\:focus\:text-gray-900:focus {
		color: #1a202c;
	}

	.lg\:focus\:text-red:focus {
		color: #e3342f;
	}

	.lg\:focus\:text-orange:focus {
		color: #f6993f;
	}

	.lg\:focus\:text-yellow:focus {
		color: #ffed4a;
	}

	.lg\:focus\:text-green:focus {
		color: #38c172;
	}

	.lg\:focus\:text-teal:focus {
		color: #4dc0b5;
	}

	.lg\:focus\:text-blue:focus {
		color: #3490dc;
	}

	.lg\:focus\:text-indigo:focus {
		color: #6574cd;
	}

	.lg\:focus\:text-purple:focus {
		color: #9561e2;
	}

	.lg\:focus\:text-pink:focus {
		color: #f66d9b;
	}

	.lg\:focus\:text-grey-darkest:focus {
		color: #3d4852;
	}

	.lg\:focus\:text-grey-darker:focus {
		color: #606f7b;
	}

	.lg\:focus\:text-grey-dark:focus {
		color: #8795a1;
	}

	.lg\:focus\:text-grey:focus {
		color: #b8c2cc;
	}

	.lg\:focus\:text-grey-light:focus {
		color: #dae1e7;
	}

	.lg\:focus\:text-grey-lighter:focus {
		color: #f1f5f8;
	}

	.lg\:focus\:text-grey-lightest:focus {
		color: #f8fafc;
	}

	.lg\:focus\:text-red-darkest:focus {
		color: #3b0d0c;
	}

	.lg\:focus\:text-red-darker:focus {
		color: #621b18;
	}

	.lg\:focus\:text-red-dark:focus {
		color: #cc1f1a;
	}

	.lg\:focus\:text-red-light:focus {
		color: #ef5753;
	}

	.lg\:focus\:text-red-lighter:focus {
		color: #f9acaa;
	}

	.lg\:focus\:text-red-lightest:focus {
		color: #fcebea;
	}

	.lg\:focus\:text-orange-darkest:focus {
		color: #462a16;
	}

	.lg\:focus\:text-orange-darker:focus {
		color: #613b1f;
	}

	.lg\:focus\:text-orange-dark:focus {
		color: #de751f;
	}

	.lg\:focus\:text-orange-light:focus {
		color: #faad63;
	}

	.lg\:focus\:text-orange-lighter:focus {
		color: #fcd9b6;
	}

	.lg\:focus\:text-orange-lightest:focus {
		color: #fff5eb;
	}

	.lg\:focus\:text-yellow-darkest:focus {
		color: #453411;
	}

	.lg\:focus\:text-yellow-darker:focus {
		color: #684f1d;
	}

	.lg\:focus\:text-yellow-dark:focus {
		color: #f2d024;
	}

	.lg\:focus\:text-yellow-light:focus {
		color: #fff382;
	}

	.lg\:focus\:text-yellow-lighter:focus {
		color: #fff9c2;
	}

	.lg\:focus\:text-yellow-lightest:focus {
		color: #fcfbeb;
	}

	.lg\:focus\:text-green-darkest:focus {
		color: #0f2f21;
	}

	.lg\:focus\:text-green-darker:focus {
		color: #1a4731;
	}

	.lg\:focus\:text-green-dark:focus {
		color: #1f9d55;
	}

	.lg\:focus\:text-green-light:focus {
		color: #51d88a;
	}

	.lg\:focus\:text-green-lighter:focus {
		color: #a2f5bf;
	}

	.lg\:focus\:text-green-lightest:focus {
		color: #e3fcec;
	}

	.lg\:focus\:text-teal-darkest:focus {
		color: #0d3331;
	}

	.lg\:focus\:text-teal-darker:focus {
		color: #20504f;
	}

	.lg\:focus\:text-teal-dark:focus {
		color: #38a89d;
	}

	.lg\:focus\:text-teal-light:focus {
		color: #64d5ca;
	}

	.lg\:focus\:text-teal-lighter:focus {
		color: #a0f0ed;
	}

	.lg\:focus\:text-teal-lightest:focus {
		color: #e8fffe;
	}

	.lg\:focus\:text-blue-darkest:focus {
		color: #12283a;
	}

	.lg\:focus\:text-blue-darker:focus {
		color: #1c3d5a;
	}

	.lg\:focus\:text-blue-dark:focus {
		color: #2779bd;
	}

	.lg\:focus\:text-blue-light:focus {
		color: #6cb2eb;
	}

	.lg\:focus\:text-blue-lighter:focus {
		color: #bcdefa;
	}

	.lg\:focus\:text-blue-lightest:focus {
		color: #eff8ff;
	}

	.lg\:focus\:text-indigo-darkest:focus {
		color: #191e38;
	}

	.lg\:focus\:text-indigo-darker:focus {
		color: #2f365f;
	}

	.lg\:focus\:text-indigo-dark:focus {
		color: #5661b3;
	}

	.lg\:focus\:text-indigo-light:focus {
		color: #7886d7;
	}

	.lg\:focus\:text-indigo-lighter:focus {
		color: #b2b7ff;
	}

	.lg\:focus\:text-indigo-lightest:focus {
		color: #e6e8ff;
	}

	.lg\:focus\:text-purple-darkest:focus {
		color: #21183c;
	}

	.lg\:focus\:text-purple-darker:focus {
		color: #382b5f;
	}

	.lg\:focus\:text-purple-dark:focus {
		color: #794acf;
	}

	.lg\:focus\:text-purple-light:focus {
		color: #a779e9;
	}

	.lg\:focus\:text-purple-lighter:focus {
		color: #d6bbfc;
	}

	.lg\:focus\:text-purple-lightest:focus {
		color: #f3ebff;
	}

	.lg\:focus\:text-pink-darkest:focus {
		color: #451225;
	}

	.lg\:focus\:text-pink-darker:focus {
		color: #6f213f;
	}

	.lg\:focus\:text-pink-dark:focus {
		color: #eb5286;
	}

	.lg\:focus\:text-pink-light:focus {
		color: #fa7ea8;
	}

	.lg\:focus\:text-pink-lighter:focus {
		color: #ffbbca;
	}

	.lg\:focus\:text-pink-lightest:focus {
		color: #ffebef;
	}

	.lg\:text-10 {
		font-size: 1rem;
	}

	.lg\:text-11 {
		font-size: 1.1rem;
	}

	.lg\:text-12 {
		font-size: 1.2rem;
	}

	.lg\:text-13 {
		font-size: 1.3rem;
	}

	.lg\:text-14 {
		font-size: 1.4rem;
	}

	.lg\:text-15 {
		font-size: 1.5rem;
	}

	.lg\:text-16 {
		font-size: 1.6rem;
	}

	.lg\:text-17 {
		font-size: 1.7rem;
	}

	.lg\:text-18 {
		font-size: 1.8rem;
	}

	.lg\:text-19 {
		font-size: 1.9rem;
	}

	.lg\:text-20 {
		font-size: 2rem;
	}

	.lg\:text-24 {
		font-size: 2.4rem;
	}

	.lg\:text-28 {
		font-size: 2.8rem;
	}

	.lg\:text-32 {
		font-size: 3.2rem;
	}

	.lg\:text-36 {
		font-size: 3.6rem;
	}

	.lg\:text-40 {
		font-size: 4rem;
	}

	.lg\:text-44 {
		font-size: 4.4rem;
	}

	.lg\:text-48 {
		font-size: 4.8rem;
	}

	.lg\:text-52 {
		font-size: 5.2rem;
	}

	.lg\:text-56 {
		font-size: 5.6rem;
	}

	.lg\:text-60 {
		font-size: 6rem;
	}

	.lg\:text-64 {
		font-size: 6.4rem;
	}

	.lg\:text-68 {
		font-size: 6.8rem;
	}

	.lg\:text-72 {
		font-size: 7.2rem;
	}

	.lg\:text-96 {
		font-size: 9.6rem;
	}

	.lg\:text-128 {
		font-size: 12.8rem;
	}

	.lg\:text-xs {
		font-size: 1.2rem;
	}

	.lg\:text-sm {
		font-size: 2.4rem;
	}

	.lg\:text-base {
		font-size: 1.6rem;
	}

	.lg\:text-lg {
		font-size: 1.8rem;
	}

	.lg\:text-xl {
		font-size: 2rem;
	}

	.lg\:text-2xl {
		font-size: 2.4rem;
	}

	.lg\:text-3xl {
		font-size: 3rem;
	}

	.lg\:text-4xl {
		font-size: 3.6rem;
	}

	.lg\:text-5xl {
		font-size: 4.8rem;
	}

	.lg\:text-6xl {
		font-size: 4rem;
	}

	.lg\:italic {
		font-style: italic;
	}

	.lg\:not-italic {
		font-style: normal;
	}

	.lg\:hover\:italic:hover {
		font-style: italic;
	}

	.lg\:hover\:not-italic:hover {
		font-style: normal;
	}

	.lg\:focus\:italic:focus {
		font-style: italic;
	}

	.lg\:focus\:not-italic:focus {
		font-style: normal;
	}

	.lg\:uppercase {
		text-transform: uppercase;
	}

	.lg\:lowercase {
		text-transform: lowercase;
	}

	.lg\:capitalize {
		text-transform: capitalize;
	}

	.lg\:normal-case {
		text-transform: none;
	}

	.lg\:underline {
		text-decoration: underline;
	}

	.lg\:line-through {
		text-decoration: line-through;
	}

	.lg\:no-underline {
		text-decoration: none;
	}

	.lg\:hover\:underline:hover {
		text-decoration: underline;
	}

	.lg\:hover\:line-through:hover {
		text-decoration: line-through;
	}

	.lg\:hover\:no-underline:hover {
		text-decoration: none;
	}

	.lg\:focus\:underline:focus {
		text-decoration: underline;
	}

	.lg\:focus\:line-through:focus {
		text-decoration: line-through;
	}

	.lg\:focus\:no-underline:focus {
		text-decoration: none;
	}

	.lg\:antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.lg\:subpixel-antialiased {
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;
	}

	.lg\:tracking-tighter {
		letter-spacing: -0.05em;
	}

	.lg\:tracking-tight {
		letter-spacing: -0.025em;
	}

	.lg\:tracking-normal {
		letter-spacing: 0;
	}

	.lg\:tracking-wide {
		letter-spacing: 0.025em;
	}

	.lg\:tracking-wider {
		letter-spacing: 0.05em;
	}

	.lg\:tracking-widest {
		letter-spacing: 0.1em;
	}

	.lg\:select-none {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.lg\:select-text {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.lg\:select-all {
		-webkit-user-select: all;
		-moz-user-select: all;
		-ms-user-select: all;
		user-select: all;
	}

	.lg\:select-auto {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.lg\:align-baseline {
		vertical-align: baseline;
	}

	.lg\:align-top {
		vertical-align: top;
	}

	.lg\:align-middle {
		vertical-align: middle;
	}

	.lg\:align-bottom {
		vertical-align: bottom;
	}

	.lg\:align-text-top {
		vertical-align: text-top;
	}

	.lg\:align-text-bottom {
		vertical-align: text-bottom;
	}

	.lg\:visible {
		visibility: visible;
	}

	.lg\:invisible {
		visibility: hidden;
	}

	.lg\:whitespace-normal {
		white-space: normal;
	}

	.lg\:whitespace-no-wrap {
		white-space: nowrap;
	}

	.lg\:whitespace-pre {
		white-space: pre;
	}

	.lg\:whitespace-pre-line {
		white-space: pre-line;
	}

	.lg\:whitespace-pre-wrap {
		white-space: pre-wrap;
	}

	.lg\:break-normal {
		overflow-wrap: normal;
		word-break: normal;
	}

	.lg\:break-words {
		overflow-wrap: break-word;
	}

	.lg\:break-all {
		word-break: break-all;
	}

	.lg\:truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.lg\:w-0 {
		width: 0;
	}

	.lg\:w-1 {
		width: 0.25rem;
	}

	.lg\:w-2 {
		width: 0.5rem;
	}

	.lg\:w-3 {
		width: 0.75rem;
	}

	.lg\:w-4 {
		width: 0.4rem;
	}

	.lg\:w-5 {
		width: 1.25rem;
	}

	.lg\:w-6 {
		width: 0.6rem;
	}

	.lg\:w-8 {
		width: 0.8rem;
	}

	.lg\:w-10 {
		width: 2.5rem;
	}

	.lg\:w-12 {
		width: 1.2rem;
	}

	.lg\:w-16 {
		width: 1.6rem;
	}

	.lg\:w-20 {
		width: 2rem;
	}

	.lg\:w-24 {
		width: 2.4rem;
	}

	.lg\:w-28 {
		width: 2.8rem;
	}

	.lg\:w-32 {
		width: 3.2rem;
	}

	.lg\:w-36 {
		width: 3.6rem;
	}

	.lg\:w-40 {
		width: 4rem;
	}

	.lg\:w-44 {
		width: 4.4rem;
	}

	.lg\:w-48 {
		width: 4.8rem;
	}

	.lg\:w-52 {
		width: 5.2rem;
	}

	.lg\:w-56 {
		width: 5.6rem;
	}

	.lg\:w-60 {
		width: 6rem;
	}

	.lg\:w-64 {
		width: 6.4rem;
	}

	.lg\:w-68 {
		width: 6.8rem;
	}

	.lg\:w-72 {
		width: 7.2rem;
	}

	.lg\:w-76 {
		width: 7.6rem;
	}

	.lg\:w-80 {
		width: 8rem;
	}

	.lg\:w-84 {
		width: 8.4rem;
	}

	.lg\:w-88 {
		width: 8.8rem;
	}

	.lg\:w-92 {
		width: 9.2rem;
	}

	.lg\:w-96 {
		width: 9.6rem;
	}

	.lg\:w-128 {
		width: 12.8rem;
	}

	.lg\:w-136 {
		width: 13.6rem;
	}

	.lg\:w-160 {
		width: 16rem;
	}

	.lg\:w-192 {
		width: 19.2rem;
	}

	.lg\:w-200 {
		width: 20rem;
	}

	.lg\:w-208 {
		width: 20.8rem;
	}

	.lg\:w-216 {
		width: 21.6rem;
	}

	.lg\:w-224 {
		width: 22.4rem;
	}

	.lg\:w-256 {
		width: 25.6rem;
	}

	.lg\:w-288 {
		width: 28.8rem;
	}

	.lg\:w-320 {
		width: 32rem;
	}

	.lg\:w-360 {
		width: 36rem;
	}

	.lg\:w-384 {
		width: 38.4rem;
	}

	.lg\:w-400 {
		width: 40rem;
	}

	.lg\:w-512 {
		width: 51.2rem;
	}

	.lg\:w-640 {
		width: 64rem;
	}

	.lg\:w-auto {
		width: auto;
	}

	.lg\:w-px {
		width: 1px;
	}

	.lg\:w-xs {
		width: 32rem;
	}

	.lg\:w-sm {
		width: 48rem;
	}

	.lg\:w-md {
		width: 64rem;
	}

	.lg\:w-lg {
		width: 80rem;
	}

	.lg\:w-xl {
		width: 96rem;
	}

	.lg\:w-2xl {
		width: 112rem;
	}

	.lg\:w-3xl {
		width: 128rem;
	}

	.lg\:w-4xl {
		width: 144rem;
	}

	.lg\:w-5xl {
		width: 160rem;
	}

	.lg\:w-1\/2 {
		width: 50%;
	}

	.lg\:w-1\/3 {
		width: 33.33333%;
	}

	.lg\:w-2\/3 {
		width: 66.66667%;
	}

	.lg\:w-1\/4 {
		width: 25%;
	}

	.lg\:w-2\/4 {
		width: 50%;
	}

	.lg\:w-3\/4 {
		width: 75%;
	}

	.lg\:w-1\/5 {
		width: 20%;
	}

	.lg\:w-2\/5 {
		width: 40%;
	}

	.lg\:w-3\/5 {
		width: 60%;
	}

	.lg\:w-4\/5 {
		width: 80%;
	}

	.lg\:w-1\/6 {
		width: 16.66667%;
	}

	.lg\:w-2\/6 {
		width: 33.33333%;
	}

	.lg\:w-3\/6 {
		width: 50%;
	}

	.lg\:w-4\/6 {
		width: 66.66667%;
	}

	.lg\:w-5\/6 {
		width: 83.33333%;
	}

	.lg\:w-1\/12 {
		width: 8.33333%;
	}

	.lg\:w-2\/12 {
		width: 16.66667%;
	}

	.lg\:w-3\/12 {
		width: 25%;
	}

	.lg\:w-4\/12 {
		width: 33.33333%;
	}

	.lg\:w-5\/12 {
		width: 41.66667%;
	}

	.lg\:w-6\/12 {
		width: 50%;
	}

	.lg\:w-7\/12 {
		width: 58.33333%;
	}

	.lg\:w-8\/12 {
		width: 66.66667%;
	}

	.lg\:w-9\/12 {
		width: 75%;
	}

	.lg\:w-10\/12 {
		width: 83.33333%;
	}

	.lg\:w-11\/12 {
		width: 91.66667%;
	}

	.lg\:w-full {
		width: 100%;
	}

	.lg\:w-screen {
		width: 100vw;
	}

	.lg\:z-0 {
		z-index: 0;
	}

	.lg\:z-10 {
		z-index: 10;
	}

	.lg\:z-20 {
		z-index: 20;
	}

	.lg\:z-30 {
		z-index: 30;
	}

	.lg\:z-40 {
		z-index: 40;
	}

	.lg\:z-50 {
		z-index: 50;
	}

	.lg\:z-99 {
		z-index: 99;
	}

	.lg\:z-999 {
		z-index: 999;
	}

	.lg\:z-9999 {
		z-index: 9999;
	}

	.lg\:z-auto {
		z-index: auto;
	}
}

@media (min-width: 1920px) {
	.xl\:appearance-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.xl\:bg-fixed {
		background-attachment: fixed;
	}

	.xl\:bg-local {
		background-attachment: local;
	}

	.xl\:bg-scroll {
		background-attachment: scroll;
	}

	.xl\:bg-transparent {
		background-color: transparent;
	}

	.xl\:bg-black {
		background-color: #22292f;
	}

	.xl\:bg-white {
		background-color: #ffffff;
	}

	.xl\:bg-gray-100 {
		background-color: #f7fafc;
	}

	.xl\:bg-gray-200 {
		background-color: #edf2f7;
	}

	.xl\:bg-gray-300 {
		background-color: #e2e8f0;
	}

	.xl\:bg-gray-400 {
		background-color: #cbd5e0;
	}

	.xl\:bg-gray-500 {
		background-color: #a0aec0;
	}

	.xl\:bg-gray-600 {
		background-color: #718096;
	}

	.xl\:bg-gray-700 {
		background-color: #4a5568;
	}

	.xl\:bg-gray-800 {
		background-color: #2d3748;
	}

	.xl\:bg-gray-900 {
		background-color: #1a202c;
	}

	.xl\:bg-red {
		background-color: #e3342f;
	}

	.xl\:bg-orange {
		background-color: #f6993f;
	}

	.xl\:bg-yellow {
		background-color: #ffed4a;
	}

	.xl\:bg-green {
		background-color: #38c172;
	}

	.xl\:bg-teal {
		background-color: #4dc0b5;
	}

	.xl\:bg-blue {
		background-color: #3490dc;
	}

	.xl\:bg-indigo {
		background-color: #6574cd;
	}

	.xl\:bg-purple {
		background-color: #9561e2;
	}

	.xl\:bg-pink {
		background-color: #f66d9b;
	}

	.xl\:bg-grey-darkest {
		background-color: #3d4852;
	}

	.xl\:bg-grey-darker {
		background-color: #606f7b;
	}

	.xl\:bg-grey-dark {
		background-color: #8795a1;
	}

	.xl\:bg-grey {
		background-color: #b8c2cc;
	}

	.xl\:bg-grey-light {
		background-color: #dae1e7;
	}

	.xl\:bg-grey-lighter {
		background-color: #f1f5f8;
	}

	.xl\:bg-grey-lightest {
		background-color: #f8fafc;
	}

	.xl\:bg-red-darkest {
		background-color: #3b0d0c;
	}

	.xl\:bg-red-darker {
		background-color: #621b18;
	}

	.xl\:bg-red-dark {
		background-color: #cc1f1a;
	}

	.xl\:bg-red-light {
		background-color: #ef5753;
	}

	.xl\:bg-red-lighter {
		background-color: #f9acaa;
	}

	.xl\:bg-red-lightest {
		background-color: #fcebea;
	}

	.xl\:bg-orange-darkest {
		background-color: #462a16;
	}

	.xl\:bg-orange-darker {
		background-color: #613b1f;
	}

	.xl\:bg-orange-dark {
		background-color: #de751f;
	}

	.xl\:bg-orange-light {
		background-color: #faad63;
	}

	.xl\:bg-orange-lighter {
		background-color: #fcd9b6;
	}

	.xl\:bg-orange-lightest {
		background-color: #fff5eb;
	}

	.xl\:bg-yellow-darkest {
		background-color: #453411;
	}

	.xl\:bg-yellow-darker {
		background-color: #684f1d;
	}

	.xl\:bg-yellow-dark {
		background-color: #f2d024;
	}

	.xl\:bg-yellow-light {
		background-color: #fff382;
	}

	.xl\:bg-yellow-lighter {
		background-color: #fff9c2;
	}

	.xl\:bg-yellow-lightest {
		background-color: #fcfbeb;
	}

	.xl\:bg-green-darkest {
		background-color: #0f2f21;
	}

	.xl\:bg-green-darker {
		background-color: #1a4731;
	}

	.xl\:bg-green-dark {
		background-color: #1f9d55;
	}

	.xl\:bg-green-light {
		background-color: #51d88a;
	}

	.xl\:bg-green-lighter {
		background-color: #a2f5bf;
	}

	.xl\:bg-green-lightest {
		background-color: #e3fcec;
	}

	.xl\:bg-teal-darkest {
		background-color: #0d3331;
	}

	.xl\:bg-teal-darker {
		background-color: #20504f;
	}

	.xl\:bg-teal-dark {
		background-color: #38a89d;
	}

	.xl\:bg-teal-light {
		background-color: #64d5ca;
	}

	.xl\:bg-teal-lighter {
		background-color: #a0f0ed;
	}

	.xl\:bg-teal-lightest {
		background-color: #e8fffe;
	}

	.xl\:bg-blue-darkest {
		background-color: #12283a;
	}

	.xl\:bg-blue-darker {
		background-color: #1c3d5a;
	}

	.xl\:bg-blue-dark {
		background-color: #2779bd;
	}

	.xl\:bg-blue-light {
		background-color: #6cb2eb;
	}

	.xl\:bg-blue-lighter {
		background-color: #bcdefa;
	}

	.xl\:bg-blue-lightest {
		background-color: #eff8ff;
	}

	.xl\:bg-indigo-darkest {
		background-color: #191e38;
	}

	.xl\:bg-indigo-darker {
		background-color: #2f365f;
	}

	.xl\:bg-indigo-dark {
		background-color: #5661b3;
	}

	.xl\:bg-indigo-light {
		background-color: #7886d7;
	}

	.xl\:bg-indigo-lighter {
		background-color: #b2b7ff;
	}

	.xl\:bg-indigo-lightest {
		background-color: #e6e8ff;
	}

	.xl\:bg-purple-darkest {
		background-color: #21183c;
	}

	.xl\:bg-purple-darker {
		background-color: #382b5f;
	}

	.xl\:bg-purple-dark {
		background-color: #794acf;
	}

	.xl\:bg-purple-light {
		background-color: #a779e9;
	}

	.xl\:bg-purple-lighter {
		background-color: #d6bbfc;
	}

	.xl\:bg-purple-lightest {
		background-color: #f3ebff;
	}

	.xl\:bg-pink-darkest {
		background-color: #451225;
	}

	.xl\:bg-pink-darker {
		background-color: #6f213f;
	}

	.xl\:bg-pink-dark {
		background-color: #eb5286;
	}

	.xl\:bg-pink-light {
		background-color: #fa7ea8;
	}

	.xl\:bg-pink-lighter {
		background-color: #ffbbca;
	}

	.xl\:bg-pink-lightest {
		background-color: #ffebef;
	}

	.xl\:hover\:bg-transparent:hover {
		background-color: transparent;
	}

	.xl\:hover\:bg-black:hover {
		background-color: #22292f;
	}

	.xl\:hover\:bg-white:hover {
		background-color: #ffffff;
	}

	.xl\:hover\:bg-gray-100:hover {
		background-color: #f7fafc;
	}

	.xl\:hover\:bg-gray-200:hover {
		background-color: #edf2f7;
	}

	.xl\:hover\:bg-gray-300:hover {
		background-color: #e2e8f0;
	}

	.xl\:hover\:bg-gray-400:hover {
		background-color: #cbd5e0;
	}

	.xl\:hover\:bg-gray-500:hover {
		background-color: #a0aec0;
	}

	.xl\:hover\:bg-gray-600:hover {
		background-color: #718096;
	}

	.xl\:hover\:bg-gray-700:hover {
		background-color: #4a5568;
	}

	.xl\:hover\:bg-gray-800:hover {
		background-color: #2d3748;
	}

	.xl\:hover\:bg-gray-900:hover {
		background-color: #1a202c;
	}

	.xl\:hover\:bg-red:hover {
		background-color: #e3342f;
	}

	.xl\:hover\:bg-orange:hover {
		background-color: #f6993f;
	}

	.xl\:hover\:bg-yellow:hover {
		background-color: #ffed4a;
	}

	.xl\:hover\:bg-green:hover {
		background-color: #38c172;
	}

	.xl\:hover\:bg-teal:hover {
		background-color: #4dc0b5;
	}

	.xl\:hover\:bg-blue:hover {
		background-color: #3490dc;
	}

	.xl\:hover\:bg-indigo:hover {
		background-color: #6574cd;
	}

	.xl\:hover\:bg-purple:hover {
		background-color: #9561e2;
	}

	.xl\:hover\:bg-pink:hover {
		background-color: #f66d9b;
	}

	.xl\:hover\:bg-grey-darkest:hover {
		background-color: #3d4852;
	}

	.xl\:hover\:bg-grey-darker:hover {
		background-color: #606f7b;
	}

	.xl\:hover\:bg-grey-dark:hover {
		background-color: #8795a1;
	}

	.xl\:hover\:bg-grey:hover {
		background-color: #b8c2cc;
	}

	.xl\:hover\:bg-grey-light:hover {
		background-color: #dae1e7;
	}

	.xl\:hover\:bg-grey-lighter:hover {
		background-color: #f1f5f8;
	}

	.xl\:hover\:bg-grey-lightest:hover {
		background-color: #f8fafc;
	}

	.xl\:hover\:bg-red-darkest:hover {
		background-color: #3b0d0c;
	}

	.xl\:hover\:bg-red-darker:hover {
		background-color: #621b18;
	}

	.xl\:hover\:bg-red-dark:hover {
		background-color: #cc1f1a;
	}

	.xl\:hover\:bg-red-light:hover {
		background-color: #ef5753;
	}

	.xl\:hover\:bg-red-lighter:hover {
		background-color: #f9acaa;
	}

	.xl\:hover\:bg-red-lightest:hover {
		background-color: #fcebea;
	}

	.xl\:hover\:bg-orange-darkest:hover {
		background-color: #462a16;
	}

	.xl\:hover\:bg-orange-darker:hover {
		background-color: #613b1f;
	}

	.xl\:hover\:bg-orange-dark:hover {
		background-color: #de751f;
	}

	.xl\:hover\:bg-orange-light:hover {
		background-color: #faad63;
	}

	.xl\:hover\:bg-orange-lighter:hover {
		background-color: #fcd9b6;
	}

	.xl\:hover\:bg-orange-lightest:hover {
		background-color: #fff5eb;
	}

	.xl\:hover\:bg-yellow-darkest:hover {
		background-color: #453411;
	}

	.xl\:hover\:bg-yellow-darker:hover {
		background-color: #684f1d;
	}

	.xl\:hover\:bg-yellow-dark:hover {
		background-color: #f2d024;
	}

	.xl\:hover\:bg-yellow-light:hover {
		background-color: #fff382;
	}

	.xl\:hover\:bg-yellow-lighter:hover {
		background-color: #fff9c2;
	}

	.xl\:hover\:bg-yellow-lightest:hover {
		background-color: #fcfbeb;
	}

	.xl\:hover\:bg-green-darkest:hover {
		background-color: #0f2f21;
	}

	.xl\:hover\:bg-green-darker:hover {
		background-color: #1a4731;
	}

	.xl\:hover\:bg-green-dark:hover {
		background-color: #1f9d55;
	}

	.xl\:hover\:bg-green-light:hover {
		background-color: #51d88a;
	}

	.xl\:hover\:bg-green-lighter:hover {
		background-color: #a2f5bf;
	}

	.xl\:hover\:bg-green-lightest:hover {
		background-color: #e3fcec;
	}

	.xl\:hover\:bg-teal-darkest:hover {
		background-color: #0d3331;
	}

	.xl\:hover\:bg-teal-darker:hover {
		background-color: #20504f;
	}

	.xl\:hover\:bg-teal-dark:hover {
		background-color: #38a89d;
	}

	.xl\:hover\:bg-teal-light:hover {
		background-color: #64d5ca;
	}

	.xl\:hover\:bg-teal-lighter:hover {
		background-color: #a0f0ed;
	}

	.xl\:hover\:bg-teal-lightest:hover {
		background-color: #e8fffe;
	}

	.xl\:hover\:bg-blue-darkest:hover {
		background-color: #12283a;
	}

	.xl\:hover\:bg-blue-darker:hover {
		background-color: #1c3d5a;
	}

	.xl\:hover\:bg-blue-dark:hover {
		background-color: #2779bd;
	}

	.xl\:hover\:bg-blue-light:hover {
		background-color: #6cb2eb;
	}

	.xl\:hover\:bg-blue-lighter:hover {
		background-color: #bcdefa;
	}

	.xl\:hover\:bg-blue-lightest:hover {
		background-color: #eff8ff;
	}

	.xl\:hover\:bg-indigo-darkest:hover {
		background-color: #191e38;
	}

	.xl\:hover\:bg-indigo-darker:hover {
		background-color: #2f365f;
	}

	.xl\:hover\:bg-indigo-dark:hover {
		background-color: #5661b3;
	}

	.xl\:hover\:bg-indigo-light:hover {
		background-color: #7886d7;
	}

	.xl\:hover\:bg-indigo-lighter:hover {
		background-color: #b2b7ff;
	}

	.xl\:hover\:bg-indigo-lightest:hover {
		background-color: #e6e8ff;
	}

	.xl\:hover\:bg-purple-darkest:hover {
		background-color: #21183c;
	}

	.xl\:hover\:bg-purple-darker:hover {
		background-color: #382b5f;
	}

	.xl\:hover\:bg-purple-dark:hover {
		background-color: #794acf;
	}

	.xl\:hover\:bg-purple-light:hover {
		background-color: #a779e9;
	}

	.xl\:hover\:bg-purple-lighter:hover {
		background-color: #d6bbfc;
	}

	.xl\:hover\:bg-purple-lightest:hover {
		background-color: #f3ebff;
	}

	.xl\:hover\:bg-pink-darkest:hover {
		background-color: #451225;
	}

	.xl\:hover\:bg-pink-darker:hover {
		background-color: #6f213f;
	}

	.xl\:hover\:bg-pink-dark:hover {
		background-color: #eb5286;
	}

	.xl\:hover\:bg-pink-light:hover {
		background-color: #fa7ea8;
	}

	.xl\:hover\:bg-pink-lighter:hover {
		background-color: #ffbbca;
	}

	.xl\:hover\:bg-pink-lightest:hover {
		background-color: #ffebef;
	}

	.xl\:focus\:bg-transparent:focus {
		background-color: transparent;
	}

	.xl\:focus\:bg-black:focus {
		background-color: #22292f;
	}

	.xl\:focus\:bg-white:focus {
		background-color: #ffffff;
	}

	.xl\:focus\:bg-gray-100:focus {
		background-color: #f7fafc;
	}

	.xl\:focus\:bg-gray-200:focus {
		background-color: #edf2f7;
	}

	.xl\:focus\:bg-gray-300:focus {
		background-color: #e2e8f0;
	}

	.xl\:focus\:bg-gray-400:focus {
		background-color: #cbd5e0;
	}

	.xl\:focus\:bg-gray-500:focus {
		background-color: #a0aec0;
	}

	.xl\:focus\:bg-gray-600:focus {
		background-color: #718096;
	}

	.xl\:focus\:bg-gray-700:focus {
		background-color: #4a5568;
	}

	.xl\:focus\:bg-gray-800:focus {
		background-color: #2d3748;
	}

	.xl\:focus\:bg-gray-900:focus {
		background-color: #1a202c;
	}

	.xl\:focus\:bg-red:focus {
		background-color: #e3342f;
	}

	.xl\:focus\:bg-orange:focus {
		background-color: #f6993f;
	}

	.xl\:focus\:bg-yellow:focus {
		background-color: #ffed4a;
	}

	.xl\:focus\:bg-green:focus {
		background-color: #38c172;
	}

	.xl\:focus\:bg-teal:focus {
		background-color: #4dc0b5;
	}

	.xl\:focus\:bg-blue:focus {
		background-color: #3490dc;
	}

	.xl\:focus\:bg-indigo:focus {
		background-color: #6574cd;
	}

	.xl\:focus\:bg-purple:focus {
		background-color: #9561e2;
	}

	.xl\:focus\:bg-pink:focus {
		background-color: #f66d9b;
	}

	.xl\:focus\:bg-grey-darkest:focus {
		background-color: #3d4852;
	}

	.xl\:focus\:bg-grey-darker:focus {
		background-color: #606f7b;
	}

	.xl\:focus\:bg-grey-dark:focus {
		background-color: #8795a1;
	}

	.xl\:focus\:bg-grey:focus {
		background-color: #b8c2cc;
	}

	.xl\:focus\:bg-grey-light:focus {
		background-color: #dae1e7;
	}

	.xl\:focus\:bg-grey-lighter:focus {
		background-color: #f1f5f8;
	}

	.xl\:focus\:bg-grey-lightest:focus {
		background-color: #f8fafc;
	}

	.xl\:focus\:bg-red-darkest:focus {
		background-color: #3b0d0c;
	}

	.xl\:focus\:bg-red-darker:focus {
		background-color: #621b18;
	}

	.xl\:focus\:bg-red-dark:focus {
		background-color: #cc1f1a;
	}

	.xl\:focus\:bg-red-light:focus {
		background-color: #ef5753;
	}

	.xl\:focus\:bg-red-lighter:focus {
		background-color: #f9acaa;
	}

	.xl\:focus\:bg-red-lightest:focus {
		background-color: #fcebea;
	}

	.xl\:focus\:bg-orange-darkest:focus {
		background-color: #462a16;
	}

	.xl\:focus\:bg-orange-darker:focus {
		background-color: #613b1f;
	}

	.xl\:focus\:bg-orange-dark:focus {
		background-color: #de751f;
	}

	.xl\:focus\:bg-orange-light:focus {
		background-color: #faad63;
	}

	.xl\:focus\:bg-orange-lighter:focus {
		background-color: #fcd9b6;
	}

	.xl\:focus\:bg-orange-lightest:focus {
		background-color: #fff5eb;
	}

	.xl\:focus\:bg-yellow-darkest:focus {
		background-color: #453411;
	}

	.xl\:focus\:bg-yellow-darker:focus {
		background-color: #684f1d;
	}

	.xl\:focus\:bg-yellow-dark:focus {
		background-color: #f2d024;
	}

	.xl\:focus\:bg-yellow-light:focus {
		background-color: #fff382;
	}

	.xl\:focus\:bg-yellow-lighter:focus {
		background-color: #fff9c2;
	}

	.xl\:focus\:bg-yellow-lightest:focus {
		background-color: #fcfbeb;
	}

	.xl\:focus\:bg-green-darkest:focus {
		background-color: #0f2f21;
	}

	.xl\:focus\:bg-green-darker:focus {
		background-color: #1a4731;
	}

	.xl\:focus\:bg-green-dark:focus {
		background-color: #1f9d55;
	}

	.xl\:focus\:bg-green-light:focus {
		background-color: #51d88a;
	}

	.xl\:focus\:bg-green-lighter:focus {
		background-color: #a2f5bf;
	}

	.xl\:focus\:bg-green-lightest:focus {
		background-color: #e3fcec;
	}

	.xl\:focus\:bg-teal-darkest:focus {
		background-color: #0d3331;
	}

	.xl\:focus\:bg-teal-darker:focus {
		background-color: #20504f;
	}

	.xl\:focus\:bg-teal-dark:focus {
		background-color: #38a89d;
	}

	.xl\:focus\:bg-teal-light:focus {
		background-color: #64d5ca;
	}

	.xl\:focus\:bg-teal-lighter:focus {
		background-color: #a0f0ed;
	}

	.xl\:focus\:bg-teal-lightest:focus {
		background-color: #e8fffe;
	}

	.xl\:focus\:bg-blue-darkest:focus {
		background-color: #12283a;
	}

	.xl\:focus\:bg-blue-darker:focus {
		background-color: #1c3d5a;
	}

	.xl\:focus\:bg-blue-dark:focus {
		background-color: #2779bd;
	}

	.xl\:focus\:bg-blue-light:focus {
		background-color: #6cb2eb;
	}

	.xl\:focus\:bg-blue-lighter:focus {
		background-color: #bcdefa;
	}

	.xl\:focus\:bg-blue-lightest:focus {
		background-color: #eff8ff;
	}

	.xl\:focus\:bg-indigo-darkest:focus {
		background-color: #191e38;
	}

	.xl\:focus\:bg-indigo-darker:focus {
		background-color: #2f365f;
	}

	.xl\:focus\:bg-indigo-dark:focus {
		background-color: #5661b3;
	}

	.xl\:focus\:bg-indigo-light:focus {
		background-color: #7886d7;
	}

	.xl\:focus\:bg-indigo-lighter:focus {
		background-color: #b2b7ff;
	}

	.xl\:focus\:bg-indigo-lightest:focus {
		background-color: #e6e8ff;
	}

	.xl\:focus\:bg-purple-darkest:focus {
		background-color: #21183c;
	}

	.xl\:focus\:bg-purple-darker:focus {
		background-color: #382b5f;
	}

	.xl\:focus\:bg-purple-dark:focus {
		background-color: #794acf;
	}

	.xl\:focus\:bg-purple-light:focus {
		background-color: #a779e9;
	}

	.xl\:focus\:bg-purple-lighter:focus {
		background-color: #d6bbfc;
	}

	.xl\:focus\:bg-purple-lightest:focus {
		background-color: #f3ebff;
	}

	.xl\:focus\:bg-pink-darkest:focus {
		background-color: #451225;
	}

	.xl\:focus\:bg-pink-darker:focus {
		background-color: #6f213f;
	}

	.xl\:focus\:bg-pink-dark:focus {
		background-color: #eb5286;
	}

	.xl\:focus\:bg-pink-light:focus {
		background-color: #fa7ea8;
	}

	.xl\:focus\:bg-pink-lighter:focus {
		background-color: #ffbbca;
	}

	.xl\:focus\:bg-pink-lightest:focus {
		background-color: #ffebef;
	}

	.xl\:bg-bottom {
		background-position: bottom;
	}

	.xl\:bg-center {
		background-position: center;
	}

	.xl\:bg-left {
		background-position: left;
	}

	.xl\:bg-left-bottom {
		background-position: left bottom;
	}

	.xl\:bg-left-top {
		background-position: left top;
	}

	.xl\:bg-right {
		background-position: right;
	}

	.xl\:bg-right-bottom {
		background-position: right bottom;
	}

	.xl\:bg-right-top {
		background-position: right top;
	}

	.xl\:bg-top {
		background-position: top;
	}

	.xl\:bg-repeat {
		background-repeat: repeat;
	}

	.xl\:bg-no-repeat {
		background-repeat: no-repeat;
	}

	.xl\:bg-repeat-x {
		background-repeat: repeat-x;
	}

	.xl\:bg-repeat-y {
		background-repeat: repeat-y;
	}

	.xl\:bg-repeat-round {
		background-repeat: round;
	}

	.xl\:bg-repeat-space {
		background-repeat: space;
	}

	.xl\:bg-auto {
		background-size: auto;
	}

	.xl\:bg-cover {
		background-size: cover;
	}

	.xl\:bg-contain {
		background-size: contain;
	}

	.xl\:border-collapse {
		border-collapse: collapse;
	}

	.xl\:border-separate {
		border-collapse: separate;
	}

	.xl\:border-transparent {
		border-color: transparent;
	}

	.xl\:border-black {
		border-color: #22292f;
	}

	.xl\:border-white {
		border-color: #ffffff;
	}

	.xl\:border-gray-100 {
		border-color: #f7fafc;
	}

	.xl\:border-gray-200 {
		border-color: #edf2f7;
	}

	.xl\:border-gray-300 {
		border-color: #e2e8f0;
	}

	.xl\:border-gray-400 {
		border-color: #cbd5e0;
	}

	.xl\:border-gray-500 {
		border-color: #a0aec0;
	}

	.xl\:border-gray-600 {
		border-color: #718096;
	}

	.xl\:border-gray-700 {
		border-color: #4a5568;
	}

	.xl\:border-gray-800 {
		border-color: #2d3748;
	}

	.xl\:border-gray-900 {
		border-color: #1a202c;
	}

	.xl\:border-red {
		border-color: #e3342f;
	}

	.xl\:border-orange {
		border-color: #f6993f;
	}

	.xl\:border-yellow {
		border-color: #ffed4a;
	}

	.xl\:border-green {
		border-color: #38c172;
	}

	.xl\:border-teal {
		border-color: #4dc0b5;
	}

	.xl\:border-blue {
		border-color: #3490dc;
	}

	.xl\:border-indigo {
		border-color: #6574cd;
	}

	.xl\:border-purple {
		border-color: #9561e2;
	}

	.xl\:border-pink {
		border-color: #f66d9b;
	}

	.xl\:border-grey-darkest {
		border-color: #3d4852;
	}

	.xl\:border-grey-darker {
		border-color: #606f7b;
	}

	.xl\:border-grey-dark {
		border-color: #8795a1;
	}

	.xl\:border-grey {
		border-color: #b8c2cc;
	}

	.xl\:border-grey-light {
		border-color: #dae1e7;
	}

	.xl\:border-grey-lighter {
		border-color: #f1f5f8;
	}

	.xl\:border-grey-lightest {
		border-color: #f8fafc;
	}

	.xl\:border-red-darkest {
		border-color: #3b0d0c;
	}

	.xl\:border-red-darker {
		border-color: #621b18;
	}

	.xl\:border-red-dark {
		border-color: #cc1f1a;
	}

	.xl\:border-red-light {
		border-color: #ef5753;
	}

	.xl\:border-red-lighter {
		border-color: #f9acaa;
	}

	.xl\:border-red-lightest {
		border-color: #fcebea;
	}

	.xl\:border-orange-darkest {
		border-color: #462a16;
	}

	.xl\:border-orange-darker {
		border-color: #613b1f;
	}

	.xl\:border-orange-dark {
		border-color: #de751f;
	}

	.xl\:border-orange-light {
		border-color: #faad63;
	}

	.xl\:border-orange-lighter {
		border-color: #fcd9b6;
	}

	.xl\:border-orange-lightest {
		border-color: #fff5eb;
	}

	.xl\:border-yellow-darkest {
		border-color: #453411;
	}

	.xl\:border-yellow-darker {
		border-color: #684f1d;
	}

	.xl\:border-yellow-dark {
		border-color: #f2d024;
	}

	.xl\:border-yellow-light {
		border-color: #fff382;
	}

	.xl\:border-yellow-lighter {
		border-color: #fff9c2;
	}

	.xl\:border-yellow-lightest {
		border-color: #fcfbeb;
	}

	.xl\:border-green-darkest {
		border-color: #0f2f21;
	}

	.xl\:border-green-darker {
		border-color: #1a4731;
	}

	.xl\:border-green-dark {
		border-color: #1f9d55;
	}

	.xl\:border-green-light {
		border-color: #51d88a;
	}

	.xl\:border-green-lighter {
		border-color: #a2f5bf;
	}

	.xl\:border-green-lightest {
		border-color: #e3fcec;
	}

	.xl\:border-teal-darkest {
		border-color: #0d3331;
	}

	.xl\:border-teal-darker {
		border-color: #20504f;
	}

	.xl\:border-teal-dark {
		border-color: #38a89d;
	}

	.xl\:border-teal-light {
		border-color: #64d5ca;
	}

	.xl\:border-teal-lighter {
		border-color: #a0f0ed;
	}

	.xl\:border-teal-lightest {
		border-color: #e8fffe;
	}

	.xl\:border-blue-darkest {
		border-color: #12283a;
	}

	.xl\:border-blue-darker {
		border-color: #1c3d5a;
	}

	.xl\:border-blue-dark {
		border-color: #2779bd;
	}

	.xl\:border-blue-light {
		border-color: #6cb2eb;
	}

	.xl\:border-blue-lighter {
		border-color: #bcdefa;
	}

	.xl\:border-blue-lightest {
		border-color: #eff8ff;
	}

	.xl\:border-indigo-darkest {
		border-color: #191e38;
	}

	.xl\:border-indigo-darker {
		border-color: #2f365f;
	}

	.xl\:border-indigo-dark {
		border-color: #5661b3;
	}

	.xl\:border-indigo-light {
		border-color: #7886d7;
	}

	.xl\:border-indigo-lighter {
		border-color: #b2b7ff;
	}

	.xl\:border-indigo-lightest {
		border-color: #e6e8ff;
	}

	.xl\:border-purple-darkest {
		border-color: #21183c;
	}

	.xl\:border-purple-darker {
		border-color: #382b5f;
	}

	.xl\:border-purple-dark {
		border-color: #794acf;
	}

	.xl\:border-purple-light {
		border-color: #a779e9;
	}

	.xl\:border-purple-lighter {
		border-color: #d6bbfc;
	}

	.xl\:border-purple-lightest {
		border-color: #f3ebff;
	}

	.xl\:border-pink-darkest {
		border-color: #451225;
	}

	.xl\:border-pink-darker {
		border-color: #6f213f;
	}

	.xl\:border-pink-dark {
		border-color: #eb5286;
	}

	.xl\:border-pink-light {
		border-color: #fa7ea8;
	}

	.xl\:border-pink-lighter {
		border-color: #ffbbca;
	}

	.xl\:border-pink-lightest {
		border-color: #ffebef;
	}

	.xl\:hover\:border-transparent:hover {
		border-color: transparent;
	}

	.xl\:hover\:border-black:hover {
		border-color: #22292f;
	}

	.xl\:hover\:border-white:hover {
		border-color: #ffffff;
	}

	.xl\:hover\:border-gray-100:hover {
		border-color: #f7fafc;
	}

	.xl\:hover\:border-gray-200:hover {
		border-color: #edf2f7;
	}

	.xl\:hover\:border-gray-300:hover {
		border-color: #e2e8f0;
	}

	.xl\:hover\:border-gray-400:hover {
		border-color: #cbd5e0;
	}

	.xl\:hover\:border-gray-500:hover {
		border-color: #a0aec0;
	}

	.xl\:hover\:border-gray-600:hover {
		border-color: #718096;
	}

	.xl\:hover\:border-gray-700:hover {
		border-color: #4a5568;
	}

	.xl\:hover\:border-gray-800:hover {
		border-color: #2d3748;
	}

	.xl\:hover\:border-gray-900:hover {
		border-color: #1a202c;
	}

	.xl\:hover\:border-red:hover {
		border-color: #e3342f;
	}

	.xl\:hover\:border-orange:hover {
		border-color: #f6993f;
	}

	.xl\:hover\:border-yellow:hover {
		border-color: #ffed4a;
	}

	.xl\:hover\:border-green:hover {
		border-color: #38c172;
	}

	.xl\:hover\:border-teal:hover {
		border-color: #4dc0b5;
	}

	.xl\:hover\:border-blue:hover {
		border-color: #3490dc;
	}

	.xl\:hover\:border-indigo:hover {
		border-color: #6574cd;
	}

	.xl\:hover\:border-purple:hover {
		border-color: #9561e2;
	}

	.xl\:hover\:border-pink:hover {
		border-color: #f66d9b;
	}

	.xl\:hover\:border-grey-darkest:hover {
		border-color: #3d4852;
	}

	.xl\:hover\:border-grey-darker:hover {
		border-color: #606f7b;
	}

	.xl\:hover\:border-grey-dark:hover {
		border-color: #8795a1;
	}

	.xl\:hover\:border-grey:hover {
		border-color: #b8c2cc;
	}

	.xl\:hover\:border-grey-light:hover {
		border-color: #dae1e7;
	}

	.xl\:hover\:border-grey-lighter:hover {
		border-color: #f1f5f8;
	}

	.xl\:hover\:border-grey-lightest:hover {
		border-color: #f8fafc;
	}

	.xl\:hover\:border-red-darkest:hover {
		border-color: #3b0d0c;
	}

	.xl\:hover\:border-red-darker:hover {
		border-color: #621b18;
	}

	.xl\:hover\:border-red-dark:hover {
		border-color: #cc1f1a;
	}

	.xl\:hover\:border-red-light:hover {
		border-color: #ef5753;
	}

	.xl\:hover\:border-red-lighter:hover {
		border-color: #f9acaa;
	}

	.xl\:hover\:border-red-lightest:hover {
		border-color: #fcebea;
	}

	.xl\:hover\:border-orange-darkest:hover {
		border-color: #462a16;
	}

	.xl\:hover\:border-orange-darker:hover {
		border-color: #613b1f;
	}

	.xl\:hover\:border-orange-dark:hover {
		border-color: #de751f;
	}

	.xl\:hover\:border-orange-light:hover {
		border-color: #faad63;
	}

	.xl\:hover\:border-orange-lighter:hover {
		border-color: #fcd9b6;
	}

	.xl\:hover\:border-orange-lightest:hover {
		border-color: #fff5eb;
	}

	.xl\:hover\:border-yellow-darkest:hover {
		border-color: #453411;
	}

	.xl\:hover\:border-yellow-darker:hover {
		border-color: #684f1d;
	}

	.xl\:hover\:border-yellow-dark:hover {
		border-color: #f2d024;
	}

	.xl\:hover\:border-yellow-light:hover {
		border-color: #fff382;
	}

	.xl\:hover\:border-yellow-lighter:hover {
		border-color: #fff9c2;
	}

	.xl\:hover\:border-yellow-lightest:hover {
		border-color: #fcfbeb;
	}

	.xl\:hover\:border-green-darkest:hover {
		border-color: #0f2f21;
	}

	.xl\:hover\:border-green-darker:hover {
		border-color: #1a4731;
	}

	.xl\:hover\:border-green-dark:hover {
		border-color: #1f9d55;
	}

	.xl\:hover\:border-green-light:hover {
		border-color: #51d88a;
	}

	.xl\:hover\:border-green-lighter:hover {
		border-color: #a2f5bf;
	}

	.xl\:hover\:border-green-lightest:hover {
		border-color: #e3fcec;
	}

	.xl\:hover\:border-teal-darkest:hover {
		border-color: #0d3331;
	}

	.xl\:hover\:border-teal-darker:hover {
		border-color: #20504f;
	}

	.xl\:hover\:border-teal-dark:hover {
		border-color: #38a89d;
	}

	.xl\:hover\:border-teal-light:hover {
		border-color: #64d5ca;
	}

	.xl\:hover\:border-teal-lighter:hover {
		border-color: #a0f0ed;
	}

	.xl\:hover\:border-teal-lightest:hover {
		border-color: #e8fffe;
	}

	.xl\:hover\:border-blue-darkest:hover {
		border-color: #12283a;
	}

	.xl\:hover\:border-blue-darker:hover {
		border-color: #1c3d5a;
	}

	.xl\:hover\:border-blue-dark:hover {
		border-color: #2779bd;
	}

	.xl\:hover\:border-blue-light:hover {
		border-color: #6cb2eb;
	}

	.xl\:hover\:border-blue-lighter:hover {
		border-color: #bcdefa;
	}

	.xl\:hover\:border-blue-lightest:hover {
		border-color: #eff8ff;
	}

	.xl\:hover\:border-indigo-darkest:hover {
		border-color: #191e38;
	}

	.xl\:hover\:border-indigo-darker:hover {
		border-color: #2f365f;
	}

	.xl\:hover\:border-indigo-dark:hover {
		border-color: #5661b3;
	}

	.xl\:hover\:border-indigo-light:hover {
		border-color: #7886d7;
	}

	.xl\:hover\:border-indigo-lighter:hover {
		border-color: #b2b7ff;
	}

	.xl\:hover\:border-indigo-lightest:hover {
		border-color: #e6e8ff;
	}

	.xl\:hover\:border-purple-darkest:hover {
		border-color: #21183c;
	}

	.xl\:hover\:border-purple-darker:hover {
		border-color: #382b5f;
	}

	.xl\:hover\:border-purple-dark:hover {
		border-color: #794acf;
	}

	.xl\:hover\:border-purple-light:hover {
		border-color: #a779e9;
	}

	.xl\:hover\:border-purple-lighter:hover {
		border-color: #d6bbfc;
	}

	.xl\:hover\:border-purple-lightest:hover {
		border-color: #f3ebff;
	}

	.xl\:hover\:border-pink-darkest:hover {
		border-color: #451225;
	}

	.xl\:hover\:border-pink-darker:hover {
		border-color: #6f213f;
	}

	.xl\:hover\:border-pink-dark:hover {
		border-color: #eb5286;
	}

	.xl\:hover\:border-pink-light:hover {
		border-color: #fa7ea8;
	}

	.xl\:hover\:border-pink-lighter:hover {
		border-color: #ffbbca;
	}

	.xl\:hover\:border-pink-lightest:hover {
		border-color: #ffebef;
	}

	.xl\:focus\:border-transparent:focus {
		border-color: transparent;
	}

	.xl\:focus\:border-black:focus {
		border-color: #22292f;
	}

	.xl\:focus\:border-white:focus {
		border-color: #ffffff;
	}

	.xl\:focus\:border-gray-100:focus {
		border-color: #f7fafc;
	}

	.xl\:focus\:border-gray-200:focus {
		border-color: #edf2f7;
	}

	.xl\:focus\:border-gray-300:focus {
		border-color: #e2e8f0;
	}

	.xl\:focus\:border-gray-400:focus {
		border-color: #cbd5e0;
	}

	.xl\:focus\:border-gray-500:focus {
		border-color: #a0aec0;
	}

	.xl\:focus\:border-gray-600:focus {
		border-color: #718096;
	}

	.xl\:focus\:border-gray-700:focus {
		border-color: #4a5568;
	}

	.xl\:focus\:border-gray-800:focus {
		border-color: #2d3748;
	}

	.xl\:focus\:border-gray-900:focus {
		border-color: #1a202c;
	}

	.xl\:focus\:border-red:focus {
		border-color: #e3342f;
	}

	.xl\:focus\:border-orange:focus {
		border-color: #f6993f;
	}

	.xl\:focus\:border-yellow:focus {
		border-color: #ffed4a;
	}

	.xl\:focus\:border-green:focus {
		border-color: #38c172;
	}

	.xl\:focus\:border-teal:focus {
		border-color: #4dc0b5;
	}

	.xl\:focus\:border-blue:focus {
		border-color: #3490dc;
	}

	.xl\:focus\:border-indigo:focus {
		border-color: #6574cd;
	}

	.xl\:focus\:border-purple:focus {
		border-color: #9561e2;
	}

	.xl\:focus\:border-pink:focus {
		border-color: #f66d9b;
	}

	.xl\:focus\:border-grey-darkest:focus {
		border-color: #3d4852;
	}

	.xl\:focus\:border-grey-darker:focus {
		border-color: #606f7b;
	}

	.xl\:focus\:border-grey-dark:focus {
		border-color: #8795a1;
	}

	.xl\:focus\:border-grey:focus {
		border-color: #b8c2cc;
	}

	.xl\:focus\:border-grey-light:focus {
		border-color: #dae1e7;
	}

	.xl\:focus\:border-grey-lighter:focus {
		border-color: #f1f5f8;
	}

	.xl\:focus\:border-grey-lightest:focus {
		border-color: #f8fafc;
	}

	.xl\:focus\:border-red-darkest:focus {
		border-color: #3b0d0c;
	}

	.xl\:focus\:border-red-darker:focus {
		border-color: #621b18;
	}

	.xl\:focus\:border-red-dark:focus {
		border-color: #cc1f1a;
	}

	.xl\:focus\:border-red-light:focus {
		border-color: #ef5753;
	}

	.xl\:focus\:border-red-lighter:focus {
		border-color: #f9acaa;
	}

	.xl\:focus\:border-red-lightest:focus {
		border-color: #fcebea;
	}

	.xl\:focus\:border-orange-darkest:focus {
		border-color: #462a16;
	}

	.xl\:focus\:border-orange-darker:focus {
		border-color: #613b1f;
	}

	.xl\:focus\:border-orange-dark:focus {
		border-color: #de751f;
	}

	.xl\:focus\:border-orange-light:focus {
		border-color: #faad63;
	}

	.xl\:focus\:border-orange-lighter:focus {
		border-color: #fcd9b6;
	}

	.xl\:focus\:border-orange-lightest:focus {
		border-color: #fff5eb;
	}

	.xl\:focus\:border-yellow-darkest:focus {
		border-color: #453411;
	}

	.xl\:focus\:border-yellow-darker:focus {
		border-color: #684f1d;
	}

	.xl\:focus\:border-yellow-dark:focus {
		border-color: #f2d024;
	}

	.xl\:focus\:border-yellow-light:focus {
		border-color: #fff382;
	}

	.xl\:focus\:border-yellow-lighter:focus {
		border-color: #fff9c2;
	}

	.xl\:focus\:border-yellow-lightest:focus {
		border-color: #fcfbeb;
	}

	.xl\:focus\:border-green-darkest:focus {
		border-color: #0f2f21;
	}

	.xl\:focus\:border-green-darker:focus {
		border-color: #1a4731;
	}

	.xl\:focus\:border-green-dark:focus {
		border-color: #1f9d55;
	}

	.xl\:focus\:border-green-light:focus {
		border-color: #51d88a;
	}

	.xl\:focus\:border-green-lighter:focus {
		border-color: #a2f5bf;
	}

	.xl\:focus\:border-green-lightest:focus {
		border-color: #e3fcec;
	}

	.xl\:focus\:border-teal-darkest:focus {
		border-color: #0d3331;
	}

	.xl\:focus\:border-teal-darker:focus {
		border-color: #20504f;
	}

	.xl\:focus\:border-teal-dark:focus {
		border-color: #38a89d;
	}

	.xl\:focus\:border-teal-light:focus {
		border-color: #64d5ca;
	}

	.xl\:focus\:border-teal-lighter:focus {
		border-color: #a0f0ed;
	}

	.xl\:focus\:border-teal-lightest:focus {
		border-color: #e8fffe;
	}

	.xl\:focus\:border-blue-darkest:focus {
		border-color: #12283a;
	}

	.xl\:focus\:border-blue-darker:focus {
		border-color: #1c3d5a;
	}

	.xl\:focus\:border-blue-dark:focus {
		border-color: #2779bd;
	}

	.xl\:focus\:border-blue-light:focus {
		border-color: #6cb2eb;
	}

	.xl\:focus\:border-blue-lighter:focus {
		border-color: #bcdefa;
	}

	.xl\:focus\:border-blue-lightest:focus {
		border-color: #eff8ff;
	}

	.xl\:focus\:border-indigo-darkest:focus {
		border-color: #191e38;
	}

	.xl\:focus\:border-indigo-darker:focus {
		border-color: #2f365f;
	}

	.xl\:focus\:border-indigo-dark:focus {
		border-color: #5661b3;
	}

	.xl\:focus\:border-indigo-light:focus {
		border-color: #7886d7;
	}

	.xl\:focus\:border-indigo-lighter:focus {
		border-color: #b2b7ff;
	}

	.xl\:focus\:border-indigo-lightest:focus {
		border-color: #e6e8ff;
	}

	.xl\:focus\:border-purple-darkest:focus {
		border-color: #21183c;
	}

	.xl\:focus\:border-purple-darker:focus {
		border-color: #382b5f;
	}

	.xl\:focus\:border-purple-dark:focus {
		border-color: #794acf;
	}

	.xl\:focus\:border-purple-light:focus {
		border-color: #a779e9;
	}

	.xl\:focus\:border-purple-lighter:focus {
		border-color: #d6bbfc;
	}

	.xl\:focus\:border-purple-lightest:focus {
		border-color: #f3ebff;
	}

	.xl\:focus\:border-pink-darkest:focus {
		border-color: #451225;
	}

	.xl\:focus\:border-pink-darker:focus {
		border-color: #6f213f;
	}

	.xl\:focus\:border-pink-dark:focus {
		border-color: #eb5286;
	}

	.xl\:focus\:border-pink-light:focus {
		border-color: #fa7ea8;
	}

	.xl\:focus\:border-pink-lighter:focus {
		border-color: #ffbbca;
	}

	.xl\:focus\:border-pink-lightest:focus {
		border-color: #ffebef;
	}

	.xl\:rounded-2 {
		border-radius: 0.2rem;
	}

	.xl\:rounded-4 {
		border-radius: 0.4rem;
	}

	.xl\:rounded-6 {
		border-radius: 0.6rem;
	}

	.xl\:rounded-8 {
		border-radius: 0.8rem;
	}

	.xl\:rounded-12 {
		border-radius: 1.2rem;
	}

	.xl\:rounded-16 {
		border-radius: 1.6rem;
	}

	.xl\:rounded-20 {
		border-radius: 2rem;
	}

	.xl\:rounded-24 {
		border-radius: 2.4rem;
	}

	.xl\:rounded-28 {
		border-radius: 2.8rem;
	}

	.xl\:rounded-32 {
		border-radius: 3.2rem;
	}

	.xl\:rounded-none {
		border-radius: 0;
	}

	.xl\:rounded-sm {
		border-radius: 0.2rem;
	}

	.xl\:rounded {
		border-radius: 0.4rem;
	}

	.xl\:rounded-lg {
		border-radius: 0.8rem;
	}

	.xl\:rounded-full {
		border-radius: 9999px;
	}

	.xl\:rounded-t-2 {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.xl\:rounded-r-2 {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.xl\:rounded-b-2 {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-l-2 {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-t-4 {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.xl\:rounded-r-4 {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.xl\:rounded-b-4 {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-l-4 {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-t-6 {
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
	}

	.xl\:rounded-r-6 {
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
	}

	.xl\:rounded-b-6 {
		border-bottom-right-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.xl\:rounded-l-6 {
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.xl\:rounded-t-8 {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.xl\:rounded-r-8 {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.xl\:rounded-b-8 {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-l-8 {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-t-12 {
		border-top-left-radius: 1.2rem;
		border-top-right-radius: 1.2rem;
	}

	.xl\:rounded-r-12 {
		border-top-right-radius: 1.2rem;
		border-bottom-right-radius: 1.2rem;
	}

	.xl\:rounded-b-12 {
		border-bottom-right-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.xl\:rounded-l-12 {
		border-top-left-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.xl\:rounded-t-16 {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
	}

	.xl\:rounded-r-16 {
		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;
	}

	.xl\:rounded-b-16 {
		border-bottom-right-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.xl\:rounded-l-16 {
		border-top-left-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.xl\:rounded-t-20 {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.xl\:rounded-r-20 {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}

	.xl\:rounded-b-20 {
		border-bottom-right-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.xl\:rounded-l-20 {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.xl\:rounded-t-24 {
		border-top-left-radius: 2.4rem;
		border-top-right-radius: 2.4rem;
	}

	.xl\:rounded-r-24 {
		border-top-right-radius: 2.4rem;
		border-bottom-right-radius: 2.4rem;
	}

	.xl\:rounded-b-24 {
		border-bottom-right-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.xl\:rounded-l-24 {
		border-top-left-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.xl\:rounded-t-28 {
		border-top-left-radius: 2.8rem;
		border-top-right-radius: 2.8rem;
	}

	.xl\:rounded-r-28 {
		border-top-right-radius: 2.8rem;
		border-bottom-right-radius: 2.8rem;
	}

	.xl\:rounded-b-28 {
		border-bottom-right-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.xl\:rounded-l-28 {
		border-top-left-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.xl\:rounded-t-32 {
		border-top-left-radius: 3.2rem;
		border-top-right-radius: 3.2rem;
	}

	.xl\:rounded-r-32 {
		border-top-right-radius: 3.2rem;
		border-bottom-right-radius: 3.2rem;
	}

	.xl\:rounded-b-32 {
		border-bottom-right-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.xl\:rounded-l-32 {
		border-top-left-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.xl\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.xl\:rounded-r-none {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.xl\:rounded-b-none {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.xl\:rounded-l-none {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.xl\:rounded-t-sm {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.xl\:rounded-r-sm {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.xl\:rounded-b-sm {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-l-sm {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-t {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.xl\:rounded-r {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.xl\:rounded-b {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-l {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-t-lg {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.xl\:rounded-r-lg {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.xl\:rounded-b-lg {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-l-lg {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-t-full {
		border-top-left-radius: 9999px;
		border-top-right-radius: 9999px;
	}

	.xl\:rounded-r-full {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}

	.xl\:rounded-b-full {
		border-bottom-right-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.xl\:rounded-l-full {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.xl\:rounded-tl-2 {
		border-top-left-radius: 0.2rem;
	}

	.xl\:rounded-tr-2 {
		border-top-right-radius: 0.2rem;
	}

	.xl\:rounded-br-2 {
		border-bottom-right-radius: 0.2rem;
	}

	.xl\:rounded-bl-2 {
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-tl-4 {
		border-top-left-radius: 0.4rem;
	}

	.xl\:rounded-tr-4 {
		border-top-right-radius: 0.4rem;
	}

	.xl\:rounded-br-4 {
		border-bottom-right-radius: 0.4rem;
	}

	.xl\:rounded-bl-4 {
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-tl-6 {
		border-top-left-radius: 0.6rem;
	}

	.xl\:rounded-tr-6 {
		border-top-right-radius: 0.6rem;
	}

	.xl\:rounded-br-6 {
		border-bottom-right-radius: 0.6rem;
	}

	.xl\:rounded-bl-6 {
		border-bottom-left-radius: 0.6rem;
	}

	.xl\:rounded-tl-8 {
		border-top-left-radius: 0.8rem;
	}

	.xl\:rounded-tr-8 {
		border-top-right-radius: 0.8rem;
	}

	.xl\:rounded-br-8 {
		border-bottom-right-radius: 0.8rem;
	}

	.xl\:rounded-bl-8 {
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-tl-12 {
		border-top-left-radius: 1.2rem;
	}

	.xl\:rounded-tr-12 {
		border-top-right-radius: 1.2rem;
	}

	.xl\:rounded-br-12 {
		border-bottom-right-radius: 1.2rem;
	}

	.xl\:rounded-bl-12 {
		border-bottom-left-radius: 1.2rem;
	}

	.xl\:rounded-tl-16 {
		border-top-left-radius: 1.6rem;
	}

	.xl\:rounded-tr-16 {
		border-top-right-radius: 1.6rem;
	}

	.xl\:rounded-br-16 {
		border-bottom-right-radius: 1.6rem;
	}

	.xl\:rounded-bl-16 {
		border-bottom-left-radius: 1.6rem;
	}

	.xl\:rounded-tl-20 {
		border-top-left-radius: 2rem;
	}

	.xl\:rounded-tr-20 {
		border-top-right-radius: 2rem;
	}

	.xl\:rounded-br-20 {
		border-bottom-right-radius: 2rem;
	}

	.xl\:rounded-bl-20 {
		border-bottom-left-radius: 2rem;
	}

	.xl\:rounded-tl-24 {
		border-top-left-radius: 2.4rem;
	}

	.xl\:rounded-tr-24 {
		border-top-right-radius: 2.4rem;
	}

	.xl\:rounded-br-24 {
		border-bottom-right-radius: 2.4rem;
	}

	.xl\:rounded-bl-24 {
		border-bottom-left-radius: 2.4rem;
	}

	.xl\:rounded-tl-28 {
		border-top-left-radius: 2.8rem;
	}

	.xl\:rounded-tr-28 {
		border-top-right-radius: 2.8rem;
	}

	.xl\:rounded-br-28 {
		border-bottom-right-radius: 2.8rem;
	}

	.xl\:rounded-bl-28 {
		border-bottom-left-radius: 2.8rem;
	}

	.xl\:rounded-tl-32 {
		border-top-left-radius: 3.2rem;
	}

	.xl\:rounded-tr-32 {
		border-top-right-radius: 3.2rem;
	}

	.xl\:rounded-br-32 {
		border-bottom-right-radius: 3.2rem;
	}

	.xl\:rounded-bl-32 {
		border-bottom-left-radius: 3.2rem;
	}

	.xl\:rounded-tl-none {
		border-top-left-radius: 0;
	}

	.xl\:rounded-tr-none {
		border-top-right-radius: 0;
	}

	.xl\:rounded-br-none {
		border-bottom-right-radius: 0;
	}

	.xl\:rounded-bl-none {
		border-bottom-left-radius: 0;
	}

	.xl\:rounded-tl-sm {
		border-top-left-radius: 0.2rem;
	}

	.xl\:rounded-tr-sm {
		border-top-right-radius: 0.2rem;
	}

	.xl\:rounded-br-sm {
		border-bottom-right-radius: 0.2rem;
	}

	.xl\:rounded-bl-sm {
		border-bottom-left-radius: 0.2rem;
	}

	.xl\:rounded-tl {
		border-top-left-radius: 0.4rem;
	}

	.xl\:rounded-tr {
		border-top-right-radius: 0.4rem;
	}

	.xl\:rounded-br {
		border-bottom-right-radius: 0.4rem;
	}

	.xl\:rounded-bl {
		border-bottom-left-radius: 0.4rem;
	}

	.xl\:rounded-tl-lg {
		border-top-left-radius: 0.8rem;
	}

	.xl\:rounded-tr-lg {
		border-top-right-radius: 0.8rem;
	}

	.xl\:rounded-br-lg {
		border-bottom-right-radius: 0.8rem;
	}

	.xl\:rounded-bl-lg {
		border-bottom-left-radius: 0.8rem;
	}

	.xl\:rounded-tl-full {
		border-top-left-radius: 9999px;
	}

	.xl\:rounded-tr-full {
		border-top-right-radius: 9999px;
	}

	.xl\:rounded-br-full {
		border-bottom-right-radius: 9999px;
	}

	.xl\:rounded-bl-full {
		border-bottom-left-radius: 9999px;
	}

	.xl\:border-solid {
		border-style: solid;
	}

	.xl\:border-dashed {
		border-style: dashed;
	}

	.xl\:border-dotted {
		border-style: dotted;
	}

	.xl\:border-none {
		border-style: none;
	}

	.xl\:border-0 {
		border-width: 0;
	}

	.xl\:border-1 {
		border-width: 1px;
	}

	.xl\:border-2 {
		border-width: 2px;
	}

	.xl\:border-3 {
		border-width: 3px;
	}

	.xl\:border-4 {
		border-width: 4px;
	}

	.xl\:border-8 {
		border-width: 8px;
	}

	.xl\:border {
		border-width: 1px;
	}

	.xl\:border-t-0 {
		border-top-width: 0;
	}

	.xl\:border-r-0 {
		border-right-width: 0;
	}

	.xl\:border-b-0 {
		border-bottom-width: 0;
	}

	.xl\:border-l-0 {
		border-left-width: 0;
	}

	.xl\:border-t-1 {
		border-top-width: 1px;
	}

	.xl\:border-r-1 {
		border-right-width: 1px;
	}

	.xl\:border-b-1 {
		border-bottom-width: 1px;
	}

	.xl\:border-l-1 {
		border-left-width: 1px;
	}

	.xl\:border-t-2 {
		border-top-width: 2px;
	}

	.xl\:border-r-2 {
		border-right-width: 2px;
	}

	.xl\:border-b-2 {
		border-bottom-width: 2px;
	}

	.xl\:border-l-2 {
		border-left-width: 2px;
	}

	.xl\:border-t-3 {
		border-top-width: 3px;
	}

	.xl\:border-r-3 {
		border-right-width: 3px;
	}

	.xl\:border-b-3 {
		border-bottom-width: 3px;
	}

	.xl\:border-l-3 {
		border-left-width: 3px;
	}

	.xl\:border-t-4 {
		border-top-width: 4px;
	}

	.xl\:border-r-4 {
		border-right-width: 4px;
	}

	.xl\:border-b-4 {
		border-bottom-width: 4px;
	}

	.xl\:border-l-4 {
		border-left-width: 4px;
	}

	.xl\:border-t-8 {
		border-top-width: 8px;
	}

	.xl\:border-r-8 {
		border-right-width: 8px;
	}

	.xl\:border-b-8 {
		border-bottom-width: 8px;
	}

	.xl\:border-l-8 {
		border-left-width: 8px;
	}

	.xl\:border-t {
		border-top-width: 1px;
	}

	.xl\:border-r {
		border-right-width: 1px;
	}

	.xl\:border-b {
		border-bottom-width: 1px;
	}

	.xl\:border-l {
		border-left-width: 1px;
	}

	.xl\:cursor-auto {
		cursor: auto;
	}

	.xl\:cursor-default {
		cursor: default;
	}

	.xl\:cursor-pointer {
		cursor: pointer;
	}

	.xl\:cursor-wait {
		cursor: wait;
	}

	.xl\:cursor-text {
		cursor: text;
	}

	.xl\:cursor-move {
		cursor: move;
	}

	.xl\:cursor-not-allowed {
		cursor: not-allowed;
	}

	.xl\:block {
		display: block;
	}

	.xl\:inline-block {
		display: inline-block;
	}

	.xl\:inline {
		display: inline;
	}

	.xl\:flex {
		display: -webkit-box;
		display: flex;
	}

	.xl\:inline-flex {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.xl\:table {
		display: table;
	}

	.xl\:table-row {
		display: table-row;
	}

	.xl\:table-cell {
		display: table-cell;
	}

	.xl\:hidden {
		display: none;
	}

	.xl\:hover\:block:hover {
		display: block;
	}

	.xl\:hover\:inline-block:hover {
		display: inline-block;
	}

	.xl\:hover\:inline:hover {
		display: inline;
	}

	.xl\:hover\:flex:hover {
		display: -webkit-box;
		display: flex;
	}

	.xl\:hover\:inline-flex:hover {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.xl\:hover\:table:hover {
		display: table;
	}

	.xl\:hover\:table-row:hover {
		display: table-row;
	}

	.xl\:hover\:table-cell:hover {
		display: table-cell;
	}

	.xl\:hover\:hidden:hover {
		display: none;
	}

	.xl\:focus\:block:focus {
		display: block;
	}

	.xl\:focus\:inline-block:focus {
		display: inline-block;
	}

	.xl\:focus\:inline:focus {
		display: inline;
	}

	.xl\:focus\:flex:focus {
		display: -webkit-box;
		display: flex;
	}

	.xl\:focus\:inline-flex:focus {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.xl\:focus\:table:focus {
		display: table;
	}

	.xl\:focus\:table-row:focus {
		display: table-row;
	}

	.xl\:focus\:table-cell:focus {
		display: table-cell;
	}

	.xl\:focus\:hidden:focus {
		display: none;
	}

	.xl\:flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.xl\:flex-row-reverse {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.xl\:flex-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.xl\:flex-col-reverse {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.xl\:flex-wrap {
		flex-wrap: wrap;
	}

	.xl\:flex-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	.xl\:flex-no-wrap {
		flex-wrap: nowrap;
	}

	.xl\:items-start {
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.xl\:items-end {
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.xl\:items-center {
		-webkit-box-align: center;
		align-items: center;
	}

	.xl\:items-baseline {
		-webkit-box-align: baseline;
		align-items: baseline;
	}

	.xl\:items-stretch {
		-webkit-box-align: stretch;
		align-items: stretch;
	}

	.xl\:self-auto {
		align-self: auto;
	}

	.xl\:self-start {
		align-self: flex-start;
	}

	.xl\:self-end {
		align-self: flex-end;
	}

	.xl\:self-center {
		align-self: center;
	}

	.xl\:self-stretch {
		align-self: stretch;
	}

	.xl\:justify-start {
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.xl\:justify-end {
		-webkit-box-pack: end;
		justify-content: flex-end;
	}

	.xl\:justify-center {
		-webkit-box-pack: center;
		justify-content: center;
	}

	.xl\:justify-between {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.xl\:justify-around {
		justify-content: space-around;
	}

	.xl\:content-center {
		align-content: center;
	}

	.xl\:content-start {
		align-content: flex-start;
	}

	.xl\:content-end {
		align-content: flex-end;
	}

	.xl\:content-between {
		align-content: space-between;
	}

	.xl\:content-around {
		align-content: space-around;
	}

	.xl\:flex-1 {
		-webkit-box-flex: 1;
		flex: 1 1 0%;
	}

	.xl\:flex-auto {
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}

	.xl\:flex-initial {
		-webkit-box-flex: 0;
		flex: 0 1 auto;
	}

	.xl\:flex-none {
		-webkit-box-flex: 0;
		flex: none;
	}

	.xl\:flex-grow-0 {
		-webkit-box-flex: 0;
		flex-grow: 0;
	}

	.xl\:flex-grow {
		-webkit-box-flex: 1;
		flex-grow: 1;
	}

	.xl\:flex-shrink-0 {
		flex-shrink: 0;
	}

	.xl\:flex-shrink {
		flex-shrink: 1;
	}

	.xl\:order-1 {
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	.xl\:order-2 {
		-webkit-box-ordinal-group: 3;
		order: 2;
	}

	.xl\:order-3 {
		-webkit-box-ordinal-group: 4;
		order: 3;
	}

	.xl\:order-4 {
		-webkit-box-ordinal-group: 5;
		order: 4;
	}

	.xl\:order-5 {
		-webkit-box-ordinal-group: 6;
		order: 5;
	}

	.xl\:order-6 {
		-webkit-box-ordinal-group: 7;
		order: 6;
	}

	.xl\:order-7 {
		-webkit-box-ordinal-group: 8;
		order: 7;
	}

	.xl\:order-8 {
		-webkit-box-ordinal-group: 9;
		order: 8;
	}

	.xl\:order-9 {
		-webkit-box-ordinal-group: 10;
		order: 9;
	}

	.xl\:order-10 {
		-webkit-box-ordinal-group: 11;
		order: 10;
	}

	.xl\:order-11 {
		-webkit-box-ordinal-group: 12;
		order: 11;
	}

	.xl\:order-12 {
		-webkit-box-ordinal-group: 13;
		order: 12;
	}

	.xl\:order-first {
		-webkit-box-ordinal-group: -9998;
		order: -9999;
	}

	.xl\:order-last {
		-webkit-box-ordinal-group: 10000;
		order: 9999;
	}

	.xl\:order-none {
		-webkit-box-ordinal-group: 1;
		order: 0;
	}

	.xl\:float-right {
		float: right;
	}

	.xl\:float-left {
		float: left;
	}

	.xl\:float-none {
		float: none;
	}

	.xl\:clearfix:after {
		content: '';
		display: table;
		clear: both;
	}

	.xl\:font-sans {
		font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
			Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
			sans-serif;
	}

	.xl\:font-serif {
		font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
			DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
	}

	.xl\:font-mono {
		font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
			monospace;
	}

	.xl\:font-100 {
		font-weight: 100;
	}

	.xl\:font-200 {
		font-weight: 200;
	}

	.xl\:font-300 {
		font-weight: 300;
	}

	.xl\:font-400 {
		font-weight: 400;
	}

	.xl\:font-500 {
		font-weight: 500;
	}

	.xl\:font-600 {
		font-weight: 600;
	}

	.xl\:font-700 {
		font-weight: 700;
	}

	.xl\:font-800 {
		font-weight: 800;
	}

	.xl\:font-900 {
		font-weight: 900;
	}

	.xl\:font-hairline {
		font-weight: 100;
	}

	.xl\:font-thin {
		font-weight: 200;
	}

	.xl\:font-light {
		font-weight: 300;
	}

	.xl\:font-normal {
		font-weight: 400;
	}

	.xl\:font-medium {
		font-weight: 500;
	}

	.xl\:font-semibold {
		font-weight: 600;
	}

	.xl\:font-bold {
		font-weight: 700;
	}

	.xl\:font-extrabold {
		font-weight: 800;
	}

	.xl\:font-black {
		font-weight: 900;
	}

	.xl\:hover\:font-100:hover {
		font-weight: 100;
	}

	.xl\:hover\:font-200:hover {
		font-weight: 200;
	}

	.xl\:hover\:font-300:hover {
		font-weight: 300;
	}

	.xl\:hover\:font-400:hover {
		font-weight: 400;
	}

	.xl\:hover\:font-500:hover {
		font-weight: 500;
	}

	.xl\:hover\:font-600:hover {
		font-weight: 600;
	}

	.xl\:hover\:font-700:hover {
		font-weight: 700;
	}

	.xl\:hover\:font-800:hover {
		font-weight: 800;
	}

	.xl\:hover\:font-900:hover {
		font-weight: 900;
	}

	.xl\:hover\:font-hairline:hover {
		font-weight: 100;
	}

	.xl\:hover\:font-thin:hover {
		font-weight: 200;
	}

	.xl\:hover\:font-light:hover {
		font-weight: 300;
	}

	.xl\:hover\:font-normal:hover {
		font-weight: 400;
	}

	.xl\:hover\:font-medium:hover {
		font-weight: 500;
	}

	.xl\:hover\:font-semibold:hover {
		font-weight: 600;
	}

	.xl\:hover\:font-bold:hover {
		font-weight: 700;
	}

	.xl\:hover\:font-extrabold:hover {
		font-weight: 800;
	}

	.xl\:hover\:font-black:hover {
		font-weight: 900;
	}

	.xl\:focus\:font-100:focus {
		font-weight: 100;
	}

	.xl\:focus\:font-200:focus {
		font-weight: 200;
	}

	.xl\:focus\:font-300:focus {
		font-weight: 300;
	}

	.xl\:focus\:font-400:focus {
		font-weight: 400;
	}

	.xl\:focus\:font-500:focus {
		font-weight: 500;
	}

	.xl\:focus\:font-600:focus {
		font-weight: 600;
	}

	.xl\:focus\:font-700:focus {
		font-weight: 700;
	}

	.xl\:focus\:font-800:focus {
		font-weight: 800;
	}

	.xl\:focus\:font-900:focus {
		font-weight: 900;
	}

	.xl\:focus\:font-hairline:focus {
		font-weight: 100;
	}

	.xl\:focus\:font-thin:focus {
		font-weight: 200;
	}

	.xl\:focus\:font-light:focus {
		font-weight: 300;
	}

	.xl\:focus\:font-normal:focus {
		font-weight: 400;
	}

	.xl\:focus\:font-medium:focus {
		font-weight: 500;
	}

	.xl\:focus\:font-semibold:focus {
		font-weight: 600;
	}

	.xl\:focus\:font-bold:focus {
		font-weight: 700;
	}

	.xl\:focus\:font-extrabold:focus {
		font-weight: 800;
	}

	.xl\:focus\:font-black:focus {
		font-weight: 900;
	}

	.xl\:h-0 {
		height: 0;
	}

	.xl\:h-1 {
		height: 0.25rem;
	}

	.xl\:h-2 {
		height: 0.5rem;
	}

	.xl\:h-3 {
		height: 0.75rem;
	}

	.xl\:h-4 {
		height: 0.4rem;
	}

	.xl\:h-5 {
		height: 1.25rem;
	}

	.xl\:h-6 {
		height: 0.6rem;
	}

	.xl\:h-8 {
		height: 0.8rem;
	}

	.xl\:h-10 {
		height: 2.5rem;
	}

	.xl\:h-12 {
		height: 1.2rem;
	}

	.xl\:h-16 {
		height: 1.6rem;
	}

	.xl\:h-20 {
		height: 2rem;
	}

	.xl\:h-24 {
		height: 2.4rem;
	}

	.xl\:h-28 {
		height: 2.8rem;
	}

	.xl\:h-32 {
		height: 3.2rem;
	}

	.xl\:h-36 {
		height: 3.6rem;
	}

	.xl\:h-40 {
		height: 4rem;
	}

	.xl\:h-44 {
		height: 4.4rem;
	}

	.xl\:h-48 {
		height: 4.8rem;
	}

	.xl\:h-52 {
		height: 5.2rem;
	}

	.xl\:h-56 {
		height: 5.6rem;
	}

	.xl\:h-60 {
		height: 6rem;
	}

	.xl\:h-64 {
		height: 6.4rem;
	}

	.xl\:h-68 {
		height: 6.8rem;
	}

	.xl\:h-72 {
		height: 7.2rem;
	}

	.xl\:h-76 {
		height: 7.6rem;
	}

	.xl\:h-80 {
		height: 8rem;
	}

	.xl\:h-84 {
		height: 8.4rem;
	}

	.xl\:h-88 {
		height: 8.8rem;
	}

	.xl\:h-92 {
		height: 9.2rem;
	}

	.xl\:h-96 {
		height: 9.6rem;
	}

	.xl\:h-128 {
		height: 12.8rem;
	}

	.xl\:h-136 {
		height: 13.6rem;
	}

	.xl\:h-160 {
		height: 16rem;
	}

	.xl\:h-192 {
		height: 19.2rem;
	}

	.xl\:h-200 {
		height: 20rem;
	}

	.xl\:h-208 {
		height: 20.8rem;
	}

	.xl\:h-216 {
		height: 21.6rem;
	}

	.xl\:h-224 {
		height: 22.4rem;
	}

	.xl\:h-256 {
		height: 25.6rem;
	}

	.xl\:h-288 {
		height: 28.8rem;
	}

	.xl\:h-320 {
		height: 32rem;
	}

	.xl\:h-360 {
		height: 36rem;
	}

	.xl\:h-384 {
		height: 38.4rem;
	}

	.xl\:h-400 {
		height: 40rem;
	}

	.xl\:h-512 {
		height: 51.2rem;
	}

	.xl\:h-640 {
		height: 64rem;
	}

	.xl\:h-auto {
		height: auto;
	}

	.xl\:h-px {
		height: 1px;
	}

	.xl\:h-xs {
		height: 32rem;
	}

	.xl\:h-sm {
		height: 48rem;
	}

	.xl\:h-md {
		height: 64rem;
	}

	.xl\:h-lg {
		height: 80rem;
	}

	.xl\:h-xl {
		height: 96rem;
	}

	.xl\:h-2xl {
		height: 112rem;
	}

	.xl\:h-3xl {
		height: 128rem;
	}

	.xl\:h-4xl {
		height: 144rem;
	}

	.xl\:h-5xl {
		height: 160rem;
	}

	.xl\:h-full {
		height: 100%;
	}

	.xl\:h-screen {
		height: 100vh;
	}

	.xl\:leading-none {
		line-height: 1;
	}

	.xl\:leading-tight {
		line-height: 1.25;
	}

	.xl\:leading-snug {
		line-height: 1.375;
	}

	.xl\:leading-normal {
		line-height: 1.5;
	}

	.xl\:leading-relaxed {
		line-height: 1.625;
	}

	.xl\:leading-loose {
		line-height: 2;
	}

	.xl\:list-inside {
		list-style-position: inside;
	}

	.xl\:list-outside {
		list-style-position: outside;
	}

	.xl\:list-none {
		list-style-type: none;
	}

	.xl\:list-disc {
		list-style-type: disc;
	}

	.xl\:list-decimal {
		list-style-type: decimal;
	}

	.xl\:m-0 {
		margin: 0;
	}

	.xl\:m-1 {
		margin: 0.25rem;
	}

	.xl\:m-2 {
		margin: 0.5rem;
	}

	.xl\:m-3 {
		margin: 0.75rem;
	}

	.xl\:m-4 {
		margin: 0.4rem;
	}

	.xl\:m-5 {
		margin: 1.25rem;
	}

	.xl\:m-6 {
		margin: 0.6rem;
	}

	.xl\:m-8 {
		margin: 0.8rem;
	}

	.xl\:m-10 {
		margin: 2.5rem;
	}

	.xl\:m-12 {
		margin: 1.2rem;
	}

	.xl\:m-16 {
		margin: 1.6rem;
	}

	.xl\:m-20 {
		margin: 2rem;
	}

	.xl\:m-24 {
		margin: 2.4rem;
	}

	.xl\:m-28 {
		margin: 2.8rem;
	}

	.xl\:m-32 {
		margin: 3.2rem;
	}

	.xl\:m-36 {
		margin: 3.6rem;
	}

	.xl\:m-40 {
		margin: 4rem;
	}

	.xl\:m-44 {
		margin: 4.4rem;
	}

	.xl\:m-48 {
		margin: 4.8rem;
	}

	.xl\:m-52 {
		margin: 5.2rem;
	}

	.xl\:m-56 {
		margin: 5.6rem;
	}

	.xl\:m-60 {
		margin: 6rem;
	}

	.xl\:m-64 {
		margin: 6.4rem;
	}

	.xl\:m-68 {
		margin: 6.8rem;
	}

	.xl\:m-72 {
		margin: 7.2rem;
	}

	.xl\:m-76 {
		margin: 7.6rem;
	}

	.xl\:m-80 {
		margin: 8rem;
	}

	.xl\:m-84 {
		margin: 8.4rem;
	}

	.xl\:m-88 {
		margin: 8.8rem;
	}

	.xl\:m-92 {
		margin: 9.2rem;
	}

	.xl\:m-96 {
		margin: 9.6rem;
	}

	.xl\:m-128 {
		margin: 12.8rem;
	}

	.xl\:m-136 {
		margin: 13.6rem;
	}

	.xl\:m-160 {
		margin: 16rem;
	}

	.xl\:m-192 {
		margin: 19.2rem;
	}

	.xl\:m-200 {
		margin: 20rem;
	}

	.xl\:m-208 {
		margin: 20.8rem;
	}

	.xl\:m-216 {
		margin: 21.6rem;
	}

	.xl\:m-224 {
		margin: 22.4rem;
	}

	.xl\:m-256 {
		margin: 25.6rem;
	}

	.xl\:m-288 {
		margin: 28.8rem;
	}

	.xl\:m-320 {
		margin: 32rem;
	}

	.xl\:m-360 {
		margin: 36rem;
	}

	.xl\:m-384 {
		margin: 38.4rem;
	}

	.xl\:m-400 {
		margin: 40rem;
	}

	.xl\:m-512 {
		margin: 51.2rem;
	}

	.xl\:m-640 {
		margin: 64rem;
	}

	.xl\:m-auto {
		margin: auto;
	}

	.xl\:m-px {
		margin: 1px;
	}

	.xl\:m-xs {
		margin: 32rem;
	}

	.xl\:m-sm {
		margin: 48rem;
	}

	.xl\:m-md {
		margin: 64rem;
	}

	.xl\:m-lg {
		margin: 80rem;
	}

	.xl\:m-xl {
		margin: 96rem;
	}

	.xl\:m-2xl {
		margin: 112rem;
	}

	.xl\:m-3xl {
		margin: 128rem;
	}

	.xl\:m-4xl {
		margin: 144rem;
	}

	.xl\:m-5xl {
		margin: 160rem;
	}

	.xl\:-m-1 {
		margin: -0.25rem;
	}

	.xl\:-m-2 {
		margin: -0.5rem;
	}

	.xl\:-m-3 {
		margin: -0.75rem;
	}

	.xl\:-m-4 {
		margin: -0.4rem;
	}

	.xl\:-m-5 {
		margin: -1.25rem;
	}

	.xl\:-m-6 {
		margin: -0.6rem;
	}

	.xl\:-m-8 {
		margin: -0.8rem;
	}

	.xl\:-m-10 {
		margin: -2.5rem;
	}

	.xl\:-m-12 {
		margin: -1.2rem;
	}

	.xl\:-m-16 {
		margin: -1.6rem;
	}

	.xl\:-m-20 {
		margin: -2rem;
	}

	.xl\:-m-24 {
		margin: -2.4rem;
	}

	.xl\:-m-28 {
		margin: -2.8rem;
	}

	.xl\:-m-32 {
		margin: -3.2rem;
	}

	.xl\:-m-36 {
		margin: -3.6rem;
	}

	.xl\:-m-40 {
		margin: -4rem;
	}

	.xl\:-m-44 {
		margin: -4.4rem;
	}

	.xl\:-m-48 {
		margin: -4.8rem;
	}

	.xl\:-m-52 {
		margin: -5.2rem;
	}

	.xl\:-m-56 {
		margin: -5.6rem;
	}

	.xl\:-m-60 {
		margin: -6rem;
	}

	.xl\:-m-64 {
		margin: -6.4rem;
	}

	.xl\:-m-68 {
		margin: -6.8rem;
	}

	.xl\:-m-72 {
		margin: -7.2rem;
	}

	.xl\:-m-76 {
		margin: -7.6rem;
	}

	.xl\:-m-80 {
		margin: -8rem;
	}

	.xl\:-m-84 {
		margin: -8.4rem;
	}

	.xl\:-m-88 {
		margin: -8.8rem;
	}

	.xl\:-m-92 {
		margin: -9.2rem;
	}

	.xl\:-m-96 {
		margin: -9.6rem;
	}

	.xl\:-m-128 {
		margin: -12.8rem;
	}

	.xl\:-m-136 {
		margin: -13.6rem;
	}

	.xl\:-m-160 {
		margin: -16rem;
	}

	.xl\:-m-192 {
		margin: -19.2rem;
	}

	.xl\:-m-200 {
		margin: -20rem;
	}

	.xl\:-m-208 {
		margin: -20.8rem;
	}

	.xl\:-m-216 {
		margin: -21.6rem;
	}

	.xl\:-m-224 {
		margin: -22.4rem;
	}

	.xl\:-m-256 {
		margin: -25.6rem;
	}

	.xl\:-m-288 {
		margin: -28.8rem;
	}

	.xl\:-m-320 {
		margin: -32rem;
	}

	.xl\:-m-360 {
		margin: -36rem;
	}

	.xl\:-m-384 {
		margin: -38.4rem;
	}

	.xl\:-m-400 {
		margin: -40rem;
	}

	.xl\:-m-512 {
		margin: -51.2rem;
	}

	.xl\:-m-640 {
		margin: -64rem;
	}

	.xl\:-m-px {
		margin: -1px;
	}

	.xl\:-m-xs {
		margin: -32rem;
	}

	.xl\:-m-sm {
		margin: -48rem;
	}

	.xl\:-m-md {
		margin: -64rem;
	}

	.xl\:-m-lg {
		margin: -80rem;
	}

	.xl\:-m-xl {
		margin: -96rem;
	}

	.xl\:-m-2xl {
		margin: -112rem;
	}

	.xl\:-m-3xl {
		margin: -128rem;
	}

	.xl\:-m-4xl {
		margin: -144rem;
	}

	.xl\:-m-5xl {
		margin: -160rem;
	}

	.xl\:-m-auto {
		margin: -auto;
	}

	.xl\:my-0 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.xl\:mx-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.xl\:my-1 {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.xl\:mx-1 {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.xl\:my-2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.xl\:mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.xl\:my-3 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.xl\:mx-3 {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.xl\:my-4 {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.xl\:mx-4 {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}

	.xl\:my-5 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.xl\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.xl\:my-6 {
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}

	.xl\:mx-6 {
		margin-left: 0.6rem;
		margin-right: 0.6rem;
	}

	.xl\:my-8 {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}

	.xl\:mx-8 {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}

	.xl\:my-10 {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.xl\:mx-10 {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.xl\:my-12 {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.xl\:mx-12 {
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.xl\:my-16 {
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.xl\:mx-16 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.xl\:my-20 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.xl\:mx-20 {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.xl\:my-24 {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	.xl\:mx-24 {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	.xl\:my-28 {
		margin-top: 2.8rem;
		margin-bottom: 2.8rem;
	}

	.xl\:mx-28 {
		margin-left: 2.8rem;
		margin-right: 2.8rem;
	}

	.xl\:my-32 {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}

	.xl\:mx-32 {
		margin-left: 3.2rem;
		margin-right: 3.2rem;
	}

	.xl\:my-36 {
		margin-top: 3.6rem;
		margin-bottom: 3.6rem;
	}

	.xl\:mx-36 {
		margin-left: 3.6rem;
		margin-right: 3.6rem;
	}

	.xl\:my-40 {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.xl\:mx-40 {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.xl\:my-44 {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	.xl\:mx-44 {
		margin-left: 4.4rem;
		margin-right: 4.4rem;
	}

	.xl\:my-48 {
		margin-top: 4.8rem;
		margin-bottom: 4.8rem;
	}

	.xl\:mx-48 {
		margin-left: 4.8rem;
		margin-right: 4.8rem;
	}

	.xl\:my-52 {
		margin-top: 5.2rem;
		margin-bottom: 5.2rem;
	}

	.xl\:mx-52 {
		margin-left: 5.2rem;
		margin-right: 5.2rem;
	}

	.xl\:my-56 {
		margin-top: 5.6rem;
		margin-bottom: 5.6rem;
	}

	.xl\:mx-56 {
		margin-left: 5.6rem;
		margin-right: 5.6rem;
	}

	.xl\:my-60 {
		margin-top: 6rem;
		margin-bottom: 6rem;
	}

	.xl\:mx-60 {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	.xl\:my-64 {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	.xl\:mx-64 {
		margin-left: 6.4rem;
		margin-right: 6.4rem;
	}

	.xl\:my-68 {
		margin-top: 6.8rem;
		margin-bottom: 6.8rem;
	}

	.xl\:mx-68 {
		margin-left: 6.8rem;
		margin-right: 6.8rem;
	}

	.xl\:my-72 {
		margin-top: 7.2rem;
		margin-bottom: 7.2rem;
	}

	.xl\:mx-72 {
		margin-left: 7.2rem;
		margin-right: 7.2rem;
	}

	.xl\:my-76 {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.xl\:mx-76 {
		margin-left: 7.6rem;
		margin-right: 7.6rem;
	}

	.xl\:my-80 {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}

	.xl\:mx-80 {
		margin-left: 8rem;
		margin-right: 8rem;
	}

	.xl\:my-84 {
		margin-top: 8.4rem;
		margin-bottom: 8.4rem;
	}

	.xl\:mx-84 {
		margin-left: 8.4rem;
		margin-right: 8.4rem;
	}

	.xl\:my-88 {
		margin-top: 8.8rem;
		margin-bottom: 8.8rem;
	}

	.xl\:mx-88 {
		margin-left: 8.8rem;
		margin-right: 8.8rem;
	}

	.xl\:my-92 {
		margin-top: 9.2rem;
		margin-bottom: 9.2rem;
	}

	.xl\:mx-92 {
		margin-left: 9.2rem;
		margin-right: 9.2rem;
	}

	.xl\:my-96 {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}

	.xl\:mx-96 {
		margin-left: 9.6rem;
		margin-right: 9.6rem;
	}

	.xl\:my-128 {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}

	.xl\:mx-128 {
		margin-left: 12.8rem;
		margin-right: 12.8rem;
	}

	.xl\:my-136 {
		margin-top: 13.6rem;
		margin-bottom: 13.6rem;
	}

	.xl\:mx-136 {
		margin-left: 13.6rem;
		margin-right: 13.6rem;
	}

	.xl\:my-160 {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}

	.xl\:mx-160 {
		margin-left: 16rem;
		margin-right: 16rem;
	}

	.xl\:my-192 {
		margin-top: 19.2rem;
		margin-bottom: 19.2rem;
	}

	.xl\:mx-192 {
		margin-left: 19.2rem;
		margin-right: 19.2rem;
	}

	.xl\:my-200 {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.xl\:mx-200 {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.xl\:my-208 {
		margin-top: 20.8rem;
		margin-bottom: 20.8rem;
	}

	.xl\:mx-208 {
		margin-left: 20.8rem;
		margin-right: 20.8rem;
	}

	.xl\:my-216 {
		margin-top: 21.6rem;
		margin-bottom: 21.6rem;
	}

	.xl\:mx-216 {
		margin-left: 21.6rem;
		margin-right: 21.6rem;
	}

	.xl\:my-224 {
		margin-top: 22.4rem;
		margin-bottom: 22.4rem;
	}

	.xl\:mx-224 {
		margin-left: 22.4rem;
		margin-right: 22.4rem;
	}

	.xl\:my-256 {
		margin-top: 25.6rem;
		margin-bottom: 25.6rem;
	}

	.xl\:mx-256 {
		margin-left: 25.6rem;
		margin-right: 25.6rem;
	}

	.xl\:my-288 {
		margin-top: 28.8rem;
		margin-bottom: 28.8rem;
	}

	.xl\:mx-288 {
		margin-left: 28.8rem;
		margin-right: 28.8rem;
	}

	.xl\:my-320 {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.xl\:mx-320 {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.xl\:my-360 {
		margin-top: 36rem;
		margin-bottom: 36rem;
	}

	.xl\:mx-360 {
		margin-left: 36rem;
		margin-right: 36rem;
	}

	.xl\:my-384 {
		margin-top: 38.4rem;
		margin-bottom: 38.4rem;
	}

	.xl\:mx-384 {
		margin-left: 38.4rem;
		margin-right: 38.4rem;
	}

	.xl\:my-400 {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.xl\:mx-400 {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.xl\:my-512 {
		margin-top: 51.2rem;
		margin-bottom: 51.2rem;
	}

	.xl\:mx-512 {
		margin-left: 51.2rem;
		margin-right: 51.2rem;
	}

	.xl\:my-640 {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.xl\:mx-640 {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.xl\:my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.xl\:mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.xl\:my-px {
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.xl\:mx-px {
		margin-left: 1px;
		margin-right: 1px;
	}

	.xl\:my-xs {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.xl\:mx-xs {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.xl\:my-sm {
		margin-top: 48rem;
		margin-bottom: 48rem;
	}

	.xl\:mx-sm {
		margin-left: 48rem;
		margin-right: 48rem;
	}

	.xl\:my-md {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.xl\:mx-md {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.xl\:my-lg {
		margin-top: 80rem;
		margin-bottom: 80rem;
	}

	.xl\:mx-lg {
		margin-left: 80rem;
		margin-right: 80rem;
	}

	.xl\:my-xl {
		margin-top: 96rem;
		margin-bottom: 96rem;
	}

	.xl\:mx-xl {
		margin-left: 96rem;
		margin-right: 96rem;
	}

	.xl\:my-2xl {
		margin-top: 112rem;
		margin-bottom: 112rem;
	}

	.xl\:mx-2xl {
		margin-left: 112rem;
		margin-right: 112rem;
	}

	.xl\:my-3xl {
		margin-top: 128rem;
		margin-bottom: 128rem;
	}

	.xl\:mx-3xl {
		margin-left: 128rem;
		margin-right: 128rem;
	}

	.xl\:my-4xl {
		margin-top: 144rem;
		margin-bottom: 144rem;
	}

	.xl\:mx-4xl {
		margin-left: 144rem;
		margin-right: 144rem;
	}

	.xl\:my-5xl {
		margin-top: 160rem;
		margin-bottom: 160rem;
	}

	.xl\:mx-5xl {
		margin-left: 160rem;
		margin-right: 160rem;
	}

	.xl\:-my-1 {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}

	.xl\:-mx-1 {
		margin-left: -0.25rem;
		margin-right: -0.25rem;
	}

	.xl\:-my-2 {
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
	}

	.xl\:-mx-2 {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	.xl\:-my-3 {
		margin-top: -0.75rem;
		margin-bottom: -0.75rem;
	}

	.xl\:-mx-3 {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}

	.xl\:-my-4 {
		margin-top: -0.4rem;
		margin-bottom: -0.4rem;
	}

	.xl\:-mx-4 {
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.xl\:-my-5 {
		margin-top: -1.25rem;
		margin-bottom: -1.25rem;
	}

	.xl\:-mx-5 {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	}

	.xl\:-my-6 {
		margin-top: -0.6rem;
		margin-bottom: -0.6rem;
	}

	.xl\:-mx-6 {
		margin-left: -0.6rem;
		margin-right: -0.6rem;
	}

	.xl\:-my-8 {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}

	.xl\:-mx-8 {
		margin-left: -0.8rem;
		margin-right: -0.8rem;
	}

	.xl\:-my-10 {
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.xl\:-mx-10 {
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}

	.xl\:-my-12 {
		margin-top: -1.2rem;
		margin-bottom: -1.2rem;
	}

	.xl\:-mx-12 {
		margin-left: -1.2rem;
		margin-right: -1.2rem;
	}

	.xl\:-my-16 {
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	.xl\:-mx-16 {
		margin-left: -1.6rem;
		margin-right: -1.6rem;
	}

	.xl\:-my-20 {
		margin-top: -2rem;
		margin-bottom: -2rem;
	}

	.xl\:-mx-20 {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.xl\:-my-24 {
		margin-top: -2.4rem;
		margin-bottom: -2.4rem;
	}

	.xl\:-mx-24 {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}

	.xl\:-my-28 {
		margin-top: -2.8rem;
		margin-bottom: -2.8rem;
	}

	.xl\:-mx-28 {
		margin-left: -2.8rem;
		margin-right: -2.8rem;
	}

	.xl\:-my-32 {
		margin-top: -3.2rem;
		margin-bottom: -3.2rem;
	}

	.xl\:-mx-32 {
		margin-left: -3.2rem;
		margin-right: -3.2rem;
	}

	.xl\:-my-36 {
		margin-top: -3.6rem;
		margin-bottom: -3.6rem;
	}

	.xl\:-mx-36 {
		margin-left: -3.6rem;
		margin-right: -3.6rem;
	}

	.xl\:-my-40 {
		margin-top: -4rem;
		margin-bottom: -4rem;
	}

	.xl\:-mx-40 {
		margin-left: -4rem;
		margin-right: -4rem;
	}

	.xl\:-my-44 {
		margin-top: -4.4rem;
		margin-bottom: -4.4rem;
	}

	.xl\:-mx-44 {
		margin-left: -4.4rem;
		margin-right: -4.4rem;
	}

	.xl\:-my-48 {
		margin-top: -4.8rem;
		margin-bottom: -4.8rem;
	}

	.xl\:-mx-48 {
		margin-left: -4.8rem;
		margin-right: -4.8rem;
	}

	.xl\:-my-52 {
		margin-top: -5.2rem;
		margin-bottom: -5.2rem;
	}

	.xl\:-mx-52 {
		margin-left: -5.2rem;
		margin-right: -5.2rem;
	}

	.xl\:-my-56 {
		margin-top: -5.6rem;
		margin-bottom: -5.6rem;
	}

	.xl\:-mx-56 {
		margin-left: -5.6rem;
		margin-right: -5.6rem;
	}

	.xl\:-my-60 {
		margin-top: -6rem;
		margin-bottom: -6rem;
	}

	.xl\:-mx-60 {
		margin-left: -6rem;
		margin-right: -6rem;
	}

	.xl\:-my-64 {
		margin-top: -6.4rem;
		margin-bottom: -6.4rem;
	}

	.xl\:-mx-64 {
		margin-left: -6.4rem;
		margin-right: -6.4rem;
	}

	.xl\:-my-68 {
		margin-top: -6.8rem;
		margin-bottom: -6.8rem;
	}

	.xl\:-mx-68 {
		margin-left: -6.8rem;
		margin-right: -6.8rem;
	}

	.xl\:-my-72 {
		margin-top: -7.2rem;
		margin-bottom: -7.2rem;
	}

	.xl\:-mx-72 {
		margin-left: -7.2rem;
		margin-right: -7.2rem;
	}

	.xl\:-my-76 {
		margin-top: -7.6rem;
		margin-bottom: -7.6rem;
	}

	.xl\:-mx-76 {
		margin-left: -7.6rem;
		margin-right: -7.6rem;
	}

	.xl\:-my-80 {
		margin-top: -8rem;
		margin-bottom: -8rem;
	}

	.xl\:-mx-80 {
		margin-left: -8rem;
		margin-right: -8rem;
	}

	.xl\:-my-84 {
		margin-top: -8.4rem;
		margin-bottom: -8.4rem;
	}

	.xl\:-mx-84 {
		margin-left: -8.4rem;
		margin-right: -8.4rem;
	}

	.xl\:-my-88 {
		margin-top: -8.8rem;
		margin-bottom: -8.8rem;
	}

	.xl\:-mx-88 {
		margin-left: -8.8rem;
		margin-right: -8.8rem;
	}

	.xl\:-my-92 {
		margin-top: -9.2rem;
		margin-bottom: -9.2rem;
	}

	.xl\:-mx-92 {
		margin-left: -9.2rem;
		margin-right: -9.2rem;
	}

	.xl\:-my-96 {
		margin-top: -9.6rem;
		margin-bottom: -9.6rem;
	}

	.xl\:-mx-96 {
		margin-left: -9.6rem;
		margin-right: -9.6rem;
	}

	.xl\:-my-128 {
		margin-top: -12.8rem;
		margin-bottom: -12.8rem;
	}

	.xl\:-mx-128 {
		margin-left: -12.8rem;
		margin-right: -12.8rem;
	}

	.xl\:-my-136 {
		margin-top: -13.6rem;
		margin-bottom: -13.6rem;
	}

	.xl\:-mx-136 {
		margin-left: -13.6rem;
		margin-right: -13.6rem;
	}

	.xl\:-my-160 {
		margin-top: -16rem;
		margin-bottom: -16rem;
	}

	.xl\:-mx-160 {
		margin-left: -16rem;
		margin-right: -16rem;
	}

	.xl\:-my-192 {
		margin-top: -19.2rem;
		margin-bottom: -19.2rem;
	}

	.xl\:-mx-192 {
		margin-left: -19.2rem;
		margin-right: -19.2rem;
	}

	.xl\:-my-200 {
		margin-top: -20rem;
		margin-bottom: -20rem;
	}

	.xl\:-mx-200 {
		margin-left: -20rem;
		margin-right: -20rem;
	}

	.xl\:-my-208 {
		margin-top: -20.8rem;
		margin-bottom: -20.8rem;
	}

	.xl\:-mx-208 {
		margin-left: -20.8rem;
		margin-right: -20.8rem;
	}

	.xl\:-my-216 {
		margin-top: -21.6rem;
		margin-bottom: -21.6rem;
	}

	.xl\:-mx-216 {
		margin-left: -21.6rem;
		margin-right: -21.6rem;
	}

	.xl\:-my-224 {
		margin-top: -22.4rem;
		margin-bottom: -22.4rem;
	}

	.xl\:-mx-224 {
		margin-left: -22.4rem;
		margin-right: -22.4rem;
	}

	.xl\:-my-256 {
		margin-top: -25.6rem;
		margin-bottom: -25.6rem;
	}

	.xl\:-mx-256 {
		margin-left: -25.6rem;
		margin-right: -25.6rem;
	}

	.xl\:-my-288 {
		margin-top: -28.8rem;
		margin-bottom: -28.8rem;
	}

	.xl\:-mx-288 {
		margin-left: -28.8rem;
		margin-right: -28.8rem;
	}

	.xl\:-my-320 {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.xl\:-mx-320 {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.xl\:-my-360 {
		margin-top: -36rem;
		margin-bottom: -36rem;
	}

	.xl\:-mx-360 {
		margin-left: -36rem;
		margin-right: -36rem;
	}

	.xl\:-my-384 {
		margin-top: -38.4rem;
		margin-bottom: -38.4rem;
	}

	.xl\:-mx-384 {
		margin-left: -38.4rem;
		margin-right: -38.4rem;
	}

	.xl\:-my-400 {
		margin-top: -40rem;
		margin-bottom: -40rem;
	}

	.xl\:-mx-400 {
		margin-left: -40rem;
		margin-right: -40rem;
	}

	.xl\:-my-512 {
		margin-top: -51.2rem;
		margin-bottom: -51.2rem;
	}

	.xl\:-mx-512 {
		margin-left: -51.2rem;
		margin-right: -51.2rem;
	}

	.xl\:-my-640 {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.xl\:-mx-640 {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.xl\:-my-px {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	.xl\:-mx-px {
		margin-left: -1px;
		margin-right: -1px;
	}

	.xl\:-my-xs {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.xl\:-mx-xs {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.xl\:-my-sm {
		margin-top: -48rem;
		margin-bottom: -48rem;
	}

	.xl\:-mx-sm {
		margin-left: -48rem;
		margin-right: -48rem;
	}

	.xl\:-my-md {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.xl\:-mx-md {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.xl\:-my-lg {
		margin-top: -80rem;
		margin-bottom: -80rem;
	}

	.xl\:-mx-lg {
		margin-left: -80rem;
		margin-right: -80rem;
	}

	.xl\:-my-xl {
		margin-top: -96rem;
		margin-bottom: -96rem;
	}

	.xl\:-mx-xl {
		margin-left: -96rem;
		margin-right: -96rem;
	}

	.xl\:-my-2xl {
		margin-top: -112rem;
		margin-bottom: -112rem;
	}

	.xl\:-mx-2xl {
		margin-left: -112rem;
		margin-right: -112rem;
	}

	.xl\:-my-3xl {
		margin-top: -128rem;
		margin-bottom: -128rem;
	}

	.xl\:-mx-3xl {
		margin-left: -128rem;
		margin-right: -128rem;
	}

	.xl\:-my-4xl {
		margin-top: -144rem;
		margin-bottom: -144rem;
	}

	.xl\:-mx-4xl {
		margin-left: -144rem;
		margin-right: -144rem;
	}

	.xl\:-my-5xl {
		margin-top: -160rem;
		margin-bottom: -160rem;
	}

	.xl\:-mx-5xl {
		margin-left: -160rem;
		margin-right: -160rem;
	}

	.xl\:-my-auto {
		margin-top: -auto;
		margin-bottom: -auto;
	}

	.xl\:-mx-auto {
		margin-left: -auto;
		margin-right: -auto;
	}

	.xl\:mt-0 {
		margin-top: 0;
	}

	.xl\:mr-0 {
		margin-right: 0;
	}

	.xl\:mb-0 {
		margin-bottom: 0;
	}

	.xl\:ml-0 {
		margin-left: 0;
	}

	.xl\:mt-1 {
		margin-top: 0.25rem;
	}

	.xl\:mr-1 {
		margin-right: 0.25rem;
	}

	.xl\:mb-1 {
		margin-bottom: 0.25rem;
	}

	.xl\:ml-1 {
		margin-left: 0.25rem;
	}

	.xl\:mt-2 {
		margin-top: 0.5rem;
	}

	.xl\:mr-2 {
		margin-right: 0.5rem;
	}

	.xl\:mb-2 {
		margin-bottom: 0.5rem;
	}

	.xl\:ml-2 {
		margin-left: 0.5rem;
	}

	.xl\:mt-3 {
		margin-top: 0.75rem;
	}

	.xl\:mr-3 {
		margin-right: 0.75rem;
	}

	.xl\:mb-3 {
		margin-bottom: 0.75rem;
	}

	.xl\:ml-3 {
		margin-left: 0.75rem;
	}

	.xl\:mt-4 {
		margin-top: 0.4rem;
	}

	.xl\:mr-4 {
		margin-right: 0.4rem;
	}

	.xl\:mb-4 {
		margin-bottom: 0.4rem;
	}

	.xl\:ml-4 {
		margin-left: 0.4rem;
	}

	.xl\:mt-5 {
		margin-top: 1.25rem;
	}

	.xl\:mr-5 {
		margin-right: 1.25rem;
	}

	.xl\:mb-5 {
		margin-bottom: 1.25rem;
	}

	.xl\:ml-5 {
		margin-left: 1.25rem;
	}

	.xl\:mt-6 {
		margin-top: 0.6rem;
	}

	.xl\:mr-6 {
		margin-right: 0.6rem;
	}

	.xl\:mb-6 {
		margin-bottom: 0.6rem;
	}

	.xl\:ml-6 {
		margin-left: 0.6rem;
	}

	.xl\:mt-8 {
		margin-top: 0.8rem;
	}

	.xl\:mr-8 {
		margin-right: 0.8rem;
	}

	.xl\:mb-8 {
		margin-bottom: 0.8rem;
	}

	.xl\:ml-8 {
		margin-left: 0.8rem;
	}

	.xl\:mt-10 {
		margin-top: 2.5rem;
	}

	.xl\:mr-10 {
		margin-right: 2.5rem;
	}

	.xl\:mb-10 {
		margin-bottom: 2.5rem;
	}

	.xl\:ml-10 {
		margin-left: 2.5rem;
	}

	.xl\:mt-12 {
		margin-top: 1.2rem;
	}

	.xl\:mr-12 {
		margin-right: 1.2rem;
	}

	.xl\:mb-12 {
		margin-bottom: 1.2rem;
	}

	.xl\:ml-12 {
		margin-left: 1.2rem;
	}

	.xl\:mt-16 {
		margin-top: 1.6rem;
	}

	.xl\:mr-16 {
		margin-right: 1.6rem;
	}

	.xl\:mb-16 {
		margin-bottom: 1.6rem;
	}

	.xl\:ml-16 {
		margin-left: 1.6rem;
	}

	.xl\:mt-20 {
		margin-top: 2rem;
	}

	.xl\:mr-20 {
		margin-right: 2rem;
	}

	.xl\:mb-20 {
		margin-bottom: 2rem;
	}

	.xl\:ml-20 {
		margin-left: 2rem;
	}

	.xl\:mt-24 {
		margin-top: 2.4rem;
	}

	.xl\:mr-24 {
		margin-right: 2.4rem;
	}

	.xl\:mb-24 {
		margin-bottom: 2.4rem;
	}

	.xl\:ml-24 {
		margin-left: 2.4rem;
	}

	.xl\:mt-28 {
		margin-top: 2.8rem;
	}

	.xl\:mr-28 {
		margin-right: 2.8rem;
	}

	.xl\:mb-28 {
		margin-bottom: 2.8rem;
	}

	.xl\:ml-28 {
		margin-left: 2.8rem;
	}

	.xl\:mt-32 {
		margin-top: 3.2rem;
	}

	.xl\:mr-32 {
		margin-right: 3.2rem;
	}

	.xl\:mb-32 {
		margin-bottom: 3.2rem;
	}

	.xl\:ml-32 {
		margin-left: 3.2rem;
	}

	.xl\:mt-36 {
		margin-top: 3.6rem;
	}

	.xl\:mr-36 {
		margin-right: 3.6rem;
	}

	.xl\:mb-36 {
		margin-bottom: 3.6rem;
	}

	.xl\:ml-36 {
		margin-left: 3.6rem;
	}

	.xl\:mt-40 {
		margin-top: 4rem;
	}

	.xl\:mr-40 {
		margin-right: 4rem;
	}

	.xl\:mb-40 {
		margin-bottom: 4rem;
	}

	.xl\:ml-40 {
		margin-left: 4rem;
	}

	.xl\:mt-44 {
		margin-top: 4.4rem;
	}

	.xl\:mr-44 {
		margin-right: 4.4rem;
	}

	.xl\:mb-44 {
		margin-bottom: 4.4rem;
	}

	.xl\:ml-44 {
		margin-left: 4.4rem;
	}

	.xl\:mt-48 {
		margin-top: 4.8rem;
	}

	.xl\:mr-48 {
		margin-right: 4.8rem;
	}

	.xl\:mb-48 {
		margin-bottom: 4.8rem;
	}

	.xl\:ml-48 {
		margin-left: 4.8rem;
	}

	.xl\:mt-52 {
		margin-top: 5.2rem;
	}

	.xl\:mr-52 {
		margin-right: 5.2rem;
	}

	.xl\:mb-52 {
		margin-bottom: 5.2rem;
	}

	.xl\:ml-52 {
		margin-left: 5.2rem;
	}

	.xl\:mt-56 {
		margin-top: 5.6rem;
	}

	.xl\:mr-56 {
		margin-right: 5.6rem;
	}

	.xl\:mb-56 {
		margin-bottom: 5.6rem;
	}

	.xl\:ml-56 {
		margin-left: 5.6rem;
	}

	.xl\:mt-60 {
		margin-top: 6rem;
	}

	.xl\:mr-60 {
		margin-right: 6rem;
	}

	.xl\:mb-60 {
		margin-bottom: 6rem;
	}

	.xl\:ml-60 {
		margin-left: 6rem;
	}

	.xl\:mt-64 {
		margin-top: 6.4rem;
	}

	.xl\:mr-64 {
		margin-right: 6.4rem;
	}

	.xl\:mb-64 {
		margin-bottom: 6.4rem;
	}

	.xl\:ml-64 {
		margin-left: 6.4rem;
	}

	.xl\:mt-68 {
		margin-top: 6.8rem;
	}

	.xl\:mr-68 {
		margin-right: 6.8rem;
	}

	.xl\:mb-68 {
		margin-bottom: 6.8rem;
	}

	.xl\:ml-68 {
		margin-left: 6.8rem;
	}

	.xl\:mt-72 {
		margin-top: 7.2rem;
	}

	.xl\:mr-72 {
		margin-right: 7.2rem;
	}

	.xl\:mb-72 {
		margin-bottom: 7.2rem;
	}

	.xl\:ml-72 {
		margin-left: 7.2rem;
	}

	.xl\:mt-76 {
		margin-top: 7.6rem;
	}

	.xl\:mr-76 {
		margin-right: 7.6rem;
	}

	.xl\:mb-76 {
		margin-bottom: 7.6rem;
	}

	.xl\:ml-76 {
		margin-left: 7.6rem;
	}

	.xl\:mt-80 {
		margin-top: 8rem;
	}

	.xl\:mr-80 {
		margin-right: 8rem;
	}

	.xl\:mb-80 {
		margin-bottom: 8rem;
	}

	.xl\:ml-80 {
		margin-left: 8rem;
	}

	.xl\:mt-84 {
		margin-top: 8.4rem;
	}

	.xl\:mr-84 {
		margin-right: 8.4rem;
	}

	.xl\:mb-84 {
		margin-bottom: 8.4rem;
	}

	.xl\:ml-84 {
		margin-left: 8.4rem;
	}

	.xl\:mt-88 {
		margin-top: 8.8rem;
	}

	.xl\:mr-88 {
		margin-right: 8.8rem;
	}

	.xl\:mb-88 {
		margin-bottom: 8.8rem;
	}

	.xl\:ml-88 {
		margin-left: 8.8rem;
	}

	.xl\:mt-92 {
		margin-top: 9.2rem;
	}

	.xl\:mr-92 {
		margin-right: 9.2rem;
	}

	.xl\:mb-92 {
		margin-bottom: 9.2rem;
	}

	.xl\:ml-92 {
		margin-left: 9.2rem;
	}

	.xl\:mt-96 {
		margin-top: 9.6rem;
	}

	.xl\:mr-96 {
		margin-right: 9.6rem;
	}

	.xl\:mb-96 {
		margin-bottom: 9.6rem;
	}

	.xl\:ml-96 {
		margin-left: 9.6rem;
	}

	.xl\:mt-128 {
		margin-top: 12.8rem;
	}

	.xl\:mr-128 {
		margin-right: 12.8rem;
	}

	.xl\:mb-128 {
		margin-bottom: 12.8rem;
	}

	.xl\:ml-128 {
		margin-left: 12.8rem;
	}

	.xl\:mt-136 {
		margin-top: 13.6rem;
	}

	.xl\:mr-136 {
		margin-right: 13.6rem;
	}

	.xl\:mb-136 {
		margin-bottom: 13.6rem;
	}

	.xl\:ml-136 {
		margin-left: 13.6rem;
	}

	.xl\:mt-160 {
		margin-top: 16rem;
	}

	.xl\:mr-160 {
		margin-right: 16rem;
	}

	.xl\:mb-160 {
		margin-bottom: 16rem;
	}

	.xl\:ml-160 {
		margin-left: 16rem;
	}

	.xl\:mt-192 {
		margin-top: 19.2rem;
	}

	.xl\:mr-192 {
		margin-right: 19.2rem;
	}

	.xl\:mb-192 {
		margin-bottom: 19.2rem;
	}

	.xl\:ml-192 {
		margin-left: 19.2rem;
	}

	.xl\:mt-200 {
		margin-top: 20rem;
	}

	.xl\:mr-200 {
		margin-right: 20rem;
	}

	.xl\:mb-200 {
		margin-bottom: 20rem;
	}

	.xl\:ml-200 {
		margin-left: 20rem;
	}

	.xl\:mt-208 {
		margin-top: 20.8rem;
	}

	.xl\:mr-208 {
		margin-right: 20.8rem;
	}

	.xl\:mb-208 {
		margin-bottom: 20.8rem;
	}

	.xl\:ml-208 {
		margin-left: 20.8rem;
	}

	.xl\:mt-216 {
		margin-top: 21.6rem;
	}

	.xl\:mr-216 {
		margin-right: 21.6rem;
	}

	.xl\:mb-216 {
		margin-bottom: 21.6rem;
	}

	.xl\:ml-216 {
		margin-left: 21.6rem;
	}

	.xl\:mt-224 {
		margin-top: 22.4rem;
	}

	.xl\:mr-224 {
		margin-right: 22.4rem;
	}

	.xl\:mb-224 {
		margin-bottom: 22.4rem;
	}

	.xl\:ml-224 {
		margin-left: 22.4rem;
	}

	.xl\:mt-256 {
		margin-top: 25.6rem;
	}

	.xl\:mr-256 {
		margin-right: 25.6rem;
	}

	.xl\:mb-256 {
		margin-bottom: 25.6rem;
	}

	.xl\:ml-256 {
		margin-left: 25.6rem;
	}

	.xl\:mt-288 {
		margin-top: 28.8rem;
	}

	.xl\:mr-288 {
		margin-right: 28.8rem;
	}

	.xl\:mb-288 {
		margin-bottom: 28.8rem;
	}

	.xl\:ml-288 {
		margin-left: 28.8rem;
	}

	.xl\:mt-320 {
		margin-top: 32rem;
	}

	.xl\:mr-320 {
		margin-right: 32rem;
	}

	.xl\:mb-320 {
		margin-bottom: 32rem;
	}

	.xl\:ml-320 {
		margin-left: 32rem;
	}

	.xl\:mt-360 {
		margin-top: 36rem;
	}

	.xl\:mr-360 {
		margin-right: 36rem;
	}

	.xl\:mb-360 {
		margin-bottom: 36rem;
	}

	.xl\:ml-360 {
		margin-left: 36rem;
	}

	.xl\:mt-384 {
		margin-top: 38.4rem;
	}

	.xl\:mr-384 {
		margin-right: 38.4rem;
	}

	.xl\:mb-384 {
		margin-bottom: 38.4rem;
	}

	.xl\:ml-384 {
		margin-left: 38.4rem;
	}

	.xl\:mt-400 {
		margin-top: 40rem;
	}

	.xl\:mr-400 {
		margin-right: 40rem;
	}

	.xl\:mb-400 {
		margin-bottom: 40rem;
	}

	.xl\:ml-400 {
		margin-left: 40rem;
	}

	.xl\:mt-512 {
		margin-top: 51.2rem;
	}

	.xl\:mr-512 {
		margin-right: 51.2rem;
	}

	.xl\:mb-512 {
		margin-bottom: 51.2rem;
	}

	.xl\:ml-512 {
		margin-left: 51.2rem;
	}

	.xl\:mt-640 {
		margin-top: 64rem;
	}

	.xl\:mr-640 {
		margin-right: 64rem;
	}

	.xl\:mb-640 {
		margin-bottom: 64rem;
	}

	.xl\:ml-640 {
		margin-left: 64rem;
	}

	.xl\:mt-auto {
		margin-top: auto;
	}

	.xl\:mr-auto {
		margin-right: auto;
	}

	.xl\:mb-auto {
		margin-bottom: auto;
	}

	.xl\:ml-auto {
		margin-left: auto;
	}

	.xl\:mt-px {
		margin-top: 1px;
	}

	.xl\:mr-px {
		margin-right: 1px;
	}

	.xl\:mb-px {
		margin-bottom: 1px;
	}

	.xl\:ml-px {
		margin-left: 1px;
	}

	.xl\:mt-xs {
		margin-top: 32rem;
	}

	.xl\:mr-xs {
		margin-right: 32rem;
	}

	.xl\:mb-xs {
		margin-bottom: 32rem;
	}

	.xl\:ml-xs {
		margin-left: 32rem;
	}

	.xl\:mt-sm {
		margin-top: 48rem;
	}

	.xl\:mr-sm {
		margin-right: 48rem;
	}

	.xl\:mb-sm {
		margin-bottom: 48rem;
	}

	.xl\:ml-sm {
		margin-left: 48rem;
	}

	.xl\:mt-md {
		margin-top: 64rem;
	}

	.xl\:mr-md {
		margin-right: 64rem;
	}

	.xl\:mb-md {
		margin-bottom: 64rem;
	}

	.xl\:ml-md {
		margin-left: 64rem;
	}

	.xl\:mt-lg {
		margin-top: 80rem;
	}

	.xl\:mr-lg {
		margin-right: 80rem;
	}

	.xl\:mb-lg {
		margin-bottom: 80rem;
	}

	.xl\:ml-lg {
		margin-left: 80rem;
	}

	.xl\:mt-xl {
		margin-top: 96rem;
	}

	.xl\:mr-xl {
		margin-right: 96rem;
	}

	.xl\:mb-xl {
		margin-bottom: 96rem;
	}

	.xl\:ml-xl {
		margin-left: 96rem;
	}

	.xl\:mt-2xl {
		margin-top: 112rem;
	}

	.xl\:mr-2xl {
		margin-right: 112rem;
	}

	.xl\:mb-2xl {
		margin-bottom: 112rem;
	}

	.xl\:ml-2xl {
		margin-left: 112rem;
	}

	.xl\:mt-3xl {
		margin-top: 128rem;
	}

	.xl\:mr-3xl {
		margin-right: 128rem;
	}

	.xl\:mb-3xl {
		margin-bottom: 128rem;
	}

	.xl\:ml-3xl {
		margin-left: 128rem;
	}

	.xl\:mt-4xl {
		margin-top: 144rem;
	}

	.xl\:mr-4xl {
		margin-right: 144rem;
	}

	.xl\:mb-4xl {
		margin-bottom: 144rem;
	}

	.xl\:ml-4xl {
		margin-left: 144rem;
	}

	.xl\:mt-5xl {
		margin-top: 160rem;
	}

	.xl\:mr-5xl {
		margin-right: 160rem;
	}

	.xl\:mb-5xl {
		margin-bottom: 160rem;
	}

	.xl\:ml-5xl {
		margin-left: 160rem;
	}

	.xl\:-mt-1 {
		margin-top: -0.25rem;
	}

	.xl\:-mr-1 {
		margin-right: -0.25rem;
	}

	.xl\:-mb-1 {
		margin-bottom: -0.25rem;
	}

	.xl\:-ml-1 {
		margin-left: -0.25rem;
	}

	.xl\:-mt-2 {
		margin-top: -0.5rem;
	}

	.xl\:-mr-2 {
		margin-right: -0.5rem;
	}

	.xl\:-mb-2 {
		margin-bottom: -0.5rem;
	}

	.xl\:-ml-2 {
		margin-left: -0.5rem;
	}

	.xl\:-mt-3 {
		margin-top: -0.75rem;
	}

	.xl\:-mr-3 {
		margin-right: -0.75rem;
	}

	.xl\:-mb-3 {
		margin-bottom: -0.75rem;
	}

	.xl\:-ml-3 {
		margin-left: -0.75rem;
	}

	.xl\:-mt-4 {
		margin-top: -0.4rem;
	}

	.xl\:-mr-4 {
		margin-right: -0.4rem;
	}

	.xl\:-mb-4 {
		margin-bottom: -0.4rem;
	}

	.xl\:-ml-4 {
		margin-left: -0.4rem;
	}

	.xl\:-mt-5 {
		margin-top: -1.25rem;
	}

	.xl\:-mr-5 {
		margin-right: -1.25rem;
	}

	.xl\:-mb-5 {
		margin-bottom: -1.25rem;
	}

	.xl\:-ml-5 {
		margin-left: -1.25rem;
	}

	.xl\:-mt-6 {
		margin-top: -0.6rem;
	}

	.xl\:-mr-6 {
		margin-right: -0.6rem;
	}

	.xl\:-mb-6 {
		margin-bottom: -0.6rem;
	}

	.xl\:-ml-6 {
		margin-left: -0.6rem;
	}

	.xl\:-mt-8 {
		margin-top: -0.8rem;
	}

	.xl\:-mr-8 {
		margin-right: -0.8rem;
	}

	.xl\:-mb-8 {
		margin-bottom: -0.8rem;
	}

	.xl\:-ml-8 {
		margin-left: -0.8rem;
	}

	.xl\:-mt-10 {
		margin-top: -2.5rem;
	}

	.xl\:-mr-10 {
		margin-right: -2.5rem;
	}

	.xl\:-mb-10 {
		margin-bottom: -2.5rem;
	}

	.xl\:-ml-10 {
		margin-left: -2.5rem;
	}

	.xl\:-mt-12 {
		margin-top: -1.2rem;
	}

	.xl\:-mr-12 {
		margin-right: -1.2rem;
	}

	.xl\:-mb-12 {
		margin-bottom: -1.2rem;
	}

	.xl\:-ml-12 {
		margin-left: -1.2rem;
	}

	.xl\:-mt-16 {
		margin-top: -1.6rem;
	}

	.xl\:-mr-16 {
		margin-right: -1.6rem;
	}

	.xl\:-mb-16 {
		margin-bottom: -1.6rem;
	}

	.xl\:-ml-16 {
		margin-left: -1.6rem;
	}

	.xl\:-mt-20 {
		margin-top: -2rem;
	}

	.xl\:-mr-20 {
		margin-right: -2rem;
	}

	.xl\:-mb-20 {
		margin-bottom: -2rem;
	}

	.xl\:-ml-20 {
		margin-left: -2rem;
	}

	.xl\:-mt-24 {
		margin-top: -2.4rem;
	}

	.xl\:-mr-24 {
		margin-right: -2.4rem;
	}

	.xl\:-mb-24 {
		margin-bottom: -2.4rem;
	}

	.xl\:-ml-24 {
		margin-left: -2.4rem;
	}

	.xl\:-mt-28 {
		margin-top: -2.8rem;
	}

	.xl\:-mr-28 {
		margin-right: -2.8rem;
	}

	.xl\:-mb-28 {
		margin-bottom: -2.8rem;
	}

	.xl\:-ml-28 {
		margin-left: -2.8rem;
	}

	.xl\:-mt-32 {
		margin-top: -3.2rem;
	}

	.xl\:-mr-32 {
		margin-right: -3.2rem;
	}

	.xl\:-mb-32 {
		margin-bottom: -3.2rem;
	}

	.xl\:-ml-32 {
		margin-left: -3.2rem;
	}

	.xl\:-mt-36 {
		margin-top: -3.6rem;
	}

	.xl\:-mr-36 {
		margin-right: -3.6rem;
	}

	.xl\:-mb-36 {
		margin-bottom: -3.6rem;
	}

	.xl\:-ml-36 {
		margin-left: -3.6rem;
	}

	.xl\:-mt-40 {
		margin-top: -4rem;
	}

	.xl\:-mr-40 {
		margin-right: -4rem;
	}

	.xl\:-mb-40 {
		margin-bottom: -4rem;
	}

	.xl\:-ml-40 {
		margin-left: -4rem;
	}

	.xl\:-mt-44 {
		margin-top: -4.4rem;
	}

	.xl\:-mr-44 {
		margin-right: -4.4rem;
	}

	.xl\:-mb-44 {
		margin-bottom: -4.4rem;
	}

	.xl\:-ml-44 {
		margin-left: -4.4rem;
	}

	.xl\:-mt-48 {
		margin-top: -4.8rem;
	}

	.xl\:-mr-48 {
		margin-right: -4.8rem;
	}

	.xl\:-mb-48 {
		margin-bottom: -4.8rem;
	}

	.xl\:-ml-48 {
		margin-left: -4.8rem;
	}

	.xl\:-mt-52 {
		margin-top: -5.2rem;
	}

	.xl\:-mr-52 {
		margin-right: -5.2rem;
	}

	.xl\:-mb-52 {
		margin-bottom: -5.2rem;
	}

	.xl\:-ml-52 {
		margin-left: -5.2rem;
	}

	.xl\:-mt-56 {
		margin-top: -5.6rem;
	}

	.xl\:-mr-56 {
		margin-right: -5.6rem;
	}

	.xl\:-mb-56 {
		margin-bottom: -5.6rem;
	}

	.xl\:-ml-56 {
		margin-left: -5.6rem;
	}

	.xl\:-mt-60 {
		margin-top: -6rem;
	}

	.xl\:-mr-60 {
		margin-right: -6rem;
	}

	.xl\:-mb-60 {
		margin-bottom: -6rem;
	}

	.xl\:-ml-60 {
		margin-left: -6rem;
	}

	.xl\:-mt-64 {
		margin-top: -6.4rem;
	}

	.xl\:-mr-64 {
		margin-right: -6.4rem;
	}

	.xl\:-mb-64 {
		margin-bottom: -6.4rem;
	}

	.xl\:-ml-64 {
		margin-left: -6.4rem;
	}

	.xl\:-mt-68 {
		margin-top: -6.8rem;
	}

	.xl\:-mr-68 {
		margin-right: -6.8rem;
	}

	.xl\:-mb-68 {
		margin-bottom: -6.8rem;
	}

	.xl\:-ml-68 {
		margin-left: -6.8rem;
	}

	.xl\:-mt-72 {
		margin-top: -7.2rem;
	}

	.xl\:-mr-72 {
		margin-right: -7.2rem;
	}

	.xl\:-mb-72 {
		margin-bottom: -7.2rem;
	}

	.xl\:-ml-72 {
		margin-left: -7.2rem;
	}

	.xl\:-mt-76 {
		margin-top: -7.6rem;
	}

	.xl\:-mr-76 {
		margin-right: -7.6rem;
	}

	.xl\:-mb-76 {
		margin-bottom: -7.6rem;
	}

	.xl\:-ml-76 {
		margin-left: -7.6rem;
	}

	.xl\:-mt-80 {
		margin-top: -8rem;
	}

	.xl\:-mr-80 {
		margin-right: -8rem;
	}

	.xl\:-mb-80 {
		margin-bottom: -8rem;
	}

	.xl\:-ml-80 {
		margin-left: -8rem;
	}

	.xl\:-mt-84 {
		margin-top: -8.4rem;
	}

	.xl\:-mr-84 {
		margin-right: -8.4rem;
	}

	.xl\:-mb-84 {
		margin-bottom: -8.4rem;
	}

	.xl\:-ml-84 {
		margin-left: -8.4rem;
	}

	.xl\:-mt-88 {
		margin-top: -8.8rem;
	}

	.xl\:-mr-88 {
		margin-right: -8.8rem;
	}

	.xl\:-mb-88 {
		margin-bottom: -8.8rem;
	}

	.xl\:-ml-88 {
		margin-left: -8.8rem;
	}

	.xl\:-mt-92 {
		margin-top: -9.2rem;
	}

	.xl\:-mr-92 {
		margin-right: -9.2rem;
	}

	.xl\:-mb-92 {
		margin-bottom: -9.2rem;
	}

	.xl\:-ml-92 {
		margin-left: -9.2rem;
	}

	.xl\:-mt-96 {
		margin-top: -9.6rem;
	}

	.xl\:-mr-96 {
		margin-right: -9.6rem;
	}

	.xl\:-mb-96 {
		margin-bottom: -9.6rem;
	}

	.xl\:-ml-96 {
		margin-left: -9.6rem;
	}

	.xl\:-mt-128 {
		margin-top: -12.8rem;
	}

	.xl\:-mr-128 {
		margin-right: -12.8rem;
	}

	.xl\:-mb-128 {
		margin-bottom: -12.8rem;
	}

	.xl\:-ml-128 {
		margin-left: -12.8rem;
	}

	.xl\:-mt-136 {
		margin-top: -13.6rem;
	}

	.xl\:-mr-136 {
		margin-right: -13.6rem;
	}

	.xl\:-mb-136 {
		margin-bottom: -13.6rem;
	}

	.xl\:-ml-136 {
		margin-left: -13.6rem;
	}

	.xl\:-mt-160 {
		margin-top: -16rem;
	}

	.xl\:-mr-160 {
		margin-right: -16rem;
	}

	.xl\:-mb-160 {
		margin-bottom: -16rem;
	}

	.xl\:-ml-160 {
		margin-left: -16rem;
	}

	.xl\:-mt-192 {
		margin-top: -19.2rem;
	}

	.xl\:-mr-192 {
		margin-right: -19.2rem;
	}

	.xl\:-mb-192 {
		margin-bottom: -19.2rem;
	}

	.xl\:-ml-192 {
		margin-left: -19.2rem;
	}

	.xl\:-mt-200 {
		margin-top: -20rem;
	}

	.xl\:-mr-200 {
		margin-right: -20rem;
	}

	.xl\:-mb-200 {
		margin-bottom: -20rem;
	}

	.xl\:-ml-200 {
		margin-left: -20rem;
	}

	.xl\:-mt-208 {
		margin-top: -20.8rem;
	}

	.xl\:-mr-208 {
		margin-right: -20.8rem;
	}

	.xl\:-mb-208 {
		margin-bottom: -20.8rem;
	}

	.xl\:-ml-208 {
		margin-left: -20.8rem;
	}

	.xl\:-mt-216 {
		margin-top: -21.6rem;
	}

	.xl\:-mr-216 {
		margin-right: -21.6rem;
	}

	.xl\:-mb-216 {
		margin-bottom: -21.6rem;
	}

	.xl\:-ml-216 {
		margin-left: -21.6rem;
	}

	.xl\:-mt-224 {
		margin-top: -22.4rem;
	}

	.xl\:-mr-224 {
		margin-right: -22.4rem;
	}

	.xl\:-mb-224 {
		margin-bottom: -22.4rem;
	}

	.xl\:-ml-224 {
		margin-left: -22.4rem;
	}

	.xl\:-mt-256 {
		margin-top: -25.6rem;
	}

	.xl\:-mr-256 {
		margin-right: -25.6rem;
	}

	.xl\:-mb-256 {
		margin-bottom: -25.6rem;
	}

	.xl\:-ml-256 {
		margin-left: -25.6rem;
	}

	.xl\:-mt-288 {
		margin-top: -28.8rem;
	}

	.xl\:-mr-288 {
		margin-right: -28.8rem;
	}

	.xl\:-mb-288 {
		margin-bottom: -28.8rem;
	}

	.xl\:-ml-288 {
		margin-left: -28.8rem;
	}

	.xl\:-mt-320 {
		margin-top: -32rem;
	}

	.xl\:-mr-320 {
		margin-right: -32rem;
	}

	.xl\:-mb-320 {
		margin-bottom: -32rem;
	}

	.xl\:-ml-320 {
		margin-left: -32rem;
	}

	.xl\:-mt-360 {
		margin-top: -36rem;
	}

	.xl\:-mr-360 {
		margin-right: -36rem;
	}

	.xl\:-mb-360 {
		margin-bottom: -36rem;
	}

	.xl\:-ml-360 {
		margin-left: -36rem;
	}

	.xl\:-mt-384 {
		margin-top: -38.4rem;
	}

	.xl\:-mr-384 {
		margin-right: -38.4rem;
	}

	.xl\:-mb-384 {
		margin-bottom: -38.4rem;
	}

	.xl\:-ml-384 {
		margin-left: -38.4rem;
	}

	.xl\:-mt-400 {
		margin-top: -40rem;
	}

	.xl\:-mr-400 {
		margin-right: -40rem;
	}

	.xl\:-mb-400 {
		margin-bottom: -40rem;
	}

	.xl\:-ml-400 {
		margin-left: -40rem;
	}

	.xl\:-mt-512 {
		margin-top: -51.2rem;
	}

	.xl\:-mr-512 {
		margin-right: -51.2rem;
	}

	.xl\:-mb-512 {
		margin-bottom: -51.2rem;
	}

	.xl\:-ml-512 {
		margin-left: -51.2rem;
	}

	.xl\:-mt-640 {
		margin-top: -64rem;
	}

	.xl\:-mr-640 {
		margin-right: -64rem;
	}

	.xl\:-mb-640 {
		margin-bottom: -64rem;
	}

	.xl\:-ml-640 {
		margin-left: -64rem;
	}

	.xl\:-mt-px {
		margin-top: -1px;
	}

	.xl\:-mr-px {
		margin-right: -1px;
	}

	.xl\:-mb-px {
		margin-bottom: -1px;
	}

	.xl\:-ml-px {
		margin-left: -1px;
	}

	.xl\:-mt-xs {
		margin-top: -32rem;
	}

	.xl\:-mr-xs {
		margin-right: -32rem;
	}

	.xl\:-mb-xs {
		margin-bottom: -32rem;
	}

	.xl\:-ml-xs {
		margin-left: -32rem;
	}

	.xl\:-mt-sm {
		margin-top: -48rem;
	}

	.xl\:-mr-sm {
		margin-right: -48rem;
	}

	.xl\:-mb-sm {
		margin-bottom: -48rem;
	}

	.xl\:-ml-sm {
		margin-left: -48rem;
	}

	.xl\:-mt-md {
		margin-top: -64rem;
	}

	.xl\:-mr-md {
		margin-right: -64rem;
	}

	.xl\:-mb-md {
		margin-bottom: -64rem;
	}

	.xl\:-ml-md {
		margin-left: -64rem;
	}

	.xl\:-mt-lg {
		margin-top: -80rem;
	}

	.xl\:-mr-lg {
		margin-right: -80rem;
	}

	.xl\:-mb-lg {
		margin-bottom: -80rem;
	}

	.xl\:-ml-lg {
		margin-left: -80rem;
	}

	.xl\:-mt-xl {
		margin-top: -96rem;
	}

	.xl\:-mr-xl {
		margin-right: -96rem;
	}

	.xl\:-mb-xl {
		margin-bottom: -96rem;
	}

	.xl\:-ml-xl {
		margin-left: -96rem;
	}

	.xl\:-mt-2xl {
		margin-top: -112rem;
	}

	.xl\:-mr-2xl {
		margin-right: -112rem;
	}

	.xl\:-mb-2xl {
		margin-bottom: -112rem;
	}

	.xl\:-ml-2xl {
		margin-left: -112rem;
	}

	.xl\:-mt-3xl {
		margin-top: -128rem;
	}

	.xl\:-mr-3xl {
		margin-right: -128rem;
	}

	.xl\:-mb-3xl {
		margin-bottom: -128rem;
	}

	.xl\:-ml-3xl {
		margin-left: -128rem;
	}

	.xl\:-mt-4xl {
		margin-top: -144rem;
	}

	.xl\:-mr-4xl {
		margin-right: -144rem;
	}

	.xl\:-mb-4xl {
		margin-bottom: -144rem;
	}

	.xl\:-ml-4xl {
		margin-left: -144rem;
	}

	.xl\:-mt-5xl {
		margin-top: -160rem;
	}

	.xl\:-mr-5xl {
		margin-right: -160rem;
	}

	.xl\:-mb-5xl {
		margin-bottom: -160rem;
	}

	.xl\:-ml-5xl {
		margin-left: -160rem;
	}

	.xl\:-mt-auto {
		margin-top: -auto;
	}

	.xl\:-mr-auto {
		margin-right: -auto;
	}

	.xl\:-mb-auto {
		margin-bottom: -auto;
	}

	.xl\:-ml-auto {
		margin-left: -auto;
	}

	.xl\:max-h-0 {
		max-height: 0;
	}

	.xl\:max-h-1 {
		max-height: 0.25rem;
	}

	.xl\:max-h-2 {
		max-height: 0.5rem;
	}

	.xl\:max-h-3 {
		max-height: 0.75rem;
	}

	.xl\:max-h-4 {
		max-height: 0.4rem;
	}

	.xl\:max-h-5 {
		max-height: 1.25rem;
	}

	.xl\:max-h-6 {
		max-height: 0.6rem;
	}

	.xl\:max-h-8 {
		max-height: 0.8rem;
	}

	.xl\:max-h-10 {
		max-height: 2.5rem;
	}

	.xl\:max-h-12 {
		max-height: 1.2rem;
	}

	.xl\:max-h-16 {
		max-height: 1.6rem;
	}

	.xl\:max-h-20 {
		max-height: 2rem;
	}

	.xl\:max-h-24 {
		max-height: 2.4rem;
	}

	.xl\:max-h-28 {
		max-height: 2.8rem;
	}

	.xl\:max-h-32 {
		max-height: 3.2rem;
	}

	.xl\:max-h-36 {
		max-height: 3.6rem;
	}

	.xl\:max-h-40 {
		max-height: 4rem;
	}

	.xl\:max-h-44 {
		max-height: 4.4rem;
	}

	.xl\:max-h-48 {
		max-height: 4.8rem;
	}

	.xl\:max-h-52 {
		max-height: 5.2rem;
	}

	.xl\:max-h-56 {
		max-height: 5.6rem;
	}

	.xl\:max-h-60 {
		max-height: 6rem;
	}

	.xl\:max-h-64 {
		max-height: 6.4rem;
	}

	.xl\:max-h-68 {
		max-height: 6.8rem;
	}

	.xl\:max-h-72 {
		max-height: 7.2rem;
	}

	.xl\:max-h-76 {
		max-height: 7.6rem;
	}

	.xl\:max-h-80 {
		max-height: 8rem;
	}

	.xl\:max-h-84 {
		max-height: 8.4rem;
	}

	.xl\:max-h-88 {
		max-height: 8.8rem;
	}

	.xl\:max-h-92 {
		max-height: 9.2rem;
	}

	.xl\:max-h-96 {
		max-height: 9.6rem;
	}

	.xl\:max-h-128 {
		max-height: 12.8rem;
	}

	.xl\:max-h-136 {
		max-height: 13.6rem;
	}

	.xl\:max-h-160 {
		max-height: 16rem;
	}

	.xl\:max-h-192 {
		max-height: 19.2rem;
	}

	.xl\:max-h-200 {
		max-height: 20rem;
	}

	.xl\:max-h-208 {
		max-height: 20.8rem;
	}

	.xl\:max-h-216 {
		max-height: 21.6rem;
	}

	.xl\:max-h-224 {
		max-height: 22.4rem;
	}

	.xl\:max-h-256 {
		max-height: 25.6rem;
	}

	.xl\:max-h-288 {
		max-height: 28.8rem;
	}

	.xl\:max-h-320 {
		max-height: 32rem;
	}

	.xl\:max-h-360 {
		max-height: 36rem;
	}

	.xl\:max-h-384 {
		max-height: 38.4rem;
	}

	.xl\:max-h-400 {
		max-height: 40rem;
	}

	.xl\:max-h-512 {
		max-height: 51.2rem;
	}

	.xl\:max-h-640 {
		max-height: 64rem;
	}

	.xl\:max-h-full {
		max-height: 100%;
	}

	.xl\:max-h-screen {
		max-height: 100vh;
	}

	.xl\:max-h-auto {
		max-height: auto;
	}

	.xl\:max-h-px {
		max-height: 1px;
	}

	.xl\:max-h-xs {
		max-height: 32rem;
	}

	.xl\:max-h-sm {
		max-height: 48rem;
	}

	.xl\:max-h-md {
		max-height: 64rem;
	}

	.xl\:max-h-lg {
		max-height: 80rem;
	}

	.xl\:max-h-xl {
		max-height: 96rem;
	}

	.xl\:max-h-2xl {
		max-height: 112rem;
	}

	.xl\:max-h-3xl {
		max-height: 128rem;
	}

	.xl\:max-h-4xl {
		max-height: 144rem;
	}

	.xl\:max-h-5xl {
		max-height: 160rem;
	}

	.xl\:max-w-0 {
		max-width: 0;
	}

	.xl\:max-w-1 {
		max-width: 0.25rem;
	}

	.xl\:max-w-2 {
		max-width: 0.5rem;
	}

	.xl\:max-w-3 {
		max-width: 0.75rem;
	}

	.xl\:max-w-4 {
		max-width: 0.4rem;
	}

	.xl\:max-w-5 {
		max-width: 1.25rem;
	}

	.xl\:max-w-6 {
		max-width: 0.6rem;
	}

	.xl\:max-w-8 {
		max-width: 0.8rem;
	}

	.xl\:max-w-10 {
		max-width: 2.5rem;
	}

	.xl\:max-w-12 {
		max-width: 1.2rem;
	}

	.xl\:max-w-16 {
		max-width: 1.6rem;
	}

	.xl\:max-w-20 {
		max-width: 2rem;
	}

	.xl\:max-w-24 {
		max-width: 2.4rem;
	}

	.xl\:max-w-28 {
		max-width: 2.8rem;
	}

	.xl\:max-w-32 {
		max-width: 3.2rem;
	}

	.xl\:max-w-36 {
		max-width: 3.6rem;
	}

	.xl\:max-w-40 {
		max-width: 4rem;
	}

	.xl\:max-w-44 {
		max-width: 4.4rem;
	}

	.xl\:max-w-48 {
		max-width: 4.8rem;
	}

	.xl\:max-w-52 {
		max-width: 5.2rem;
	}

	.xl\:max-w-56 {
		max-width: 5.6rem;
	}

	.xl\:max-w-60 {
		max-width: 6rem;
	}

	.xl\:max-w-64 {
		max-width: 6.4rem;
	}

	.xl\:max-w-68 {
		max-width: 6.8rem;
	}

	.xl\:max-w-72 {
		max-width: 7.2rem;
	}

	.xl\:max-w-76 {
		max-width: 7.6rem;
	}

	.xl\:max-w-80 {
		max-width: 8rem;
	}

	.xl\:max-w-84 {
		max-width: 8.4rem;
	}

	.xl\:max-w-88 {
		max-width: 8.8rem;
	}

	.xl\:max-w-92 {
		max-width: 9.2rem;
	}

	.xl\:max-w-96 {
		max-width: 9.6rem;
	}

	.xl\:max-w-128 {
		max-width: 12.8rem;
	}

	.xl\:max-w-136 {
		max-width: 13.6rem;
	}

	.xl\:max-w-160 {
		max-width: 16rem;
	}

	.xl\:max-w-192 {
		max-width: 19.2rem;
	}

	.xl\:max-w-200 {
		max-width: 20rem;
	}

	.xl\:max-w-208 {
		max-width: 20.8rem;
	}

	.xl\:max-w-216 {
		max-width: 21.6rem;
	}

	.xl\:max-w-224 {
		max-width: 22.4rem;
	}

	.xl\:max-w-256 {
		max-width: 25.6rem;
	}

	.xl\:max-w-288 {
		max-width: 28.8rem;
	}

	.xl\:max-w-320 {
		max-width: 32rem;
	}

	.xl\:max-w-360 {
		max-width: 36rem;
	}

	.xl\:max-w-384 {
		max-width: 38.4rem;
	}

	.xl\:max-w-400 {
		max-width: 40rem;
	}

	.xl\:max-w-512 {
		max-width: 51.2rem;
	}

	.xl\:max-w-640 {
		max-width: 64rem;
	}

	.xl\:max-w-xs {
		max-width: 32rem;
	}

	.xl\:max-w-sm {
		max-width: 48rem;
	}

	.xl\:max-w-md {
		max-width: 64rem;
	}

	.xl\:max-w-lg {
		max-width: 80rem;
	}

	.xl\:max-w-xl {
		max-width: 96rem;
	}

	.xl\:max-w-2xl {
		max-width: 112rem;
	}

	.xl\:max-w-3xl {
		max-width: 128rem;
	}

	.xl\:max-w-4xl {
		max-width: 144rem;
	}

	.xl\:max-w-5xl {
		max-width: 160rem;
	}

	.xl\:max-w-6xl {
		max-width: 72rem;
	}

	.xl\:max-w-full {
		max-width: 100%;
	}

	.xl\:max-w-px {
		max-width: 1px;
	}

	.xl\:max-w-auto {
		max-width: auto;
	}

	.xl\:max-w-screen {
		max-width: 100vw;
	}

	.xl\:min-h-0 {
		min-height: 0;
	}

	.xl\:min-h-1 {
		min-height: 0.25rem;
	}

	.xl\:min-h-2 {
		min-height: 0.5rem;
	}

	.xl\:min-h-3 {
		min-height: 0.75rem;
	}

	.xl\:min-h-4 {
		min-height: 0.4rem;
	}

	.xl\:min-h-5 {
		min-height: 1.25rem;
	}

	.xl\:min-h-6 {
		min-height: 0.6rem;
	}

	.xl\:min-h-8 {
		min-height: 0.8rem;
	}

	.xl\:min-h-10 {
		min-height: 2.5rem;
	}

	.xl\:min-h-12 {
		min-height: 1.2rem;
	}

	.xl\:min-h-16 {
		min-height: 1.6rem;
	}

	.xl\:min-h-20 {
		min-height: 2rem;
	}

	.xl\:min-h-24 {
		min-height: 2.4rem;
	}

	.xl\:min-h-28 {
		min-height: 2.8rem;
	}

	.xl\:min-h-32 {
		min-height: 3.2rem;
	}

	.xl\:min-h-36 {
		min-height: 3.6rem;
	}

	.xl\:min-h-40 {
		min-height: 4rem;
	}

	.xl\:min-h-44 {
		min-height: 4.4rem;
	}

	.xl\:min-h-48 {
		min-height: 4.8rem;
	}

	.xl\:min-h-52 {
		min-height: 5.2rem;
	}

	.xl\:min-h-56 {
		min-height: 5.6rem;
	}

	.xl\:min-h-60 {
		min-height: 6rem;
	}

	.xl\:min-h-64 {
		min-height: 6.4rem;
	}

	.xl\:min-h-68 {
		min-height: 6.8rem;
	}

	.xl\:min-h-72 {
		min-height: 7.2rem;
	}

	.xl\:min-h-76 {
		min-height: 7.6rem;
	}

	.xl\:min-h-80 {
		min-height: 8rem;
	}

	.xl\:min-h-84 {
		min-height: 8.4rem;
	}

	.xl\:min-h-88 {
		min-height: 8.8rem;
	}

	.xl\:min-h-92 {
		min-height: 9.2rem;
	}

	.xl\:min-h-96 {
		min-height: 9.6rem;
	}

	.xl\:min-h-128 {
		min-height: 12.8rem;
	}

	.xl\:min-h-136 {
		min-height: 13.6rem;
	}

	.xl\:min-h-160 {
		min-height: 16rem;
	}

	.xl\:min-h-192 {
		min-height: 19.2rem;
	}

	.xl\:min-h-200 {
		min-height: 20rem;
	}

	.xl\:min-h-208 {
		min-height: 20.8rem;
	}

	.xl\:min-h-216 {
		min-height: 21.6rem;
	}

	.xl\:min-h-224 {
		min-height: 22.4rem;
	}

	.xl\:min-h-256 {
		min-height: 25.6rem;
	}

	.xl\:min-h-288 {
		min-height: 28.8rem;
	}

	.xl\:min-h-320 {
		min-height: 32rem;
	}

	.xl\:min-h-360 {
		min-height: 36rem;
	}

	.xl\:min-h-384 {
		min-height: 38.4rem;
	}

	.xl\:min-h-400 {
		min-height: 40rem;
	}

	.xl\:min-h-512 {
		min-height: 51.2rem;
	}

	.xl\:min-h-640 {
		min-height: 64rem;
	}

	.xl\:min-h-full {
		min-height: 100%;
	}

	.xl\:min-h-screen {
		min-height: 100vh;
	}

	.xl\:min-h-auto {
		min-height: auto;
	}

	.xl\:min-h-px {
		min-height: 1px;
	}

	.xl\:min-h-xs {
		min-height: 32rem;
	}

	.xl\:min-h-sm {
		min-height: 48rem;
	}

	.xl\:min-h-md {
		min-height: 64rem;
	}

	.xl\:min-h-lg {
		min-height: 80rem;
	}

	.xl\:min-h-xl {
		min-height: 96rem;
	}

	.xl\:min-h-2xl {
		min-height: 112rem;
	}

	.xl\:min-h-3xl {
		min-height: 128rem;
	}

	.xl\:min-h-4xl {
		min-height: 144rem;
	}

	.xl\:min-h-5xl {
		min-height: 160rem;
	}

	.xl\:min-w-0 {
		min-width: 0;
	}

	.xl\:min-w-1 {
		min-width: 0.25rem;
	}

	.xl\:min-w-2 {
		min-width: 0.5rem;
	}

	.xl\:min-w-3 {
		min-width: 0.75rem;
	}

	.xl\:min-w-4 {
		min-width: 0.4rem;
	}

	.xl\:min-w-5 {
		min-width: 1.25rem;
	}

	.xl\:min-w-6 {
		min-width: 0.6rem;
	}

	.xl\:min-w-8 {
		min-width: 0.8rem;
	}

	.xl\:min-w-10 {
		min-width: 2.5rem;
	}

	.xl\:min-w-12 {
		min-width: 1.2rem;
	}

	.xl\:min-w-16 {
		min-width: 1.6rem;
	}

	.xl\:min-w-20 {
		min-width: 2rem;
	}

	.xl\:min-w-24 {
		min-width: 2.4rem;
	}

	.xl\:min-w-28 {
		min-width: 2.8rem;
	}

	.xl\:min-w-32 {
		min-width: 3.2rem;
	}

	.xl\:min-w-36 {
		min-width: 3.6rem;
	}

	.xl\:min-w-40 {
		min-width: 4rem;
	}

	.xl\:min-w-44 {
		min-width: 4.4rem;
	}

	.xl\:min-w-48 {
		min-width: 4.8rem;
	}

	.xl\:min-w-52 {
		min-width: 5.2rem;
	}

	.xl\:min-w-56 {
		min-width: 5.6rem;
	}

	.xl\:min-w-60 {
		min-width: 6rem;
	}

	.xl\:min-w-64 {
		min-width: 6.4rem;
	}

	.xl\:min-w-68 {
		min-width: 6.8rem;
	}

	.xl\:min-w-72 {
		min-width: 7.2rem;
	}

	.xl\:min-w-76 {
		min-width: 7.6rem;
	}

	.xl\:min-w-80 {
		min-width: 8rem;
	}

	.xl\:min-w-84 {
		min-width: 8.4rem;
	}

	.xl\:min-w-88 {
		min-width: 8.8rem;
	}

	.xl\:min-w-92 {
		min-width: 9.2rem;
	}

	.xl\:min-w-96 {
		min-width: 9.6rem;
	}

	.xl\:min-w-128 {
		min-width: 12.8rem;
	}

	.xl\:min-w-136 {
		min-width: 13.6rem;
	}

	.xl\:min-w-160 {
		min-width: 16rem;
	}

	.xl\:min-w-192 {
		min-width: 19.2rem;
	}

	.xl\:min-w-200 {
		min-width: 20rem;
	}

	.xl\:min-w-208 {
		min-width: 20.8rem;
	}

	.xl\:min-w-216 {
		min-width: 21.6rem;
	}

	.xl\:min-w-224 {
		min-width: 22.4rem;
	}

	.xl\:min-w-256 {
		min-width: 25.6rem;
	}

	.xl\:min-w-288 {
		min-width: 28.8rem;
	}

	.xl\:min-w-320 {
		min-width: 32rem;
	}

	.xl\:min-w-360 {
		min-width: 36rem;
	}

	.xl\:min-w-384 {
		min-width: 38.4rem;
	}

	.xl\:min-w-400 {
		min-width: 40rem;
	}

	.xl\:min-w-512 {
		min-width: 51.2rem;
	}

	.xl\:min-w-640 {
		min-width: 64rem;
	}

	.xl\:min-w-full {
		min-width: 100%;
	}

	.xl\:min-w-px {
		min-width: 1px;
	}

	.xl\:min-w-xs {
		min-width: 32rem;
	}

	.xl\:min-w-sm {
		min-width: 48rem;
	}

	.xl\:min-w-md {
		min-width: 64rem;
	}

	.xl\:min-w-lg {
		min-width: 80rem;
	}

	.xl\:min-w-xl {
		min-width: 96rem;
	}

	.xl\:min-w-2xl {
		min-width: 112rem;
	}

	.xl\:min-w-3xl {
		min-width: 128rem;
	}

	.xl\:min-w-4xl {
		min-width: 144rem;
	}

	.xl\:min-w-5xl {
		min-width: 160rem;
	}

	.xl\:min-w-auto {
		min-width: auto;
	}

	.xl\:min-w-screen {
		min-width: 100vw;
	}

	.xl\:object-contain {
		object-fit: contain;
	}

	.xl\:object-cover {
		object-fit: cover;
	}

	.xl\:object-fill {
		object-fit: fill;
	}

	.xl\:object-none {
		object-fit: none;
	}

	.xl\:object-scale-down {
		object-fit: scale-down;
	}

	.xl\:object-bottom {
		object-position: bottom;
	}

	.xl\:object-center {
		object-position: center;
	}

	.xl\:object-left {
		object-position: left;
	}

	.xl\:object-left-bottom {
		object-position: left bottom;
	}

	.xl\:object-left-top {
		object-position: left top;
	}

	.xl\:object-right {
		object-position: right;
	}

	.xl\:object-right-bottom {
		object-position: right bottom;
	}

	.xl\:object-right-top {
		object-position: right top;
	}

	.xl\:object-top {
		object-position: top;
	}

	.xl\:opacity-0 {
		opacity: 0;
	}

	.xl\:opacity-25 {
		opacity: 0.25;
	}

	.xl\:opacity-50 {
		opacity: 0.5;
	}

	.xl\:opacity-75 {
		opacity: 0.75;
	}

	.xl\:opacity-100 {
		opacity: 1;
	}

	.xl\:outline-none {
		outline: 0;
	}

	.xl\:focus\:outline-none:focus {
		outline: 0;
	}

	.xl\:overflow-auto {
		overflow: auto;
	}

	.xl\:overflow-hidden {
		overflow: hidden;
	}

	.xl\:overflow-visible {
		overflow: visible;
	}

	.xl\:overflow-scroll {
		overflow: scroll;
	}

	.xl\:overflow-x-auto {
		overflow-x: auto;
	}

	.xl\:overflow-y-auto {
		overflow-y: auto;
	}

	.xl\:overflow-x-hidden {
		overflow-x: hidden;
	}

	.xl\:overflow-y-hidden {
		overflow-y: hidden;
	}

	.xl\:overflow-x-visible {
		overflow-x: visible;
	}

	.xl\:overflow-y-visible {
		overflow-y: visible;
	}

	.xl\:overflow-x-scroll {
		overflow-x: scroll;
	}

	.xl\:overflow-y-scroll {
		overflow-y: scroll;
	}

	.xl\:scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}

	.xl\:scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}

	.xl\:p-0 {
		padding: 0;
	}

	.xl\:p-1 {
		padding: 0.25rem;
	}

	.xl\:p-2 {
		padding: 0.5rem;
	}

	.xl\:p-3 {
		padding: 0.75rem;
	}

	.xl\:p-4 {
		padding: 0.4rem;
	}

	.xl\:p-5 {
		padding: 1.25rem;
	}

	.xl\:p-6 {
		padding: 0.6rem;
	}

	.xl\:p-8 {
		padding: 0.8rem;
	}

	.xl\:p-10 {
		padding: 2.5rem;
	}

	.xl\:p-12 {
		padding: 1.2rem;
	}

	.xl\:p-16 {
		padding: 1.6rem;
	}

	.xl\:p-20 {
		padding: 2rem;
	}

	.xl\:p-24 {
		padding: 2.4rem;
	}

	.xl\:p-28 {
		padding: 2.8rem;
	}

	.xl\:p-32 {
		padding: 3.2rem;
	}

	.xl\:p-36 {
		padding: 3.6rem;
	}

	.xl\:p-40 {
		padding: 4rem;
	}

	.xl\:p-44 {
		padding: 4.4rem;
	}

	.xl\:p-48 {
		padding: 4.8rem;
	}

	.xl\:p-52 {
		padding: 5.2rem;
	}

	.xl\:p-56 {
		padding: 5.6rem;
	}

	.xl\:p-60 {
		padding: 6rem;
	}

	.xl\:p-64 {
		padding: 6.4rem;
	}

	.xl\:p-68 {
		padding: 6.8rem;
	}

	.xl\:p-72 {
		padding: 7.2rem;
	}

	.xl\:p-76 {
		padding: 7.6rem;
	}

	.xl\:p-80 {
		padding: 8rem;
	}

	.xl\:p-84 {
		padding: 8.4rem;
	}

	.xl\:p-88 {
		padding: 8.8rem;
	}

	.xl\:p-92 {
		padding: 9.2rem;
	}

	.xl\:p-96 {
		padding: 9.6rem;
	}

	.xl\:p-128 {
		padding: 12.8rem;
	}

	.xl\:p-136 {
		padding: 13.6rem;
	}

	.xl\:p-160 {
		padding: 16rem;
	}

	.xl\:p-192 {
		padding: 19.2rem;
	}

	.xl\:p-200 {
		padding: 20rem;
	}

	.xl\:p-208 {
		padding: 20.8rem;
	}

	.xl\:p-216 {
		padding: 21.6rem;
	}

	.xl\:p-224 {
		padding: 22.4rem;
	}

	.xl\:p-256 {
		padding: 25.6rem;
	}

	.xl\:p-288 {
		padding: 28.8rem;
	}

	.xl\:p-320 {
		padding: 32rem;
	}

	.xl\:p-360 {
		padding: 36rem;
	}

	.xl\:p-384 {
		padding: 38.4rem;
	}

	.xl\:p-400 {
		padding: 40rem;
	}

	.xl\:p-512 {
		padding: 51.2rem;
	}

	.xl\:p-640 {
		padding: 64rem;
	}

	.xl\:p-px {
		padding: 1px;
	}

	.xl\:p-xs {
		padding: 32rem;
	}

	.xl\:p-sm {
		padding: 48rem;
	}

	.xl\:p-md {
		padding: 64rem;
	}

	.xl\:p-lg {
		padding: 80rem;
	}

	.xl\:p-xl {
		padding: 96rem;
	}

	.xl\:p-2xl {
		padding: 112rem;
	}

	.xl\:p-3xl {
		padding: 128rem;
	}

	.xl\:p-4xl {
		padding: 144rem;
	}

	.xl\:p-5xl {
		padding: 160rem;
	}

	.xl\:p-auto {
		padding: auto;
	}

	.xl\:py-0 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.xl\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.xl\:py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.xl\:px-1 {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.xl\:py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.xl\:px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.xl\:py-3 {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.xl\:px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.xl\:py-4 {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.xl\:px-4 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}

	.xl\:py-5 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.xl\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.xl\:py-6 {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.xl\:px-6 {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.xl\:py-8 {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
	}

	.xl\:px-8 {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.xl\:py-10 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.xl\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.xl\:py-12 {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.xl\:px-12 {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}

	.xl\:py-16 {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	.xl\:px-16 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.xl\:py-20 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.xl\:px-20 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.xl\:py-24 {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	.xl\:px-24 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.xl\:py-28 {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}

	.xl\:px-28 {
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	.xl\:py-32 {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.xl\:px-32 {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.xl\:py-36 {
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
	}

	.xl\:px-36 {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.xl\:py-40 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.xl\:px-40 {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	.xl\:py-44 {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.xl\:px-44 {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.xl\:py-48 {
		padding-top: 4.8rem;
		padding-bottom: 4.8rem;
	}

	.xl\:px-48 {
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.xl\:py-52 {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.xl\:px-52 {
		padding-left: 5.2rem;
		padding-right: 5.2rem;
	}

	.xl\:py-56 {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}

	.xl\:px-56 {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}

	.xl\:py-60 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.xl\:px-60 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.xl\:py-64 {
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;
	}

	.xl\:px-64 {
		padding-left: 6.4rem;
		padding-right: 6.4rem;
	}

	.xl\:py-68 {
		padding-top: 6.8rem;
		padding-bottom: 6.8rem;
	}

	.xl\:px-68 {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.xl\:py-72 {
		padding-top: 7.2rem;
		padding-bottom: 7.2rem;
	}

	.xl\:px-72 {
		padding-left: 7.2rem;
		padding-right: 7.2rem;
	}

	.xl\:py-76 {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
	}

	.xl\:px-76 {
		padding-left: 7.6rem;
		padding-right: 7.6rem;
	}

	.xl\:py-80 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.xl\:px-80 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.xl\:py-84 {
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}

	.xl\:px-84 {
		padding-left: 8.4rem;
		padding-right: 8.4rem;
	}

	.xl\:py-88 {
		padding-top: 8.8rem;
		padding-bottom: 8.8rem;
	}

	.xl\:px-88 {
		padding-left: 8.8rem;
		padding-right: 8.8rem;
	}

	.xl\:py-92 {
		padding-top: 9.2rem;
		padding-bottom: 9.2rem;
	}

	.xl\:px-92 {
		padding-left: 9.2rem;
		padding-right: 9.2rem;
	}

	.xl\:py-96 {
		padding-top: 9.6rem;
		padding-bottom: 9.6rem;
	}

	.xl\:px-96 {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}

	.xl\:py-128 {
		padding-top: 12.8rem;
		padding-bottom: 12.8rem;
	}

	.xl\:px-128 {
		padding-left: 12.8rem;
		padding-right: 12.8rem;
	}

	.xl\:py-136 {
		padding-top: 13.6rem;
		padding-bottom: 13.6rem;
	}

	.xl\:px-136 {
		padding-left: 13.6rem;
		padding-right: 13.6rem;
	}

	.xl\:py-160 {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}

	.xl\:px-160 {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.xl\:py-192 {
		padding-top: 19.2rem;
		padding-bottom: 19.2rem;
	}

	.xl\:px-192 {
		padding-left: 19.2rem;
		padding-right: 19.2rem;
	}

	.xl\:py-200 {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.xl\:px-200 {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.xl\:py-208 {
		padding-top: 20.8rem;
		padding-bottom: 20.8rem;
	}

	.xl\:px-208 {
		padding-left: 20.8rem;
		padding-right: 20.8rem;
	}

	.xl\:py-216 {
		padding-top: 21.6rem;
		padding-bottom: 21.6rem;
	}

	.xl\:px-216 {
		padding-left: 21.6rem;
		padding-right: 21.6rem;
	}

	.xl\:py-224 {
		padding-top: 22.4rem;
		padding-bottom: 22.4rem;
	}

	.xl\:px-224 {
		padding-left: 22.4rem;
		padding-right: 22.4rem;
	}

	.xl\:py-256 {
		padding-top: 25.6rem;
		padding-bottom: 25.6rem;
	}

	.xl\:px-256 {
		padding-left: 25.6rem;
		padding-right: 25.6rem;
	}

	.xl\:py-288 {
		padding-top: 28.8rem;
		padding-bottom: 28.8rem;
	}

	.xl\:px-288 {
		padding-left: 28.8rem;
		padding-right: 28.8rem;
	}

	.xl\:py-320 {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.xl\:px-320 {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.xl\:py-360 {
		padding-top: 36rem;
		padding-bottom: 36rem;
	}

	.xl\:px-360 {
		padding-left: 36rem;
		padding-right: 36rem;
	}

	.xl\:py-384 {
		padding-top: 38.4rem;
		padding-bottom: 38.4rem;
	}

	.xl\:px-384 {
		padding-left: 38.4rem;
		padding-right: 38.4rem;
	}

	.xl\:py-400 {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.xl\:px-400 {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.xl\:py-512 {
		padding-top: 51.2rem;
		padding-bottom: 51.2rem;
	}

	.xl\:px-512 {
		padding-left: 51.2rem;
		padding-right: 51.2rem;
	}

	.xl\:py-640 {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.xl\:px-640 {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.xl\:py-px {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.xl\:px-px {
		padding-left: 1px;
		padding-right: 1px;
	}

	.xl\:py-xs {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.xl\:px-xs {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.xl\:py-sm {
		padding-top: 48rem;
		padding-bottom: 48rem;
	}

	.xl\:px-sm {
		padding-left: 48rem;
		padding-right: 48rem;
	}

	.xl\:py-md {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.xl\:px-md {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.xl\:py-lg {
		padding-top: 80rem;
		padding-bottom: 80rem;
	}

	.xl\:px-lg {
		padding-left: 80rem;
		padding-right: 80rem;
	}

	.xl\:py-xl {
		padding-top: 96rem;
		padding-bottom: 96rem;
	}

	.xl\:px-xl {
		padding-left: 96rem;
		padding-right: 96rem;
	}

	.xl\:py-2xl {
		padding-top: 112rem;
		padding-bottom: 112rem;
	}

	.xl\:px-2xl {
		padding-left: 112rem;
		padding-right: 112rem;
	}

	.xl\:py-3xl {
		padding-top: 128rem;
		padding-bottom: 128rem;
	}

	.xl\:px-3xl {
		padding-left: 128rem;
		padding-right: 128rem;
	}

	.xl\:py-4xl {
		padding-top: 144rem;
		padding-bottom: 144rem;
	}

	.xl\:px-4xl {
		padding-left: 144rem;
		padding-right: 144rem;
	}

	.xl\:py-5xl {
		padding-top: 160rem;
		padding-bottom: 160rem;
	}

	.xl\:px-5xl {
		padding-left: 160rem;
		padding-right: 160rem;
	}

	.xl\:py-auto {
		padding-top: auto;
		padding-bottom: auto;
	}

	.xl\:px-auto {
		padding-left: auto;
		padding-right: auto;
	}

	.xl\:pt-0 {
		padding-top: 0;
	}

	.xl\:pr-0 {
		padding-right: 0;
	}

	.xl\:pb-0 {
		padding-bottom: 0;
	}

	.xl\:pl-0 {
		padding-left: 0;
	}

	.xl\:pt-1 {
		padding-top: 0.25rem;
	}

	.xl\:pr-1 {
		padding-right: 0.25rem;
	}

	.xl\:pb-1 {
		padding-bottom: 0.25rem;
	}

	.xl\:pl-1 {
		padding-left: 0.25rem;
	}

	.xl\:pt-2 {
		padding-top: 0.5rem;
	}

	.xl\:pr-2 {
		padding-right: 0.5rem;
	}

	.xl\:pb-2 {
		padding-bottom: 0.5rem;
	}

	.xl\:pl-2 {
		padding-left: 0.5rem;
	}

	.xl\:pt-3 {
		padding-top: 0.75rem;
	}

	.xl\:pr-3 {
		padding-right: 0.75rem;
	}

	.xl\:pb-3 {
		padding-bottom: 0.75rem;
	}

	.xl\:pl-3 {
		padding-left: 0.75rem;
	}

	.xl\:pt-4 {
		padding-top: 0.4rem;
	}

	.xl\:pr-4 {
		padding-right: 0.4rem;
	}

	.xl\:pb-4 {
		padding-bottom: 0.4rem;
	}

	.xl\:pl-4 {
		padding-left: 0.4rem;
	}

	.xl\:pt-5 {
		padding-top: 1.25rem;
	}

	.xl\:pr-5 {
		padding-right: 1.25rem;
	}

	.xl\:pb-5 {
		padding-bottom: 1.25rem;
	}

	.xl\:pl-5 {
		padding-left: 1.25rem;
	}

	.xl\:pt-6 {
		padding-top: 0.6rem;
	}

	.xl\:pr-6 {
		padding-right: 0.6rem;
	}

	.xl\:pb-6 {
		padding-bottom: 0.6rem;
	}

	.xl\:pl-6 {
		padding-left: 0.6rem;
	}

	.xl\:pt-8 {
		padding-top: 0.8rem;
	}

	.xl\:pr-8 {
		padding-right: 0.8rem;
	}

	.xl\:pb-8 {
		padding-bottom: 0.8rem;
	}

	.xl\:pl-8 {
		padding-left: 0.8rem;
	}

	.xl\:pt-10 {
		padding-top: 2.5rem;
	}

	.xl\:pr-10 {
		padding-right: 2.5rem;
	}

	.xl\:pb-10 {
		padding-bottom: 2.5rem;
	}

	.xl\:pl-10 {
		padding-left: 2.5rem;
	}

	.xl\:pt-12 {
		padding-top: 1.2rem;
	}

	.xl\:pr-12 {
		padding-right: 1.2rem;
	}

	.xl\:pb-12 {
		padding-bottom: 1.2rem;
	}

	.xl\:pl-12 {
		padding-left: 1.2rem;
	}

	.xl\:pt-16 {
		padding-top: 1.6rem;
	}

	.xl\:pr-16 {
		padding-right: 1.6rem;
	}

	.xl\:pb-16 {
		padding-bottom: 1.6rem;
	}

	.xl\:pl-16 {
		padding-left: 1.6rem;
	}

	.xl\:pt-20 {
		padding-top: 2rem;
	}

	.xl\:pr-20 {
		padding-right: 2rem;
	}

	.xl\:pb-20 {
		padding-bottom: 2rem;
	}

	.xl\:pl-20 {
		padding-left: 2rem;
	}

	.xl\:pt-24 {
		padding-top: 2.4rem;
	}

	.xl\:pr-24 {
		padding-right: 2.4rem;
	}

	.xl\:pb-24 {
		padding-bottom: 2.4rem;
	}

	.xl\:pl-24 {
		padding-left: 2.4rem;
	}

	.xl\:pt-28 {
		padding-top: 2.8rem;
	}

	.xl\:pr-28 {
		padding-right: 2.8rem;
	}

	.xl\:pb-28 {
		padding-bottom: 2.8rem;
	}

	.xl\:pl-28 {
		padding-left: 2.8rem;
	}

	.xl\:pt-32 {
		padding-top: 3.2rem;
	}

	.xl\:pr-32 {
		padding-right: 3.2rem;
	}

	.xl\:pb-32 {
		padding-bottom: 3.2rem;
	}

	.xl\:pl-32 {
		padding-left: 3.2rem;
	}

	.xl\:pt-36 {
		padding-top: 3.6rem;
	}

	.xl\:pr-36 {
		padding-right: 3.6rem;
	}

	.xl\:pb-36 {
		padding-bottom: 3.6rem;
	}

	.xl\:pl-36 {
		padding-left: 3.6rem;
	}

	.xl\:pt-40 {
		padding-top: 4rem;
	}

	.xl\:pr-40 {
		padding-right: 4rem;
	}

	.xl\:pb-40 {
		padding-bottom: 4rem;
	}

	.xl\:pl-40 {
		padding-left: 4rem;
	}

	.xl\:pt-44 {
		padding-top: 4.4rem;
	}

	.xl\:pr-44 {
		padding-right: 4.4rem;
	}

	.xl\:pb-44 {
		padding-bottom: 4.4rem;
	}

	.xl\:pl-44 {
		padding-left: 4.4rem;
	}

	.xl\:pt-48 {
		padding-top: 4.8rem;
	}

	.xl\:pr-48 {
		padding-right: 4.8rem;
	}

	.xl\:pb-48 {
		padding-bottom: 4.8rem;
	}

	.xl\:pl-48 {
		padding-left: 4.8rem;
	}

	.xl\:pt-52 {
		padding-top: 5.2rem;
	}

	.xl\:pr-52 {
		padding-right: 5.2rem;
	}

	.xl\:pb-52 {
		padding-bottom: 5.2rem;
	}

	.xl\:pl-52 {
		padding-left: 5.2rem;
	}

	.xl\:pt-56 {
		padding-top: 5.6rem;
	}

	.xl\:pr-56 {
		padding-right: 5.6rem;
	}

	.xl\:pb-56 {
		padding-bottom: 5.6rem;
	}

	.xl\:pl-56 {
		padding-left: 5.6rem;
	}

	.xl\:pt-60 {
		padding-top: 6rem;
	}

	.xl\:pr-60 {
		padding-right: 6rem;
	}

	.xl\:pb-60 {
		padding-bottom: 6rem;
	}

	.xl\:pl-60 {
		padding-left: 6rem;
	}

	.xl\:pt-64 {
		padding-top: 6.4rem;
	}

	.xl\:pr-64 {
		padding-right: 6.4rem;
	}

	.xl\:pb-64 {
		padding-bottom: 6.4rem;
	}

	.xl\:pl-64 {
		padding-left: 6.4rem;
	}

	.xl\:pt-68 {
		padding-top: 6.8rem;
	}

	.xl\:pr-68 {
		padding-right: 6.8rem;
	}

	.xl\:pb-68 {
		padding-bottom: 6.8rem;
	}

	.xl\:pl-68 {
		padding-left: 6.8rem;
	}

	.xl\:pt-72 {
		padding-top: 7.2rem;
	}

	.xl\:pr-72 {
		padding-right: 7.2rem;
	}

	.xl\:pb-72 {
		padding-bottom: 7.2rem;
	}

	.xl\:pl-72 {
		padding-left: 7.2rem;
	}

	.xl\:pt-76 {
		padding-top: 7.6rem;
	}

	.xl\:pr-76 {
		padding-right: 7.6rem;
	}

	.xl\:pb-76 {
		padding-bottom: 7.6rem;
	}

	.xl\:pl-76 {
		padding-left: 7.6rem;
	}

	.xl\:pt-80 {
		padding-top: 8rem;
	}

	.xl\:pr-80 {
		padding-right: 8rem;
	}

	.xl\:pb-80 {
		padding-bottom: 8rem;
	}

	.xl\:pl-80 {
		padding-left: 8rem;
	}

	.xl\:pt-84 {
		padding-top: 8.4rem;
	}

	.xl\:pr-84 {
		padding-right: 8.4rem;
	}

	.xl\:pb-84 {
		padding-bottom: 8.4rem;
	}

	.xl\:pl-84 {
		padding-left: 8.4rem;
	}

	.xl\:pt-88 {
		padding-top: 8.8rem;
	}

	.xl\:pr-88 {
		padding-right: 8.8rem;
	}

	.xl\:pb-88 {
		padding-bottom: 8.8rem;
	}

	.xl\:pl-88 {
		padding-left: 8.8rem;
	}

	.xl\:pt-92 {
		padding-top: 9.2rem;
	}

	.xl\:pr-92 {
		padding-right: 9.2rem;
	}

	.xl\:pb-92 {
		padding-bottom: 9.2rem;
	}

	.xl\:pl-92 {
		padding-left: 9.2rem;
	}

	.xl\:pt-96 {
		padding-top: 9.6rem;
	}

	.xl\:pr-96 {
		padding-right: 9.6rem;
	}

	.xl\:pb-96 {
		padding-bottom: 9.6rem;
	}

	.xl\:pl-96 {
		padding-left: 9.6rem;
	}

	.xl\:pt-128 {
		padding-top: 12.8rem;
	}

	.xl\:pr-128 {
		padding-right: 12.8rem;
	}

	.xl\:pb-128 {
		padding-bottom: 12.8rem;
	}

	.xl\:pl-128 {
		padding-left: 12.8rem;
	}

	.xl\:pt-136 {
		padding-top: 13.6rem;
	}

	.xl\:pr-136 {
		padding-right: 13.6rem;
	}

	.xl\:pb-136 {
		padding-bottom: 13.6rem;
	}

	.xl\:pl-136 {
		padding-left: 13.6rem;
	}

	.xl\:pt-160 {
		padding-top: 16rem;
	}

	.xl\:pr-160 {
		padding-right: 16rem;
	}

	.xl\:pb-160 {
		padding-bottom: 16rem;
	}

	.xl\:pl-160 {
		padding-left: 16rem;
	}

	.xl\:pt-192 {
		padding-top: 19.2rem;
	}

	.xl\:pr-192 {
		padding-right: 19.2rem;
	}

	.xl\:pb-192 {
		padding-bottom: 19.2rem;
	}

	.xl\:pl-192 {
		padding-left: 19.2rem;
	}

	.xl\:pt-200 {
		padding-top: 20rem;
	}

	.xl\:pr-200 {
		padding-right: 20rem;
	}

	.xl\:pb-200 {
		padding-bottom: 20rem;
	}

	.xl\:pl-200 {
		padding-left: 20rem;
	}

	.xl\:pt-208 {
		padding-top: 20.8rem;
	}

	.xl\:pr-208 {
		padding-right: 20.8rem;
	}

	.xl\:pb-208 {
		padding-bottom: 20.8rem;
	}

	.xl\:pl-208 {
		padding-left: 20.8rem;
	}

	.xl\:pt-216 {
		padding-top: 21.6rem;
	}

	.xl\:pr-216 {
		padding-right: 21.6rem;
	}

	.xl\:pb-216 {
		padding-bottom: 21.6rem;
	}

	.xl\:pl-216 {
		padding-left: 21.6rem;
	}

	.xl\:pt-224 {
		padding-top: 22.4rem;
	}

	.xl\:pr-224 {
		padding-right: 22.4rem;
	}

	.xl\:pb-224 {
		padding-bottom: 22.4rem;
	}

	.xl\:pl-224 {
		padding-left: 22.4rem;
	}

	.xl\:pt-256 {
		padding-top: 25.6rem;
	}

	.xl\:pr-256 {
		padding-right: 25.6rem;
	}

	.xl\:pb-256 {
		padding-bottom: 25.6rem;
	}

	.xl\:pl-256 {
		padding-left: 25.6rem;
	}

	.xl\:pt-288 {
		padding-top: 28.8rem;
	}

	.xl\:pr-288 {
		padding-right: 28.8rem;
	}

	.xl\:pb-288 {
		padding-bottom: 28.8rem;
	}

	.xl\:pl-288 {
		padding-left: 28.8rem;
	}

	.xl\:pt-320 {
		padding-top: 32rem;
	}

	.xl\:pr-320 {
		padding-right: 32rem;
	}

	.xl\:pb-320 {
		padding-bottom: 32rem;
	}

	.xl\:pl-320 {
		padding-left: 32rem;
	}

	.xl\:pt-360 {
		padding-top: 36rem;
	}

	.xl\:pr-360 {
		padding-right: 36rem;
	}

	.xl\:pb-360 {
		padding-bottom: 36rem;
	}

	.xl\:pl-360 {
		padding-left: 36rem;
	}

	.xl\:pt-384 {
		padding-top: 38.4rem;
	}

	.xl\:pr-384 {
		padding-right: 38.4rem;
	}

	.xl\:pb-384 {
		padding-bottom: 38.4rem;
	}

	.xl\:pl-384 {
		padding-left: 38.4rem;
	}

	.xl\:pt-400 {
		padding-top: 40rem;
	}

	.xl\:pr-400 {
		padding-right: 40rem;
	}

	.xl\:pb-400 {
		padding-bottom: 40rem;
	}

	.xl\:pl-400 {
		padding-left: 40rem;
	}

	.xl\:pt-512 {
		padding-top: 51.2rem;
	}

	.xl\:pr-512 {
		padding-right: 51.2rem;
	}

	.xl\:pb-512 {
		padding-bottom: 51.2rem;
	}

	.xl\:pl-512 {
		padding-left: 51.2rem;
	}

	.xl\:pt-640 {
		padding-top: 64rem;
	}

	.xl\:pr-640 {
		padding-right: 64rem;
	}

	.xl\:pb-640 {
		padding-bottom: 64rem;
	}

	.xl\:pl-640 {
		padding-left: 64rem;
	}

	.xl\:pt-px {
		padding-top: 1px;
	}

	.xl\:pr-px {
		padding-right: 1px;
	}

	.xl\:pb-px {
		padding-bottom: 1px;
	}

	.xl\:pl-px {
		padding-left: 1px;
	}

	.xl\:pt-xs {
		padding-top: 32rem;
	}

	.xl\:pr-xs {
		padding-right: 32rem;
	}

	.xl\:pb-xs {
		padding-bottom: 32rem;
	}

	.xl\:pl-xs {
		padding-left: 32rem;
	}

	.xl\:pt-sm {
		padding-top: 48rem;
	}

	.xl\:pr-sm {
		padding-right: 48rem;
	}

	.xl\:pb-sm {
		padding-bottom: 48rem;
	}

	.xl\:pl-sm {
		padding-left: 48rem;
	}

	.xl\:pt-md {
		padding-top: 64rem;
	}

	.xl\:pr-md {
		padding-right: 64rem;
	}

	.xl\:pb-md {
		padding-bottom: 64rem;
	}

	.xl\:pl-md {
		padding-left: 64rem;
	}

	.xl\:pt-lg {
		padding-top: 80rem;
	}

	.xl\:pr-lg {
		padding-right: 80rem;
	}

	.xl\:pb-lg {
		padding-bottom: 80rem;
	}

	.xl\:pl-lg {
		padding-left: 80rem;
	}

	.xl\:pt-xl {
		padding-top: 96rem;
	}

	.xl\:pr-xl {
		padding-right: 96rem;
	}

	.xl\:pb-xl {
		padding-bottom: 96rem;
	}

	.xl\:pl-xl {
		padding-left: 96rem;
	}

	.xl\:pt-2xl {
		padding-top: 112rem;
	}

	.xl\:pr-2xl {
		padding-right: 112rem;
	}

	.xl\:pb-2xl {
		padding-bottom: 112rem;
	}

	.xl\:pl-2xl {
		padding-left: 112rem;
	}

	.xl\:pt-3xl {
		padding-top: 128rem;
	}

	.xl\:pr-3xl {
		padding-right: 128rem;
	}

	.xl\:pb-3xl {
		padding-bottom: 128rem;
	}

	.xl\:pl-3xl {
		padding-left: 128rem;
	}

	.xl\:pt-4xl {
		padding-top: 144rem;
	}

	.xl\:pr-4xl {
		padding-right: 144rem;
	}

	.xl\:pb-4xl {
		padding-bottom: 144rem;
	}

	.xl\:pl-4xl {
		padding-left: 144rem;
	}

	.xl\:pt-5xl {
		padding-top: 160rem;
	}

	.xl\:pr-5xl {
		padding-right: 160rem;
	}

	.xl\:pb-5xl {
		padding-bottom: 160rem;
	}

	.xl\:pl-5xl {
		padding-left: 160rem;
	}

	.xl\:pt-auto {
		padding-top: auto;
	}

	.xl\:pr-auto {
		padding-right: auto;
	}

	.xl\:pb-auto {
		padding-bottom: auto;
	}

	.xl\:pl-auto {
		padding-left: auto;
	}

	.xl\:pointer-events-none {
		pointer-events: none;
	}

	.xl\:pointer-events-auto {
		pointer-events: auto;
	}

	.xl\:static {
		position: static;
	}

	.xl\:fixed {
		position: fixed;
	}

	.xl\:absolute {
		position: absolute;
	}

	.xl\:relative {
		position: relative;
	}

	.xl\:sticky {
		position: -webkit-sticky;
		position: sticky;
	}

	.xl\:inset-0 {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.xl\:inset-auto {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}

	.xl\:inset-y-0 {
		top: 0;
		bottom: 0;
	}

	.xl\:inset-x-0 {
		right: 0;
		left: 0;
	}

	.xl\:inset-y-auto {
		top: auto;
		bottom: auto;
	}

	.xl\:inset-x-auto {
		right: auto;
		left: auto;
	}

	.xl\:top-0 {
		top: 0;
	}

	.xl\:right-0 {
		right: 0;
	}

	.xl\:bottom-0 {
		bottom: 0;
	}

	.xl\:left-0 {
		left: 0;
	}

	.xl\:top-auto {
		top: auto;
	}

	.xl\:right-auto {
		right: auto;
	}

	.xl\:bottom-auto {
		bottom: auto;
	}

	.xl\:left-auto {
		left: auto;
	}

	.xl\:resize-none {
		resize: none;
	}

	.xl\:resize-y {
		resize: vertical;
	}

	.xl\:resize-x {
		resize: horizontal;
	}

	.xl\:resize {
		resize: both;
	}

	.xl\:shadow-0 {
		box-shadow: none;
	}

	.xl\:shadow-1 {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-2 {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-3 {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-4 {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-5 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-6 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-7 {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-8 {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-9 {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-10 {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-11 {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-12 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-13 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-14 {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-15 {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-16 {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-17 {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-18 {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-19 {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-20 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-21 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-22 {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-23 {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow-24 {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.xl\:shadow-md {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:shadow-lg {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:shadow-xl {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.xl\:shadow-2xl {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.xl\:shadow-inner {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.xl\:shadow-outline {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.xl\:shadow-none {
		box-shadow: none;
	}

	.xl\:hover\:shadow-0:hover {
		box-shadow: none;
	}

	.xl\:hover\:shadow-1:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-2:hover {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-3:hover {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-4:hover {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-5:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-6:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-7:hover {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-8:hover {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-9:hover {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-10:hover {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-11:hover {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-12:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-13:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-14:hover {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-15:hover {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-16:hover {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-17:hover {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-18:hover {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-19:hover {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-20:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-21:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-22:hover {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-23:hover {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow-24:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:hover\:shadow:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.xl\:hover\:shadow-md:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:hover\:shadow-lg:hover {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:hover\:shadow-xl:hover {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.xl\:hover\:shadow-2xl:hover {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.xl\:hover\:shadow-inner:hover {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.xl\:hover\:shadow-outline:hover {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.xl\:hover\:shadow-none:hover {
		box-shadow: none;
	}

	.xl\:focus\:shadow-0:focus {
		box-shadow: none;
	}

	.xl\:focus\:shadow-1:focus {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-2:focus {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-3:focus {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-4:focus {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-5:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-6:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-7:focus {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-8:focus {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-9:focus {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-10:focus {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-11:focus {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-12:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-13:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-14:focus {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-15:focus {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-16:focus {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-17:focus {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-18:focus {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-19:focus {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-20:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-21:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-22:focus {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-23:focus {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow-24:focus {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.xl\:focus\:shadow:focus {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.xl\:focus\:shadow-md:focus {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:focus\:shadow-lg:focus {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.xl\:focus\:shadow-xl:focus {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.xl\:focus\:shadow-2xl:focus {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.xl\:focus\:shadow-inner:focus {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.xl\:focus\:shadow-outline:focus {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.xl\:focus\:shadow-none:focus {
		box-shadow: none;
	}

	.xl\:fill-current {
		fill: currentColor;
	}

	.xl\:stroke-current {
		stroke: currentColor;
	}

	.xl\:table-auto {
		table-layout: auto;
	}

	.xl\:table-fixed {
		table-layout: fixed;
	}

	.xl\:text-left {
		text-align: left;
	}

	.xl\:text-center {
		text-align: center;
	}

	.xl\:text-right {
		text-align: right;
	}

	.xl\:text-justify {
		text-align: justify;
	}

	.xl\:text-transparent {
		color: transparent;
	}

	.xl\:text-black {
		color: #22292f;
	}

	.xl\:text-white {
		color: #ffffff;
	}

	.xl\:text-gray-100 {
		color: #f7fafc;
	}

	.xl\:text-gray-200 {
		color: #edf2f7;
	}

	.xl\:text-gray-300 {
		color: #e2e8f0;
	}

	.xl\:text-gray-400 {
		color: #cbd5e0;
	}

	.xl\:text-gray-500 {
		color: #a0aec0;
	}

	.xl\:text-gray-600 {
		color: #718096;
	}

	.xl\:text-gray-700 {
		color: #4a5568;
	}

	.xl\:text-gray-800 {
		color: #2d3748;
	}

	.xl\:text-gray-900 {
		color: #1a202c;
	}

	.xl\:text-red {
		color: #e3342f;
	}

	.xl\:text-orange {
		color: #f6993f;
	}

	.xl\:text-yellow {
		color: #ffed4a;
	}

	.xl\:text-green {
		color: #38c172;
	}

	.xl\:text-teal {
		color: #4dc0b5;
	}

	.xl\:text-blue {
		color: #3490dc;
	}

	.xl\:text-indigo {
		color: #6574cd;
	}

	.xl\:text-purple {
		color: #9561e2;
	}

	.xl\:text-pink {
		color: #f66d9b;
	}

	.xl\:text-grey-darkest {
		color: #3d4852;
	}

	.xl\:text-grey-darker {
		color: #606f7b;
	}

	.xl\:text-grey-dark {
		color: #8795a1;
	}

	.xl\:text-grey {
		color: #b8c2cc;
	}

	.xl\:text-grey-light {
		color: #dae1e7;
	}

	.xl\:text-grey-lighter {
		color: #f1f5f8;
	}

	.xl\:text-grey-lightest {
		color: #f8fafc;
	}

	.xl\:text-red-darkest {
		color: #3b0d0c;
	}

	.xl\:text-red-darker {
		color: #621b18;
	}

	.xl\:text-red-dark {
		color: #cc1f1a;
	}

	.xl\:text-red-light {
		color: #ef5753;
	}

	.xl\:text-red-lighter {
		color: #f9acaa;
	}

	.xl\:text-red-lightest {
		color: #fcebea;
	}

	.xl\:text-orange-darkest {
		color: #462a16;
	}

	.xl\:text-orange-darker {
		color: #613b1f;
	}

	.xl\:text-orange-dark {
		color: #de751f;
	}

	.xl\:text-orange-light {
		color: #faad63;
	}

	.xl\:text-orange-lighter {
		color: #fcd9b6;
	}

	.xl\:text-orange-lightest {
		color: #fff5eb;
	}

	.xl\:text-yellow-darkest {
		color: #453411;
	}

	.xl\:text-yellow-darker {
		color: #684f1d;
	}

	.xl\:text-yellow-dark {
		color: #f2d024;
	}

	.xl\:text-yellow-light {
		color: #fff382;
	}

	.xl\:text-yellow-lighter {
		color: #fff9c2;
	}

	.xl\:text-yellow-lightest {
		color: #fcfbeb;
	}

	.xl\:text-green-darkest {
		color: #0f2f21;
	}

	.xl\:text-green-darker {
		color: #1a4731;
	}

	.xl\:text-green-dark {
		color: #1f9d55;
	}

	.xl\:text-green-light {
		color: #51d88a;
	}

	.xl\:text-green-lighter {
		color: #a2f5bf;
	}

	.xl\:text-green-lightest {
		color: #e3fcec;
	}

	.xl\:text-teal-darkest {
		color: #0d3331;
	}

	.xl\:text-teal-darker {
		color: #20504f;
	}

	.xl\:text-teal-dark {
		color: #38a89d;
	}

	.xl\:text-teal-light {
		color: #64d5ca;
	}

	.xl\:text-teal-lighter {
		color: #a0f0ed;
	}

	.xl\:text-teal-lightest {
		color: #e8fffe;
	}

	.xl\:text-blue-darkest {
		color: #12283a;
	}

	.xl\:text-blue-darker {
		color: #1c3d5a;
	}

	.xl\:text-blue-dark {
		color: #2779bd;
	}

	.xl\:text-blue-light {
		color: #6cb2eb;
	}

	.xl\:text-blue-lighter {
		color: #bcdefa;
	}

	.xl\:text-blue-lightest {
		color: #eff8ff;
	}

	.xl\:text-indigo-darkest {
		color: #191e38;
	}

	.xl\:text-indigo-darker {
		color: #2f365f;
	}

	.xl\:text-indigo-dark {
		color: #5661b3;
	}

	.xl\:text-indigo-light {
		color: #7886d7;
	}

	.xl\:text-indigo-lighter {
		color: #b2b7ff;
	}

	.xl\:text-indigo-lightest {
		color: #e6e8ff;
	}

	.xl\:text-purple-darkest {
		color: #21183c;
	}

	.xl\:text-purple-darker {
		color: #382b5f;
	}

	.xl\:text-purple-dark {
		color: #794acf;
	}

	.xl\:text-purple-light {
		color: #a779e9;
	}

	.xl\:text-purple-lighter {
		color: #d6bbfc;
	}

	.xl\:text-purple-lightest {
		color: #f3ebff;
	}

	.xl\:text-pink-darkest {
		color: #451225;
	}

	.xl\:text-pink-darker {
		color: #6f213f;
	}

	.xl\:text-pink-dark {
		color: #eb5286;
	}

	.xl\:text-pink-light {
		color: #fa7ea8;
	}

	.xl\:text-pink-lighter {
		color: #ffbbca;
	}

	.xl\:text-pink-lightest {
		color: #ffebef;
	}

	.xl\:hover\:text-transparent:hover {
		color: transparent;
	}

	.xl\:hover\:text-black:hover {
		color: #22292f;
	}

	.xl\:hover\:text-white:hover {
		color: #ffffff;
	}

	.xl\:hover\:text-gray-100:hover {
		color: #f7fafc;
	}

	.xl\:hover\:text-gray-200:hover {
		color: #edf2f7;
	}

	.xl\:hover\:text-gray-300:hover {
		color: #e2e8f0;
	}

	.xl\:hover\:text-gray-400:hover {
		color: #cbd5e0;
	}

	.xl\:hover\:text-gray-500:hover {
		color: #a0aec0;
	}

	.xl\:hover\:text-gray-600:hover {
		color: #718096;
	}

	.xl\:hover\:text-gray-700:hover {
		color: #4a5568;
	}

	.xl\:hover\:text-gray-800:hover {
		color: #2d3748;
	}

	.xl\:hover\:text-gray-900:hover {
		color: #1a202c;
	}

	.xl\:hover\:text-red:hover {
		color: #e3342f;
	}

	.xl\:hover\:text-orange:hover {
		color: #f6993f;
	}

	.xl\:hover\:text-yellow:hover {
		color: #ffed4a;
	}

	.xl\:hover\:text-green:hover {
		color: #38c172;
	}

	.xl\:hover\:text-teal:hover {
		color: #4dc0b5;
	}

	.xl\:hover\:text-blue:hover {
		color: #3490dc;
	}

	.xl\:hover\:text-indigo:hover {
		color: #6574cd;
	}

	.xl\:hover\:text-purple:hover {
		color: #9561e2;
	}

	.xl\:hover\:text-pink:hover {
		color: #f66d9b;
	}

	.xl\:hover\:text-grey-darkest:hover {
		color: #3d4852;
	}

	.xl\:hover\:text-grey-darker:hover {
		color: #606f7b;
	}

	.xl\:hover\:text-grey-dark:hover {
		color: #8795a1;
	}

	.xl\:hover\:text-grey:hover {
		color: #b8c2cc;
	}

	.xl\:hover\:text-grey-light:hover {
		color: #dae1e7;
	}

	.xl\:hover\:text-grey-lighter:hover {
		color: #f1f5f8;
	}

	.xl\:hover\:text-grey-lightest:hover {
		color: #f8fafc;
	}

	.xl\:hover\:text-red-darkest:hover {
		color: #3b0d0c;
	}

	.xl\:hover\:text-red-darker:hover {
		color: #621b18;
	}

	.xl\:hover\:text-red-dark:hover {
		color: #cc1f1a;
	}

	.xl\:hover\:text-red-light:hover {
		color: #ef5753;
	}

	.xl\:hover\:text-red-lighter:hover {
		color: #f9acaa;
	}

	.xl\:hover\:text-red-lightest:hover {
		color: #fcebea;
	}

	.xl\:hover\:text-orange-darkest:hover {
		color: #462a16;
	}

	.xl\:hover\:text-orange-darker:hover {
		color: #613b1f;
	}

	.xl\:hover\:text-orange-dark:hover {
		color: #de751f;
	}

	.xl\:hover\:text-orange-light:hover {
		color: #faad63;
	}

	.xl\:hover\:text-orange-lighter:hover {
		color: #fcd9b6;
	}

	.xl\:hover\:text-orange-lightest:hover {
		color: #fff5eb;
	}

	.xl\:hover\:text-yellow-darkest:hover {
		color: #453411;
	}

	.xl\:hover\:text-yellow-darker:hover {
		color: #684f1d;
	}

	.xl\:hover\:text-yellow-dark:hover {
		color: #f2d024;
	}

	.xl\:hover\:text-yellow-light:hover {
		color: #fff382;
	}

	.xl\:hover\:text-yellow-lighter:hover {
		color: #fff9c2;
	}

	.xl\:hover\:text-yellow-lightest:hover {
		color: #fcfbeb;
	}

	.xl\:hover\:text-green-darkest:hover {
		color: #0f2f21;
	}

	.xl\:hover\:text-green-darker:hover {
		color: #1a4731;
	}

	.xl\:hover\:text-green-dark:hover {
		color: #1f9d55;
	}

	.xl\:hover\:text-green-light:hover {
		color: #51d88a;
	}

	.xl\:hover\:text-green-lighter:hover {
		color: #a2f5bf;
	}

	.xl\:hover\:text-green-lightest:hover {
		color: #e3fcec;
	}

	.xl\:hover\:text-teal-darkest:hover {
		color: #0d3331;
	}

	.xl\:hover\:text-teal-darker:hover {
		color: #20504f;
	}

	.xl\:hover\:text-teal-dark:hover {
		color: #38a89d;
	}

	.xl\:hover\:text-teal-light:hover {
		color: #64d5ca;
	}

	.xl\:hover\:text-teal-lighter:hover {
		color: #a0f0ed;
	}

	.xl\:hover\:text-teal-lightest:hover {
		color: #e8fffe;
	}

	.xl\:hover\:text-blue-darkest:hover {
		color: #12283a;
	}

	.xl\:hover\:text-blue-darker:hover {
		color: #1c3d5a;
	}

	.xl\:hover\:text-blue-dark:hover {
		color: #2779bd;
	}

	.xl\:hover\:text-blue-light:hover {
		color: #6cb2eb;
	}

	.xl\:hover\:text-blue-lighter:hover {
		color: #bcdefa;
	}

	.xl\:hover\:text-blue-lightest:hover {
		color: #eff8ff;
	}

	.xl\:hover\:text-indigo-darkest:hover {
		color: #191e38;
	}

	.xl\:hover\:text-indigo-darker:hover {
		color: #2f365f;
	}

	.xl\:hover\:text-indigo-dark:hover {
		color: #5661b3;
	}

	.xl\:hover\:text-indigo-light:hover {
		color: #7886d7;
	}

	.xl\:hover\:text-indigo-lighter:hover {
		color: #b2b7ff;
	}

	.xl\:hover\:text-indigo-lightest:hover {
		color: #e6e8ff;
	}

	.xl\:hover\:text-purple-darkest:hover {
		color: #21183c;
	}

	.xl\:hover\:text-purple-darker:hover {
		color: #382b5f;
	}

	.xl\:hover\:text-purple-dark:hover {
		color: #794acf;
	}

	.xl\:hover\:text-purple-light:hover {
		color: #a779e9;
	}

	.xl\:hover\:text-purple-lighter:hover {
		color: #d6bbfc;
	}

	.xl\:hover\:text-purple-lightest:hover {
		color: #f3ebff;
	}

	.xl\:hover\:text-pink-darkest:hover {
		color: #451225;
	}

	.xl\:hover\:text-pink-darker:hover {
		color: #6f213f;
	}

	.xl\:hover\:text-pink-dark:hover {
		color: #eb5286;
	}

	.xl\:hover\:text-pink-light:hover {
		color: #fa7ea8;
	}

	.xl\:hover\:text-pink-lighter:hover {
		color: #ffbbca;
	}

	.xl\:hover\:text-pink-lightest:hover {
		color: #ffebef;
	}

	.xl\:focus\:text-transparent:focus {
		color: transparent;
	}

	.xl\:focus\:text-black:focus {
		color: #22292f;
	}

	.xl\:focus\:text-white:focus {
		color: #ffffff;
	}

	.xl\:focus\:text-gray-100:focus {
		color: #f7fafc;
	}

	.xl\:focus\:text-gray-200:focus {
		color: #edf2f7;
	}

	.xl\:focus\:text-gray-300:focus {
		color: #e2e8f0;
	}

	.xl\:focus\:text-gray-400:focus {
		color: #cbd5e0;
	}

	.xl\:focus\:text-gray-500:focus {
		color: #a0aec0;
	}

	.xl\:focus\:text-gray-600:focus {
		color: #718096;
	}

	.xl\:focus\:text-gray-700:focus {
		color: #4a5568;
	}

	.xl\:focus\:text-gray-800:focus {
		color: #2d3748;
	}

	.xl\:focus\:text-gray-900:focus {
		color: #1a202c;
	}

	.xl\:focus\:text-red:focus {
		color: #e3342f;
	}

	.xl\:focus\:text-orange:focus {
		color: #f6993f;
	}

	.xl\:focus\:text-yellow:focus {
		color: #ffed4a;
	}

	.xl\:focus\:text-green:focus {
		color: #38c172;
	}

	.xl\:focus\:text-teal:focus {
		color: #4dc0b5;
	}

	.xl\:focus\:text-blue:focus {
		color: #3490dc;
	}

	.xl\:focus\:text-indigo:focus {
		color: #6574cd;
	}

	.xl\:focus\:text-purple:focus {
		color: #9561e2;
	}

	.xl\:focus\:text-pink:focus {
		color: #f66d9b;
	}

	.xl\:focus\:text-grey-darkest:focus {
		color: #3d4852;
	}

	.xl\:focus\:text-grey-darker:focus {
		color: #606f7b;
	}

	.xl\:focus\:text-grey-dark:focus {
		color: #8795a1;
	}

	.xl\:focus\:text-grey:focus {
		color: #b8c2cc;
	}

	.xl\:focus\:text-grey-light:focus {
		color: #dae1e7;
	}

	.xl\:focus\:text-grey-lighter:focus {
		color: #f1f5f8;
	}

	.xl\:focus\:text-grey-lightest:focus {
		color: #f8fafc;
	}

	.xl\:focus\:text-red-darkest:focus {
		color: #3b0d0c;
	}

	.xl\:focus\:text-red-darker:focus {
		color: #621b18;
	}

	.xl\:focus\:text-red-dark:focus {
		color: #cc1f1a;
	}

	.xl\:focus\:text-red-light:focus {
		color: #ef5753;
	}

	.xl\:focus\:text-red-lighter:focus {
		color: #f9acaa;
	}

	.xl\:focus\:text-red-lightest:focus {
		color: #fcebea;
	}

	.xl\:focus\:text-orange-darkest:focus {
		color: #462a16;
	}

	.xl\:focus\:text-orange-darker:focus {
		color: #613b1f;
	}

	.xl\:focus\:text-orange-dark:focus {
		color: #de751f;
	}

	.xl\:focus\:text-orange-light:focus {
		color: #faad63;
	}

	.xl\:focus\:text-orange-lighter:focus {
		color: #fcd9b6;
	}

	.xl\:focus\:text-orange-lightest:focus {
		color: #fff5eb;
	}

	.xl\:focus\:text-yellow-darkest:focus {
		color: #453411;
	}

	.xl\:focus\:text-yellow-darker:focus {
		color: #684f1d;
	}

	.xl\:focus\:text-yellow-dark:focus {
		color: #f2d024;
	}

	.xl\:focus\:text-yellow-light:focus {
		color: #fff382;
	}

	.xl\:focus\:text-yellow-lighter:focus {
		color: #fff9c2;
	}

	.xl\:focus\:text-yellow-lightest:focus {
		color: #fcfbeb;
	}

	.xl\:focus\:text-green-darkest:focus {
		color: #0f2f21;
	}

	.xl\:focus\:text-green-darker:focus {
		color: #1a4731;
	}

	.xl\:focus\:text-green-dark:focus {
		color: #1f9d55;
	}

	.xl\:focus\:text-green-light:focus {
		color: #51d88a;
	}

	.xl\:focus\:text-green-lighter:focus {
		color: #a2f5bf;
	}

	.xl\:focus\:text-green-lightest:focus {
		color: #e3fcec;
	}

	.xl\:focus\:text-teal-darkest:focus {
		color: #0d3331;
	}

	.xl\:focus\:text-teal-darker:focus {
		color: #20504f;
	}

	.xl\:focus\:text-teal-dark:focus {
		color: #38a89d;
	}

	.xl\:focus\:text-teal-light:focus {
		color: #64d5ca;
	}

	.xl\:focus\:text-teal-lighter:focus {
		color: #a0f0ed;
	}

	.xl\:focus\:text-teal-lightest:focus {
		color: #e8fffe;
	}

	.xl\:focus\:text-blue-darkest:focus {
		color: #12283a;
	}

	.xl\:focus\:text-blue-darker:focus {
		color: #1c3d5a;
	}

	.xl\:focus\:text-blue-dark:focus {
		color: #2779bd;
	}

	.xl\:focus\:text-blue-light:focus {
		color: #6cb2eb;
	}

	.xl\:focus\:text-blue-lighter:focus {
		color: #bcdefa;
	}

	.xl\:focus\:text-blue-lightest:focus {
		color: #eff8ff;
	}

	.xl\:focus\:text-indigo-darkest:focus {
		color: #191e38;
	}

	.xl\:focus\:text-indigo-darker:focus {
		color: #2f365f;
	}

	.xl\:focus\:text-indigo-dark:focus {
		color: #5661b3;
	}

	.xl\:focus\:text-indigo-light:focus {
		color: #7886d7;
	}

	.xl\:focus\:text-indigo-lighter:focus {
		color: #b2b7ff;
	}

	.xl\:focus\:text-indigo-lightest:focus {
		color: #e6e8ff;
	}

	.xl\:focus\:text-purple-darkest:focus {
		color: #21183c;
	}

	.xl\:focus\:text-purple-darker:focus {
		color: #382b5f;
	}

	.xl\:focus\:text-purple-dark:focus {
		color: #794acf;
	}

	.xl\:focus\:text-purple-light:focus {
		color: #a779e9;
	}

	.xl\:focus\:text-purple-lighter:focus {
		color: #d6bbfc;
	}

	.xl\:focus\:text-purple-lightest:focus {
		color: #f3ebff;
	}

	.xl\:focus\:text-pink-darkest:focus {
		color: #451225;
	}

	.xl\:focus\:text-pink-darker:focus {
		color: #6f213f;
	}

	.xl\:focus\:text-pink-dark:focus {
		color: #eb5286;
	}

	.xl\:focus\:text-pink-light:focus {
		color: #fa7ea8;
	}

	.xl\:focus\:text-pink-lighter:focus {
		color: #ffbbca;
	}

	.xl\:focus\:text-pink-lightest:focus {
		color: #ffebef;
	}

	.xl\:text-10 {
		font-size: 1rem;
	}

	.xl\:text-11 {
		font-size: 1.1rem;
	}

	.xl\:text-12 {
		font-size: 1.2rem;
	}

	.xl\:text-13 {
		font-size: 1.3rem;
	}

	.xl\:text-14 {
		font-size: 1.4rem;
	}

	.xl\:text-15 {
		font-size: 1.5rem;
	}

	.xl\:text-16 {
		font-size: 1.6rem;
	}

	.xl\:text-17 {
		font-size: 1.7rem;
	}

	.xl\:text-18 {
		font-size: 1.8rem;
	}

	.xl\:text-19 {
		font-size: 1.9rem;
	}

	.xl\:text-20 {
		font-size: 2rem;
	}

	.xl\:text-24 {
		font-size: 2.4rem;
	}

	.xl\:text-28 {
		font-size: 2.8rem;
	}

	.xl\:text-32 {
		font-size: 3.2rem;
	}

	.xl\:text-36 {
		font-size: 3.6rem;
	}

	.xl\:text-40 {
		font-size: 4rem;
	}

	.xl\:text-44 {
		font-size: 4.4rem;
	}

	.xl\:text-48 {
		font-size: 4.8rem;
	}

	.xl\:text-52 {
		font-size: 5.2rem;
	}

	.xl\:text-56 {
		font-size: 5.6rem;
	}

	.xl\:text-60 {
		font-size: 6rem;
	}

	.xl\:text-64 {
		font-size: 6.4rem;
	}

	.xl\:text-68 {
		font-size: 6.8rem;
	}

	.xl\:text-72 {
		font-size: 7.2rem;
	}

	.xl\:text-96 {
		font-size: 9.6rem;
	}

	.xl\:text-128 {
		font-size: 12.8rem;
	}

	.xl\:text-xs {
		font-size: 1.2rem;
	}

	.xl\:text-sm {
		font-size: 2.4rem;
	}

	.xl\:text-base {
		font-size: 1.6rem;
	}

	.xl\:text-lg {
		font-size: 1.8rem;
	}

	.xl\:text-xl {
		font-size: 2rem;
	}

	.xl\:text-2xl {
		font-size: 2.4rem;
	}

	.xl\:text-3xl {
		font-size: 3rem;
	}

	.xl\:text-4xl {
		font-size: 3.6rem;
	}

	.xl\:text-5xl {
		font-size: 4.8rem;
	}

	.xl\:text-6xl {
		font-size: 4rem;
	}

	.xl\:italic {
		font-style: italic;
	}

	.xl\:not-italic {
		font-style: normal;
	}

	.xl\:hover\:italic:hover {
		font-style: italic;
	}

	.xl\:hover\:not-italic:hover {
		font-style: normal;
	}

	.xl\:focus\:italic:focus {
		font-style: italic;
	}

	.xl\:focus\:not-italic:focus {
		font-style: normal;
	}

	.xl\:uppercase {
		text-transform: uppercase;
	}

	.xl\:lowercase {
		text-transform: lowercase;
	}

	.xl\:capitalize {
		text-transform: capitalize;
	}

	.xl\:normal-case {
		text-transform: none;
	}

	.xl\:underline {
		text-decoration: underline;
	}

	.xl\:line-through {
		text-decoration: line-through;
	}

	.xl\:no-underline {
		text-decoration: none;
	}

	.xl\:hover\:underline:hover {
		text-decoration: underline;
	}

	.xl\:hover\:line-through:hover {
		text-decoration: line-through;
	}

	.xl\:hover\:no-underline:hover {
		text-decoration: none;
	}

	.xl\:focus\:underline:focus {
		text-decoration: underline;
	}

	.xl\:focus\:line-through:focus {
		text-decoration: line-through;
	}

	.xl\:focus\:no-underline:focus {
		text-decoration: none;
	}

	.xl\:antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.xl\:subpixel-antialiased {
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;
	}

	.xl\:tracking-tighter {
		letter-spacing: -0.05em;
	}

	.xl\:tracking-tight {
		letter-spacing: -0.025em;
	}

	.xl\:tracking-normal {
		letter-spacing: 0;
	}

	.xl\:tracking-wide {
		letter-spacing: 0.025em;
	}

	.xl\:tracking-wider {
		letter-spacing: 0.05em;
	}

	.xl\:tracking-widest {
		letter-spacing: 0.1em;
	}

	.xl\:select-none {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.xl\:select-text {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.xl\:select-all {
		-webkit-user-select: all;
		-moz-user-select: all;
		-ms-user-select: all;
		user-select: all;
	}

	.xl\:select-auto {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.xl\:align-baseline {
		vertical-align: baseline;
	}

	.xl\:align-top {
		vertical-align: top;
	}

	.xl\:align-middle {
		vertical-align: middle;
	}

	.xl\:align-bottom {
		vertical-align: bottom;
	}

	.xl\:align-text-top {
		vertical-align: text-top;
	}

	.xl\:align-text-bottom {
		vertical-align: text-bottom;
	}

	.xl\:visible {
		visibility: visible;
	}

	.xl\:invisible {
		visibility: hidden;
	}

	.xl\:whitespace-normal {
		white-space: normal;
	}

	.xl\:whitespace-no-wrap {
		white-space: nowrap;
	}

	.xl\:whitespace-pre {
		white-space: pre;
	}

	.xl\:whitespace-pre-line {
		white-space: pre-line;
	}

	.xl\:whitespace-pre-wrap {
		white-space: pre-wrap;
	}

	.xl\:break-normal {
		overflow-wrap: normal;
		word-break: normal;
	}

	.xl\:break-words {
		overflow-wrap: break-word;
	}

	.xl\:break-all {
		word-break: break-all;
	}

	.xl\:truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.xl\:w-0 {
		width: 0;
	}

	.xl\:w-1 {
		width: 0.25rem;
	}

	.xl\:w-2 {
		width: 0.5rem;
	}

	.xl\:w-3 {
		width: 0.75rem;
	}

	.xl\:w-4 {
		width: 0.4rem;
	}

	.xl\:w-5 {
		width: 1.25rem;
	}

	.xl\:w-6 {
		width: 0.6rem;
	}

	.xl\:w-8 {
		width: 0.8rem;
	}

	.xl\:w-10 {
		width: 2.5rem;
	}

	.xl\:w-12 {
		width: 1.2rem;
	}

	.xl\:w-16 {
		width: 1.6rem;
	}

	.xl\:w-20 {
		width: 2rem;
	}

	.xl\:w-24 {
		width: 2.4rem;
	}

	.xl\:w-28 {
		width: 2.8rem;
	}

	.xl\:w-32 {
		width: 3.2rem;
	}

	.xl\:w-36 {
		width: 3.6rem;
	}

	.xl\:w-40 {
		width: 4rem;
	}

	.xl\:w-44 {
		width: 4.4rem;
	}

	.xl\:w-48 {
		width: 4.8rem;
	}

	.xl\:w-52 {
		width: 5.2rem;
	}

	.xl\:w-56 {
		width: 5.6rem;
	}

	.xl\:w-60 {
		width: 6rem;
	}

	.xl\:w-64 {
		width: 6.4rem;
	}

	.xl\:w-68 {
		width: 6.8rem;
	}

	.xl\:w-72 {
		width: 7.2rem;
	}

	.xl\:w-76 {
		width: 7.6rem;
	}

	.xl\:w-80 {
		width: 8rem;
	}

	.xl\:w-84 {
		width: 8.4rem;
	}

	.xl\:w-88 {
		width: 8.8rem;
	}

	.xl\:w-92 {
		width: 9.2rem;
	}

	.xl\:w-96 {
		width: 9.6rem;
	}

	.xl\:w-128 {
		width: 12.8rem;
	}

	.xl\:w-136 {
		width: 13.6rem;
	}

	.xl\:w-160 {
		width: 16rem;
	}

	.xl\:w-192 {
		width: 19.2rem;
	}

	.xl\:w-200 {
		width: 20rem;
	}

	.xl\:w-208 {
		width: 20.8rem;
	}

	.xl\:w-216 {
		width: 21.6rem;
	}

	.xl\:w-224 {
		width: 22.4rem;
	}

	.xl\:w-256 {
		width: 25.6rem;
	}

	.xl\:w-288 {
		width: 28.8rem;
	}

	.xl\:w-320 {
		width: 32rem;
	}

	.xl\:w-360 {
		width: 36rem;
	}

	.xl\:w-384 {
		width: 38.4rem;
	}

	.xl\:w-400 {
		width: 40rem;
	}

	.xl\:w-512 {
		width: 51.2rem;
	}

	.xl\:w-640 {
		width: 64rem;
	}

	.xl\:w-auto {
		width: auto;
	}

	.xl\:w-px {
		width: 1px;
	}

	.xl\:w-xs {
		width: 32rem;
	}

	.xl\:w-sm {
		width: 48rem;
	}

	.xl\:w-md {
		width: 64rem;
	}

	.xl\:w-lg {
		width: 80rem;
	}

	.xl\:w-xl {
		width: 96rem;
	}

	.xl\:w-2xl {
		width: 112rem;
	}

	.xl\:w-3xl {
		width: 128rem;
	}

	.xl\:w-4xl {
		width: 144rem;
	}

	.xl\:w-5xl {
		width: 160rem;
	}

	.xl\:w-1\/2 {
		width: 50%;
	}

	.xl\:w-1\/3 {
		width: 33.33333%;
	}

	.xl\:w-2\/3 {
		width: 66.66667%;
	}

	.xl\:w-1\/4 {
		width: 25%;
	}

	.xl\:w-2\/4 {
		width: 50%;
	}

	.xl\:w-3\/4 {
		width: 75%;
	}

	.xl\:w-1\/5 {
		width: 20%;
	}

	.xl\:w-2\/5 {
		width: 40%;
	}

	.xl\:w-3\/5 {
		width: 60%;
	}

	.xl\:w-4\/5 {
		width: 80%;
	}

	.xl\:w-1\/6 {
		width: 16.66667%;
	}

	.xl\:w-2\/6 {
		width: 33.33333%;
	}

	.xl\:w-3\/6 {
		width: 50%;
	}

	.xl\:w-4\/6 {
		width: 66.66667%;
	}

	.xl\:w-5\/6 {
		width: 83.33333%;
	}

	.xl\:w-1\/12 {
		width: 8.33333%;
	}

	.xl\:w-2\/12 {
		width: 16.66667%;
	}

	.xl\:w-3\/12 {
		width: 25%;
	}

	.xl\:w-4\/12 {
		width: 33.33333%;
	}

	.xl\:w-5\/12 {
		width: 41.66667%;
	}

	.xl\:w-6\/12 {
		width: 50%;
	}

	.xl\:w-7\/12 {
		width: 58.33333%;
	}

	.xl\:w-8\/12 {
		width: 66.66667%;
	}

	.xl\:w-9\/12 {
		width: 75%;
	}

	.xl\:w-10\/12 {
		width: 83.33333%;
	}

	.xl\:w-11\/12 {
		width: 91.66667%;
	}

	.xl\:w-full {
		width: 100%;
	}

	.xl\:w-screen {
		width: 100vw;
	}

	.xl\:z-0 {
		z-index: 0;
	}

	.xl\:z-10 {
		z-index: 10;
	}

	.xl\:z-20 {
		z-index: 20;
	}

	.xl\:z-30 {
		z-index: 30;
	}

	.xl\:z-40 {
		z-index: 40;
	}

	.xl\:z-50 {
		z-index: 50;
	}

	.xl\:z-99 {
		z-index: 99;
	}

	.xl\:z-999 {
		z-index: 999;
	}

	.xl\:z-9999 {
		z-index: 9999;
	}

	.xl\:z-auto {
		z-index: auto;
	}
}

@media print {
	.print\:appearance-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.print\:bg-fixed {
		background-attachment: fixed;
	}

	.print\:bg-local {
		background-attachment: local;
	}

	.print\:bg-scroll {
		background-attachment: scroll;
	}

	.print\:bg-transparent {
		background-color: transparent;
	}

	.print\:bg-black {
		background-color: #22292f;
	}

	.print\:bg-white {
		background-color: #ffffff;
	}

	.print\:bg-gray-100 {
		background-color: #f7fafc;
	}

	.print\:bg-gray-200 {
		background-color: #edf2f7;
	}

	.print\:bg-gray-300 {
		background-color: #e2e8f0;
	}

	.print\:bg-gray-400 {
		background-color: #cbd5e0;
	}

	.print\:bg-gray-500 {
		background-color: #a0aec0;
	}

	.print\:bg-gray-600 {
		background-color: #718096;
	}

	.print\:bg-gray-700 {
		background-color: #4a5568;
	}

	.print\:bg-gray-800 {
		background-color: #2d3748;
	}

	.print\:bg-gray-900 {
		background-color: #1a202c;
	}

	.print\:bg-red {
		background-color: #e3342f;
	}

	.print\:bg-orange {
		background-color: #f6993f;
	}

	.print\:bg-yellow {
		background-color: #ffed4a;
	}

	.print\:bg-green {
		background-color: #38c172;
	}

	.print\:bg-teal {
		background-color: #4dc0b5;
	}

	.print\:bg-blue {
		background-color: #3490dc;
	}

	.print\:bg-indigo {
		background-color: #6574cd;
	}

	.print\:bg-purple {
		background-color: #9561e2;
	}

	.print\:bg-pink {
		background-color: #f66d9b;
	}

	.print\:bg-grey-darkest {
		background-color: #3d4852;
	}

	.print\:bg-grey-darker {
		background-color: #606f7b;
	}

	.print\:bg-grey-dark {
		background-color: #8795a1;
	}

	.print\:bg-grey {
		background-color: #b8c2cc;
	}

	.print\:bg-grey-light {
		background-color: #dae1e7;
	}

	.print\:bg-grey-lighter {
		background-color: #f1f5f8;
	}

	.print\:bg-grey-lightest {
		background-color: #f8fafc;
	}

	.print\:bg-red-darkest {
		background-color: #3b0d0c;
	}

	.print\:bg-red-darker {
		background-color: #621b18;
	}

	.print\:bg-red-dark {
		background-color: #cc1f1a;
	}

	.print\:bg-red-light {
		background-color: #ef5753;
	}

	.print\:bg-red-lighter {
		background-color: #f9acaa;
	}

	.print\:bg-red-lightest {
		background-color: #fcebea;
	}

	.print\:bg-orange-darkest {
		background-color: #462a16;
	}

	.print\:bg-orange-darker {
		background-color: #613b1f;
	}

	.print\:bg-orange-dark {
		background-color: #de751f;
	}

	.print\:bg-orange-light {
		background-color: #faad63;
	}

	.print\:bg-orange-lighter {
		background-color: #fcd9b6;
	}

	.print\:bg-orange-lightest {
		background-color: #fff5eb;
	}

	.print\:bg-yellow-darkest {
		background-color: #453411;
	}

	.print\:bg-yellow-darker {
		background-color: #684f1d;
	}

	.print\:bg-yellow-dark {
		background-color: #f2d024;
	}

	.print\:bg-yellow-light {
		background-color: #fff382;
	}

	.print\:bg-yellow-lighter {
		background-color: #fff9c2;
	}

	.print\:bg-yellow-lightest {
		background-color: #fcfbeb;
	}

	.print\:bg-green-darkest {
		background-color: #0f2f21;
	}

	.print\:bg-green-darker {
		background-color: #1a4731;
	}

	.print\:bg-green-dark {
		background-color: #1f9d55;
	}

	.print\:bg-green-light {
		background-color: #51d88a;
	}

	.print\:bg-green-lighter {
		background-color: #a2f5bf;
	}

	.print\:bg-green-lightest {
		background-color: #e3fcec;
	}

	.print\:bg-teal-darkest {
		background-color: #0d3331;
	}

	.print\:bg-teal-darker {
		background-color: #20504f;
	}

	.print\:bg-teal-dark {
		background-color: #38a89d;
	}

	.print\:bg-teal-light {
		background-color: #64d5ca;
	}

	.print\:bg-teal-lighter {
		background-color: #a0f0ed;
	}

	.print\:bg-teal-lightest {
		background-color: #e8fffe;
	}

	.print\:bg-blue-darkest {
		background-color: #12283a;
	}

	.print\:bg-blue-darker {
		background-color: #1c3d5a;
	}

	.print\:bg-blue-dark {
		background-color: #2779bd;
	}

	.print\:bg-blue-light {
		background-color: #6cb2eb;
	}

	.print\:bg-blue-lighter {
		background-color: #bcdefa;
	}

	.print\:bg-blue-lightest {
		background-color: #eff8ff;
	}

	.print\:bg-indigo-darkest {
		background-color: #191e38;
	}

	.print\:bg-indigo-darker {
		background-color: #2f365f;
	}

	.print\:bg-indigo-dark {
		background-color: #5661b3;
	}

	.print\:bg-indigo-light {
		background-color: #7886d7;
	}

	.print\:bg-indigo-lighter {
		background-color: #b2b7ff;
	}

	.print\:bg-indigo-lightest {
		background-color: #e6e8ff;
	}

	.print\:bg-purple-darkest {
		background-color: #21183c;
	}

	.print\:bg-purple-darker {
		background-color: #382b5f;
	}

	.print\:bg-purple-dark {
		background-color: #794acf;
	}

	.print\:bg-purple-light {
		background-color: #a779e9;
	}

	.print\:bg-purple-lighter {
		background-color: #d6bbfc;
	}

	.print\:bg-purple-lightest {
		background-color: #f3ebff;
	}

	.print\:bg-pink-darkest {
		background-color: #451225;
	}

	.print\:bg-pink-darker {
		background-color: #6f213f;
	}

	.print\:bg-pink-dark {
		background-color: #eb5286;
	}

	.print\:bg-pink-light {
		background-color: #fa7ea8;
	}

	.print\:bg-pink-lighter {
		background-color: #ffbbca;
	}

	.print\:bg-pink-lightest {
		background-color: #ffebef;
	}

	.print\:hover\:bg-transparent:hover {
		background-color: transparent;
	}

	.print\:hover\:bg-black:hover {
		background-color: #22292f;
	}

	.print\:hover\:bg-white:hover {
		background-color: #ffffff;
	}

	.print\:hover\:bg-gray-100:hover {
		background-color: #f7fafc;
	}

	.print\:hover\:bg-gray-200:hover {
		background-color: #edf2f7;
	}

	.print\:hover\:bg-gray-300:hover {
		background-color: #e2e8f0;
	}

	.print\:hover\:bg-gray-400:hover {
		background-color: #cbd5e0;
	}

	.print\:hover\:bg-gray-500:hover {
		background-color: #a0aec0;
	}

	.print\:hover\:bg-gray-600:hover {
		background-color: #718096;
	}

	.print\:hover\:bg-gray-700:hover {
		background-color: #4a5568;
	}

	.print\:hover\:bg-gray-800:hover {
		background-color: #2d3748;
	}

	.print\:hover\:bg-gray-900:hover {
		background-color: #1a202c;
	}

	.print\:hover\:bg-red:hover {
		background-color: #e3342f;
	}

	.print\:hover\:bg-orange:hover {
		background-color: #f6993f;
	}

	.print\:hover\:bg-yellow:hover {
		background-color: #ffed4a;
	}

	.print\:hover\:bg-green:hover {
		background-color: #38c172;
	}

	.print\:hover\:bg-teal:hover {
		background-color: #4dc0b5;
	}

	.print\:hover\:bg-blue:hover {
		background-color: #3490dc;
	}

	.print\:hover\:bg-indigo:hover {
		background-color: #6574cd;
	}

	.print\:hover\:bg-purple:hover {
		background-color: #9561e2;
	}

	.print\:hover\:bg-pink:hover {
		background-color: #f66d9b;
	}

	.print\:hover\:bg-grey-darkest:hover {
		background-color: #3d4852;
	}

	.print\:hover\:bg-grey-darker:hover {
		background-color: #606f7b;
	}

	.print\:hover\:bg-grey-dark:hover {
		background-color: #8795a1;
	}

	.print\:hover\:bg-grey:hover {
		background-color: #b8c2cc;
	}

	.print\:hover\:bg-grey-light:hover {
		background-color: #dae1e7;
	}

	.print\:hover\:bg-grey-lighter:hover {
		background-color: #f1f5f8;
	}

	.print\:hover\:bg-grey-lightest:hover {
		background-color: #f8fafc;
	}

	.print\:hover\:bg-red-darkest:hover {
		background-color: #3b0d0c;
	}

	.print\:hover\:bg-red-darker:hover {
		background-color: #621b18;
	}

	.print\:hover\:bg-red-dark:hover {
		background-color: #cc1f1a;
	}

	.print\:hover\:bg-red-light:hover {
		background-color: #ef5753;
	}

	.print\:hover\:bg-red-lighter:hover {
		background-color: #f9acaa;
	}

	.print\:hover\:bg-red-lightest:hover {
		background-color: #fcebea;
	}

	.print\:hover\:bg-orange-darkest:hover {
		background-color: #462a16;
	}

	.print\:hover\:bg-orange-darker:hover {
		background-color: #613b1f;
	}

	.print\:hover\:bg-orange-dark:hover {
		background-color: #de751f;
	}

	.print\:hover\:bg-orange-light:hover {
		background-color: #faad63;
	}

	.print\:hover\:bg-orange-lighter:hover {
		background-color: #fcd9b6;
	}

	.print\:hover\:bg-orange-lightest:hover {
		background-color: #fff5eb;
	}

	.print\:hover\:bg-yellow-darkest:hover {
		background-color: #453411;
	}

	.print\:hover\:bg-yellow-darker:hover {
		background-color: #684f1d;
	}

	.print\:hover\:bg-yellow-dark:hover {
		background-color: #f2d024;
	}

	.print\:hover\:bg-yellow-light:hover {
		background-color: #fff382;
	}

	.print\:hover\:bg-yellow-lighter:hover {
		background-color: #fff9c2;
	}

	.print\:hover\:bg-yellow-lightest:hover {
		background-color: #fcfbeb;
	}

	.print\:hover\:bg-green-darkest:hover {
		background-color: #0f2f21;
	}

	.print\:hover\:bg-green-darker:hover {
		background-color: #1a4731;
	}

	.print\:hover\:bg-green-dark:hover {
		background-color: #1f9d55;
	}

	.print\:hover\:bg-green-light:hover {
		background-color: #51d88a;
	}

	.print\:hover\:bg-green-lighter:hover {
		background-color: #a2f5bf;
	}

	.print\:hover\:bg-green-lightest:hover {
		background-color: #e3fcec;
	}

	.print\:hover\:bg-teal-darkest:hover {
		background-color: #0d3331;
	}

	.print\:hover\:bg-teal-darker:hover {
		background-color: #20504f;
	}

	.print\:hover\:bg-teal-dark:hover {
		background-color: #38a89d;
	}

	.print\:hover\:bg-teal-light:hover {
		background-color: #64d5ca;
	}

	.print\:hover\:bg-teal-lighter:hover {
		background-color: #a0f0ed;
	}

	.print\:hover\:bg-teal-lightest:hover {
		background-color: #e8fffe;
	}

	.print\:hover\:bg-blue-darkest:hover {
		background-color: #12283a;
	}

	.print\:hover\:bg-blue-darker:hover {
		background-color: #1c3d5a;
	}

	.print\:hover\:bg-blue-dark:hover {
		background-color: #2779bd;
	}

	.print\:hover\:bg-blue-light:hover {
		background-color: #6cb2eb;
	}

	.print\:hover\:bg-blue-lighter:hover {
		background-color: #bcdefa;
	}

	.print\:hover\:bg-blue-lightest:hover {
		background-color: #eff8ff;
	}

	.print\:hover\:bg-indigo-darkest:hover {
		background-color: #191e38;
	}

	.print\:hover\:bg-indigo-darker:hover {
		background-color: #2f365f;
	}

	.print\:hover\:bg-indigo-dark:hover {
		background-color: #5661b3;
	}

	.print\:hover\:bg-indigo-light:hover {
		background-color: #7886d7;
	}

	.print\:hover\:bg-indigo-lighter:hover {
		background-color: #b2b7ff;
	}

	.print\:hover\:bg-indigo-lightest:hover {
		background-color: #e6e8ff;
	}

	.print\:hover\:bg-purple-darkest:hover {
		background-color: #21183c;
	}

	.print\:hover\:bg-purple-darker:hover {
		background-color: #382b5f;
	}

	.print\:hover\:bg-purple-dark:hover {
		background-color: #794acf;
	}

	.print\:hover\:bg-purple-light:hover {
		background-color: #a779e9;
	}

	.print\:hover\:bg-purple-lighter:hover {
		background-color: #d6bbfc;
	}

	.print\:hover\:bg-purple-lightest:hover {
		background-color: #f3ebff;
	}

	.print\:hover\:bg-pink-darkest:hover {
		background-color: #451225;
	}

	.print\:hover\:bg-pink-darker:hover {
		background-color: #6f213f;
	}

	.print\:hover\:bg-pink-dark:hover {
		background-color: #eb5286;
	}

	.print\:hover\:bg-pink-light:hover {
		background-color: #fa7ea8;
	}

	.print\:hover\:bg-pink-lighter:hover {
		background-color: #ffbbca;
	}

	.print\:hover\:bg-pink-lightest:hover {
		background-color: #ffebef;
	}

	.print\:focus\:bg-transparent:focus {
		background-color: transparent;
	}

	.print\:focus\:bg-black:focus {
		background-color: #22292f;
	}

	.print\:focus\:bg-white:focus {
		background-color: #ffffff;
	}

	.print\:focus\:bg-gray-100:focus {
		background-color: #f7fafc;
	}

	.print\:focus\:bg-gray-200:focus {
		background-color: #edf2f7;
	}

	.print\:focus\:bg-gray-300:focus {
		background-color: #e2e8f0;
	}

	.print\:focus\:bg-gray-400:focus {
		background-color: #cbd5e0;
	}

	.print\:focus\:bg-gray-500:focus {
		background-color: #a0aec0;
	}

	.print\:focus\:bg-gray-600:focus {
		background-color: #718096;
	}

	.print\:focus\:bg-gray-700:focus {
		background-color: #4a5568;
	}

	.print\:focus\:bg-gray-800:focus {
		background-color: #2d3748;
	}

	.print\:focus\:bg-gray-900:focus {
		background-color: #1a202c;
	}

	.print\:focus\:bg-red:focus {
		background-color: #e3342f;
	}

	.print\:focus\:bg-orange:focus {
		background-color: #f6993f;
	}

	.print\:focus\:bg-yellow:focus {
		background-color: #ffed4a;
	}

	.print\:focus\:bg-green:focus {
		background-color: #38c172;
	}

	.print\:focus\:bg-teal:focus {
		background-color: #4dc0b5;
	}

	.print\:focus\:bg-blue:focus {
		background-color: #3490dc;
	}

	.print\:focus\:bg-indigo:focus {
		background-color: #6574cd;
	}

	.print\:focus\:bg-purple:focus {
		background-color: #9561e2;
	}

	.print\:focus\:bg-pink:focus {
		background-color: #f66d9b;
	}

	.print\:focus\:bg-grey-darkest:focus {
		background-color: #3d4852;
	}

	.print\:focus\:bg-grey-darker:focus {
		background-color: #606f7b;
	}

	.print\:focus\:bg-grey-dark:focus {
		background-color: #8795a1;
	}

	.print\:focus\:bg-grey:focus {
		background-color: #b8c2cc;
	}

	.print\:focus\:bg-grey-light:focus {
		background-color: #dae1e7;
	}

	.print\:focus\:bg-grey-lighter:focus {
		background-color: #f1f5f8;
	}

	.print\:focus\:bg-grey-lightest:focus {
		background-color: #f8fafc;
	}

	.print\:focus\:bg-red-darkest:focus {
		background-color: #3b0d0c;
	}

	.print\:focus\:bg-red-darker:focus {
		background-color: #621b18;
	}

	.print\:focus\:bg-red-dark:focus {
		background-color: #cc1f1a;
	}

	.print\:focus\:bg-red-light:focus {
		background-color: #ef5753;
	}

	.print\:focus\:bg-red-lighter:focus {
		background-color: #f9acaa;
	}

	.print\:focus\:bg-red-lightest:focus {
		background-color: #fcebea;
	}

	.print\:focus\:bg-orange-darkest:focus {
		background-color: #462a16;
	}

	.print\:focus\:bg-orange-darker:focus {
		background-color: #613b1f;
	}

	.print\:focus\:bg-orange-dark:focus {
		background-color: #de751f;
	}

	.print\:focus\:bg-orange-light:focus {
		background-color: #faad63;
	}

	.print\:focus\:bg-orange-lighter:focus {
		background-color: #fcd9b6;
	}

	.print\:focus\:bg-orange-lightest:focus {
		background-color: #fff5eb;
	}

	.print\:focus\:bg-yellow-darkest:focus {
		background-color: #453411;
	}

	.print\:focus\:bg-yellow-darker:focus {
		background-color: #684f1d;
	}

	.print\:focus\:bg-yellow-dark:focus {
		background-color: #f2d024;
	}

	.print\:focus\:bg-yellow-light:focus {
		background-color: #fff382;
	}

	.print\:focus\:bg-yellow-lighter:focus {
		background-color: #fff9c2;
	}

	.print\:focus\:bg-yellow-lightest:focus {
		background-color: #fcfbeb;
	}

	.print\:focus\:bg-green-darkest:focus {
		background-color: #0f2f21;
	}

	.print\:focus\:bg-green-darker:focus {
		background-color: #1a4731;
	}

	.print\:focus\:bg-green-dark:focus {
		background-color: #1f9d55;
	}

	.print\:focus\:bg-green-light:focus {
		background-color: #51d88a;
	}

	.print\:focus\:bg-green-lighter:focus {
		background-color: #a2f5bf;
	}

	.print\:focus\:bg-green-lightest:focus {
		background-color: #e3fcec;
	}

	.print\:focus\:bg-teal-darkest:focus {
		background-color: #0d3331;
	}

	.print\:focus\:bg-teal-darker:focus {
		background-color: #20504f;
	}

	.print\:focus\:bg-teal-dark:focus {
		background-color: #38a89d;
	}

	.print\:focus\:bg-teal-light:focus {
		background-color: #64d5ca;
	}

	.print\:focus\:bg-teal-lighter:focus {
		background-color: #a0f0ed;
	}

	.print\:focus\:bg-teal-lightest:focus {
		background-color: #e8fffe;
	}

	.print\:focus\:bg-blue-darkest:focus {
		background-color: #12283a;
	}

	.print\:focus\:bg-blue-darker:focus {
		background-color: #1c3d5a;
	}

	.print\:focus\:bg-blue-dark:focus {
		background-color: #2779bd;
	}

	.print\:focus\:bg-blue-light:focus {
		background-color: #6cb2eb;
	}

	.print\:focus\:bg-blue-lighter:focus {
		background-color: #bcdefa;
	}

	.print\:focus\:bg-blue-lightest:focus {
		background-color: #eff8ff;
	}

	.print\:focus\:bg-indigo-darkest:focus {
		background-color: #191e38;
	}

	.print\:focus\:bg-indigo-darker:focus {
		background-color: #2f365f;
	}

	.print\:focus\:bg-indigo-dark:focus {
		background-color: #5661b3;
	}

	.print\:focus\:bg-indigo-light:focus {
		background-color: #7886d7;
	}

	.print\:focus\:bg-indigo-lighter:focus {
		background-color: #b2b7ff;
	}

	.print\:focus\:bg-indigo-lightest:focus {
		background-color: #e6e8ff;
	}

	.print\:focus\:bg-purple-darkest:focus {
		background-color: #21183c;
	}

	.print\:focus\:bg-purple-darker:focus {
		background-color: #382b5f;
	}

	.print\:focus\:bg-purple-dark:focus {
		background-color: #794acf;
	}

	.print\:focus\:bg-purple-light:focus {
		background-color: #a779e9;
	}

	.print\:focus\:bg-purple-lighter:focus {
		background-color: #d6bbfc;
	}

	.print\:focus\:bg-purple-lightest:focus {
		background-color: #f3ebff;
	}

	.print\:focus\:bg-pink-darkest:focus {
		background-color: #451225;
	}

	.print\:focus\:bg-pink-darker:focus {
		background-color: #6f213f;
	}

	.print\:focus\:bg-pink-dark:focus {
		background-color: #eb5286;
	}

	.print\:focus\:bg-pink-light:focus {
		background-color: #fa7ea8;
	}

	.print\:focus\:bg-pink-lighter:focus {
		background-color: #ffbbca;
	}

	.print\:focus\:bg-pink-lightest:focus {
		background-color: #ffebef;
	}

	.print\:bg-bottom {
		background-position: bottom;
	}

	.print\:bg-center {
		background-position: center;
	}

	.print\:bg-left {
		background-position: left;
	}

	.print\:bg-left-bottom {
		background-position: left bottom;
	}

	.print\:bg-left-top {
		background-position: left top;
	}

	.print\:bg-right {
		background-position: right;
	}

	.print\:bg-right-bottom {
		background-position: right bottom;
	}

	.print\:bg-right-top {
		background-position: right top;
	}

	.print\:bg-top {
		background-position: top;
	}

	.print\:bg-repeat {
		background-repeat: repeat;
	}

	.print\:bg-no-repeat {
		background-repeat: no-repeat;
	}

	.print\:bg-repeat-x {
		background-repeat: repeat-x;
	}

	.print\:bg-repeat-y {
		background-repeat: repeat-y;
	}

	.print\:bg-repeat-round {
		background-repeat: round;
	}

	.print\:bg-repeat-space {
		background-repeat: space;
	}

	.print\:bg-auto {
		background-size: auto;
	}

	.print\:bg-cover {
		background-size: cover;
	}

	.print\:bg-contain {
		background-size: contain;
	}

	.print\:border-collapse {
		border-collapse: collapse;
	}

	.print\:border-separate {
		border-collapse: separate;
	}

	.print\:border-transparent {
		border-color: transparent;
	}

	.print\:border-black {
		border-color: #22292f;
	}

	.print\:border-white {
		border-color: #ffffff;
	}

	.print\:border-gray-100 {
		border-color: #f7fafc;
	}

	.print\:border-gray-200 {
		border-color: #edf2f7;
	}

	.print\:border-gray-300 {
		border-color: #e2e8f0;
	}

	.print\:border-gray-400 {
		border-color: #cbd5e0;
	}

	.print\:border-gray-500 {
		border-color: #a0aec0;
	}

	.print\:border-gray-600 {
		border-color: #718096;
	}

	.print\:border-gray-700 {
		border-color: #4a5568;
	}

	.print\:border-gray-800 {
		border-color: #2d3748;
	}

	.print\:border-gray-900 {
		border-color: #1a202c;
	}

	.print\:border-red {
		border-color: #e3342f;
	}

	.print\:border-orange {
		border-color: #f6993f;
	}

	.print\:border-yellow {
		border-color: #ffed4a;
	}

	.print\:border-green {
		border-color: #38c172;
	}

	.print\:border-teal {
		border-color: #4dc0b5;
	}

	.print\:border-blue {
		border-color: #3490dc;
	}

	.print\:border-indigo {
		border-color: #6574cd;
	}

	.print\:border-purple {
		border-color: #9561e2;
	}

	.print\:border-pink {
		border-color: #f66d9b;
	}

	.print\:border-grey-darkest {
		border-color: #3d4852;
	}

	.print\:border-grey-darker {
		border-color: #606f7b;
	}

	.print\:border-grey-dark {
		border-color: #8795a1;
	}

	.print\:border-grey {
		border-color: #b8c2cc;
	}

	.print\:border-grey-light {
		border-color: #dae1e7;
	}

	.print\:border-grey-lighter {
		border-color: #f1f5f8;
	}

	.print\:border-grey-lightest {
		border-color: #f8fafc;
	}

	.print\:border-red-darkest {
		border-color: #3b0d0c;
	}

	.print\:border-red-darker {
		border-color: #621b18;
	}

	.print\:border-red-dark {
		border-color: #cc1f1a;
	}

	.print\:border-red-light {
		border-color: #ef5753;
	}

	.print\:border-red-lighter {
		border-color: #f9acaa;
	}

	.print\:border-red-lightest {
		border-color: #fcebea;
	}

	.print\:border-orange-darkest {
		border-color: #462a16;
	}

	.print\:border-orange-darker {
		border-color: #613b1f;
	}

	.print\:border-orange-dark {
		border-color: #de751f;
	}

	.print\:border-orange-light {
		border-color: #faad63;
	}

	.print\:border-orange-lighter {
		border-color: #fcd9b6;
	}

	.print\:border-orange-lightest {
		border-color: #fff5eb;
	}

	.print\:border-yellow-darkest {
		border-color: #453411;
	}

	.print\:border-yellow-darker {
		border-color: #684f1d;
	}

	.print\:border-yellow-dark {
		border-color: #f2d024;
	}

	.print\:border-yellow-light {
		border-color: #fff382;
	}

	.print\:border-yellow-lighter {
		border-color: #fff9c2;
	}

	.print\:border-yellow-lightest {
		border-color: #fcfbeb;
	}

	.print\:border-green-darkest {
		border-color: #0f2f21;
	}

	.print\:border-green-darker {
		border-color: #1a4731;
	}

	.print\:border-green-dark {
		border-color: #1f9d55;
	}

	.print\:border-green-light {
		border-color: #51d88a;
	}

	.print\:border-green-lighter {
		border-color: #a2f5bf;
	}

	.print\:border-green-lightest {
		border-color: #e3fcec;
	}

	.print\:border-teal-darkest {
		border-color: #0d3331;
	}

	.print\:border-teal-darker {
		border-color: #20504f;
	}

	.print\:border-teal-dark {
		border-color: #38a89d;
	}

	.print\:border-teal-light {
		border-color: #64d5ca;
	}

	.print\:border-teal-lighter {
		border-color: #a0f0ed;
	}

	.print\:border-teal-lightest {
		border-color: #e8fffe;
	}

	.print\:border-blue-darkest {
		border-color: #12283a;
	}

	.print\:border-blue-darker {
		border-color: #1c3d5a;
	}

	.print\:border-blue-dark {
		border-color: #2779bd;
	}

	.print\:border-blue-light {
		border-color: #6cb2eb;
	}

	.print\:border-blue-lighter {
		border-color: #bcdefa;
	}

	.print\:border-blue-lightest {
		border-color: #eff8ff;
	}

	.print\:border-indigo-darkest {
		border-color: #191e38;
	}

	.print\:border-indigo-darker {
		border-color: #2f365f;
	}

	.print\:border-indigo-dark {
		border-color: #5661b3;
	}

	.print\:border-indigo-light {
		border-color: #7886d7;
	}

	.print\:border-indigo-lighter {
		border-color: #b2b7ff;
	}

	.print\:border-indigo-lightest {
		border-color: #e6e8ff;
	}

	.print\:border-purple-darkest {
		border-color: #21183c;
	}

	.print\:border-purple-darker {
		border-color: #382b5f;
	}

	.print\:border-purple-dark {
		border-color: #794acf;
	}

	.print\:border-purple-light {
		border-color: #a779e9;
	}

	.print\:border-purple-lighter {
		border-color: #d6bbfc;
	}

	.print\:border-purple-lightest {
		border-color: #f3ebff;
	}

	.print\:border-pink-darkest {
		border-color: #451225;
	}

	.print\:border-pink-darker {
		border-color: #6f213f;
	}

	.print\:border-pink-dark {
		border-color: #eb5286;
	}

	.print\:border-pink-light {
		border-color: #fa7ea8;
	}

	.print\:border-pink-lighter {
		border-color: #ffbbca;
	}

	.print\:border-pink-lightest {
		border-color: #ffebef;
	}

	.print\:hover\:border-transparent:hover {
		border-color: transparent;
	}

	.print\:hover\:border-black:hover {
		border-color: #22292f;
	}

	.print\:hover\:border-white:hover {
		border-color: #ffffff;
	}

	.print\:hover\:border-gray-100:hover {
		border-color: #f7fafc;
	}

	.print\:hover\:border-gray-200:hover {
		border-color: #edf2f7;
	}

	.print\:hover\:border-gray-300:hover {
		border-color: #e2e8f0;
	}

	.print\:hover\:border-gray-400:hover {
		border-color: #cbd5e0;
	}

	.print\:hover\:border-gray-500:hover {
		border-color: #a0aec0;
	}

	.print\:hover\:border-gray-600:hover {
		border-color: #718096;
	}

	.print\:hover\:border-gray-700:hover {
		border-color: #4a5568;
	}

	.print\:hover\:border-gray-800:hover {
		border-color: #2d3748;
	}

	.print\:hover\:border-gray-900:hover {
		border-color: #1a202c;
	}

	.print\:hover\:border-red:hover {
		border-color: #e3342f;
	}

	.print\:hover\:border-orange:hover {
		border-color: #f6993f;
	}

	.print\:hover\:border-yellow:hover {
		border-color: #ffed4a;
	}

	.print\:hover\:border-green:hover {
		border-color: #38c172;
	}

	.print\:hover\:border-teal:hover {
		border-color: #4dc0b5;
	}

	.print\:hover\:border-blue:hover {
		border-color: #3490dc;
	}

	.print\:hover\:border-indigo:hover {
		border-color: #6574cd;
	}

	.print\:hover\:border-purple:hover {
		border-color: #9561e2;
	}

	.print\:hover\:border-pink:hover {
		border-color: #f66d9b;
	}

	.print\:hover\:border-grey-darkest:hover {
		border-color: #3d4852;
	}

	.print\:hover\:border-grey-darker:hover {
		border-color: #606f7b;
	}

	.print\:hover\:border-grey-dark:hover {
		border-color: #8795a1;
	}

	.print\:hover\:border-grey:hover {
		border-color: #b8c2cc;
	}

	.print\:hover\:border-grey-light:hover {
		border-color: #dae1e7;
	}

	.print\:hover\:border-grey-lighter:hover {
		border-color: #f1f5f8;
	}

	.print\:hover\:border-grey-lightest:hover {
		border-color: #f8fafc;
	}

	.print\:hover\:border-red-darkest:hover {
		border-color: #3b0d0c;
	}

	.print\:hover\:border-red-darker:hover {
		border-color: #621b18;
	}

	.print\:hover\:border-red-dark:hover {
		border-color: #cc1f1a;
	}

	.print\:hover\:border-red-light:hover {
		border-color: #ef5753;
	}

	.print\:hover\:border-red-lighter:hover {
		border-color: #f9acaa;
	}

	.print\:hover\:border-red-lightest:hover {
		border-color: #fcebea;
	}

	.print\:hover\:border-orange-darkest:hover {
		border-color: #462a16;
	}

	.print\:hover\:border-orange-darker:hover {
		border-color: #613b1f;
	}

	.print\:hover\:border-orange-dark:hover {
		border-color: #de751f;
	}

	.print\:hover\:border-orange-light:hover {
		border-color: #faad63;
	}

	.print\:hover\:border-orange-lighter:hover {
		border-color: #fcd9b6;
	}

	.print\:hover\:border-orange-lightest:hover {
		border-color: #fff5eb;
	}

	.print\:hover\:border-yellow-darkest:hover {
		border-color: #453411;
	}

	.print\:hover\:border-yellow-darker:hover {
		border-color: #684f1d;
	}

	.print\:hover\:border-yellow-dark:hover {
		border-color: #f2d024;
	}

	.print\:hover\:border-yellow-light:hover {
		border-color: #fff382;
	}

	.print\:hover\:border-yellow-lighter:hover {
		border-color: #fff9c2;
	}

	.print\:hover\:border-yellow-lightest:hover {
		border-color: #fcfbeb;
	}

	.print\:hover\:border-green-darkest:hover {
		border-color: #0f2f21;
	}

	.print\:hover\:border-green-darker:hover {
		border-color: #1a4731;
	}

	.print\:hover\:border-green-dark:hover {
		border-color: #1f9d55;
	}

	.print\:hover\:border-green-light:hover {
		border-color: #51d88a;
	}

	.print\:hover\:border-green-lighter:hover {
		border-color: #a2f5bf;
	}

	.print\:hover\:border-green-lightest:hover {
		border-color: #e3fcec;
	}

	.print\:hover\:border-teal-darkest:hover {
		border-color: #0d3331;
	}

	.print\:hover\:border-teal-darker:hover {
		border-color: #20504f;
	}

	.print\:hover\:border-teal-dark:hover {
		border-color: #38a89d;
	}

	.print\:hover\:border-teal-light:hover {
		border-color: #64d5ca;
	}

	.print\:hover\:border-teal-lighter:hover {
		border-color: #a0f0ed;
	}

	.print\:hover\:border-teal-lightest:hover {
		border-color: #e8fffe;
	}

	.print\:hover\:border-blue-darkest:hover {
		border-color: #12283a;
	}

	.print\:hover\:border-blue-darker:hover {
		border-color: #1c3d5a;
	}

	.print\:hover\:border-blue-dark:hover {
		border-color: #2779bd;
	}

	.print\:hover\:border-blue-light:hover {
		border-color: #6cb2eb;
	}

	.print\:hover\:border-blue-lighter:hover {
		border-color: #bcdefa;
	}

	.print\:hover\:border-blue-lightest:hover {
		border-color: #eff8ff;
	}

	.print\:hover\:border-indigo-darkest:hover {
		border-color: #191e38;
	}

	.print\:hover\:border-indigo-darker:hover {
		border-color: #2f365f;
	}

	.print\:hover\:border-indigo-dark:hover {
		border-color: #5661b3;
	}

	.print\:hover\:border-indigo-light:hover {
		border-color: #7886d7;
	}

	.print\:hover\:border-indigo-lighter:hover {
		border-color: #b2b7ff;
	}

	.print\:hover\:border-indigo-lightest:hover {
		border-color: #e6e8ff;
	}

	.print\:hover\:border-purple-darkest:hover {
		border-color: #21183c;
	}

	.print\:hover\:border-purple-darker:hover {
		border-color: #382b5f;
	}

	.print\:hover\:border-purple-dark:hover {
		border-color: #794acf;
	}

	.print\:hover\:border-purple-light:hover {
		border-color: #a779e9;
	}

	.print\:hover\:border-purple-lighter:hover {
		border-color: #d6bbfc;
	}

	.print\:hover\:border-purple-lightest:hover {
		border-color: #f3ebff;
	}

	.print\:hover\:border-pink-darkest:hover {
		border-color: #451225;
	}

	.print\:hover\:border-pink-darker:hover {
		border-color: #6f213f;
	}

	.print\:hover\:border-pink-dark:hover {
		border-color: #eb5286;
	}

	.print\:hover\:border-pink-light:hover {
		border-color: #fa7ea8;
	}

	.print\:hover\:border-pink-lighter:hover {
		border-color: #ffbbca;
	}

	.print\:hover\:border-pink-lightest:hover {
		border-color: #ffebef;
	}

	.print\:focus\:border-transparent:focus {
		border-color: transparent;
	}

	.print\:focus\:border-black:focus {
		border-color: #22292f;
	}

	.print\:focus\:border-white:focus {
		border-color: #ffffff;
	}

	.print\:focus\:border-gray-100:focus {
		border-color: #f7fafc;
	}

	.print\:focus\:border-gray-200:focus {
		border-color: #edf2f7;
	}

	.print\:focus\:border-gray-300:focus {
		border-color: #e2e8f0;
	}

	.print\:focus\:border-gray-400:focus {
		border-color: #cbd5e0;
	}

	.print\:focus\:border-gray-500:focus {
		border-color: #a0aec0;
	}

	.print\:focus\:border-gray-600:focus {
		border-color: #718096;
	}

	.print\:focus\:border-gray-700:focus {
		border-color: #4a5568;
	}

	.print\:focus\:border-gray-800:focus {
		border-color: #2d3748;
	}

	.print\:focus\:border-gray-900:focus {
		border-color: #1a202c;
	}

	.print\:focus\:border-red:focus {
		border-color: #e3342f;
	}

	.print\:focus\:border-orange:focus {
		border-color: #f6993f;
	}

	.print\:focus\:border-yellow:focus {
		border-color: #ffed4a;
	}

	.print\:focus\:border-green:focus {
		border-color: #38c172;
	}

	.print\:focus\:border-teal:focus {
		border-color: #4dc0b5;
	}

	.print\:focus\:border-blue:focus {
		border-color: #3490dc;
	}

	.print\:focus\:border-indigo:focus {
		border-color: #6574cd;
	}

	.print\:focus\:border-purple:focus {
		border-color: #9561e2;
	}

	.print\:focus\:border-pink:focus {
		border-color: #f66d9b;
	}

	.print\:focus\:border-grey-darkest:focus {
		border-color: #3d4852;
	}

	.print\:focus\:border-grey-darker:focus {
		border-color: #606f7b;
	}

	.print\:focus\:border-grey-dark:focus {
		border-color: #8795a1;
	}

	.print\:focus\:border-grey:focus {
		border-color: #b8c2cc;
	}

	.print\:focus\:border-grey-light:focus {
		border-color: #dae1e7;
	}

	.print\:focus\:border-grey-lighter:focus {
		border-color: #f1f5f8;
	}

	.print\:focus\:border-grey-lightest:focus {
		border-color: #f8fafc;
	}

	.print\:focus\:border-red-darkest:focus {
		border-color: #3b0d0c;
	}

	.print\:focus\:border-red-darker:focus {
		border-color: #621b18;
	}

	.print\:focus\:border-red-dark:focus {
		border-color: #cc1f1a;
	}

	.print\:focus\:border-red-light:focus {
		border-color: #ef5753;
	}

	.print\:focus\:border-red-lighter:focus {
		border-color: #f9acaa;
	}

	.print\:focus\:border-red-lightest:focus {
		border-color: #fcebea;
	}

	.print\:focus\:border-orange-darkest:focus {
		border-color: #462a16;
	}

	.print\:focus\:border-orange-darker:focus {
		border-color: #613b1f;
	}

	.print\:focus\:border-orange-dark:focus {
		border-color: #de751f;
	}

	.print\:focus\:border-orange-light:focus {
		border-color: #faad63;
	}

	.print\:focus\:border-orange-lighter:focus {
		border-color: #fcd9b6;
	}

	.print\:focus\:border-orange-lightest:focus {
		border-color: #fff5eb;
	}

	.print\:focus\:border-yellow-darkest:focus {
		border-color: #453411;
	}

	.print\:focus\:border-yellow-darker:focus {
		border-color: #684f1d;
	}

	.print\:focus\:border-yellow-dark:focus {
		border-color: #f2d024;
	}

	.print\:focus\:border-yellow-light:focus {
		border-color: #fff382;
	}

	.print\:focus\:border-yellow-lighter:focus {
		border-color: #fff9c2;
	}

	.print\:focus\:border-yellow-lightest:focus {
		border-color: #fcfbeb;
	}

	.print\:focus\:border-green-darkest:focus {
		border-color: #0f2f21;
	}

	.print\:focus\:border-green-darker:focus {
		border-color: #1a4731;
	}

	.print\:focus\:border-green-dark:focus {
		border-color: #1f9d55;
	}

	.print\:focus\:border-green-light:focus {
		border-color: #51d88a;
	}

	.print\:focus\:border-green-lighter:focus {
		border-color: #a2f5bf;
	}

	.print\:focus\:border-green-lightest:focus {
		border-color: #e3fcec;
	}

	.print\:focus\:border-teal-darkest:focus {
		border-color: #0d3331;
	}

	.print\:focus\:border-teal-darker:focus {
		border-color: #20504f;
	}

	.print\:focus\:border-teal-dark:focus {
		border-color: #38a89d;
	}

	.print\:focus\:border-teal-light:focus {
		border-color: #64d5ca;
	}

	.print\:focus\:border-teal-lighter:focus {
		border-color: #a0f0ed;
	}

	.print\:focus\:border-teal-lightest:focus {
		border-color: #e8fffe;
	}

	.print\:focus\:border-blue-darkest:focus {
		border-color: #12283a;
	}

	.print\:focus\:border-blue-darker:focus {
		border-color: #1c3d5a;
	}

	.print\:focus\:border-blue-dark:focus {
		border-color: #2779bd;
	}

	.print\:focus\:border-blue-light:focus {
		border-color: #6cb2eb;
	}

	.print\:focus\:border-blue-lighter:focus {
		border-color: #bcdefa;
	}

	.print\:focus\:border-blue-lightest:focus {
		border-color: #eff8ff;
	}

	.print\:focus\:border-indigo-darkest:focus {
		border-color: #191e38;
	}

	.print\:focus\:border-indigo-darker:focus {
		border-color: #2f365f;
	}

	.print\:focus\:border-indigo-dark:focus {
		border-color: #5661b3;
	}

	.print\:focus\:border-indigo-light:focus {
		border-color: #7886d7;
	}

	.print\:focus\:border-indigo-lighter:focus {
		border-color: #b2b7ff;
	}

	.print\:focus\:border-indigo-lightest:focus {
		border-color: #e6e8ff;
	}

	.print\:focus\:border-purple-darkest:focus {
		border-color: #21183c;
	}

	.print\:focus\:border-purple-darker:focus {
		border-color: #382b5f;
	}

	.print\:focus\:border-purple-dark:focus {
		border-color: #794acf;
	}

	.print\:focus\:border-purple-light:focus {
		border-color: #a779e9;
	}

	.print\:focus\:border-purple-lighter:focus {
		border-color: #d6bbfc;
	}

	.print\:focus\:border-purple-lightest:focus {
		border-color: #f3ebff;
	}

	.print\:focus\:border-pink-darkest:focus {
		border-color: #451225;
	}

	.print\:focus\:border-pink-darker:focus {
		border-color: #6f213f;
	}

	.print\:focus\:border-pink-dark:focus {
		border-color: #eb5286;
	}

	.print\:focus\:border-pink-light:focus {
		border-color: #fa7ea8;
	}

	.print\:focus\:border-pink-lighter:focus {
		border-color: #ffbbca;
	}

	.print\:focus\:border-pink-lightest:focus {
		border-color: #ffebef;
	}

	.print\:rounded-2 {
		border-radius: 0.2rem;
	}

	.print\:rounded-4 {
		border-radius: 0.4rem;
	}

	.print\:rounded-6 {
		border-radius: 0.6rem;
	}

	.print\:rounded-8 {
		border-radius: 0.8rem;
	}

	.print\:rounded-12 {
		border-radius: 1.2rem;
	}

	.print\:rounded-16 {
		border-radius: 1.6rem;
	}

	.print\:rounded-20 {
		border-radius: 2rem;
	}

	.print\:rounded-24 {
		border-radius: 2.4rem;
	}

	.print\:rounded-28 {
		border-radius: 2.8rem;
	}

	.print\:rounded-32 {
		border-radius: 3.2rem;
	}

	.print\:rounded-none {
		border-radius: 0;
	}

	.print\:rounded-sm {
		border-radius: 0.2rem;
	}

	.print\:rounded {
		border-radius: 0.4rem;
	}

	.print\:rounded-lg {
		border-radius: 0.8rem;
	}

	.print\:rounded-full {
		border-radius: 9999px;
	}

	.print\:rounded-t-2 {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.print\:rounded-r-2 {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.print\:rounded-b-2 {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-l-2 {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-t-4 {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.print\:rounded-r-4 {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.print\:rounded-b-4 {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-l-4 {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-t-6 {
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
	}

	.print\:rounded-r-6 {
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
	}

	.print\:rounded-b-6 {
		border-bottom-right-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.print\:rounded-l-6 {
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
	}

	.print\:rounded-t-8 {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.print\:rounded-r-8 {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.print\:rounded-b-8 {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-l-8 {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-t-12 {
		border-top-left-radius: 1.2rem;
		border-top-right-radius: 1.2rem;
	}

	.print\:rounded-r-12 {
		border-top-right-radius: 1.2rem;
		border-bottom-right-radius: 1.2rem;
	}

	.print\:rounded-b-12 {
		border-bottom-right-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.print\:rounded-l-12 {
		border-top-left-radius: 1.2rem;
		border-bottom-left-radius: 1.2rem;
	}

	.print\:rounded-t-16 {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
	}

	.print\:rounded-r-16 {
		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;
	}

	.print\:rounded-b-16 {
		border-bottom-right-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.print\:rounded-l-16 {
		border-top-left-radius: 1.6rem;
		border-bottom-left-radius: 1.6rem;
	}

	.print\:rounded-t-20 {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.print\:rounded-r-20 {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}

	.print\:rounded-b-20 {
		border-bottom-right-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.print\:rounded-l-20 {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}

	.print\:rounded-t-24 {
		border-top-left-radius: 2.4rem;
		border-top-right-radius: 2.4rem;
	}

	.print\:rounded-r-24 {
		border-top-right-radius: 2.4rem;
		border-bottom-right-radius: 2.4rem;
	}

	.print\:rounded-b-24 {
		border-bottom-right-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.print\:rounded-l-24 {
		border-top-left-radius: 2.4rem;
		border-bottom-left-radius: 2.4rem;
	}

	.print\:rounded-t-28 {
		border-top-left-radius: 2.8rem;
		border-top-right-radius: 2.8rem;
	}

	.print\:rounded-r-28 {
		border-top-right-radius: 2.8rem;
		border-bottom-right-radius: 2.8rem;
	}

	.print\:rounded-b-28 {
		border-bottom-right-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.print\:rounded-l-28 {
		border-top-left-radius: 2.8rem;
		border-bottom-left-radius: 2.8rem;
	}

	.print\:rounded-t-32 {
		border-top-left-radius: 3.2rem;
		border-top-right-radius: 3.2rem;
	}

	.print\:rounded-r-32 {
		border-top-right-radius: 3.2rem;
		border-bottom-right-radius: 3.2rem;
	}

	.print\:rounded-b-32 {
		border-bottom-right-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.print\:rounded-l-32 {
		border-top-left-radius: 3.2rem;
		border-bottom-left-radius: 3.2rem;
	}

	.print\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.print\:rounded-r-none {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.print\:rounded-b-none {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.print\:rounded-l-none {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.print\:rounded-t-sm {
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.print\:rounded-r-sm {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}

	.print\:rounded-b-sm {
		border-bottom-right-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-l-sm {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-t {
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
	}

	.print\:rounded-r {
		border-top-right-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	.print\:rounded-b {
		border-bottom-right-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-l {
		border-top-left-radius: 0.4rem;
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-t-lg {
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	.print\:rounded-r-lg {
		border-top-right-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}

	.print\:rounded-b-lg {
		border-bottom-right-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-l-lg {
		border-top-left-radius: 0.8rem;
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-t-full {
		border-top-left-radius: 9999px;
		border-top-right-radius: 9999px;
	}

	.print\:rounded-r-full {
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
	}

	.print\:rounded-b-full {
		border-bottom-right-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.print\:rounded-l-full {
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
	}

	.print\:rounded-tl-2 {
		border-top-left-radius: 0.2rem;
	}

	.print\:rounded-tr-2 {
		border-top-right-radius: 0.2rem;
	}

	.print\:rounded-br-2 {
		border-bottom-right-radius: 0.2rem;
	}

	.print\:rounded-bl-2 {
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-tl-4 {
		border-top-left-radius: 0.4rem;
	}

	.print\:rounded-tr-4 {
		border-top-right-radius: 0.4rem;
	}

	.print\:rounded-br-4 {
		border-bottom-right-radius: 0.4rem;
	}

	.print\:rounded-bl-4 {
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-tl-6 {
		border-top-left-radius: 0.6rem;
	}

	.print\:rounded-tr-6 {
		border-top-right-radius: 0.6rem;
	}

	.print\:rounded-br-6 {
		border-bottom-right-radius: 0.6rem;
	}

	.print\:rounded-bl-6 {
		border-bottom-left-radius: 0.6rem;
	}

	.print\:rounded-tl-8 {
		border-top-left-radius: 0.8rem;
	}

	.print\:rounded-tr-8 {
		border-top-right-radius: 0.8rem;
	}

	.print\:rounded-br-8 {
		border-bottom-right-radius: 0.8rem;
	}

	.print\:rounded-bl-8 {
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-tl-12 {
		border-top-left-radius: 1.2rem;
	}

	.print\:rounded-tr-12 {
		border-top-right-radius: 1.2rem;
	}

	.print\:rounded-br-12 {
		border-bottom-right-radius: 1.2rem;
	}

	.print\:rounded-bl-12 {
		border-bottom-left-radius: 1.2rem;
	}

	.print\:rounded-tl-16 {
		border-top-left-radius: 1.6rem;
	}

	.print\:rounded-tr-16 {
		border-top-right-radius: 1.6rem;
	}

	.print\:rounded-br-16 {
		border-bottom-right-radius: 1.6rem;
	}

	.print\:rounded-bl-16 {
		border-bottom-left-radius: 1.6rem;
	}

	.print\:rounded-tl-20 {
		border-top-left-radius: 2rem;
	}

	.print\:rounded-tr-20 {
		border-top-right-radius: 2rem;
	}

	.print\:rounded-br-20 {
		border-bottom-right-radius: 2rem;
	}

	.print\:rounded-bl-20 {
		border-bottom-left-radius: 2rem;
	}

	.print\:rounded-tl-24 {
		border-top-left-radius: 2.4rem;
	}

	.print\:rounded-tr-24 {
		border-top-right-radius: 2.4rem;
	}

	.print\:rounded-br-24 {
		border-bottom-right-radius: 2.4rem;
	}

	.print\:rounded-bl-24 {
		border-bottom-left-radius: 2.4rem;
	}

	.print\:rounded-tl-28 {
		border-top-left-radius: 2.8rem;
	}

	.print\:rounded-tr-28 {
		border-top-right-radius: 2.8rem;
	}

	.print\:rounded-br-28 {
		border-bottom-right-radius: 2.8rem;
	}

	.print\:rounded-bl-28 {
		border-bottom-left-radius: 2.8rem;
	}

	.print\:rounded-tl-32 {
		border-top-left-radius: 3.2rem;
	}

	.print\:rounded-tr-32 {
		border-top-right-radius: 3.2rem;
	}

	.print\:rounded-br-32 {
		border-bottom-right-radius: 3.2rem;
	}

	.print\:rounded-bl-32 {
		border-bottom-left-radius: 3.2rem;
	}

	.print\:rounded-tl-none {
		border-top-left-radius: 0;
	}

	.print\:rounded-tr-none {
		border-top-right-radius: 0;
	}

	.print\:rounded-br-none {
		border-bottom-right-radius: 0;
	}

	.print\:rounded-bl-none {
		border-bottom-left-radius: 0;
	}

	.print\:rounded-tl-sm {
		border-top-left-radius: 0.2rem;
	}

	.print\:rounded-tr-sm {
		border-top-right-radius: 0.2rem;
	}

	.print\:rounded-br-sm {
		border-bottom-right-radius: 0.2rem;
	}

	.print\:rounded-bl-sm {
		border-bottom-left-radius: 0.2rem;
	}

	.print\:rounded-tl {
		border-top-left-radius: 0.4rem;
	}

	.print\:rounded-tr {
		border-top-right-radius: 0.4rem;
	}

	.print\:rounded-br {
		border-bottom-right-radius: 0.4rem;
	}

	.print\:rounded-bl {
		border-bottom-left-radius: 0.4rem;
	}

	.print\:rounded-tl-lg {
		border-top-left-radius: 0.8rem;
	}

	.print\:rounded-tr-lg {
		border-top-right-radius: 0.8rem;
	}

	.print\:rounded-br-lg {
		border-bottom-right-radius: 0.8rem;
	}

	.print\:rounded-bl-lg {
		border-bottom-left-radius: 0.8rem;
	}

	.print\:rounded-tl-full {
		border-top-left-radius: 9999px;
	}

	.print\:rounded-tr-full {
		border-top-right-radius: 9999px;
	}

	.print\:rounded-br-full {
		border-bottom-right-radius: 9999px;
	}

	.print\:rounded-bl-full {
		border-bottom-left-radius: 9999px;
	}

	.print\:border-solid {
		border-style: solid;
	}

	.print\:border-dashed {
		border-style: dashed;
	}

	.print\:border-dotted {
		border-style: dotted;
	}

	.print\:border-none {
		border-style: none;
	}

	.print\:border-0 {
		border-width: 0;
	}

	.print\:border-1 {
		border-width: 1px;
	}

	.print\:border-2 {
		border-width: 2px;
	}

	.print\:border-3 {
		border-width: 3px;
	}

	.print\:border-4 {
		border-width: 4px;
	}

	.print\:border-8 {
		border-width: 8px;
	}

	.print\:border {
		border-width: 1px;
	}

	.print\:border-t-0 {
		border-top-width: 0;
	}

	.print\:border-r-0 {
		border-right-width: 0;
	}

	.print\:border-b-0 {
		border-bottom-width: 0;
	}

	.print\:border-l-0 {
		border-left-width: 0;
	}

	.print\:border-t-1 {
		border-top-width: 1px;
	}

	.print\:border-r-1 {
		border-right-width: 1px;
	}

	.print\:border-b-1 {
		border-bottom-width: 1px;
	}

	.print\:border-l-1 {
		border-left-width: 1px;
	}

	.print\:border-t-2 {
		border-top-width: 2px;
	}

	.print\:border-r-2 {
		border-right-width: 2px;
	}

	.print\:border-b-2 {
		border-bottom-width: 2px;
	}

	.print\:border-l-2 {
		border-left-width: 2px;
	}

	.print\:border-t-3 {
		border-top-width: 3px;
	}

	.print\:border-r-3 {
		border-right-width: 3px;
	}

	.print\:border-b-3 {
		border-bottom-width: 3px;
	}

	.print\:border-l-3 {
		border-left-width: 3px;
	}

	.print\:border-t-4 {
		border-top-width: 4px;
	}

	.print\:border-r-4 {
		border-right-width: 4px;
	}

	.print\:border-b-4 {
		border-bottom-width: 4px;
	}

	.print\:border-l-4 {
		border-left-width: 4px;
	}

	.print\:border-t-8 {
		border-top-width: 8px;
	}

	.print\:border-r-8 {
		border-right-width: 8px;
	}

	.print\:border-b-8 {
		border-bottom-width: 8px;
	}

	.print\:border-l-8 {
		border-left-width: 8px;
	}

	.print\:border-t {
		border-top-width: 1px;
	}

	.print\:border-r {
		border-right-width: 1px;
	}

	.print\:border-b {
		border-bottom-width: 1px;
	}

	.print\:border-l {
		border-left-width: 1px;
	}

	.print\:cursor-auto {
		cursor: auto;
	}

	.print\:cursor-default {
		cursor: default;
	}

	.print\:cursor-pointer {
		cursor: pointer;
	}

	.print\:cursor-wait {
		cursor: wait;
	}

	.print\:cursor-text {
		cursor: text;
	}

	.print\:cursor-move {
		cursor: move;
	}

	.print\:cursor-not-allowed {
		cursor: not-allowed;
	}

	.print\:block {
		display: block;
	}

	.print\:inline-block {
		display: inline-block;
	}

	.print\:inline {
		display: inline;
	}

	.print\:flex {
		display: -webkit-box;
		display: flex;
	}

	.print\:inline-flex {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.print\:table {
		display: table;
	}

	.print\:table-row {
		display: table-row;
	}

	.print\:table-cell {
		display: table-cell;
	}

	.print\:hidden {
		display: none;
	}

	.print\:hover\:block:hover {
		display: block;
	}

	.print\:hover\:inline-block:hover {
		display: inline-block;
	}

	.print\:hover\:inline:hover {
		display: inline;
	}

	.print\:hover\:flex:hover {
		display: -webkit-box;
		display: flex;
	}

	.print\:hover\:inline-flex:hover {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.print\:hover\:table:hover {
		display: table;
	}

	.print\:hover\:table-row:hover {
		display: table-row;
	}

	.print\:hover\:table-cell:hover {
		display: table-cell;
	}

	.print\:hover\:hidden:hover {
		display: none;
	}

	.print\:focus\:block:focus {
		display: block;
	}

	.print\:focus\:inline-block:focus {
		display: inline-block;
	}

	.print\:focus\:inline:focus {
		display: inline;
	}

	.print\:focus\:flex:focus {
		display: -webkit-box;
		display: flex;
	}

	.print\:focus\:inline-flex:focus {
		display: -webkit-inline-box;
		display: inline-flex;
	}

	.print\:focus\:table:focus {
		display: table;
	}

	.print\:focus\:table-row:focus {
		display: table-row;
	}

	.print\:focus\:table-cell:focus {
		display: table-cell;
	}

	.print\:focus\:hidden:focus {
		display: none;
	}

	.print\:flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.print\:flex-row-reverse {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.print\:flex-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.print\:flex-col-reverse {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.print\:flex-wrap {
		flex-wrap: wrap;
	}

	.print\:flex-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	.print\:flex-no-wrap {
		flex-wrap: nowrap;
	}

	.print\:items-start {
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.print\:items-end {
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.print\:items-center {
		-webkit-box-align: center;
		align-items: center;
	}

	.print\:items-baseline {
		-webkit-box-align: baseline;
		align-items: baseline;
	}

	.print\:items-stretch {
		-webkit-box-align: stretch;
		align-items: stretch;
	}

	.print\:self-auto {
		align-self: auto;
	}

	.print\:self-start {
		align-self: flex-start;
	}

	.print\:self-end {
		align-self: flex-end;
	}

	.print\:self-center {
		align-self: center;
	}

	.print\:self-stretch {
		align-self: stretch;
	}

	.print\:justify-start {
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.print\:justify-end {
		-webkit-box-pack: end;
		justify-content: flex-end;
	}

	.print\:justify-center {
		-webkit-box-pack: center;
		justify-content: center;
	}

	.print\:justify-between {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.print\:justify-around {
		justify-content: space-around;
	}

	.print\:content-center {
		align-content: center;
	}

	.print\:content-start {
		align-content: flex-start;
	}

	.print\:content-end {
		align-content: flex-end;
	}

	.print\:content-between {
		align-content: space-between;
	}

	.print\:content-around {
		align-content: space-around;
	}

	.print\:flex-1 {
		-webkit-box-flex: 1;
		flex: 1 1 0%;
	}

	.print\:flex-auto {
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}

	.print\:flex-initial {
		-webkit-box-flex: 0;
		flex: 0 1 auto;
	}

	.print\:flex-none {
		-webkit-box-flex: 0;
		flex: none;
	}

	.print\:flex-grow-0 {
		-webkit-box-flex: 0;
		flex-grow: 0;
	}

	.print\:flex-grow {
		-webkit-box-flex: 1;
		flex-grow: 1;
	}

	.print\:flex-shrink-0 {
		flex-shrink: 0;
	}

	.print\:flex-shrink {
		flex-shrink: 1;
	}

	.print\:order-1 {
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	.print\:order-2 {
		-webkit-box-ordinal-group: 3;
		order: 2;
	}

	.print\:order-3 {
		-webkit-box-ordinal-group: 4;
		order: 3;
	}

	.print\:order-4 {
		-webkit-box-ordinal-group: 5;
		order: 4;
	}

	.print\:order-5 {
		-webkit-box-ordinal-group: 6;
		order: 5;
	}

	.print\:order-6 {
		-webkit-box-ordinal-group: 7;
		order: 6;
	}

	.print\:order-7 {
		-webkit-box-ordinal-group: 8;
		order: 7;
	}

	.print\:order-8 {
		-webkit-box-ordinal-group: 9;
		order: 8;
	}

	.print\:order-9 {
		-webkit-box-ordinal-group: 10;
		order: 9;
	}

	.print\:order-10 {
		-webkit-box-ordinal-group: 11;
		order: 10;
	}

	.print\:order-11 {
		-webkit-box-ordinal-group: 12;
		order: 11;
	}

	.print\:order-12 {
		-webkit-box-ordinal-group: 13;
		order: 12;
	}

	.print\:order-first {
		-webkit-box-ordinal-group: -9998;
		order: -9999;
	}

	.print\:order-last {
		-webkit-box-ordinal-group: 10000;
		order: 9999;
	}

	.print\:order-none {
		-webkit-box-ordinal-group: 1;
		order: 0;
	}

	.print\:float-right {
		float: right;
	}

	.print\:float-left {
		float: left;
	}

	.print\:float-none {
		float: none;
	}

	.print\:clearfix:after {
		content: '';
		display: table;
		clear: both;
	}

	.print\:font-sans {
		font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
			Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
			sans-serif;
	}

	.print\:font-serif {
		font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
			DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
	}

	.print\:font-mono {
		font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
			monospace;
	}

	.print\:font-100 {
		font-weight: 100;
	}

	.print\:font-200 {
		font-weight: 200;
	}

	.print\:font-300 {
		font-weight: 300;
	}

	.print\:font-400 {
		font-weight: 400;
	}

	.print\:font-500 {
		font-weight: 500;
	}

	.print\:font-600 {
		font-weight: 600;
	}

	.print\:font-700 {
		font-weight: 700;
	}

	.print\:font-800 {
		font-weight: 800;
	}

	.print\:font-900 {
		font-weight: 900;
	}

	.print\:font-hairline {
		font-weight: 100;
	}

	.print\:font-thin {
		font-weight: 200;
	}

	.print\:font-light {
		font-weight: 300;
	}

	.print\:font-normal {
		font-weight: 400;
	}

	.print\:font-medium {
		font-weight: 500;
	}

	.print\:font-semibold {
		font-weight: 600;
	}

	.print\:font-bold {
		font-weight: 700;
	}

	.print\:font-extrabold {
		font-weight: 800;
	}

	.print\:font-black {
		font-weight: 900;
	}

	.print\:hover\:font-100:hover {
		font-weight: 100;
	}

	.print\:hover\:font-200:hover {
		font-weight: 200;
	}

	.print\:hover\:font-300:hover {
		font-weight: 300;
	}

	.print\:hover\:font-400:hover {
		font-weight: 400;
	}

	.print\:hover\:font-500:hover {
		font-weight: 500;
	}

	.print\:hover\:font-600:hover {
		font-weight: 600;
	}

	.print\:hover\:font-700:hover {
		font-weight: 700;
	}

	.print\:hover\:font-800:hover {
		font-weight: 800;
	}

	.print\:hover\:font-900:hover {
		font-weight: 900;
	}

	.print\:hover\:font-hairline:hover {
		font-weight: 100;
	}

	.print\:hover\:font-thin:hover {
		font-weight: 200;
	}

	.print\:hover\:font-light:hover {
		font-weight: 300;
	}

	.print\:hover\:font-normal:hover {
		font-weight: 400;
	}

	.print\:hover\:font-medium:hover {
		font-weight: 500;
	}

	.print\:hover\:font-semibold:hover {
		font-weight: 600;
	}

	.print\:hover\:font-bold:hover {
		font-weight: 700;
	}

	.print\:hover\:font-extrabold:hover {
		font-weight: 800;
	}

	.print\:hover\:font-black:hover {
		font-weight: 900;
	}

	.print\:focus\:font-100:focus {
		font-weight: 100;
	}

	.print\:focus\:font-200:focus {
		font-weight: 200;
	}

	.print\:focus\:font-300:focus {
		font-weight: 300;
	}

	.print\:focus\:font-400:focus {
		font-weight: 400;
	}

	.print\:focus\:font-500:focus {
		font-weight: 500;
	}

	.print\:focus\:font-600:focus {
		font-weight: 600;
	}

	.print\:focus\:font-700:focus {
		font-weight: 700;
	}

	.print\:focus\:font-800:focus {
		font-weight: 800;
	}

	.print\:focus\:font-900:focus {
		font-weight: 900;
	}

	.print\:focus\:font-hairline:focus {
		font-weight: 100;
	}

	.print\:focus\:font-thin:focus {
		font-weight: 200;
	}

	.print\:focus\:font-light:focus {
		font-weight: 300;
	}

	.print\:focus\:font-normal:focus {
		font-weight: 400;
	}

	.print\:focus\:font-medium:focus {
		font-weight: 500;
	}

	.print\:focus\:font-semibold:focus {
		font-weight: 600;
	}

	.print\:focus\:font-bold:focus {
		font-weight: 700;
	}

	.print\:focus\:font-extrabold:focus {
		font-weight: 800;
	}

	.print\:focus\:font-black:focus {
		font-weight: 900;
	}

	.print\:h-0 {
		height: 0;
	}

	.print\:h-1 {
		height: 0.25rem;
	}

	.print\:h-2 {
		height: 0.5rem;
	}

	.print\:h-3 {
		height: 0.75rem;
	}

	.print\:h-4 {
		height: 0.4rem;
	}

	.print\:h-5 {
		height: 1.25rem;
	}

	.print\:h-6 {
		height: 0.6rem;
	}

	.print\:h-8 {
		height: 0.8rem;
	}

	.print\:h-10 {
		height: 2.5rem;
	}

	.print\:h-12 {
		height: 1.2rem;
	}

	.print\:h-16 {
		height: 1.6rem;
	}

	.print\:h-20 {
		height: 2rem;
	}

	.print\:h-24 {
		height: 2.4rem;
	}

	.print\:h-28 {
		height: 2.8rem;
	}

	.print\:h-32 {
		height: 3.2rem;
	}

	.print\:h-36 {
		height: 3.6rem;
	}

	.print\:h-40 {
		height: 4rem;
	}

	.print\:h-44 {
		height: 4.4rem;
	}

	.print\:h-48 {
		height: 4.8rem;
	}

	.print\:h-52 {
		height: 5.2rem;
	}

	.print\:h-56 {
		height: 5.6rem;
	}

	.print\:h-60 {
		height: 6rem;
	}

	.print\:h-64 {
		height: 6.4rem;
	}

	.print\:h-68 {
		height: 6.8rem;
	}

	.print\:h-72 {
		height: 7.2rem;
	}

	.print\:h-76 {
		height: 7.6rem;
	}

	.print\:h-80 {
		height: 8rem;
	}

	.print\:h-84 {
		height: 8.4rem;
	}

	.print\:h-88 {
		height: 8.8rem;
	}

	.print\:h-92 {
		height: 9.2rem;
	}

	.print\:h-96 {
		height: 9.6rem;
	}

	.print\:h-128 {
		height: 12.8rem;
	}

	.print\:h-136 {
		height: 13.6rem;
	}

	.print\:h-160 {
		height: 16rem;
	}

	.print\:h-192 {
		height: 19.2rem;
	}

	.print\:h-200 {
		height: 20rem;
	}

	.print\:h-208 {
		height: 20.8rem;
	}

	.print\:h-216 {
		height: 21.6rem;
	}

	.print\:h-224 {
		height: 22.4rem;
	}

	.print\:h-256 {
		height: 25.6rem;
	}

	.print\:h-288 {
		height: 28.8rem;
	}

	.print\:h-320 {
		height: 32rem;
	}

	.print\:h-360 {
		height: 36rem;
	}

	.print\:h-384 {
		height: 38.4rem;
	}

	.print\:h-400 {
		height: 40rem;
	}

	.print\:h-512 {
		height: 51.2rem;
	}

	.print\:h-640 {
		height: 64rem;
	}

	.print\:h-auto {
		height: auto;
	}

	.print\:h-px {
		height: 1px;
	}

	.print\:h-xs {
		height: 32rem;
	}

	.print\:h-sm {
		height: 48rem;
	}

	.print\:h-md {
		height: 64rem;
	}

	.print\:h-lg {
		height: 80rem;
	}

	.print\:h-xl {
		height: 96rem;
	}

	.print\:h-2xl {
		height: 112rem;
	}

	.print\:h-3xl {
		height: 128rem;
	}

	.print\:h-4xl {
		height: 144rem;
	}

	.print\:h-5xl {
		height: 160rem;
	}

	.print\:h-full {
		height: 100%;
	}

	.print\:h-screen {
		height: 100vh;
	}

	.print\:leading-none {
		line-height: 1;
	}

	.print\:leading-tight {
		line-height: 1.25;
	}

	.print\:leading-snug {
		line-height: 1.375;
	}

	.print\:leading-normal {
		line-height: 1.5;
	}

	.print\:leading-relaxed {
		line-height: 1.625;
	}

	.print\:leading-loose {
		line-height: 2;
	}

	.print\:list-inside {
		list-style-position: inside;
	}

	.print\:list-outside {
		list-style-position: outside;
	}

	.print\:list-none {
		list-style-type: none;
	}

	.print\:list-disc {
		list-style-type: disc;
	}

	.print\:list-decimal {
		list-style-type: decimal;
	}

	.print\:m-0 {
		margin: 0;
	}

	.print\:m-1 {
		margin: 0.25rem;
	}

	.print\:m-2 {
		margin: 0.5rem;
	}

	.print\:m-3 {
		margin: 0.75rem;
	}

	.print\:m-4 {
		margin: 0.4rem;
	}

	.print\:m-5 {
		margin: 1.25rem;
	}

	.print\:m-6 {
		margin: 0.6rem;
	}

	.print\:m-8 {
		margin: 0.8rem;
	}

	.print\:m-10 {
		margin: 2.5rem;
	}

	.print\:m-12 {
		margin: 1.2rem;
	}

	.print\:m-16 {
		margin: 1.6rem;
	}

	.print\:m-20 {
		margin: 2rem;
	}

	.print\:m-24 {
		margin: 2.4rem;
	}

	.print\:m-28 {
		margin: 2.8rem;
	}

	.print\:m-32 {
		margin: 3.2rem;
	}

	.print\:m-36 {
		margin: 3.6rem;
	}

	.print\:m-40 {
		margin: 4rem;
	}

	.print\:m-44 {
		margin: 4.4rem;
	}

	.print\:m-48 {
		margin: 4.8rem;
	}

	.print\:m-52 {
		margin: 5.2rem;
	}

	.print\:m-56 {
		margin: 5.6rem;
	}

	.print\:m-60 {
		margin: 6rem;
	}

	.print\:m-64 {
		margin: 6.4rem;
	}

	.print\:m-68 {
		margin: 6.8rem;
	}

	.print\:m-72 {
		margin: 7.2rem;
	}

	.print\:m-76 {
		margin: 7.6rem;
	}

	.print\:m-80 {
		margin: 8rem;
	}

	.print\:m-84 {
		margin: 8.4rem;
	}

	.print\:m-88 {
		margin: 8.8rem;
	}

	.print\:m-92 {
		margin: 9.2rem;
	}

	.print\:m-96 {
		margin: 9.6rem;
	}

	.print\:m-128 {
		margin: 12.8rem;
	}

	.print\:m-136 {
		margin: 13.6rem;
	}

	.print\:m-160 {
		margin: 16rem;
	}

	.print\:m-192 {
		margin: 19.2rem;
	}

	.print\:m-200 {
		margin: 20rem;
	}

	.print\:m-208 {
		margin: 20.8rem;
	}

	.print\:m-216 {
		margin: 21.6rem;
	}

	.print\:m-224 {
		margin: 22.4rem;
	}

	.print\:m-256 {
		margin: 25.6rem;
	}

	.print\:m-288 {
		margin: 28.8rem;
	}

	.print\:m-320 {
		margin: 32rem;
	}

	.print\:m-360 {
		margin: 36rem;
	}

	.print\:m-384 {
		margin: 38.4rem;
	}

	.print\:m-400 {
		margin: 40rem;
	}

	.print\:m-512 {
		margin: 51.2rem;
	}

	.print\:m-640 {
		margin: 64rem;
	}

	.print\:m-auto {
		margin: auto;
	}

	.print\:m-px {
		margin: 1px;
	}

	.print\:m-xs {
		margin: 32rem;
	}

	.print\:m-sm {
		margin: 48rem;
	}

	.print\:m-md {
		margin: 64rem;
	}

	.print\:m-lg {
		margin: 80rem;
	}

	.print\:m-xl {
		margin: 96rem;
	}

	.print\:m-2xl {
		margin: 112rem;
	}

	.print\:m-3xl {
		margin: 128rem;
	}

	.print\:m-4xl {
		margin: 144rem;
	}

	.print\:m-5xl {
		margin: 160rem;
	}

	.print\:-m-1 {
		margin: -0.25rem;
	}

	.print\:-m-2 {
		margin: -0.5rem;
	}

	.print\:-m-3 {
		margin: -0.75rem;
	}

	.print\:-m-4 {
		margin: -0.4rem;
	}

	.print\:-m-5 {
		margin: -1.25rem;
	}

	.print\:-m-6 {
		margin: -0.6rem;
	}

	.print\:-m-8 {
		margin: -0.8rem;
	}

	.print\:-m-10 {
		margin: -2.5rem;
	}

	.print\:-m-12 {
		margin: -1.2rem;
	}

	.print\:-m-16 {
		margin: -1.6rem;
	}

	.print\:-m-20 {
		margin: -2rem;
	}

	.print\:-m-24 {
		margin: -2.4rem;
	}

	.print\:-m-28 {
		margin: -2.8rem;
	}

	.print\:-m-32 {
		margin: -3.2rem;
	}

	.print\:-m-36 {
		margin: -3.6rem;
	}

	.print\:-m-40 {
		margin: -4rem;
	}

	.print\:-m-44 {
		margin: -4.4rem;
	}

	.print\:-m-48 {
		margin: -4.8rem;
	}

	.print\:-m-52 {
		margin: -5.2rem;
	}

	.print\:-m-56 {
		margin: -5.6rem;
	}

	.print\:-m-60 {
		margin: -6rem;
	}

	.print\:-m-64 {
		margin: -6.4rem;
	}

	.print\:-m-68 {
		margin: -6.8rem;
	}

	.print\:-m-72 {
		margin: -7.2rem;
	}

	.print\:-m-76 {
		margin: -7.6rem;
	}

	.print\:-m-80 {
		margin: -8rem;
	}

	.print\:-m-84 {
		margin: -8.4rem;
	}

	.print\:-m-88 {
		margin: -8.8rem;
	}

	.print\:-m-92 {
		margin: -9.2rem;
	}

	.print\:-m-96 {
		margin: -9.6rem;
	}

	.print\:-m-128 {
		margin: -12.8rem;
	}

	.print\:-m-136 {
		margin: -13.6rem;
	}

	.print\:-m-160 {
		margin: -16rem;
	}

	.print\:-m-192 {
		margin: -19.2rem;
	}

	.print\:-m-200 {
		margin: -20rem;
	}

	.print\:-m-208 {
		margin: -20.8rem;
	}

	.print\:-m-216 {
		margin: -21.6rem;
	}

	.print\:-m-224 {
		margin: -22.4rem;
	}

	.print\:-m-256 {
		margin: -25.6rem;
	}

	.print\:-m-288 {
		margin: -28.8rem;
	}

	.print\:-m-320 {
		margin: -32rem;
	}

	.print\:-m-360 {
		margin: -36rem;
	}

	.print\:-m-384 {
		margin: -38.4rem;
	}

	.print\:-m-400 {
		margin: -40rem;
	}

	.print\:-m-512 {
		margin: -51.2rem;
	}

	.print\:-m-640 {
		margin: -64rem;
	}

	.print\:-m-px {
		margin: -1px;
	}

	.print\:-m-xs {
		margin: -32rem;
	}

	.print\:-m-sm {
		margin: -48rem;
	}

	.print\:-m-md {
		margin: -64rem;
	}

	.print\:-m-lg {
		margin: -80rem;
	}

	.print\:-m-xl {
		margin: -96rem;
	}

	.print\:-m-2xl {
		margin: -112rem;
	}

	.print\:-m-3xl {
		margin: -128rem;
	}

	.print\:-m-4xl {
		margin: -144rem;
	}

	.print\:-m-5xl {
		margin: -160rem;
	}

	.print\:-m-auto {
		margin: -auto;
	}

	.print\:my-0 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.print\:mx-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.print\:my-1 {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.print\:mx-1 {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.print\:my-2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.print\:mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.print\:my-3 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.print\:mx-3 {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.print\:my-4 {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.print\:mx-4 {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}

	.print\:my-5 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.print\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.print\:my-6 {
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}

	.print\:mx-6 {
		margin-left: 0.6rem;
		margin-right: 0.6rem;
	}

	.print\:my-8 {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}

	.print\:mx-8 {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}

	.print\:my-10 {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.print\:mx-10 {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.print\:my-12 {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.print\:mx-12 {
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.print\:my-16 {
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.print\:mx-16 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.print\:my-20 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.print\:mx-20 {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.print\:my-24 {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	.print\:mx-24 {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	.print\:my-28 {
		margin-top: 2.8rem;
		margin-bottom: 2.8rem;
	}

	.print\:mx-28 {
		margin-left: 2.8rem;
		margin-right: 2.8rem;
	}

	.print\:my-32 {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}

	.print\:mx-32 {
		margin-left: 3.2rem;
		margin-right: 3.2rem;
	}

	.print\:my-36 {
		margin-top: 3.6rem;
		margin-bottom: 3.6rem;
	}

	.print\:mx-36 {
		margin-left: 3.6rem;
		margin-right: 3.6rem;
	}

	.print\:my-40 {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.print\:mx-40 {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.print\:my-44 {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	.print\:mx-44 {
		margin-left: 4.4rem;
		margin-right: 4.4rem;
	}

	.print\:my-48 {
		margin-top: 4.8rem;
		margin-bottom: 4.8rem;
	}

	.print\:mx-48 {
		margin-left: 4.8rem;
		margin-right: 4.8rem;
	}

	.print\:my-52 {
		margin-top: 5.2rem;
		margin-bottom: 5.2rem;
	}

	.print\:mx-52 {
		margin-left: 5.2rem;
		margin-right: 5.2rem;
	}

	.print\:my-56 {
		margin-top: 5.6rem;
		margin-bottom: 5.6rem;
	}

	.print\:mx-56 {
		margin-left: 5.6rem;
		margin-right: 5.6rem;
	}

	.print\:my-60 {
		margin-top: 6rem;
		margin-bottom: 6rem;
	}

	.print\:mx-60 {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	.print\:my-64 {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	.print\:mx-64 {
		margin-left: 6.4rem;
		margin-right: 6.4rem;
	}

	.print\:my-68 {
		margin-top: 6.8rem;
		margin-bottom: 6.8rem;
	}

	.print\:mx-68 {
		margin-left: 6.8rem;
		margin-right: 6.8rem;
	}

	.print\:my-72 {
		margin-top: 7.2rem;
		margin-bottom: 7.2rem;
	}

	.print\:mx-72 {
		margin-left: 7.2rem;
		margin-right: 7.2rem;
	}

	.print\:my-76 {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.print\:mx-76 {
		margin-left: 7.6rem;
		margin-right: 7.6rem;
	}

	.print\:my-80 {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}

	.print\:mx-80 {
		margin-left: 8rem;
		margin-right: 8rem;
	}

	.print\:my-84 {
		margin-top: 8.4rem;
		margin-bottom: 8.4rem;
	}

	.print\:mx-84 {
		margin-left: 8.4rem;
		margin-right: 8.4rem;
	}

	.print\:my-88 {
		margin-top: 8.8rem;
		margin-bottom: 8.8rem;
	}

	.print\:mx-88 {
		margin-left: 8.8rem;
		margin-right: 8.8rem;
	}

	.print\:my-92 {
		margin-top: 9.2rem;
		margin-bottom: 9.2rem;
	}

	.print\:mx-92 {
		margin-left: 9.2rem;
		margin-right: 9.2rem;
	}

	.print\:my-96 {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}

	.print\:mx-96 {
		margin-left: 9.6rem;
		margin-right: 9.6rem;
	}

	.print\:my-128 {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}

	.print\:mx-128 {
		margin-left: 12.8rem;
		margin-right: 12.8rem;
	}

	.print\:my-136 {
		margin-top: 13.6rem;
		margin-bottom: 13.6rem;
	}

	.print\:mx-136 {
		margin-left: 13.6rem;
		margin-right: 13.6rem;
	}

	.print\:my-160 {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}

	.print\:mx-160 {
		margin-left: 16rem;
		margin-right: 16rem;
	}

	.print\:my-192 {
		margin-top: 19.2rem;
		margin-bottom: 19.2rem;
	}

	.print\:mx-192 {
		margin-left: 19.2rem;
		margin-right: 19.2rem;
	}

	.print\:my-200 {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.print\:mx-200 {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.print\:my-208 {
		margin-top: 20.8rem;
		margin-bottom: 20.8rem;
	}

	.print\:mx-208 {
		margin-left: 20.8rem;
		margin-right: 20.8rem;
	}

	.print\:my-216 {
		margin-top: 21.6rem;
		margin-bottom: 21.6rem;
	}

	.print\:mx-216 {
		margin-left: 21.6rem;
		margin-right: 21.6rem;
	}

	.print\:my-224 {
		margin-top: 22.4rem;
		margin-bottom: 22.4rem;
	}

	.print\:mx-224 {
		margin-left: 22.4rem;
		margin-right: 22.4rem;
	}

	.print\:my-256 {
		margin-top: 25.6rem;
		margin-bottom: 25.6rem;
	}

	.print\:mx-256 {
		margin-left: 25.6rem;
		margin-right: 25.6rem;
	}

	.print\:my-288 {
		margin-top: 28.8rem;
		margin-bottom: 28.8rem;
	}

	.print\:mx-288 {
		margin-left: 28.8rem;
		margin-right: 28.8rem;
	}

	.print\:my-320 {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.print\:mx-320 {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.print\:my-360 {
		margin-top: 36rem;
		margin-bottom: 36rem;
	}

	.print\:mx-360 {
		margin-left: 36rem;
		margin-right: 36rem;
	}

	.print\:my-384 {
		margin-top: 38.4rem;
		margin-bottom: 38.4rem;
	}

	.print\:mx-384 {
		margin-left: 38.4rem;
		margin-right: 38.4rem;
	}

	.print\:my-400 {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.print\:mx-400 {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.print\:my-512 {
		margin-top: 51.2rem;
		margin-bottom: 51.2rem;
	}

	.print\:mx-512 {
		margin-left: 51.2rem;
		margin-right: 51.2rem;
	}

	.print\:my-640 {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.print\:mx-640 {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.print\:my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.print\:mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.print\:my-px {
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.print\:mx-px {
		margin-left: 1px;
		margin-right: 1px;
	}

	.print\:my-xs {
		margin-top: 32rem;
		margin-bottom: 32rem;
	}

	.print\:mx-xs {
		margin-left: 32rem;
		margin-right: 32rem;
	}

	.print\:my-sm {
		margin-top: 48rem;
		margin-bottom: 48rem;
	}

	.print\:mx-sm {
		margin-left: 48rem;
		margin-right: 48rem;
	}

	.print\:my-md {
		margin-top: 64rem;
		margin-bottom: 64rem;
	}

	.print\:mx-md {
		margin-left: 64rem;
		margin-right: 64rem;
	}

	.print\:my-lg {
		margin-top: 80rem;
		margin-bottom: 80rem;
	}

	.print\:mx-lg {
		margin-left: 80rem;
		margin-right: 80rem;
	}

	.print\:my-xl {
		margin-top: 96rem;
		margin-bottom: 96rem;
	}

	.print\:mx-xl {
		margin-left: 96rem;
		margin-right: 96rem;
	}

	.print\:my-2xl {
		margin-top: 112rem;
		margin-bottom: 112rem;
	}

	.print\:mx-2xl {
		margin-left: 112rem;
		margin-right: 112rem;
	}

	.print\:my-3xl {
		margin-top: 128rem;
		margin-bottom: 128rem;
	}

	.print\:mx-3xl {
		margin-left: 128rem;
		margin-right: 128rem;
	}

	.print\:my-4xl {
		margin-top: 144rem;
		margin-bottom: 144rem;
	}

	.print\:mx-4xl {
		margin-left: 144rem;
		margin-right: 144rem;
	}

	.print\:my-5xl {
		margin-top: 160rem;
		margin-bottom: 160rem;
	}

	.print\:mx-5xl {
		margin-left: 160rem;
		margin-right: 160rem;
	}

	.print\:-my-1 {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}

	.print\:-mx-1 {
		margin-left: -0.25rem;
		margin-right: -0.25rem;
	}

	.print\:-my-2 {
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
	}

	.print\:-mx-2 {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	.print\:-my-3 {
		margin-top: -0.75rem;
		margin-bottom: -0.75rem;
	}

	.print\:-mx-3 {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}

	.print\:-my-4 {
		margin-top: -0.4rem;
		margin-bottom: -0.4rem;
	}

	.print\:-mx-4 {
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.print\:-my-5 {
		margin-top: -1.25rem;
		margin-bottom: -1.25rem;
	}

	.print\:-mx-5 {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	}

	.print\:-my-6 {
		margin-top: -0.6rem;
		margin-bottom: -0.6rem;
	}

	.print\:-mx-6 {
		margin-left: -0.6rem;
		margin-right: -0.6rem;
	}

	.print\:-my-8 {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}

	.print\:-mx-8 {
		margin-left: -0.8rem;
		margin-right: -0.8rem;
	}

	.print\:-my-10 {
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.print\:-mx-10 {
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}

	.print\:-my-12 {
		margin-top: -1.2rem;
		margin-bottom: -1.2rem;
	}

	.print\:-mx-12 {
		margin-left: -1.2rem;
		margin-right: -1.2rem;
	}

	.print\:-my-16 {
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	.print\:-mx-16 {
		margin-left: -1.6rem;
		margin-right: -1.6rem;
	}

	.print\:-my-20 {
		margin-top: -2rem;
		margin-bottom: -2rem;
	}

	.print\:-mx-20 {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.print\:-my-24 {
		margin-top: -2.4rem;
		margin-bottom: -2.4rem;
	}

	.print\:-mx-24 {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}

	.print\:-my-28 {
		margin-top: -2.8rem;
		margin-bottom: -2.8rem;
	}

	.print\:-mx-28 {
		margin-left: -2.8rem;
		margin-right: -2.8rem;
	}

	.print\:-my-32 {
		margin-top: -3.2rem;
		margin-bottom: -3.2rem;
	}

	.print\:-mx-32 {
		margin-left: -3.2rem;
		margin-right: -3.2rem;
	}

	.print\:-my-36 {
		margin-top: -3.6rem;
		margin-bottom: -3.6rem;
	}

	.print\:-mx-36 {
		margin-left: -3.6rem;
		margin-right: -3.6rem;
	}

	.print\:-my-40 {
		margin-top: -4rem;
		margin-bottom: -4rem;
	}

	.print\:-mx-40 {
		margin-left: -4rem;
		margin-right: -4rem;
	}

	.print\:-my-44 {
		margin-top: -4.4rem;
		margin-bottom: -4.4rem;
	}

	.print\:-mx-44 {
		margin-left: -4.4rem;
		margin-right: -4.4rem;
	}

	.print\:-my-48 {
		margin-top: -4.8rem;
		margin-bottom: -4.8rem;
	}

	.print\:-mx-48 {
		margin-left: -4.8rem;
		margin-right: -4.8rem;
	}

	.print\:-my-52 {
		margin-top: -5.2rem;
		margin-bottom: -5.2rem;
	}

	.print\:-mx-52 {
		margin-left: -5.2rem;
		margin-right: -5.2rem;
	}

	.print\:-my-56 {
		margin-top: -5.6rem;
		margin-bottom: -5.6rem;
	}

	.print\:-mx-56 {
		margin-left: -5.6rem;
		margin-right: -5.6rem;
	}

	.print\:-my-60 {
		margin-top: -6rem;
		margin-bottom: -6rem;
	}

	.print\:-mx-60 {
		margin-left: -6rem;
		margin-right: -6rem;
	}

	.print\:-my-64 {
		margin-top: -6.4rem;
		margin-bottom: -6.4rem;
	}

	.print\:-mx-64 {
		margin-left: -6.4rem;
		margin-right: -6.4rem;
	}

	.print\:-my-68 {
		margin-top: -6.8rem;
		margin-bottom: -6.8rem;
	}

	.print\:-mx-68 {
		margin-left: -6.8rem;
		margin-right: -6.8rem;
	}

	.print\:-my-72 {
		margin-top: -7.2rem;
		margin-bottom: -7.2rem;
	}

	.print\:-mx-72 {
		margin-left: -7.2rem;
		margin-right: -7.2rem;
	}

	.print\:-my-76 {
		margin-top: -7.6rem;
		margin-bottom: -7.6rem;
	}

	.print\:-mx-76 {
		margin-left: -7.6rem;
		margin-right: -7.6rem;
	}

	.print\:-my-80 {
		margin-top: -8rem;
		margin-bottom: -8rem;
	}

	.print\:-mx-80 {
		margin-left: -8rem;
		margin-right: -8rem;
	}

	.print\:-my-84 {
		margin-top: -8.4rem;
		margin-bottom: -8.4rem;
	}

	.print\:-mx-84 {
		margin-left: -8.4rem;
		margin-right: -8.4rem;
	}

	.print\:-my-88 {
		margin-top: -8.8rem;
		margin-bottom: -8.8rem;
	}

	.print\:-mx-88 {
		margin-left: -8.8rem;
		margin-right: -8.8rem;
	}

	.print\:-my-92 {
		margin-top: -9.2rem;
		margin-bottom: -9.2rem;
	}

	.print\:-mx-92 {
		margin-left: -9.2rem;
		margin-right: -9.2rem;
	}

	.print\:-my-96 {
		margin-top: -9.6rem;
		margin-bottom: -9.6rem;
	}

	.print\:-mx-96 {
		margin-left: -9.6rem;
		margin-right: -9.6rem;
	}

	.print\:-my-128 {
		margin-top: -12.8rem;
		margin-bottom: -12.8rem;
	}

	.print\:-mx-128 {
		margin-left: -12.8rem;
		margin-right: -12.8rem;
	}

	.print\:-my-136 {
		margin-top: -13.6rem;
		margin-bottom: -13.6rem;
	}

	.print\:-mx-136 {
		margin-left: -13.6rem;
		margin-right: -13.6rem;
	}

	.print\:-my-160 {
		margin-top: -16rem;
		margin-bottom: -16rem;
	}

	.print\:-mx-160 {
		margin-left: -16rem;
		margin-right: -16rem;
	}

	.print\:-my-192 {
		margin-top: -19.2rem;
		margin-bottom: -19.2rem;
	}

	.print\:-mx-192 {
		margin-left: -19.2rem;
		margin-right: -19.2rem;
	}

	.print\:-my-200 {
		margin-top: -20rem;
		margin-bottom: -20rem;
	}

	.print\:-mx-200 {
		margin-left: -20rem;
		margin-right: -20rem;
	}

	.print\:-my-208 {
		margin-top: -20.8rem;
		margin-bottom: -20.8rem;
	}

	.print\:-mx-208 {
		margin-left: -20.8rem;
		margin-right: -20.8rem;
	}

	.print\:-my-216 {
		margin-top: -21.6rem;
		margin-bottom: -21.6rem;
	}

	.print\:-mx-216 {
		margin-left: -21.6rem;
		margin-right: -21.6rem;
	}

	.print\:-my-224 {
		margin-top: -22.4rem;
		margin-bottom: -22.4rem;
	}

	.print\:-mx-224 {
		margin-left: -22.4rem;
		margin-right: -22.4rem;
	}

	.print\:-my-256 {
		margin-top: -25.6rem;
		margin-bottom: -25.6rem;
	}

	.print\:-mx-256 {
		margin-left: -25.6rem;
		margin-right: -25.6rem;
	}

	.print\:-my-288 {
		margin-top: -28.8rem;
		margin-bottom: -28.8rem;
	}

	.print\:-mx-288 {
		margin-left: -28.8rem;
		margin-right: -28.8rem;
	}

	.print\:-my-320 {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.print\:-mx-320 {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.print\:-my-360 {
		margin-top: -36rem;
		margin-bottom: -36rem;
	}

	.print\:-mx-360 {
		margin-left: -36rem;
		margin-right: -36rem;
	}

	.print\:-my-384 {
		margin-top: -38.4rem;
		margin-bottom: -38.4rem;
	}

	.print\:-mx-384 {
		margin-left: -38.4rem;
		margin-right: -38.4rem;
	}

	.print\:-my-400 {
		margin-top: -40rem;
		margin-bottom: -40rem;
	}

	.print\:-mx-400 {
		margin-left: -40rem;
		margin-right: -40rem;
	}

	.print\:-my-512 {
		margin-top: -51.2rem;
		margin-bottom: -51.2rem;
	}

	.print\:-mx-512 {
		margin-left: -51.2rem;
		margin-right: -51.2rem;
	}

	.print\:-my-640 {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.print\:-mx-640 {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.print\:-my-px {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	.print\:-mx-px {
		margin-left: -1px;
		margin-right: -1px;
	}

	.print\:-my-xs {
		margin-top: -32rem;
		margin-bottom: -32rem;
	}

	.print\:-mx-xs {
		margin-left: -32rem;
		margin-right: -32rem;
	}

	.print\:-my-sm {
		margin-top: -48rem;
		margin-bottom: -48rem;
	}

	.print\:-mx-sm {
		margin-left: -48rem;
		margin-right: -48rem;
	}

	.print\:-my-md {
		margin-top: -64rem;
		margin-bottom: -64rem;
	}

	.print\:-mx-md {
		margin-left: -64rem;
		margin-right: -64rem;
	}

	.print\:-my-lg {
		margin-top: -80rem;
		margin-bottom: -80rem;
	}

	.print\:-mx-lg {
		margin-left: -80rem;
		margin-right: -80rem;
	}

	.print\:-my-xl {
		margin-top: -96rem;
		margin-bottom: -96rem;
	}

	.print\:-mx-xl {
		margin-left: -96rem;
		margin-right: -96rem;
	}

	.print\:-my-2xl {
		margin-top: -112rem;
		margin-bottom: -112rem;
	}

	.print\:-mx-2xl {
		margin-left: -112rem;
		margin-right: -112rem;
	}

	.print\:-my-3xl {
		margin-top: -128rem;
		margin-bottom: -128rem;
	}

	.print\:-mx-3xl {
		margin-left: -128rem;
		margin-right: -128rem;
	}

	.print\:-my-4xl {
		margin-top: -144rem;
		margin-bottom: -144rem;
	}

	.print\:-mx-4xl {
		margin-left: -144rem;
		margin-right: -144rem;
	}

	.print\:-my-5xl {
		margin-top: -160rem;
		margin-bottom: -160rem;
	}

	.print\:-mx-5xl {
		margin-left: -160rem;
		margin-right: -160rem;
	}

	.print\:-my-auto {
		margin-top: -auto;
		margin-bottom: -auto;
	}

	.print\:-mx-auto {
		margin-left: -auto;
		margin-right: -auto;
	}

	.print\:mt-0 {
		margin-top: 0;
	}

	.print\:mr-0 {
		margin-right: 0;
	}

	.print\:mb-0 {
		margin-bottom: 0;
	}

	.print\:ml-0 {
		margin-left: 0;
	}

	.print\:mt-1 {
		margin-top: 0.25rem;
	}

	.print\:mr-1 {
		margin-right: 0.25rem;
	}

	.print\:mb-1 {
		margin-bottom: 0.25rem;
	}

	.print\:ml-1 {
		margin-left: 0.25rem;
	}

	.print\:mt-2 {
		margin-top: 0.5rem;
	}

	.print\:mr-2 {
		margin-right: 0.5rem;
	}

	.print\:mb-2 {
		margin-bottom: 0.5rem;
	}

	.print\:ml-2 {
		margin-left: 0.5rem;
	}

	.print\:mt-3 {
		margin-top: 0.75rem;
	}

	.print\:mr-3 {
		margin-right: 0.75rem;
	}

	.print\:mb-3 {
		margin-bottom: 0.75rem;
	}

	.print\:ml-3 {
		margin-left: 0.75rem;
	}

	.print\:mt-4 {
		margin-top: 0.4rem;
	}

	.print\:mr-4 {
		margin-right: 0.4rem;
	}

	.print\:mb-4 {
		margin-bottom: 0.4rem;
	}

	.print\:ml-4 {
		margin-left: 0.4rem;
	}

	.print\:mt-5 {
		margin-top: 1.25rem;
	}

	.print\:mr-5 {
		margin-right: 1.25rem;
	}

	.print\:mb-5 {
		margin-bottom: 1.25rem;
	}

	.print\:ml-5 {
		margin-left: 1.25rem;
	}

	.print\:mt-6 {
		margin-top: 0.6rem;
	}

	.print\:mr-6 {
		margin-right: 0.6rem;
	}

	.print\:mb-6 {
		margin-bottom: 0.6rem;
	}

	.print\:ml-6 {
		margin-left: 0.6rem;
	}

	.print\:mt-8 {
		margin-top: 0.8rem;
	}

	.print\:mr-8 {
		margin-right: 0.8rem;
	}

	.print\:mb-8 {
		margin-bottom: 0.8rem;
	}

	.print\:ml-8 {
		margin-left: 0.8rem;
	}

	.print\:mt-10 {
		margin-top: 2.5rem;
	}

	.print\:mr-10 {
		margin-right: 2.5rem;
	}

	.print\:mb-10 {
		margin-bottom: 2.5rem;
	}

	.print\:ml-10 {
		margin-left: 2.5rem;
	}

	.print\:mt-12 {
		margin-top: 1.2rem;
	}

	.print\:mr-12 {
		margin-right: 1.2rem;
	}

	.print\:mb-12 {
		margin-bottom: 1.2rem;
	}

	.print\:ml-12 {
		margin-left: 1.2rem;
	}

	.print\:mt-16 {
		margin-top: 1.6rem;
	}

	.print\:mr-16 {
		margin-right: 1.6rem;
	}

	.print\:mb-16 {
		margin-bottom: 1.6rem;
	}

	.print\:ml-16 {
		margin-left: 1.6rem;
	}

	.print\:mt-20 {
		margin-top: 2rem;
	}

	.print\:mr-20 {
		margin-right: 2rem;
	}

	.print\:mb-20 {
		margin-bottom: 2rem;
	}

	.print\:ml-20 {
		margin-left: 2rem;
	}

	.print\:mt-24 {
		margin-top: 2.4rem;
	}

	.print\:mr-24 {
		margin-right: 2.4rem;
	}

	.print\:mb-24 {
		margin-bottom: 2.4rem;
	}

	.print\:ml-24 {
		margin-left: 2.4rem;
	}

	.print\:mt-28 {
		margin-top: 2.8rem;
	}

	.print\:mr-28 {
		margin-right: 2.8rem;
	}

	.print\:mb-28 {
		margin-bottom: 2.8rem;
	}

	.print\:ml-28 {
		margin-left: 2.8rem;
	}

	.print\:mt-32 {
		margin-top: 3.2rem;
	}

	.print\:mr-32 {
		margin-right: 3.2rem;
	}

	.print\:mb-32 {
		margin-bottom: 3.2rem;
	}

	.print\:ml-32 {
		margin-left: 3.2rem;
	}

	.print\:mt-36 {
		margin-top: 3.6rem;
	}

	.print\:mr-36 {
		margin-right: 3.6rem;
	}

	.print\:mb-36 {
		margin-bottom: 3.6rem;
	}

	.print\:ml-36 {
		margin-left: 3.6rem;
	}

	.print\:mt-40 {
		margin-top: 4rem;
	}

	.print\:mr-40 {
		margin-right: 4rem;
	}

	.print\:mb-40 {
		margin-bottom: 4rem;
	}

	.print\:ml-40 {
		margin-left: 4rem;
	}

	.print\:mt-44 {
		margin-top: 4.4rem;
	}

	.print\:mr-44 {
		margin-right: 4.4rem;
	}

	.print\:mb-44 {
		margin-bottom: 4.4rem;
	}

	.print\:ml-44 {
		margin-left: 4.4rem;
	}

	.print\:mt-48 {
		margin-top: 4.8rem;
	}

	.print\:mr-48 {
		margin-right: 4.8rem;
	}

	.print\:mb-48 {
		margin-bottom: 4.8rem;
	}

	.print\:ml-48 {
		margin-left: 4.8rem;
	}

	.print\:mt-52 {
		margin-top: 5.2rem;
	}

	.print\:mr-52 {
		margin-right: 5.2rem;
	}

	.print\:mb-52 {
		margin-bottom: 5.2rem;
	}

	.print\:ml-52 {
		margin-left: 5.2rem;
	}

	.print\:mt-56 {
		margin-top: 5.6rem;
	}

	.print\:mr-56 {
		margin-right: 5.6rem;
	}

	.print\:mb-56 {
		margin-bottom: 5.6rem;
	}

	.print\:ml-56 {
		margin-left: 5.6rem;
	}

	.print\:mt-60 {
		margin-top: 6rem;
	}

	.print\:mr-60 {
		margin-right: 6rem;
	}

	.print\:mb-60 {
		margin-bottom: 6rem;
	}

	.print\:ml-60 {
		margin-left: 6rem;
	}

	.print\:mt-64 {
		margin-top: 6.4rem;
	}

	.print\:mr-64 {
		margin-right: 6.4rem;
	}

	.print\:mb-64 {
		margin-bottom: 6.4rem;
	}

	.print\:ml-64 {
		margin-left: 6.4rem;
	}

	.print\:mt-68 {
		margin-top: 6.8rem;
	}

	.print\:mr-68 {
		margin-right: 6.8rem;
	}

	.print\:mb-68 {
		margin-bottom: 6.8rem;
	}

	.print\:ml-68 {
		margin-left: 6.8rem;
	}

	.print\:mt-72 {
		margin-top: 7.2rem;
	}

	.print\:mr-72 {
		margin-right: 7.2rem;
	}

	.print\:mb-72 {
		margin-bottom: 7.2rem;
	}

	.print\:ml-72 {
		margin-left: 7.2rem;
	}

	.print\:mt-76 {
		margin-top: 7.6rem;
	}

	.print\:mr-76 {
		margin-right: 7.6rem;
	}

	.print\:mb-76 {
		margin-bottom: 7.6rem;
	}

	.print\:ml-76 {
		margin-left: 7.6rem;
	}

	.print\:mt-80 {
		margin-top: 8rem;
	}

	.print\:mr-80 {
		margin-right: 8rem;
	}

	.print\:mb-80 {
		margin-bottom: 8rem;
	}

	.print\:ml-80 {
		margin-left: 8rem;
	}

	.print\:mt-84 {
		margin-top: 8.4rem;
	}

	.print\:mr-84 {
		margin-right: 8.4rem;
	}

	.print\:mb-84 {
		margin-bottom: 8.4rem;
	}

	.print\:ml-84 {
		margin-left: 8.4rem;
	}

	.print\:mt-88 {
		margin-top: 8.8rem;
	}

	.print\:mr-88 {
		margin-right: 8.8rem;
	}

	.print\:mb-88 {
		margin-bottom: 8.8rem;
	}

	.print\:ml-88 {
		margin-left: 8.8rem;
	}

	.print\:mt-92 {
		margin-top: 9.2rem;
	}

	.print\:mr-92 {
		margin-right: 9.2rem;
	}

	.print\:mb-92 {
		margin-bottom: 9.2rem;
	}

	.print\:ml-92 {
		margin-left: 9.2rem;
	}

	.print\:mt-96 {
		margin-top: 9.6rem;
	}

	.print\:mr-96 {
		margin-right: 9.6rem;
	}

	.print\:mb-96 {
		margin-bottom: 9.6rem;
	}

	.print\:ml-96 {
		margin-left: 9.6rem;
	}

	.print\:mt-128 {
		margin-top: 12.8rem;
	}

	.print\:mr-128 {
		margin-right: 12.8rem;
	}

	.print\:mb-128 {
		margin-bottom: 12.8rem;
	}

	.print\:ml-128 {
		margin-left: 12.8rem;
	}

	.print\:mt-136 {
		margin-top: 13.6rem;
	}

	.print\:mr-136 {
		margin-right: 13.6rem;
	}

	.print\:mb-136 {
		margin-bottom: 13.6rem;
	}

	.print\:ml-136 {
		margin-left: 13.6rem;
	}

	.print\:mt-160 {
		margin-top: 16rem;
	}

	.print\:mr-160 {
		margin-right: 16rem;
	}

	.print\:mb-160 {
		margin-bottom: 16rem;
	}

	.print\:ml-160 {
		margin-left: 16rem;
	}

	.print\:mt-192 {
		margin-top: 19.2rem;
	}

	.print\:mr-192 {
		margin-right: 19.2rem;
	}

	.print\:mb-192 {
		margin-bottom: 19.2rem;
	}

	.print\:ml-192 {
		margin-left: 19.2rem;
	}

	.print\:mt-200 {
		margin-top: 20rem;
	}

	.print\:mr-200 {
		margin-right: 20rem;
	}

	.print\:mb-200 {
		margin-bottom: 20rem;
	}

	.print\:ml-200 {
		margin-left: 20rem;
	}

	.print\:mt-208 {
		margin-top: 20.8rem;
	}

	.print\:mr-208 {
		margin-right: 20.8rem;
	}

	.print\:mb-208 {
		margin-bottom: 20.8rem;
	}

	.print\:ml-208 {
		margin-left: 20.8rem;
	}

	.print\:mt-216 {
		margin-top: 21.6rem;
	}

	.print\:mr-216 {
		margin-right: 21.6rem;
	}

	.print\:mb-216 {
		margin-bottom: 21.6rem;
	}

	.print\:ml-216 {
		margin-left: 21.6rem;
	}

	.print\:mt-224 {
		margin-top: 22.4rem;
	}

	.print\:mr-224 {
		margin-right: 22.4rem;
	}

	.print\:mb-224 {
		margin-bottom: 22.4rem;
	}

	.print\:ml-224 {
		margin-left: 22.4rem;
	}

	.print\:mt-256 {
		margin-top: 25.6rem;
	}

	.print\:mr-256 {
		margin-right: 25.6rem;
	}

	.print\:mb-256 {
		margin-bottom: 25.6rem;
	}

	.print\:ml-256 {
		margin-left: 25.6rem;
	}

	.print\:mt-288 {
		margin-top: 28.8rem;
	}

	.print\:mr-288 {
		margin-right: 28.8rem;
	}

	.print\:mb-288 {
		margin-bottom: 28.8rem;
	}

	.print\:ml-288 {
		margin-left: 28.8rem;
	}

	.print\:mt-320 {
		margin-top: 32rem;
	}

	.print\:mr-320 {
		margin-right: 32rem;
	}

	.print\:mb-320 {
		margin-bottom: 32rem;
	}

	.print\:ml-320 {
		margin-left: 32rem;
	}

	.print\:mt-360 {
		margin-top: 36rem;
	}

	.print\:mr-360 {
		margin-right: 36rem;
	}

	.print\:mb-360 {
		margin-bottom: 36rem;
	}

	.print\:ml-360 {
		margin-left: 36rem;
	}

	.print\:mt-384 {
		margin-top: 38.4rem;
	}

	.print\:mr-384 {
		margin-right: 38.4rem;
	}

	.print\:mb-384 {
		margin-bottom: 38.4rem;
	}

	.print\:ml-384 {
		margin-left: 38.4rem;
	}

	.print\:mt-400 {
		margin-top: 40rem;
	}

	.print\:mr-400 {
		margin-right: 40rem;
	}

	.print\:mb-400 {
		margin-bottom: 40rem;
	}

	.print\:ml-400 {
		margin-left: 40rem;
	}

	.print\:mt-512 {
		margin-top: 51.2rem;
	}

	.print\:mr-512 {
		margin-right: 51.2rem;
	}

	.print\:mb-512 {
		margin-bottom: 51.2rem;
	}

	.print\:ml-512 {
		margin-left: 51.2rem;
	}

	.print\:mt-640 {
		margin-top: 64rem;
	}

	.print\:mr-640 {
		margin-right: 64rem;
	}

	.print\:mb-640 {
		margin-bottom: 64rem;
	}

	.print\:ml-640 {
		margin-left: 64rem;
	}

	.print\:mt-auto {
		margin-top: auto;
	}

	.print\:mr-auto {
		margin-right: auto;
	}

	.print\:mb-auto {
		margin-bottom: auto;
	}

	.print\:ml-auto {
		margin-left: auto;
	}

	.print\:mt-px {
		margin-top: 1px;
	}

	.print\:mr-px {
		margin-right: 1px;
	}

	.print\:mb-px {
		margin-bottom: 1px;
	}

	.print\:ml-px {
		margin-left: 1px;
	}

	.print\:mt-xs {
		margin-top: 32rem;
	}

	.print\:mr-xs {
		margin-right: 32rem;
	}

	.print\:mb-xs {
		margin-bottom: 32rem;
	}

	.print\:ml-xs {
		margin-left: 32rem;
	}

	.print\:mt-sm {
		margin-top: 48rem;
	}

	.print\:mr-sm {
		margin-right: 48rem;
	}

	.print\:mb-sm {
		margin-bottom: 48rem;
	}

	.print\:ml-sm {
		margin-left: 48rem;
	}

	.print\:mt-md {
		margin-top: 64rem;
	}

	.print\:mr-md {
		margin-right: 64rem;
	}

	.print\:mb-md {
		margin-bottom: 64rem;
	}

	.print\:ml-md {
		margin-left: 64rem;
	}

	.print\:mt-lg {
		margin-top: 80rem;
	}

	.print\:mr-lg {
		margin-right: 80rem;
	}

	.print\:mb-lg {
		margin-bottom: 80rem;
	}

	.print\:ml-lg {
		margin-left: 80rem;
	}

	.print\:mt-xl {
		margin-top: 96rem;
	}

	.print\:mr-xl {
		margin-right: 96rem;
	}

	.print\:mb-xl {
		margin-bottom: 96rem;
	}

	.print\:ml-xl {
		margin-left: 96rem;
	}

	.print\:mt-2xl {
		margin-top: 112rem;
	}

	.print\:mr-2xl {
		margin-right: 112rem;
	}

	.print\:mb-2xl {
		margin-bottom: 112rem;
	}

	.print\:ml-2xl {
		margin-left: 112rem;
	}

	.print\:mt-3xl {
		margin-top: 128rem;
	}

	.print\:mr-3xl {
		margin-right: 128rem;
	}

	.print\:mb-3xl {
		margin-bottom: 128rem;
	}

	.print\:ml-3xl {
		margin-left: 128rem;
	}

	.print\:mt-4xl {
		margin-top: 144rem;
	}

	.print\:mr-4xl {
		margin-right: 144rem;
	}

	.print\:mb-4xl {
		margin-bottom: 144rem;
	}

	.print\:ml-4xl {
		margin-left: 144rem;
	}

	.print\:mt-5xl {
		margin-top: 160rem;
	}

	.print\:mr-5xl {
		margin-right: 160rem;
	}

	.print\:mb-5xl {
		margin-bottom: 160rem;
	}

	.print\:ml-5xl {
		margin-left: 160rem;
	}

	.print\:-mt-1 {
		margin-top: -0.25rem;
	}

	.print\:-mr-1 {
		margin-right: -0.25rem;
	}

	.print\:-mb-1 {
		margin-bottom: -0.25rem;
	}

	.print\:-ml-1 {
		margin-left: -0.25rem;
	}

	.print\:-mt-2 {
		margin-top: -0.5rem;
	}

	.print\:-mr-2 {
		margin-right: -0.5rem;
	}

	.print\:-mb-2 {
		margin-bottom: -0.5rem;
	}

	.print\:-ml-2 {
		margin-left: -0.5rem;
	}

	.print\:-mt-3 {
		margin-top: -0.75rem;
	}

	.print\:-mr-3 {
		margin-right: -0.75rem;
	}

	.print\:-mb-3 {
		margin-bottom: -0.75rem;
	}

	.print\:-ml-3 {
		margin-left: -0.75rem;
	}

	.print\:-mt-4 {
		margin-top: -0.4rem;
	}

	.print\:-mr-4 {
		margin-right: -0.4rem;
	}

	.print\:-mb-4 {
		margin-bottom: -0.4rem;
	}

	.print\:-ml-4 {
		margin-left: -0.4rem;
	}

	.print\:-mt-5 {
		margin-top: -1.25rem;
	}

	.print\:-mr-5 {
		margin-right: -1.25rem;
	}

	.print\:-mb-5 {
		margin-bottom: -1.25rem;
	}

	.print\:-ml-5 {
		margin-left: -1.25rem;
	}

	.print\:-mt-6 {
		margin-top: -0.6rem;
	}

	.print\:-mr-6 {
		margin-right: -0.6rem;
	}

	.print\:-mb-6 {
		margin-bottom: -0.6rem;
	}

	.print\:-ml-6 {
		margin-left: -0.6rem;
	}

	.print\:-mt-8 {
		margin-top: -0.8rem;
	}

	.print\:-mr-8 {
		margin-right: -0.8rem;
	}

	.print\:-mb-8 {
		margin-bottom: -0.8rem;
	}

	.print\:-ml-8 {
		margin-left: -0.8rem;
	}

	.print\:-mt-10 {
		margin-top: -2.5rem;
	}

	.print\:-mr-10 {
		margin-right: -2.5rem;
	}

	.print\:-mb-10 {
		margin-bottom: -2.5rem;
	}

	.print\:-ml-10 {
		margin-left: -2.5rem;
	}

	.print\:-mt-12 {
		margin-top: -1.2rem;
	}

	.print\:-mr-12 {
		margin-right: -1.2rem;
	}

	.print\:-mb-12 {
		margin-bottom: -1.2rem;
	}

	.print\:-ml-12 {
		margin-left: -1.2rem;
	}

	.print\:-mt-16 {
		margin-top: -1.6rem;
	}

	.print\:-mr-16 {
		margin-right: -1.6rem;
	}

	.print\:-mb-16 {
		margin-bottom: -1.6rem;
	}

	.print\:-ml-16 {
		margin-left: -1.6rem;
	}

	.print\:-mt-20 {
		margin-top: -2rem;
	}

	.print\:-mr-20 {
		margin-right: -2rem;
	}

	.print\:-mb-20 {
		margin-bottom: -2rem;
	}

	.print\:-ml-20 {
		margin-left: -2rem;
	}

	.print\:-mt-24 {
		margin-top: -2.4rem;
	}

	.print\:-mr-24 {
		margin-right: -2.4rem;
	}

	.print\:-mb-24 {
		margin-bottom: -2.4rem;
	}

	.print\:-ml-24 {
		margin-left: -2.4rem;
	}

	.print\:-mt-28 {
		margin-top: -2.8rem;
	}

	.print\:-mr-28 {
		margin-right: -2.8rem;
	}

	.print\:-mb-28 {
		margin-bottom: -2.8rem;
	}

	.print\:-ml-28 {
		margin-left: -2.8rem;
	}

	.print\:-mt-32 {
		margin-top: -3.2rem;
	}

	.print\:-mr-32 {
		margin-right: -3.2rem;
	}

	.print\:-mb-32 {
		margin-bottom: -3.2rem;
	}

	.print\:-ml-32 {
		margin-left: -3.2rem;
	}

	.print\:-mt-36 {
		margin-top: -3.6rem;
	}

	.print\:-mr-36 {
		margin-right: -3.6rem;
	}

	.print\:-mb-36 {
		margin-bottom: -3.6rem;
	}

	.print\:-ml-36 {
		margin-left: -3.6rem;
	}

	.print\:-mt-40 {
		margin-top: -4rem;
	}

	.print\:-mr-40 {
		margin-right: -4rem;
	}

	.print\:-mb-40 {
		margin-bottom: -4rem;
	}

	.print\:-ml-40 {
		margin-left: -4rem;
	}

	.print\:-mt-44 {
		margin-top: -4.4rem;
	}

	.print\:-mr-44 {
		margin-right: -4.4rem;
	}

	.print\:-mb-44 {
		margin-bottom: -4.4rem;
	}

	.print\:-ml-44 {
		margin-left: -4.4rem;
	}

	.print\:-mt-48 {
		margin-top: -4.8rem;
	}

	.print\:-mr-48 {
		margin-right: -4.8rem;
	}

	.print\:-mb-48 {
		margin-bottom: -4.8rem;
	}

	.print\:-ml-48 {
		margin-left: -4.8rem;
	}

	.print\:-mt-52 {
		margin-top: -5.2rem;
	}

	.print\:-mr-52 {
		margin-right: -5.2rem;
	}

	.print\:-mb-52 {
		margin-bottom: -5.2rem;
	}

	.print\:-ml-52 {
		margin-left: -5.2rem;
	}

	.print\:-mt-56 {
		margin-top: -5.6rem;
	}

	.print\:-mr-56 {
		margin-right: -5.6rem;
	}

	.print\:-mb-56 {
		margin-bottom: -5.6rem;
	}

	.print\:-ml-56 {
		margin-left: -5.6rem;
	}

	.print\:-mt-60 {
		margin-top: -6rem;
	}

	.print\:-mr-60 {
		margin-right: -6rem;
	}

	.print\:-mb-60 {
		margin-bottom: -6rem;
	}

	.print\:-ml-60 {
		margin-left: -6rem;
	}

	.print\:-mt-64 {
		margin-top: -6.4rem;
	}

	.print\:-mr-64 {
		margin-right: -6.4rem;
	}

	.print\:-mb-64 {
		margin-bottom: -6.4rem;
	}

	.print\:-ml-64 {
		margin-left: -6.4rem;
	}

	.print\:-mt-68 {
		margin-top: -6.8rem;
	}

	.print\:-mr-68 {
		margin-right: -6.8rem;
	}

	.print\:-mb-68 {
		margin-bottom: -6.8rem;
	}

	.print\:-ml-68 {
		margin-left: -6.8rem;
	}

	.print\:-mt-72 {
		margin-top: -7.2rem;
	}

	.print\:-mr-72 {
		margin-right: -7.2rem;
	}

	.print\:-mb-72 {
		margin-bottom: -7.2rem;
	}

	.print\:-ml-72 {
		margin-left: -7.2rem;
	}

	.print\:-mt-76 {
		margin-top: -7.6rem;
	}

	.print\:-mr-76 {
		margin-right: -7.6rem;
	}

	.print\:-mb-76 {
		margin-bottom: -7.6rem;
	}

	.print\:-ml-76 {
		margin-left: -7.6rem;
	}

	.print\:-mt-80 {
		margin-top: -8rem;
	}

	.print\:-mr-80 {
		margin-right: -8rem;
	}

	.print\:-mb-80 {
		margin-bottom: -8rem;
	}

	.print\:-ml-80 {
		margin-left: -8rem;
	}

	.print\:-mt-84 {
		margin-top: -8.4rem;
	}

	.print\:-mr-84 {
		margin-right: -8.4rem;
	}

	.print\:-mb-84 {
		margin-bottom: -8.4rem;
	}

	.print\:-ml-84 {
		margin-left: -8.4rem;
	}

	.print\:-mt-88 {
		margin-top: -8.8rem;
	}

	.print\:-mr-88 {
		margin-right: -8.8rem;
	}

	.print\:-mb-88 {
		margin-bottom: -8.8rem;
	}

	.print\:-ml-88 {
		margin-left: -8.8rem;
	}

	.print\:-mt-92 {
		margin-top: -9.2rem;
	}

	.print\:-mr-92 {
		margin-right: -9.2rem;
	}

	.print\:-mb-92 {
		margin-bottom: -9.2rem;
	}

	.print\:-ml-92 {
		margin-left: -9.2rem;
	}

	.print\:-mt-96 {
		margin-top: -9.6rem;
	}

	.print\:-mr-96 {
		margin-right: -9.6rem;
	}

	.print\:-mb-96 {
		margin-bottom: -9.6rem;
	}

	.print\:-ml-96 {
		margin-left: -9.6rem;
	}

	.print\:-mt-128 {
		margin-top: -12.8rem;
	}

	.print\:-mr-128 {
		margin-right: -12.8rem;
	}

	.print\:-mb-128 {
		margin-bottom: -12.8rem;
	}

	.print\:-ml-128 {
		margin-left: -12.8rem;
	}

	.print\:-mt-136 {
		margin-top: -13.6rem;
	}

	.print\:-mr-136 {
		margin-right: -13.6rem;
	}

	.print\:-mb-136 {
		margin-bottom: -13.6rem;
	}

	.print\:-ml-136 {
		margin-left: -13.6rem;
	}

	.print\:-mt-160 {
		margin-top: -16rem;
	}

	.print\:-mr-160 {
		margin-right: -16rem;
	}

	.print\:-mb-160 {
		margin-bottom: -16rem;
	}

	.print\:-ml-160 {
		margin-left: -16rem;
	}

	.print\:-mt-192 {
		margin-top: -19.2rem;
	}

	.print\:-mr-192 {
		margin-right: -19.2rem;
	}

	.print\:-mb-192 {
		margin-bottom: -19.2rem;
	}

	.print\:-ml-192 {
		margin-left: -19.2rem;
	}

	.print\:-mt-200 {
		margin-top: -20rem;
	}

	.print\:-mr-200 {
		margin-right: -20rem;
	}

	.print\:-mb-200 {
		margin-bottom: -20rem;
	}

	.print\:-ml-200 {
		margin-left: -20rem;
	}

	.print\:-mt-208 {
		margin-top: -20.8rem;
	}

	.print\:-mr-208 {
		margin-right: -20.8rem;
	}

	.print\:-mb-208 {
		margin-bottom: -20.8rem;
	}

	.print\:-ml-208 {
		margin-left: -20.8rem;
	}

	.print\:-mt-216 {
		margin-top: -21.6rem;
	}

	.print\:-mr-216 {
		margin-right: -21.6rem;
	}

	.print\:-mb-216 {
		margin-bottom: -21.6rem;
	}

	.print\:-ml-216 {
		margin-left: -21.6rem;
	}

	.print\:-mt-224 {
		margin-top: -22.4rem;
	}

	.print\:-mr-224 {
		margin-right: -22.4rem;
	}

	.print\:-mb-224 {
		margin-bottom: -22.4rem;
	}

	.print\:-ml-224 {
		margin-left: -22.4rem;
	}

	.print\:-mt-256 {
		margin-top: -25.6rem;
	}

	.print\:-mr-256 {
		margin-right: -25.6rem;
	}

	.print\:-mb-256 {
		margin-bottom: -25.6rem;
	}

	.print\:-ml-256 {
		margin-left: -25.6rem;
	}

	.print\:-mt-288 {
		margin-top: -28.8rem;
	}

	.print\:-mr-288 {
		margin-right: -28.8rem;
	}

	.print\:-mb-288 {
		margin-bottom: -28.8rem;
	}

	.print\:-ml-288 {
		margin-left: -28.8rem;
	}

	.print\:-mt-320 {
		margin-top: -32rem;
	}

	.print\:-mr-320 {
		margin-right: -32rem;
	}

	.print\:-mb-320 {
		margin-bottom: -32rem;
	}

	.print\:-ml-320 {
		margin-left: -32rem;
	}

	.print\:-mt-360 {
		margin-top: -36rem;
	}

	.print\:-mr-360 {
		margin-right: -36rem;
	}

	.print\:-mb-360 {
		margin-bottom: -36rem;
	}

	.print\:-ml-360 {
		margin-left: -36rem;
	}

	.print\:-mt-384 {
		margin-top: -38.4rem;
	}

	.print\:-mr-384 {
		margin-right: -38.4rem;
	}

	.print\:-mb-384 {
		margin-bottom: -38.4rem;
	}

	.print\:-ml-384 {
		margin-left: -38.4rem;
	}

	.print\:-mt-400 {
		margin-top: -40rem;
	}

	.print\:-mr-400 {
		margin-right: -40rem;
	}

	.print\:-mb-400 {
		margin-bottom: -40rem;
	}

	.print\:-ml-400 {
		margin-left: -40rem;
	}

	.print\:-mt-512 {
		margin-top: -51.2rem;
	}

	.print\:-mr-512 {
		margin-right: -51.2rem;
	}

	.print\:-mb-512 {
		margin-bottom: -51.2rem;
	}

	.print\:-ml-512 {
		margin-left: -51.2rem;
	}

	.print\:-mt-640 {
		margin-top: -64rem;
	}

	.print\:-mr-640 {
		margin-right: -64rem;
	}

	.print\:-mb-640 {
		margin-bottom: -64rem;
	}

	.print\:-ml-640 {
		margin-left: -64rem;
	}

	.print\:-mt-px {
		margin-top: -1px;
	}

	.print\:-mr-px {
		margin-right: -1px;
	}

	.print\:-mb-px {
		margin-bottom: -1px;
	}

	.print\:-ml-px {
		margin-left: -1px;
	}

	.print\:-mt-xs {
		margin-top: -32rem;
	}

	.print\:-mr-xs {
		margin-right: -32rem;
	}

	.print\:-mb-xs {
		margin-bottom: -32rem;
	}

	.print\:-ml-xs {
		margin-left: -32rem;
	}

	.print\:-mt-sm {
		margin-top: -48rem;
	}

	.print\:-mr-sm {
		margin-right: -48rem;
	}

	.print\:-mb-sm {
		margin-bottom: -48rem;
	}

	.print\:-ml-sm {
		margin-left: -48rem;
	}

	.print\:-mt-md {
		margin-top: -64rem;
	}

	.print\:-mr-md {
		margin-right: -64rem;
	}

	.print\:-mb-md {
		margin-bottom: -64rem;
	}

	.print\:-ml-md {
		margin-left: -64rem;
	}

	.print\:-mt-lg {
		margin-top: -80rem;
	}

	.print\:-mr-lg {
		margin-right: -80rem;
	}

	.print\:-mb-lg {
		margin-bottom: -80rem;
	}

	.print\:-ml-lg {
		margin-left: -80rem;
	}

	.print\:-mt-xl {
		margin-top: -96rem;
	}

	.print\:-mr-xl {
		margin-right: -96rem;
	}

	.print\:-mb-xl {
		margin-bottom: -96rem;
	}

	.print\:-ml-xl {
		margin-left: -96rem;
	}

	.print\:-mt-2xl {
		margin-top: -112rem;
	}

	.print\:-mr-2xl {
		margin-right: -112rem;
	}

	.print\:-mb-2xl {
		margin-bottom: -112rem;
	}

	.print\:-ml-2xl {
		margin-left: -112rem;
	}

	.print\:-mt-3xl {
		margin-top: -128rem;
	}

	.print\:-mr-3xl {
		margin-right: -128rem;
	}

	.print\:-mb-3xl {
		margin-bottom: -128rem;
	}

	.print\:-ml-3xl {
		margin-left: -128rem;
	}

	.print\:-mt-4xl {
		margin-top: -144rem;
	}

	.print\:-mr-4xl {
		margin-right: -144rem;
	}

	.print\:-mb-4xl {
		margin-bottom: -144rem;
	}

	.print\:-ml-4xl {
		margin-left: -144rem;
	}

	.print\:-mt-5xl {
		margin-top: -160rem;
	}

	.print\:-mr-5xl {
		margin-right: -160rem;
	}

	.print\:-mb-5xl {
		margin-bottom: -160rem;
	}

	.print\:-ml-5xl {
		margin-left: -160rem;
	}

	.print\:-mt-auto {
		margin-top: -auto;
	}

	.print\:-mr-auto {
		margin-right: -auto;
	}

	.print\:-mb-auto {
		margin-bottom: -auto;
	}

	.print\:-ml-auto {
		margin-left: -auto;
	}

	.print\:max-h-0 {
		max-height: 0;
	}

	.print\:max-h-1 {
		max-height: 0.25rem;
	}

	.print\:max-h-2 {
		max-height: 0.5rem;
	}

	.print\:max-h-3 {
		max-height: 0.75rem;
	}

	.print\:max-h-4 {
		max-height: 0.4rem;
	}

	.print\:max-h-5 {
		max-height: 1.25rem;
	}

	.print\:max-h-6 {
		max-height: 0.6rem;
	}

	.print\:max-h-8 {
		max-height: 0.8rem;
	}

	.print\:max-h-10 {
		max-height: 2.5rem;
	}

	.print\:max-h-12 {
		max-height: 1.2rem;
	}

	.print\:max-h-16 {
		max-height: 1.6rem;
	}

	.print\:max-h-20 {
		max-height: 2rem;
	}

	.print\:max-h-24 {
		max-height: 2.4rem;
	}

	.print\:max-h-28 {
		max-height: 2.8rem;
	}

	.print\:max-h-32 {
		max-height: 3.2rem;
	}

	.print\:max-h-36 {
		max-height: 3.6rem;
	}

	.print\:max-h-40 {
		max-height: 4rem;
	}

	.print\:max-h-44 {
		max-height: 4.4rem;
	}

	.print\:max-h-48 {
		max-height: 4.8rem;
	}

	.print\:max-h-52 {
		max-height: 5.2rem;
	}

	.print\:max-h-56 {
		max-height: 5.6rem;
	}

	.print\:max-h-60 {
		max-height: 6rem;
	}

	.print\:max-h-64 {
		max-height: 6.4rem;
	}

	.print\:max-h-68 {
		max-height: 6.8rem;
	}

	.print\:max-h-72 {
		max-height: 7.2rem;
	}

	.print\:max-h-76 {
		max-height: 7.6rem;
	}

	.print\:max-h-80 {
		max-height: 8rem;
	}

	.print\:max-h-84 {
		max-height: 8.4rem;
	}

	.print\:max-h-88 {
		max-height: 8.8rem;
	}

	.print\:max-h-92 {
		max-height: 9.2rem;
	}

	.print\:max-h-96 {
		max-height: 9.6rem;
	}

	.print\:max-h-128 {
		max-height: 12.8rem;
	}

	.print\:max-h-136 {
		max-height: 13.6rem;
	}

	.print\:max-h-160 {
		max-height: 16rem;
	}

	.print\:max-h-192 {
		max-height: 19.2rem;
	}

	.print\:max-h-200 {
		max-height: 20rem;
	}

	.print\:max-h-208 {
		max-height: 20.8rem;
	}

	.print\:max-h-216 {
		max-height: 21.6rem;
	}

	.print\:max-h-224 {
		max-height: 22.4rem;
	}

	.print\:max-h-256 {
		max-height: 25.6rem;
	}

	.print\:max-h-288 {
		max-height: 28.8rem;
	}

	.print\:max-h-320 {
		max-height: 32rem;
	}

	.print\:max-h-360 {
		max-height: 36rem;
	}

	.print\:max-h-384 {
		max-height: 38.4rem;
	}

	.print\:max-h-400 {
		max-height: 40rem;
	}

	.print\:max-h-512 {
		max-height: 51.2rem;
	}

	.print\:max-h-640 {
		max-height: 64rem;
	}

	.print\:max-h-full {
		max-height: 100%;
	}

	.print\:max-h-screen {
		max-height: 100vh;
	}

	.print\:max-h-auto {
		max-height: auto;
	}

	.print\:max-h-px {
		max-height: 1px;
	}

	.print\:max-h-xs {
		max-height: 32rem;
	}

	.print\:max-h-sm {
		max-height: 48rem;
	}

	.print\:max-h-md {
		max-height: 64rem;
	}

	.print\:max-h-lg {
		max-height: 80rem;
	}

	.print\:max-h-xl {
		max-height: 96rem;
	}

	.print\:max-h-2xl {
		max-height: 112rem;
	}

	.print\:max-h-3xl {
		max-height: 128rem;
	}

	.print\:max-h-4xl {
		max-height: 144rem;
	}

	.print\:max-h-5xl {
		max-height: 160rem;
	}

	.print\:max-w-0 {
		max-width: 0;
	}

	.print\:max-w-1 {
		max-width: 0.25rem;
	}

	.print\:max-w-2 {
		max-width: 0.5rem;
	}

	.print\:max-w-3 {
		max-width: 0.75rem;
	}

	.print\:max-w-4 {
		max-width: 0.4rem;
	}

	.print\:max-w-5 {
		max-width: 1.25rem;
	}

	.print\:max-w-6 {
		max-width: 0.6rem;
	}

	.print\:max-w-8 {
		max-width: 0.8rem;
	}

	.print\:max-w-10 {
		max-width: 2.5rem;
	}

	.print\:max-w-12 {
		max-width: 1.2rem;
	}

	.print\:max-w-16 {
		max-width: 1.6rem;
	}

	.print\:max-w-20 {
		max-width: 2rem;
	}

	.print\:max-w-24 {
		max-width: 2.4rem;
	}

	.print\:max-w-28 {
		max-width: 2.8rem;
	}

	.print\:max-w-32 {
		max-width: 3.2rem;
	}

	.print\:max-w-36 {
		max-width: 3.6rem;
	}

	.print\:max-w-40 {
		max-width: 4rem;
	}

	.print\:max-w-44 {
		max-width: 4.4rem;
	}

	.print\:max-w-48 {
		max-width: 4.8rem;
	}

	.print\:max-w-52 {
		max-width: 5.2rem;
	}

	.print\:max-w-56 {
		max-width: 5.6rem;
	}

	.print\:max-w-60 {
		max-width: 6rem;
	}

	.print\:max-w-64 {
		max-width: 6.4rem;
	}

	.print\:max-w-68 {
		max-width: 6.8rem;
	}

	.print\:max-w-72 {
		max-width: 7.2rem;
	}

	.print\:max-w-76 {
		max-width: 7.6rem;
	}

	.print\:max-w-80 {
		max-width: 8rem;
	}

	.print\:max-w-84 {
		max-width: 8.4rem;
	}

	.print\:max-w-88 {
		max-width: 8.8rem;
	}

	.print\:max-w-92 {
		max-width: 9.2rem;
	}

	.print\:max-w-96 {
		max-width: 9.6rem;
	}

	.print\:max-w-128 {
		max-width: 12.8rem;
	}

	.print\:max-w-136 {
		max-width: 13.6rem;
	}

	.print\:max-w-160 {
		max-width: 16rem;
	}

	.print\:max-w-192 {
		max-width: 19.2rem;
	}

	.print\:max-w-200 {
		max-width: 20rem;
	}

	.print\:max-w-208 {
		max-width: 20.8rem;
	}

	.print\:max-w-216 {
		max-width: 21.6rem;
	}

	.print\:max-w-224 {
		max-width: 22.4rem;
	}

	.print\:max-w-256 {
		max-width: 25.6rem;
	}

	.print\:max-w-288 {
		max-width: 28.8rem;
	}

	.print\:max-w-320 {
		max-width: 32rem;
	}

	.print\:max-w-360 {
		max-width: 36rem;
	}

	.print\:max-w-384 {
		max-width: 38.4rem;
	}

	.print\:max-w-400 {
		max-width: 40rem;
	}

	.print\:max-w-512 {
		max-width: 51.2rem;
	}

	.print\:max-w-640 {
		max-width: 64rem;
	}

	.print\:max-w-xs {
		max-width: 32rem;
	}

	.print\:max-w-sm {
		max-width: 48rem;
	}

	.print\:max-w-md {
		max-width: 64rem;
	}

	.print\:max-w-lg {
		max-width: 80rem;
	}

	.print\:max-w-xl {
		max-width: 96rem;
	}

	.print\:max-w-2xl {
		max-width: 112rem;
	}

	.print\:max-w-3xl {
		max-width: 128rem;
	}

	.print\:max-w-4xl {
		max-width: 144rem;
	}

	.print\:max-w-5xl {
		max-width: 160rem;
	}

	.print\:max-w-6xl {
		max-width: 72rem;
	}

	.print\:max-w-full {
		max-width: 100%;
	}

	.print\:max-w-px {
		max-width: 1px;
	}

	.print\:max-w-auto {
		max-width: auto;
	}

	.print\:max-w-screen {
		max-width: 100vw;
	}

	.print\:min-h-0 {
		min-height: 0;
	}

	.print\:min-h-1 {
		min-height: 0.25rem;
	}

	.print\:min-h-2 {
		min-height: 0.5rem;
	}

	.print\:min-h-3 {
		min-height: 0.75rem;
	}

	.print\:min-h-4 {
		min-height: 0.4rem;
	}

	.print\:min-h-5 {
		min-height: 1.25rem;
	}

	.print\:min-h-6 {
		min-height: 0.6rem;
	}

	.print\:min-h-8 {
		min-height: 0.8rem;
	}

	.print\:min-h-10 {
		min-height: 2.5rem;
	}

	.print\:min-h-12 {
		min-height: 1.2rem;
	}

	.print\:min-h-16 {
		min-height: 1.6rem;
	}

	.print\:min-h-20 {
		min-height: 2rem;
	}

	.print\:min-h-24 {
		min-height: 2.4rem;
	}

	.print\:min-h-28 {
		min-height: 2.8rem;
	}

	.print\:min-h-32 {
		min-height: 3.2rem;
	}

	.print\:min-h-36 {
		min-height: 3.6rem;
	}

	.print\:min-h-40 {
		min-height: 4rem;
	}

	.print\:min-h-44 {
		min-height: 4.4rem;
	}

	.print\:min-h-48 {
		min-height: 4.8rem;
	}

	.print\:min-h-52 {
		min-height: 5.2rem;
	}

	.print\:min-h-56 {
		min-height: 5.6rem;
	}

	.print\:min-h-60 {
		min-height: 6rem;
	}

	.print\:min-h-64 {
		min-height: 6.4rem;
	}

	.print\:min-h-68 {
		min-height: 6.8rem;
	}

	.print\:min-h-72 {
		min-height: 7.2rem;
	}

	.print\:min-h-76 {
		min-height: 7.6rem;
	}

	.print\:min-h-80 {
		min-height: 8rem;
	}

	.print\:min-h-84 {
		min-height: 8.4rem;
	}

	.print\:min-h-88 {
		min-height: 8.8rem;
	}

	.print\:min-h-92 {
		min-height: 9.2rem;
	}

	.print\:min-h-96 {
		min-height: 9.6rem;
	}

	.print\:min-h-128 {
		min-height: 12.8rem;
	}

	.print\:min-h-136 {
		min-height: 13.6rem;
	}

	.print\:min-h-160 {
		min-height: 16rem;
	}

	.print\:min-h-192 {
		min-height: 19.2rem;
	}

	.print\:min-h-200 {
		min-height: 20rem;
	}

	.print\:min-h-208 {
		min-height: 20.8rem;
	}

	.print\:min-h-216 {
		min-height: 21.6rem;
	}

	.print\:min-h-224 {
		min-height: 22.4rem;
	}

	.print\:min-h-256 {
		min-height: 25.6rem;
	}

	.print\:min-h-288 {
		min-height: 28.8rem;
	}

	.print\:min-h-320 {
		min-height: 32rem;
	}

	.print\:min-h-360 {
		min-height: 36rem;
	}

	.print\:min-h-384 {
		min-height: 38.4rem;
	}

	.print\:min-h-400 {
		min-height: 40rem;
	}

	.print\:min-h-512 {
		min-height: 51.2rem;
	}

	.print\:min-h-640 {
		min-height: 64rem;
	}

	.print\:min-h-full {
		min-height: 100%;
	}

	.print\:min-h-screen {
		min-height: 100vh;
	}

	.print\:min-h-auto {
		min-height: auto;
	}

	.print\:min-h-px {
		min-height: 1px;
	}

	.print\:min-h-xs {
		min-height: 32rem;
	}

	.print\:min-h-sm {
		min-height: 48rem;
	}

	.print\:min-h-md {
		min-height: 64rem;
	}

	.print\:min-h-lg {
		min-height: 80rem;
	}

	.print\:min-h-xl {
		min-height: 96rem;
	}

	.print\:min-h-2xl {
		min-height: 112rem;
	}

	.print\:min-h-3xl {
		min-height: 128rem;
	}

	.print\:min-h-4xl {
		min-height: 144rem;
	}

	.print\:min-h-5xl {
		min-height: 160rem;
	}

	.print\:min-w-0 {
		min-width: 0;
	}

	.print\:min-w-1 {
		min-width: 0.25rem;
	}

	.print\:min-w-2 {
		min-width: 0.5rem;
	}

	.print\:min-w-3 {
		min-width: 0.75rem;
	}

	.print\:min-w-4 {
		min-width: 0.4rem;
	}

	.print\:min-w-5 {
		min-width: 1.25rem;
	}

	.print\:min-w-6 {
		min-width: 0.6rem;
	}

	.print\:min-w-8 {
		min-width: 0.8rem;
	}

	.print\:min-w-10 {
		min-width: 2.5rem;
	}

	.print\:min-w-12 {
		min-width: 1.2rem;
	}

	.print\:min-w-16 {
		min-width: 1.6rem;
	}

	.print\:min-w-20 {
		min-width: 2rem;
	}

	.print\:min-w-24 {
		min-width: 2.4rem;
	}

	.print\:min-w-28 {
		min-width: 2.8rem;
	}

	.print\:min-w-32 {
		min-width: 3.2rem;
	}

	.print\:min-w-36 {
		min-width: 3.6rem;
	}

	.print\:min-w-40 {
		min-width: 4rem;
	}

	.print\:min-w-44 {
		min-width: 4.4rem;
	}

	.print\:min-w-48 {
		min-width: 4.8rem;
	}

	.print\:min-w-52 {
		min-width: 5.2rem;
	}

	.print\:min-w-56 {
		min-width: 5.6rem;
	}

	.print\:min-w-60 {
		min-width: 6rem;
	}

	.print\:min-w-64 {
		min-width: 6.4rem;
	}

	.print\:min-w-68 {
		min-width: 6.8rem;
	}

	.print\:min-w-72 {
		min-width: 7.2rem;
	}

	.print\:min-w-76 {
		min-width: 7.6rem;
	}

	.print\:min-w-80 {
		min-width: 8rem;
	}

	.print\:min-w-84 {
		min-width: 8.4rem;
	}

	.print\:min-w-88 {
		min-width: 8.8rem;
	}

	.print\:min-w-92 {
		min-width: 9.2rem;
	}

	.print\:min-w-96 {
		min-width: 9.6rem;
	}

	.print\:min-w-128 {
		min-width: 12.8rem;
	}

	.print\:min-w-136 {
		min-width: 13.6rem;
	}

	.print\:min-w-160 {
		min-width: 16rem;
	}

	.print\:min-w-192 {
		min-width: 19.2rem;
	}

	.print\:min-w-200 {
		min-width: 20rem;
	}

	.print\:min-w-208 {
		min-width: 20.8rem;
	}

	.print\:min-w-216 {
		min-width: 21.6rem;
	}

	.print\:min-w-224 {
		min-width: 22.4rem;
	}

	.print\:min-w-256 {
		min-width: 25.6rem;
	}

	.print\:min-w-288 {
		min-width: 28.8rem;
	}

	.print\:min-w-320 {
		min-width: 32rem;
	}

	.print\:min-w-360 {
		min-width: 36rem;
	}

	.print\:min-w-384 {
		min-width: 38.4rem;
	}

	.print\:min-w-400 {
		min-width: 40rem;
	}

	.print\:min-w-512 {
		min-width: 51.2rem;
	}

	.print\:min-w-640 {
		min-width: 64rem;
	}

	.print\:min-w-full {
		min-width: 100%;
	}

	.print\:min-w-px {
		min-width: 1px;
	}

	.print\:min-w-xs {
		min-width: 32rem;
	}

	.print\:min-w-sm {
		min-width: 48rem;
	}

	.print\:min-w-md {
		min-width: 64rem;
	}

	.print\:min-w-lg {
		min-width: 80rem;
	}

	.print\:min-w-xl {
		min-width: 96rem;
	}

	.print\:min-w-2xl {
		min-width: 112rem;
	}

	.print\:min-w-3xl {
		min-width: 128rem;
	}

	.print\:min-w-4xl {
		min-width: 144rem;
	}

	.print\:min-w-5xl {
		min-width: 160rem;
	}

	.print\:min-w-auto {
		min-width: auto;
	}

	.print\:min-w-screen {
		min-width: 100vw;
	}

	.print\:object-contain {
		object-fit: contain;
	}

	.print\:object-cover {
		object-fit: cover;
	}

	.print\:object-fill {
		object-fit: fill;
	}

	.print\:object-none {
		object-fit: none;
	}

	.print\:object-scale-down {
		object-fit: scale-down;
	}

	.print\:object-bottom {
		object-position: bottom;
	}

	.print\:object-center {
		object-position: center;
	}

	.print\:object-left {
		object-position: left;
	}

	.print\:object-left-bottom {
		object-position: left bottom;
	}

	.print\:object-left-top {
		object-position: left top;
	}

	.print\:object-right {
		object-position: right;
	}

	.print\:object-right-bottom {
		object-position: right bottom;
	}

	.print\:object-right-top {
		object-position: right top;
	}

	.print\:object-top {
		object-position: top;
	}

	.print\:opacity-0 {
		opacity: 0;
	}

	.print\:opacity-25 {
		opacity: 0.25;
	}

	.print\:opacity-50 {
		opacity: 0.5;
	}

	.print\:opacity-75 {
		opacity: 0.75;
	}

	.print\:opacity-100 {
		opacity: 1;
	}

	.print\:outline-none {
		outline: 0;
	}

	.print\:focus\:outline-none:focus {
		outline: 0;
	}

	.print\:overflow-auto {
		overflow: auto;
	}

	.print\:overflow-hidden {
		overflow: hidden;
	}

	.print\:overflow-visible {
		overflow: visible;
	}

	.print\:overflow-scroll {
		overflow: scroll;
	}

	.print\:overflow-x-auto {
		overflow-x: auto;
	}

	.print\:overflow-y-auto {
		overflow-y: auto;
	}

	.print\:overflow-x-hidden {
		overflow-x: hidden;
	}

	.print\:overflow-y-hidden {
		overflow-y: hidden;
	}

	.print\:overflow-x-visible {
		overflow-x: visible;
	}

	.print\:overflow-y-visible {
		overflow-y: visible;
	}

	.print\:overflow-x-scroll {
		overflow-x: scroll;
	}

	.print\:overflow-y-scroll {
		overflow-y: scroll;
	}

	.print\:scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}

	.print\:scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}

	.print\:p-0 {
		padding: 0;
	}

	.print\:p-1 {
		padding: 0.25rem;
	}

	.print\:p-2 {
		padding: 0.5rem;
	}

	.print\:p-3 {
		padding: 0.75rem;
	}

	.print\:p-4 {
		padding: 0.4rem;
	}

	.print\:p-5 {
		padding: 1.25rem;
	}

	.print\:p-6 {
		padding: 0.6rem;
	}

	.print\:p-8 {
		padding: 0.8rem;
	}

	.print\:p-10 {
		padding: 2.5rem;
	}

	.print\:p-12 {
		padding: 1.2rem;
	}

	.print\:p-16 {
		padding: 1.6rem;
	}

	.print\:p-20 {
		padding: 2rem;
	}

	.print\:p-24 {
		padding: 2.4rem;
	}

	.print\:p-28 {
		padding: 2.8rem;
	}

	.print\:p-32 {
		padding: 3.2rem;
	}

	.print\:p-36 {
		padding: 3.6rem;
	}

	.print\:p-40 {
		padding: 4rem;
	}

	.print\:p-44 {
		padding: 4.4rem;
	}

	.print\:p-48 {
		padding: 4.8rem;
	}

	.print\:p-52 {
		padding: 5.2rem;
	}

	.print\:p-56 {
		padding: 5.6rem;
	}

	.print\:p-60 {
		padding: 6rem;
	}

	.print\:p-64 {
		padding: 6.4rem;
	}

	.print\:p-68 {
		padding: 6.8rem;
	}

	.print\:p-72 {
		padding: 7.2rem;
	}

	.print\:p-76 {
		padding: 7.6rem;
	}

	.print\:p-80 {
		padding: 8rem;
	}

	.print\:p-84 {
		padding: 8.4rem;
	}

	.print\:p-88 {
		padding: 8.8rem;
	}

	.print\:p-92 {
		padding: 9.2rem;
	}

	.print\:p-96 {
		padding: 9.6rem;
	}

	.print\:p-128 {
		padding: 12.8rem;
	}

	.print\:p-136 {
		padding: 13.6rem;
	}

	.print\:p-160 {
		padding: 16rem;
	}

	.print\:p-192 {
		padding: 19.2rem;
	}

	.print\:p-200 {
		padding: 20rem;
	}

	.print\:p-208 {
		padding: 20.8rem;
	}

	.print\:p-216 {
		padding: 21.6rem;
	}

	.print\:p-224 {
		padding: 22.4rem;
	}

	.print\:p-256 {
		padding: 25.6rem;
	}

	.print\:p-288 {
		padding: 28.8rem;
	}

	.print\:p-320 {
		padding: 32rem;
	}

	.print\:p-360 {
		padding: 36rem;
	}

	.print\:p-384 {
		padding: 38.4rem;
	}

	.print\:p-400 {
		padding: 40rem;
	}

	.print\:p-512 {
		padding: 51.2rem;
	}

	.print\:p-640 {
		padding: 64rem;
	}

	.print\:p-px {
		padding: 1px;
	}

	.print\:p-xs {
		padding: 32rem;
	}

	.print\:p-sm {
		padding: 48rem;
	}

	.print\:p-md {
		padding: 64rem;
	}

	.print\:p-lg {
		padding: 80rem;
	}

	.print\:p-xl {
		padding: 96rem;
	}

	.print\:p-2xl {
		padding: 112rem;
	}

	.print\:p-3xl {
		padding: 128rem;
	}

	.print\:p-4xl {
		padding: 144rem;
	}

	.print\:p-5xl {
		padding: 160rem;
	}

	.print\:p-auto {
		padding: auto;
	}

	.print\:py-0 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.print\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.print\:py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.print\:px-1 {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.print\:py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.print\:px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.print\:py-3 {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.print\:px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.print\:py-4 {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.print\:px-4 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}

	.print\:py-5 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.print\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.print\:py-6 {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.print\:px-6 {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.print\:py-8 {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
	}

	.print\:px-8 {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.print\:py-10 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.print\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.print\:py-12 {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.print\:px-12 {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}

	.print\:py-16 {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	.print\:px-16 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.print\:py-20 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.print\:px-20 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.print\:py-24 {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	.print\:px-24 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.print\:py-28 {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}

	.print\:px-28 {
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	.print\:py-32 {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.print\:px-32 {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.print\:py-36 {
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
	}

	.print\:px-36 {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.print\:py-40 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.print\:px-40 {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	.print\:py-44 {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.print\:px-44 {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.print\:py-48 {
		padding-top: 4.8rem;
		padding-bottom: 4.8rem;
	}

	.print\:px-48 {
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.print\:py-52 {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.print\:px-52 {
		padding-left: 5.2rem;
		padding-right: 5.2rem;
	}

	.print\:py-56 {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}

	.print\:px-56 {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}

	.print\:py-60 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.print\:px-60 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.print\:py-64 {
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;
	}

	.print\:px-64 {
		padding-left: 6.4rem;
		padding-right: 6.4rem;
	}

	.print\:py-68 {
		padding-top: 6.8rem;
		padding-bottom: 6.8rem;
	}

	.print\:px-68 {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.print\:py-72 {
		padding-top: 7.2rem;
		padding-bottom: 7.2rem;
	}

	.print\:px-72 {
		padding-left: 7.2rem;
		padding-right: 7.2rem;
	}

	.print\:py-76 {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
	}

	.print\:px-76 {
		padding-left: 7.6rem;
		padding-right: 7.6rem;
	}

	.print\:py-80 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.print\:px-80 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.print\:py-84 {
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}

	.print\:px-84 {
		padding-left: 8.4rem;
		padding-right: 8.4rem;
	}

	.print\:py-88 {
		padding-top: 8.8rem;
		padding-bottom: 8.8rem;
	}

	.print\:px-88 {
		padding-left: 8.8rem;
		padding-right: 8.8rem;
	}

	.print\:py-92 {
		padding-top: 9.2rem;
		padding-bottom: 9.2rem;
	}

	.print\:px-92 {
		padding-left: 9.2rem;
		padding-right: 9.2rem;
	}

	.print\:py-96 {
		padding-top: 9.6rem;
		padding-bottom: 9.6rem;
	}

	.print\:px-96 {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}

	.print\:py-128 {
		padding-top: 12.8rem;
		padding-bottom: 12.8rem;
	}

	.print\:px-128 {
		padding-left: 12.8rem;
		padding-right: 12.8rem;
	}

	.print\:py-136 {
		padding-top: 13.6rem;
		padding-bottom: 13.6rem;
	}

	.print\:px-136 {
		padding-left: 13.6rem;
		padding-right: 13.6rem;
	}

	.print\:py-160 {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}

	.print\:px-160 {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.print\:py-192 {
		padding-top: 19.2rem;
		padding-bottom: 19.2rem;
	}

	.print\:px-192 {
		padding-left: 19.2rem;
		padding-right: 19.2rem;
	}

	.print\:py-200 {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.print\:px-200 {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.print\:py-208 {
		padding-top: 20.8rem;
		padding-bottom: 20.8rem;
	}

	.print\:px-208 {
		padding-left: 20.8rem;
		padding-right: 20.8rem;
	}

	.print\:py-216 {
		padding-top: 21.6rem;
		padding-bottom: 21.6rem;
	}

	.print\:px-216 {
		padding-left: 21.6rem;
		padding-right: 21.6rem;
	}

	.print\:py-224 {
		padding-top: 22.4rem;
		padding-bottom: 22.4rem;
	}

	.print\:px-224 {
		padding-left: 22.4rem;
		padding-right: 22.4rem;
	}

	.print\:py-256 {
		padding-top: 25.6rem;
		padding-bottom: 25.6rem;
	}

	.print\:px-256 {
		padding-left: 25.6rem;
		padding-right: 25.6rem;
	}

	.print\:py-288 {
		padding-top: 28.8rem;
		padding-bottom: 28.8rem;
	}

	.print\:px-288 {
		padding-left: 28.8rem;
		padding-right: 28.8rem;
	}

	.print\:py-320 {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.print\:px-320 {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.print\:py-360 {
		padding-top: 36rem;
		padding-bottom: 36rem;
	}

	.print\:px-360 {
		padding-left: 36rem;
		padding-right: 36rem;
	}

	.print\:py-384 {
		padding-top: 38.4rem;
		padding-bottom: 38.4rem;
	}

	.print\:px-384 {
		padding-left: 38.4rem;
		padding-right: 38.4rem;
	}

	.print\:py-400 {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.print\:px-400 {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.print\:py-512 {
		padding-top: 51.2rem;
		padding-bottom: 51.2rem;
	}

	.print\:px-512 {
		padding-left: 51.2rem;
		padding-right: 51.2rem;
	}

	.print\:py-640 {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.print\:px-640 {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.print\:py-px {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.print\:px-px {
		padding-left: 1px;
		padding-right: 1px;
	}

	.print\:py-xs {
		padding-top: 32rem;
		padding-bottom: 32rem;
	}

	.print\:px-xs {
		padding-left: 32rem;
		padding-right: 32rem;
	}

	.print\:py-sm {
		padding-top: 48rem;
		padding-bottom: 48rem;
	}

	.print\:px-sm {
		padding-left: 48rem;
		padding-right: 48rem;
	}

	.print\:py-md {
		padding-top: 64rem;
		padding-bottom: 64rem;
	}

	.print\:px-md {
		padding-left: 64rem;
		padding-right: 64rem;
	}

	.print\:py-lg {
		padding-top: 80rem;
		padding-bottom: 80rem;
	}

	.print\:px-lg {
		padding-left: 80rem;
		padding-right: 80rem;
	}

	.print\:py-xl {
		padding-top: 96rem;
		padding-bottom: 96rem;
	}

	.print\:px-xl {
		padding-left: 96rem;
		padding-right: 96rem;
	}

	.print\:py-2xl {
		padding-top: 112rem;
		padding-bottom: 112rem;
	}

	.print\:px-2xl {
		padding-left: 112rem;
		padding-right: 112rem;
	}

	.print\:py-3xl {
		padding-top: 128rem;
		padding-bottom: 128rem;
	}

	.print\:px-3xl {
		padding-left: 128rem;
		padding-right: 128rem;
	}

	.print\:py-4xl {
		padding-top: 144rem;
		padding-bottom: 144rem;
	}

	.print\:px-4xl {
		padding-left: 144rem;
		padding-right: 144rem;
	}

	.print\:py-5xl {
		padding-top: 160rem;
		padding-bottom: 160rem;
	}

	.print\:px-5xl {
		padding-left: 160rem;
		padding-right: 160rem;
	}

	.print\:py-auto {
		padding-top: auto;
		padding-bottom: auto;
	}

	.print\:px-auto {
		padding-left: auto;
		padding-right: auto;
	}

	.print\:pt-0 {
		padding-top: 0;
	}

	.print\:pr-0 {
		padding-right: 0;
	}

	.print\:pb-0 {
		padding-bottom: 0;
	}

	.print\:pl-0 {
		padding-left: 0;
	}

	.print\:pt-1 {
		padding-top: 0.25rem;
	}

	.print\:pr-1 {
		padding-right: 0.25rem;
	}

	.print\:pb-1 {
		padding-bottom: 0.25rem;
	}

	.print\:pl-1 {
		padding-left: 0.25rem;
	}

	.print\:pt-2 {
		padding-top: 0.5rem;
	}

	.print\:pr-2 {
		padding-right: 0.5rem;
	}

	.print\:pb-2 {
		padding-bottom: 0.5rem;
	}

	.print\:pl-2 {
		padding-left: 0.5rem;
	}

	.print\:pt-3 {
		padding-top: 0.75rem;
	}

	.print\:pr-3 {
		padding-right: 0.75rem;
	}

	.print\:pb-3 {
		padding-bottom: 0.75rem;
	}

	.print\:pl-3 {
		padding-left: 0.75rem;
	}

	.print\:pt-4 {
		padding-top: 0.4rem;
	}

	.print\:pr-4 {
		padding-right: 0.4rem;
	}

	.print\:pb-4 {
		padding-bottom: 0.4rem;
	}

	.print\:pl-4 {
		padding-left: 0.4rem;
	}

	.print\:pt-5 {
		padding-top: 1.25rem;
	}

	.print\:pr-5 {
		padding-right: 1.25rem;
	}

	.print\:pb-5 {
		padding-bottom: 1.25rem;
	}

	.print\:pl-5 {
		padding-left: 1.25rem;
	}

	.print\:pt-6 {
		padding-top: 0.6rem;
	}

	.print\:pr-6 {
		padding-right: 0.6rem;
	}

	.print\:pb-6 {
		padding-bottom: 0.6rem;
	}

	.print\:pl-6 {
		padding-left: 0.6rem;
	}

	.print\:pt-8 {
		padding-top: 0.8rem;
	}

	.print\:pr-8 {
		padding-right: 0.8rem;
	}

	.print\:pb-8 {
		padding-bottom: 0.8rem;
	}

	.print\:pl-8 {
		padding-left: 0.8rem;
	}

	.print\:pt-10 {
		padding-top: 2.5rem;
	}

	.print\:pr-10 {
		padding-right: 2.5rem;
	}

	.print\:pb-10 {
		padding-bottom: 2.5rem;
	}

	.print\:pl-10 {
		padding-left: 2.5rem;
	}

	.print\:pt-12 {
		padding-top: 1.2rem;
	}

	.print\:pr-12 {
		padding-right: 1.2rem;
	}

	.print\:pb-12 {
		padding-bottom: 1.2rem;
	}

	.print\:pl-12 {
		padding-left: 1.2rem;
	}

	.print\:pt-16 {
		padding-top: 1.6rem;
	}

	.print\:pr-16 {
		padding-right: 1.6rem;
	}

	.print\:pb-16 {
		padding-bottom: 1.6rem;
	}

	.print\:pl-16 {
		padding-left: 1.6rem;
	}

	.print\:pt-20 {
		padding-top: 2rem;
	}

	.print\:pr-20 {
		padding-right: 2rem;
	}

	.print\:pb-20 {
		padding-bottom: 2rem;
	}

	.print\:pl-20 {
		padding-left: 2rem;
	}

	.print\:pt-24 {
		padding-top: 2.4rem;
	}

	.print\:pr-24 {
		padding-right: 2.4rem;
	}

	.print\:pb-24 {
		padding-bottom: 2.4rem;
	}

	.print\:pl-24 {
		padding-left: 2.4rem;
	}

	.print\:pt-28 {
		padding-top: 2.8rem;
	}

	.print\:pr-28 {
		padding-right: 2.8rem;
	}

	.print\:pb-28 {
		padding-bottom: 2.8rem;
	}

	.print\:pl-28 {
		padding-left: 2.8rem;
	}

	.print\:pt-32 {
		padding-top: 3.2rem;
	}

	.print\:pr-32 {
		padding-right: 3.2rem;
	}

	.print\:pb-32 {
		padding-bottom: 3.2rem;
	}

	.print\:pl-32 {
		padding-left: 3.2rem;
	}

	.print\:pt-36 {
		padding-top: 3.6rem;
	}

	.print\:pr-36 {
		padding-right: 3.6rem;
	}

	.print\:pb-36 {
		padding-bottom: 3.6rem;
	}

	.print\:pl-36 {
		padding-left: 3.6rem;
	}

	.print\:pt-40 {
		padding-top: 4rem;
	}

	.print\:pr-40 {
		padding-right: 4rem;
	}

	.print\:pb-40 {
		padding-bottom: 4rem;
	}

	.print\:pl-40 {
		padding-left: 4rem;
	}

	.print\:pt-44 {
		padding-top: 4.4rem;
	}

	.print\:pr-44 {
		padding-right: 4.4rem;
	}

	.print\:pb-44 {
		padding-bottom: 4.4rem;
	}

	.print\:pl-44 {
		padding-left: 4.4rem;
	}

	.print\:pt-48 {
		padding-top: 4.8rem;
	}

	.print\:pr-48 {
		padding-right: 4.8rem;
	}

	.print\:pb-48 {
		padding-bottom: 4.8rem;
	}

	.print\:pl-48 {
		padding-left: 4.8rem;
	}

	.print\:pt-52 {
		padding-top: 5.2rem;
	}

	.print\:pr-52 {
		padding-right: 5.2rem;
	}

	.print\:pb-52 {
		padding-bottom: 5.2rem;
	}

	.print\:pl-52 {
		padding-left: 5.2rem;
	}

	.print\:pt-56 {
		padding-top: 5.6rem;
	}

	.print\:pr-56 {
		padding-right: 5.6rem;
	}

	.print\:pb-56 {
		padding-bottom: 5.6rem;
	}

	.print\:pl-56 {
		padding-left: 5.6rem;
	}

	.print\:pt-60 {
		padding-top: 6rem;
	}

	.print\:pr-60 {
		padding-right: 6rem;
	}

	.print\:pb-60 {
		padding-bottom: 6rem;
	}

	.print\:pl-60 {
		padding-left: 6rem;
	}

	.print\:pt-64 {
		padding-top: 6.4rem;
	}

	.print\:pr-64 {
		padding-right: 6.4rem;
	}

	.print\:pb-64 {
		padding-bottom: 6.4rem;
	}

	.print\:pl-64 {
		padding-left: 6.4rem;
	}

	.print\:pt-68 {
		padding-top: 6.8rem;
	}

	.print\:pr-68 {
		padding-right: 6.8rem;
	}

	.print\:pb-68 {
		padding-bottom: 6.8rem;
	}

	.print\:pl-68 {
		padding-left: 6.8rem;
	}

	.print\:pt-72 {
		padding-top: 7.2rem;
	}

	.print\:pr-72 {
		padding-right: 7.2rem;
	}

	.print\:pb-72 {
		padding-bottom: 7.2rem;
	}

	.print\:pl-72 {
		padding-left: 7.2rem;
	}

	.print\:pt-76 {
		padding-top: 7.6rem;
	}

	.print\:pr-76 {
		padding-right: 7.6rem;
	}

	.print\:pb-76 {
		padding-bottom: 7.6rem;
	}

	.print\:pl-76 {
		padding-left: 7.6rem;
	}

	.print\:pt-80 {
		padding-top: 8rem;
	}

	.print\:pr-80 {
		padding-right: 8rem;
	}

	.print\:pb-80 {
		padding-bottom: 8rem;
	}

	.print\:pl-80 {
		padding-left: 8rem;
	}

	.print\:pt-84 {
		padding-top: 8.4rem;
	}

	.print\:pr-84 {
		padding-right: 8.4rem;
	}

	.print\:pb-84 {
		padding-bottom: 8.4rem;
	}

	.print\:pl-84 {
		padding-left: 8.4rem;
	}

	.print\:pt-88 {
		padding-top: 8.8rem;
	}

	.print\:pr-88 {
		padding-right: 8.8rem;
	}

	.print\:pb-88 {
		padding-bottom: 8.8rem;
	}

	.print\:pl-88 {
		padding-left: 8.8rem;
	}

	.print\:pt-92 {
		padding-top: 9.2rem;
	}

	.print\:pr-92 {
		padding-right: 9.2rem;
	}

	.print\:pb-92 {
		padding-bottom: 9.2rem;
	}

	.print\:pl-92 {
		padding-left: 9.2rem;
	}

	.print\:pt-96 {
		padding-top: 9.6rem;
	}

	.print\:pr-96 {
		padding-right: 9.6rem;
	}

	.print\:pb-96 {
		padding-bottom: 9.6rem;
	}

	.print\:pl-96 {
		padding-left: 9.6rem;
	}

	.print\:pt-128 {
		padding-top: 12.8rem;
	}

	.print\:pr-128 {
		padding-right: 12.8rem;
	}

	.print\:pb-128 {
		padding-bottom: 12.8rem;
	}

	.print\:pl-128 {
		padding-left: 12.8rem;
	}

	.print\:pt-136 {
		padding-top: 13.6rem;
	}

	.print\:pr-136 {
		padding-right: 13.6rem;
	}

	.print\:pb-136 {
		padding-bottom: 13.6rem;
	}

	.print\:pl-136 {
		padding-left: 13.6rem;
	}

	.print\:pt-160 {
		padding-top: 16rem;
	}

	.print\:pr-160 {
		padding-right: 16rem;
	}

	.print\:pb-160 {
		padding-bottom: 16rem;
	}

	.print\:pl-160 {
		padding-left: 16rem;
	}

	.print\:pt-192 {
		padding-top: 19.2rem;
	}

	.print\:pr-192 {
		padding-right: 19.2rem;
	}

	.print\:pb-192 {
		padding-bottom: 19.2rem;
	}

	.print\:pl-192 {
		padding-left: 19.2rem;
	}

	.print\:pt-200 {
		padding-top: 20rem;
	}

	.print\:pr-200 {
		padding-right: 20rem;
	}

	.print\:pb-200 {
		padding-bottom: 20rem;
	}

	.print\:pl-200 {
		padding-left: 20rem;
	}

	.print\:pt-208 {
		padding-top: 20.8rem;
	}

	.print\:pr-208 {
		padding-right: 20.8rem;
	}

	.print\:pb-208 {
		padding-bottom: 20.8rem;
	}

	.print\:pl-208 {
		padding-left: 20.8rem;
	}

	.print\:pt-216 {
		padding-top: 21.6rem;
	}

	.print\:pr-216 {
		padding-right: 21.6rem;
	}

	.print\:pb-216 {
		padding-bottom: 21.6rem;
	}

	.print\:pl-216 {
		padding-left: 21.6rem;
	}

	.print\:pt-224 {
		padding-top: 22.4rem;
	}

	.print\:pr-224 {
		padding-right: 22.4rem;
	}

	.print\:pb-224 {
		padding-bottom: 22.4rem;
	}

	.print\:pl-224 {
		padding-left: 22.4rem;
	}

	.print\:pt-256 {
		padding-top: 25.6rem;
	}

	.print\:pr-256 {
		padding-right: 25.6rem;
	}

	.print\:pb-256 {
		padding-bottom: 25.6rem;
	}

	.print\:pl-256 {
		padding-left: 25.6rem;
	}

	.print\:pt-288 {
		padding-top: 28.8rem;
	}

	.print\:pr-288 {
		padding-right: 28.8rem;
	}

	.print\:pb-288 {
		padding-bottom: 28.8rem;
	}

	.print\:pl-288 {
		padding-left: 28.8rem;
	}

	.print\:pt-320 {
		padding-top: 32rem;
	}

	.print\:pr-320 {
		padding-right: 32rem;
	}

	.print\:pb-320 {
		padding-bottom: 32rem;
	}

	.print\:pl-320 {
		padding-left: 32rem;
	}

	.print\:pt-360 {
		padding-top: 36rem;
	}

	.print\:pr-360 {
		padding-right: 36rem;
	}

	.print\:pb-360 {
		padding-bottom: 36rem;
	}

	.print\:pl-360 {
		padding-left: 36rem;
	}

	.print\:pt-384 {
		padding-top: 38.4rem;
	}

	.print\:pr-384 {
		padding-right: 38.4rem;
	}

	.print\:pb-384 {
		padding-bottom: 38.4rem;
	}

	.print\:pl-384 {
		padding-left: 38.4rem;
	}

	.print\:pt-400 {
		padding-top: 40rem;
	}

	.print\:pr-400 {
		padding-right: 40rem;
	}

	.print\:pb-400 {
		padding-bottom: 40rem;
	}

	.print\:pl-400 {
		padding-left: 40rem;
	}

	.print\:pt-512 {
		padding-top: 51.2rem;
	}

	.print\:pr-512 {
		padding-right: 51.2rem;
	}

	.print\:pb-512 {
		padding-bottom: 51.2rem;
	}

	.print\:pl-512 {
		padding-left: 51.2rem;
	}

	.print\:pt-640 {
		padding-top: 64rem;
	}

	.print\:pr-640 {
		padding-right: 64rem;
	}

	.print\:pb-640 {
		padding-bottom: 64rem;
	}

	.print\:pl-640 {
		padding-left: 64rem;
	}

	.print\:pt-px {
		padding-top: 1px;
	}

	.print\:pr-px {
		padding-right: 1px;
	}

	.print\:pb-px {
		padding-bottom: 1px;
	}

	.print\:pl-px {
		padding-left: 1px;
	}

	.print\:pt-xs {
		padding-top: 32rem;
	}

	.print\:pr-xs {
		padding-right: 32rem;
	}

	.print\:pb-xs {
		padding-bottom: 32rem;
	}

	.print\:pl-xs {
		padding-left: 32rem;
	}

	.print\:pt-sm {
		padding-top: 48rem;
	}

	.print\:pr-sm {
		padding-right: 48rem;
	}

	.print\:pb-sm {
		padding-bottom: 48rem;
	}

	.print\:pl-sm {
		padding-left: 48rem;
	}

	.print\:pt-md {
		padding-top: 64rem;
	}

	.print\:pr-md {
		padding-right: 64rem;
	}

	.print\:pb-md {
		padding-bottom: 64rem;
	}

	.print\:pl-md {
		padding-left: 64rem;
	}

	.print\:pt-lg {
		padding-top: 80rem;
	}

	.print\:pr-lg {
		padding-right: 80rem;
	}

	.print\:pb-lg {
		padding-bottom: 80rem;
	}

	.print\:pl-lg {
		padding-left: 80rem;
	}

	.print\:pt-xl {
		padding-top: 96rem;
	}

	.print\:pr-xl {
		padding-right: 96rem;
	}

	.print\:pb-xl {
		padding-bottom: 96rem;
	}

	.print\:pl-xl {
		padding-left: 96rem;
	}

	.print\:pt-2xl {
		padding-top: 112rem;
	}

	.print\:pr-2xl {
		padding-right: 112rem;
	}

	.print\:pb-2xl {
		padding-bottom: 112rem;
	}

	.print\:pl-2xl {
		padding-left: 112rem;
	}

	.print\:pt-3xl {
		padding-top: 128rem;
	}

	.print\:pr-3xl {
		padding-right: 128rem;
	}

	.print\:pb-3xl {
		padding-bottom: 128rem;
	}

	.print\:pl-3xl {
		padding-left: 128rem;
	}

	.print\:pt-4xl {
		padding-top: 144rem;
	}

	.print\:pr-4xl {
		padding-right: 144rem;
	}

	.print\:pb-4xl {
		padding-bottom: 144rem;
	}

	.print\:pl-4xl {
		padding-left: 144rem;
	}

	.print\:pt-5xl {
		padding-top: 160rem;
	}

	.print\:pr-5xl {
		padding-right: 160rem;
	}

	.print\:pb-5xl {
		padding-bottom: 160rem;
	}

	.print\:pl-5xl {
		padding-left: 160rem;
	}

	.print\:pt-auto {
		padding-top: auto;
	}

	.print\:pr-auto {
		padding-right: auto;
	}

	.print\:pb-auto {
		padding-bottom: auto;
	}

	.print\:pl-auto {
		padding-left: auto;
	}

	.print\:pointer-events-none {
		pointer-events: none;
	}

	.print\:pointer-events-auto {
		pointer-events: auto;
	}

	.print\:static {
		position: static;
	}

	.print\:fixed {
		position: fixed;
	}

	.print\:absolute {
		position: absolute;
	}

	.print\:relative {
		position: relative;
	}

	.print\:sticky {
		position: -webkit-sticky;
		position: sticky;
	}

	.print\:inset-0 {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.print\:inset-auto {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}

	.print\:inset-y-0 {
		top: 0;
		bottom: 0;
	}

	.print\:inset-x-0 {
		right: 0;
		left: 0;
	}

	.print\:inset-y-auto {
		top: auto;
		bottom: auto;
	}

	.print\:inset-x-auto {
		right: auto;
		left: auto;
	}

	.print\:top-0 {
		top: 0;
	}

	.print\:right-0 {
		right: 0;
	}

	.print\:bottom-0 {
		bottom: 0;
	}

	.print\:left-0 {
		left: 0;
	}

	.print\:top-auto {
		top: auto;
	}

	.print\:right-auto {
		right: auto;
	}

	.print\:bottom-auto {
		bottom: auto;
	}

	.print\:left-auto {
		left: auto;
	}

	.print\:resize-none {
		resize: none;
	}

	.print\:resize-y {
		resize: vertical;
	}

	.print\:resize-x {
		resize: horizontal;
	}

	.print\:resize {
		resize: both;
	}

	.print\:shadow-0 {
		box-shadow: none;
	}

	.print\:shadow-1 {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-2 {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-3 {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-4 {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-5 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-6 {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-7 {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-8 {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-9 {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-10 {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-11 {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-12 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-13 {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-14 {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-15 {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-16 {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-17 {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-18 {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-19 {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-20 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-21 {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-22 {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-23 {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow-24 {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.print\:shadow-md {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:shadow-lg {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:shadow-xl {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.print\:shadow-2xl {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.print\:shadow-inner {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.print\:shadow-outline {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.print\:shadow-none {
		box-shadow: none;
	}

	.print\:hover\:shadow-0:hover {
		box-shadow: none;
	}

	.print\:hover\:shadow-1:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-2:hover {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-3:hover {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-4:hover {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-5:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-6:hover {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-7:hover {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-8:hover {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-9:hover {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-10:hover {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-11:hover {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-12:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-13:hover {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-14:hover {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-15:hover {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-16:hover {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-17:hover {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-18:hover {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-19:hover {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-20:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-21:hover {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-22:hover {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-23:hover {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow-24:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:hover\:shadow:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.print\:hover\:shadow-md:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:hover\:shadow-lg:hover {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:hover\:shadow-xl:hover {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.print\:hover\:shadow-2xl:hover {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.print\:hover\:shadow-inner:hover {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.print\:hover\:shadow-outline:hover {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.print\:hover\:shadow-none:hover {
		box-shadow: none;
	}

	.print\:focus\:shadow-0:focus {
		box-shadow: none;
	}

	.print\:focus\:shadow-1:focus {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-2:focus {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-3:focus {
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
			0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-4:focus {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-5:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-6:focus {
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-7:focus {
		box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
			0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-8:focus {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-9:focus {
		box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
			0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-10:focus {
		box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
			0px 10px 14px 1px rgba(0, 0, 0, 0.14),
			0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-11:focus {
		box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
			0px 11px 15px 1px rgba(0, 0, 0, 0.14),
			0px 4px 20px 3px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-12:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 12px 17px 2px rgba(0, 0, 0, 0.14),
			0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-13:focus {
		box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
			0px 13px 19px 2px rgba(0, 0, 0, 0.14),
			0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-14:focus {
		box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
			0px 14px 21px 2px rgba(0, 0, 0, 0.14),
			0px 5px 26px 4px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-15:focus {
		box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
			0px 15px 22px 2px rgba(0, 0, 0, 0.14),
			0px 6px 28px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-16:focus {
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
			0px 16px 24px 2px rgba(0, 0, 0, 0.14),
			0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-17:focus {
		box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
			0px 17px 26px 2px rgba(0, 0, 0, 0.14),
			0px 6px 32px 5px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-18:focus {
		box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
			0px 18px 28px 2px rgba(0, 0, 0, 0.14),
			0px 7px 34px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-19:focus {
		box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
			0px 19px 29px 2px rgba(0, 0, 0, 0.14),
			0px 7px 36px 6px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-20:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 20px 31px 3px rgba(0, 0, 0, 0.14),
			0px 8px 38px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-21:focus {
		box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
			0px 21px 33px 3px rgba(0, 0, 0, 0.14),
			0px 8px 40px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-22:focus {
		box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
			0px 22px 35px 3px rgba(0, 0, 0, 0.14),
			0px 8px 42px 7px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-23:focus {
		box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
			0px 23px 36px 3px rgba(0, 0, 0, 0.14),
			0px 9px 44px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow-24:focus {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}

	.print\:focus\:shadow:focus {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.print\:focus\:shadow-md:focus {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:focus\:shadow-lg:focus {
		box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
			0 5px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.print\:focus\:shadow-xl:focus {
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	.print\:focus\:shadow-2xl:focus {
		box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	}

	.print\:focus\:shadow-inner:focus {
		box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	}

	.print\:focus\:shadow-outline:focus {
		box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	}

	.print\:focus\:shadow-none:focus {
		box-shadow: none;
	}

	.print\:fill-current {
		fill: currentColor;
	}

	.print\:stroke-current {
		stroke: currentColor;
	}

	.print\:table-auto {
		table-layout: auto;
	}

	.print\:table-fixed {
		table-layout: fixed;
	}

	.print\:text-left {
		text-align: left;
	}

	.print\:text-center {
		text-align: center;
	}

	.print\:text-right {
		text-align: right;
	}

	.print\:text-justify {
		text-align: justify;
	}

	.print\:text-transparent {
		color: transparent;
	}

	.print\:text-black {
		color: #22292f;
	}

	.print\:text-white {
		color: #ffffff;
	}

	.print\:text-gray-100 {
		color: #f7fafc;
	}

	.print\:text-gray-200 {
		color: #edf2f7;
	}

	.print\:text-gray-300 {
		color: #e2e8f0;
	}

	.print\:text-gray-400 {
		color: #cbd5e0;
	}

	.print\:text-gray-500 {
		color: #a0aec0;
	}

	.print\:text-gray-600 {
		color: #718096;
	}

	.print\:text-gray-700 {
		color: #4a5568;
	}

	.print\:text-gray-800 {
		color: #2d3748;
	}

	.print\:text-gray-900 {
		color: #1a202c;
	}

	.print\:text-red {
		color: #e3342f;
	}

	.print\:text-orange {
		color: #f6993f;
	}

	.print\:text-yellow {
		color: #ffed4a;
	}

	.print\:text-green {
		color: #38c172;
	}

	.print\:text-teal {
		color: #4dc0b5;
	}

	.print\:text-blue {
		color: #3490dc;
	}

	.print\:text-indigo {
		color: #6574cd;
	}

	.print\:text-purple {
		color: #9561e2;
	}

	.print\:text-pink {
		color: #f66d9b;
	}

	.print\:text-grey-darkest {
		color: #3d4852;
	}

	.print\:text-grey-darker {
		color: #606f7b;
	}

	.print\:text-grey-dark {
		color: #8795a1;
	}

	.print\:text-grey {
		color: #b8c2cc;
	}

	.print\:text-grey-light {
		color: #dae1e7;
	}

	.print\:text-grey-lighter {
		color: #f1f5f8;
	}

	.print\:text-grey-lightest {
		color: #f8fafc;
	}

	.print\:text-red-darkest {
		color: #3b0d0c;
	}

	.print\:text-red-darker {
		color: #621b18;
	}

	.print\:text-red-dark {
		color: #cc1f1a;
	}

	.print\:text-red-light {
		color: #ef5753;
	}

	.print\:text-red-lighter {
		color: #f9acaa;
	}

	.print\:text-red-lightest {
		color: #fcebea;
	}

	.print\:text-orange-darkest {
		color: #462a16;
	}

	.print\:text-orange-darker {
		color: #613b1f;
	}

	.print\:text-orange-dark {
		color: #de751f;
	}

	.print\:text-orange-light {
		color: #faad63;
	}

	.print\:text-orange-lighter {
		color: #fcd9b6;
	}

	.print\:text-orange-lightest {
		color: #fff5eb;
	}

	.print\:text-yellow-darkest {
		color: #453411;
	}

	.print\:text-yellow-darker {
		color: #684f1d;
	}

	.print\:text-yellow-dark {
		color: #f2d024;
	}

	.print\:text-yellow-light {
		color: #fff382;
	}

	.print\:text-yellow-lighter {
		color: #fff9c2;
	}

	.print\:text-yellow-lightest {
		color: #fcfbeb;
	}

	.print\:text-green-darkest {
		color: #0f2f21;
	}

	.print\:text-green-darker {
		color: #1a4731;
	}

	.print\:text-green-dark {
		color: #1f9d55;
	}

	.print\:text-green-light {
		color: #51d88a;
	}

	.print\:text-green-lighter {
		color: #a2f5bf;
	}

	.print\:text-green-lightest {
		color: #e3fcec;
	}

	.print\:text-teal-darkest {
		color: #0d3331;
	}

	.print\:text-teal-darker {
		color: #20504f;
	}

	.print\:text-teal-dark {
		color: #38a89d;
	}

	.print\:text-teal-light {
		color: #64d5ca;
	}

	.print\:text-teal-lighter {
		color: #a0f0ed;
	}

	.print\:text-teal-lightest {
		color: #e8fffe;
	}

	.print\:text-blue-darkest {
		color: #12283a;
	}

	.print\:text-blue-darker {
		color: #1c3d5a;
	}

	.print\:text-blue-dark {
		color: #2779bd;
	}

	.print\:text-blue-light {
		color: #6cb2eb;
	}

	.print\:text-blue-lighter {
		color: #bcdefa;
	}

	.print\:text-blue-lightest {
		color: #eff8ff;
	}

	.print\:text-indigo-darkest {
		color: #191e38;
	}

	.print\:text-indigo-darker {
		color: #2f365f;
	}

	.print\:text-indigo-dark {
		color: #5661b3;
	}

	.print\:text-indigo-light {
		color: #7886d7;
	}

	.print\:text-indigo-lighter {
		color: #b2b7ff;
	}

	.print\:text-indigo-lightest {
		color: #e6e8ff;
	}

	.print\:text-purple-darkest {
		color: #21183c;
	}

	.print\:text-purple-darker {
		color: #382b5f;
	}

	.print\:text-purple-dark {
		color: #794acf;
	}

	.print\:text-purple-light {
		color: #a779e9;
	}

	.print\:text-purple-lighter {
		color: #d6bbfc;
	}

	.print\:text-purple-lightest {
		color: #f3ebff;
	}

	.print\:text-pink-darkest {
		color: #451225;
	}

	.print\:text-pink-darker {
		color: #6f213f;
	}

	.print\:text-pink-dark {
		color: #eb5286;
	}

	.print\:text-pink-light {
		color: #fa7ea8;
	}

	.print\:text-pink-lighter {
		color: #ffbbca;
	}

	.print\:text-pink-lightest {
		color: #ffebef;
	}

	.print\:hover\:text-transparent:hover {
		color: transparent;
	}

	.print\:hover\:text-black:hover {
		color: #22292f;
	}

	.print\:hover\:text-white:hover {
		color: #ffffff;
	}

	.print\:hover\:text-gray-100:hover {
		color: #f7fafc;
	}

	.print\:hover\:text-gray-200:hover {
		color: #edf2f7;
	}

	.print\:hover\:text-gray-300:hover {
		color: #e2e8f0;
	}

	.print\:hover\:text-gray-400:hover {
		color: #cbd5e0;
	}

	.print\:hover\:text-gray-500:hover {
		color: #a0aec0;
	}

	.print\:hover\:text-gray-600:hover {
		color: #718096;
	}

	.print\:hover\:text-gray-700:hover {
		color: #4a5568;
	}

	.print\:hover\:text-gray-800:hover {
		color: #2d3748;
	}

	.print\:hover\:text-gray-900:hover {
		color: #1a202c;
	}

	.print\:hover\:text-red:hover {
		color: #e3342f;
	}

	.print\:hover\:text-orange:hover {
		color: #f6993f;
	}

	.print\:hover\:text-yellow:hover {
		color: #ffed4a;
	}

	.print\:hover\:text-green:hover {
		color: #38c172;
	}

	.print\:hover\:text-teal:hover {
		color: #4dc0b5;
	}

	.print\:hover\:text-blue:hover {
		color: #3490dc;
	}

	.print\:hover\:text-indigo:hover {
		color: #6574cd;
	}

	.print\:hover\:text-purple:hover {
		color: #9561e2;
	}

	.print\:hover\:text-pink:hover {
		color: #f66d9b;
	}

	.print\:hover\:text-grey-darkest:hover {
		color: #3d4852;
	}

	.print\:hover\:text-grey-darker:hover {
		color: #606f7b;
	}

	.print\:hover\:text-grey-dark:hover {
		color: #8795a1;
	}

	.print\:hover\:text-grey:hover {
		color: #b8c2cc;
	}

	.print\:hover\:text-grey-light:hover {
		color: #dae1e7;
	}

	.print\:hover\:text-grey-lighter:hover {
		color: #f1f5f8;
	}

	.print\:hover\:text-grey-lightest:hover {
		color: #f8fafc;
	}

	.print\:hover\:text-red-darkest:hover {
		color: #3b0d0c;
	}

	.print\:hover\:text-red-darker:hover {
		color: #621b18;
	}

	.print\:hover\:text-red-dark:hover {
		color: #cc1f1a;
	}

	.print\:hover\:text-red-light:hover {
		color: #ef5753;
	}

	.print\:hover\:text-red-lighter:hover {
		color: #f9acaa;
	}

	.print\:hover\:text-red-lightest:hover {
		color: #fcebea;
	}

	.print\:hover\:text-orange-darkest:hover {
		color: #462a16;
	}

	.print\:hover\:text-orange-darker:hover {
		color: #613b1f;
	}

	.print\:hover\:text-orange-dark:hover {
		color: #de751f;
	}

	.print\:hover\:text-orange-light:hover {
		color: #faad63;
	}

	.print\:hover\:text-orange-lighter:hover {
		color: #fcd9b6;
	}

	.print\:hover\:text-orange-lightest:hover {
		color: #fff5eb;
	}

	.print\:hover\:text-yellow-darkest:hover {
		color: #453411;
	}

	.print\:hover\:text-yellow-darker:hover {
		color: #684f1d;
	}

	.print\:hover\:text-yellow-dark:hover {
		color: #f2d024;
	}

	.print\:hover\:text-yellow-light:hover {
		color: #fff382;
	}

	.print\:hover\:text-yellow-lighter:hover {
		color: #fff9c2;
	}

	.print\:hover\:text-yellow-lightest:hover {
		color: #fcfbeb;
	}

	.print\:hover\:text-green-darkest:hover {
		color: #0f2f21;
	}

	.print\:hover\:text-green-darker:hover {
		color: #1a4731;
	}

	.print\:hover\:text-green-dark:hover {
		color: #1f9d55;
	}

	.print\:hover\:text-green-light:hover {
		color: #51d88a;
	}

	.print\:hover\:text-green-lighter:hover {
		color: #a2f5bf;
	}

	.print\:hover\:text-green-lightest:hover {
		color: #e3fcec;
	}

	.print\:hover\:text-teal-darkest:hover {
		color: #0d3331;
	}

	.print\:hover\:text-teal-darker:hover {
		color: #20504f;
	}

	.print\:hover\:text-teal-dark:hover {
		color: #38a89d;
	}

	.print\:hover\:text-teal-light:hover {
		color: #64d5ca;
	}

	.print\:hover\:text-teal-lighter:hover {
		color: #a0f0ed;
	}

	.print\:hover\:text-teal-lightest:hover {
		color: #e8fffe;
	}

	.print\:hover\:text-blue-darkest:hover {
		color: #12283a;
	}

	.print\:hover\:text-blue-darker:hover {
		color: #1c3d5a;
	}

	.print\:hover\:text-blue-dark:hover {
		color: #2779bd;
	}

	.print\:hover\:text-blue-light:hover {
		color: #6cb2eb;
	}

	.print\:hover\:text-blue-lighter:hover {
		color: #bcdefa;
	}

	.print\:hover\:text-blue-lightest:hover {
		color: #eff8ff;
	}

	.print\:hover\:text-indigo-darkest:hover {
		color: #191e38;
	}

	.print\:hover\:text-indigo-darker:hover {
		color: #2f365f;
	}

	.print\:hover\:text-indigo-dark:hover {
		color: #5661b3;
	}

	.print\:hover\:text-indigo-light:hover {
		color: #7886d7;
	}

	.print\:hover\:text-indigo-lighter:hover {
		color: #b2b7ff;
	}

	.print\:hover\:text-indigo-lightest:hover {
		color: #e6e8ff;
	}

	.print\:hover\:text-purple-darkest:hover {
		color: #21183c;
	}

	.print\:hover\:text-purple-darker:hover {
		color: #382b5f;
	}

	.print\:hover\:text-purple-dark:hover {
		color: #794acf;
	}

	.print\:hover\:text-purple-light:hover {
		color: #a779e9;
	}

	.print\:hover\:text-purple-lighter:hover {
		color: #d6bbfc;
	}

	.print\:hover\:text-purple-lightest:hover {
		color: #f3ebff;
	}

	.print\:hover\:text-pink-darkest:hover {
		color: #451225;
	}

	.print\:hover\:text-pink-darker:hover {
		color: #6f213f;
	}

	.print\:hover\:text-pink-dark:hover {
		color: #eb5286;
	}

	.print\:hover\:text-pink-light:hover {
		color: #fa7ea8;
	}

	.print\:hover\:text-pink-lighter:hover {
		color: #ffbbca;
	}

	.print\:hover\:text-pink-lightest:hover {
		color: #ffebef;
	}

	.print\:focus\:text-transparent:focus {
		color: transparent;
	}

	.print\:focus\:text-black:focus {
		color: #22292f;
	}

	.print\:focus\:text-white:focus {
		color: #ffffff;
	}

	.print\:focus\:text-gray-100:focus {
		color: #f7fafc;
	}

	.print\:focus\:text-gray-200:focus {
		color: #edf2f7;
	}

	.print\:focus\:text-gray-300:focus {
		color: #e2e8f0;
	}

	.print\:focus\:text-gray-400:focus {
		color: #cbd5e0;
	}

	.print\:focus\:text-gray-500:focus {
		color: #a0aec0;
	}

	.print\:focus\:text-gray-600:focus {
		color: #718096;
	}

	.print\:focus\:text-gray-700:focus {
		color: #4a5568;
	}

	.print\:focus\:text-gray-800:focus {
		color: #2d3748;
	}

	.print\:focus\:text-gray-900:focus {
		color: #1a202c;
	}

	.print\:focus\:text-red:focus {
		color: #e3342f;
	}

	.print\:focus\:text-orange:focus {
		color: #f6993f;
	}

	.print\:focus\:text-yellow:focus {
		color: #ffed4a;
	}

	.print\:focus\:text-green:focus {
		color: #38c172;
	}

	.print\:focus\:text-teal:focus {
		color: #4dc0b5;
	}

	.print\:focus\:text-blue:focus {
		color: #3490dc;
	}

	.print\:focus\:text-indigo:focus {
		color: #6574cd;
	}

	.print\:focus\:text-purple:focus {
		color: #9561e2;
	}

	.print\:focus\:text-pink:focus {
		color: #f66d9b;
	}

	.print\:focus\:text-grey-darkest:focus {
		color: #3d4852;
	}

	.print\:focus\:text-grey-darker:focus {
		color: #606f7b;
	}

	.print\:focus\:text-grey-dark:focus {
		color: #8795a1;
	}

	.print\:focus\:text-grey:focus {
		color: #b8c2cc;
	}

	.print\:focus\:text-grey-light:focus {
		color: #dae1e7;
	}

	.print\:focus\:text-grey-lighter:focus {
		color: #f1f5f8;
	}

	.print\:focus\:text-grey-lightest:focus {
		color: #f8fafc;
	}

	.print\:focus\:text-red-darkest:focus {
		color: #3b0d0c;
	}

	.print\:focus\:text-red-darker:focus {
		color: #621b18;
	}

	.print\:focus\:text-red-dark:focus {
		color: #cc1f1a;
	}

	.print\:focus\:text-red-light:focus {
		color: #ef5753;
	}

	.print\:focus\:text-red-lighter:focus {
		color: #f9acaa;
	}

	.print\:focus\:text-red-lightest:focus {
		color: #fcebea;
	}

	.print\:focus\:text-orange-darkest:focus {
		color: #462a16;
	}

	.print\:focus\:text-orange-darker:focus {
		color: #613b1f;
	}

	.print\:focus\:text-orange-dark:focus {
		color: #de751f;
	}

	.print\:focus\:text-orange-light:focus {
		color: #faad63;
	}

	.print\:focus\:text-orange-lighter:focus {
		color: #fcd9b6;
	}

	.print\:focus\:text-orange-lightest:focus {
		color: #fff5eb;
	}

	.print\:focus\:text-yellow-darkest:focus {
		color: #453411;
	}

	.print\:focus\:text-yellow-darker:focus {
		color: #684f1d;
	}

	.print\:focus\:text-yellow-dark:focus {
		color: #f2d024;
	}

	.print\:focus\:text-yellow-light:focus {
		color: #fff382;
	}

	.print\:focus\:text-yellow-lighter:focus {
		color: #fff9c2;
	}

	.print\:focus\:text-yellow-lightest:focus {
		color: #fcfbeb;
	}

	.print\:focus\:text-green-darkest:focus {
		color: #0f2f21;
	}

	.print\:focus\:text-green-darker:focus {
		color: #1a4731;
	}

	.print\:focus\:text-green-dark:focus {
		color: #1f9d55;
	}

	.print\:focus\:text-green-light:focus {
		color: #51d88a;
	}

	.print\:focus\:text-green-lighter:focus {
		color: #a2f5bf;
	}

	.print\:focus\:text-green-lightest:focus {
		color: #e3fcec;
	}

	.print\:focus\:text-teal-darkest:focus {
		color: #0d3331;
	}

	.print\:focus\:text-teal-darker:focus {
		color: #20504f;
	}

	.print\:focus\:text-teal-dark:focus {
		color: #38a89d;
	}

	.print\:focus\:text-teal-light:focus {
		color: #64d5ca;
	}

	.print\:focus\:text-teal-lighter:focus {
		color: #a0f0ed;
	}

	.print\:focus\:text-teal-lightest:focus {
		color: #e8fffe;
	}

	.print\:focus\:text-blue-darkest:focus {
		color: #12283a;
	}

	.print\:focus\:text-blue-darker:focus {
		color: #1c3d5a;
	}

	.print\:focus\:text-blue-dark:focus {
		color: #2779bd;
	}

	.print\:focus\:text-blue-light:focus {
		color: #6cb2eb;
	}

	.print\:focus\:text-blue-lighter:focus {
		color: #bcdefa;
	}

	.print\:focus\:text-blue-lightest:focus {
		color: #eff8ff;
	}

	.print\:focus\:text-indigo-darkest:focus {
		color: #191e38;
	}

	.print\:focus\:text-indigo-darker:focus {
		color: #2f365f;
	}

	.print\:focus\:text-indigo-dark:focus {
		color: #5661b3;
	}

	.print\:focus\:text-indigo-light:focus {
		color: #7886d7;
	}

	.print\:focus\:text-indigo-lighter:focus {
		color: #b2b7ff;
	}

	.print\:focus\:text-indigo-lightest:focus {
		color: #e6e8ff;
	}

	.print\:focus\:text-purple-darkest:focus {
		color: #21183c;
	}

	.print\:focus\:text-purple-darker:focus {
		color: #382b5f;
	}

	.print\:focus\:text-purple-dark:focus {
		color: #794acf;
	}

	.print\:focus\:text-purple-light:focus {
		color: #a779e9;
	}

	.print\:focus\:text-purple-lighter:focus {
		color: #d6bbfc;
	}

	.print\:focus\:text-purple-lightest:focus {
		color: #f3ebff;
	}

	.print\:focus\:text-pink-darkest:focus {
		color: #451225;
	}

	.print\:focus\:text-pink-darker:focus {
		color: #6f213f;
	}

	.print\:focus\:text-pink-dark:focus {
		color: #eb5286;
	}

	.print\:focus\:text-pink-light:focus {
		color: #fa7ea8;
	}

	.print\:focus\:text-pink-lighter:focus {
		color: #ffbbca;
	}

	.print\:focus\:text-pink-lightest:focus {
		color: #ffebef;
	}

	.print\:text-10 {
		font-size: 1rem;
	}

	.print\:text-11 {
		font-size: 1.1rem;
	}

	.print\:text-12 {
		font-size: 1.2rem;
	}

	.print\:text-13 {
		font-size: 1.3rem;
	}

	.print\:text-14 {
		font-size: 1.4rem;
	}

	.print\:text-15 {
		font-size: 1.5rem;
	}

	.print\:text-16 {
		font-size: 1.6rem;
	}

	.print\:text-17 {
		font-size: 1.7rem;
	}

	.print\:text-18 {
		font-size: 1.8rem;
	}

	.print\:text-19 {
		font-size: 1.9rem;
	}

	.print\:text-20 {
		font-size: 2rem;
	}

	.print\:text-24 {
		font-size: 2.4rem;
	}

	.print\:text-28 {
		font-size: 2.8rem;
	}

	.print\:text-32 {
		font-size: 3.2rem;
	}

	.print\:text-36 {
		font-size: 3.6rem;
	}

	.print\:text-40 {
		font-size: 4rem;
	}

	.print\:text-44 {
		font-size: 4.4rem;
	}

	.print\:text-48 {
		font-size: 4.8rem;
	}

	.print\:text-52 {
		font-size: 5.2rem;
	}

	.print\:text-56 {
		font-size: 5.6rem;
	}

	.print\:text-60 {
		font-size: 6rem;
	}

	.print\:text-64 {
		font-size: 6.4rem;
	}

	.print\:text-68 {
		font-size: 6.8rem;
	}

	.print\:text-72 {
		font-size: 7.2rem;
	}

	.print\:text-96 {
		font-size: 9.6rem;
	}

	.print\:text-128 {
		font-size: 12.8rem;
	}

	.print\:text-xs {
		font-size: 1.2rem;
	}

	.print\:text-sm {
		font-size: 2.4rem;
	}

	.print\:text-base {
		font-size: 1.6rem;
	}

	.print\:text-lg {
		font-size: 1.8rem;
	}

	.print\:text-xl {
		font-size: 2rem;
	}

	.print\:text-2xl {
		font-size: 2.4rem;
	}

	.print\:text-3xl {
		font-size: 3rem;
	}

	.print\:text-4xl {
		font-size: 3.6rem;
	}

	.print\:text-5xl {
		font-size: 4.8rem;
	}

	.print\:text-6xl {
		font-size: 4rem;
	}

	.print\:italic {
		font-style: italic;
	}

	.print\:not-italic {
		font-style: normal;
	}

	.print\:hover\:italic:hover {
		font-style: italic;
	}

	.print\:hover\:not-italic:hover {
		font-style: normal;
	}

	.print\:focus\:italic:focus {
		font-style: italic;
	}

	.print\:focus\:not-italic:focus {
		font-style: normal;
	}

	.print\:uppercase {
		text-transform: uppercase;
	}

	.print\:lowercase {
		text-transform: lowercase;
	}

	.print\:capitalize {
		text-transform: capitalize;
	}

	.print\:normal-case {
		text-transform: none;
	}

	.print\:underline {
		text-decoration: underline;
	}

	.print\:line-through {
		text-decoration: line-through;
	}

	.print\:no-underline {
		text-decoration: none;
	}

	.print\:hover\:underline:hover {
		text-decoration: underline;
	}

	.print\:hover\:line-through:hover {
		text-decoration: line-through;
	}

	.print\:hover\:no-underline:hover {
		text-decoration: none;
	}

	.print\:focus\:underline:focus {
		text-decoration: underline;
	}

	.print\:focus\:line-through:focus {
		text-decoration: line-through;
	}

	.print\:focus\:no-underline:focus {
		text-decoration: none;
	}

	.print\:antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.print\:subpixel-antialiased {
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;
	}

	.print\:tracking-tighter {
		letter-spacing: -0.05em;
	}

	.print\:tracking-tight {
		letter-spacing: -0.025em;
	}

	.print\:tracking-normal {
		letter-spacing: 0;
	}

	.print\:tracking-wide {
		letter-spacing: 0.025em;
	}

	.print\:tracking-wider {
		letter-spacing: 0.05em;
	}

	.print\:tracking-widest {
		letter-spacing: 0.1em;
	}

	.print\:select-none {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.print\:select-text {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.print\:select-all {
		-webkit-user-select: all;
		-moz-user-select: all;
		-ms-user-select: all;
		user-select: all;
	}

	.print\:select-auto {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.print\:align-baseline {
		vertical-align: baseline;
	}

	.print\:align-top {
		vertical-align: top;
	}

	.print\:align-middle {
		vertical-align: middle;
	}

	.print\:align-bottom {
		vertical-align: bottom;
	}

	.print\:align-text-top {
		vertical-align: text-top;
	}

	.print\:align-text-bottom {
		vertical-align: text-bottom;
	}

	.print\:visible {
		visibility: visible;
	}

	.print\:invisible {
		visibility: hidden;
	}

	.print\:whitespace-normal {
		white-space: normal;
	}

	.print\:whitespace-no-wrap {
		white-space: nowrap;
	}

	.print\:whitespace-pre {
		white-space: pre;
	}

	.print\:whitespace-pre-line {
		white-space: pre-line;
	}

	.print\:whitespace-pre-wrap {
		white-space: pre-wrap;
	}

	.print\:break-normal {
		overflow-wrap: normal;
		word-break: normal;
	}

	.print\:break-words {
		overflow-wrap: break-word;
	}

	.print\:break-all {
		word-break: break-all;
	}

	.print\:truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.print\:w-0 {
		width: 0;
	}

	.print\:w-1 {
		width: 0.25rem;
	}

	.print\:w-2 {
		width: 0.5rem;
	}

	.print\:w-3 {
		width: 0.75rem;
	}

	.print\:w-4 {
		width: 0.4rem;
	}

	.print\:w-5 {
		width: 1.25rem;
	}

	.print\:w-6 {
		width: 0.6rem;
	}

	.print\:w-8 {
		width: 0.8rem;
	}

	.print\:w-10 {
		width: 2.5rem;
	}

	.print\:w-12 {
		width: 1.2rem;
	}

	.print\:w-16 {
		width: 1.6rem;
	}

	.print\:w-20 {
		width: 2rem;
	}

	.print\:w-24 {
		width: 2.4rem;
	}

	.print\:w-28 {
		width: 2.8rem;
	}

	.print\:w-32 {
		width: 3.2rem;
	}

	.print\:w-36 {
		width: 3.6rem;
	}

	.print\:w-40 {
		width: 4rem;
	}

	.print\:w-44 {
		width: 4.4rem;
	}

	.print\:w-48 {
		width: 4.8rem;
	}

	.print\:w-52 {
		width: 5.2rem;
	}

	.print\:w-56 {
		width: 5.6rem;
	}

	.print\:w-60 {
		width: 6rem;
	}

	.print\:w-64 {
		width: 6.4rem;
	}

	.print\:w-68 {
		width: 6.8rem;
	}

	.print\:w-72 {
		width: 7.2rem;
	}

	.print\:w-76 {
		width: 7.6rem;
	}

	.print\:w-80 {
		width: 8rem;
	}

	.print\:w-84 {
		width: 8.4rem;
	}

	.print\:w-88 {
		width: 8.8rem;
	}

	.print\:w-92 {
		width: 9.2rem;
	}

	.print\:w-96 {
		width: 9.6rem;
	}

	.print\:w-128 {
		width: 12.8rem;
	}

	.print\:w-136 {
		width: 13.6rem;
	}

	.print\:w-160 {
		width: 16rem;
	}

	.print\:w-192 {
		width: 19.2rem;
	}

	.print\:w-200 {
		width: 20rem;
	}

	.print\:w-208 {
		width: 20.8rem;
	}

	.print\:w-216 {
		width: 21.6rem;
	}

	.print\:w-224 {
		width: 22.4rem;
	}

	.print\:w-256 {
		width: 25.6rem;
	}

	.print\:w-288 {
		width: 28.8rem;
	}

	.print\:w-320 {
		width: 32rem;
	}

	.print\:w-360 {
		width: 36rem;
	}

	.print\:w-384 {
		width: 38.4rem;
	}

	.print\:w-400 {
		width: 40rem;
	}

	.print\:w-512 {
		width: 51.2rem;
	}

	.print\:w-640 {
		width: 64rem;
	}

	.print\:w-auto {
		width: auto;
	}

	.print\:w-px {
		width: 1px;
	}

	.print\:w-xs {
		width: 32rem;
	}

	.print\:w-sm {
		width: 48rem;
	}

	.print\:w-md {
		width: 64rem;
	}

	.print\:w-lg {
		width: 80rem;
	}

	.print\:w-xl {
		width: 96rem;
	}

	.print\:w-2xl {
		width: 112rem;
	}

	.print\:w-3xl {
		width: 128rem;
	}

	.print\:w-4xl {
		width: 144rem;
	}

	.print\:w-5xl {
		width: 160rem;
	}

	.print\:w-1\/2 {
		width: 50%;
	}

	.print\:w-1\/3 {
		width: 33.33333%;
	}

	.print\:w-2\/3 {
		width: 66.66667%;
	}

	.print\:w-1\/4 {
		width: 25%;
	}

	.print\:w-2\/4 {
		width: 50%;
	}

	.print\:w-3\/4 {
		width: 75%;
	}

	.print\:w-1\/5 {
		width: 20%;
	}

	.print\:w-2\/5 {
		width: 40%;
	}

	.print\:w-3\/5 {
		width: 60%;
	}

	.print\:w-4\/5 {
		width: 80%;
	}

	.print\:w-1\/6 {
		width: 16.66667%;
	}

	.print\:w-2\/6 {
		width: 33.33333%;
	}

	.print\:w-3\/6 {
		width: 50%;
	}

	.print\:w-4\/6 {
		width: 66.66667%;
	}

	.print\:w-5\/6 {
		width: 83.33333%;
	}

	.print\:w-1\/12 {
		width: 8.33333%;
	}

	.print\:w-2\/12 {
		width: 16.66667%;
	}

	.print\:w-3\/12 {
		width: 25%;
	}

	.print\:w-4\/12 {
		width: 33.33333%;
	}

	.print\:w-5\/12 {
		width: 41.66667%;
	}

	.print\:w-6\/12 {
		width: 50%;
	}

	.print\:w-7\/12 {
		width: 58.33333%;
	}

	.print\:w-8\/12 {
		width: 66.66667%;
	}

	.print\:w-9\/12 {
		width: 75%;
	}

	.print\:w-10\/12 {
		width: 83.33333%;
	}

	.print\:w-11\/12 {
		width: 91.66667%;
	}

	.print\:w-full {
		width: 100%;
	}

	.print\:w-screen {
		width: 100vw;
	}

	.print\:z-0 {
		z-index: 0;
	}

	.print\:z-10 {
		z-index: 10;
	}

	.print\:z-20 {
		z-index: 20;
	}

	.print\:z-30 {
		z-index: 30;
	}

	.print\:z-40 {
		z-index: 40;
	}

	.print\:z-50 {
		z-index: 50;
	}

	.print\:z-99 {
		z-index: 99;
	}

	.print\:z-999 {
		z-index: 999;
	}

	.print\:z-9999 {
		z-index: 9999;
	}

	.print\:z-auto {
		z-index: auto;
	}
}
