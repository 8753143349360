@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  height: fit-content;
  /* font-size: 65.5%; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

a[role='button'] {
  text-decoration: none;
}

table td {
  border: 1px solid rgba(196, 196, 196, 0.2);
  padding: 10px 5px;
}


#hide_select_arrow {
  -webkit-appearance: none;
}

#leaderboard {
  background-image: linear-gradient(to bottom, rgba(246, 18, 196, 0.1), rgba(246, 18, 196, 0.1));
}

#leaderboard table tr td,
#ranklist table tr td
{
  border: none;
  padding:  0.5rem;
}

#index_table table tr td {
  border: none;
  padding: 0.2rem;
}

#ranklist table td {
  padding: 16px;
}

#__react-alert__ div div div {
  font-size:.8rem !important;
  width:auto !important;
}

.recharts-default-tooltip{
  background-color: #160f33 !important;
  border: none !important;
  border-radius: .5rem !important;
}

.carousel-arrow {
  color:white !important;
}



/* Medium Devices, Desktops Only */
@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.95);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
    
  }

@media only screen and (max-width: 768px) {
  .MuiPaginationItem-root {
    color: white !important;
    font-size: 0.8em !important;
  }
  #responsive__home__header{
    background-image: radial-gradient(circle , rgba(0,0,0,.7) , rgba(0,0,0,.5) , rgba(0,0,0,.3));
  }
}


#referal_design{
  z-index: 1;
}


#carousel #single_contest_card{
  min-width: 25rem;
  
}
#carousel #single_bucket_card{
  min-width: 45rem;
}

#carousel #single_contest_card h3{
font-size: 1rem;
  
}

#carousel #single_bucket_card h1{
  font-size: 1.3rem;
}

#carousel::-webkit-scrollbar {
  width:6px !important;      
}
#carousel::-webkit-scrollbar-track {
  background: #180F3C;        
}
#carousel::-webkit-scrollbar-thumb {
  background-color: #342862;  
  border:none;      
}
form label {
  z-index: 99;
}

.MuiButtonBase-root {
  font-weight: 700 !important;
  font-size: 0.9em !important;
  text-transform: capitalize !important;
  padding: 0 !important;
}

.input-range__label-container{
  color:white !important;
  font-family: inherit !important;
  font-size: .7rem !important;
}


/* .MuiPagination-root {
  font-size: 1.4em !important;
} */

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.MuiPaginationItem-root {
  color: white !important;
  font-size: 1.1em !important;
}

.carousel-dots li button::before {
  color: white !important;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
#auth__otp::-webkit-outer-spin-button,
#auth__otp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

@import 'tailwind.css';
@import 'loader.css';
@import 'chart.css';
@import 'landing.css';
@import 'ranklist.css';
@import 'header.css';
