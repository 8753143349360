#ranklist_second {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  padding: 0.5rem;
}

#ranklist_first {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  padding: 0.5rem;

}

#ranklist_third {
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  padding: 0.5rem;

}

.shadow_avatar {
  box-shadow: 0px 0px 20px 0px rgba(55, 137, 252, 1);
}
