.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #655b9c;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes ldio-v2obrx097wr {
  0% {
    transform: translate(6px, 40px) scale(0);
  }
  25% {
    transform: translate(6px, 40px) scale(0);
  }
  50% {
    transform: translate(6px, 40px) scale(1);
  }
  75% {
    transform: translate(40px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(1);
  }
}
@keyframes ldio-v2obrx097wr-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(0);
  }
}
@keyframes ldio-v2obrx097wr-c {
  0% {
    background: #655b9c;
  }
  25% {
    background: #655b9c;
  }
  50% {
    background: #655b9c;
  }
  75% {
    background: #655b9c;
  }
  100% {
    background: #655b9c;
  }
}
.ldio-v2obrx097wr div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px, 40px) scale(1);
  background: #655b9c;
  animation: ldio-v2obrx097wr 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-v2obrx097wr div:nth-child(1) {
  background: #655b9c;
  transform: translate(74px, 40px) scale(1);
  animation: ldio-v2obrx097wr-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-v2obrx097wr-c 1s infinite step-start;
}
.ldio-v2obrx097wr div:nth-child(2) {
  animation-delay: -0.25s;
  background: #655b9c;
}
.ldio-v2obrx097wr div:nth-child(3) {
  animation-delay: -0.5s;
  background: #655b9c;
}
.ldio-v2obrx097wr div:nth-child(4) {
  animation-delay: -0.75s;
  background: #655b9c;
}
.ldio-v2obrx097wr div:nth-child(5) {
  animation-delay: -1s;
  background: #655b9c;
}
.loadingio-spinner-ellipsis-8kmdv1oq89l {
  width: 30px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-v2obrx097wr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.3);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-v2obrx097wr div {
  box-sizing: content-box;
}
