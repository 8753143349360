#header_one {
  background-image: url('../main/apps/landing/assets/img/bg-1.png');
  background-repeat: no-repeat;
  background-position: top right;
}

#header_two {
  background-image: url('../main/apps/landing/assets/img/bg-2.jpeg');
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
}

#header_three {
  background-image: url('../main/apps/landing/assets/img/bg-3.jpeg');
  background-position: right;
  background-repeat: no-repeat;
}

#static__portfolio__bg {
  background-image: url('../main/apps/static-portfolio/assets/img/header-bg.jpeg');
  background-position: left;
  background-repeat: no-repeat;
}
#landing_modal {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url('../main/apps/landing/assets/img/landing_modal_bg_2.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
.text__shadow {
  text-shadow: 2px 0px 2px rgba(24, 15, 60, 1);
}

@media only screen and (max-width: 786px) {
  #header_one {
 
    height: 100%;
    background-image: linear-gradient(to bottom , rgba(0,0,0,.3),rgba(0,0,0,.1)),url('../main/apps/landing/assets/img/mbg-1.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }

  #static__portfolio__bg {
    background-image: none;
  }
}
